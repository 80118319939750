import clsx from "clsx";
import React from "react";

export default function CircularProgressBar({
  percent,
  activeStroke = "#4F47E5",
  textStyle,
  classsName,
  size = 60,
  strokeWidth = 12,
}: {
  percent: number;
  activeStroke?: string;
  textStyle?: string;
  classsName?: string;
  size?: number;
  strokeWidth?: number;
}) {
  const circumference = (size - strokeWidth) * 2 * Math.PI;

  return (
    <div className="flex items-center justify-center overflow-hidde rounded-full relative">
      <svg
        className={clsx(
          `svg-indicator w-32 h-32 relative -rotate-90`,
          classsName
        )}
      >
        <circle
          strokeWidth={strokeWidth}
          fill="transparent"
          cx={size}
          cy={size}
          r={size - strokeWidth}
          className="svg-indicator-track"
          stroke="#E5E5E5"
        />
        <circle
          strokeWidth={strokeWidth}
          cx={size}
          cy={size}
          r={size - strokeWidth}
          fill="transparent"
          className="svg-indicator-indication"
          stroke={activeStroke}
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={circumference - (percent / 100) * circumference}
        />
      </svg>
      <span
        className={`absolute mt-2 mr-1 text-2xl mx-auto ${
          textStyle ? textStyle : "text-indigo-700"
        }`}
      >{`${percent}%`}</span>
    </div>
  );
}
