import React from "react";
import MappingHeader from "features/MappingTools/MappingHeader";
import MappingEditor from "features/MappingTools";

const Mapping = () => {
  return (
    <div className="fixed top-0 left-0 bg-gray-100 w-screen min-h-screen max-h-min z-20">
      <MappingHeader />
      <div className="pt-4 px-6">
        <MappingEditor />
      </div>
    </div>
  );
};

export default Mapping;
