import { ColumnDef } from "@tanstack/react-table";
import { MaterialSearchOUT } from "api-client";
import React from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "components/Tooltip";

const useColumns = () => {
  const { t } = useTranslation();

  const columns = React.useMemo<ColumnDef<MaterialSearchOUT>[]>(
    () => [
      {
        accessorFn: (row) => row.name,
        id: "name",
        cell: (info) => (
          <div className="cursor-pointer">{info.row.original.name}</div>
        ),
        header: () => <div>{t("productEditor.name")}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        size: 250,
      },
      {
        accessorFn: (row) => row.category_general__name,
        id: "category_general__name",
        cell: (info) => (
          <div className="cursor-pointer">
            {info.row.original.category_general__name}
          </div>
        ),
        header: () => <div>{t("productEditor.category")}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        size: 250,
      },
      {
        accessorFn: (row) => row.recyclability_qualification,
        id: "recyclability_qualification",
        cell: (info) => (
          <div className="cursor-pointer">
            {info.row.original.recyclability_qualification}
          </div>
        ),
        header: () => (
          <div className="flex items-center">
            {t("productEditor.recyclability")}
            <Tooltip content={t("productEditor.recyclabilityTooltip")} />
          </div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
        size: 250,
      },
    ],
    []
  );
  return columns;
};

export default useColumns;
