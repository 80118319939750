import { scrollToFirstInvalidInput } from "../utils";

export default function validateComponentForm():
  | { [key: string]: string }
  | false {
  const componentForm = document.getElementById("step-3-1");
  if (!componentForm) return false;
  const allInputs = Array.from(
    componentForm.querySelectorAll("input, select")
  ) as (HTMLInputElement | HTMLSelectElement)[];
  const requiredInputs = allInputs.filter(
    (el: HTMLInputElement | HTMLSelectElement) => el.required
  );
  const errors = [];
  const values = [];

  for (let i = 0; i < requiredInputs.length; i++) {
    if (requiredInputs[i].value !== "") {
      values.push({
        name: requiredInputs[i].name,
        value: requiredInputs[i].value,
      });
      continue;
    }
    errors.push(requiredInputs[i]);
    requiredInputs[i].setAttribute("data-invalid", "true");
  }

  let foundImages: File[] = [];

  allInputs
    .filter((inp) => "files" in inp)
    .forEach((inp) => {
      const input = inp as HTMLInputElement;
      if (input.files && input.files.length > 0) {
        foundImages = Array.from(
          new Set([...foundImages, ...Array.from(input.files)])
        );
      }
    });

  if (errors.length === 0) {
    for (let i = 0; i < requiredInputs.length; i++) {
      requiredInputs[i].setAttribute("data-invalid", "false");
    }
    const result = allInputs
      .map((input) => ({
        name: input.name,
        value: input.value,
        files: (input as HTMLInputElement).files,
      }))
      .reduce((acc, cur) => {
        return { ...acc, [cur.name]: cur.files ? foundImages : cur.value };
      }, {});
    console.log("validateComponentForm", result);
    return result;
  } else {
    scrollToFirstInvalidInput(errors);
    for (let i = 0; i < errors.length; i++) {
      errors[i].setAttribute("data-invalid", "true");
    }
    return false;
  }
}
