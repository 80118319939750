import clsx from "clsx";
import { useWindowSize } from "hooks/useWindowSize";
import { observer } from "mobx-react";
import React from "react";
import { globalStore } from "store/GlobalStore";

const TooltipPopup = () => {
  const popupRef = React.useRef<HTMLDivElement>(null);
  const windowSize = useWindowSize();

  function calculateTopPos() {
    const calculatedTopPos =
      Number(globalStore.tooltip?.position?.top) -
      Number(popupRef?.current?.clientHeight);
    let topPos =
      calculatedTopPos > 0
        ? calculatedTopPos
        : Number(globalStore.tooltip?.position?.top) + 20;
    const bottomPos = topPos + Number(popupRef?.current?.clientHeight);
    if (bottomPos > Number(windowSize.height)) {
      topPos -= bottomPos - Number(windowSize.height) + 25;
    }
    return topPos;
  }

  function calculateLeftPos() {
    const leftOffset =
      globalStore.tooltip?.align === "right"
        ? 0
        : Number(popupRef?.current?.clientWidth) / 2;
    const calculatedLeftPos =
      Number(globalStore.tooltip?.position?.left) - leftOffset;
    let leftPos = calculatedLeftPos > 0 ? calculatedLeftPos : 5;
    const rightedge = leftPos + Number(popupRef?.current?.clientWidth);
    if (rightedge > Number(windowSize.width)) {
      leftPos -= rightedge - Number(windowSize.width) + 5;
    }
    return leftPos;
  }

  function setTooltipPosition() {
    if (popupRef.current && globalStore.tooltip?.active) {
      const leftPos = calculateLeftPos();
      const topPos = calculateTopPos();
      popupRef.current.style.left = leftPos + "px";
      popupRef.current.style.top = topPos + "px";
      popupRef.current.style.visibility = "visible";
    }
  }

  React.useEffect(() => {
    setTooltipPosition();
  }, [globalStore.tooltip?.active]);

  React.useEffect(() => {
    if (
      !globalStore.tooltip?.hoverOnTooltip ||
      globalStore.tooltip?.disableStickOnHover
    ) {
      const timeout = setTimeout(() => {
        globalStore.setTooltipContent(undefined);
        clearTimeout(timeout);
      }, 100);
    }
  }, [globalStore.tooltip?.hoverOnTooltip]);

  const setHoverOnTooltip = (hover: boolean) => {
    globalStore.setTooltipContent({
      ...globalStore.tooltip,
      hoverOnTooltip: hover,
    });
  };

  if (!globalStore.tooltip?.active || !globalStore.tooltip?.content)
    return null;
  return (
    <div
      className={clsx(
        "absolute z-50 shadow-lg rounded-md max-w-[99%]",
        globalStore.tooltip?.whiteTheme
          ? "bg-white text-gray-800 drop-shadow"
          : "text-white px-2 py-1.5 bg-gray-700 w-[320px] max-w-fit text-xs",
        globalStore.tooltip?.className
      )}
      style={{ visibility: "hidden" }}
      ref={popupRef}
      onMouseEnter={() => setHoverOnTooltip(true)}
      onMouseLeave={() => setHoverOnTooltip(false)}
    >
      {globalStore.tooltip?.content}
    </div>
  );
};

export default observer(TooltipPopup);
