import React, { useCallback } from "react";
import Badge from "components/Badge";
import { ArrowsExpandIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import { Row } from "@tanstack/react-table";
import { MaterialFileObject } from "store/IfcMapping/types";
import clsx from "clsx";
import MappingChip from "./MappingChip";
import { CertificationOut, EPDConformityOut } from "api-client";
import { CalculatedColumns, InfoColumns } from "store/IfcMapping/MEStore";
import Tooltip from "components/Tooltip";
import { isFailedStatus } from "utils";
import BPENotFoundIcon from "assets/images/icons/not-found.svg";
import { REFERENCE_UNITS } from "./consts";

export function getCurrentReferenceValue(id?: string) {
  return REFERENCE_UNITS?.find((item) => item.id === id);
}

export function attributeRow(
  index: number,
  attr: { label: string; value: string | number | undefined }
) {
  return (
    <div
      key={index}
      className="flex justify-between text-gray-600 text-sm font-normal mb-2"
    >
      <div>{attr.label}</div>
      <div className="text-gray-500">{attr.value}</div>
    </div>
  );
}

export function dynamicColumnsBorder(colNumber: number | undefined) {
  switch (colNumber) {
    case 0:
      return "[&>*:nth-child(1)]:border-l";
    case 1:
      return "[&>*:nth-child(2)]:border-l";
    case 2:
      return "[&>*:nth-child(3)]:border-l";
    case 3:
      return "[&>*:nth-child(4)]:border-l";
    case 4:
      return "[&>*:nth-child(5)]:border-l";
    case 5:
      return "[&>*:nth-child(6)]:border-l";
    case 6:
      return "[&>*:nth-child(7)]:border-l";
    case 7:
      return "[&>*:nth-child(8)]:border-l";
    case 8:
      return "[&>*:nth-child(9)]:border-l";
    case 9:
      return "[&>*:nth-child(10)]:border-l";
    case 10:
      return "[&>*:nth-child(11)]:border-l";
    default:
      return "";
  }
}

type BadgeTypes = "attention" | "success" | "warning" | "info" | "auto";

export const ProcessingStatus = ({
  status,
  mapped,
  total,
}: {
  status: string | undefined;
  mapped: number | undefined;
  total: number | undefined;
}) => {
  const { t } = useTranslation();
  let badgeType: BadgeTypes = "auto";
  let label = "";
  let tooltipMessage = "";

  const calcPercentage = () => (100 * (mapped || 0)) / (total || 1);
  const getValue = () =>
    (mapped || 0) > 0 && calcPercentage() < 1
      ? Math.ceil(calcPercentage())
      : Math.floor(calcPercentage());

  const percentage = (total || 0) > 0 ? getValue() : 0;

  if (status === "uploaded") {
    badgeType = "attention";
    label = t("buildingMaterialResources.processing");
    tooltipMessage = t("buildingMaterialResources.processingTooltip");
  } else if (status === "reports_ready") {
    if (mapped && total && mapped === total) {
      badgeType = "success";
      label = t("buildingMaterialResources.complete");
      tooltipMessage = t("buildingMaterialResources.completeTooltip");
    } else {
      badgeType = "attention";
      label = t("buildingMaterialResources.mappingStatus", {
        value: percentage,
      });
      tooltipMessage = t("buildingMaterialResources.mappingStatusTooltip");
    }
  } else if (isFailedStatus(status)) {
    badgeType = "warning";
    label = t("buildingMaterialResources.failedChipText");
    tooltipMessage = t("buildingMaterialResources.failedChipTooltip");
  }

  return (
    <div className="w-full flex">
      <Badge
        className="justify-between !whitespace-nowrap !px-4 min-w-fit"
        type={badgeType}
      >
        <div className="break-all">{label}</div>
        <Tooltip content={tooltipMessage} />
      </Badge>
    </div>
  );
};

export type MQType = "Good" | "Okay" | "Poor" | "None";

export type Report = {
  imported: number | undefined;
  total: number | undefined;
  percentage: number;
};

interface MQProps {
  report: Report;
  onClick?: (report: Report) => void;
}

export const ModelQualityNoneChip = () => {
  const { t } = useTranslation();
  return (
    <div
      className={
        "bg-gray-100 relative max-w-[100px] h-[25px] rounded-md flex items-center justify-center font-semibold"
      }
    >
      {t("buildingMaterialResources.none")}
    </div>
  );
};

export const ModalQualityChip = ({ report, onClick }: MQProps) => {
  const { t } = useTranslation();

  const { percentage } = report;

  // Good progress and color green by default
  let color = "bg-green-100";
  let hoverColor = "group-hover:bg-green-200";
  let textColor = "text-green-800";
  let hoverTextColor = "group-hover:text-green-900";
  let quality: MQType = t("buildingMaterialResources.good");

  if (percentage <= 25) {
    color = "bg-red-100";
    hoverColor = "group-hover:bg-red-200";
    textColor = "text-red-800";
    hoverTextColor = "group-hover:text-red-900";
    quality = t("buildingMaterialResources.poor");
  } else if (percentage <= 50) {
    color = "bg-yellow-100";
    hoverColor = "group-hover:bg-yellow-200";
    textColor = "text-yellow-800";
    hoverTextColor = "group-hover:text-yellow-900";
    quality = t("buildingMaterialResources.okay");
  }

  function handleClick() {
    onClick && onClick(report);
  }

  return (
    <div
      onClick={handleClick}
      className={clsx(
        `bg-gray-100 relative max-w-[100px] h-[25px] rounded-md flex items-center group`,
        {
          "cursor-pointer": onClick,
        }
      )}
    >
      <div
        style={{
          width: `${percentage < 5 ? percentage + 2 : percentage}%`,
        }}
        className={`${color} z-1 absolute ${
          percentage === 100 ? "rounded-md" : "rounded-tl-md rounded-bl-md"
        } h-full`}
      >
        <div
          className={`${hoverColor} h-full ${
            percentage === 100 ? "rounded-md" : "rounded-tl-md rounded-bl-md"
          }`}
        />
      </div>
      <div className="z-1 flex justify-between px-2 items-center w-full">
        <p></p>
        <p className={`${textColor} font-semibold`}>{quality}</p>
        <ArrowsExpandIcon className={`w-4 ${textColor} ${hoverTextColor}`} />
      </div>
    </div>
  );
};

export const getHiddenColumns = (
  groupBy: string[] | undefined,
  infoColumnsState: InfoColumns,
  calculatedColumnsState: CalculatedColumns
) => {
  return {
    component: !!groupBy?.find((item) => item === "component"),
    ifc_entity: !!groupBy?.find((item) => item === "ifc_entity"),
    floor: !!groupBy?.find((item) => item === "floor"),
    material: !!groupBy?.find((item) => item === "material"),
    predefinedType: !!groupBy?.find((item) => item === "predefinedType"),
    length: infoColumnsState.length,
    width: infoColumnsState.width,
    height: infoColumnsState.height,
    area: infoColumnsState.area,
    volume: infoColumnsState.volume,
    gwp: calculatedColumnsState.gwp,
    mass: calculatedColumnsState.mass,
  };
};

export const CategoryMappingStatus = ({
  row,
  unmatch,
  i,
}: {
  row: Row<unknown>;
  unmatch?: (row: Row<unknown>) => Promise<void>;
  i?: string;
}) => {
  const { t } = useTranslation();
  const object = row.original as MaterialFileObject;
  const [loading, setLoading] = React.useState(false);

  const handleUnmatch = useCallback(async () => {
    setLoading(true);
    await unmatch?.(row);
    setLoading(false);
  }, [row]);

  if (object.products && object.products.length > 0) {
    return object.products.length > 1 ||
      (object?.products.length === 1 &&
        Number(object?.total_objects) > 1 &&
        Number(object?.total_mapped) < Number(object?.total_objects)) ? (
      <div className="text-xs text-gray-400 w-max">
        {t("mapping.multipleMappings")}
      </div>
    ) : (
      <MappingChip
        object={object}
        product={object?.products?.[0]}
        unmatch={handleUnmatch}
        loading={loading}
      />
    );
  } else if (object.product_match) {
    return (
      <MappingChip
        object={object}
        product={object.product_match}
        unmatch={handleUnmatch}
        loading={loading}
      />
    );
  }
  return (
    <div key={i} className="text-xs text-gray-400 w-max">
      {t("mapping.searchAndMap")}
    </div>
  );
};

export function detailViewSkeleton() {
  return (
    <div className="animate-pulse">
      {[...Array(5).keys()].map((item) => (
        <div className="gap-4 mb-7" key={item}>
          <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-[40%] mb-4"></div>
          <div className="h-3 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
          <div className="h-3 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
          <div className="h-3 bg-gray-200 rounded-lg dark:bg-gray-700 w-full mb-4"></div>
        </div>
      ))}
    </div>
  );
}

export function EmptyStateOverview(props: { children: React.ReactNode }) {
  return (
    <div className="flex h-full -mt-20 text-center items-center justify-center text-sm text-gray-500">
      <div className="flex flex-col w-fit mt-16 items-center justify-center">
        <img alt="BPENotFoundIcon" src={BPENotFoundIcon} className="py-3" />
        <span className="font-normal text-sm text-gray-500 p-4 max-w-md text-left">
          {props.children}
        </span>
      </div>
    </div>
  );
}

export function complianceChipsGroup(
  allItems: CertificationOut[] | EPDConformityOut[],
  availableItems: string[] | undefined
) {
  return (
    <div className="flex">
      {allItems?.map((rec) => {
        const itemExist =
          availableItems?.find((item) => item === rec.name) !== undefined;
        return (
          <div
            key={rec.id}
            className={clsx(
              "text-xs rounded-full px-2 mr-1",
              itemExist
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            )}
          >
            {rec.name}
          </div>
        );
      })}
    </div>
  );
}
