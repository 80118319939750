import React from "react";
import clsx from "clsx";

interface LinearLoadingProps {
  type?: "auto" | "success";
  containerClassName?: string;
}

export default function LinearLoading({
  type = "auto",
  containerClassName,
}: LinearLoadingProps) {
  return (
    <div className={clsx("absolute w-full left-0 top-0", containerClassName)}>
      <span
        className={clsx("block overflow-hidden h-1 relative", {
          "bg-indigo-50": type === "auto",
          "bg-green-50": type === "success",
        })}
      >
        <span
          className={clsx(
            "absolute left-0 bottom-0 top-0 w-[60%] transition ease-linear origin-left",
            "animate-ping duration-1000",
            {
              "bg-indigo-600": type === "auto",
              "bg-green-600": type === "success",
            }
          )}
        ></span>
      </span>
    </div>
  );
}
