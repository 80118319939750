import React from "react";
import { Category, inventoryStore } from "store/InventoryStore";
import MinMaxFilter from "components/Table/MinMaxFilter";
import CustomFilter, { CustomFilterItem } from "components/Table/BeFilter";
import { useTranslation } from "react-i18next";
import useFeatureFlag from "hooks/useFeatureFlag";

export function useInventory() {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();

  function onChangeFilter(value: string, key: string) {
    if (inventoryStore.loadingMore) return;
    if (value !== "") {
      inventoryStore.setSearchFilters(key, value);
    } else {
      inventoryStore.resetInventoryFilters();
    }
  }

  const columnFilter = (key: string) => {
    const minWidth = key === "object_name" ? "min-w-[200px]" : "";
    return (
      <CustomFilter
        className={`${minWidth} !mt-2`}
        name={key}
        items={getFilterItems(key) ?? []}
        handleChange={(value) => onChangeFilter(String(value), key)}
        placeholder={t("buildingInventory.search")}
      />
    );
  };

  function getFilterItems(columnKey: string) {
    const data = inventoryStore.filterValues;
    const currentFilterItems = (data as unknown as { [key: string]: string[] })[
      columnKey
    ];
    if (currentFilterItems) {
      const items: CustomFilterItem[] = currentFilterItems.map(
        (item, index) => ({
          id: String(index),
          name: String(item),
        })
      );
      return items;
    }
  }

  function onChangeMinMax(
    key: string,
    min: string | number,
    max: string | number
  ) {
    if (inventoryStore.loadingMore) return;
    if (min !== "" || max !== "") {
      inventoryStore.setMinMaxFilters(key, min, max);
    } else {
      inventoryStore.resetInventoryFilters();
    }
  }

  const minMaxFilter = (key: string) => {
    return (
      <MinMaxFilter
        columnKey={key}
        handleChange={(min, max) => onChangeMinMax(key, min, max)}
      />
    );
  };

  function getDebugColumns() {
    const debugColumnsList: string[] = [];
    inventoryStore.categories?.forEach((cat) =>
      cat.columns.forEach((col) => {
        if (col.in_debug) {
          debugColumnsList.push(col.property_name);
        }
      })
    );
    inventoryStore.setDebugColumnsList(debugColumnsList);
  }

  const getColumnsSavedState = () => {
    const savedState = localStorage.getItem("inventory_settings");
    return savedState ? JSON.parse(savedState) : null;
  };

  const saveColumnsState = (categorySettings: Category[]) => {
    localStorage.setItem(
      "inventory_settings",
      JSON.stringify(categorySettings)
    );
  };

  const checkDebugColumnsFlag = (columnsObject: { [key: string]: boolean }) => {
    if (!isFeatureVisible("inventory_debug")) {
      inventoryStore.debugColumnsList.forEach(
        (item) => (columnsObject[item] = false)
      );
    }
    return columnsObject;
  };

  function getColumnsVisibility() {
    const listOfStates = inventoryStore.categories.map(
      (category) => category.visibility
    );
    const reducedList = listOfStates.reduce((r, c) => Object.assign(r, c), {});
    const columnsVisibility = checkDebugColumnsFlag(reducedList);
    return columnsVisibility;
  }

  async function getColumnsAndStates() {
    await inventoryStore.fetchColumns();
    const savedState = await getColumnsSavedState();
    if (savedState) {
      const savedStateColumnsAreDifferent = inventoryStore.categories.find(
        (item, inx) =>
          item?.columns.find(
            (item, index) =>
              item.property_name !==
              savedState[inx]?.columns?.[index]?.property_name
          )
      );
      if (savedStateColumnsAreDifferent) {
        localStorage.removeItem("inventory_settings");
      } else {
        inventoryStore.setCategories(savedState);
      }
    }
  }

  return {
    columnFilter,
    minMaxFilter,
    saveColumnsState,
    getColumnsVisibility,
    getColumnsAndStates,
    getDebugColumns,
  };
}
