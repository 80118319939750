import React from "react";
import { TrashIcon } from "@heroicons/react/solid";
import { LocalImage } from "../../api-client-local/db_interfaces";

export default function ImageUploaderThumbnail(props: {
  item: File | LocalImage;
  onRemove: (image: File | LocalImage) => void;
}): JSX.Element {
  let imgUrl = "";
  if (props.item instanceof File) {
    imgUrl = URL.createObjectURL(props.item);
  } else if ("file" in props.item) {
    imgUrl = URL.createObjectURL(props.item.file);
  } else {
    imgUrl = "";
  }
  return (
    <div
      className={`group border max-w-[90px] h-full aspect-square items-center md:h-auto md:mb-2 md:mr-2 flex min-w-30 justify-center p-2 relative rounded-md`}
    >
      <img src={imgUrl} className="object-fit" />
      <br />
      <button
        type="button"
        className="hidden group-hover:block group-hover:md:hidden w-7 text-indigo-700 p-1 opacity-100 absolute right-0 top-0 rounded-lg bg-white"
        onClick={() => props.onRemove(props.item)}
      >
        <TrashIcon />
      </button>
      <div className="hidden group-hover:md:flex bg-gray-800 absolute w-full h-full top-0 bottom-0 bg-opacity-60 transition justify-center items-center gap-1">
        <button
          type="button"
          className="w-4 text-white opacity-100"
          onClick={() => props.onRemove(props.item)}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
}
