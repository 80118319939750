import { dgnbApi } from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { DGNBFormData } from "store/DGNBStore";

export default async function handleDgnbPassportCreate(
  dgnbFormData: DGNBFormData
) {
  const localAuthHeader = await getLocalAuthHeader();
  const { buildingId, outputType, dgnbPassport } = dgnbFormData;
  try {
    const response = await dgnbApi.reportApiV1RoutersDgnbPassportCreate(
      buildingId as string,
      outputType,
      dgnbPassport,
      localAuthHeader
    );
    return response.data;
  } catch (e) {
    console.error("Failed to create DGNB Passport", e, buildingId);
  }
}
