import React from "react";
import { BuildingOUT, PlaceDetailsOUT } from "api-client";
import { placeDetailsToString } from "utils";

export default function BuildingHeaderAddress({
  building,
}: {
  building: BuildingOUT;
}) {
  function getAddress() {
    const address = placeDetailsToString({
      ...building.address,
      postal_code: building.postal_code,
      region: building.region,
    } as PlaceDetailsOUT);
    return address !== "" ? address : "";
  }

  return (
    <div className="text-xs md:text-base text-gray-700 pl-0 md:pl-4 leading-4">
      {getAddress()}
    </div>
  );
}
