import React from "react";
import Checkbox from "components/Checkbox";
import { FormValue } from ".";

interface Props {
  id: string;
  handleChange: (value: FormValue, name?: string | undefined) => void;
  title: string;
  subTitle: string;
  checked: boolean | undefined;
}

const SettingsCheckbox = ({
  handleChange,
  id,
  title,
  subTitle,
  checked,
}: Props) => {
  return (
    <div className="my-6 flex">
      <Checkbox
        id={id}
        onChange={(e) => handleChange(e.currentTarget.checked, id)}
        checked={checked}
      />
      <label htmlFor={id} className="pl-2 text-sm">
        <div className="text-gray-700 font-medium">{title}</div>
        <div className="text-gray-500 font-normal">{subTitle}</div>
      </label>
    </div>
  );
};

export default SettingsCheckbox;
