import React from "react";
import ComponentChildContent from "./ComponentChildContent";
import { getCurrentReferenceValue } from "features/MappingTools/utils";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ComponentDetailsByFileOut } from "api-client";

const ComponentChildOverview = ({
  childItem,
  referenceUnit,
  hideInfoIcon,
}: {
  childItem: ComponentDetailsByFileOut;
  referenceUnit?: string;
  hideInfoIcon?: boolean;
}) => {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const itemReferenceUnit = getCurrentReferenceValue(
    childItem.reference_unit.toUpperCase()
  );

  return (
    <div className="border border-gray-300 rounded-md overflow-hidden flex items-stretch w-full mb-3">
      <div className="border-r border-gray-200 bg-gray-50 text-gray-500 min-w-[115px] items-center justify-center flex text-sm align-middle text-center">
        <span className="text-gray-900 font-semibold mr-1">
          {childItem?.unit_conversion}
        </span>
        <span className="text-gray-500">
          {`${
            isEng
              ? itemReferenceUnit?.name ?? "-"
              : itemReferenceUnit?.name_de ?? "-"
          }/${referenceUnit}`}
        </span>
      </div>
      <div className="bg-white text-gray-700 text-sm p-3 overflow-hidden w-full">
        <ComponentChildContent
          childItem={childItem}
          className="!p-0"
          hideInfoIcon={hideInfoIcon}
        />
        {childItem.comment ? (
          <div className="pt-2">{childItem.comment}</div>
        ) : null}
      </div>
    </div>
  );
};

export default observer(ComponentChildOverview);
