import clsx from "clsx";
import AuditBackButton from "features/Audit/AuditBackButton";
import AuditBackButtonOnline from "features/AuditOnline/AuditBackButtonOnline";
import React from "react";
import { useLocation } from "react-router";

interface IProps {
  children?: React.ReactNode;
  className?: string;
}

export default function BlueprintsLayout({ children, className }: IProps) {
  const { pathname } = useLocation();

  const backButton = () =>
    pathname.includes("audits-online") ? (
      <AuditBackButtonOnline />
    ) : (
      <AuditBackButton />
    );

  return (
    <>
      <header
        className={clsx(
          "h-10 md:h-12 text-black !bg-white items-center border-b",
          "border-b-gray-300 w-full z-20 top-0 sticky flex px-4",
          className
        )}
      >
        {backButton()}
      </header>
      <div>{children}</div>
    </>
  );
}
