import React from "react";
import AddComponents from "./AddComponents";
import AddComponent from "./AddComponent";
import { observer } from "mobx-react-lite";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

const Step3Components = () => {
  const step = blueprintCreateStore.componentCurrentStep;
  const steps = [<AddComponents key={0} />, <AddComponent key={1} />];

  return (
    <>
      <div className="grid">
        <div className="overflow-hidden">
          <div
            style={{ transform: `translateX(-${step * 100}%)` }}
            className="whitespace-nowrap align-top transition-transform duration-300"
          >
            {steps.map((page, i) => {
              return (
                <div
                  key={i}
                  id={`step-3-${i}`}
                  className={`w-full inline-block p-1 align-top`}
                >
                  {page}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Step3Components);
