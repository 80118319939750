import React from "react";
import ButtonAsDiv from "components/ButtonAsDiv";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { validateStep } from "features/AuditOnline/utils";

const SkipButton = () => {
  const { t } = useTranslation();

  return (
    <ButtonAsDiv
      className="w-full md:w-auto"
      color="secondary"
      onClick={() => {
        const validated = validateStep(blueprintCreateStore.step);
        if (validated) {
          blueprintCreateStore.setStep(blueprintCreateStore.stepCount - 1);
          blueprintCreateStore.setFilledSteps([
            ...Array.from({
              length: blueprintCreateStore.stepCount - 1,
            }).keys(),
          ]);
        }
      }}
    >
      {t("audits.skipDetails")}
    </ButtonAsDiv>
  );
};

export default SkipButton;
