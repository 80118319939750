import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import EditDeleteItem from "components/ActionMenu/EditDeleteItemMenu";
import { Link } from "react-router-dom";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { observer } from "mobx-react-lite";

const ItemComponentListOnline = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, audit_id } = useParams();
  const { selectedBlueprint } = caOnlineStore;

  if (!selectedBlueprint || selectedBlueprint?.components?.length === 0) {
    return null;
  }

  return (
    <div className="my-4">
      <h4 className="text-sm font-semibold">{t("audits.components")}</h4>
      <div>
        {selectedBlueprint?.components?.map((comp) => {
          const componentEditRoute = `/buildings/${id}/audits-online/${audit_id}/blueprints/${comp.id}`;

          const handleRouting = () => {
            navigate(componentEditRoute);
          };

          const handleDelete = async () => {
            await caOnlineStore.deleteComponent(comp.id);
          };

          return (
            <div
              className="flex pr-2 text-sm justify-between items-center border-b-2 py-2"
              key={comp.id}
            >
              <Link
                onClick={() => {
                  caOnlineStore.setIsComponent(true);
                  caOnlineStore.setSelectedBPOrComponents(comp);
                }}
                to={componentEditRoute}
                className="w-full"
              >
                <span>{comp.name} </span> ({comp.as_component_amount})
              </Link>
              <EditDeleteItem
                id={comp.id}
                onEdit={handleRouting}
                onDelete={handleDelete}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default observer(ItemComponentListOnline);
