import { COMPONENT_DATA_LOCAL_KEY } from "features/MappingTools/consts";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { searchStore } from "store/IfcMapping/SearchStore";

const useComponentActions = () => {
  const navigate = useNavigate();
  const { ifc_id } = useParams();

  const editComponent = async (id?: string | null) => {
    if (!id) return;
    await searchStore.getComponentDetails(id, ifc_id);
    const pathname = saveStateAndGetPath();
    navigate(`${pathname}/component-editor`, {
      state: { id: id, compliant: false, mode: "edit" },
    });
  };

  const duplicateComponent = async (id?: string | null) => {
    if (!id) return;
    await searchStore.getComponentDetails(id, ifc_id);
    const pathname = saveStateAndGetPath();
    navigate(`${pathname}/component-editor`, {
      state: { id: id, compliant: false, mode: "duplicate" },
    });
  };

  function saveStateAndGetPath() {
    if (componentsStore.openedSearchInCE) {
      localStorage.setItem(
        COMPONENT_DATA_LOCAL_KEY,
        JSON.stringify({
          data: componentsStore.data,
          step: componentsStore.activeStep,
        })
      );
    }
    const pathname = location.pathname.replace("/component-editor", "");
    return pathname;
  }

  return { editComponent, duplicateComponent, saveStateAndGetPath };
};

export default useComponentActions;
