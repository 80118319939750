import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { TrashIcon } from "@heroicons/react/solid";
import ImageUploaderLocal from "components/ImageUploaderLocal";

interface Image {
  id: string;
  image: string;
}
interface IProps {
  name?: string;
  containerClassName?: string;
  images?: { id: string; image: string }[];
  onChange?: (value: File[], name: string) => void;
  onBlur?: (name: string) => void;
  multiple?: boolean;
  labelName?: string;
  onRemove?: (id: string) => void;
}

const ImageUploader = ({
  name = "",
  containerClassName,
  images = [],
  onChange,
  onBlur,
  multiple,
  labelName,
  onRemove,
}: IProps) => {
  const [selectedImages, setSelectedImages] = useState<File[]>([]);
  const [uploadedImages, setUploadedImages] = useState<Image[]>([]);
  const [showDelete, setShowDelete] = useState<{
    id: number | string;
    show: boolean;
  }>({
    id: 0,
    show: false,
  });

  useEffect(() => {
    return () => setUploadedImages([]);
  }, []);

  useEffect(() => {
    if (!multiple) {
      setSelectedImages([]);
    }
    setUploadedImages(images);
  }, [images]);

  const handleFileChanged = async (filesList: File[] | FileList) => {
    !multiple && setSelectedImages([]);
    const files = Array.from(filesList);
    setSelectedImages((state) => [...state, ...files]);
  };

  useEffect(() => {
    onChange && onChange(selectedImages, name);
    onBlur && onBlur(name);
  }, [selectedImages]);

  const removeUploadedImages = (id: string) => {
    const images = uploadedImages.filter((item) => item.id !== id);
    setUploadedImages(images);
    onRemove && onRemove(id);
  };

  function showUploadedImage(index: number, item: Image) {
    if (!item.image) return;
    return (
      <div
        className={clsx(
          "border max-w-[90px] h-auto mb-2 flex border-green-500",
          "min-w-30 justify-center p-2 mr-2 relative rounded-md"
        )}
        onMouseEnter={() => setShowDelete({ id: item.id, show: true })}
        onMouseLeave={() => setShowDelete({ id: item.id, show: false })}
        key={index}
      >
        <img src={item.image} alt="" />
        <br />
        {showDelete.id === item.id && showDelete.show && (
          <div className="bg-gray-800 absolute w-full h-full top-0 bottom-0 bg-opacity-60 transition flex justify-center items-center gap-1">
            <button
              type="button"
              className="w-4 text-white opacity-100"
              onClick={() => removeUploadedImages(String(item.id))}
            >
              <TrashIcon />
            </button>
          </div>
        )}
      </div>
    );
  }

  const handleOnChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      handleFileChanged(e.target.files);
    },
    []
  );

  const onRemoveSelected = useCallback((file: File) => {
    const filteredItems = selectedImages.filter((item) => item != file);
    setSelectedImages([...filteredItems]);
  }, []);

  return (
    <ImageUploaderLocal
      name={name}
      labelName={labelName}
      multiple={multiple}
      className={containerClassName ?? ""}
      onChange={handleOnChange}
      onDraged={handleFileChanged}
      onRemoveSelected={onRemoveSelected}
    >
      {uploadedImages &&
        uploadedImages.length > 0 &&
        uploadedImages?.map((item: Image, index: number) =>
          showUploadedImage(index, item)
        )}
    </ImageUploaderLocal>
  );
};

export default ImageUploader;
