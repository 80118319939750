import React from "react";
import {
  CustomComponent,
  componentsStore,
} from "store/IfcMapping/ComponentsStore";
import ComponentEditorFooter from "./ComponentEditorFooter";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import { observer } from "mobx-react-lite";
import Step3 from "./Steps/Step3";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import { StepperCustomCircle } from "utils";
import clsx from "clsx";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";
import { COMPONENT_DATA_LOCAL_KEY } from "../consts";
import { useLocation } from "react-router";
import { searchStore } from "store/IfcMapping/SearchStore";
import { ProductSearchOutput } from "api-client";
import { getCurrentReferenceValue } from "../utils";
import useFeatureFlag from "hooks/useFeatureFlag";

const ComponentEditor = () => {
  const { steps, activeStep } = componentsStore;
  const location = useLocation();
  const { isFeatureVisible } = useFeatureFlag();

  async function initialLoad() {
    if (
      isFeatureVisible("component_editor_circularity_step") &&
      buildingProductStore.optionsLists.preuse_qualification.length === 0
    ) {
      await buildingProductStore.fetchCPXValues();
    }

    const lastState = JSON.parse(
      localStorage.getItem(COMPONENT_DATA_LOCAL_KEY) || "{}"
    );

    if (lastState.data) {
      recallState(lastState);
    } else if (location.state) {
      loadDataForUpdate();
    } else {
      componentsStore.setActiveStep(0);
      componentsStore.resetData();
    }
  }

  React.useEffect(() => {
    (async () => await initialLoad())();

    return () => {
      componentsStore.resetData();
      componentsStore.setActiveStep(0);
      () => buildingProductStore.resetData();
    };
  }, []);

  function recallState(lastState: { step: number; data: CustomComponent }) {
    componentsStore.setActiveStep(lastState.step);
    componentsStore.setData(lastState.data);
    localStorage.removeItem(COMPONENT_DATA_LOCAL_KEY);
    if (componentsStore.onTheFlyProductAdded) {
      componentsStore.addChildItem(componentsStore.onTheFlyProductAdded);
      componentsStore.setOnTheFlyProductAdded(undefined);
    }
  }

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      default:
        return null;
    }
  };

  function loadDataForUpdate() {
    const currentItem: ProductSearchOutput | undefined =
      searchStore.searchResults.items.find(
        (item) => item.id === location.state.id
      );
    if (!currentItem) return;
    componentsStore.setData({
      id: currentItem?.id,
      name: currentItem?.name,
      ifc_is_external: currentItem.is_external,
      ifc_is_loadbearing: currentItem.is_load_bearing,
      tags_automapper: currentItem.tags_automapper,
      reference_unit: getCurrentReferenceValue(
        currentItem.reference_unit.toUpperCase()
      ),
      childrenList: searchStore.componentDetails?.children,
      preuse_qualification:
        buildingProductStore.optionsLists.preuse_qualification.find(
          (item) => item.name === currentItem?.preuse_qualification
        )?.id,
      deconstructability_qualification:
        buildingProductStore.optionsLists.deconstructability_qualification.find(
          (item) =>
            item.name?.includes(
              currentItem?.deconstructability_qualification as string
            )
        )?.id,
      reusability_qualification:
        buildingProductStore.optionsLists.reusability_qualification.find(
          (item) => item.name === currentItem?.reusability_qualification
        )?.id,
      compliance: {
        compliant_certifications:
          searchStore.componentDetails?.compliance?.certifications,
        conformities: searchStore.componentDetails?.compliance?.conformities,
        dataset_sources:
          searchStore.componentDetails?.compliance?.dataset_sources,
      },
      component_din_category: `${currentItem?.din_category_number} ${currentItem?.din_category}`,
    } as CustomComponent);
  }

  return (
    <div className="flex w-full relative">
      <div className="pt-4 px-6 pb-10 relative overflow-y-scroll max-h-[calc(100vh-114px)] w-full">
        {isFeatureVisible("component_editor_circularity_step") ? (
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ backgroundColor: "transparent" }}
          >
            {steps.map((step) => (
              <Step key={step.title}>
                <StepLabel
                  color="black"
                  StepIconComponent={StepperCustomCircle}
                />
              </Step>
            ))}
          </Stepper>
        ) : null}
        <div
          className={clsx("overflow-y-scroll", {
            "overscroll-y-auto": activeStep === 1 || activeStep === 2,
          })}
        >
          {renderStepContent()}
        </div>
      </div>
      <ComponentEditorFooter />
    </div>
  );
};

export default observer(ComponentEditor);
