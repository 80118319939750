import React from "react";
import { ReactNode } from "react";
import clsx from "clsx";
import { ReactI18NextChild } from "react-i18next";

interface IProps {
  type?:
    | "primary"
    | "secondary"
    | "gray"
    | "danger"
    | "white"
    | "link"
    | "submit";
  disabled?: boolean;
  color?: "secondary" | "outline";
  className?: string;
  size?: "default" | "small";
  width?: "fit-container" | "fit-content";
  onClick?: (e: React.MouseEvent) => void;
  onSubmit?: (e: React.FormEvent) => void;
  children?: ReactNode | ReactI18NextChild;
  loading?: boolean;
  disabledClasses?: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  "data-required"?: boolean;
}

function makeColorClassNames(color?: string) {
  const result =
    color === "secondary"
      ? "bg-indigo-100 text-indigo-700 hover:bg-indigo-200"
      : color === "outline"
      ? "bg-transparent border text-indigo-600 border-indigo-600 hover:bg-indigo-600 hover:text-white"
      : "bg-indigo-600 text-white hover:bg-indigo-700"; // primary
  return result;
}
const ButtonAsDiv = React.forwardRef(
  (props: IProps, ref: React.Ref<HTMLDivElement>) => {
    const colorClass = makeColorClassNames(props.color);
    return (
      <div
        ref={ref}
        data-required={props["data-required"] ?? undefined}
        className={`font-medium cursor-pointer flex px-4 text-sm justify-center items-center tracking-tight rounded-md disabled:opacity-40 ${colorClass} h-default py-3 min-w-[8rem] ${
          props.className ?? ""
        }`}
        onClick={props.disabled ? undefined : props.onClick}
      >
        {props.leadingIcon && (
          <span
            className={clsx({
              "-ml-1 mr-3 h-5 w-5": props.size === "default",
              "-ml-0.5 mr-2 h-4 w-4": props.size === "small",
              "mr-auto ml-auto": !props.children,
            })}
          >
            {props.leadingIcon}
          </span>
        )}
        {props.children}
        {props.trailingIcon && (
          <span
            className={clsx({
              "ml-3 -mr-1 h-5 w-5": props.size === "default",
              "ml-2 -mr-0.5 h-4 w-4": props.size === "small",
              "mr-auto ml-auto": !props.children,
            })}
          >
            {props.trailingIcon}
          </span>
        )}
        {props.loading && <Loading />}
      </div>
    );
  }
);

ButtonAsDiv.displayName = "ButtonAsDiv";

export default ButtonAsDiv;

function Loading() {
  return (
    <svg
      className="animate-spin -mr-1 ml-3 h-4 w-4 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
}
