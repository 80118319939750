import React from "react";
import Button from "components/Button";
import TreeView, { TreeViewNode } from "components/TreeView";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { observer } from "mobx-react-lite";
import {
  ComponentDetailsByFileOut,
  ProductDisabledStatusEnum,
} from "api-client";
import { IconCube, IconStack2 } from "@tabler/icons-react";
import { getCurrentReferenceValue } from "../utils";
import { searchStore } from "store/IfcMapping/SearchStore";
import clsx from "clsx";

const ComponentOverview = ({
  details,
  hasWarnings,
}: {
  details?: ComponentDetailsByFileOut;
  hasWarnings?: boolean;
}) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const [treeData, setTreeData] = React.useState<TreeViewNode[]>([]);
  const [showOverview, setShowOverview] = React.useState(true);

  const createNodeElement = (item: ComponentDetailsByFileOut): TreeViewNode => {
    const ref_value = getCurrentReferenceValue(
      item.reference_unit.toUpperCase()
    );
    return {
      ...item,
      id: item.id,
      isOpen: false,
      activeNode: false,
      detail: isEng ? ref_value?.name : ref_value?.name_de,
      childrenNodes:
        item?.children?.map((rec: ComponentDetailsByFileOut) =>
          createNodeElement(rec)
        ) ?? [],
      icon:
        item.type === "PRODUCT" ? (
          <IconCube
            stroke={3}
            className="bg-teal-300 rounded-full p-1 w-5 h-5 mr-2 text-teal-900"
          />
        ) : (
          <IconStack2
            stroke={3}
            className="bg-cyan-300 rounded-full p-1 w-5 h-5 mr-2 text-cyan-900"
          />
        ),
      hasAlert:
        !item.is_compliant_by_file ||
        item.product_disabled_status != ProductDisabledStatusEnum.UpToDate,
    };
  };

  React.useEffect(() => {
    const data = details?.children?.map((item) => createNodeElement(item));
    setTreeData(data as unknown as TreeViewNode[]);
  }, [details]);

  const onNodeClicked = (node: TreeViewNode) => {
    searchStore.setCurrentDetailNode(
      node as unknown as ComponentDetailsByFileOut
    );
    setShowOverview(false);
  };

  const onClickOverview = () => {
    searchStore.setCurrentDetailNode(details);
    setShowOverview(true);
  };

  return (
    <div
      className={clsx(
        "mx-auto overflow-y-auto min-w-[363px] max-w-[363px] bg-gray-50 p-6",
        "border-r border-gray-300 flex flex-col justify-between"
      )}
    >
      <div className="flex flex-col">
        <div className="text-lg font-semibold mb-4">
          {t("mapping.containedBuildingProducts")}
        </div>
        <Button
          type="gray"
          className="mb-4"
          onClick={onClickOverview}
          disable={showOverview}
        >
          {t("mapping.displayOverview")}
        </Button>
        {treeData && (
          <TreeView
            data={treeData}
            nodeClicked={onNodeClicked}
            highlightSelected={!showOverview}
          />
        )}
      </div>
      {hasWarnings || !details?.is_compliant_by_file ? (
        <Alert
          type="attention"
          description={
            <span className="text-yellow-700">
              {t("mapping.componentAlertGeneral")}
            </span>
          }
          className="!bg-transparent !border-none !p-0 mt-3"
          titleClassName="!w-[318px]"
        />
      ) : null}
    </div>
  );
};

export default observer(ComponentOverview);
