import {
  CheckIcon,
  RefreshIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import React from "react";
import { useTranslation } from "react-i18next";

export type StatusName =
  | "initial"
  | "updating"
  | "uploadingImages"
  | "receivingUpdates"
  | "noNetwork"
  | "error"
  | "missingFields"
  | "updated"
  | "restoring"
  | "restored";

export default function useSyncStatus() {
  const { t } = useTranslation();
  const statuses = React.useMemo(
    () => ({
      initial: {
        icon: <RefreshIcon width={16} />,
        bgColor: "bg-indigo-100",
        color: "text-indigo-700",
        text: <span className="ml-2">{t("audits.syncBar.update")}</span>,
      },
      updating: {
        icon: <RefreshIcon className="animate-reverse-spin" width={16} />,
        bgColor: "bg-indigo-100",
        color: "text-indigo-700",
        text: <span className="ml-2">{t("audits.syncBar.updating")}</span>,
      },
      restoring: {
        icon: <RefreshIcon className="animate-reverse-spin" width={16} />,
        bgColor: "bg-indigo-100",
        color: "text-indigo-700",
        text: <span className="ml-2">{t("audits.syncBar.restoring")}</span>,
      },
      uploadingImages: {
        icon: <RefreshIcon className="animate-reverse-spin" width={16} />,
        bgColor: "bg-indigo-100",
        color: "text-indigo-700",
        text: <span className="ml-2">{t("audits.syncBar.updating")}</span>,
      },
      receivingUpdates: {
        icon: <RefreshIcon className="animate-reverse-spin" width={16} />,
        bgColor: "bg-indigo-100",
        color: "text-indigo-700",
        text: <span className="ml-2">{t("audits.syncBar.updating")}</span>,
      },
      noNetwork: {
        icon: <ExclamationIcon width={16} />,
        bgColor: "bg-red-100",
        color: "text-red-700",
        text: <span className="ml-2">{t("audits.syncBar.noNetwork")}</span>,
      },
      error: {
        icon: <ExclamationIcon width={16} />,
        bgColor: "bg-red-100",
        color: "text-red-700",
        text: <span className="ml-2">{t("audits.errorOccurred")}</span>,
      },
      missingFields: {
        icon: <ExclamationIcon width={16} />,
        bgColor: "bg-red-100",
        color: "text-red-700",
        text: <span className="ml-2">{t("audits.syncBar.missingFields")}</span>,
      },
      updated: {
        icon: <CheckIcon width={16} />,
        bgColor: "bg-green-100",
        color: "text-green-700",
        text: <span className="ml-2">{t("audits.syncBar.updated")}</span>,
      },
      restored: {
        icon: <CheckIcon width={16} />,
        bgColor: "bg-green-100",
        color: "text-green-700",
        text: <span className="ml-2">{t("audits.syncBar.restored")}</span>,
      },
    }),
    []
  );
  const [status, setStatus] = React.useState<keyof typeof statuses>("initial");
  return { status, setStatus, statuses };
}
