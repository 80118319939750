import React from "react";
import { SetterOrUpdater, useSetRecoilState } from "recoil";
import ButtonAsDiv from "components/ButtonAsDiv";
import { ProductGroupOUT } from "api-client";
import { useTranslation } from "react-i18next";
import { selectedComponentAtom } from "store/atoms/audits";

interface ComponentListItemProps {
  componentProductGroup: ProductGroupOUT;
  isOptional?: boolean;
  setStep: SetterOrUpdater<number>;
  setSelectedComponentProductGroup: SetterOrUpdater<ProductGroupOUT | null>;
}

export default function ComponentListItem({
  isOptional,
  componentProductGroup,
  setStep,
  setSelectedComponentProductGroup,
}: ComponentListItemProps) {
  const setSelectedComponent = useSetRecoilState(selectedComponentAtom);
  const { i18n } = useTranslation();

  const handleAddComponentRouting = React.useCallback(() => {
    setStep(1);
    setSelectedComponentProductGroup(componentProductGroup);
    setSelectedComponent(null);
  }, [componentProductGroup.id]);

  return (
    <div className="grid gap-2">
      <ButtonAsDiv
        data-required={true}
        onClick={handleAddComponentRouting}
        color={"outline"}
        className={`mb-2 !justify-start rounded-md col-start-1 col-end-13  ${
          isOptional ? "text-gray-500 !border-gray-300" : ""
        }`}
      >
        <span className="mr-2 text-xl -translate-y-[1px]">+ </span>
        {(i18n.language === "en"
          ? componentProductGroup.name
          : componentProductGroup.name_de) ?? componentProductGroup.name}{" "}
        {isOptional ? null : (
          <span className="ml-1 text-red-700 text-lg">*</span>
        )}
      </ButtonAsDiv>
    </div>
  );
}
