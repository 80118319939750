import React from "react";
import { useTranslation } from "react-i18next";
import { CogIcon } from "@heroicons/react/outline";
import { classNames } from "utils";
import UpTray from "assets/images/icons/UpTray.svg";
import { useAuth0 } from "@auth0/auth0-react";
import useSignout from "hooks/useSignout";
import ActionMenu from "components/ActionMenu";
import ConfirmationPopup from "features/Audit/ConfirmationPopup";
import { userStore } from "store/UserStore";

const ProfileMenu = ({
  openSettings,
  onboarding,
}: {
  openSettings?: () => void;
  onboarding?: boolean;
}) => {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const { userProfile } = userStore;
  const signOut = useSignout();

  const menuItems = [
    <div key={0} className="flex">
      <CogIcon width={15} className="mr-3" />
      {t("commons.settings")}
    </div>,
    <div key={1} className="flex">
      <img src={UpTray} className="rotate-90 mr-3" width={15} />
      {t("commons.logout")}
    </div>,
  ];

  const actions = openSettings ? [() => openSettings(), () => signOut()] : [];

  const [open, setOpen] = React.useState(false);

  return (
    <>
      <ConfirmationPopup
        onPressYes={signOut}
        open={open}
        setOpen={setOpen}
        title={t("commons.logout")}
      />
      {onboarding ? (
        <button
          className={classNames(
            "w-full text-left px-4 py-2 text-sm flex items-center"
          )}
          onClick={() => setOpen(true)}
          test-id="logout_button"
        >
          <img src={UpTray} className="rotate-90 mr-3" width={15} />
          {t("commons.logout")}
        </button>
      ) : (
        <div className="flex justify-between relative items-center text-left w-full">
          <div className="flex items-center">
            <span className="inline-flex items-center justify-center w-7 h-7 rounded-full bg-gray-500">
              <span className="font-medium leading-none text-white text-xs">
                {`${userProfile?.first_name
                  ?.toUpperCase()
                  .charAt(0)}${userProfile?.last_name
                  ?.toUpperCase()
                  .charAt(0)}`}
              </span>
            </span>
            <span className="pl-2">
              {userProfile ? userProfile.first_name : user?.nickname}
            </span>
          </div>
          <ActionMenu
            items={menuItems}
            onSelect={(index) =>
              index === 1 ? setOpen(true) : actions[index]()
            }
            direction="top"
          ></ActionMenu>
        </div>
      )}
    </>
  );
};

export default ProfileMenu;
