import React from "react";
import { useTranslation } from "react-i18next";
import {
  IconEye,
  IconGripVertical,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import clsx from "clsx";
import SimpleSelect from "components/SimpleSelect";
import { EMPTY_SORT_ROW, SORT_OPTIONS } from "../../const";
import { PropertyBrowserTreeNode } from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { observer } from "mobx-react-lite";
import { SortProps } from ".";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import SimpleColumnsMenu from "./SimpleExistingMenu";
import LinearLoading from "components/LinearLoading";

const wrapperID = "sort-wrapper";

function SortMenu({
  cursorPosition,
  items,
  onChangeProperty,
}: {
  cursorPosition: { x: number; y: number };
  items: PropertyBrowserTreeNode[];
  onChangeProperty: (value: PropertyBrowserTreeNode, index: number) => void;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollPos, setScrollPos] = React.useState<number>(0);

  const onChangeSortOption = (value: string | undefined, index: number) => {
    const rows = [...dynamicMEStore.selectedSortedByItems];
    rows[index].sort = String(value);
    dynamicMEStore.setSelectedSortedByItems([...rows]);
  };

  const addRow = () => {
    const rows = [
      ...dynamicMEStore.selectedSortedByItems,
      {
        ...EMPTY_SORT_ROW,
        id: String(dynamicMEStore.selectedSortedByItems.length),
      },
    ];
    dynamicMEStore.setSelectedSortedByItems(rows);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeCurrentRow = (index: number) => {
    if (dynamicMEStore.selectedSortedByItems.length === 1) {
      dynamicMEStore.removeAllSortingRules();
    } else {
      const rows = [...dynamicMEStore.selectedSortedByItems];
      rows.splice(index, 1);
      dynamicMEStore.setSelectedSortedByItems([...rows]);
    }
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(dynamicMEStore.selectedSortedByItems);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, reorderedItem);
    dynamicMEStore.setSelectedSortedByItems(
      reorderedItems as unknown as SortProps[]
    );
  };

  const onScrollDiv = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const containerElement = e.target as HTMLDivElement;
    setScrollPos(containerElement.scrollTop);
  };

  const sortRow = (row: SortProps, index: number) => {
    return (
      <Draggable key={row?.id} draggableId={row?.id} index={index}>
        {(provided) => (
          <li
            className={clsx(
              "flex items-center p-2 gap-2 min-w-[400px] hover:bg-indigo-100",
              "transition group/sortItem relative"
            )}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{ ...provided.draggableProps.style }}
          >
            <IconGripVertical className="group-hover/sortItem:text-indigo-700" />
            <SimpleColumnsMenu<PropertyBrowserTreeNode>
              isEng={isEng}
              handleSelect={(value) => onChangeProperty(value, index)}
              items={items}
              current={row?.property}
              containerClassName="ml-1 relative"
              wrapperID={wrapperID}
              leftOffset={45}
              parentScrollTop={scrollPos}
              scrollAreaHeight={420}
              placeholder={t("mappingEditor.findProperty")}
            />
            <SimpleSelect
              options={SORT_OPTIONS}
              noMargin
              className="!mb-0 !mt-0"
              onChange={(value) => onChangeSortOption(value, index)}
              current={row?.sort}
            />
            <IconTrash
              className={clsx(
                "cursor-pointer ml-2 min-w-[20px]group-hover/sortItem:text-indigo-700"
              )}
              size={20}
              onClick={() => removeCurrentRow(index)}
            />
          </li>
        )}
      </Draggable>
    );
  };

  const rows = dynamicMEStore.selectedSortedByItems;
  const hasAnyPropertySet =
    rows.map((e) => e.property).filter(Boolean).length > 0;

  return (
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
      }}
      onClick={(e) => e.stopPropagation()}
      id={wrapperID}
    >
      <div className="flex items-center px-4 py-1 border-b rounded-t-md border-gray-300 bg-gray-50 text-xs text-gray-500">
        <IconEye size={19} className="mr-2" stroke={2.5} />
        {`${t("mappingEditor.sorted")} ${t("mappingEditor.by")}`}
      </div>
      {dynamicMEStore.dataFetchLoading && (
        <LinearLoading type="auto" containerClassName="top-7 h-1" />
      )}
      <div
        className={clsx("pt-1.5 max-h-[325px]", {
          "overflow-y-auto": rows.length > 6,
        })}
        ref={scrollContainerRef}
        onScroll={onScrollDiv}
      >
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <ul
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{ listStyle: "none", padding: 0 }}
              >
                {rows.map((row, index) => sortRow(row, index))}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="h-1.5" ref={scrollAreaRef}></div>
      </div>
      <div className="border-t border-gray-200 transition text-sm">
        <div
          className="flex px-4 py-2 items-center cursor-pointer text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group"
          onClick={addRow}
        >
          <IconPlus className="mr-2" size={20} />
          <span>{t("mappingEditor.addSortingProperty")}</span>
        </div>
        <div
          className={clsx(
            "flex px-4 py-2 items-center cursor-pointer",
            hasAnyPropertySet
              ? "text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group"
              : "pointer-events-none cursor-not-allowed text-gray-300"
          )}
          onClick={() => dynamicMEStore.removeAllSortingRules()}
        >
          <IconTrash className="mr-2" size={20} />
          <span>{t("mappingEditor.deleteSorting")}</span>
        </div>
      </div>
    </div>
  );
}

export default observer(SortMenu);
