import React from "react";
import Button from "components/Button";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";
import { observer } from "mobx-react-lite";
import useToast from "hooks/useToast";
import { AxiosError } from "axios";
import { ComponentDetailsByFileOut, ResultOUT, SingleItem } from "api-client";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { useMappingTools } from "../useMappingTools";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import { IconCube } from "@tabler/icons-react";
import { searchStore } from "store/IfcMapping/SearchStore";
import Tooltip from "components/Tooltip";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";

export default observer(function ProductEditorHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const toast = useToast();
  const { ifc_id } = useParams();
  const { mappingMatch, onCloseEditor } = useMappingTools();
  const isDME = location.pathname.includes("mapping-editor");

  async function createProduct(
    doAdd: boolean | undefined,
    doMatch: boolean | undefined
  ) {
    const createdItem = await buildingProductStore.registerCustomProduct();
    if (doAdd && createdItem) {
      componentsStore.setOnTheFlyProductAdded(
        createdItem as ComponentDetailsByFileOut
      );
    }
    if (doMatch && createdItem?.id) {
      isDME
        ? await dynamicMEStore.runSelectedObjectOperations(
            ifc_id,
            "manual_match",
            createdItem.id,
            dynamicMEStore.currentRow?.original as SingleItem
          )
        : await mappingMatch(createdItem.id);
    }
    toast(t("productEditor.successMsgOnSave"), "success");
  }

  async function updateProduct(
    doAdd: boolean | undefined,
    doMatch: boolean | undefined
  ) {
    const response = await buildingProductStore.updateCustomProduct();
    if (doAdd && response) {
      componentsStore.setOnTheFlyProductAdded(
        response.data as ComponentDetailsByFileOut
      );
    }
    if (doMatch) {
      const productID = response.data.id;
      isDME
        ? await dynamicMEStore.runSelectedObjectOperations(
            ifc_id,
            "manual_match",
            productID,
            dynamicMEStore.currentRow?.original as SingleItem
          )
        : productID && (await mappingMatch(productID));
    }
    if (response.data.is_recalculation_triggered) {
      toast(t("productEditor.successMsgOnEditWithRecalculation"), "success");
    } else {
      toast(t("productEditor.successMsgOnEdit"), "success");
    }
  }

  const saveAndClose = async (doAdd?: boolean, doMatch?: boolean) => {
    try {
      if (location.state?.mode === "edit") {
        await updateProduct(doAdd, doMatch);
      } else {
        await createProduct(doAdd, doMatch);
      }
      searchStore.setComponentDetails(undefined);
      buildingProductStore.resetData();
      onCloseEditor(1, doAdd || doMatch);
    } catch (error) {
      toast(
        `${t("productEditor.warningMsgOnSave")} ${
          (
            ((error as AxiosError)?.response?.data as ResultOUT)
              ?.message as ResultOUT
          )?.message ?? error
        }`,
        "warning"
      );
    }
  };

  const productName = buildingProductStore.data.name ?? "";

  return (
    <div className="sm:flex w-full bg-white px-4 py-2 items-center justify-between border-b border-gray-300 bg-gradient-to-r from-teal-50">
      <div id="editor-title" className="sm:flex mb-3 sm:mb-0">
        <div className="flex justify-between items-center">
          <IconCube
            className="bg-teal-300 w-8 h-8 rounded-full p-1.5 mr-3"
            stroke={2}
          />
          <div className="text-xl font-semibold text-black mr-6 mb-2 sm:mb-0">
            {t("productEditor.buildingProductEditor")}
          </div>
        </div>
      </div>
      <div className="flex items-center">
        {productName ? (
          <Tooltip
            className="text-base font-semibold leading-7 max-w-[500px]"
            truncate
          >
            {productName}
          </Tooltip>
        ) : null}
        <Badge type="info" className="ml-2 mb-2 sm:mb-0">
          {location.state?.mode
            ? t(`mapping.${location.state?.mode}`)
            : t("mapping.createNew")}
        </Badge>
      </div>
      <div className="flex">
        <Button
          width="fit-content"
          className="block mr-3"
          type="gray"
          disable={
            mappingEditorStore.mappingMatchLoading ||
            dynamicMEStore.showMappingLoading
          }
          onClick={() => navigate(-1)}
        >
          {t("commons.cancel")}
        </Button>
        <Button
          width="fit-content"
          className=" block"
          type="secondary"
          onClick={() => saveAndClose()}
          disable={
            buildingProductStore.thirdStepValidionErrors ||
            mappingEditorStore.mappingMatchLoading ||
            dynamicMEStore.showMappingLoading
          }
        >
          {t("commons.save")}
        </Button>
        {componentsStore.openedSearchInCE ? (
          <Button
            width="fit-content"
            className="block ml-3"
            type="primary"
            onClick={() => saveAndClose(true)}
            disable={buildingProductStore.thirdStepValidionErrors}
          >
            {t("productEditor.saveAndAdd")}
          </Button>
        ) : null}
        {!componentsStore.openedSearchInCE ? (
          <Button
            width="fit-content"
            className="block ml-3"
            type="primary"
            loading={
              mappingEditorStore.mappingMatchLoading ||
              dynamicMEStore.showMappingLoading
            }
            onClick={() => saveAndClose(false, true)}
            disable={
              buildingProductStore.thirdStepValidionErrors ||
              mappingEditorStore.mappingMatchLoading ||
              dynamicMEStore.showMappingLoading
            }
          >
            {t("productEditor.saveAndMapp")}
          </Button>
        ) : null}
      </div>
    </div>
  );
});
