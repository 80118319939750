import { BannerProvider, useBanner } from "./context";
import Portal from "./components/Portal";
import CookiePreferences from "./components/Preferences";
import CookieDialog from "./components/Dialog";
import { BannerProps } from "./types";
import Actions from "./components/Actions";
import React from "react";

export const LocalStorageKey = "cookiebanner_consented";

export const hasConsented = () => {
  return localStorage.getItem(LocalStorageKey) !== null;
};

export default function CookieBanner(props: BannerProps) {
  return (
    <BannerProvider
      texts={props.texts}
      preferences={props.preferences}
      onConsent={props.onConsent}
    >
      <BannerApp />
    </BannerProvider>
  );
}

function BannerApp() {
  const { prefsOpened, alertTriggered, texts, consented } = useBanner();
  if (consented) return null;
  return (
    <>
      <Portal
        id="cookies-banner"
        className="fixed z-30 bottom-0 bg-white p-8 lg:px-32 lg:flex"
      >
        <div className="text-slate-600 text-sm text-center lg:w-3/4 lg:text-left">
          {texts.banner}
          <a
            className="text-indigo-500 text-semibold"
            href={texts.privacyPageLink}
            target="_blank"
            rel="noreferrer"
          >
            {texts.privacyPageName}
          </a>
        </div>
        <Actions />
      </Portal>
      {prefsOpened ? <CookiePreferences /> : null}
      {alertTriggered ? <CookieDialog /> : null}
    </>
  );
}
