import { useBanner } from "../context";
import CookieButton from "./Button";
import React from "react";

export default function Actions() {
  const { togglePreferences, acceptAll, texts } = useBanner();
  return (
    <div className="flex justify-between mt-6 lg:mt-0 lg:ml-6 lg:w-1/3 items-center">
      <CookieButton
        onClick={togglePreferences}
        className="w-full"
        role="secondary"
      >
        {texts.manage}
      </CookieButton>
      <CookieButton
        onClick={acceptAll}
        className="ml-4 w-full"
        testID="acceptallcookie_button"
      >
        {texts.acceptAll}
      </CookieButton>
    </div>
  );
}
