import React from "react";
import clsx from "clsx";
import { PlaceDetailsOUT } from "../api-client";
import { db } from "api-client-local/db";
import { StepIconProps } from "@material-ui/core";

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const REQUIREMENTS_URL =
  "https://concular.notion.site/Ifc-Model-Requirements-27ac3a4b5a1d469c8b9657d84ffced68";

export const IFC_ENTITIES_URL =
  "https://www.notion.so/concular/Werden-bestimmte-IFC-Entit-ten-beim-Import-vom-System-ignoriert-a14fcb3ea9564e09967115016f18f2c0?pvs=4";

export const CERT_EXPORT_ALERT_URL =
  "https://concular.notion.site/kobilanzierung-f-r-Zertifizierungen-0fac08a5324b495b853952a493308b95?pvs=4";

export const SUPPORTING_DOCS =
  "https://www.qng.info/app/uploads/2023/07/QNG_Handbuch_Anlage-1-Siegelvarianten_v1-4.pdf";

export const CIRCULARITY_GUIDE_URL =
  "https://concular.notion.site/Circularity-Performance-Index-CPX-8aa764b1b29344eabf96466f68c23ed8";

export const BNB_DOCUMENT =
  "https://www.nachhaltigesbauen.de/fileadmin/pdf/Nutzungsdauer_Bauteile/BNB_Nutzungsdauern_von_Bauteilen_2017-02-24.pdf";

export const CIRCULARITY_DOCUMENT_URL =
  "https://concular.notion.site/Circularity-Performance-Index-CPX-8aa764b1b29344eabf96466f68c23ed8?pvs=4";

export const RESIDENTIAL_BUILDING_FILE =
  "https://concular-retool-assets.s3.eu-central-1.amazonaws.com/concore/b6_operational_energy/Betriebsenergie%2BBerechnung_Wohngeba%CC%88ude.xlsx";

export const NON_RESIDENTIAL_BUILDING_FILE =
  "https://concular-retool-assets.s3.eu-central-1.amazonaws.com/concore/b6_operational_energy/Betriebsenergie%2BBerechnung_nichtwohngeba%CC%88ude.xlsx";

export const PORTFOLIO_URL = "portfolio-dashboard";
export const URBAN_MINING_HUB_URL = "urban-mining-hub";

export const placeDetailsToString = (item: PlaceDetailsOUT) => {
  return item.street ||
    item.latitude ||
    item.longitude ||
    item.country_code ||
    item.place_id
    ? `${item.street ?? ""} ${item.house_number ?? ""} ${
        item.postal_code ?? ""
      } ${item.region ?? ""} ${item.country_code ?? ""}`.trim()
    : "";
};

export function omit<T>(key: keyof T, obj: T) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: omitted, ...rest } = obj;
  return rest;
}

export const isMobileSize = () => window.innerWidth <= 640;
export const isTabletSize = () => window.innerWidth <= 768;

export function handleSmoothTopScroll() {
  const timer = setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    clearTimeout(timer);
  }, 250);
}

export function getItemIDFromUrl(itemPath: string) {
  const regexp = new RegExp(`${itemPath}/(.*)`);
  const matches = window.location.href.match(regexp);
  if (!matches) return null;
  const value = matches.pop();
  if (!value) return null;
  let id = value;
  if (value.includes("/")) {
    id = value.split("/")[0];
  }
  return hasUUIDPattern(id) ? id : null;
}

export function isISODate(str: string) {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return (
    d instanceof Date &&
    !isNaN(d as unknown as number) &&
    d.toISOString() === str
  );
}

export function isNumeric(val: unknown): boolean {
  return (
    !Array.isArray(val) && (val as number) - parseFloat(val as string) + 1 >= 0
  );
}

export function downloadJSON(jsonData: unknown, filename: string) {
  const dataString =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(jsonData));
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataString);
  downloadAnchorNode.setAttribute("download", filename + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export function slugify(str: string | number, separator = "-") {
  return str
    .toString()
    .normalize("NFD")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, separator);
}

export function hasUUIDPattern(val: unknown): boolean {
  if (!val) false;
  const regexp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
  return regexp.test(val as string);
}

export async function convertFileToImageSource(
  file: File
): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      const source = e.target && e.target.result ? e.target.result : null;
      return source ? resolve(source) : reject(source);
    };
  });
}

export default function checkEmailValidation(value: string) {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
}

export async function checkAndAddBlueprintId(blueprintId: string) {
  if (!blueprintId || blueprintId === "") {
    logResult("Blueprint Id not found, stop further execution");
    return;
  }

  const similarBPIdsCount = await db.blueprintIds
    .where("blueprintId")
    .equals(`${blueprintId}`)
    .count();

  if (similarBPIdsCount > 0) {
    logResult(`ID: ${blueprintId} Already exists`);
    //------------------------------------------------------------
    // Example for @Mauricio and @Zubair to fetch all Bp Ids,
    // and then send to the API etc.
    // const bpIds = await db.blueprintIds.toArray();
    // console.log("BP Ids:", bpIds);
    // ----------------------------------------------------------
    return;
  }
  const id = await db.blueprintIds.add({
    id: window.crypto.randomUUID(),
    blueprintId,
  });

  logResult(`Stored successfully with ID: ${id}`);
}

function logResult(result: string) {
  console.log("BlueprintID Local DB Result: ", result);
}

export function skeleton(className?: string) {
  return (
    <div className="animate-pulse">
      <div
        className={`h-80 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5 ${className}`}
      ></div>
    </div>
  );
}

export const StepperCustomCircle = (props: StepIconProps) => {
  return (
    <div
      className={clsx(
        "flex rounded-full w-8 h-8 justify-center items-center self-center",
        {
          "border border-indigo-600 text-indigo-700": props.active,
          "bg-indigo-600 border border-indigo-600 text-white": props.completed,
          "bg-gray-100 border border-gray-100 text-gray-500":
            !props.completed && !props.active,
        }
      )}
    >
      {props.icon}
    </div>
  );
};

export const unitPower = (unit: string) => {
  return (
    <span>
      {unit.endsWith("3") || unit.endsWith("2") ? (
        <>
          {unit.slice(0, unit.length - 1)}
          <sup>{unit[unit.length - 1]}</sup>
        </>
      ) : (
        unit
      )}
    </span>
  );
};

export const numberCustomFormat = (num: number) => {
  if (num === 0) {
    return "0.00";
  } else if (num < 0.01) {
    return num.toFixed(6).replace(/0+$/, "");
  } else {
    return num.toFixed(2);
  }
};

/**
 * Check the status if it's undefined or null and includes failed, return true
 */
export function isFailedStatus(status: string | null | undefined) {
  return status?.includes("failed") ?? false;
}

export function getModuleStringTotal(certification: string | undefined | null) {
  if (certification === "DGNB 2018") {
    return "moduleString_total_2018";
  } else {
    return "moduleString_total";
  }
}

export function getModuleStringB4(certification: string | undefined) {
  if (certification === "DGNB 2018") {
    return "moduleString_b4_2018";
  } else {
    return "moduleString_b4";
  }
}

export function roundNumber(num: number | string) {
  return Math.round((Number(num) + Number.EPSILON) * 100) / 100;
}
