import React from "react";

const useForm = (
  formData: React.ComponentState,
  updateBuildingDetails: React.SetStateAction<React.ComponentState>
) => {
  const handleChange = (
    value: string | number | undefined | File[] | Date,
    name?: string
  ) => {
    name &&
      updateBuildingDetails({
        ...formData,
        [name]: value,
      });
  };

  return {
    handleChange,
  };
};

export default useForm;
