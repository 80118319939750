import React from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EyeIcon, EyeOffIcon, PencilIcon } from "@heroicons/react/outline";
import FormLoadingContainer from "features/Audit/FormLoadingContainer";
import PingAnimation from "components/PingAnimation";
import { observer } from "mobx-react-lite";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { BlueprintInstanceList } from "./BlueprintInstanceList";
import ItemComponentListOnline from "features/AuditOnline/ItemComponentListOnline";
import { checkIfBlueprintIsComponent } from "features/AuditOnline/utils";
import BlueprintOverviewOnline from "features/AuditOnline/BlueprintOverviewOnline";

const BlueprintDetailOnline = () => {
  const { id, audit_id, blueprint_id } = useParams();
  const { t } = useTranslation();
  const [overviewHidden, setOverviewHidden] = React.useState(false);

  const blueprintRoute = `/buildings/${id}/audits-online/${audit_id}/blueprints/${blueprint_id}`;

  const {
    selectedBlueprint,
    selectedProductGroup,
    isComponent,
    shouldUpdateBlueprintList,
  } = caOnlineStore;

  const fetchBlueprintDetails = async () => {
    // fetch floors and rooms for instances before fetching the blueprint details
    caOnlineStore.setIsLoading(true);
    await caOnlineStore.fetchFloorSet();
    await caOnlineStore.fetchRoomSet();
    await caOnlineStore.fetchBlueprintDetails(blueprint_id);
    caOnlineStore.setIsLoading(false);
  };

  React.useEffect(() => {
    fetchBlueprintDetails();
  }, [blueprint_id, shouldUpdateBlueprintList]);

  return (
    <>
      {selectedBlueprint ? (
        <div className="mb-20">
          <FormLoadingContainer formLoading={""} />

          <div className="flex my-4 font-semibold">
            <button
              title={t("audits.showDetails")}
              onClick={() => setOverviewHidden(!overviewHidden)}
              className="text-gray-500 mr-2 h-7 flex items-center bg-gray-200 rounded-md px-2"
            >
              {overviewHidden ? (
                <EyeIcon width={18} />
              ) : (
                <EyeOffIcon width={18} />
              )}
              <span className="ml-2 text-xs">{t("audits.showDetails")}</span>
            </button>
            <Link
              onClick={() => {
                caOnlineStore.setIsComponent(
                  checkIfBlueprintIsComponent(selectedBlueprint)
                );
                caOnlineStore.setSelectedBPOrComponents(selectedBlueprint);
              }}
              className="text-xs px-2 h-7 flex items-center bg-indigo-100 rounded-md text-indigo-700 enabled:hover:bg-indigo-200"
              to={blueprintRoute}
            >
              <PencilIcon width={14} />
              <span className="ml-2 text-xs">{t("audits.editBlueprint")}</span>
            </Link>
          </div>

          <h1 className="text-base font-semibold">{selectedBlueprint.name}</h1>

          {!isComponent && <BlueprintInstanceList />}

          {selectedProductGroup && (
            <BlueprintOverviewOnline
              selectedBlueprint={selectedBlueprint}
              selectedProductGroup={selectedProductGroup}
              className={overviewHidden ? "hidden" : ""}
            />
          )}

          <ItemComponentListOnline />
        </div>
      ) : (
        <PingAnimation
          className="min-h-[50vh] flex flex-col justify-center"
          text={t("audits.loadingBlueprint")}
        />
      )}
    </>
  );
};

export default observer(BlueprintDetailOnline);
