import { useBanner } from "../context";
import React from "react";

export default function PreferencesHeader() {
  const { headers } = useBanner();
  const className = (i: number) =>
    `border border-slate-300 ${
      i === headers.length - 1 && headers.length > 3
        ? "hidden lg:table-cell"
        : ""
    }`;
  return (
    <thead>
      <tr className="align-top text-left bg-slate-200">
        {headers.map((header, i) => {
          return (
            <th key={i} className={className(i)}>
              {header}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
