import React from "react";
import PingAnimation from "components/PingAnimation";

export default function FormLoadingContainer(props: { formLoading: string }) {
  return (
    <div
      className={`${
        props.formLoading === "" ? "hidden" : "fixed flex"
      } bg-white z-10 top-0 left-0 opacity-50 h-full w-full flex-col justify-center`}
    >
      <PingAnimation color="gray" size="small" />
      <div className="mx-auto font-semibold">{props.formLoading}</div>
    </div>
  );
}
