import React from "react";
import { BlueprintOUT, ProductGroupOUT, SlimBlueprintOUT } from "api-client";
import ItemAmount from "features/Audit/ItemAmount";
import { Link } from "react-router-dom";
import { ItemImage } from "./ItemImage";
import { observer } from "mobx-react-lite";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";

interface ItemComponentProps {
  blueprint: SlimBlueprintOUT;
}

export const ComponentListItem = observer(
  ({ blueprint }: ItemComponentProps) => {
    const [pg, setPg] = React.useState<ProductGroupOUT>();
    const { productGroups } = caOnlineStore;

    React.useEffect(() => {
      setPg(
        productGroups
          ? productGroups.find(
              (p: ProductGroupOUT) => p.id === blueprint.product_group_id
            )
          : undefined
      );
    }, [productGroups]);

    return (
      <>
        {blueprint.components.map((c) => {
          const componentAmount = c.as_component_amount ?? 0;
          const instanceAmount =
            (blueprint.total_instance_amount ?? 0) * componentAmount;

          return (
            <div
              key={c.id}
              className="flex items-center justify-between w-full border-b px-8"
            >
              <Link
                onClick={() => {
                  caOnlineStore.setIsComponent(true);
                  caOnlineStore.setSelectedBPOrComponents(c as BlueprintOUT);
                }}
                to={`blueprints/${c.id}/instances`}
                className="flex py-3 items-center"
              >
                <ItemImage itemId={c.id} itemAlbums={c.albums} />
                <span className="text-sm w-full">{c.name}</span>
              </Link>
              <ItemAmount
                value={instanceAmount}
                unit={pg?.unit ?? "pcs"}
                componentAmount={componentAmount}
              />
            </div>
          );
        })}
      </>
    );
  }
);
