import Badge from "components/Badge";
import React from "react";
import { CollectionIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import DropdownCheckbox, { DropdownOption } from "components/DropdownCheckbox";
import { useParams } from "react-router";
import { useMappingTools } from "../useMappingTools";

export default observer(function GroupByFilter() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { groupByOptions } = useMappingTools();
  const { id: buildingID } = useParams();
  const [savedState, setSavedState] = React.useState<{
    [key: string]: string[];
  }>();

  React.useEffect(() => {
    setSavedState(
      JSON.parse(localStorage.getItem("ME_groupby") ?? "null") ?? undefined
    );
  }, []);

  React.useEffect(() => {
    setDefaultSelected();
  }, [savedState]);

  function setDefaultSelected() {
    if (
      savedState &&
      Object.keys(savedState).find((item) => item === buildingID)
    ) {
      const current = groupByOptions.filter(
        (item) =>
          savedState[buildingID as string]?.indexOf(String(item.id)) !== -1
      );
      onChangeGroupBy(current);
    } else {
      onChangeGroupBy([groupByOptions[2]]);
    }
  }

  const onChangeGroupBy = (values: DropdownOption[]) => {
    mappingEditorStore.setSelectedGroupByItems(values);
    if (values.length) {
      const selected = values.map((item) => String(item.id));
      saveSelectedState(selected);
      mappingEditorStore.setGroupBy(selected);
    }
  };

  function onRemoveGroupBy(removingItem: DropdownOption | undefined) {
    mappingEditorStore.setRemoveSelectedGroupByItem(removingItem);
    if (savedState && removingItem?.id) {
      const updatedList = savedState[buildingID as string]?.filter(
        (item: string) => item !== removingItem.id
      );
      saveSelectedState(updatedList);
    }
  }

  const handleRemoveSelectedGroup = React.useCallback(() => {
    mappingEditorStore.setRemoveSelectedGroupByItem(undefined);
  }, []);

  function saveSelectedState(selectedItems: string[]) {
    const updatedState = {
      ...savedState,
      [buildingID as string]: selectedItems,
    };
    localStorage.setItem("ME_groupby", JSON.stringify(updatedState));
  }

  return (
    <div>
      <div className="flex items-center mb-2">
        <CollectionIcon className="w-4 h-4 mr-1" />
        {t("mapping.groupedBy")}
      </div>
      <div className="contents sm:flex gap-1 text-sm max-w-full whitespace-nowrap items-end">
        {mappingEditorStore.selectedGroupByItems?.map((item) => (
          <Badge
            type="auto"
            className="mr-1 mb-1 sm:mb-0"
            size="middle"
            closable
            key={item.id}
            onClose={() => onRemoveGroupBy(item)}
          >
            {isEng ? item.name : item.name_de}
          </Badge>
        ))}

        {groupByOptions && (
          <DropdownCheckbox<DropdownOption>
            items={groupByOptions}
            displayKey={isEng ? "name" : "name_de"}
            height="middle"
            className="mt-0"
            required={true}
            name="groupBy"
            containerClassName="ml-1 w-[185px] mt-2 sm:mt-0"
            optionsClassName="z-40"
            labelWithSearch
            placeholder={t("mapping.searchForAttribute")}
            handleSelect={onChangeGroupBy}
            handleRemoveSelected={handleRemoveSelectedGroup}
            removeSelected={mappingEditorStore.removeSelectedGroupByItem}
            checkedItems={mappingEditorStore.selectedGroupByItems}
            noMargin
            disabled={mappingEditorStore.dataFetchLoading}
          />
        )}
      </div>
    </div>
  );
});
