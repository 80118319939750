import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import SimpleSelect from "components/SimpleSelect";
import { FormHelperLabel, StepHeading, valueFromOptions } from "./utils";
import {
  dgnb_area_concept_description,
  dgnb_area_division_possible,
  dgnb_area_concept_description_vorhanden,
  dgnb_area_expandable,
} from "features/Building/consts.d";
import { DGNBStore } from "store/DGNBStore";
import { observer } from "mobx-react-lite";

export type StepFlexibilityTypeProps = {
  type: string;
};

const StepFlexibility = () => {
  const { t } = useTranslation();
  const store = DGNBStore.getInstance();
  const { dgnbPassport } = store;

  const showAreaConceptDescriptionComment =
    dgnbPassport.area_concept_description &&
    dgnbPassport.area_concept_description ==
      dgnb_area_concept_description_vorhanden;

  return (
    <div className="w-full flex flex-col px-6">
      <StepHeading text={t("DGNB.flexibility_step_header")} />

      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            unit={"%-Anteil MF-G2/BGF"}
            min={0}
            max={100}
            labelName={t("DGNB.multiple_use_areas__label")}
            placeholder={t("DGNB.multiple_use_areas__placeholder")}
            type="number"
            name="multiple_use_areas"
            value={dgnbPassport.multiple_use_areas ?? ""}
            onChange={(e) => e.preventDefault()}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue("multiple_use_areas", `${value}`)
            }
            noMargin
          />
          <FormHelperLabel text={t("DGNB.multiple_use_areas__helper")} />
        </div>
        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            unit={"%-Anteil der NRF"}
            labelName={t("DGNB.repurposing_capability_area__label")}
            placeholder={t("DGNB.repurposing_capability_area__placeholder")}
            type="number"
            min={0}
            max={100}
            name="repurposing_capability_area"
            value={dgnbPassport.repurposing_capability_area ?? ""}
            onChange={(e) => e.preventDefault()}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue(
                "repurposing_capability_area",
                `${value}`
              )
            }
            noMargin
          />
          <FormHelperLabel
            text={t("DGNB.repurposing_capability_area__helper")}
          />
        </div>
        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.area_division_possible__label")}
            options={dgnb_area_division_possible}
            name="area_division_possible"
            placeholder={t("DGNB.area_division_possible__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "area_division_possible",
                dgnb_area_division_possible,
                value
              )
            }
            current={valueFromOptions(
              dgnb_area_division_possible,
              dgnbPassport.area_division_possible
            )}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.area_division_possible__helper")} />
        </div>

        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            labelName={t("DGNB.area_division_possible_source__label")}
            placeholder={t("DGNB.area_division_possible_source__placeholder")}
            type="text"
            name="area_division_possible_source"
            onChange={(e) => e.preventDefault()}
            value={dgnbPassport.area_division_possible_source ?? ""}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue(
                "area_division_possible_source",
                `${value}`
              )
            }
            noMargin
          />
          <FormHelperLabel
            text={t("DGNB.area_division_possible_source__helper")}
          />
        </div>
        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            unit="%-Anteil MF-G2/BGF"
            labelName={t("DGNB.area_utilization_ratio__label")}
            placeholder={t("DGNB.area_utilization_ratio__placeholder")}
            type="number"
            min={0}
            max={100}
            name="area_utilization_ratio"
            onChange={(e) => e.preventDefault()}
            value={dgnbPassport.area_utilization_ratio ?? ""}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue(
                "area_utilization_ratio",
                `${value}`
              )
            }
            noMargin
          />
          <FormHelperLabel text={t("DGNB.area_utilization_ratio__helper")} />
        </div>
        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            unit="m²/NE"
            labelName={t("DGNB.area_utility_need__label")}
            placeholder={t("DGNB.area_utility_need__placeholder")}
            type="number"
            name="area_utility_need"
            min={0}
            onChange={(e) => e.preventDefault()}
            value={dgnbPassport.area_utility_need ?? ""}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue("area_utility_need", `${value}`)
            }
            noMargin
          />
          <FormHelperLabel text={t("DGNB.area_utility_need__helper")} />
        </div>
        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.area_expandable__label")}
            options={dgnb_area_concept_description}
            name="area_expandable"
            placeholder={t("DGNB.area_expandable__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "area_expandable",
                dgnb_area_expandable,
                value
              )
            }
            current={valueFromOptions(
              dgnb_area_expandable,
              dgnbPassport.area_expandable
            )}
            noMargin
          />

          <FormHelperLabel text={t("DGNB.area_expandable__helper")} />
        </div>

        <div className="col-span-1">
          <TextInput
            containerClass="mb-0"
            labelName={t("DGNB.area_expandable_source__label")}
            placeholder={t("DGNB.area_expandable_source__placeholder")}
            type="text"
            name="area_expandable_source"
            onChange={(e) => e.preventDefault()}
            value={dgnbPassport.area_expandable_source ?? ""}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue(
                "area_expandable_source",
                `${value}`
              )
            }
            noMargin
          />
          <FormHelperLabel text={t("DGNB.area_expandable_source__helper")} />
        </div>

        <div className="col-span-2">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.area_concept_description__label")}
            options={dgnb_area_concept_description}
            name="area_concept_description"
            placeholder={t("DGNB.area_concept_description__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "area_concept_description",
                dgnb_area_concept_description,
                value
              )
            }
            current={valueFromOptions(
              dgnb_area_concept_description,
              dgnbPassport.area_concept_description
            )}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.area_concept_description__helper")} />
        </div>

        <div className="col-span-2">
          {showAreaConceptDescriptionComment && (
            <>
              <TextInput
                containerClass="mb-0"
                labelName={t("DGNB.area_concept_description_comment__label")}
                placeholder={t(
                  "DGNB.area_concept_description_comment__placeholder"
                )}
                type="text"
                name="area_concept_description_comment"
                onChange={(e) => e.preventDefault()}
                value={dgnbPassport.area_concept_description_explanation ?? ""}
                handleChange={(value) =>
                  store.setDGNBPassFieldAndValue(
                    "area_concept_description_explanation",
                    `${value}`
                  )
                }
                noMargin
              />
              <FormHelperLabel
                text={t("DGNB.area_concept_description_comment__helper")}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(StepFlexibility);
