import { SelectOption } from "components/SimpleSelect";
import Heading from "components/Heading";

export const FormHelperLabel = (props: { text: string }) => {
  return (
    <label className="font-[400] text-[14px] text-gray-500">{props.text}</label>
  );
};

export const valueFromOptions = (
  options: SelectOption[],
  value: string | null | undefined
) => {
  return options.find((item) => item.name === value)?.id ?? "";
};

export const StepHeading = (props: { text: string }) => {
  return (
    <Heading className="font-[600] text-[16px] mt-2" size="title">
      {props.text}
    </Heading>
  );
};
