import React from "react";
import LoadingIcon from "components/LoadingIcon";

interface LoadingProps {
  message: string;
}

export const LoadingView = ({ message }: LoadingProps) => {
  return (
    <div className="group flex flex-wrap items-center justify-center flex-col font-[500] text-[12px] text-gray-500">
      <LoadingIcon type="primary" className={`w-7 h-7 mb-4`} />
      <p className="text-center">{message}</p>
    </div>
  );
};
