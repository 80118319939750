import React from "react";
import { useSetRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";
import ComponentEditor from "features/MappingTools/ComponentEditor";
import ComponentEditorHeader from "features/MappingTools/ComponentEditor/ComponentEditorHeader";
import ComponentOverview from "features/MappingTools/ComponentEditor/ComponentOverview";
import EditorLayout from "layouts/EditorLayout";

export default function ComponentEditorPage() {
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);

  React.useEffect(() => {
    setSidebarOpened(false);
  }, []);

  return (
    <EditorLayout header={<ComponentEditorHeader />}>
      <ComponentEditor />
      <ComponentOverview />
    </EditorLayout>
  );
}
