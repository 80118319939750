import {
  IconChevronRight,
  IconChevronUp,
  IconReportSearch,
  IconSearch,
} from "@tabler/icons-react";
import { CellContext, ColumnDef, Row } from "@tanstack/react-table";
import { MaterialFileObject } from "store/IfcMapping/types";
import { CategoryMappingStatus } from "features/MappingTools/utils";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import useObjectsTable from "./useObjectsTable";
import Tooltip from "components/Tooltip";
import { getModuleStringTotal } from "utils";
import { buildingStore } from "store/Building/BuildingStore";

const GROUPING_COL_SIZE = 180;
const MIN_GROUPING_COL_SIZE = 100;
const INFO_COL_SIZE = 100;
const MIN_INFO_COL_SIZE = 80;

export default function useObjectsTableColumns(
  isParent: boolean,
  setOpenDetails: React.Dispatch<React.SetStateAction<boolean>>,
  setOpenProductSearch?: () => void,
  unmatch?: (row: Row<unknown>) => Promise<void>
) {
  const { t } = useTranslation();
  const { columnFilter, getStoredColumnSizes } = useObjectsTable();
  const savedSizes = getStoredColumnSizes();

  function hasChildren(info: CellContext<MaterialFileObject, unknown>) {
    return Number(info.row.original?.total_objects) > 1;
  }

  const openProductSearch = useCallback(() => {
    setOpenProductSearch?.();
  }, []);

  function materialCell(info: CellContext<MaterialFileObject, unknown>) {
    return (
      <div className="break-all">
        {(info.getValue() as string) === "nan" ||
        !(info.getValue() as string) ? (
          t("mapping.notDefined")
        ) : (
          <Tooltip truncate className="max-w-auto">
            {(info.getValue() as string) || "-"}
          </Tooltip>
        )}
      </div>
    );
  }

  function mappingCell(info: CellContext<MaterialFileObject, unknown>) {
    return (
      <div
        className="flex justify-between items-center min-h-[52px] cursor-pointer group"
        onClick={() => onClickMappingCell(info)}
        test-id={`manualmapping_row_${info.row.index}`}
      >
        <CategoryMappingStatus
          row={info.row as Row<unknown>}
          unmatch={unmatch}
        />
        <IconSearch className="w-5 transition stroke-gray-400 group-hover:stroke-gray-500" />
      </div>
    );
  }

  function onClickMappingCell(info: CellContext<MaterialFileObject, unknown>) {
    mappingEditorStore.setCurrentRow({
      parent: mappingEditorStore.currentRow?.parent,
      row: info.row as Row<unknown>,
    });
    !mappingEditorStore.openedDetailsPopup && openProductSearch();
  }

  function volumeCell(info: CellContext<MaterialFileObject, unknown>) {
    const volume = info.row.original.volume;
    return (
      <div className="">
        {volume !== null && volume !== undefined ? volume : "-"}
      </div>
    );
  }

  function areaCell(info: CellContext<MaterialFileObject, unknown>) {
    const area = info.row.original.area;
    return (
      <div className="">{area !== null && area !== undefined ? area : "-"}</div>
    );
  }

  function massCell(info: CellContext<MaterialFileObject, unknown>) {
    const mass = info.row.original.mass;
    return (
      <div className="">{mass !== null && mass !== undefined ? mass : "-"}</div>
    );
  }

  function co2Cell(info: CellContext<MaterialFileObject, unknown>) {
    const gwp = info.row.original.gwp;
    return (
      <div className="">{gwp !== null && gwp !== undefined ? gwp : "-"}</div>
    );
  }

  function heightWidthLengthCell(
    info: CellContext<MaterialFileObject, unknown>
  ) {
    const value = info.getValue();
    return (
      <div className="">
        {value !== null && value !== undefined ? `${value as number}` : "-"}
      </div>
    );
  }

  function ifcEntityCell(info: CellContext<MaterialFileObject, unknown>) {
    return (
      <div className="breck-all overflow-wrap-anywhere">
        <Tooltip truncate className="max-w-auto">
          {(info.row.original.ifc_entity as string) || t("mapping.notDefined")}
        </Tooltip>
      </div>
    );
  }

  function componentCell(info: CellContext<MaterialFileObject, unknown>) {
    return (
      <div className="text-sm breck-all overflow-wrap-anywhere">
        <Tooltip truncate className="max-w-auto">
          {(info.row.original.component as string) || "-"}
        </Tooltip>
      </div>
    );
  }

  function objectsCell(info: CellContext<MaterialFileObject, unknown>) {
    return hasChildren(info) ? (
      <div className="">{info.row.original?.total_objects}</div>
    ) : (
      <div
        className="text-indigo-600 cursor-pointer break-all"
        onClick={() => {
          if (
            info.row.original?.objects &&
            info.row.original?.objects?.count > 1
          ) {
            info.row.toggleExpanded();
          } else {
            mappingEditorStore.setCurrentRow({
              parent: mappingEditorStore.currentRow.parent,
              row: info.row as Row<unknown>,
            });
            setOpenDetails(true);
          }
        }}
      >
        <Tooltip truncate className="max-w-auto">
          {((info.row.original?.component as string) ?? "").trim() || (
            <IconReportSearch />
          )}
        </Tooltip>
      </div>
    );
  }

  function expanderRow(row: Row<MaterialFileObject>) {
    return (
      <div
        className="pl-0"
        onClick={() =>
          mappingEditorStore.setResetChildTable({
            ...mappingEditorStore.resetChildTable,
            [row.original.id as string]: row.getIsExpanded() ? false : true,
          })
        }
        test-id={`expander_row_${row.index}`}
      >
        {isCategoryExpandable() ? (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              style: { cursor: "pointer" },
            }}
            className="mt-1"
          >
            {row.getIsExpanded() ? (
              <IconChevronUp className="w-5 h-5" />
            ) : (
              <IconChevronRight className="w-5 h-5" />
            )}
          </button>
        ) : null}
      </div>
    );

    function isCategoryExpandable() {
      return (
        row.original?.total_objects &&
        row.original?.total_objects > 1 &&
        row.getCanExpand()
      );
    }
  }

  function getMinimumInfoColumnSize(key: string) {
    if (!savedSizes[key]) return INFO_COL_SIZE;
    if (savedSizes[key] < MIN_INFO_COL_SIZE) return MIN_INFO_COL_SIZE;
    return savedSizes[key];
  }

  function getMinimumGroupingColumnSize(key: string) {
    if (!savedSizes[key]) return GROUPING_COL_SIZE;
    if (savedSizes[key] < MIN_GROUPING_COL_SIZE) return MIN_GROUPING_COL_SIZE;
    return savedSizes[key];
  }

  const infoColumnsSettings = {
    defaultWidth: INFO_COL_SIZE,
    minWidth: MIN_INFO_COL_SIZE,
    enableColumnFilter: false,
    enableSorting: false,
  };

  const getGWPTooltipTotal = t("buildingInventory.modulesAlert", {
    moduleString: t(
      `commons.${getModuleStringTotal(
        buildingStore.getBuildingCertificationName()
      )}`
    ),
  });

  const columns = React.useMemo<ColumnDef<MaterialFileObject>[]>(
    () => [
      {
        accessorFn: (row) => row,
        id: "objects",
        size: getMinimumGroupingColumnSize("objects"),
        defaultWidth: GROUPING_COL_SIZE,
        minWidth: MIN_GROUPING_COL_SIZE,
        header: () => t("mapping.objects"),
        cell: (info) => objectsCell(info),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.component,
        id: "component",
        header: () => (
          <div className="whitespace-nowrap">{t("mapping.objectName")}</div>
        ),
        size: getMinimumGroupingColumnSize("component"),
        defaultWidth: GROUPING_COL_SIZE,
        minWidth: MIN_GROUPING_COL_SIZE,
        cell: (info) => componentCell(info),
        customFilter: columnFilter("component"),
      },
      {
        accessorFn: (row) => row.ifc_entity,
        id: "ifc_entity",
        size: getMinimumGroupingColumnSize("ifc_entity"),
        defaultWidth: GROUPING_COL_SIZE,
        minWidth: MIN_GROUPING_COL_SIZE,
        header: () => (
          <div className="whitespace-nowrap">{t("mapping.ifcClass")}</div>
        ),
        cell: (info) => ifcEntityCell(info),
        customFilter: columnFilter("ifc_entity"),
      },
      {
        accessorFn: (row) => row.material,
        id: "material",
        header: () => t("mapping.material"),
        size: getMinimumGroupingColumnSize("material"),
        defaultWidth: GROUPING_COL_SIZE,
        minWidth: MIN_GROUPING_COL_SIZE,
        cell: (info) => materialCell(info),
        customFilter: columnFilter("material"),
      },
      {
        accessorFn: (row) => row.length,
        id: "length",
        header: () => (
          <div>
            {t("mapping.length")}
            <br />
            <span className="text-xs text-gray-500">m</span>
          </div>
        ),
        cell: (info) => heightWidthLengthCell(info),
        size: getMinimumInfoColumnSize("length"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row.width,
        id: "width",
        header: () => (
          <div>
            {t("mapping.width")}
            <br />
            <span className="text-xs text-gray-500">m</span>
          </div>
        ),
        cell: (info) => heightWidthLengthCell(info),
        size: getMinimumInfoColumnSize("width"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row.height,
        id: "height",
        header: () => (
          <div>
            {t("mapping.height")}
            <br />
            <span className="text-xs text-gray-500">m</span>
          </div>
        ),
        cell: (info) => heightWidthLengthCell(info),
        size: getMinimumInfoColumnSize("height"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row.area,
        id: "area",
        header: () => (
          <div>
            {t("mapping.area")}
            <br />
            <span className="text-xs text-gray-500">m²</span>
          </div>
        ),
        cell: (info) => areaCell(info),
        size: getMinimumInfoColumnSize("area"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row.volume,
        id: "volume",
        header: () => (
          <div>
            {t("mapping.volume")}
            <br />
            <span className="text-xs text-gray-500">m³</span>
          </div>
        ),
        cell: (info) => volumeCell(info),
        size: getMinimumInfoColumnSize("volume"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row.gwp,
        id: "gwp",
        header: () => (
          <div>
            <div className="flex">
              GWP
              <Tooltip content={getGWPTooltipTotal}></Tooltip>
            </div>
            <span className="text-xs text-gray-500">kg CO2e</span>
          </div>
        ),
        cell: (info) => co2Cell(info),
        size: getMinimumInfoColumnSize("gwp"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row.mass,
        id: "mass",
        header: () => (
          <div>
            {t("mapping.mass")}
            <br />
            <span className="text-xs text-gray-500">kg</span>
          </div>
        ),
        cell: (info) => massCell(info),
        size: getMinimumInfoColumnSize("mass"),
        ...infoColumnsSettings,
      },
      {
        accessorFn: (row) => row,
        id: "mapping",
        header: () => t("mapping.objectMapping"),
        cell: (info) => mappingCell(info),
        enableColumnFilter: false,
        size: 300,
        minWidth: 300,
        maxWidth: 300,
        enableSorting: false,
        disableResizing: true,
      },
    ],
    []
  );

  const parentColumns = React.useMemo<ColumnDef<MaterialFileObject>[]>(
    () => [
      {
        id: "expander",
        size: 40,
        minSize: 40,
        maxSize: 40,
        disableResizing: true,
        cell: ({ row }) => expanderRow(row),
      },
      ...columns,
    ],
    []
  );

  if (isParent) return parentColumns;
  return columns;
}
