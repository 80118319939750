import {
  FieldOUT,
  ProductGroupOUT,
  repositoriesApi,
  SlimFieldOUT,
  SlimProductGroupFieldSetOUT,
  SlimProductGroupOUT,
} from "../api-client";
import { db } from "./db";

export interface AuthConfig {
  headers: {
    Authorization: string;
  };
}

async function getFieldsForSlimFields(
  slim_fields: SlimFieldOUT[]
): Promise<FieldOUT[]> {
  const fields: FieldOUT[] = [];

  if (!slim_fields) {
    return fields;
  }

  for (const slim_field of slim_fields) {
    const field = await db.fields.get(slim_field.id);
    if (field) {
      fields.push(field);
    }
  }
  return fields;
}

export async function convertSlimToFullProductGroup(
  slimProductGroup: SlimProductGroupOUT
) {
  const productGroup = slimProductGroup as ProductGroupOUT;

  // Repopulate the fields for each fieldset
  const fieldsets = (productGroup.blueprint_field_set ||
    []) as SlimProductGroupFieldSetOUT[];
  for (let index = 0; index < fieldsets.length; index++) {
    const fieldset = fieldsets[index];
    fieldset.optional_fields = await getFieldsForSlimFields(
      (fieldset.optional_fields || []) as SlimFieldOUT[]
    );
    fieldset.required_fields = await getFieldsForSlimFields(
      (fieldset.required_fields || []) as SlimFieldOUT[]
    );
  }

  // Repopulate the remaining fields with full fields
  productGroup.required_fields = await getFieldsForSlimFields(
    slimProductGroup.required_fields
  );
  productGroup.optional_fields = await getFieldsForSlimFields(
    slimProductGroup.optional_fields
  );

  if (slimProductGroup.required_components) {
    // Recurse through the components.
    productGroup.required_components = await Promise.all(
      slimProductGroup.required_components.map(async (component) => {
        return await convertSlimToFullProductGroup(component);
      })
    );
  }

  if (slimProductGroup.optional_components) {
    // Recurse through the components.
    productGroup.optional_components = await Promise.all(
      slimProductGroup.optional_components.map(async (component) => {
        return await convertSlimToFullProductGroup(component);
      })
    );
  }

  return productGroup;
}

export async function populateProductGroups(authHeader: AuthConfig) {
  console.log("started populateProductGroups");
  const slimProductGroupsResponse =
    await repositoriesApi.caApiV1RoutersRepositoryGetSlimProductGroups(
      authHeader
    );
  const productGroups = await Promise.all(
    slimProductGroupsResponse.data.map(async (slimProductGroup) => {
      return await convertSlimToFullProductGroup(slimProductGroup);
    })
  );
  await db.product_groups.clear();
  await db.product_groups.bulkAdd(productGroups);
  console.log("Populated product_groups.");
  return productGroups;
}

export async function populateManufacturers(authHeader: AuthConfig) {
  const manufacturersResponse =
    await repositoriesApi.caApiV1RoutersRepositoryGetManufacturers(authHeader);
  const manufacturers = manufacturersResponse.data;
  await db.manufacturers.clear();
  await db.manufacturers.bulkAdd(manufacturers);
  console.log("Populated manufacturers.");
  return manufacturers;
}

export async function populateFields(authHeader: AuthConfig) {
  const fieldsResponse =
    await repositoriesApi.caApiV1RoutersRepositoryGetFields(authHeader);
  const fields = fieldsResponse.data;
  await db.fields.clear();
  await db.fields.bulkAdd(fields);
  console.log("Populated fields.");
}
