import React from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { observer } from "mobx-react-lite";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import NotFound from "components/NotFound";
import useToast from "hooks/useToast";
import { handleAuditDelete } from "features/Audit/utils";
import ConfirmationPopup from "features/Audit/ConfirmationPopup";
import { useSetRecoilState } from "recoil";
import { auditsAtom, currentAuditAtom } from "store/atoms/audits";
import PingAnimation from "components/PingAnimation";

const AuditsOnline = () => {
  const { id: building_id, audit_id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const toast = useToast();

  const [open, setOpen] = React.useState(false);
  const [auditId, setAuditId] = React.useState("");
  const [auditTitle, setAuditTitle] = React.useState("");

  const setAudits = useSetRecoilState(auditsAtom);
  const setCurrentAudit = useSetRecoilState(currentAuditAtom);

  const shouldRenderOutlet =
    audit_id || (!audit_id && pathname.endsWith("audits-online/add"));

  React.useEffect(() => {
    (async () => {
      if (building_id) {
        caOnlineStore.setIsLoading(true);
        await caOnlineStore.fetchAudits(building_id);
        caOnlineStore.setIsLoading(false);
      }
    })();
  }, []);

  const { audits } = caOnlineStore;

  const deleteAudit = async () => {
    if (!auditId) {
      throw new Error("Audit Id not found for deletion");
    }
    if (!building_id) {
      throw new Error("Building id undefined");
    }
    setOpen(false);
    const result = await handleAuditDelete(auditId);
    const message = result
      ? t("audits.auditDeleteSuccess")
      : t("audits.auditDeleteFailed");
    toast(message, result ? "success" : "warning");
    clearAndNavigate();
  };

  const clearAndNavigate = () => {
    const filteredAudits = audits?.filter((audit) => audit.id !== auditId);
    if (filteredAudits && filteredAudits.length > 0) {
      caOnlineStore.setAudits(filteredAudits);
    } else {
      setAuditId("");
      setAuditTitle("");
      caOnlineStore.setCurrentAudit(caOnlineStore.initCurrentAudit());
      caOnlineStore.setAudits([]);

      setAudits(null);
      setCurrentAudit(null);
    }
  };

  return (
    <div className="p-4 relative">
      <ConfirmationPopup
        open={open}
        setOpen={setOpen}
        title={`${t("audits.auditDeleteConfirm")} - ${auditTitle}`}
        onPressYes={deleteAudit}
      />
      {shouldRenderOutlet ? (
        <Outlet />
      ) : caOnlineStore.isLoading ? (
        <div className="h-56 flex flex-col justify-center">
          <PingAnimation
            size="large"
            color="gray"
            text={t("audits.fetchingAudits")}
          />
        </div>
      ) : (
        <div className="pt-2">
          {audits.length === 0 ? (
            <NotFound noZIndex description={t("audits.noAuditsFound")} />
          ) : (
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-normal">
                {t("audits.audits")}
              </h1>
            </div>
          )}

          <div className="my-4">
            {audits.map((audit) => {
              return (
                <div key={audit.id} className="flex items-between w-full">
                  <Link
                    onClick={() => caOnlineStore.setCurrentAudit(audit)}
                    to={audit.id}
                    className="flex items-center w-full border-b py-2"
                  >
                    <AuditListItem auditTitle={audit.title} />
                  </Link>
                  <EditDeleteItemMenu
                    id={"props.item.id"}
                    onDelete={() => {
                      setAuditId(audit.id);
                      setAuditTitle(audit.title);
                      setOpen(true);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  auditTitle: string;
}

const AuditListItem = ({ auditTitle }: Props) => {
  return (
    <div className="flex w-[100%] items-center">
      <div className="relative rounded-md basis-9 flex items-center w-8 h-8 mr-2">
        <div className="bg-gray-200 h-full w-8 rounded-md" />
      </div>
      <span className="w-fit text-sm">{auditTitle}</span>
    </div>
  );
};

export default observer(AuditsOnline);
