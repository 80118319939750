import React, { useEffect, useState } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import CreateOrganisation from "./CreateOrganisation";
import { organisationAccessRequestApi } from "api-client";
import { PlusIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import moment from "moment";
import Badge from "components/Badge";
import Tooltip from "components/Tooltip";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

interface RequestedOrganisation {
  id: string;
  request: boolean;
  requestDate: string;
}

const JoinOrganisation = ({
  onboarding,
  close,
}: {
  onboarding?: boolean;
  close?: () => void;
}) => {
  const { t } = useTranslation();
  const { domainOrganisations, organisationTypes } = organisationStore;
  const { authHeader } = userStore;
  const [orgRequestStatus, setOrgRequestStatus] = useState<
    RequestedOrganisation[]
  >([]);
  const [showCreateOrg, setShowCreateOrg] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    !onboarding && organisationStore.getUserDomainRelatedOrganisations();
  }, []);

  const mapOrganisationsStatus = async () => {
    const organisationsStatus = domainOrganisations.map((item) => ({
      id: item.id,
      request: false,
      requestDate: "",
    }));
    if (!authHeader) return;
    await Promise.all(
      domainOrganisations.map(async (org, index) => {
        try {
          if (!org.id) throw new Error("No org id to fetch.");
          const response =
            await organisationAccessRequestApi.concularApiV1RoutersOrganisationAccessRequestCheckOrgAccessRequestStatus(
              org.id,
              authHeader
            );
          if (String(response.data.status) !== "1") {
            return;
          }
          organisationsStatus[index].request = true;
          organisationsStatus[index].requestDate = response.data.modified;
        } catch (error) {
          console.error(error);
        }
      })
    );
    setOrgRequestStatus(organisationsStatus);
  };

  const requestAccess = async (
    organisation: Partial<RequestedOrganisation>
  ) => {
    if (!organisation.id || !authHeader) {
      return;
    }
    try {
      await organisationAccessRequestApi.concularApiV1RoutersOrganisationAccessRequestRequestToJoinOrganisation(
        organisation.id,
        authHeader
      );
    } catch (error) {
      setShowError(true);
      console.log(error);
    }
    await mapOrganisationsStatus();
    setShowError(false);
  };

  useEffect(() => {
    domainOrganisations.length && mapOrganisationsStatus();
  }, [domainOrganisations]);

  return (
    <>
      {!domainOrganisations.length || showCreateOrg ? (
        <div
          className={clsx("rounded-md text-center w-full", {
            "border mb-20 pt-6 border-gray-300": onboarding,
          })}
        >
          <CreateOrganisation close={close} />
          {showCreateOrg && (
            <div className="w-full">
              <Button type="link" onClick={() => setShowCreateOrg(false)}>
                {t("onboarding.backToOnboarding")}
              </Button>
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            className={clsx(
              "mx-4 p-6 my-4 border border-gray-300 mb-10 rounded-md lg:w-[720px] max-w-[720px]",
              {
                "my-6 xs:my-14": onboarding,
              }
            )}
          >
            <div className="font-semibold text-xl mt-3 mb-7 text-center">
              {t("onboarding.joinOrganisation")}
            </div>
            <div className="border rounded-md border-gray-300 mx-8 max-h-54 overflow-y-scroll scrollbar">
              {domainOrganisations.map((organisation, index) => (
                <div
                  key={organisation.id}
                  className="flex justify-start items-center border-b last-of-type:border-b-0 border-gray-300 p-4"
                >
                  <div
                    id="organisation-logo"
                    className={clsx(
                      "rounded",
                      "text-white min-w-[32px] w-8 h-8 text-sm flex justify-center items-center mr-3",
                      { "bg-indigo-700": !organisation?.logo }
                    )}
                  >
                    {organisation?.logo ? (
                      <img width={32} src={organisation?.logo} />
                    ) : (
                      `${organisation.name?.substring(0, 1).toUpperCase()}`
                    )}
                  </div>
                  <div className="grow text-left">
                    <Tooltip truncate className="max-w-[100px]">
                      {organisation.name}
                    </Tooltip>
                  </div>
                  <div className="text-gray-400">
                    {
                      organisationTypes.find(
                        (item: { id: string }) => item.id === organisation.id
                      )?.name
                    }
                  </div>
                  <div className="flex justify-between items-center">
                    {orgRequestStatus?.length
                      ? orgRequestStatus[index]?.requestDate && (
                          <Badge type="attention" size="small" className="mr-3">
                            {`${t("onboarding.requestedOn")} ${moment(
                              orgRequestStatus[index].requestDate
                            ).format("DD.MM.YYYY")}`}
                          </Badge>
                        )
                      : null}
                    {organisation && orgRequestStatus.length ? (
                      orgRequestStatus[index].request === true ? (
                        <Button
                          leadingIcon={<PlusIcon />}
                          type="secondary"
                          width="fit-content"
                          onClick={async () =>
                            await requestAccess(organisation)
                          }
                          className="whitespace-nowrap"
                        >
                          {t("onboarding.resendRequest")}
                        </Button>
                      ) : (
                        <Button
                          leadingIcon={<PlusIcon />}
                          type="secondary"
                          width="fit-content"
                          onClick={async () =>
                            await requestAccess(organisation)
                          }
                          className="whitespace-nowrap"
                        >
                          {t("onboarding.requestAccess")}
                        </Button>
                      )
                    ) : null}
                  </div>
                </div>
              ))}
            </div>

            {showError && (
              <p className="text-red-700 font-light">
                {t("organisationErrorMessage")}
              </p>
            )}
            <div className="text-gray-500 text-sm pt-6 text-center">
              {t("onboarding.joinOrganisationMessage")}
            </div>
          </div>
          <div className="w-full text-center text-gray-500 font-semibold text-lg">
            {t("commons.or")}
          </div>
          <div
            className={clsx(
              "border border-gray-300 p-6 mt-8 mb-10 rounded-md mx-4",
              {
                "mx-36": onboarding,
              }
            )}
          >
            <div className="font-semibold text-xl mt-2 mb-6 text-center">
              {t("onboarding.createNewOrganisation")}
            </div>
            <Button
              type="secondary"
              size="default"
              width="fit-content"
              onClick={() => setShowCreateOrg(true)}
              className="mx-auto"
            >
              {t("onboarding.createOrganisation")}
            </Button>
            <p className="text-gray-500 text-sm pt-6 text-center">
              {t("onboarding.createOrganisationMessagePart1")}
              <br /> {t("onboarding.createOrganisationMessagePart2")}
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default observer(JoinOrganisation);
