import React from "react";
import InputLabel from "components/InputLabel";
import useSwitch from "hooks/useSwitch";
import useInvalidInput from "hooks/useInvalidInput";
import { CheckCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import useOutsideClick from "hooks/useOutsideClick";

interface TextEntrySelectOption {
  id: string;
  name: string;
  name_de?: string;
}

interface TextEntrySelectProps extends React.HTMLProps<HTMLInputElement> {
  options: TextEntrySelectOption[];
  labelName?: string;
  containerClassName?: string;
  onNewEntry?: (value: string) => void | Promise<void>;
}

export default function TextEntrySelect(props: TextEntrySelectProps) {
  const [showError, switchShowError] = useSwitch(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const optionsRef = React.useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const [writtenValue, setWrittenValue] = React.useState("");
  const [renderedOptions, setRenderedOptions] = React.useState<
    TextEntrySelectOption[]
  >([]);

  React.useEffect(() => {
    setRenderedOptions(props.options);
  }, [props.options]);

  useInvalidInput(inputRef, (isInvalid) => {
    if (isInvalid) {
      switchShowError.on();
    } else {
      switchShowError.off();
    }
  });

  function addValueToRenderedOptions(value: string) {
    props.onNewEntry && props.onNewEntry(value);
    return setRenderedOptions((prev) => {
      // If found previously, don't add.
      if (prev.find((v) => v.name === value)) {
        return prev;
      }
      return [...prev, { id: "temp", name: value }];
    });
  }

  const handleSelectNavigation = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Enter" || e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
      if (e.key === "Enter") {
        if (writtenValue === "") return;
        addValueToRenderedOptions(writtenValue);
        setShowOptions(false);
      }
      if (e.key === "ArrowUp") {
        console.log("pressed_arrowup");
      }
      if (e.key === "ArrowDown") {
        console.log("pressed_arrowdown");
      }
    },
    [writtenValue, renderedOptions]
  );

  React.useEffect(() => {
    if (!inputRef || !inputRef.current) return;
    inputRef.current.addEventListener("keydown", handleSelectNavigation);
    return () =>
      inputRef.current?.removeEventListener("keydown", handleSelectNavigation);
  }, [inputRef, writtenValue, renderedOptions]);

  function handleValue(e: React.MouseEvent) {
    e.preventDefault();
    const value = e.currentTarget.getAttribute("data-option-value");
    if (value === "") return;
    if (value && inputRef.current) {
      inputRef.current.value = value;
    }
  }

  useOutsideClick(optionsRef, () => {
    setShowOptions(false);
  });

  return (
    <div className={`mb-4 relative ${props.containerClassName ?? ""}`}>
      {props.labelName && (
        <InputLabel
          name={props.name as string}
          labelName={props.labelName}
          required={props.required}
          showError={showError}
        />
      )}
      <input
        onInvalid={() => switchShowError.on()}
        data-invalid="false"
        autoCorrect="false"
        autoComplete="off"
        defaultValue={props.defaultValue}
        required={props.required}
        type="text"
        className={`w-full text-sm py-1 items-center flex overflow-x-scroll pl-3 pr-10 mt-0.5 bg-white border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500 sm:text-sm h-middle overflow-hidden ${
          showError ? "border-red-700" : ""
        } ${props.className ?? ""}`}
        name={props.name}
        ref={inputRef}
        onClick={(e) => {
          switchShowError.off();
          if (e.target === e.currentTarget) {
            setShowOptions(true);
          }
        }}
        onFocus={() => {
          setShowOptions(true);
        }}
        onChange={(e) => {
          setShowOptions(true);
          setWrittenValue(e.target.value);
          console.log(e.target.value);
        }}
      />
      <div
        ref={optionsRef}
        className={`${
          showOptions && writtenValue !== "" ? "" : "hidden"
        } bg-white w-full border shadow-sm absolute sm:text-sm text-base left-0 mt-1 rounded-md py-2 top-14 overflow-y-scroll max-h-36 z-10`}
      >
        {writtenValue !== "" && (
          <div
            onClick={() => {
              addValueToRenderedOptions(writtenValue);
              setShowOptions(false);
            }}
            className={`py-1 flex items-center cursor-pointer px-2 text-sm bg-gray-100`}
          >
            <div className="h-4 w-4 mr-2">
              <PlusCircleIcon className="h-full text-indigo-400" />
            </div>
            <span className="h-5">{writtenValue}</span>
          </div>
        )}
        {[...renderedOptions]
          .sort((a, b) =>
            (isEng ? a.name : a.name_de ?? a.name).localeCompare(
              isEng ? b.name : b.name_de ?? b.name
            )
          )
          .filter((option) => {
            let nameString = isEng ? option.name : option.name_de;
            nameString = nameString ?? option.name ?? option.name_de;
            return (
              nameString.toLowerCase().includes(writtenValue.toLowerCase()) &&
              nameString.toLowerCase() !== writtenValue.toLowerCase()
            );
          })
          .map((option, i) => {
            let nameString = isEng ? option.name : option.name_de;
            nameString = nameString ?? option.name ?? option.name_de;
            return (
              <div
                key={i}
                onClick={handleValue}
                className={`py-1 flex items-center cursor-pointer px-2 text-sm hover:bg-gray-100`}
                data-option-id={option.id}
                data-option-value={nameString}
              >
                <div className="h-4 w-4 mr-2">
                  {option.id !== "temp" && (
                    <CheckCircleIcon className="h-full text-indigo-400" />
                  )}
                </div>
                <span>{nameString}</span>
              </div>
            );
          })}
      </div>
    </div>
  );
}
