import React from "react";
import Modal, { ModalProps } from "components/Modal";
import SelectFileType from "./SelectFileType";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { DownloadIcon } from "@heroicons/react/outline";
import LoadingIcon from "components/LoadingIcon";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";
import { Category, inventoryStore } from "store/InventoryStore";
import TableColumnSettings from "../CustomizeColumns/ColumnsStateSettings";
import { useInventory } from "../useInventory";

export default observer(function ExportInventory(props: ModalProps) {
  const { t } = useTranslation();
  const [fileType, setFileType] = React.useState<string>("");
  const [fileReady, setFileReady] = React.useState<boolean>(false);
  const [fileURL, setFileURL] = React.useState<string>();
  const { id: building_id } = useParams();
  const { getColumnsAndStates } = useInventory();
  const [columnsState, setColumnsState] = React.useState<Category[]>([]);
  const [exportList, setExportList] = React.useState<string[]>([]);

  React.useEffect(() => {
    inventoryStore.setBuildingId(building_id);
    if (!inventoryStore.columns.length)
      (async () => await getColumnsAndStates())();
  }, [props.open]);

  React.useEffect(() => {
    fileType && (async () => await downloadFile())();
  }, [fileType]);

  const downloadFile = async () => {
    const url = await inventoryStore.exportSummary(fileType, exportList);
    setFileURL(url);
    setFileReady(true);
  };

  function creatingFileTile() {
    return (
      <>
        <div className="pb-5">{t("buildingInventory.generatingFileText")}</div>
        <LoadingIcon className="w-24 h-24" />
      </>
    );
  }

  function downloadFilesTile() {
    return (
      <>
        <div className="pb-5">{t("buildingInventory.fileCreatedText")}</div>
        {fileURL ? (
          <a href={fileURL} target="_blank" rel="noreferrer" download>
            <Button width="fit-content" leadingIcon={<DownloadIcon />}>
              {t("buildingInventory.downloadList")}
            </Button>
          </a>
        ) : null}
      </>
    );
  }

  const getSelectedItems = () => {
    return columnsState
      .map((column) =>
        Object.entries(column.visibility)
          .filter((item) => item[1])
          .map((rec) => rec[0])
      )
      .flatMap((item) => item);
  };

  const atleastOneSelected = () => getSelectedItems().length > 1;

  const updateSelectedColumns = () => {
    setExportList(getSelectedItems());
  };

  function footerTile(): React.ReactNode {
    return exportList.length ? null : (
      <div className="w-full flex justify-end items-center text-gray-600">
        {atleastOneSelected() ? null : (
          <span className="text-sm font-normal pr-5">
            {t("buildingInventory.selectMinimumOne")}
          </span>
        )}
        <Button
          width="fit-content"
          onClick={updateSelectedColumns}
          disable={!atleastOneSelected()}
        >
          {t("commons.next")}
        </Button>
      </div>
    );
  }

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      title={t("buildingInventory.exportInventory")}
      closeButton
      containerClassName={exportList.length ? "min-w-[500px]" : "min-w-[980px]"}
      footer={footerTile()}
    >
      {exportList.length === 0 ? (
        <TableColumnSettings
          columnsState={columnsState}
          setColumnsState={setColumnsState}
          title={t("buildingInventory.exportInventoryTitle")}
        />
      ) : (
        <div className="p-6">
          {fileType ? (
            <div className="text-gray-500 text-xs flex flex-col items-center justify-items-center">
              {fileReady ? downloadFilesTile() : creatingFileTile()}
            </div>
          ) : (
            <SelectFileType setSelected={setFileType} />
          )}
        </div>
      )}
    </Modal>
  );
});
