import React from "react";

export default function useViewportZoomLock() {
  React.useEffect(() => {
    setViewportZoom(ZOOM_VALUE.LOCKED);
    return () => setViewportZoom(ZOOM_VALUE.INITIAL);
  }, []);
}

const viewportSelector = `meta[name='viewport']`;

enum ZOOM_VALUE {
  INITIAL = "width=device-width, initial-scale=1",
  LOCKED = "width=device-width, initial-scale=1, maximum-scale=1",
}

function setViewportZoom(value: string) {
  const viewport = document.querySelector(viewportSelector);
  if (!viewport) return;
  viewport.setAttribute("content", value);
}
