import React from "react";

interface FormProps<T> extends React.HTMLProps<HTMLFormElement> {
  handleSubmit?: (values: T) => Promise<void> | void;
}

export default function Form<FormDataType>(
  props: React.PropsWithChildren<FormProps<FormDataType>>
) {
  const formRef = React.useRef<HTMLFormElement>(null);

  async function handleSubmit(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!formRef.current) return;
    const values = getFormValues<FormDataType>(formRef.current);
    if (values && props.handleSubmit) {
      await props.handleSubmit(values as FormDataType);
    }
    props.onSubmit && props.onSubmit(e);
  }
  return (
    <form
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onInvalid={props.onInvalid}
      ref={formRef}
      onSubmit={handleSubmit}
      className={props.className}
    >
      {props.children}
    </form>
  );
}

function getFormValues<T>(formElement: HTMLFormElement) {
  const formData = new FormData(formElement);
  const formInputNames = Array.from(
    formElement.querySelectorAll("input,select,textarea")
  )
    .map((el) => el.getAttribute("name"))
    .filter((el) => el !== null) as string[];
  if (formInputNames.length === 0) return undefined;
  const result = formInputNames.reduce((acc: Record<string, unknown>, cur) => {
    acc[cur] = formData.get(cur);
    return acc;
  }, {});
  return result as T;
}
