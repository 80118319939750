export const UPCOMING_OPERATION_LIST = [
  { id: "1", name: "New building", name_de: "Neubau" },
  {
    id: "2",
    name: "Existing building (Renovation)",
    name_de: "Bestandserhalt (Sanierung)",
  },
  {
    id: "3",
    name: "Existing building (Conversion)",
    name_de: "Bestandserhalt (Umbau)",
  },
  {
    id: "4",
    name: "Existing building (Extension)",
    name_de: "Bestandserhalt (Erweiterung)",
  },
  { id: "5", name: "Deconstruction", name_de: "Rückbau" },
];

export const dgnb_type_of_operation = [
  { id: "1", name: "Bestand" },
  { id: "2", name: "Bestandserhalt (Sanierung)" },
  { id: "3", name: "Bestandserhalt (Umbau)" },
  { id: "4", name: "Bestandserhalt (Erweiterung)" },
  { id: "5", name: "Bestandserhalt >50% (Sanierung)" },
  { id: "6", name: "Bestandserhalt >50% (Umbau)" },
  { id: "7", name: "Bestandserhalt >50% (Erweiterung)" },
  { id: "8", name: "Neubau, kurzlebig (<20 Jahre)" },
  { id: "9", name: "Neubau, langlebig (>20 Jahre)" },
  { id: "10", name: "Weiteres / Gemischt" },
];

export const constructionTypes = [
  { id: "1", name: "Holz Massivbau" },
  { id: "2", name: "Holz Elementbau" },
  { id: "3", name: "Holz Fachwerkbau" },
  { id: "4", name: "Holz Tafel-/Rahmen-/Ständerbau" },
  { id: "5", name: "Holz Blockbau" },
  { id: "6", name: "Holz Gemischt-Bauweise" },
  { id: "7", name: "Mauerwerk Massivbau" },
  { id: "8", name: "Mauerwerk Kalksandstein" },
  { id: "9", name: "Mauerwerk Leichtbeton" },
  { id: "10", name: "Mauerwerk Porenbeton" },
  { id: "11", name: "Mauerwerk Planziegel" },
  { id: "12", name: "Stahl Elementbau" },
  { id: "13", name: "Stahl Leichtbau" },
  { id: "14", name: "Stahlbeton Massivbau" },
  { id: "15", name: "Stahlbeton Leichtbau" },
  { id: "16", name: "Stahlbeton Skelettbau" },
  { id: "17", name: "Stahlbeton Elementbau" },
  { id: "18", name: "Stahlbeton Verbundbau" },
  { id: "19", name: "Stahlbeton Raumtragwerk" },
  { id: "20", name: "Stahl-Stahlbeton-Hybridbau" },
  { id: "21", name: "Holz-Stahlbeton-Hybridbau" },
  { id: "22", name: "Holz-Beton-Verbundbau" },
  { id: "23", name: "Mauerwerk-Stahlbeton-Hybridbau" },
  { id: "24", name: "Modulbauweise" },
  { id: "25", name: "Pavillon/Messestand" },
  { id: "26", name: "andere / Misch-Bauweise" },
];

export const utilityUnits = [
  { id: "1", name: "Wohneinheit" },
  { id: "2", name: "Bewohner*innen" },
  { id: "3", name: "Mitarbeiter*innen" },
  { id: "4", name: "Arbeitsplatz" },
  { id: "5", name: "Hotelbett" },
];

export const dgnb_building_category = [
  { id: "1", name: "Einfamilienhaus, freistehend" },
  { id: "2", name: "Einfamilienhaus, angrenzend" },
  { id: "3", name: "Doppelhaushälfte" },
  { id: "4", name: "Reihenhaus, Mittelhaus" },
  { id: "5", name: "Reihenhaus, Endhaus" },
  { id: "6", name: "Mehrfamilienhaus, freihstehend" },
  { id: "7", name: "Mehrfamilienhaus, angrenzend" },
  { id: "8", name: "Wohngebäude" },
  { id: "9", name: "Büro- / Verwaltungsgebäude" },
  { id: "10", name: "Bildungsbau" },
  { id: "11", name: "Handelsbau" },
  { id: "12", name: "Industriegebäude" },
  { id: "13", name: "Versammlungsstätte" },
  { id: "14", name: "Beherbergungsbau" },
];

export const building_type_pollution_measures = [
  { id: "1", name: "gemäß DGNB Kriterium ENV1.2, Kriterienmatrix" },
  { id: "2", name: "gemäß Level(s) Rahmenwerk" },
  { id: "3", name: "gemäß BNB Einstufung" },
  { id: "4", name: "gemäß QNG Einstufung" },
  { id: "5", name: "gemäß Cradle2Cradle Einstufung" },
  { id: "6", name: "eigene Beschreibung" },
];

export const building_type_pollution_measures_comment =
  building_type_pollution_measures[building_type_pollution_measures.length - 1]
    .name;

export const building_type_pollution = [
  { id: "1", name: "N/A" },
  { id: "2", name: "QS0" },
  { id: "3", name: "QS1" },
  { id: "4", name: "QS2" },
  { id: "5", name: "QS3" },
  { id: "6", name: "QS4" },
  { id: "7", name: "Niveau 1 (BNB)" },
  { id: "8", name: "Niveau 2 (BNB)" },
  { id: "9", name: "Niveau 3 (BNB)" },
  { id: "10", name: "Niveau 4 (BNB)" },
  { id: "11", name: "Niveau 5 (BNB) " },
  { id: "12", name: "eigene Beschreibung" },
];

export const building_type_pollution_comment =
  building_type_pollution[building_type_pollution.length - 1].name;

export const building_type_pollution_quality = [
  { id: "1", name: "selbstständig erstellt" },
  { id: "2", name: "Daten intern geprüft" },
  { id: "3", name: "Daten extern geprüft" },
  { id: "4", name: "Daten extern unabhängig geprüft" },
  { id: "5", name: "eigene Bewertungen" },
];

export const dgnb_lvl_pollution = [
  { id: "1", name: "schadstofffrei" },
  { id: "2", name: "weitgehend schadstofffrei" },
  { id: "3", name: "Kreislauffähigkeit nicht verhindernd, gehoben" },
  { id: "4", name: "Kreislauffähigkeit nicht verhindernd, Standard" },
  { id: "5", name: "verhindert die Kreislaufführung" },
  { id: "6", name: "nicht genügend Informationen für Beurteilung" },
];

export const dgnb_lvl_pollution_quality = building_type_pollution_quality;

export const pollution_usage = [
  { id: "1", name: "zu erwarten" },
  { id: "2", name: "eher zu erwarten" },
  { id: "3", name: "eher nicht zu erwarten" },
  { id: "4", name: "nicht zu erwarten" },
];

export const dgnb_report_pollution = [
  { id: "1", name: "Gutachten vorhanden" },
  { id: "2", name: "Gutachten nicht vorhanden" },
];

export const dgnb_report_pollution_vorhanden = dgnb_report_pollution[0].name;

export const dgnb_result_report_pollution = [
  { id: "1", name: "Analyse durchgeführt, keine Schadstoffe vorhanden" },
  {
    id: "2",
    name: "Analyse und Schadstoff-Sanierung (inkl. Risiko-/Störstoffen) wurde durchgeführt, Rest-Schadstoffkataster ist dokumentiert",
  },
  {
    id: "3",
    name: "Analyse und Schadstoff-Sanierung hat stattgefunden, Rest-Schadstoffkataster ist dokumentiert",
  },
  { id: "4", name: "Analyse durchgeführt, Schadstoffkataster vorhanden" },
  { id: "5", name: "Keine Analyse, Verdacht liegt vor (z.B. anhand Baujahr)" },
  { id: "6", name: "Keine Analyse, kein Verdacht" },
];

export const dgnb_area_division_possible = [
  { id: "1", name: "Nein, Konzept nicht vorhanden" },
  { id: "2", name: "Teilweise, Konzept vorhanden" },
  { id: "3", name: "Teilweise, Konzept nicht vorhanden" },
  { id: "4", name: "Ja, Konzept nicht vorhanden" },
  { id: "5", name: "Ja, Konzept vorhanden" },
];

export const dgnb_area_expandable = dgnb_area_division_possible;

export const dgnb_deconstruction_type = [
  { id: "1", name: "ja, konventionell, mit Audit" },
  { id: "2", name: "ja, konventionell, ohne Audit" },
  { id: "3", name: "ja, selektiv, mit Audit" },
  { id: "4", name: "ja, selektiv, ohne Audit" },
  { id: "5", name: "nein" },
];

export const dgnb_deconstruction_type_nein =
  dgnb_deconstruction_type[dgnb_deconstruction_type.length - 1].name;

export const dgnb_area_concept_description = [
  { id: "1", name: "Beschreibung vorhanden" },
  { id: "2", name: "Beschreibung nicht vorhanden" },
];

export const dgnb_area_concept_description_vorhanden =
  dgnb_area_concept_description[0].name;
