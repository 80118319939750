import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import CheckCircleIcon from "assets/images/check-circle-green.svg";

export type StepSuccessTypeProps = {
  handleClose: (open_exports: boolean) => void;
};

const StepSuccess = (props: StepSuccessTypeProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full justify-center items-center p-4">
      <div className="space-y-4 text-center items-center flex flex-col justify-center w-[80%]">
        <img className="w-12" src={CheckCircleIcon} alt="check-circle" />
        <label className="font-[600] text-lg text-gray-900">
          {t("DGNB.downloadDGNBPass")}
        </label>
      </div>
      <label className="font-[500] text-[12px] text-gray-500 pb-6">
        {t("DGNB.fileCreatedText")}
      </label>

      <div className="grid grid-cols-2">
        <div className="col-span-1">
          <Button
            type="gray"
            width="fit-content"
            onClick={() => {
              props.handleClose(true);
            }}
          >
            {t("DGNB.success_open_export")}
          </Button>
        </div>

        <div className="col-span-1">
          <Button
            type="primary"
            width="fit-content"
            onClick={() => {
              props.handleClose(false);
            }}
          >
            {t("DGNB.success_and_close")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default observer(StepSuccess);
