import { AuditIN, AuditOUT } from "api-client";
import { upsertMultiple } from "./utils";
import { db } from "./db";

export async function storeAudit(audit: AuditOUT | AuditOUT[]) {
  const isArray = Array.isArray(audit);
  if (isArray) {
    return await upsertMultiple(audit);
  } else {
    return await db.audits.put(audit);
  }
}

export async function getAudit(auditId: string) {
  const foundAudit = await db.audits.get(auditId);
  return foundAudit;
}

export async function setLocalAudit(audit: AuditIN | AuditOUT) {
  const foundAudit = await getAudit(audit.id);
  await db.transaction("rw", db.audits, async () => {
    if (foundAudit) {
      await db.audits.put(audit);
    } else {
      await db.audits.add(audit);
    }
  });

  console.log("(Dexie DB) Audit updated", audit);
  return audit as AuditOUT;
}
