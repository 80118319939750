import { MinusCircleIcon } from "@heroicons/react/outline";
import { ImageOUT } from "api-client";
import ImageResizer from "features/Audit/ImageResizer";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";

export const RemoteImagesOnline = observer(() => {
  const { t } = useTranslation();
  const { blueprint_id } = useParams();

  const getBlueprintImages = async () => {
    if (!blueprint_id) return;

    caOnlineStore.setBlueprintImages([]);
    await caOnlineStore.fetchBlueprintImages(blueprint_id);
  };

  React.useEffect(() => {
    getBlueprintImages();
  }, []);

  return (
    <>
      {caOnlineStore.blueprintImages.length > 0 && (
        <>
          <p className="font-semibold text-sm mt-2">
            {t("audits.uploadedImagesLabel")}
          </p>
          <div className="whitespace-nowrap overflow-x-auto">
            {caOnlineStore.blueprintImages
              .slice()
              .sort((a, b) => {
                return b.order - a.order;
              })
              .map((img: ImageOUT) => {
                return (
                  <div
                    className="border bg-gray-200 max-w-[130px] my-3 p-1 inline-block mr-3 relative rounded-md"
                    key={img.id}
                  >
                    <ImageResizer
                      width="500"
                      height="500"
                      className="object-fill !w-full !h-full rounded-sm"
                      src={img.image ?? ""}
                      alt={img.id}
                      isLink
                    />
                    <button
                      className="text-white absolute w-5 h-5 top-0 !z-100 -right-1 bg-red-700 rounded-full"
                      onClick={() => {
                        caOnlineStore.deleteBlueprintImage(img.id);
                      }}
                    >
                      <MinusCircleIcon />
                    </button>
                  </div>
                );
              })}
          </div>
        </>
      )}
    </>
  );
});
