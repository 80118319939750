import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import SimpleSelect from "components/SimpleSelect";
import Alert from "components/Alert";
import {
  buildingProductStore,
  Option,
} from "store/IfcMapping/BuildingProductStore";
import { CIRCULARITY_GUIDE_URL } from "utils";

export default observer(function CircularityStep() {
  const { t } = useTranslation();
  buildingProductStore.checkStepValidity(1);

  return (
    <>
      <div className="text-lg font-semibold mb-2">
        {t("productEditor.circularity")}
      </div>
      <div className="text-sm font-normal text-gray-700 mb-6">
        {t("productEditor.circularityDescription")}
      </div>
      <div className="bg-white border-gray-300 border p-6 rounded-md">
        <div className="border-b border-gray-100">
          <div className="text-base font-semibold mb-3">
            {t("productEditor.preUse")}
          </div>
          <SimpleSelect
            options={
              buildingProductStore.optionsLists.preuse_qualification as Option[]
            }
            labelName={t("productEditor.material_origin")}
            required
            placeholder={t("productEditor.selectValue")}
            name="preuse_qualification"
            onChange={(value, name) =>
              buildingProductStore.onChangeData(name as string, value)
            }
            current={buildingProductStore.data.preuse_qualification}
          />
        </div>
        <div className="mt-4">
          <div className="text-base font-semibold mb-3">
            {t("productEditor.postUse")}
          </div>
          <div className="flex w-full justify-between gap-4">
            <SimpleSelect
              options={
                buildingProductStore.optionsLists
                  .separability_qualification as Option[]
              }
              labelName={t("productEditor.separability")}
              placeholder={t("productEditor.selectValue")}
              containerClassName="w-full"
              required
              name="separability_qualification"
              onChange={(value, name) =>
                buildingProductStore.onChangeData(name as string, value)
              }
              current={buildingProductStore.data.separability_qualification}
            />
            <SimpleSelect
              options={
                buildingProductStore.optionsLists
                  .deconstructability_qualification as Option[]
              }
              labelName={t("productEditor.deconstructability")}
              placeholder={t("productEditor.selectValue")}
              containerClassName="w-full"
              name="deconstructability_qualification"
              required
              onChange={(value, name) =>
                buildingProductStore.onChangeData(name as string, value)
              }
              current={
                buildingProductStore.data.deconstructability_qualification
              }
            />
          </div>
          <SimpleSelect
            options={
              buildingProductStore.optionsLists
                .reusability_qualification as Option[]
            }
            labelName={t("productEditor.reusability")}
            placeholder={t("productEditor.selectValue")}
            name="reusability_qualification"
            onChange={(value, name) =>
              buildingProductStore.onChangeData(name as string, value)
            }
            current={buildingProductStore.data.reusability_qualification}
            required
          />
        </div>

        <Alert
          description={t("productEditor.defaultAlertMessage")}
          actionButton={t("productEditor.openGuide")}
          type="info"
          actionButtonOnClick={() =>
            window.open(CIRCULARITY_GUIDE_URL, "_blank", "noopener,noreferrer")
          }
        />
      </div>
    </>
  );
});
