import React from "react";
import { observer } from "mobx-react-lite";
import { Dimensions, ReportTileInfo } from "api-client";
import { InfoItemProps } from "store/IfcMapping/DetailViewV2Store";
import Alert from "components/Alert";
import { useTranslation } from "react-i18next";
import ListSectionHeader from "./ListSectionHeader";
import ListSection from "./ListSection";

interface Props {
  infoItem: ReportTileInfo;
}

const InfoListItem = ({ infoItem }: Props) => {
  const { t } = useTranslation();

  function checkNullValue(values: Dimensions[] | undefined) {
    if (!values) return false;
    for (const item of values) {
      if (item.value !== null) {
        return true;
      }
    }
    return false;
  }

  const { general_information, dimensions } = infoItem;

  return (
    <>
      <ListSectionHeader title={t("detailView.infoTab.generalInformation")} />
      {general_information?.length ? (
        <>
          <ListSection items={general_information as InfoItemProps[]} />
        </>
      ) : (
        <Alert
          type="info"
          description={t("detailView.infoTab.noGeneralInfoAlert")}
        />
      )}
      <ListSectionHeader title={t("detailView.infoTab.dimensions")} />
      {dimensions?.length && checkNullValue(dimensions) ? (
        <>
          <ListSection items={dimensions} />
        </>
      ) : (
        <Alert
          className="mt-2"
          type="attention"
          title={t("detailView.infoTab.noDimensionsAlertTitle")}
          description={t("detailView.infoTab.noDimensionsAlertMsg")}
        />
      )}
    </>
  );
};

export default observer(InfoListItem);
