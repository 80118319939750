import { calculationsApi } from "./../../api-client/index";
import {
  BuildingOUT,
  BuildingReportOUT,
  buildingsApi,
  BuildingTypeOUT,
  datasetCheckerApi,
  DatasetCheckerOUT,
  ExportStatusEnum,
  PagedBuildingOUT,
  reportBuildingApi,
  userBuildingApi,
  UserBuildingOUT,
  UserRoleOUT,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable } from "mobx";
import {
  BuildingMappingStatus,
  CalculationsStatus,
  ExportedCertifications,
  MaterialResources,
  OrganisationBuilding,
  SharedBuildings,
} from "./types";
import { organisationStore } from "store/OrganisationStore";
import { AxiosResponse } from "axios";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";

export class BuildingStore {
  currentBuilding: BuildingOUT | undefined;
  buildingMappingStatus: BuildingMappingStatus = {
    hasMappedObjects: false,
    hasComplientsObjects: false,
    hasReport: false,
    loading: true,
  };
  recalculatingLoading: string[] = [];
  buildingTypes: BuildingTypeOUT[] = [];
  sharedBuildingsUserRoles: UserRoleOUT[] = [];
  sharedBuildings: SharedBuildings = {
    items: [],
    loading: true,
  };
  isSharedBuilding = false;
  buildingsLoading = false;
  materialResources: MaterialResources = {
    items: [],
    loading: true,
  };
  organisationsBuildings: OrganisationBuilding[] = [];
  noBuilding: boolean | undefined = undefined;
  buildingID: string | undefined;
  exportedCertifications: ExportedCertifications = {
    loading: true,
    items: [],
  };
  calculationsStatus: CalculationsStatus = { id: "", inProgress: false };
  interval: NodeJS.Timer | undefined;

  setBuildingID(buildingID: string | undefined) {
    this.buildingID = buildingID;
  }

  setBuildingTypes(buildingTypes: BuildingTypeOUT[]) {
    this.buildingTypes = buildingTypes;
  }

  setSharedBuildingsUserRoles(sharedBuildingsUserRoles: UserRoleOUT[]) {
    this.sharedBuildingsUserRoles = sharedBuildingsUserRoles;
  }

  setSharedBuildings(sharedBuildings: SharedBuildings) {
    this.sharedBuildings = sharedBuildings;
  }

  setIsSharedBuilding(isSharedBuilding: boolean) {
    this.isSharedBuilding = isSharedBuilding;
  }

  setBuildingsLoading(buildingsLoading: boolean) {
    this.buildingsLoading = buildingsLoading;
  }

  setMaterialResources(materialResources: MaterialResources) {
    this.materialResources = materialResources;
  }

  setOrganisationsBuildings(organisationsBuildings: OrganisationBuilding[]) {
    this.organisationsBuildings = organisationsBuildings;
  }

  setNoBuilding(noBuilding: boolean | undefined) {
    this.noBuilding = noBuilding;
  }

  setCurrentBuilding(currentBuilding: BuildingOUT | undefined) {
    let buildingChanged = false;
    if (currentBuilding && currentBuilding?.id !== this.currentBuilding?.id) {
      buildingChanged = true;
    }
    this.currentBuilding = currentBuilding;
    if (buildingChanged) {
      this.checkIfUserIsGuest();
    }
  }

  setBuildingMappingStatus(buildingMappingStatus: BuildingMappingStatus) {
    this.buildingMappingStatus = buildingMappingStatus;
  }

  setExportedCertifications(exportedCertifications: ExportedCertifications) {
    this.exportedCertifications = exportedCertifications;
  }

  setCalculationsLoading(calculationsStatus: CalculationsStatus) {
    this.calculationsStatus = calculationsStatus;
  }

  resetBuildingMappingStatus() {
    this.setBuildingMappingStatus({
      hasMappedObjects: false,
      hasComplientsObjects: false,
      hasReport: false,
      loading: true,
    });
  }

  async fetchCheckDatasetCompliance(
    building_id: string | undefined,
    certification_id: string | undefined | null,
    conformity_id: string | undefined | null,
    use_simplified_version_lca?: boolean
  ): Promise<DatasetCheckerOUT | void> {
    if (!building_id || !certification_id || !conformity_id) return;
    const authHeader = await getLocalAuthHeader();
    return await datasetCheckerApi
      .inventoryApiV1RoutersDatasetCheckerCheckDatasetCompliance(
        {
          building_id: building_id,
          certification_id: certification_id,
          conformity_id: conformity_id,
          din_category_filter: { use_simplified_version_lca },
        },
        authHeader
      )
      .then((response) => response.data)
      .catch((err) =>
        console.error(err, "error.DatasetCheckerCheckDatasetCompliance")
      );
  }

  async getCurrentBuildingDetails(building_id?: string | undefined) {
    const buildingId = building_id ?? this.currentBuilding?.id;
    if (!buildingId) return;
    const authHeader = await getLocalAuthHeader();
    return buildingsApi
      .concularApiV1RoutersBuildingsGetOrgBuilding(buildingId, authHeader)
      .then((response) => response.data)
      .catch((err) => console.error(err, "getCurrentBuildingDetails.error"));
  }

  async getBuildingMappingStatus(building_id?: string | undefined) {
    const buildingId = building_id ?? this.currentBuilding?.id;
    if (!buildingId) return;
    this.resetBuildingMappingStatus();
    const authHeader = await getLocalAuthHeader();
    await buildingsApi
      .concularApiV1RoutersBuildingsMappingStatus(buildingId, authHeader)
      .then((response) => {
        const data = response.data;
        if (data.building_id === buildingId)
          this.setBuildingMappingStatus({
            hasMappedObjects: data.has_mapped_objects,
            hasComplientsObjects: data.has_compliant_matches,
            hasReport: data.has_mapped_objects && data.has_compliant_matches,
            loading: false,
          });
      })
      .catch((err) => console.error(err, "BuildingsMappingStatus"));
  }

  async getBuildingTypes() {
    const authHeader = await getLocalAuthHeader();
    buildingsApi
      .concularApiV1RoutersBuildingsGetAllBuildingTypes(100, 0, authHeader)
      .then((response) => {
        if (response.data.items) {
          this.setBuildingTypes(response.data.items);
        } else {
          console.error("getBuildingTypes.response", response);
        }
      })
      .catch((err) => console.error("getBuildingTypes", err));
  }

  async getAllMaterialResources() {
    const authHeader = await getLocalAuthHeader();
    if (!this.currentBuilding?.id) return;
    await buildingsApi
      .concularApiV1RoutersBuildingsGetAllMaterialResources(
        this.currentBuilding?.id,
        authHeader
      )
      .then((response: AxiosResponse) => {
        this.setMaterialResources({ items: response.data, loading: false });
      })
      .catch((err) => {
        console.error("getAllMaterialResources", err);
        this.setMaterialResources({
          ...this.materialResources,
          loading: false,
        });
      });
  }

  async getSharedBuildingsUserRoles() {
    const authHeader = await getLocalAuthHeader();
    userBuildingApi
      .concularApiV1RoutersUserBuildingsGetAllRoles(authHeader)
      .then((response) => {
        if (response.data) {
          this.setSharedBuildingsUserRoles(response.data);
        } else {
          console.error("getUseruserBuildingRoles.response", response);
        }
      })
      .catch((err) => console.error("getUseruserBuildingRoles", err));
  }

  async getSharedBuildings() {
    const authHeader = await getLocalAuthHeader();
    await userBuildingApi
      .concularApiV1RoutersUserBuildingsGetUserBuildings(authHeader)
      .then(async (response: AxiosResponse<UserBuildingOUT[]>) => {
        const userBuilding = response?.data;
        if (!userBuilding) return console.error("getUserBuildings", response);
        this.setSharedBuildings({ items: userBuilding, loading: false });
        await this.getSharedBuildingsUserRoles();
      })
      .catch((err) => {
        console.error("getUserBuildings", err);
      })
      .finally(() =>
        this.setSharedBuildings({ ...this.sharedBuildings, loading: false })
      );
  }

  async getAllOrganizationsBuildings() {
    await Promise.all(
      organisationStore.organisations.map(async (org) => {
        await this.getOrganisationBuildings(org.id);
      })
    );
    this.setNoBuilding(!this.hasAnyBuildings());
  }

  async getOrganisationBuildings(org_id?: string) {
    const currentOrgID = org_id ?? organisationStore.currentOrganisation?.id;
    const authHeader = await getLocalAuthHeader();
    if (!currentOrgID) {
      return console.error("undefined.org.id-organisationsBuildings");
    }
    await buildingsApi
      .concularApiV1RoutersBuildingsGetAllOrgBuildings(
        currentOrgID,
        100,
        0,
        authHeader
      )
      .then((response: AxiosResponse<PagedBuildingOUT, Error>) => {
        const responseItems = response.data.items;
        this.updateOrganisationBuildings(currentOrgID, responseItems);
        this.findAndUpdateCurrentBuilding(responseItems);
      })
      .catch((err) => console.error("getAllOrganizationsBuildings", err));
  }

  private updateOrganisationBuildings(
    currentOrgID: string,
    responseItems: BuildingOUT[]
  ) {
    const foundCurrentOrgBuildings = this.organisationsBuildings.find(
      (rec) => rec.orgId === currentOrgID
    );
    if (foundCurrentOrgBuildings) {
      this.updateCurrentOrganisationBuildings(currentOrgID, responseItems);
    } else {
      this.setOrganisationsBuildings([
        ...this.organisationsBuildings,
        {
          loading: false,
          items: responseItems,
          orgId: currentOrgID,
        },
      ]);
    }
  }

  private findAndUpdateCurrentBuilding(responseItems: BuildingOUT[]) {
    if (this.buildingID && responseItems) {
      const foundCurrentBuilding = responseItems.find(
        (el) => el.id === this.buildingID
      );
      foundCurrentBuilding && this.setCurrentBuilding(foundCurrentBuilding);
    }
  }

  private updateCurrentOrganisationBuildings(
    orgId: string,
    buildings: BuildingOUT[]
  ) {
    this.organisationsBuildings.forEach((rec) => {
      if (rec.orgId === orgId) {
        rec.items = [...buildings];
      }
    });
    this.setOrganisationsBuildings([...this.organisationsBuildings]);
  }

  getCurrentOrganisationBuildings = (org_id: string) => {
    return this.organisationsBuildings.find((item) => item.orgId === org_id)
      ?.items;
  };

  hasAnyBuildings() {
    return (
      this.organisationsBuildings.filter((rec) => rec.items.length > 0).length >
      0
    );
  }

  checkIfUserIsGuest() {
    //on change current building we check if user is a guest
    const sharedBuilding = this.sharedBuildings.items.find(
      (building) => building.building.id === this.currentBuilding?.id
    );
    if (sharedBuilding) {
      return this.setIsSharedBuilding(true);
    }
    this.setIsSharedBuilding(false);
  }

  async getBuildingExportCertifications(
    status?: ExportStatusEnum
  ): Promise<void | BuildingReportOUT[]> {
    const authHeader = await getLocalAuthHeader();
    if (!this.buildingID) return;
    this.setExportedCertifications({
      ...this.exportedCertifications,
      loading: true,
    });
    return await reportBuildingApi
      .reportApiV1RoutersBuildingGetBuildingExportedCertifications(
        this.buildingID,
        status,
        authHeader
      )
      .then((response) => response.data)
      .catch((err) => console.error("GetBuildingExportedCertifications", err))
      .finally(() => {
        this.setExportedCertifications({
          ...this.exportedCertifications,
          loading: false,
        });
      });
  }

  async deleteBuildingExportedCertification(report_id: string) {
    const authHeader = await getLocalAuthHeader();
    if (!this.buildingID) return;
    return await reportBuildingApi
      .reportApiV1RoutersBuildingDeleteBuildingExportedCertification(
        this.buildingID,
        report_id,
        authHeader
      )
      .then((response) => response.data)
      .catch((err) =>
        console.error("deleteBuildingExportedCertification", err)
      );
  }

  getBuildingCertificationName(certification_id?: string | null) {
    const certId = certification_id || this.currentBuilding?.certification_id;
    return ifcMappingStore.certificationOptions.find(
      (item) => item.id === certId
    )?.name;
  }

  checkIfNoBuildingDashboard() {
    const allFilesCount = this.materialResources.items.length;
    const activeFileIDsList = this.getActiveFileIDs();
    return (
      allFilesCount == 0 ||
      activeFileIDsList.length == 0 ||
      !this.buildingMappingStatus.hasMappedObjects
    );
  }

  getActiveFileIDs() {
    return this.materialResources.items
      .filter((item) => item.reporting_status === "ACTIVE")
      .map((item) => item.id);
  }

  getModuleNamesPerCertification() {
    const generalModules = ["A1-A3", "B4", "C3", "C4"];
    const dgnb2018 = [...generalModules, "D"];
    let result = generalModules;

    if (!this.currentBuilding?.certification_id) {
      return result;
    }
    const certificationName = this.getBuildingCertificationName(
      this.currentBuilding?.certification_id
    );
    if (certificationName?.includes("2018")) {
      result = dgnb2018;
    }
    return result;
  }

  getXKTFileUrl(file_id: string | undefined) {
    if (!file_id) return;
    return this.materialResources.items.find((item) => item.id === file_id)
      ?.file_processed_xkt;
  }

  getXKTFileStatus(file_id: string | undefined) {
    if (!file_id) return;
    return this.materialResources.items.find((item) => item.id === file_id)
      ?.xkt_status;
  }

  private async reCheckCalculationsStatus(id?: string) {
    if (!id) return;
    this.interval = setInterval(
      async () => {
        await this.getBuildingCalculationsStatus(id);
        if (!this.calculationsStatus.inProgress) clearInterval(this.interval);
      },
      this.calculationsStatus.inProgress ? 5000 : 10000
    );
  }

  async getBuildingCalculationsStatus(id?: string) {
    const authHeader = await getLocalAuthHeader();
    const currentID = id ?? this.buildingID;
    if (!currentID) return;

    const previousBuildingCalculationStatus =
      this.calculationsStatus.inProgress &&
      currentID !== this.calculationsStatus.id;
    if (previousBuildingCalculationStatus) {
      /* Clear last interval on change building */
      clearInterval(this.interval);
      this.setCalculationsLoading({ id: undefined, inProgress: false });
    }

    return await calculationsApi
      .concularApiV1RoutersCalculationsGetCalculationsStatus(
        "building",
        currentID,
        authHeader
      )
      .then((response) => {
        const currentCalculationsState = response.data.status === "IN_PROGRESS";

        const currentBuildingCalculationsStatusChanged =
          currentCalculationsState && !this.calculationsStatus.inProgress;
        if (currentBuildingCalculationsStatusChanged) {
          /* Run interval to recheck calculations status */
          this.reCheckCalculationsStatus(currentID);
        }

        this.setCalculationsLoading({
          id: currentID,
          inProgress: currentCalculationsState,
        });
      });
  }

  async getPPDFReport() {
    if (!this.buildingID) return "";
    const authHeader = await getLocalAuthHeader();
    await reportBuildingApi
      .reportApiV1RoutersBuildingGetBuildingPdfReport(this.buildingID, {
        ...authHeader,
        responseType: "blob",
      })
      .then(async (response) =>
        this.downloadPDF(response.data as unknown as string)
      )
      .catch((err) => console.error(err, "GetBuildingPdfReport"));
  }

  downloadPDF(data: string) {
    try {
      // Convert string to a Blob
      const blob = new Blob([data], { type: "application/pdf" });
      if (!(blob instanceof Blob) || blob.size === 0) {
        throw new Error("Invalid Blob data");
      }
      // Create a URL for the Blob
      const url = window.URL.createObjectURL(blob);
      // Create an anchor element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "building_report.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error handling PDF download:", error);
    }
  }

  async initialFetch() {
    //get building types, shared buildings list and all buildings of all organisations on loading the app
    await this.getBuildingTypes();
    await this.getSharedBuildings();
    await this.getAllOrganizationsBuildings();
  }

  static instance: BuildingStore;

  constructor() {
    makeAutoObservable(this);
  }

  static getInstance(): BuildingStore {
    if (!BuildingStore.instance) {
      BuildingStore.instance = new BuildingStore();
    }
    return BuildingStore.instance;
  }
}

export const buildingStore = BuildingStore.getInstance();
