import React from "react";
import { observer } from "mobx-react";
import revitSync from "assets/images/revit-sync-view.svg";
import { useTranslation } from "react-i18next";

export default observer(function StepSync() {
  const { t } = useTranslation();

  return (
    <>
      <img src={revitSync} className="m-auto" />
      <div className="text-xs font-normal text-gray-700 mt-6 mb-4">
        {t("revit.syncDescription")}
      </div>
    </>
  );
});
