import {
  AuditOUT,
  BlueprintOUT,
  ManufacturerOUT,
  ProductGroupOUT,
  UserProfileOUT,
} from "api-client";
import React from "react";
import { SetterOrUpdater } from "recoil";
import { BlueprintAddForm } from "../../../pages/Audit/BlueprintAdd";
import { makeBlueprintPropertyModel, makeBlueprintRawModel } from "../utils";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

import validateAddedComponents from "./validateAddedComponents";
import retrieveFields from "./retrieveFields";
import { setLocalAudit } from "api-client-local/audits";
import { db } from "../../../api-client-local/db";
import { LocalImage } from "../../../api-client-local/db_interfaces";
import { checkAndAddBlueprintId } from "utils";

interface HandleAuditSubmitArgs {
  images: (File | LocalImage)[];
  selectedProductGroup: ProductGroupOUT;
  selectedManufacturer?: ManufacturerOUT | null;
  audit: AuditOUT;
  setFormLoading:
    | React.Dispatch<React.SetStateAction<string>>
    | SetterOrUpdater<string>;
  userProfile: UserProfileOUT;
  authHeader: AxiosRequestConfig<AxiosRequestHeaders>;
}

export interface HandleBlueprintItemFormSubmitArgs
  extends HandleAuditSubmitArgs {
  selectedBlueprint: BlueprintOUT;
}

interface HandleBlueprintSubmitArgs extends Partial<HandleAuditSubmitArgs> {
  addedComponents: { [id: string]: BlueprintOUT };
  setStep: SetterOrUpdater<number>;
  setComponentStep: SetterOrUpdater<number>;
  building_id: string | undefined;
  audit: AuditOUT;
  addedComponentImages: { [id: string]: File[] } | null;
  setAudit: SetterOrUpdater<AuditOUT | null>;
  generatedBlueprintName: string | null;
}

export default async function handleBlueprintSubmit(
  form: BlueprintAddForm,
  args: HandleBlueprintSubmitArgs,
  loadingCallback: (message: string) => void
) {
  try {
    if (!args.authHeader) throw "authHeader undefined.";
    if (!args.selectedProductGroup) throw "selectedProductGroup undefined.";
    const areComponentsValid = await validateAddedComponents(
      args.selectedProductGroup,
      args.addedComponents
    );
    console.log("Components are valid?", areComponentsValid);
    if (!areComponentsValid) {
      args.setStep(2);
      return args.setComponentStep(0);
    }

    const { foundFields, notFoundFields } = retrieveFields(
      form,
      args.selectedProductGroup.required_fields,
      args.selectedProductGroup.optional_fields
    );

    const blueprintId = window.crypto.randomUUID();
    const blueprintProperties = foundFields.map((found) => {
      return makeBlueprintPropertyModel(
        found.field.id,
        blueprintId,
        found.value
      );
    });

    if (!args.userProfile || !args.audit) {
      throw "userProfile or audit undefined.";
    }

    const blueprint = makeBlueprintRawModel(
      args.audit.id as string,
      args.selectedProductGroup.id,
      args.userProfile,
      {
        extra_info: form.extra_info,
        name: args.generatedBlueprintName ?? blueprintId,
        name_de: args.generatedBlueprintName ?? blueprintId,
        blueprintproperty_set: blueprintProperties,
        components: Object.values(args.addedComponents),
        manufacturer: args.selectedManufacturer ?? undefined,
      }
    );

    console.log("BlueprintAdd.form", form);
    console.log("BlueprintAdd.foundFields", foundFields);
    console.log("BlueprintAdd.notFoundFields", notFoundFields);
    console.log("BlueprintAdd.blueprintProperties", blueprintProperties);
    console.log("BlueprintAdd.blueprint", blueprint);

    loadingCallback("audits.sendingFormData");

    const auditRequest = {
      ...args.audit,
      id: args.audit.id,
      blueprint_set: args.audit.blueprint_set.concat(blueprint),
    };

    const updatedLocalAudit = await setLocalAudit(auditRequest);

    args.setAudit(updatedLocalAudit);

    if (args.images) {
      console.log("args.images", args.images);
      loadingCallback("audits.uploadingBlueprintImages");
      // Create the the images in CADexie.
      for (const image of args.images) {
        db.images.add({
          id: window.crypto.randomUUID(),
          sourceType: "blueprint",
          sourceId: blueprint.id,
          file: image as File,
          deleted: false,
          uploaded: false,
          order: 0,
          albumName: "default",
        });
      }

      loadingCallback("audits.uploadedImages");
    }

    if (
      args.addedComponentImages &&
      blueprint.components &&
      blueprint.components.length > 0
    ) {
      loadingCallback("audits.uploadingComponentImages");
      const componentImages = Object.values(args.addedComponentImages);
      // const db = await getDB();
      await Promise.all(
        blueprint.components.map(async (item, i) => {
          console.log("blueprint.components", item);
          console.log("blueprint.components.img", componentImages[i]);
          for (const image of componentImages[i]) {
            db.images.add({
              id: window.crypto.randomUUID(),
              sourceType: "blueprint",
              sourceId: item.id,
              file: image as File,
              deleted: false,
              uploaded: false,
              order: 0,
              albumName: "default",
            });
          }
        })
      );
      loadingCallback("audits.uploadedImages");
    }

    args.setAudit(updatedLocalAudit);
    loadingCallback("");
    await checkAndAddBlueprintId(blueprint.id);
    return blueprint;
  } catch (err) {
    loadingCallback("audits.errorOccured");
    console.error(err);
    const timer = setTimeout(() => {
      loadingCallback("");
      clearTimeout(timer);
    }, 2000);
    return false;
  }
}
