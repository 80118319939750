import { useBanner } from "../context";
import CookieButton from "./Button";
import Modal from "./Modal";
import React from "react";

export default function CookieDialog() {
  const { goBack, forceCancel, texts } = useBanner();
  return (
    <Modal id="cookies-dialog" className="max-w-xl">
      <h2 className="text-semibold mb-4">{texts.dialog.title}</h2>
      <p>{texts.dialog.content}</p>
      <div className="flex justify-end mt-6">
        <CookieButton onClick={goBack} role="secondary">
          {texts.dialog.buttonBack}
        </CookieButton>
        <CookieButton onClick={forceCancel} className="ml-4" type="submit">
          {texts.dialog.buttonSubmit}
        </CookieButton>
      </div>
    </Modal>
  );
}
