import React, { ReactNode } from "react";
import { XIcon } from "@heroicons/react/outline";
import clsx from "clsx";

interface SlideOverProps {
  open: boolean;
  close: () => void;
  className?: string;
  containerClassName?: string;
  title?: string | ReactNode;
  children: ReactNode;
}

export default function SlideOver({
  open,
  close,
  className,
  containerClassName,
  title,
  children,
}: Readonly<SlideOverProps>) {
  return (
    <div
      className={clsx(
        "fixed h-full transition-all duration-500 top-0 bg-white",
        "z-30 shadow-md pb-16",
        `${open ? "right-0" : "-right-[480px]"}`,
        containerClassName
      )}
    >
      <div className="flex flex-col h-full">
        <div className="bg-opacity-70">
          <div
            className={clsx(
              "flex justify-between border-b border-gray-300 w-full bg-white text-lg font-medium text-gray-900 py-3 px-6"
            )}
          >
            {title}
            <XIcon
              className="min-w-[24px] w-6 text-gray-400 ml-1 cursor-pointer"
              onClick={close}
            />
          </div>
        </div>
        <div className={clsx("p-6 overflow-y-auto h-full", className)}>
          {children}
        </div>
      </div>
    </div>
  );
}
