import React, { useState } from "react";
import { Outlet, useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";
import { AuditOUT, BlueprintOUT } from "api-client";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import Button from "components/Button";
import SearchInput from "components/SearchInput";
import { currentAuditAtom, formLoadingAtom } from "store/atoms/audits";
import useAuditFormReset from "hooks/useAuditFormReset";
import ItemFilterMenu from "components/ActionMenu/ItemFilterMenu";
import useAuditBlueprintActions from "hooks/useAuditBlueprintActions";
import BlueprintListItem from "features/Audit/BlueprintListItem";
import FormLoadingContainer from "features/Audit/FormLoadingContainer";
import { PlusIcon } from "@heroicons/react/solid";
import PingAnimation from "components/PingAnimation";
import SyncBar, { TSyncStatus } from "features/Audit/SyncBar/index";
import useFeatureFlag from "hooks/useFeatureFlag";
import { db } from "api-client-local/db";

export default function Audit() {
  const { audit_id } = useParams();
  const location = useLocation();
  const { t } = useTranslation();
  const audit = useRecoilValue(currentAuditAtom);
  const setAudit = useSetRecoilState(currentAuditAtom);
  const formLoading = useRecoilValue(formLoadingAtom);
  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [renderedItems, setRenderedItems] = React.useState<BlueprintOUT[]>([]);
  const reset = useAuditFormReset();

  const isInstanceOrBlueprint =
    location.pathname.includes("blueprint") ||
    location.pathname.includes("instance");

  const { handleDelete, handleSearch, handleMultiSelect, handleDuplicate } =
    useAuditBlueprintActions(selectedItems, setSelectedItems, setRenderedItems);

  const [searchText, setSearchText] = useState("");

  React.useEffect(() => {
    if (!audit_id) return;
    const updateAudit = async () => {
      const foundAudit = await db.audits.get(audit_id);
      if (!foundAudit) {
        return console.warn(`Audit (${audit_id}) not found.`);
      }
      setAudit(foundAudit as AuditOUT);
    };
    updateAudit();
  }, [audit_id]);

  React.useEffect(() => {
    if (!audit) return;
    const componentIds = audit.blueprint_set
      .map((b) => b.components)
      .flat(2)
      .map((c) => (c ? c.id : undefined));
    setRenderedItems(
      [...audit.blueprint_set]
        .sort((a, b) => (a.created < b.created ? 1 : -1))
        .filter((b) => componentIds.includes(b.id) === false)
    );
    return () => setRenderedItems([]);
  }, [audit]);

  const shouldRenderOutlet = isInstanceOrBlueprint && audit_id;
  const shouldRenderAudit = audit && !shouldRenderOutlet;
  const [syncSuccess, setSyncSuccess] = useState(false);

  const handleFilterSelect = (i: number) => {
    if (i === 0) {
      setRenderedItems((items) =>
        [...items].sort((a, b) => (a.created > b.created ? -1 : 1))
      );
    }
    if (i === 1) {
      setRenderedItems((items) =>
        [...items].sort((a, b) => (a.created > b.created ? 1 : -1))
      );
    }
    if (i === 2) {
      setRenderedItems((items) =>
        [...items].sort((a, b) => a.name.localeCompare(b.name))
      );
    }
    if (i === 3) {
      setRenderedItems((items) =>
        [...items].sort((a, b) => b.name.localeCompare(a.name))
      );
    }
  };

  return (
    <>
      <FormLoadingContainer formLoading={formLoading} />
      {shouldRenderOutlet && <Outlet />}
      {shouldRenderAudit && (
        <>
          <SyncBar
            onSyncCompleted={(syncStatus: TSyncStatus) => {
              setSyncSuccess(syncStatus === "success");
            }}
          />
          <div className="my-4 pb-20">
            <SearchInput
              name="string_address"
              onChange={(value) => {
                setSearchText(value);
                handleSearch(value);
              }}
              value={searchText}
              placeholder={t("audits.searchBlueprints")}
            />
            <ItemFilterMenu onSelect={handleFilterSelect} />
            <div className="py-2 flex justify-between border-b text-xs">
              <span>{t("audits.blueprints")}</span>
              <span className="mr-16">{t("audits.amount")}</span>
            </div>
            <div className="mb-4">
              {renderedItems &&
                renderedItems.length > 0 &&
                renderedItems.map((item) => (
                  <BlueprintListItem
                    syncSuccess={syncSuccess}
                    key={item.id}
                    item={item}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    handlers={{
                      handleDuplicate,
                      handleDelete,
                      handleMultiSelect,
                    }}
                  />
                ))}
            </div>
          </div>
          <div className="flex justify-between pb-12 fixed md:sticky bottom-0">
            <Link to={`blueprints/add`} onClick={reset}>
              <Button
                leadingIcon={<PlusIcon />}
                disabledClasses="w-full"
                className="!rounded-full"
              >
                {t("audits.addNewBlueprint")}
              </Button>
            </Link>
            {selectedItems.length > 0 && (
              <Button
                onClick={async () =>
                  await Promise.all(selectedItems.map(handleDelete))
                }
                type="danger"
                disabledClasses="w-full"
              >
                {t("audits.delete")}
              </Button>
            )}
          </div>
        </>
      )}
      {!shouldRenderOutlet && !shouldRenderAudit && (
        <PingAnimation
          className="min-h-[50vh] flex flex-col items-center justify-center"
          text={t("audits.loadingBlueprints")}
        />
      )}
    </>
  );
}
