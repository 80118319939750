import React, { useState } from "react";
import Button from "../Button";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import XLSXFileIcon from "assets/images/icons/xlsx-file-icon.svg";
import Modal from "components/Modal";
import Checkbox from "components/Checkbox";
import Alert from "components/Alert";
import { REQUIREMENTS_URL } from "utils";
import { IconUpload } from "@tabler/icons-react";

const KEY_SKIP = "@skipModalReq";

interface IProps {
  label?: string;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>> | undefined;
  icon: string;
  acceptedTypes?: string[];
  dragMessage?: string;
  buttonText?: string;
  showAlertForIFC?: boolean;
}

const FilesDropZone = ({
  label,
  setFile,
  icon,
  acceptedTypes,
  dragMessage,
  buttonText,
  showAlertForIFC,
}: IProps) => {
  const { t } = useTranslation();
  const wasSkipped = localStorage.getItem(KEY_SKIP);
  const uploadType = icon?.includes("csv-file-icon") ? "CSV" : "IFC";

  const [isOpen, setIsOpen] = useState(false);
  const [modalShown, setModalShown] = useState(wasSkipped !== null);

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "text/html": acceptedTypes ?? [],
    },
    onDrop(acceptedFiles) {
      if (uploadType === "IFC") {
        if (!modalShown) {
          onShowModal();
        } else if (acceptedFiles) {
          setFile?.(acceptedFiles[0]);
        }
      } else {
        setFile?.(acceptedFiles[0]);
      }
    },
  });

  const handleOnSkip = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) localStorage.setItem(KEY_SKIP, "yes");
    else localStorage.removeItem(KEY_SKIP);
  };

  const onShowModal = () => {
    if (wasSkipped === null) {
      setIsOpen(true);
      setModalShown(true);
    }
  };

  const handleYesIDid = () => {
    setIsOpen(false);
    if (acceptedFiles[0]) {
      setFile?.(acceptedFiles[0]);
    } else {
      open();
    }
  };

  return (
    <>
      {uploadType === "IFC" && showAlertForIFC && (
        <Modal
          disableOutsideClick
          title={t("mapping.popupInfoTitle")}
          closeButton
          isOpen={isOpen}
          setIsOpen={(open) => {
            setModalShown(true);
            setIsOpen(open);
          }}
          containerClassName="h-fit md:max-w-lg !min-w-[500px]"
          footerClassName="bg-[white]"
          footer={
            <div className="items-center flex flex-row w-full justify-between">
              <Checkbox
                labelElement={t("mapping.skipNextTime")}
                onChange={handleOnSkip}
              />
              <Button onClick={handleYesIDid} width="fit-content">
                {t("mapping.yesIDid")}
              </Button>
            </div>
          }
        >
          <div className="m-4">
            <Alert
              type="info"
              title={t("mapping.popupInfoDescription")}
              actionButton={t(
                "buildingMaterialResources.showModelRequirements"
              )}
              actionButtonOnClick={() =>
                window.open(REQUIREMENTS_URL, "_blank", "noopener,noreferrer")
              }
            />
          </div>
        </Modal>
      )}
      <section className="w-full text-center">
        <div
          {...getRootProps({
            onClick: (e) => {
              e.stopPropagation();
              if (modalShown || !showAlertForIFC) {
                open();
                return;
              }
              onShowModal();
            },
            className:
              "flex-1 justify-center items-center w-full h-full px-4 py-6 transition bg-white border-2 border-gray-300 border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none",
          })}
        >
          <div className="flex justify-center">
            <img src={icon} />
            {icon?.includes("csv-file-icon") && <img src={XLSXFileIcon} />}
          </div>
          <div className="mt-1 text-sm text-gray-500">
            {label && <div className="py-4">{label}</div>}
            <div className="font-medium text-sm text-gray-700 p-2">
              {dragMessage ?? t("commons.dragAndDropMessage")}
            </div>
            <div className="font-medium text-sm text-gray-700 pb-1">
              {t("commons.or")}
            </div>
            <Button
              leadingIcon={<IconUpload />}
              type="secondary"
              width="fit-content"
              className="mx-auto mt-2"
              testID="uploadfile_button"
            >
              {buttonText ?? t("commons.uploadFile")}
            </Button>
          </div>

          <input {...getInputProps()} />
        </div>
      </section>
    </>
  );
};

export default FilesDropZone;
