import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import React from "react";
import { useTranslation } from "react-i18next";
import DropdownCheckbox, { DropdownOption } from "components/DropdownCheckbox";
import { CogIcon } from "@heroicons/react/outline";
import Checkbox from "components/Checkbox";
import TableSettings from "./TableSettings";
import GroupByFilter from "./GroupByFilter";
import { useMappingTools } from "../useMappingTools";

export default observer(function SettingsBar() {
  const { t } = useTranslation();
  const { autoMappLoading, undoMappingLoading } = mappingEditorStore;
  const { mappingStatusOptions } = useMappingTools();
  const [mappingStatusFilter, setMappingStatusFilter] = React.useState<
    DropdownOption[]
  >([]);

  const [showSettings, setShowSettings] = React.useState(false);

  React.useEffect(() => {
    setCurrentMappingStatus();
  }, []);

  function setCurrentMappingStatus() {
    const currentStatus = localStorage.getItem("mapping_status");
    currentStatus && mappingEditorStore.setMappingStatusSelected(currentStatus);
    const currentItems = currentStatus
      ? mappingStatusOptions.filter((item) =>
          currentStatus.includes(item.id as string)
        )
      : mappingStatusOptions;
    setMappingStatusFilter(currentItems);
  }

  const openSettingsModal = React.useCallback(() => {
    setShowSettings(true);
  }, []);

  const onChangeMappingStatusFilter = React.useCallback(
    (values: DropdownOption[]) => {
      setMappingStatusFilter(values);
      const selectedFilter =
        values.length === mappingStatusOptions.length
          ? ""
          : values.map((item) => item.id).join(",");
      localStorage.setItem("mapping_status", selectedFilter);
      mappingEditorStore.setMappingStatusSelected(selectedFilter);
    },
    []
  );

  const handleShowAll = React.useCallback(
    (value: {
      currentTarget: { checked: boolean | ((prevState: boolean) => boolean) };
    }) =>
      mappingEditorStore.setShowAllObjects(
        value.currentTarget.checked as boolean
      ),
    []
  );

  const showAllobjectsCheckbox = () => {
    return (
      <Checkbox
        id={"show-all"}
        labelElement={t("mapping.showObjectsIndividually")}
        checked={mappingEditorStore.showAllObjects}
        onChange={handleShowAll}
      />
    );
  };

  const closeSettings = React.useCallback(() => setShowSettings(false), []);

  return (
    <>
      <div className="sm:flex justify-between py-4 px-4 rounded-t-md bg-gray-50 border-b border-gray-300">
        <div
          className={clsx("flex justify-start w-full h-full gap-4", {
            "pointer-events-none opacity-50":
              autoMappLoading || undoMappingLoading,
          })}
        >
          <GroupByFilter />
        </div>
        <div className="min-w-fit flex">
          <div
            className={clsx("flex mt-2 min-w-fit sm:mt-0 xs:justify-end", {
              "pointer-events-none opacity-50":
                autoMappLoading || undoMappingLoading,
            })}
          >
            {showAllobjectsCheckbox()}
            <div className="ml-4 mr-2">
              <div className="text-sm text-gray-700">
                {t("mapping.mappingStatus")}
              </div>
              <DropdownCheckbox<DropdownOption>
                displayKey={"name"}
                containerClassName="min-w-[160px]"
                className="z-4"
                items={mappingStatusOptions}
                labelName={
                  mappingStatusFilter.length === 3
                    ? t("commons.all")
                    : mappingStatusFilter.map((item) => item.title).join(", ")
                }
                labelWithOutSearch
                hideOptionsSearch
                optionsClassName="z-40"
                checkedItems={mappingStatusFilter}
                handleSelect={onChangeMappingStatusFilter}
                height="middle"
                disabled={mappingEditorStore.dataFetchLoading}
              />
            </div>
            <CogIcon
              width={20}
              onClick={openSettingsModal}
              className="ml-3 cursor-pointer"
            />
          </div>
        </div>
      </div>
      <TableSettings open={showSettings} close={closeSettings} />
    </>
  );
});
