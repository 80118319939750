import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import useBlueprint from "hooks/useBlueprint";
import { useRecoilState } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import EditDeleteItem from "components/ActionMenu/EditDeleteItemMenu";
import handleComponentDelete from "./handlers/handleComponentDelete";
import { Link } from "react-router-dom";

export default function ItemComponentList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, audit_id, blueprint_id } = useParams();
  const [audit, setAudit] = useRecoilState(currentAuditAtom);
  const { selectedBlueprint } = useBlueprint(blueprint_id);

  if (
    !selectedBlueprint ||
    !selectedBlueprint.components ||
    selectedBlueprint.components.length === 0
  ) {
    return null;
  }

  return (
    <div className="my-4">
      <h4 className="text-sm font-semibold">{t("audits.components")}</h4>
      <div>
        {audit &&
          selectedBlueprint.components.map((comp) => {
            const componentEditRoute = `/buildings/${id}/audits/${audit_id}/blueprints/${comp.id}`;
            // const componentIndexRoute = `/buildings/${id}/audits/${audit_id}/blueprints/${comp.id}/instances`;

            const handleRouting = () => {
              navigate(componentEditRoute);
            };

            const handleDelete = async () => {
              const updatedAudit = await handleComponentDelete(comp.id, audit);
              setAudit(updatedAudit ?? audit);
            };

            return (
              <div
                className="flex pr-2 text-sm justify-between items-center border-b-2 py-2"
                key={comp.id}
              >
                <Link to={componentEditRoute} className="w-full">
                  <span>{comp.name} </span> ({comp.as_component_amount})
                </Link>
                <EditDeleteItem
                  id={comp.id}
                  onEdit={handleRouting}
                  onDelete={handleDelete}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
}
