import { ManufacturerOUT } from "api-client";
import TextSearchInput from "components/TextSearchInput";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { manufacturersAtom } from "store/atoms/audits";

interface SelectProps {
  onChange?: (value: string | undefined, name?: string) => void;
  onReset?: () => void | Promise<void>;
  selected?: ManufacturerOUT | null | undefined;
}

export default function SelectManufacturer(props: SelectProps) {
  return (
    <React.Suspense fallback={<div>Loading manufacturers...</div>}>
      <SelectManufacturerSuspensed
        onReset={props.onReset}
        selected={props.selected}
        onChange={props.onChange}
      />
    </React.Suspense>
  );
}

function SelectManufacturerSuspensed(props: SelectProps) {
  const manufacturers = useRecoilValue(manufacturersAtom);

  const { t } = useTranslation();
  if (!manufacturers || manufacturers.length === 0) {
    return (
      <div className="flex items-center">Manufacturers could not loaded.</div>
    );
  }
  return (
    <TextSearchInput<ManufacturerOUT>
      displayKey={"name"}
      selectedId={props.selected ? props.selected.id : undefined}
      onReset={props.onReset}
      handleChange={props.onChange}
      labelName={t("audits.selectManufacturer")}
      name="select_manufacturer"
      items={[...manufacturers].sort((a, b) => a.name.localeCompare(b.name))}
    />
  );
}
