import { AuditOUT, auditsApi } from "api-client";
import { setLocalAudit } from "api-client-local/audits";
import { getLocalAuthHeader } from "../../../api-client-local/utils";

export default async function handleBlueprintDelete(
  id: string,
  audit: AuditOUT
) {
  const targetBlueprints = audit.blueprint_set.filter(
    (blueprint) => blueprint.id === id
  );

  const blueprintComponents = targetBlueprints
    .map((b) => (b.components ? b.components : []))
    .flat(2);

  const blueprintsForDeletion = [
    ...targetBlueprints,
    ...blueprintComponents,
  ].map((b) => b.id);

  // Try deleting from remote, fail silently if not there.
  const localAuthHeader = await getLocalAuthHeader();
  for (const id of blueprintsForDeletion) {
    try {
      await auditsApi.caApiV1RoutersAuditDeleteBlueprint(id, localAuthHeader);
    } catch (e) {
      console.error("Failed to delete blueprint from remote", e, id);
    }
  }

  const newSet = audit.blueprint_set
    .filter((blueprint) => blueprint.id !== id)
    .filter((b) => !blueprintsForDeletion.includes(b.id));

  const auditRequest = {
    ...audit,
    id: audit.id,
    blueprint_set: newSet,
  };
  const updatedLocalAudit = await setLocalAudit(auditRequest);
  return updatedLocalAudit;
}
