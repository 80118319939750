import React from "react";
import Modal, { ModalProps } from "components/Modal";
import Checkbox from "components/Checkbox";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import {
  CalculatedColumns,
  InfoColumns,
  mappingEditorStore,
} from "store/IfcMapping/MEStore";
import Alert from "components/Alert";

type GroupType = "info" | "calculated";
interface Column {
  key: string;
  title: string;
  description: string;
  items: InfoColumns | CalculatedColumns;
}

export default observer(function TableSettings({ open, close }: ModalProps) {
  const { t } = useTranslation();
  const [infoColumnsState, setInfoColumnsState] = React.useState<InfoColumns>({
    ...mappingEditorStore.infoColumns,
  });
  const [calculatedColumnsState, setCalculatedColumnsState] =
    React.useState<CalculatedColumns>({
      ...mappingEditorStore.calculatedColumns,
    });

  const [selectAllChecked, setSelectAllChecked] = React.useState({
    info: false,
    calculated: false,
  });

  React.useEffect(() => {
    if (open) {
      setSelectAllChecked({
        info: checkAllChecked(mappingEditorStore.infoColumns),
        calculated: checkAllChecked(mappingEditorStore.calculatedColumns),
      });
    }
  }, [open]);

  const saveChanges = () => {
    mappingEditorStore.setInfoColumns({ ...infoColumnsState });
    mappingEditorStore.setCalculatedColumns({ ...calculatedColumnsState });
    close();
  };

  const checkAllChecked = (groupObject: InfoColumns | CalculatedColumns) =>
    Object.values(groupObject).indexOf(false) === -1;

  function handleOnChange(
    name: string,
    group: GroupType
  ): React.FormEventHandler<HTMLInputElement> | undefined {
    return (ev) => {
      const isChecked = ev.currentTarget.checked;
      if (group === "info") {
        const newState = {
          ...infoColumnsState,
          [name as unknown as keyof InfoColumns]: isChecked,
        };
        setInfoColumnsState(newState);
        setSelectAllChecked((state) => ({
          ...state,
          [group]: checkAllChecked(newState),
        }));
      } else {
        const newState = {
          ...calculatedColumnsState,
          [name as unknown as keyof CalculatedColumns]: isChecked,
        };
        setCalculatedColumnsState(newState);
        setSelectAllChecked((state) => ({
          ...state,
          [group]: checkAllChecked(newState),
        }));
      }
    };
  }

  function handleSelectAll(
    group: GroupType,
    ev: React.FormEvent<HTMLInputElement>
  ) {
    const checked = ev.currentTarget.checked;
    setSelectAllChecked((state) => ({ ...state, [group]: checked }));
    if (group === "info") {
      Object.keys(infoColumnsState).forEach(
        (key) => (infoColumnsState[key as keyof InfoColumns] = checked)
      );
      setInfoColumnsState({ ...infoColumnsState });
    } else {
      Object.keys(calculatedColumnsState).forEach(
        (key) =>
          (calculatedColumnsState[key as keyof CalculatedColumns] = checked)
      );
      setCalculatedColumnsState({ ...calculatedColumnsState });
    }
  }

  const columns: Column[] = [
    {
      key: "info",
      title: t("mapping.infoColumns"),
      description: t("mapping.infoColumnsDesc"),
      items: infoColumnsState,
    },
    {
      key: "calculated",
      title: t("mapping.calculatedColumns"),
      description: t("mapping.calculatedColumnsDesc"),
      items: calculatedColumnsState,
    },
  ];

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      title={t("mapping.tableSettings")}
      containerClassName="md:min-w-[800px]"
      disableOutsideClick
      footer={
        <div className="flex justify-end w-full">
          <Button width="fit-content" onClick={saveChanges}>
            {t("mapping.saveChanges")}
          </Button>
        </div>
      }
    >
      <div className="p-6">
        <div className="grid grid-cols-2 gap-6 mb-5">
          {columns.map((column) => {
            return (
              <div key={column.key}>
                <div className="text-base text-gray-700 font-semibold mb-1">
                  {column.title}
                </div>
                <div className="text-sm font-normal text-gray-700 mb-4">
                  {column.description}
                </div>
                <Checkbox
                  labelElement={t("mapping.selectAll")}
                  checked={selectAllChecked[column.key as never]}
                  onChange={(ev) =>
                    handleSelectAll(column.key as GroupType, ev)
                  }
                  containerClassName="border-b border-gray-200"
                />
                <ul>
                  {Object.keys(column.items).map((name, index: number) => (
                    <li key={index}>
                      <Checkbox
                        labelElement={t(`mapping.${name}`)}
                        checked={column.items[name as never]}
                        id={name}
                        onChange={handleOnChange(name, column.key as GroupType)}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
        <Alert type="info" description={t("mapping.tableSettingsAlert")} />
      </div>
    </Modal>
  );
});
