import React from "react";
import TextInput from "components/TextInput";
import PingAnimation from "components/PingAnimation";
import { addValuesToFieldsFromProperties } from "../../../../features/Audit/utils";
import ImageUploaderLocal from "components/ImageUploaderLocal";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import SelectManufacturer from "features/Audit/SelectManufacturer";
import GeneratedFieldsOnline from "features/AuditOnline/GeneratedFieldsOnline";
import { observer } from "mobx-react-lite";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { SubmitComponentButton } from "../navigation/SubmitComponentButton";
import { EditComponentButton } from "../navigation/EditComponentButton";
import { BackComponentsButton } from "../navigation/BackComponentsButton";

const AddComponent = () => {
  const { i18n, t } = useTranslation();
  const {
    selectedComponentProductGroup,
    addedComponents,
    selectedComponent,
    selectedProductGroup,
    addedComponentImages,
  } = blueprintCreateStore;

  useDisableScrollOnNumberInputs();

  const addedBefore = React.useMemo(() => {
    if (!selectedComponent) return false;
    return Object.keys(addedComponents).includes(selectedComponent.id);
  }, [selectedComponent]);

  const foundImages = React.useMemo(() => {
    if (addedBefore && addedComponentImages && selectedComponent) {
      return addedComponentImages[selectedComponent.id];
    }
    return null;
  }, [addedBefore, addedComponentImages, selectedComponent]);

  const ComponentPropertyFields = React.useCallback(() => {
    if (!selectedComponentProductGroup) return null;
    if (addedBefore && selectedComponent) {
      const addedComponentProps =
        addedComponents[selectedComponent.id].blueprintproperty_set;
      const requiredDefaultValues = addValuesToFieldsFromProperties(
        selectedComponentProductGroup.required_fields,
        addedComponentProps
      );
      const optionalDefaultValues = addValuesToFieldsFromProperties(
        selectedComponentProductGroup.optional_fields,
        addedComponentProps
      );
      return (
        <GeneratedFieldsOnline
          requiredDefaultValues={requiredDefaultValues}
          optionalDefaultValues={optionalDefaultValues}
          required_fields={selectedComponentProductGroup.required_fields}
          optional_fields={selectedComponentProductGroup.optional_fields}
        />
      );
    }
    return (
      <GeneratedFieldsOnline
        required_fields={selectedComponentProductGroup.required_fields}
        optional_fields={selectedComponentProductGroup.optional_fields}
      />
    );
  }, [selectedComponentProductGroup, selectedComponent, addedBefore]);

  if (
    !selectedComponentProductGroup ||
    selectedComponentProductGroup === null
  ) {
    return (
      <div>
        <PingAnimation size="small" color="gray" />
      </div>
    );
  }

  return (
    <>
      <TextInput
        type="text"
        className="sm:py-2"
        defaultValue={
          addedBefore && selectedComponent
            ? selectedComponent.name
            : i18n.language === "en"
            ? selectedComponentProductGroup?.name
            : selectedComponentProductGroup?.name_de
        }
        labelName="Name"
        name="name"
      />
      <TextInput
        containerClass="w-[49%]"
        className="sm:py-2 "
        unit={`${selectedComponentProductGroup?.unit}/${selectedProductGroup?.unit}`}
        labelName={t("audits.amount")}
        type="number"
        defaultValue={selectedComponent?.as_component_amount}
        name="as_component_amount"
      />
      <SelectManufacturer selected={selectedComponent?.manufacturer} />
      <ImageUploaderLocal
        id="component-images"
        images={foundImages ?? undefined}
        name="images_component"
        multiple
        labelName={t("audits.addImages")}
      />
      {ComponentPropertyFields()}
      <div className="flex justify-evenly pt-6">
        <BackComponentsButton />
        {addedBefore ? <EditComponentButton /> : <SubmitComponentButton />}
      </div>
    </>
  );
};

export default observer(AddComponent);
