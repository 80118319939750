import React from "react";
import clsx from "clsx";
import { IconChevronRight } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import useBuildActionBar, { MenuItem } from "./useBulkActionBar";
import Tooltip from "components/Tooltip";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";

interface BulkActionBarMenuProps {
  menuItems?: MenuItem[];
  className?: string;
}

const BulkActionBarMenu = ({
  menuItems,
  className,
}: BulkActionBarMenuProps) => {
  const { t } = useTranslation();
  const { menuList } = useBuildActionBar();
  const currentMenu = menuItems ?? menuList;

  function showSubMenu(item: MenuItem) {
    return (
      <div className="relative">
        <IconChevronRight
          className="text-gray-500 group-hover/menu-item:text-indigo-700 ml-1"
          size={18}
        />
        <BulkActionBarMenu
          menuItems={item.children}
          className="left-[39px] !-bottom-2 hidden group-hover/menu-item:block"
        />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "bg-white absolute bottom-10 border border-gray-300 rounded-md",
        className
      )}
      onMouseLeave={() => dynamicMEStore.setOpenBulkActionsMenu(false)}
    >
      {currentMenu.map((group) => (
        <div key={group.id} className="last-of-type:border-t border-gray-300">
          <div
            className={clsx(
              "text-gray-500 bg-gray-50 text-xs font-medium py-1 px-4 rounded-tl-md",
              "rounded-tr-md border-b border-gray-300"
            )}
          >
            {group.title}
          </div>
          {group.items?.map((item) => (
            <div
              className={clsx(
                "flex items-center justify-between text-gray-700 px-4 py-2 group/menu-item",
                "hover:bg-indigo-50 hover:text-indigo-700 cursor-pointer font-medium",
                "last-of-type:rounded-bl-md last-of-type:rounded-br-md whitespace-nowrap"
              )}
              key={item.id}
              onClick={item.onClick}
            >
              <div className="flex items-center">
                {item.icon}
                <span className="ml-2">{item.label}</span>
              </div>
              <div className="flex items-center pl-7">
                {item.tooltip && (
                  <Tooltip
                    iconClassName="opacity-80"
                    content={item.tooltip}
                  ></Tooltip>
                )}
                {item.value && (
                  <div className="opacity-80 font-normal">{item.value}</div>
                )}
                {item.children?.length && showSubMenu(item)}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default observer(BulkActionBarMenu);
