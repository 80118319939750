import {
  OrganisationOUT,
  OrganisationTypeOUT,
  PagedOrganisationOUT,
  UserRoleOUT,
  organisationsApi,
  organisationsTypesApi,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { AxiosResponse } from "axios";
import { makeAutoObservable } from "mobx";

type OrganisationRole = { id: string; name: string };
class OrganisationStore {
  organisations: OrganisationOUT[] = [];
  currentOrganisation: OrganisationOUT | undefined = undefined;
  clickedOrganisation: OrganisationOUT | undefined = undefined;
  domainOrganisations: OrganisationOUT[] = [];
  organisationTypes: OrganisationTypeOUT[] = [];
  allRoles: OrganisationRole[] = [];

  setOrganisations(organisations: OrganisationOUT[]) {
    this.organisations = organisations;
  }

  setCurrentOrganisation(currentOrganisation: OrganisationOUT | undefined) {
    this.currentOrganisation = currentOrganisation;
  }

  setClickedOrganisation(clickedOrganisation: OrganisationOUT) {
    this.clickedOrganisation = clickedOrganisation;
  }

  setDomainOrganisations(domainOrganisations: OrganisationOUT[]) {
    this.domainOrganisations = domainOrganisations;
  }

  setOrganisationTypes(organisationTypes: OrganisationTypeOUT[]) {
    this.organisationTypes = organisationTypes;
  }

  setAllRoles(allRoles: OrganisationRole[]) {
    this.allRoles = allRoles;
  }

  async getOrganisationTypes() {
    const authHeader = await getLocalAuthHeader();
    return await organisationsTypesApi
      .concularApiV1RoutersOrganisationsTypeGetOrganisationTypes(
        100,
        0,
        authHeader
      )
      .then((response: AxiosResponse) =>
        this.setOrganisationTypes(response.data.items)
      )
      .catch((err) => {
        console.error(err, "OrganisationsTypeGetOrganisationTypes");
      });
  }

  async getAllOrganisations() {
    const authHeader = await getLocalAuthHeader();
    await organisationsApi
      .concularApiV1RoutersOrganisationsGetAllOrganisations(100, 0, authHeader)
      .then(async (response: AxiosResponse) => {
        const organisations = response.data.items;
        this.setOrganisations(organisations);
      })
      .catch((err) => {
        console.error(err, "OrganisationsGetAllOrganisations");
      });
  }

  async getUserDomainRelatedOrganisations() {
    const authHeader = await getLocalAuthHeader();
    try {
      const response: AxiosResponse<PagedOrganisationOUT, Error> =
        await organisationsApi.concularApiV1RoutersOrganisationsGetUserDomainRelatedOrganisations(
          100,
          0,
          authHeader
        );
      this.setDomainOrganisations(response.data.items);
    } catch (err) {
      console.error(err, "getUserDomainRelatedOrganisations");
    }
  }

  async getAllRoles() {
    const authHeader = await getLocalAuthHeader();
    try {
      const response: AxiosResponse<UserRoleOUT[], Error> =
        await organisationsApi.concularApiV1RoutersOrganisationsGetAllRoles(
          authHeader
        );
      const roles = response.data;
      const rolesOptions = roles.map((item) => ({
        id: item.role_type,
        name: item.role_name,
      }));
      if (rolesOptions) {
        this.setAllRoles(rolesOptions);
      }
    } catch (err) {
      console.error(err, "OrganisationsGetAllRoles");
    }
  }

  async initialFetch() {
    await this.getOrganisationTypes();
    await this.getAllOrganisations();
    !this.currentOrganisation &&
      this.setCurrentOrganisation(this.organisations[0]);
    await this.getUserDomainRelatedOrganisations();
    await this.getAllRoles();
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: OrganisationStore;

  static getInstance(): OrganisationStore {
    if (!OrganisationStore.instance) {
      OrganisationStore.instance = new OrganisationStore();
    }
    return OrganisationStore.instance;
  }
}

export const organisationStore = OrganisationStore.getInstance();
