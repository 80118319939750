import Portal from "./Portal";
import React from "react";

export default function Modal(
  props: React.PropsWithChildren<{ id: string; className: string }>
) {
  return (
    <Portal
      id={props.id}
      className={`fixed h-full w-full z-40 bottom-0 justify-center flex flex-col bg-slate-800 bg-opacity-70 text-slate-600 p-2 lg:px-32`}
    >
      <div className={`bg-white rounded-lg p-8 m-auto ${props.className}`}>
        {props.children}
      </div>
    </Portal>
  );
}
