import React from "react";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import Heading from "components/Heading";
import Input from "components/Input";
import SimpleSelect from "components/SimpleSelect";
import { useNavigate } from "react-router";
import { organisationsApi } from "api-client";
import { PlusIcon } from "@heroicons/react/solid";
import FileUploader from "components/ImageUploaderLocal";
import useForm from "hooks/useForm";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";
import { buildingStore } from "store/Building/BuildingStore";

const CreateOrganisation = ({ close }: { close?: () => void }) => {
  const [t] = useTranslation();
  const { authHeader } = userStore;
  const navigate = useNavigate();
  const [showMandatoryAlert, setShowMandatoryAlert] = React.useState(false);
  const [formData, updateBuildingDetails] = React.useState<{
    name: string;
    type: string;
  }>({
    name: "",
    type: "",
  });
  const form = useForm(formData, updateBuildingDetails);

  async function uploadLogo(organisationId: string, image: File) {
    if (!authHeader) return;
    return await organisationsApi.concularApiV1RoutersOrganisationsUploadOrganisationLogo(
      organisationId,
      image,
      authHeader
    );
  }

  async function submitForm(event: React.FormEvent<HTMLFormElement>) {
    try {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const organisationName = formData.get("name") as string;
      const organisationTypeId = formData.get("type") as string;
      const organisationLogo = formData.get("image") as File | null;

      if (!organisationName || organisationName === "") {
        setShowMandatoryAlert(true);
        return;
      }
      if (!organisationTypeId || organisationTypeId === "") {
        setShowMandatoryAlert(true);
        return;
      }
      if (!authHeader) return;
      const response =
        await organisationsApi.concularApiV1RoutersOrganisationsCreateOrganisation(
          {
            name: organisationName,
            organisation_type_id: organisationTypeId,
          },
          authHeader
        );
      if (organisationLogo && organisationLogo.name !== "") {
        await uploadLogo(response.data.id, organisationLogo);
      }
      organisationStore.setCurrentOrganisation(response.data);
      await organisationStore.getAllOrganisations();
      await buildingStore.getAllOrganizationsBuildings();
      userStore.setOnboarded(organisationStore.organisations.length > 0);
      navigate("/dashboard");
      close && close();
    } catch (err) {
      console.error("submitForm.err", err);
    }
  }

  return (
    <div className="md:max-w-lg pb-6 px-6 m-auto lg:h-auto md:h-[calc(100vh-120px)] flex flex-col justify-center">
      <Heading size="title" className={"mb-6"}>
        {t("onboarding.createNewOrganisation")}
      </Heading>
      <form onSubmit={submitForm} className="text-left">
        <Input
          name="name"
          labelName={t("onboarding.name")}
          placeholder={t("onboarding.organisationNamePlaceholder")}
          className={"mb-1"}
          value={formData.name}
          onChange={form.handleChange}
          required
          showMandatoryAlert={showMandatoryAlert}
          errorMessage={t("onboarding.nameErrorMsg")}
        />
        {organisationStore.organisationTypes?.length > 0 && (
          <SimpleSelect
            labelName={t("onboarding.organisationType")}
            name="type"
            options={organisationStore.organisationTypes}
            className={"mb-7"}
            placeholder={t("onboarding.organisationTypePlaceholder")}
            required
            onChange={form.handleChange}
            current={formData.type}
            showMandatoryAlert={showMandatoryAlert}
            errorMessage={t("onboarding.organisationTypeErrorMsg")}
          />
        )}
        <FileUploader
          labelName="Logo"
          name="image"
          containerClassName={"mb-6"}
        />
        <Button
          type="primary"
          leadingIcon={<PlusIcon />}
          size="default"
          htmlType="submit"
        >
          {t("onboarding.createOrganisation")}
        </Button>
      </form>
      <div className="mt-6 text-gray-500 text-sm">
        {t("onboarding.organisationsInfo")}
      </div>
    </div>
  );
};

export default observer(CreateOrganisation);
