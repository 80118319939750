import React from "react";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import { InstanceAddForm } from "features/Audit/handlers/handleInstanceAdd";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import AuditItemFormOnline from "features/AuditOnline/AuditItemFormOnline";
import { HandleBlueprintItemFormSubmitArgs } from "features/AuditOnline/models";
import { createInstanceByFormOnline } from "features/AuditOnline/utils";

const InstanceAddOnline = () => {
  const { t } = useTranslation();
  const { blueprint_id } = useParams();

  useDisableScrollOnNumberInputs();

  const { currentAudit: audit } = caOnlineStore;

  const handleSubmit = async (
    form: InstanceAddForm,
    args: HandleBlueprintItemFormSubmitArgs
  ) => {
    const createdInstance = createInstanceByFormOnline(
      form,
      args.selectedBlueprint.id,
      args.selectedProductGroup
    );

    if (createdInstance.room_id === "") {
      const roomId = await caOnlineStore.createRoomAndGetId(form.room_name);
      createdInstance.room_id = roomId;
    }

    console.log("createdInstance", createdInstance);

    await caOnlineStore.createInstanceSet(createdInstance);
  };

  return (
    <AuditItemFormOnline<InstanceAddForm>
      title={t("audits.addItems")}
      isInstance
      submitText={t("audits.createItem")}
      redirect={`/buildings/${audit.building_id}/audits-online/${audit.id}/blueprints/${blueprint_id}/instances`}
      handleSubmit={handleSubmit}
    />
  );
};

export default observer(InstanceAddOnline);
