import InputLabel from "components/InputLabel";
import React from "react";

interface TextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  labelName?: string;
  containerClass?: string;
}

export default function TextArea({
  containerClass,
  labelName,
  cols,
  rows,
  ...props
}: TextAreaProps) {
  props.className = `rounded-md mt-2 border-gray-300 lg:text-sm ${
    props.className ?? ""
  }`;
  return (
    <div className={`grid mb-4 ${containerClass} w-full mx-auto`}>
      {labelName && (
        <InputLabel
          required={props.required}
          labelName={labelName}
          name={props.name as string}
        />
      )}
      <textarea {...props} cols={cols ?? 30} rows={rows ?? 5} />
    </div>
  );
}
