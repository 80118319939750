import { BlueprintOUT, ManufacturerOUT } from "api-client";
import ButtonAsDiv from "components/ButtonAsDiv";
import validateComponentForm from "features/Audit/handlers/validateComponentForm";
import {
  makeBlueprintPropertyModel,
  makeBlueprintRawModel,
} from "features/Audit/utils";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { UserProfile, userStore } from "store/UserStore";
import {
  addedComponentImagesAtom,
  addedComponentsAtom,
  componentCurrentStepAtom,
  manufacturersAtom,
  selectedComponentAtom,
  selectedComponentProductGroupAtom,
} from "store/atoms/audits";
import { checkAndAddBlueprintId } from "utils";

export default observer(function SubmitComponentButton() {
  const { audit_id } = useParams();
  const { t } = useTranslation();
  const { userProfile } = userStore;
  const setComponentStep = useSetRecoilState(componentCurrentStepAtom);
  const setSelectedComponent = useSetRecoilState(selectedComponentAtom);
  const setAddedComponents = useSetRecoilState(addedComponentsAtom);
  const manufacturers = useRecoilValue(manufacturersAtom);
  const [selectedComponentProductGroup, setSelectedComponentProductGroup] =
    useRecoilState(selectedComponentProductGroupAtom);
  const setAddedComponentImages = useSetRecoilState(addedComponentImagesAtom);

  const handleComponentSubmit = React.useCallback(async () => {
    try {
      const form = validateComponentForm();
      if (!form) throw "form is undefined.";
      if (!selectedComponentProductGroup)
        throw "selectedComponent is undefined.";
      const componentFields = [
        ...selectedComponentProductGroup.required_fields,
        ...selectedComponentProductGroup.optional_fields,
      ];
      if (componentFields.length === 0) throw "componentFields.length is 0";
      const manufacturer: ManufacturerOUT | undefined =
        form.select_manufacturer && form.select_manufacturer.length > 0
          ? manufacturers?.find((m) => m.name === form.select_manufacturer)
          : undefined;

      let componentBlueprintID = "";
      setAddedComponents((prev: { [id: string]: BlueprintOUT }) => {
        const user = {
          email: userProfile?.email,
          first_name: userProfile?.first_name,
          last_name: userProfile?.first_name,
          username: userProfile?.username,
        } as UserProfile;
        const componentBlueprint = makeBlueprintRawModel(
          audit_id as string,
          selectedComponentProductGroup.id,
          user,
          {
            name: form.name,
            as_component_amount: Number(form.as_component_amount),
            manufacturer: manufacturer,
          }
        );
        componentBlueprintID = componentBlueprint.id;
        componentBlueprint.blueprintproperty_set = componentFields.map(
          (field) => {
            return makeBlueprintPropertyModel(
              field.id,
              componentBlueprint.id,
              form[field.id]
            );
          }
        );
        return {
          ...prev,
          [componentBlueprint.id]: componentBlueprint,
        };
      });
      if (form.images_component && form.images_component.length > 0) {
        setAddedComponentImages((prev) => ({
          ...prev,
          [componentBlueprintID]: form.images_component as unknown as File[],
        }));
      }
      await checkAndAddBlueprintId(componentBlueprintID);
      setComponentStep(0);
      setSelectedComponentProductGroup(null);
      setSelectedComponent(null);
    } catch (err) {
      console.error(err);
    }
  }, [manufacturers, selectedComponentProductGroup]);

  return (
    <ButtonAsDiv onClick={handleComponentSubmit}>
      {t("audits.addNewComponent")}
    </ButtonAsDiv>
  );
});
