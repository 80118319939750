import {
  AuditOUT,
  BlueprintOUT,
  InstanceOUT,
  ProductGroupOUT,
  UserProfileOUT,
} from "api-client";
import { setLocalAudit } from "api-client-local/audits";
import { makeInstancePropertyModel, makeInstanceRawModel } from "../utils";
import retrieveFields from "./retrieveFields";
import { db } from "../../../api-client-local/db";
import { HandleInstanceItemFormSubmitArgs } from "../AuditItemForm";
import { checkAndAddBlueprintId } from "utils";

export interface InstanceAddForm {
  [key: string]: string | string[];

  name: string;
  instance_amount: string;
  extra_info: string;
  room_name: string;
  floor_ids: string[];
}

interface HandleInstanceAddArgs extends HandleInstanceItemFormSubmitArgs {
  selectedProductGroup: ProductGroupOUT;
  userProfile: UserProfileOUT;
}

export default async function handleInstanceAdd(
  form: InstanceAddForm,
  args: HandleInstanceAddArgs,
  loadingCallback: (m: string) => void
) {
  loadingCallback("audits.sendingFormData");

  const blueprintIndex = args.audit.blueprint_set.findIndex(
    (b) => b.id === args.selectedBlueprint.id
  );

  if (!args.userProfile || !args.audit) {
    throw Error("userProfile or audit undefined.");
  }

  if (blueprintIndex < 0) return;

  // Because of read-only property.
  const auditCopy: AuditOUT = JSON.parse(JSON.stringify(args.audit));

  // START - Create single instance
  // const instanceAmount = Number(form.instance_amount);
  const createdInstance = createInstanceByForm(
    form,
    args.selectedBlueprint.id,
    args.selectedProductGroup,
    args.userProfile
  );

  auditCopy.blueprint_set[blueprintIndex].instance_set.push(createdInstance);

  /* START - Adding components, duplicating via amount */
  if (
    args.selectedBlueprint.components &&
    args.selectedBlueprint.components.length > 0
  ) {
    loadingCallback("audits.addingComponentData");
    for (const component of args.selectedBlueprint.components) {
      const originalBlueprintComponents =
        auditCopy.blueprint_set[blueprintIndex].components || [];
      const componentIndex = originalBlueprintComponents.findIndex(
        (b) => b.id === component.id
      );

      // we have a broken reference
      if (componentIndex < 0) {
        continue;
      }

      const componentInstance = createInstanceFromBlueprint(
        component,
        Number(form.instance_amount) * component.as_component_amount,
        args.userProfile,
        {
          room_name: form.room_name ?? "",
          floor_ids: parseFloorIds(form.floor_ids),
        }
      );

      originalBlueprintComponents[componentIndex].instance_set.push(
        componentInstance
      );
      auditCopy.blueprint_set[blueprintIndex].components =
        originalBlueprintComponents;
    }
    loadingCallback("audits.componentDataAdded");
  }
  /* END - Adding components, duplicating via amount */

  console.log("Blueprints", auditCopy.blueprint_set);
  console.log(
    "Instances",
    auditCopy.blueprint_set[blueprintIndex].instance_set
  );

  loadingCallback("audits.uploadingItemData");

  const auditRequest = {
    ...args.audit,
    id: args.audit.id,
    blueprint_set: auditCopy.blueprint_set,
  };
  const updatedLocalAudit = await setLocalAudit(auditRequest);

  if (args.images && args.images.length > 0) {
    loadingCallback("audits.uploadingItemImages");
    for (const image of args.images) {
      db.images.add({
        id: window.crypto.randomUUID(),
        sourceType: "instance",
        sourceId: args.instance_id || "",
        file: image as File,
        deleted: false,
        uploaded: false,
        order: 0,
        albumName: "default",
      });
    }
    loadingCallback("audits.uploadedImages");
  }

  await checkAndAddBlueprintId(args.selectedBlueprint.id);

  return updatedLocalAudit;
}

function createInstanceFromBlueprint(
  blueprint: BlueprintOUT,
  instanceAmount: number,
  user: UserProfileOUT,
  instanceArgs?: Partial<InstanceOUT>
) {
  const instance = makeInstanceRawModel(blueprint.id, user, {
    name: blueprint.name,
    name_de: blueprint.name_de ?? blueprint.name ?? "",
    extra_info: blueprint.extra_info ?? "",
    instance_amount: instanceAmount,
    ...instanceArgs,
  });
  const instanceProps = blueprint.blueprintproperty_set.map((p) => {
    return makeInstancePropertyModel(
      p.field_id,
      instance.id,
      (p.value_datetime ??
        p.value_numeric ??
        p.value_string ??
        p.value_field_option_ids) as string | number | string[]
    );
  });
  instance.instanceproperty_set = instanceProps;
  return instance;
}

export function createInstanceByForm(
  form: InstanceAddForm,
  blueprintId: string,
  pg: ProductGroupOUT,
  user: UserProfileOUT
) {
  const { foundFields } = retrieveFields(
    form,
    pg.required_fields,
    pg.optional_fields
  );

  const instance = makeInstanceRawModel(blueprintId, user, {
    name: form.name,
    room_name: form.room_name ?? "",
    floor_ids: parseFloorIds(form.floor_ids),
    extra_info: form.extra_info,
    instance_amount: Number(form.instance_amount),
  });

  const instanceProperties = foundFields.map((found) => {
    return makeInstancePropertyModel(found.field.id, instance.id, found.value);
  });

  instance.instanceproperty_set = instanceProperties;

  return instance;
}

export function parseFloorIds(floor_ids: string[] | string | []) {
  if (!floor_ids) return [];
  let floorIds = [];
  if (typeof floor_ids === "string") {
    if (floor_ids.includes(",")) {
      floorIds = floor_ids.split(",");
    } else {
      floorIds = [floor_ids];
    }
  } else {
    floorIds = floor_ids;
  }

  return floorIds.filter((id) => id !== "") ?? []; // Remove empty strings from array
}
