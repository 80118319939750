import React from "react";
import { useRecoilValue } from "recoil";
import { selectedProductGroupAtom, stepAtom } from "store/atoms/audits";
import GeneratedFields from "../../../../features/Audit/GeneratedFields";
import { ContinueButton, SkipButton } from "../navigation";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import { DataFetcherStrip } from "..";
import Collapse from "components/Collapse";
import useManufacturerAndProductGroups from "../useManufacturerAndProductGroups";
import { LoadingView } from "features/Audit/LoadingView";
import { FieldOUT } from "api-client";

export default function Step2() {
  const selectedProductGroup = useRecoilValue(selectedProductGroupAtom);
  const { t, i18n } = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);
  const isEng = i18n.language === "en";
  const currentStep = useRecoilValue(stepAtom);
  useDisableScrollOnNumberInputs();

  const { fetchDataAsync, loading } = useManufacturerAndProductGroups();

  React.useEffect(() => {
    if (!ref?.current) return;
    if (currentStep !== 1) return;
    setTimeout(() => {
      if (!ref?.current) return;
      const firstInput = ref.current.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      }
    }, 500);
  }, [ref, currentStep]);

  if (loading) {
    return (
      <div className="items-center justify-center flex h-[50vh]">
        <LoadingView message={loading} />
      </div>
    );
  }

  const fieldsets = [...(selectedProductGroup?.blueprint_field_set || [])].sort(
    (a, b) => a.order_of_appearance - b.order_of_appearance
  );

  if (!selectedProductGroup) {
    return <div>{t("audits.productGroupMustBeSelected")}</div>;
  } else {
    return (
      <>
        {fieldsets.map((rec, index) => (
          <Collapse
            headerTitle={(isEng ? rec.name : rec.name_de) as string}
            containerClassName="border border-gray-200 mb-6 rounded"
            className="p-4"
            key={rec.id}
            defaultClose={index !== 0}
          >
            <GeneratedFields
              ref={ref}
              generatesDimensions
              generatesColor
              required_fields={rec.required_fields as FieldOUT[]}
              optional_fields={rec.optional_fields as FieldOUT[]}
            />
          </Collapse>
        ))}
        <div className="flex justify-evenly gap-4">
          <SkipButton />
          <ContinueButton />
        </div>
        <DataFetcherStrip onDataFetching={() => fetchDataAsync(false)} />
      </>
    );
  }
}
