import React from "react";
import { useRecoilState } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import AuditItemForm, {
  HandleInstanceItemFormSubmitArgs,
} from "features/Audit/AuditItemForm";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import handleInstanceAdd, {
  InstanceAddForm,
} from "features/Audit/handlers/handleInstanceAdd";
import { useParams } from "react-router";

export default function InstanceAdd() {
  const [audit, setAudit] = useRecoilState(currentAuditAtom);
  const { t } = useTranslation();
  const { blueprint_id } = useParams();

  useDisableScrollOnNumberInputs();

  const handleSubmit = async (
    form: InstanceAddForm,
    args: HandleInstanceItemFormSubmitArgs
  ) => {
    const updatedAudit = await handleInstanceAdd(form, args, (message) =>
      args.setFormLoading(message !== "" ? t(message) : "")
    );
    if (updatedAudit) {
      setAudit(updatedAudit);
    }
  };

  if (!audit) return null;

  return (
    <AuditItemForm<InstanceAddForm>
      title={t("audits.addItems")}
      isInstance
      submitText={t("audits.createItem")}
      redirect={`/buildings/${audit.building_id}/audits/${audit.id}/blueprints/${blueprint_id}/instances`}
      handleSubmit={handleSubmit}
    />
  );
}
