import {
  FetchReportTileIN,
  IFCProperties,
  InventoryPhysicalObjectDetailOut,
  ReportTileInfo,
  ReportTileReport,
  SingleItem,
  detailReportTileApi,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable } from "mobx";
import { MaterialFileObject } from "./types";

interface ModuleItem {
  module_name: string;
  value: number;
  unit: string;
}

interface LCAIndicator {
  indicator_name: string;
  value: number;
  unit: string;
  modules: ModuleItem[];
}

interface CircularityItem {
  field_name: string;
  value: number;
}

interface FinancialItem {
  field_name: string;
  value: number;
}
export interface DVReports {
  mass: FinancialItem;
  lca_indicator_values: LCAIndicator[];
  circularity: CircularityItem[];
  financial: number;
}

export interface InfoItemProps {
  field_name?: string | null;
  field_name_de?: string | null;
  value?: string | number | null;
  unit: string;
}

// MobX Store
class DetailViewV2Store {
  infoTileData: ReportTileInfo | null | undefined = undefined;
  reportsTileData: ReportTileReport | null | undefined = undefined;
  ifcPropertiesData: IFCProperties | null | undefined = undefined;
  physicalObject:
    | InventoryPhysicalObjectDetailOut
    | MaterialFileObject
    | SingleItem
    | undefined = undefined;
  info: boolean = true;
  reports: boolean = false;
  ifc_properties: boolean = false;
  loading: boolean = false;
  objectId: string | undefined | null = undefined;
  currentIndex = 0;
  apiError: unknown = null;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchDetailViewData() {
    if (!this.objectId || this.loading) {
      return;
    }

    if (this.apiError) {
      this.setAPIError(null);
    }

    const fetchReportTileIN: FetchReportTileIN = {
      object_id: this.objectId,
      report_keys: {
        info: this.info,
        reports: this.reports,
        ifc_properties: this.ifc_properties,
      },
    };

    this.setLoading(true);

    try {
      const authHeader = await getLocalAuthHeader();
      const response =
        await detailReportTileApi.inventoryApiV1RoutersDetailReportTileFetchReportTile(
          fetchReportTileIN,
          authHeader
        );
      if (this.info) {
        this.setInfoTileData(response.data.info);
      } else if (this.reports) {
        this.setReportsData(response.data.reports);
      } else if (this.ifc_properties) {
        this.setIfcPropertiesData(response.data.ifc_properties);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      this.setAPIError(error);
    } finally {
      this.setLoading(false);
    }
  }

  resetDetailViewData() {
    this.infoTileData = null;
    this.reportsTileData = null;
    this.ifcPropertiesData = null;
  }

  setAPIError(apiError: unknown) {
    this.apiError = apiError;
  }

  setCurrentIndex(index: number) {
    this.currentIndex = index;
  }

  setObjectId(objectId: string | undefined | null) {
    this.objectId = objectId;
  }

  setInfoTileData(infoTileData: ReportTileInfo | null | undefined) {
    this.infoTileData = infoTileData;
  }

  setReportsData(reportsData: ReportTileReport | null | undefined) {
    this.reportsTileData = reportsData;
  }

  setIfcPropertiesData(ifcPropertiesData: IFCProperties | null | undefined) {
    this.ifcPropertiesData = ifcPropertiesData;
  }

  setLoading(value: boolean) {
    this.loading = value;
  }

  setInfo(value: boolean) {
    this.info = value;
  }

  setReports(value: boolean) {
    this.reports = value;
  }

  setIfcProperties(value: boolean) {
    this.ifc_properties = value;
  }

  setPhysicalObject(
    physicalObject:
      | InventoryPhysicalObjectDetailOut
      | MaterialFileObject
      | SingleItem
  ) {
    this.physicalObject = physicalObject;
  }

  get isObjectMapped() {
    return this.physicalObject?.product_match;
  }

  resetOnObjectTabChange() {
    this.infoTileData = undefined;
    this.reportsTileData = undefined;
    this.ifcPropertiesData = undefined;
  }

  async setContentBasedOnTabIndex() {
    if (this.apiError) {
      this.setAPIError(null);
    }

    switch (this.currentIndex) {
      case 0:
        this.setInfo(true);
        this.setReports(false);
        this.setIfcProperties(false);
        if (!this.infoTileData) {
          await this.fetchDetailViewData();
        }
        break;

      case 1:
        this.setInfo(false);
        this.setReports(true);
        this.setIfcProperties(false);
        if (!this.reportsTileData && this.physicalObject?.product_match?.id) {
          await this.fetchDetailViewData();
        }

        break;

      case 2:
        this.setInfo(false);
        this.setReports(false);
        this.setIfcProperties(true);
        if (!this.ifcPropertiesData) {
          await this.fetchDetailViewData();
        }
        break;
      default:
        this.setInfo(true);
        this.setReports(false);
        this.setIfcProperties(false);
        break;
    }
  }
}

// Create an instance of the MobX store
const detailViewV2Store = new DetailViewV2Store();

export { DetailViewV2Store, detailViewV2Store };
