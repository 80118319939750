import React from "react";
import { useParams } from "react-router";
import { useRecoilState } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import useSyncStatus, { StatusName } from "./useSyncStatus";
import { MissingFieldsList } from "./missingFieldsList";
import { performSync } from "./performSync";
import { performExport } from "./performExport";
import { ErrorLocation, handleSyncError } from "./lib";
import { useTranslation } from "react-i18next";
import ConfirmationPopup from "../ConfirmationPopup";
import StorageInformation from "../StorageInformation";
import Button from "components/Button";
import { CloudUploadIcon } from "@heroicons/react/solid";
import { performRestore } from "./performRestore";

export type TSyncStatus = "success" | "failure" | "";
interface ISyncProps {
  onSyncCompleted: (syncStatus: TSyncStatus) => void;
}

let canRestore = false;

export default function SyncBar({ onSyncCompleted }: ISyncProps) {
  const { audit_id } = useParams();
  const { t } = useTranslation();
  const [currentAudit, setCurrentAudit] = useRecoilState(currentAuditAtom);
  const [modalType, setModalType] = React.useState<
    "sync" | "export" | "reset" | "syncAndRestore" | ""
  >("");
  const { status, setStatus, statuses } = useSyncStatus();
  const [missingFields, setMissingFields] = React.useState<ErrorLocation[]>([]);
  const [open, setOpen] = React.useState(false);
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleSync = React.useCallback(async () => {
    setStatus("updating");
    try {
      await performSync({
        setStatus: (message: string) => {
          setStatus(message as StatusName);
        },
        audit_id,
        setCurrentAudit,
        setMissingFields,
      });
      setStatus("updated");
      onSyncCompleted("success");
      setTimeout(() => {
        if (canRestore || modalType === "syncAndRestore") {
          restore();
        }
        setStatus("initial");
        onSyncCompleted("");
      }, 2000);
    } catch (error) {
      console.error("SyncBar.handleSync", error);
      onSyncCompleted("failure");
      handleSyncError({
        setStatus: (message: string) => {
          setStatus(message as StatusName);
        },
        audit_id,
        setCurrentAudit,
        setMissingFields,
        error,
      });
    }
  }, [audit_id]);

  const exportZip = React.useCallback(async () => {
    if (!audit_id) {
      return;
    }
    await performExport(audit_id, setStatus);
  }, [audit_id]);

  const restore = React.useCallback(async () => {
    if (!audit_id) {
      return;
    }
    setStatus("restoring");
    await performRestore({
      audit_id,
      setCurrentAudit,
      t,
    });
    setStatus("restored");
    setTimeout(() => {
      setStatus("initial");
      onSyncCompleted("");
    }, 2500);
  }, [audit_id]);

  function getModalTitle() {
    switch (modalType) {
      case "sync":
        return t("audits.sync");
      case "export":
        return t("audits.export");
      case "reset":
        return t("buildingMaterialResources.clearStorage");
      case "syncAndRestore":
        return t("audits.syncAction");
      default:
        return "";
    }
  }

  const handleModalYes = async () => {
    setOpen(false);
    if (modalType === "sync") {
      handleSync();
    } else if (modalType === "export") {
      exportZip();
    } else if (modalType === "reset") {
      restore();
    } else if (modalType === "syncAndRestore") {
      handleSync();
      setModalType("syncAndRestore");
    } else {
      console.error("Modal type not defined!");
    }
  };

  const initialStatusBar = (
    <>
      <ConfirmationPopup
        open={open}
        setOpen={setOpen}
        title={getModalTitle()}
        onPressYes={handleModalYes}
      />
      <div
        onClick={() => {
          if (showTooltip) {
            setShowTooltip(false);
          }
        }}
        className="bg-white sm:h-[80px] rounded-md px-4 py-2 w-full flex flex-row items-center justify-between"
      >
        <Button
          className="py-2 px-4 rounded w-[90px] md:w-[150px]"
          onClick={() => {
            setModalType("sync");
            setOpen(true);
          }}
          type="secondary"
          leadingIcon={<CloudUploadIcon className="text-indigo-600" />}
        >
          {t("audits.sync")}
        </Button>
        <StorageInformation
          showTooltip={showTooltip}
          onToggleTooltip={(val) => {
            setShowTooltip(val);
          }}
          onExport={() => {
            setModalType("export");
            setOpen(true);
          }}
          onResetStorage={() => {
            setModalType("reset");
            setOpen(true);
          }}
          onSyncAndClearStorage={() => {
            canRestore = true;
            setModalType("syncAndRestore");
            setOpen(true);
          }}
        />
      </div>
    </>
  );

  const inProgressStatusBar = (
    <div
      className={`cursor-pointer h-[80px] text-md ${statuses[status].color} ${statuses[status].bgColor} rounded-md px-4 py-2 w-full flex flex-row items-center justify-center`}
    >
      {statuses[status].icon}
      {statuses[status].text}
      {status === "missingFields" && missingFields.length > 0 && (
        <MissingFieldsList
          missingFields={missingFields}
          currentAudit={currentAudit}
        />
      )}
    </div>
  );

  return status == "initial" ? initialStatusBar : inProgressStatusBar;
}
