import { useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";

export default function useSignout() {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const signOut = async () => {
    try {
      logout({ returnTo: window.location.origin });
      indexedDB.deleteDatabase("concular-db");
      navigate("/log-in", { replace: true });
      console.log("Sign out succesfully");
    } catch (error) {
      console.error("signOut", error);
    }
  };

  return signOut;
}
