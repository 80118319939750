import React from "react";
import { ProductGroupOUT } from "api-client";
import TextSearchInput from "components/TextSearchInput";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { productGroupsAtom } from "store/atoms/audits";

interface SelectProps {
  onChange: (value: string | undefined, name?: string) => void;
  onReset: () => void | Promise<void>;
}

export default function SelectProductGroup(props: SelectProps) {
  return (
    <SelectProductGroupDexie
      onReset={props.onReset}
      onChange={props.onChange}
    ></SelectProductGroupDexie>
  );
}

function SelectProductGroupDexie(props: SelectProps) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const productGroups = useRecoilValue(productGroupsAtom);
  if (!productGroups || productGroups.length === 0) {
    return (
      <div className="flex items-center">Product groups could not loaded.</div>
    );
  }
  const list = [...productGroups].sort((a, b) =>
    a[isEng ? "name" : "name_de"].localeCompare(b[isEng ? "name" : "name_de"])
  );

  return (
    <TextSearchInput<ProductGroupOUT>
      displayKey={isEng ? "name" : "name_de"}
      required={true}
      handleChange={props.onChange}
      onReset={props.onReset}
      labelName={t("audits.selectProductGroup")}
      name="select_product_group"
      items={list}
    />
  );
}
