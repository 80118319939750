import React, { Fragment } from "react";
import InputLabel from "components/InputLabel";
import { Combobox, Transition } from "@headlessui/react";
import { SearchIcon, XIcon } from "@heroicons/react/outline";
import useSwitch from "hooks/useSwitch";
import { useTranslation } from "react-i18next";
import useInvalidInput from "hooks/useInvalidInput";
import { slugify } from "utils";
import clsx from "clsx";

export interface TextSearchItem {
  id: string;
  name: string;
}
interface TextSearchInputProps<T> extends React.HTMLProps<HTMLInputElement> {
  labelName?: string;
  enforceRequired?: boolean;
  containerClassName?: string;
  items: T[];
  displayKey: keyof T;
  selectedId?: string;
  handleChange?: (value: string) => void;
  onReset?: () => void | Promise<void>;
  handleSelect?: (value: string, name?: string) => void;
}

export default function TextSearchInput<Item extends { id?: string }>(
  props: TextSearchInputProps<Item>
) {
  const [selected, setSelected] = React.useState<Item>(
    props.items.find((item) => item.id === props.selectedId) || ({} as Item)
  );
  const [query, setQuery] = React.useState("");
  const [showError, switchShowError] = useSwitch(false);
  const [focused, switchFocused] = useSwitch(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!props.selectedId) return;
    const found = props.items.find((i) => i.id && i.id === props.selectedId);
    if (!found) return;
    setSelected(found);
  }, [props.selectedId]);

  const filteredItems =
    query === ""
      ? props.items
      : props.items?.filter((item) => {
          let resolved;
          if (props.displayKey in item) {
            resolved = item[props.displayKey];
          } else return;
          const condition = slugify(resolved as unknown as string).includes(
            slugify(query)
          );
          return condition;
        });

  useInvalidInput(inputRef, (isInvalid) => {
    if (isInvalid) {
      switchShowError.on();
    } else {
      switchShowError.off();
    }
  });

  const isColorField =
    props.labelName === "Color" || props.labelName === "Farbe";

  return (
    <div
      style={props.style}
      className={`${props.containerClassName ?? ""} mb-4 relative`}
    >
      {props.labelName && (
        <InputLabel
          name={props.name as string}
          labelName={props.labelName}
          required={props.required}
          showError={showError}
        />
      )}
      <Combobox value={selected} onChange={setSelected}>
        <Combobox.Button as={"div"} className={"w-full"}>
          <div>
            <div
              className={clsx(
                "relative w-full cursor-default overflow-hidden rounded-md border border-gray-300",
                "border-gray-300 bg-white text-left",
                showError ? "border border-red-700 text-red-700" : "",
                focused
                  ? "outline-none ring-2 ring-offset-2 ring-offset-indigo-500 ring-white border-white"
                  : ""
              )}
            >
              <Combobox.Input
                ref={inputRef}
                onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                  props.handleChange && props.handleChange(e.target.value);
                }}
                data-invalid={"false"}
                required={props.required && props.enforceRequired !== false}
                autoCorrect="off"
                autoComplete="off"
                placeholder={t("audits.search")}
                onInvalid={() => switchShowError.on()}
                name={props.name}
                className={clsx(
                  "w-full placeholder:text-gray-400 placeholder:font-light pl-3 border-none",
                  "pr-10 text-sm leading-5"
                )}
                onBlur={() => switchFocused.off()}
                onFocus={() => switchFocused.on()}
                displayValue={(item: Item) => {
                  let result;
                  if (!item) return "";
                  if (props.displayKey in item) {
                    result = item[props.displayKey];
                  } else return "";
                  return (result as unknown as string).toString();
                }}
                onChange={(event) => {
                  switchShowError.off();
                  setQuery(event.target.value);
                }}
              />
              {selected && (
                <div
                  onClick={() => {
                    setSelected({} as Item);
                    setQuery("");
                    props.onReset && props.onReset();
                  }}
                  className="absolute cursor-pointer h-full inset-y-0 right-6 flex items-center mr-2"
                >
                  <XIcon
                    width={20}
                    className="text-gray-500 rounded-full bg-gray-100 p-1"
                  />
                </div>
              )}
              <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                <SearchIcon
                  className="h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery("")}
            >
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 min-w-full w-fit overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-10 focus:outline-none">
                {filteredItems?.length === 0 && query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    {t("commons.noDataFound")}
                  </div>
                ) : (
                  filteredItems?.map((item, i) => (
                    <Combobox.Option
                      key={i}
                      className={({ active }) =>
                        `relative cursor-default select-none py-2 pl-4 pr-4 ${
                          active ? "bg-gray-100" : "text-gray-900"
                        }`
                      }
                      value={item}
                    >
                      {({ selected }) => (
                        <div className="flex items-center">
                          {isColorField && (
                            <div
                              className="h-4 w-4 rounded-sm mr-2"
                              style={{
                                backgroundColor: `${
                                  (item as unknown as { value: string }).value
                                }`,
                              }}
                            ></div>
                          )}
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {props.displayKey in item
                              ? (item[props.displayKey] as unknown as string)
                              : null}
                          </span>
                        </div>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox.Button>
      </Combobox>
    </div>
  );
}
