import React, { useMemo } from "react";
import { IFCFileOUT, MaterialResourceOUT } from "api-client";
import { useTranslation } from "react-i18next";
import { MaterialResourcesActions } from "./MaterialResourcesActions";
import Button from "components/Button";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import FileToggle from "./FileToggle";
import { useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import {
  modalQualityAtom,
  openMQPopupAtom,
  sidebarOpenedAtom,
} from "store/atoms";
import {
  ModalQualityChip,
  ModelQualityNoneChip,
  ProcessingStatus,
} from "features/MappingTools/utils";
import useFeatureFlag from "hooks/useFeatureFlag";
import Tooltip from "components/Tooltip";
import { buildingStore } from "store/Building/BuildingStore";
import { isFailedStatus } from "utils";

const CreateMaterialResourcesTableColumns = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { materialResources } = buildingStore;
  const navigate = useNavigate();
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);
  const setOpenModalQuality = useSetRecoilState(openMQPopupAtom);
  const setModalQuality = useSetRecoilState(modalQualityAtom);

  const setMapping = async (ifcFile: MaterialResourceOUT, type?: string) => {
    navigate(`../${type ? "mapping-editor" : "mapping"}/${ifcFile.id}`, {
      replace: true,
      state: ifcFile.name ?? "",
    });
  };

  const { isFeatureVisible } = useFeatureFlag();
  const modelCheckerAvailable = isFeatureVisible("model_checker_available");

  function TableHeader({
    title,
    titleStyle = "",
    toolTipText,
  }: {
    title: string;
    titleStyle?: string;
    toolTipText?: string;
  }) {
    return (
      <div
        className={`w-full font-semibold text-[14px] flex items-center ${titleStyle}`}
      >
        {title}
        {toolTipText ? (
          <Tooltip content={toolTipText} tooltipClassName="!w-[320px]" />
        ) : null}
      </div>
    );
  }

  function checkDate(dateString: string): boolean {
    const date = moment(dateString);
    const juneFirst2023 = moment("2023-06-01");

    return date.isBefore(juneFirst2023);
  }

  function fileNameCell(info: CellContext<MaterialResourceOUT, unknown>) {
    return (
      <div className="px-1">
        <Tooltip truncate className="max-w-[150px]">
          {String(
            info.row.original.file_type === "Audit"
              ? info.row.original.title
              : info.row.original.name ?? info.row.original.file_name
          )}
        </Tooltip>
      </div>
    );
  }

  function uploadDateCell(info: CellContext<MaterialResourceOUT, unknown>) {
    return (
      <div className="font-light">
        {info.row.original.file_type !== "Audit"
          ? moment(info.row.original.created).format("DD.MM.YYYY")
          : ""}
      </div>
    );
  }

  function showStatusChip(info: CellContext<MaterialResourceOUT, unknown>) {
    if (info.row.original.file_type !== "Audit") {
      return (
        <ProcessingStatus
          status={info.row.original.state as string}
          mapped={info.row.original.total_no_of_mapped_material_names}
          total={info.row.original.total_unique_material_names}
        />
      );
    } else {
      return null;
    }
  }

  function mappingCell(
    row: CellContext<MaterialResourceOUT, unknown>,
    type?: string
  ) {
    return row.row.original.file_type !== "Audit" ? (
      <div className="flex items-center">
        <Button
          type="secondary"
          className="text-xs w-10 h-10 whitespace-nowrap"
          onClick={() => {
            setMapping(row.row.original, type);
            setSidebarOpened(false);
          }}
          width="fit-content"
          disable={
            isFailedStatus(row.row.original?.state) ||
            !row.row.original.state?.includes("reports_ready")
          }
          testID={`file_${row.row.original.id}`}
        >
          {t("buildingMaterialResources.mapProducts")}
        </Button>
      </div>
    ) : null;
  }

  function modelQualityCell(info: CellContext<MaterialResourceOUT, unknown>) {
    const isOldRecord = checkDate(info.row.original.created) ?? false;
    const discarded = info.row.original.report?.discarded_objects;
    const total = info.row.original.report?.total_objects;
    const imported = (total || 0) - (discarded || 0);

    const canshowModalChip =
      info.row.original.report?.source_type?.toLowerCase() === "ifc";

    const percentage =
      (total || 0) > 0 ? Math.round((100 * (imported || 0)) / (total || 1)) : 0;

    const report = {
      imported,
      total,
      percentage,
    };

    return canshowModalChip ? (
      <>
        {isFailedStatus(info.row.original.state) ||
        !info.row.original.state?.includes("reports_ready") ? (
          <></>
        ) : report !== null && report.percentage !== 0 ? (
          <ModalQualityChip
            report={report}
            onClick={(report) => {
              setOpenModalQuality(true);
              setModalQuality(report);
            }}
          />
        ) : (
          isOldRecord && <ModelQualityNoneChip />
        )}
      </>
    ) : null;
  }

  function activeFileCell(row: CellContext<MaterialResourceOUT, unknown>) {
    return row.row.original.file_type !== "Audit" ? (
      <div className="flex items-center pl-3">
        <FileToggle
          disabled={
            isFailedStatus(row.row.original.state) ||
            !row.row.original.state?.includes("reports_ready")
          }
          ifcFile={row.row.original as IFCFileOUT}
          checked={row.row.original.reporting_status === "ACTIVE"}
        />
      </div>
    ) : null;
  }

  function actionsCell(row: CellContext<MaterialResourceOUT, unknown>) {
    return row.row.original.file_type !== "Audit" ? (
      <div className="flex">
        <MaterialResourcesActions
          disabled={
            isFailedStatus(row.row.original.state) ||
            !row.row.original.state?.includes("reports_ready")
          }
          file={row.row.original}
        />
      </div>
    ) : (
      <></>
    );
  }

  return useMemo<ColumnDef<MaterialResourceOUT>[]>(
    () => [
      {
        accessorFn: (row) => row.name,
        id: "name",
        cell: (info) => fileNameCell(info),
        header: () => (
          <TableHeader title={t("buildingMaterialResources.fileName")} />
        ),
        enableColumnFilter: false,
      },
      {
        accessorFn: (row) => row.file_type,
        id: "type",
        cell: (info) => (
          <div className="flex items-center">
            {info.row.original.file_type ?? ""}
          </div>
        ),
        header: () => (
          <TableHeader
            titleStyle={isEng ? "w-9" : "w-5"}
            title={t("buildingMaterialResources.type")}
          />
        ),
        enableColumnFilter: false,
        footer: (props) => props.column,
        size: 40,
      },
      {
        accessorFn: (row) => row.created,
        id: "Upload date",
        cell: (info) => uploadDateCell(info),
        header: () => (
          <TableHeader title={t("buildingMaterialResources.uploadDate")} />
        ),
        enableColumnFilter: false,
        size: 80,
      },
      {
        accessorFn: (row) => row.uploaded_by,
        id: "Uploaded by",
        cell: (row) => (
          <div className="font-light">
            {`${row.row.original.uploaded_by?.first_name ?? ""} ${
              row.row.original.uploaded_by?.last_name ?? ""
            }`}
          </div>
        ),
        header: () => (
          <TableHeader title={t("buildingMaterialResources.uploadedBy")} />
        ),
        enableColumnFilter: false,
        size: 70,
      },
      ...(modelCheckerAvailable
        ? [
            {
              accessorFn: (row: MaterialResourceOUT) => row,
              id: "ModalQuality",
              header: () => (
                <TableHeader
                  title={t("buildingMaterialResources.modalQuality")}
                  toolTipText={t(
                    "buildingMaterialResources.modalQualityHeaderTooltip"
                  )}
                />
              ),
              enableColumnFilter: false,
              enableSorting: false,
              cell: (info: CellContext<MaterialResourceOUT, unknown>) =>
                modelQualityCell(info),
            },
          ]
        : []),
      {
        accessorFn: (row) => row,
        id: "Status",
        header: () => (
          <TableHeader
            title={t("buildingMaterialResources.state")}
            toolTipText={t("buildingMaterialResources.stateTooltip")}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => showStatusChip(info),
        maxWidth: "auto",
      },
      ...(!isFeatureVisible("mapping_editor_v2")
        ? [
            {
              accessorFn: (row: unknown) => row,
              id: "MapFile",
              cell: (row: CellContext<MaterialResourceOUT, unknown>) =>
                mappingCell(row),
              enableColumnFilter: false,
              enableSorting: false,
              header: () => (
                <TableHeader
                  title={t("buildingMaterialResources.mapping")}
                  toolTipText={t("buildingMaterialResources.mappingTooltip")}
                />
              ),
              maxWidth: "auto",
            },
          ]
        : []),
      ...(isFeatureVisible("mapping_editor_v2")
        ? [
            {
              accessorFn: (row: unknown) => row,
              id: "Mapping",
              cell: (row: CellContext<MaterialResourceOUT, unknown>) =>
                mappingCell(row, "new"),
              enableColumnFilter: false,
              enableSorting: false,
              header: () => (
                <TableHeader
                  title={t("buildingMaterialResources.mapping")}
                  toolTipText={t("buildingMaterialResources.mappingTooltip")}
                />
              ),
              maxWidth: "auto",
            },
          ]
        : []),
      {
        accessorFn: (row) => row,
        id: "activeFile",
        cell: (row) => activeFileCell(row),
        enableColumnFilter: false,
        enableSorting: false,
        header: () => (
          <TableHeader
            title={t("buildingMaterialResources.activeFile")}
            toolTipText={t("buildingMaterialResources.activeFileTooltip")}
          />
        ),
        size: 60,
      },
      {
        accessorFn: (row) => row,
        id: "Actions",
        header: () => <div>{""}</div>,
        cell: (row) => actionsCell(row),
        enableColumnFilter: false,
        enableSorting: false,
        size: 40,
      },
    ],
    [materialResources.items, isEng]
  );
};

export default CreateMaterialResourcesTableColumns;
