import React from "react";
import clsx from "clsx";
import FooterRowCount from "./FooterRowCount";
import { searchStore } from "store/IfcMapping/SearchStore";
import { observer } from "mobx-react-lite";

const ProductsListContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  const fetchNextPage = async () => {
    if (searchStore.productSearchLoading) return;
    try {
      searchStore.setProductSearchLoading(true);
      const nextPage = searchStore.currentSearchPage + 1;
      await searchStore.getProducts(nextPage);
    } catch (error) {
      console.error("Error fetching search Products: ", error);
    } finally {
      searchStore.setProductSearchLoading(false);
    }
  };

  const onMouseScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    e.stopPropagation();
    const { scrollHeight, scrollTop, clientHeight } =
      e.target as HTMLDivElement;
    if (
      scrollTop + clientHeight >= scrollHeight - 5 &&
      searchStore.searchResults.count > searchStore.searchResults.items.length
    ) {
      fetchNextPage();
    }
  };

  return (
    <>
      <div
        className={clsx(
          "relative overflow-y-scroll h-[calc(100vh-355px)] pb-10",
          className
        )}
        onScroll={onMouseScroll}
      >
        {children}
      </div>
      <div className="h-6">
        <FooterRowCount />
      </div>
    </>
  );
};

export default observer(ProductsListContainer);
