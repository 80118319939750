import React from "react";
import { useParams } from "react-router-dom";
import PingAnimation from "components/PingAnimation";
import { addValuesToFieldsFromProperties } from "./utils";
import GeneratedInput from "features/Audit/GeneratedInput";
import useBlueprint from "hooks/useBlueprint";

export default function AuditItemInputById(props: { name: string }) {
  const { blueprint_id, instance_id } = useParams();
  const { selectedProductGroup, selectedBlueprint } =
    useBlueprint(blueprint_id);

  if (!selectedProductGroup || !selectedBlueprint) {
    return <PingAnimation color="gray" size="small" />;
  }

  let isOptionalField = false;

  let foundFieldIndex = selectedProductGroup.required_fields.findIndex(
    (f) => f.name === props.name
  );

  if (foundFieldIndex === -1) {
    isOptionalField = true;
    foundFieldIndex = selectedProductGroup.optional_fields.findIndex(
      (f) => f.name === props.name
    );
  }

  if (foundFieldIndex === -1) return null;

  const foundInstance = instance_id
    ? selectedBlueprint.instance_set.find(
        (instance) => instance.id === instance_id
      )
    : null;

  const requiredDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.required_fields,
    foundInstance
      ? foundInstance.instanceproperty_set
      : selectedBlueprint.blueprintproperty_set
  );

  const optionalDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.optional_fields,
    foundInstance
      ? foundInstance.instanceproperty_set
      : selectedBlueprint.blueprintproperty_set
  );

  let foundField;
  let foundFieldDefaultValue;

  if (isOptionalField) {
    foundField = selectedProductGroup.optional_fields[foundFieldIndex];
    foundFieldDefaultValue = optionalDefaultValues[foundFieldIndex];
  } else {
    foundField = selectedProductGroup.required_fields[foundFieldIndex];
    foundFieldDefaultValue = requiredDefaultValues[foundFieldIndex];
  }

  return (
    <GeneratedInput
      index={0}
      required
      defaultValue={foundFieldDefaultValue ?? undefined}
      data={foundField}
    />
  );
}
