import React from "react";
import { BlueprintOUT, ProductGroupOUT, SlimBlueprintOUT } from "api-client";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import ItemAmount from "features/Audit/ItemAmount";
import { ItemImage } from "./ItemImage";
import { ComponentListItem } from "./ComponentListItem";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import {
  handleBlueprintDelete,
  handleBlueprintDuplicate,
  handleBlueprintMultiselect,
} from "./utils";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";

interface BlueprintListItemOnlineProps {
  item: SlimBlueprintOUT;
}

const BlueprintListItemOnline = ({ item }: BlueprintListItemOnlineProps) => {
  const { t } = useTranslation();
  const [pg, setPg] = React.useState<ProductGroupOUT>();
  const { productGroups } = caOnlineStore;

  React.useEffect(() => {
    setPg(
      productGroups
        ? productGroups.find(
            (p: ProductGroupOUT) => p.id === item.product_group_id
          )
        : undefined
    );
  }, [productGroups]);

  return (
    <div>
      <div className="flex items-center justify-between w-full border-b px-4">
        <Link
          onClick={() => {
            caOnlineStore.setIsComponent(false);
            caOnlineStore.setSelectedBPOrComponents(item as BlueprintOUT);
          }}
          to={`blueprints/${item.id}/instances`}
          className="flex py-3 items-center"
        >
          <ItemImage itemId={item.id} itemAlbums={item.albums} />
          <span className="text-sm w-full">{item.name}</span>
        </Link>
        <div className="flex items-center space-x-2">
          <ItemAmount
            value={item.total_instance_amount ?? 0}
            unit={pg?.unit ?? "pcs"}
          />
          <EditDeleteItemMenu
            withDuplicate
            withMultiSelect
            id={item.id}
            url={`blueprints/${item.id}`}
            onDuplicate={handleBlueprintDuplicate}
            onMultiSelect={handleBlueprintMultiselect}
            onDelete={handleBlueprintDelete}
            items={[
              <Link key={1} className="flex" to={`blueprints/${item.id}/add`}>
                <PlusCircleIcon width={15} className="mr-3" />
                {t("audits.add")}
              </Link>,
            ]}
          />
        </div>
      </div>
      {item.components.length > 0 && (
        <div>
          <ComponentListItem blueprint={item} />
        </div>
      )}
    </div>
  );
};

export default observer(BlueprintListItemOnline);
