import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import useFeatureFlag from "hooks/useFeatureFlag";
import { BuildingFormOnchange } from "./GeneralInformation";
import SettingsCheckbox from "./SettingsCheckbox";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";

export default observer(function IfcImportSettings({
  handleChange,
}: BuildingFormOnchange) {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();

  if (!isFeatureVisible("ifc_import_settings")) return <></>;

  return (
    <>
      <div className="text-xl font-semibold mt-5 border-t pt-4">
        {t("addBuilding.IfcImportSettings")}
      </div>
      <SettingsCheckbox
        id="ifc_import_split_multi_objects"
        title={t("addBuilding.splitUpMultiLayeredObjects")}
        subTitle={t("addBuilding.splitUpMultiLayeredObjectsSubtitle")}
        checked={
          buildingSettingsStore.buildingFormDetails
            .ifc_import_split_multi_objects
        }
        handleChange={handleChange}
      />
    </>
  );
});
