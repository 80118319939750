import React from "react";
import { SetterOrUpdater } from "recoil";

export default function handleBlueprintInvalid(
  e: React.FormEvent<HTMLFormElement>,
  setStep: React.Dispatch<React.SetStateAction<number>>,
  setComponentStep: SetterOrUpdater<number>
) {
  const elementName = (e.target as HTMLInputElement | HTMLSelectElement).name;
  const failedSteps = [];
  for (let i = 1; i <= 4; i++) {
    const found = document.querySelector(`#step-${i} [name='${elementName}']`);
    found && failedSteps.push(i);
  }
  const matchedStep = failedSteps[0];
  if (matchedStep && matchedStep === 3) {
    setStep(2); //3
    return setComponentStep(1);
  }
  if (matchedStep) {
    return setStep(Number(matchedStep) - 1);
  }
  console.error(`matchedStep number not working (${matchedStep}).`);
}
