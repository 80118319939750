import React from "react";

export function useConnectionStatus() {
  const [isNetworkAvailable, setIsNetworkAvailable] = React.useState<boolean>(
    navigator.onLine
  );

  React.useEffect(() => {
    const handleConnectionChange = () => {
      setIsNetworkAvailable(navigator.onLine);
    };

    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);

    return () => {
      window.removeEventListener("online", handleConnectionChange);
      window.removeEventListener("offline", handleConnectionChange);
    };
  }, [navigator.onLine]);

  return { isNetworkAvailable };
}
