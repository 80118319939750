import React, { useEffect, useState } from "react";
import { DGNBSteps, DGNBStore } from "store/DGNBStore";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid";
import { BuildingInfoForm } from "./steps/StepBuildingInfo";
import PollutantReport from "./steps/StepPollutantReport";
import EnergyConsumption from "./steps/StepEnergyConsumption";
import ReportSuccess from "./steps/StepSuccess";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { buildingStore } from "store/Building/BuildingStore";
import { AddEditBuildingForm } from "store/Building/types";
import CustomCircle from "./components/CustomCircle";
import StepFlexibility from "./steps/StepFlexibility";
import { useNavigate } from "react-router";
import { toJS } from "mobx";
import { b6ImporterStore } from "store/B6ImporterStore";

export type DGNBStepperPropTypes = {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTitleHidden: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DGNBStepper = observer((props: DGNBStepperPropTypes) => {
  const dgnbStore = DGNBStore.getInstance();
  const { activeStep, validateFields, steps } = dgnbStore;
  const { t } = useTranslation();
  const [loading] = useState(false);
  const { buildingTypes } = buildingStore;
  const { currentBuilding } = buildingStore;
  const navigate = useNavigate();

  const prefillWithBuildingValues = async () => {
    dgnbStore.prefilDGNBFromBuildingFields(
      toJS(currentBuilding) as AddEditBuildingForm,
      buildingTypes
    );
    await b6ImporterStore.fetchReportData();
    dgnbStore.prefillB6ValuesFromReport(b6ImporterStore.fileReport);
  };

  useEffect(() => {
    dgnbStore.setBuildingId(currentBuilding?.id ?? "");
    prefillWithBuildingValues();
  }, []);

  const shouldHideTitle = (step: number) => {
    return step > DGNBSteps.BuildingFlexibility;
  };

  const handleNext = () => {
    if (!validateFields) {
      return;
    }

    if (activeStep == DGNBSteps.Success) {
      props.setOpenModal(false);
      return;
    }

    if (activeStep == DGNBSteps.BuildingFlexibility) {
      dgnbStore.sendDGNBRequestToServer();
    }

    dgnbStore.setActiveStep(activeStep + 1);
    props.setTitleHidden(shouldHideTitle(activeStep));
  };

  const handleBack = () => {
    if (activeStep == 0) {
      props.setOpenModal(false);
    } else {
      dgnbStore.setActiveStep(activeStep - 1);
      props.setTitleHidden(shouldHideTitle(activeStep));
    }
  };

  const getButtonText = (step: number) => {
    if (step === DGNBSteps.BuildingFlexibility) {
      return t("DGNB.create_dngb_pass");
    }
    return t("DGNB.next_step");
  };

  const handleSuccessClose = (open_export: boolean) => {
    props.setOpenModal(false);
    if (open_export) {
      navigate("exports");
    }
  };

  const renderStepContent = (step: number) => {
    const wrapperClass = clsx("max-h-[60vh] overflow-y-scroll");
    let content = null;

    switch (step) {
      case DGNBSteps.BuildingInformation:
        content = <BuildingInfoForm />;
        break;
      case DGNBSteps.PollutantReport:
        content = <PollutantReport />;
        break;
      case DGNBSteps.EnergyConsumption:
        content = <EnergyConsumption />;
        break;
      case DGNBSteps.BuildingFlexibility:
        content = <StepFlexibility />;
        break;
      case DGNBSteps.Success:
        content = <ReportSuccess handleClose={handleSuccessClose} />;
        break;
      default:
        content = null;
    }

    return <div className={wrapperClass}>{content}</div>;
  };
  const showStepper = activeStep < DGNBSteps.Success;

  return (
    <div className="relative w-full">
      {showStepper && (
        <div className="bg-gray-50 shadow">
          <Stepper
            style={{ backgroundColor: "transparent" }}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel color="black" StepIconComponent={CustomCircle}>
                  {""}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      )}
      {renderStepContent(activeStep)}
      <div className="bg-gray-50 p-4 mt-2 flex flex-row justify-between">
        {activeStep < DGNBSteps.Success && (
          <Button
            leadingIcon={
              activeStep > DGNBSteps.BuildingInformation && <ArrowLeftIcon />
            }
            type="gray"
            width="fit-content"
            className="w-fit"
            onClick={handleBack}
          >
            {activeStep == DGNBSteps.BuildingInformation
              ? t("DGNB.cancel")
              : t("DGNB.back")}
          </Button>
        )}
        {activeStep != DGNBSteps.Success && (
          <Button
            className="w-fit"
            width="fit-content"
            trailingIcon={
              activeStep <= DGNBSteps.BuildingFlexibility && <ArrowRightIcon />
            }
            type="primary"
            disable={!dgnbStore.validateFields || loading}
            onClick={handleNext}
          >
            {getButtonText(activeStep)}
          </Button>
        )}
      </div>
    </div>
  );
});
