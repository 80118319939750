import React from "react";
import { useParams } from "react-router";
import { ColumnSizingState, Row } from "@tanstack/react-table";
import { MaterialFileObject } from "store/IfcMapping/types";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import CustomFilter, { CustomFilterItem } from "components/Table/BeFilter";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";

export default function useObjectsTable() {
  const { ifc_id } = useParams();
  const [filtersLoading, setFiltersLoading] = React.useState(true);

  const updateMappingStatus = (updatedObject: MaterialFileObject) => {
    if (!updatedObject) return;

    const _items = [...mappingEditorStore.objectsData.items];
    const index = _items.findIndex((item) => item.id === updatedObject?.id);
    const foundedItem = { ..._items[index] };
    if (foundedItem) {
      foundedItem.mass = updatedObject.mass;
      foundedItem.gwp = updatedObject.gwp;
      foundedItem.product_match_type = updatedObject.product_match_type;
      if (updatedObject.products?.length) {
        updateCategory(foundedItem, updatedObject);
      } else {
        updateObject(foundedItem, updatedObject);
      }
      _items[index] = { ...foundedItem };
    }
    mappingEditorStore.setObjectsData({
      ...mappingEditorStore.objectsData,
      items: _items,
    });
  };

  function updateCategory(
    foundedItem: MaterialFileObject,
    response: MaterialFileObject
  ) {
    if (!response.products) return;
    foundedItem.products = [...response.products];
    foundedItem.total_mapped = response.total_mapped;
    if (mappingEditorStore.currentRow?.parent?.getIsExpanded()) {
      updateChildTable(foundedItem.id as string);
    }
  }

  function updateObject(
    foundedItem: MaterialFileObject,
    response: MaterialFileObject
  ) {
    if (foundedItem.total_objects && response.product_match) {
      foundedItem.products = [response.product_match];
    } else {
      foundedItem.product_match = response.product_match ?? null;
      foundedItem.products = response.products ?? [];
      if (mappingEditorStore.currentRow?.parent?.getIsExpanded()) {
        updateChildTable(foundedItem.id as string);
      }
    }
  }

  const getCategory = async (object: MaterialFileObject) => {
    const groupBy = mappingEditorStore.groupBy;
    if (!ifc_id || !groupBy || !parent) return;
    return await ifcMappingStore
      .getSingleCategory(
        ifc_id,
        groupBy.join(",") || "",
        groupBy.find((item) => item === "component") &&
          (object.component as string),
        groupBy.find((item) => item === "material") &&
          (object.material as string),
        groupBy.find((item) => item === "ifc_entity") &&
          (object.ifc_entity as string)
      )
      .then((response) => {
        if (response?.data && parent) {
          return response?.data;
        }
      });
  };

  const unmatch = async (row: Row<unknown>) => {
    const object = row.original as MaterialFileObject;
    if (!ifc_id || !object.id) return;
    const groupBy = mappingEditorStore.groupBy;
    if (Number(object.total_objects) > 1 && groupBy) {
      await ifcMappingStore
        .matchGroup(
          ifc_id,
          groupBy.join(",") || "",
          "",
          "",
          groupBy.find((item) => item === "component") &&
            (object.component as string),
          groupBy.find((item) => item === "material") &&
            (object.material as string),
          groupBy.find((item) => item === "ifc_entity") &&
            (object.ifc_entity as string)
        )
        .then(async () => {
          object.products = [];
          const res = await getCategory(object);
          object.mass = res.mass;
          object.gwp = res.gwp;
          updateCategoryMappingStatus(object);
          if (row.getIsExpanded() && object.id) {
            updateChildTable(object.id);
          }
        });
    } else {
      await ifcMappingStore.matchObject(ifc_id, object.id).then((response) => {
        const result = (response as unknown as MaterialFileObject[])[0];
        object.products = [];
        object.product_match = null;
        object.mass = result.mass;
        object.gwp = result.gwp;
        updateMappingStatus(object);
      });
    }
  };

  const updateChildTable = (id: string) =>
    mappingEditorStore.setResetChildTable({
      ...mappingEditorStore.resetChildTable,
      [id]: !mappingEditorStore.resetChildTable[id] || false,
    });

  const updateCategoryMappingStatus = async (item: MaterialFileObject) => {
    const res = await getCategory(item);
    updateMappingStatus(res as unknown as MaterialFileObject);
  };

  function onChangeFilter(value: string, name: string) {
    if (mappingEditorStore.columnsFilter[name] !== value) {
      mappingEditorStore.setColumnFilters(name, value);
    }
  }

  async function getFilterItems(columnKey: string) {
    if (!ifc_id) return;
    const data = (await ifcMappingStore.getAvailableFilterValues(
      ifc_id,
      columnKey
    )) as unknown as { [key: string]: string[] };
    if (data) {
      const currentColumnItems = data[columnKey];
      const items: CustomFilterItem[] = currentColumnItems.map(
        (item, index) => ({
          id: String(index),
          name: item,
        })
      );
      mappingEditorStore.setFilterItems(columnKey, items);
    }
  }

  async function getAllFiltersItems() {
    await getFilterItems("material");
    await getFilterItems("ifc_entity");
    await getFilterItems("component");
    setFiltersLoading(false);
  }

  const columnFilter = (key: string) => {
    return (
      <CustomFilter
        name={key}
        handleChange={(value) => onChangeFilter(String(value), key)}
        items={mappingEditorStore.filterItems[key]}
      />
    );
  };

  const storeColumnSizes = (e: ColumnSizingState) => {
    Object.keys(e).length &&
      localStorage.setItem("ME_sizes", JSON.stringify(e));
  };

  const getStoredColumnSizes = () =>
    JSON.parse(localStorage.getItem("ME_sizes") ?? "{}");

  return {
    getCategory,
    updateMappingStatus,
    unmatch,
    updateCategoryMappingStatus,
    columnFilter,
    getAllFiltersItems,
    filtersLoading,
    storeColumnSizes,
    getStoredColumnSizes,
  };
}
