import { BlueprintOUT } from "api-client";
import { setLocalAudit } from "api-client-local/audits";
import { useNavigate, useParams } from "react-router-dom";
import {
  handleBlueprintLocalImagesDuplicate,
  makeBlueprintRawModel,
} from "features/Audit/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { currentAuditAtom, formLoadingAtom } from "store/atoms/audits";
import { slugify } from "utils";
import useMultiselect from "./useMultiselect";
import handleBlueprintDelete from "features/Audit/handlers/handleBlueprintDelete";
import { userStore } from "store/UserStore";

export default function useAuditBlueprintActions(
  selectedIds: string[],
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>,
  setItems?: React.Dispatch<React.SetStateAction<BlueprintOUT[]>>
) {
  const { t } = useTranslation();
  const { userProfile, authHeader } = userStore;
  const { id, audit_id } = useParams();
  const [audit, setAudit] = useRecoilState(currentAuditAtom);
  const setFormLoading = useSetRecoilState(formLoadingAtom);
  const navigate = useNavigate();

  const handleDelete = React.useCallback(
    async function (id: string) {
      if (!audit) return;
      console.log(`Deleting blueprint ${id}`);
      setFormLoading(t("audits.deletingBlueprints"));

      try {
        const updatedLocalAudit = await handleBlueprintDelete(id, audit);
        setAudit(updatedLocalAudit);
        setItems && setItems(updatedLocalAudit.blueprint_set);
        setFormLoading(t("audits.successfullyDeleted"));
        setSelectedIds([]);
        setFormLoading("");
      } catch (err) {
        console.error(err);
        const timer = setTimeout(() => {
          setFormLoading("");
          clearTimeout(timer);
        }, 1500);
      }
    },
    [audit]
  );

  const handleSearch = React.useCallback(
    (value: string) => {
      if (!audit) return;
      setItems &&
        setItems(() =>
          audit.blueprint_set.filter((item) => {
            const conditions = [];
            if (item.extra_info) {
              conditions.push(
                slugify(item.extra_info).includes(slugify(value))
              );
            }
            if (item.name_de) {
              conditions.push(slugify(item.name_de).includes(slugify(value)));
            }
            conditions.push(slugify(item.name).includes(slugify(value)));
            return conditions.some((e) => e === true);
          })
        );
    },
    [audit]
  );

  const handleDuplicate = React.useCallback(
    // TODO needs to recreate all properties with same values but different ids
    async (id: string) => {
      try {
        if (!audit) return;
        const oldBlueprint = audit.blueprint_set.find((b) => b.id === id);
        if (!oldBlueprint) return;
        if (!userProfile || !authHeader) return;
        const copiedAudit = JSON.parse(JSON.stringify(audit)) as typeof audit;
        const newBlueprint = makeBlueprintRawModel(
          copiedAudit.id,
          oldBlueprint.product_group_id,
          {
            email: userProfile.email,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            username: userProfile.username,
          },
          oldBlueprint
        );

        newBlueprint.instance_set = [];

        // if (newBlueprint.components && newBlueprint.components.length > 0) {
        //   newBlueprint.components.forEach((c) => {
        //     c.instance_set = [];
        //   });
        //   copiedAudit.blueprint_set.push(...newBlueprint.components);
        // }

        copiedAudit.blueprint_set.push(newBlueprint);

        const auditRequest = {
          ...copiedAudit,
          id: copiedAudit.id,
          blueprint_set: copiedAudit.blueprint_set,
        };
        const updatedLocalAudit = await setLocalAudit(auditRequest);

        newBlueprint.albums = oldBlueprint.albums.map((album) => {
          return {
            ...album,
            id: window.crypto.randomUUID(),
            images: album.images.map((img) => {
              return {
                ...img,
                id: window.crypto.randomUUID(),
              };
            }),
          };
        });
        console.log("newBlueprint.albums...: ", newBlueprint.albums);

        // Duplication for local images
        await handleBlueprintLocalImagesDuplicate(oldBlueprint, newBlueprint);
        setAudit(updatedLocalAudit);
        setSelectedIds([]);
        navigate(
          `/buildings/${id}/audits/${audit_id}/blueprints/${newBlueprint.id}`
        );
      } catch (err) {
        console.error(err);
      }
    },
    [audit, id, audit_id]
  );

  const handleMultiSelect = useMultiselect(selectedIds, setSelectedIds);

  return { handleSearch, handleDelete, handleMultiSelect, handleDuplicate };
}
