import { BlueprintOUT, ProductGroupOUT } from "api-client";

export default async function validateAddedComponents(
  selectedProductGroup: ProductGroupOUT,
  addedComponents: { [id: string]: BlueprintOUT }
) {
  if (Object.values(addedComponents).length === 0) return true;
  const sorted = (arr: string[]) => arr.sort((a, b) => (a > b ? -1 : 1));
  const requiredComponents = selectedProductGroup.required_components;
  if (requiredComponents && requiredComponents.length > 0) {
    const sortedRequiredIds = sorted(requiredComponents.map((com) => com.id));
    const sortedAddedIds = sorted(Object.keys(addedComponents));
    const results = sortedAddedIds.map(
      (item, i) => item === sortedRequiredIds[i]
    );
    const valid = results.every((item) => item === true);
    if (!valid) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }
}
