import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  AuditOUT,
  BlueprintOUT,
  FloorOUT,
  ManufacturerOUT,
  ProductGroupOUT,
  UserProfileOUT,
} from "api-client";
import Button from "components/Button";
import Form from "components/Form";
import ImageUploaderLocal from "components/ImageUploaderLocal";
import useBlueprint from "hooks/useBlueprint";
import TextArea from "components/TextArea";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import AuditItemFields from "./AuditItemFields";
import FormLoadingContainer from "./FormLoadingContainer";
import { SetterOrUpdater, useRecoilState, useRecoilValue } from "recoil";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import AuditItemInputById from "./AuditItemInputById";
import MultiSelect from "components/MultiSelect";
import SelectManufacturer from "./SelectManufacturer";
import {
  auditsAtom,
  dimensionsValueAtom,
  generatedBlueprintNameAtom,
  manufacturersAtom,
  selectedManufacturerAtom,
} from "store/atoms/audits";
import TextEntrySelect from "components/TextEntrySelect";
import useAuditRooms from "hooks/useAuditRooms";
import { LocalImage } from "../../api-client-local/db_interfaces";
import { HandleBlueprintItemFormSubmitArgs } from "./handlers/handleBlueprintAdd";
import { DB_VARS, getDBVariable, setDBVariable } from "api-client-local";
import { userStore } from "store/UserStore";
import { observer } from "mobx-react-lite";

interface HandleAuditSubmitArgs {
  images: (File | LocalImage)[];
  selectedProductGroup: ProductGroupOUT;
  audit: AuditOUT;
  setFormLoading:
    | React.Dispatch<React.SetStateAction<string>>
    | SetterOrUpdater<string>;
  userProfile: UserProfileOUT;
  authHeader: AxiosRequestConfig<AxiosRequestHeaders>;
}

export interface HandleInstanceItemFormSubmitArgs
  extends HandleAuditSubmitArgs {
  selectedBlueprint: BlueprintOUT;
  instance_id?: string;
}

interface AuditItemFormProps<T> {
  title: string;
  submitText: string;
  isInstance?: boolean;
  images?: (File | LocalImage)[];
  handleSubmit: (
    form: T,
    args: HandleBlueprintItemFormSubmitArgs
  ) => Promise<void> | void;
  redirect?: string;
  handleSuccess?: (response: void) => Promise<void> | void;
  handleError?: (err: unknown) => void;
  componentsMenu?: React.ReactElement;
}

export default observer(function AuditItemForm<FormKeyVal>(
  props: React.PropsWithChildren<AuditItemFormProps<FormKeyVal>>
) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { blueprint_id } = useParams();
  const audits = useRecoilValue(auditsAtom);

  const { authHeader, userProfile } = userStore;
  const [images, setImages] = React.useState<(File | LocalImage)[]>(
    props.images ?? []
  );
  const [formLoading, setFormLoading] = React.useState<string>("");
  const manufacturers = useRecoilValue(manufacturersAtom);
  const [selectedManufacturer, setSelectedManufacturer] = useRecoilState(
    selectedManufacturerAtom
  );
  const {
    audit,
    isComponent,
    selectedProductGroup,
    selectedBlueprint,
    selectedInstance,
  } = useBlueprint(blueprint_id);

  const handleRoomAdd = React.useCallback(async (value: string) => {
    const roomNames = getDBVariable(DB_VARS.ROOM_NAMES);
    let result: string[];
    if (!roomNames) {
      result = [value];
    } else {
      result = Array.from(new Set([...roomNames, value]));
    }
    setDBVariable(DB_VARS.ROOM_NAMES, result.toString());
  }, []);

  const handleSubmit = React.useCallback(
    async function (form: FormKeyVal) {
      let timer: ReturnType<typeof setTimeout>;
      try {
        setFormLoading(t("audits.sendingFormData"));
        if (!authHeader || !userProfile || !audit)
          return console.error(
            "authHeader",
            authHeader,
            "userProfile",
            userProfile,
            "audit",
            audit
          );
        if (!selectedProductGroup || !selectedBlueprint)
          return console.error(
            "selectedBlueprint or selectedProductGroup undefined"
          );
        console.log("AuditItemForm.handleSubmit", form);
        const response = await props.handleSubmit(form, {
          images,
          audit,
          setFormLoading,
          authHeader,
          userProfile: {
            email: userProfile.email,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            username: userProfile.username,
          },
          selectedProductGroup,
          selectedBlueprint,
        });
        props.handleSuccess?.(response);
        props.redirect && navigate(props.redirect);
      } catch (err) {
        console.error(err);
        setFormLoading(t("audits.errorOccurred"));
        props.handleError?.(err);
      } finally {
        timer = setTimeout(() => {
          setFormLoading("");
          clearTimeout(timer);
        }, 2000);
      }
    },
    [authHeader, selectedBlueprint, images]
  );

  const roomSet = useAuditRooms(audit, audits);

  React.useEffect(() => {
    if (props.images) {
      setImages(props.images);
    }
    return () => setImages([]);
  }, [props.images]);

  React.useEffect(
    () => console.log("AuditItemForm.selectedInstance", selectedInstance),
    [selectedInstance]
  );

  const formKey = () => {
    if (selectedInstance) return selectedInstance.id;
    if (selectedBlueprint) return selectedBlueprint.id;
    return "0";
  };

  const [floorSet, setFloorSet] = useState<FloorOUT[]>([]);

  function findFloorSet() {
    if (audit?.floor_set && audit?.floor_set.length > 0) {
      setFloorSet([...audit.floor_set].sort((a, b) => a.level - b.level));
    } else if (audits && audits?.length > 0) {
      const mFloorSet =
        audits.find((aud) => aud.id === audit?.id)?.floor_set ?? [];
      setFloorSet([...mFloorSet].sort((a, b) => a.level - b.level) ?? []);
    } else {
      setFloorSet([]);
    }
  }

  useEffect(() => {
    findFloorSet();
  }, [audit]);

  const [dimensionsValue, setDimensionsValue] =
    useRecoilState(dimensionsValueAtom);
  const [generatedBlueprintName, setGeneratedBlueprintName] = useRecoilState(
    generatedBlueprintNameAtom
  );

  function extractDimensions() {
    // Regular expression to match the dimensions pattern (e.g., "11x11x22")
    const regex = /(\d+x\d+x\d+)/;

    // Use the regex to extract the dimensions from the name
    const match = selectedBlueprint?.name.match(regex);

    if (match) {
      return match[0];
    } else {
      return isComponent ? "" : "0x0x0"; // No dimensions found in the string
    }
  }

  function extractNameWithoutDimensions() {
    const regex = /\d+x\d+x\d+/;
    return selectedBlueprint?.name.replace(regex, "").trim();
  }

  React.useEffect(() => {
    if (!dimensionsValue) {
      setDimensionsValue(extractDimensions());
    }

    let selectedName = extractNameWithoutDimensions();
    if (selectedBlueprint?.manufacturer?.name) {
      selectedName = selectedName
        ?.replaceAll(selectedBlueprint?.manufacturer?.name, "")
        .trim();
    }

    const generatedName = `${selectedName} ${
      selectedManufacturer?.name ?? selectedBlueprint?.manufacturer?.name ?? ""
    } ${dimensionsValue ?? ""}`
      .trim()
      .replace(/\s+/g, " ");

    setGeneratedBlueprintName(generatedName);
  }, [
    selectedManufacturer,
    selectedBlueprint?.name,
    dimensionsValue,
    selectedBlueprint?.manufacturer?.name,
  ]);

  function getDefaultProductName() {
    if (props.isInstance) {
      return extractNameWithoutDimensions();
    }

    if (isComponent) {
      return selectedBlueprint?.name;
    }

    return generatedBlueprintName ?? selectedBlueprint?.name;
  }

  return (
    <>
      <h3 className="pb-2">{props.title}</h3>
      <FormLoadingContainer formLoading={formLoading} />
      <Form<FormKeyVal>
        key={formKey()}
        className="pb-16"
        handleSubmit={handleSubmit}
      >
        {selectedInstance && (
          <TextInput
            className="sm:py-2"
            name="name"
            defaultValue={selectedInstance.name}
            labelName={`${t("audits.item")} Name`}
          />
        )}
        {props.isInstance && (
          <TextInput
            unit={selectedProductGroup?.unit}
            labelName={t("audits.amount")}
            type="number"
            step={selectedProductGroup?.unit.includes("pcs") ? "1" : "any"}
            name="instance_amount"
            defaultValue={selectedInstance?.instance_amount}
          />
        )}
        {!selectedInstance && selectedBlueprint && (
          <TextInput
            className="sm:py-2"
            name="name"
            defaultValue={getDefaultProductName()}
            labelName={`${t("audits.blueprint")} Name`}
          />
        )}
        {!selectedInstance && selectedBlueprint && isComponent && (
          <TextInput
            containerClass="w-[49%]"
            className="sm:py-2"
            unit={selectedProductGroup?.unit}
            labelName={t("audits.amount")}
            type="number"
            step={selectedProductGroup?.unit.includes("pcs") ? "1" : "any"}
            defaultValue={selectedBlueprint.as_component_amount}
            name="as_component_amount"
          />
        )}
        {props.children}
        {!props.isInstance && (
          <ImageUploaderLocal
            id="form-images"
            images={images}
            handleChange={(files) => setImages(files)}
            name="images"
            multiple
            labelName={t("audits.addImages")}
          />
        )}
        {props.isInstance ? (
          <>
            <AuditItemInputById name={"Condition"} />
            {floorSet && floorSet.length > 0 ? (
              <MultiSelect
                containerClassName="flex-[100%]"
                defaultValues={selectedInstance?.floor_ids ?? []}
                className="py-2 mb-2"
                required={floorSet.length > 0}
                labelName={t("audits.floors")}
                name="floor_ids"
                isFloorSet
                options={floorSet ?? []}
              />
            ) : null}
            <TextEntrySelect
              onNewEntry={handleRoomAdd}
              labelName={t("audits.room")}
              name="room_name"
              required
              defaultValue={selectedInstance?.room_name as string}
              options={roomSet ?? []}
            />
          </>
        ) : (
          <>
            <SelectManufacturer
              selected={selectedBlueprint?.manufacturer as ManufacturerOUT}
              onReset={() => setSelectedManufacturer(null)}
              onChange={(value) => {
                const found = manufacturers?.find((m) => m.name === value);
                console.log("SelectManufacturer.found", found);
                if (found) {
                  setSelectedManufacturer(found);
                }
              }}
            />
            <AuditItemFields filteredFields={["Condition"]} />
          </>
        )}
        <TextArea
          defaultValue={
            props.isInstance
              ? (selectedInstance?.extra_info as string)
              : (selectedBlueprint?.extra_info as string)
          }
          name="extra_info"
          labelName={t("audits.moreSpecs")}
        />
        {props.componentsMenu ? props.componentsMenu : null}
        <Button disabledClasses="w-full" htmlType="submit">
          {props.submitText}
        </Button>
      </Form>
    </>
  );
});
