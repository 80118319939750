import React from "react";
import Modal, { ModalProps } from "components/Modal";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { useInventory } from "../useInventory";
import { Category, inventoryStore } from "store/InventoryStore";
import ColumnsStateSettings from "./ColumnsStateSettings";

export default function CustomizeColumns(props: ModalProps) {
  const { t } = useTranslation();
  const { saveColumnsState } = useInventory();
  const [columnsState, setColumnsState] = React.useState<Category[]>([]);

  function saveSettings() {
    saveColumnsState(columnsState);
    inventoryStore.setCategories(columnsState);
    props.close();
  }

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      closeButton
      title={t("buildingInventory.tableColumnSettings")}
      footer={
        <div className="w-full flex justify-end">
          <Button width="fit-content" onClick={saveSettings}>
            {t("commons.saveChanges")}
          </Button>
        </div>
      }
      containerClassName="min-w-[980px]"
    >
      <ColumnsStateSettings
        columnsState={columnsState}
        setColumnsState={setColumnsState}
      />
    </Modal>
  );
}
