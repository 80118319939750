import React from "react";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";
import ProductsListContainer from "../ProductsListContainer";
import RowsList, { RowsListProps } from "../SearchRowsList";
import EmptyState from "../EmptyState";
import ProductDetailsPopup from "features/MappingTools/ProductDetailsPopup";
import { ProductSearchOutput } from "api-client";
import { IconCopy, IconEdit, IconInfoCircle } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import useFeatureFlag from "hooks/useFeatureFlag";
import useComponentActions from "./useComponentActions";
import { componentsStore } from "store/IfcMapping/ComponentsStore";

const DefaultComponents = (props: RowsListProps) => {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();
  const { duplicateComponent, editComponent } = useComponentActions();

  function shouldShowEmptyView() {
    return (
      !searchStore.productSearchLoading &&
      searchStore.searchResults.items.length === 0
    );
  }

  const settingsItems = (product: ProductSearchOutput | null | undefined) => {
    const itemList = [
      {
        name: t("mapping.showDetails"),
        icon: <IconInfoCircle width={18} className="mr-2" />,
        onClick: () =>
          searchStore.setOpenDetails({ open: true, product: product }),
        className: "text-gray-700",
        disabled: false,
      },
      {
        name: t("mapping.duplicateComponent"),
        icon: <IconCopy width={18} className="mr-2" />,
        onClick: () => duplicateComponent(product?.id),
        className: "text-gray-700",
        disabled: componentsStore.openedSearchInCE,
      },
    ];

    if (isFeatureVisible("edit_default_components")) {
      itemList.push({
        name: t("mapping.editComponent"),
        icon: <IconEdit width={15} className="mr-2 min-w-[15px]" />,
        onClick: () => editComponent(product?.id),
        className: "text-gray-700",
        disabled: componentsStore.openedSearchInCE,
      });
    }
    return itemList;
  };

  return (
    <>
      <ProductsListContainer>
        {shouldShowEmptyView() ? (
          <EmptyState />
        ) : (
          <RowsList
            match={props.match}
            settingsItems={settingsItems}
            settingsDisabled={[false, componentsStore.openedSearchInCE]}
          />
        )}
      </ProductsListContainer>
      <ProductDetailsPopup
        product={searchStore.openDetails.product}
        open={searchStore.openDetails.open}
        close={() =>
          searchStore.setOpenDetails({ open: false, product: undefined })
        }
      ></ProductDetailsPopup>
    </>
  );
};

export default observer(DefaultComponents);
