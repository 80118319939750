import Dexie from "dexie";
import {
  LocalAudit,
  LocalBlueprint,
  LocalBlueprintIds,
  LocalBlueprintProperty,
  LocalDeletedImage,
  LocalImage,
  LocalInstance,
  LocalInstanceProperty,
} from "./db_interfaces";
import {
  AuditIN,
  AuditOUT,
  FieldOUT,
  ManufacturerOUT,
  ProductGroupOUT,
} from "../api-client";

export class CADexie extends Dexie {
  images: Dexie.Table<LocalImage, string>;
  audits: Dexie.Table<LocalAudit | AuditOUT | AuditIN, string>;
  blueprints: Dexie.Table<LocalBlueprint, string>;
  blueprintProperties: Dexie.Table<LocalBlueprintProperty, string>;
  instances: Dexie.Table<LocalInstance, string>;
  instanceProperties: Dexie.Table<LocalInstanceProperty, string>;
  deletedImages: Dexie.Table<LocalDeletedImage>;
  fields: Dexie.Table<FieldOUT>;
  manufacturers: Dexie.Table<ManufacturerOUT>;
  product_groups: Dexie.Table<ProductGroupOUT>;
  blueprintIds: Dexie.Table<LocalBlueprintIds, string>;

  constructor() {
    super("CADexie");
    this.version(6).stores({
      images: "++id, sourceType, sourceId, uploaded, deleted",
      audits: "++id, buildingId, audit",
      blueprints: "++id, auditId",
      blueprintProperties: "++id, blueprintId",
      instances: "++id, blueprintId",
      instanceProperties: "++id, instanceId",
      deletedImages: "++id",
      product_groups: "++id",
      fields: "++id",
      manufacturers: "++id, name",
      blueprintIds: "++id, blueprintId",
    });
    this.images = this.table("images");
    this.audits = this.table("audits");
    this.blueprints = this.table("blueprints");
    this.blueprintProperties = this.table("blueprintProperties");
    this.instances = this.table("instances");
    this.instanceProperties = this.table("instanceProperties");
    this.deletedImages = this.table("deletedImages");
    this.product_groups = this.table("product_groups");
    this.fields = this.table("fields");
    this.manufacturers = this.table("manufacturers");
    this.blueprintIds = this.table("blueprintIds");
  }
}

const db = new CADexie();

export { db };
