import { AxiosResponse } from "axios";
import {
  CompliantCertification,
  CPXValueOUT,
  CustomBuildingProductIN,
  CustomBuildingProductOUT,
  CustomBuildingProductReferenceUnitEnum,
  EPDOUT,
  EPDSearchIN,
  EPDSearchOUT,
  EPDSearchSortBy,
  EpdSpecificationCheckOUT,
  MaterialSearchOUT,
  MaterialSearchSortBy,
  SimpleMaterialOut,
  SortOrder,
} from "../../api-client/generated/api";
import { makeAutoObservable } from "mobx";
import { MaterialSearchIN, productEditorApi, searchAPI } from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { buildingStore } from "../Building/BuildingStore";
import { BGItem } from "components/ButtonGroups";
import { REFERENCE_UNITS } from "features/MappingTools/consts";

export interface Option {
  id: string | number;
  name: string;
  disabled?: boolean;
  isSelected?: boolean;
}
export interface Tag {
  id: string;
  name: string;
}
export interface Step {
  title: string;
  fields: string[];
}

const rawEPDItem = {
  factor: undefined,
  epd: {
    id: "",
    source: "",
    source_uuid: "",
    version: "",
    reference_size: 0,
    reference_unit: "",
    material: "",
  },
  material: undefined,
};

const rawEPDGroup: EPDGroup = {
  epds: [rawEPDItem],
  conformity: "",
  certifications: [],
};

interface EPD extends EPDSearchOUT {
  material?: string;
}
export interface EPDRowProps {
  factor?: number | string;
  epd: EPD | undefined;
  material: MaterialSearchOUT | undefined;
}
export interface EPDGroup {
  epds: EPDRowProps[];
  conformity: string | undefined | null;
  certifications: CompliantCertification[] | undefined;
}

interface FilterOptions {
  epd_type: Option[];
  epd_data_sources: Option[];
  material_categories: SimpleMaterialOut[];
}

export interface OptionsList {
  [key: string]: (CPXValueOUT | Option)[];
}
export interface CustomProduct {
  id: string;
  name: string;
  groups: EPDGroup[];
  din_category: string;
  life_span: undefined | number;
  is_external: undefined | boolean;
  is_load_bearing: undefined | boolean;
  auto_mapping_tags: Tag[];
  preuse_qualification: string;
  separability_score: undefined | number;
  separability_qualification: undefined | string;
  deconstructability_score: undefined | number;
  deconstructability_qualification: undefined | string;
  reusability_score: undefined | number;
  reusability_qualification: undefined | string;
  recyclability_score: undefined | number;
  recyclability_qualification: undefined | string;
  dataset_source: undefined | string;
  reference_unit?: BGItem;
}

type DatasetValue =
  | MaterialSearchOUT
  | string
  | number
  | EPDSearchOUT
  | undefined;

const initialData: CustomProduct = {
  id: "",
  name: "",
  groups: [rawEPDGroup],
  din_category: "",
  life_span: undefined,
  is_external: undefined,
  is_load_bearing: undefined,
  auto_mapping_tags: [],
  preuse_qualification: "",
  separability_score: undefined,
  separability_qualification: "",
  deconstructability_score: undefined,
  deconstructability_qualification: "",
  reusability_score: undefined,
  reusability_qualification: "",
  recyclability_score: undefined,
  recyclability_qualification: "",
  dataset_source: "",
  reference_unit: REFERENCE_UNITS[0],
};

interface Materials {
  count: number;
  items: MaterialSearchOUT[];
  loading: boolean;
}

interface EPDs {
  count: number;
  items: EPDSearchOUT[];
  loading: boolean;
}

class BuildingProductStore {
  activeStep = 0;
  generalInformation = {
    title: "generalInformation",
    fields: [
      "name",
      "din_category",
      "life_span",
      "is_external",
      "is_load_bearing",
      "auto_mapping_tags",
    ],
  };
  circularity = {
    title: "circularity",
    fields: [
      "preuse_qualification",
      "separability_qualification",
      "deconstructability_qualification",
      "reusability_qualification",
    ],
  };
  epdData = {
    title: "referenceUnitAndEnvironmentalData",
    fields: ["groups"],
  };
  steps: Step[] = [this.generalInformation, this.circularity, this.epdData];
  epdsValidationErrors: { datasetIndex: number; rowIndex: number }[] = [];
  factorValidationErrors: { datasetIndex: number; rowIndex: number }[] = [];
  materialValidationErrors: { datasetIndex: number; rowIndex: number }[] = [];
  thirdStepValidionErrors = true;
  data: CustomProduct = initialData;
  optionsLists: OptionsList = {
    din_category: [],
    preuse_qualification: [],
    separability_qualification: [],
    deconstructability_qualification: [],
    reusability_qualification: [],
  };
  materials: Materials = {
    count: 0,
    items: [],
    loading: false,
  };
  epds: EPDs = { count: 0, items: [], loading: false };
  filtersOptions: FilterOptions = {
    epd_type: [],
    epd_data_sources: [],
    material_categories: [],
  };

  resetData() {
    this.data = initialData;
    this.thirdStepValidionErrors = true;
  }

  setData(data: CustomProduct) {
    this.data = data;
  }

  setOptionsLists(optionsLists: OptionsList) {
    this.optionsLists = optionsLists;
  }

  onChangeData(
    name: string,
    value: string | boolean | [] | undefined | Tag[] | BGItem | undefined
  ) {
    if (name) this.data[name as keyof CustomProduct] = value as never;
  }

  onChangeDataset(
    name: string,
    value: DatasetValue,
    datasetIndex: number,
    rowIndex: number
  ) {
    if (name === "epd") {
      this.changeEpdValue(value, datasetIndex, rowIndex);
    } else if (name === "material") {
      this.data.groups[datasetIndex].epds[rowIndex].material =
        value as MaterialSearchOUT;
    } else {
      this.changeFactorValue(value, datasetIndex, rowIndex);
    }
    this.setData({ ...this.data });
    this.checkSaveValidity();
  }

  private changeFactorValue(
    value: string | number | MaterialSearchOUT | EPDSearchOUT | undefined,
    datasetIndex: number,
    rowIndex: number
  ) {
    const _result = this.factorValidity(value as string);
    this.data.groups[datasetIndex].epds[rowIndex].factor = _result as string;
  }

  private async changeEpdValue(
    value: string | number | EPDSearchOUT | MaterialSearchOUT | undefined,
    datasetIndex: number,
    rowIndex: number
  ) {
    const epd_value: EPDSearchOUT = value as EPDSearchOUT;
    this.data.groups[datasetIndex].epds[rowIndex].epd = epd_value;
    if (epd_value !== undefined) {
      if (epd_value.default_material) {
        this.data.groups[datasetIndex].epds[rowIndex].material =
          epd_value.default_material as MaterialSearchOUT;
      }
      await this.checkEPDSpecifications(datasetIndex);
    }
  }

  async checkEPDSpecifications(datasetIndex: number) {
    const authHeader = await getLocalAuthHeader();
    const datasetEPDs = this.data.groups[datasetIndex].epds
      .map((item) => item?.epd?.id)
      .flat(1)
      .filter((item) => item);
    await productEditorApi
      .inventoryApiV1RoutersProductEditorCheckEpdSpecifications(
        {
          epds: datasetEPDs as string[],
        },
        authHeader
      )
      .then((response) => {
        const data: EpdSpecificationCheckOUT = response.data;
        this.data.groups[datasetIndex].conformity = data.conformity;
        this.data.groups[datasetIndex].certifications =
          data.compliant_certifications?.filter(
            (item) => item.name !== "Keine"
          );
        this.setData({ ...this.data });
      })
      .catch((err) => console.log(err, "ProductEditorCheckEpdSpecifications"));
  }

  private factorValidity(
    value: string | number | EPDOUT | MaterialSearchOUT | undefined
  ) {
    // return value === "0" ? 1 : checkValue();

    // function checkValue() {
    return Number(value) < 0 ? undefined : value;
    // }
  }

  setActiveStep(step: number) {
    this.activeStep = step;
  }

  isAnItemOfOptions(key: string) {
    return !![
      "din_category",
      "preuse_qualification",
      "separability_qualification",
      "deconstructability_qualification",
      "reusability_qualification",
    ].find((item) => item === key);
  }

  getValueFromObject(key: string, isEng?: boolean) {
    const value = Object.entries(this.data).find(
      (item) => item[0] === key
    )?.[1];
    if (key === "auto_mapping_tags") {
      return value.map((item: Tag) => item.name).join(", ");
    } else if (key === "reference_unit") {
      return isEng ? value?.name : value?.name_de;
    } else if (this.isAnItemOfOptions(key)) {
      return this.optionsLists[key]?.find((item) => item.id === value)?.name;
    } else {
      return value;
    }
  }

  checkStepValidity(index: number) {
    if (index === 0) {
      return this.data.name && this.data.din_category && this.data.life_span;
    } else if (index === 1) {
      return (
        this.data.preuse_qualification &&
        this.data.separability_qualification &&
        this.data.deconstructability_qualification &&
        this.data.reusability_qualification
      );
    } else {
      return this.data?.groups?.map((epdGroup: EPDGroup) =>
        epdGroup?.epds?.map((item) => item?.factor && item?.epd)
      );
    }
  }

  addDataset() {
    this.data.groups?.push(rawEPDGroup);
    this.checkSaveValidity();
  }

  removeDataset(datasetIndex: number) {
    this.updateValidations(datasetIndex, 0);
    this.data.groups?.splice(datasetIndex, 1);
    this.checkSaveValidity();
  }

  clearDataset() {
    this.data.groups = [rawEPDGroup];
    this.updateValidations(0, 0);
  }

  addEpdRow(index: number) {
    this.data.groups?.[index].epds?.push(rawEPDItem);
    this.checkSaveValidity();
  }

  removeEpdRow(rowIndex: number, datasetIndex: number) {
    this.data.groups?.[datasetIndex]?.epds?.splice(rowIndex, 1);
    this.updateValidations(datasetIndex, rowIndex);
    this.checkEPDSpecifications(datasetIndex);
  }

  private updateValidations(datasetIndex: number, rowIndex: number) {
    this.removeEpdsErrors(datasetIndex, rowIndex);
    this.removeFactorErrors(datasetIndex, rowIndex);
    this.removeMaterialErrors(datasetIndex, rowIndex);
    this.checkSaveValidity();
  }

  stepHasOverview(step: number) {
    if (step === 2) {
      return Boolean(
        Object.values(this.data.groups?.[0]?.epds?.[0]?.epd ?? {})?.find(
          (item) => item
        )
      );
    } else {
      return Boolean(
        this.steps[step].fields
          .map((key) => this.getValueFromObject(key))
          .find((item) => item)
      );
    }
  }

  checkEpdValidationErrors = () => {
    this.data?.groups?.forEach((group, datasetIndex) =>
      group?.epds?.find((row, rowIndex) => {
        if (!row.epd?.id) {
          const itemExist = this.epdsValidationErrors.find(
            (item) =>
              item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          );
          if (!itemExist)
            this.epdsValidationErrors.push({ datasetIndex, rowIndex });
        }
      })
    );
    return this.epdsValidationErrors.length > 0;
  };

  checkFactorValidationErrors = () => {
    this.data?.groups?.forEach((group, datasetIndex) =>
      group?.epds?.find((row, rowIndex) => {
        if (!row.factor) {
          const itemExist = this.factorValidationErrors.find(
            (item) =>
              item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          );
          if (!itemExist)
            this.factorValidationErrors.push({ datasetIndex, rowIndex });
        }
      })
    );
    return this.factorValidationErrors.length > 0;
  };

  addFactorValidationWarnings = () => {
    this.data?.groups?.forEach((group, datasetIndex) =>
      group?.epds?.find((row, rowIndex) => {
        if (!row.factor) {
          const itemExist = this.factorValidationErrors.find(
            (item) =>
              item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          );
          if (!itemExist)
            this.factorValidationErrors.push({ datasetIndex, rowIndex });
        }
      })
    );
    return this.factorValidationErrors.length > 0;
  };

  checkMaterialValidationErrors = () => {
    this.data?.groups?.forEach((group, datasetIndex) =>
      group?.epds?.find((row, rowIndex) => {
        if (!row.material) {
          const itemExist = this.materialValidationErrors.find(
            (item) =>
              item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          );
          if (!itemExist)
            this.materialValidationErrors.push({ datasetIndex, rowIndex });
        }
      })
    );
    return this.materialValidationErrors.length > 0;
  };

  removeEpdsErrors(datasetIndex: number, rowIndex: number) {
    const currentItem = this.epdsValidationErrors.find(
      (item) => item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
    );
    if (currentItem) {
      this.epdsValidationErrors.splice(
        this.epdsValidationErrors.indexOf(currentItem),
        1
      );
    }
  }

  removeFactorErrors(datasetIndex: number, rowIndex: number) {
    const currentItem = this.factorValidationErrors.find(
      (item) => item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
    );
    if (currentItem) {
      this.factorValidationErrors.splice(
        this.factorValidationErrors.indexOf(currentItem),
        1
      );
    }
  }

  removeMaterialErrors(datasetIndex: number, rowIndex: number) {
    const currentItem = this.materialValidationErrors.find(
      (item) => item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
    );
    if (currentItem) {
      this.materialValidationErrors.splice(
        this.materialValidationErrors.indexOf(currentItem),
        1
      );
    }
  }

  hasFactorValidationError(
    datasetIndex: number,
    rowIndex?: number | undefined
  ) {
    return Boolean(
      this.factorValidationErrors.find((item) =>
        rowIndex || rowIndex === 0
          ? item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          : item.datasetIndex === datasetIndex
      )
    );
  }

  hasEpdValidationError(datasetIndex: number, rowIndex?: number) {
    return Boolean(
      this.epdsValidationErrors.find((item) =>
        rowIndex || rowIndex === 0
          ? item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          : item.datasetIndex === datasetIndex
      )
    );
  }

  hasMaterialValidationError(datasetIndex: number, rowIndex?: number) {
    return Boolean(
      this.materialValidationErrors.find((item) =>
        rowIndex || rowIndex === 0
          ? item.datasetIndex === datasetIndex && item.rowIndex === rowIndex
          : item.datasetIndex === datasetIndex
      )
    );
  }

  anyFactorAdded() {
    return (
      this.data.groups.filter((item) => item.epds.find((item) => item.factor))
        .length > 0
    );
  }

  resetFactors() {
    this.data.groups.forEach((item) =>
      item.epds.forEach((item) => (item.factor = undefined))
    );
    this.thirdStepValidionErrors = true;
    this.setData({ ...this.data });
  }

  checkSaveValidity() {
    let emptyFieldRow = undefined;
    this.data.groups?.forEach((group) => {
      group.epds?.forEach((row) => {
        if (!row.factor || !row.epd || !row.material) {
          emptyFieldRow = row;
        }
      });
    });
    this.thirdStepValidionErrors = Boolean(emptyFieldRow);
  }

  setMaterials(materials: Materials) {
    this.materials = materials;
  }

  resetMaterials() {
    this.materials = {
      count: 0,
      items: [],
      loading: false,
    };
  }

  setEPDs(epds: EPDs) {
    this.epds = epds;
  }

  resetEPDs() {
    this.epds = {
      count: 0,
      items: [],
      loading: false,
    };
  }

  async fetchMaterial(
    pageNumber: number,
    term?: string,
    category?: string[],
    sortOrder?: SortOrder | null,
    sortBy?: MaterialSearchSortBy | null
  ) {
    const authHeader = await getLocalAuthHeader();
    const props: MaterialSearchIN = { term: term, category_general: category };
    return await productEditorApi
      .inventoryApiV1RoutersProductEditorSearchMaterials(
        props,
        sortOrder,
        sortBy,
        pageNumber,
        authHeader
      )
      .then((response: AxiosResponse) => response.data)
      .catch((err) => console.log(err, "ProductEditorSearchMaterials"));
  }

  async fetchEPDs(
    pageNumber: number,
    props: EPDSearchIN,
    sortOrder: SortOrder | null,
    sortBy: EPDSearchSortBy | null
  ) {
    const authHeader = await getLocalAuthHeader();
    return await productEditorApi
      .inventoryApiV1RoutersProductEditorSearchEpds(
        props,
        sortOrder,
        sortBy,
        pageNumber,
        authHeader
      )
      .then((response: AxiosResponse) => response.data)
      .catch((err) => console.log(err, "ProductEditorSearchEpds"));
  }

  async fetchEPDtypes() {
    const authHeader = await getLocalAuthHeader();
    await productEditorApi
      .inventoryApiV1RoutersProductEditorGetEpdTypes(authHeader)
      .then((response: AxiosResponse) => {
        const items = response.data.map((item: string, index: number) => ({
          id: index,
          name: item,
        }));
        this.setFiltersOptions({
          ...this.filtersOptions,
          epd_type: items,
        });
      })
      .catch((err) => console.log(err, "ProductEditorGetEpdTypes"));
  }

  async fetchEPDDatasetSources() {
    const authHeader = await getLocalAuthHeader();
    await productEditorApi
      .inventoryApiV1RoutersProductEditorGetEpdDatasetSources(authHeader)
      .then((response: AxiosResponse) => {
        const items = response.data.map((item: string, index: number) => ({
          id: index,
          name: item,
        }));
        this.setFiltersOptions({
          ...this.filtersOptions,
          epd_data_sources: items,
        });
      })
      .catch((err) => console.log(err, "ProductEditorGetEpdDatasetSources"));
  }

  private groupByListOfObjects(
    itemsList: CPXValueOUT[],
    key: keyof CPXValueOUT
  ) {
    return itemsList.reduce(function (
      result: { [key: string]: CPXValueOUT[] },
      x: CPXValueOUT
    ) {
      if (x[key]) {
        (result[x[key] as string] = result[x[key] as string] || []).push(x);
      }
      return result;
    },
    {});
  }

  private replaceNameWithQualification(cpxGroup: CPXValueOUT[]) {
    return cpxGroup
      .map((item) => {
        item.name = item.qualification ?? null;
        return item;
      })
      .sort((a, b) => (a.name as string).localeCompare(b.name as string));
  }

  async fetchCPXValues() {
    if (this.optionsLists.preuse_qualification.length) return;
    const authHeader = await getLocalAuthHeader();
    await productEditorApi
      .inventoryApiV1RoutersProductEditorGetCpxValues(authHeader)
      .then((response: AxiosResponse) => {
        const cpxGroups = this.groupByListOfObjects(response.data, "category");
        this.setOptionsLists({
          ...this.optionsLists,
          preuse_qualification: this.replaceNameWithQualification(
            cpxGroups["preuse"]
          ),
          separability_qualification: this.replaceNameWithQualification(
            cpxGroups["separability"]
          ),
          deconstructability_qualification: this.replaceNameWithQualification(
            cpxGroups["deconstructability"]
          ),
          reusability_qualification: this.replaceNameWithQualification(
            cpxGroups["reusability"]
          ),
        });
      })
      .catch((err) => console.log(err, "ProductEditorGetCpxValues"));
  }

  async fetchMaterialCategories() {
    const authHeader = await getLocalAuthHeader();
    await productEditorApi
      .inventoryApiV1RoutersProductEditorGetCategories(
        "material_general",
        authHeader
      )
      .then((response) => {
        this.setFiltersOptions({
          ...this.filtersOptions,
          material_categories: response.data,
        });
      })
      .catch((err) => console.log(err, "ProductEditorGetCategories"));
  }

  private getCPXValue(key: keyof CustomProduct, value?: string) {
    const selectedID = value || String(this.data[key]);
    return this.optionsLists[key].find(
      (item) => item.id === selectedID
    ) as CPXValueOUT;
  }

  getRelevantScore(key: keyof CustomProduct, value?: string) {
    return Number(this.getCPXValue(key, value)?.numeric_value);
  }

  getRelevantQualification(key: keyof CustomProduct, value?: string) {
    return String(this.getCPXValue(key, value)?.name);
  }

  private getEPDGroups() {
    return this.data.groups.map((group) => ({
      epds: group.epds.map((item) => ({
        material: String(item.material?.id),
        factor: Number(item.factor),
        epd: String(item.epd?.id),
      })),
    }));
  }

  getDefaultEPDDatasetName(datasetIndex: number) {
    return this.data.groups[datasetIndex].epds?.find(
      (item) => item?.epd?.dataset_source__name
    )?.epd?.dataset_source__name;
  }

  setFiltersOptions(filtersOptions: FilterOptions) {
    this.filtersOptions = filtersOptions;
  }

  async getDinCategoriesLevel1() {
    const authHeader = await getLocalAuthHeader();
    return await searchAPI
      .inventoryApiV1RoutersSearchEngineProductDinCategories(
        "level_1",
        authHeader
      )
      .then((response) => {
        this.setOptionsLists({
          ...this.optionsLists,
          din_category: response.data?.map((item) => ({
            id: item.id,
            name: `${item.category_number} ${item.name}`,
            value: item.name,
          })),
        });
      })
      .catch((error) =>
        console.log("SearchEngineProductDinCategories.error", error)
      );
  }

  async registerCustomProduct(): Promise<CustomBuildingProductOUT | void> {
    const authHeader = await getLocalAuthHeader();
    const props: CustomBuildingProductIN = this.prepareData();
    return await productEditorApi
      .inventoryApiV1RoutersProductEditorRegisterCustomBuildingProduct(
        props,
        authHeader
      )
      .then((response) => {
        if (response.data) return response.data;
      });
  }

  private prepareData(): CustomBuildingProductIN {
    return {
      name: this.data.name,
      din_category: this.data.din_category,
      life_span: Number(this.data.life_span),
      ifc_is_external: Boolean(this.data.is_external),
      ifc_is_loadbearing: Boolean(this.data.is_load_bearing),
      automapping_tags: this.data.auto_mapping_tags.map((item) => item.name),
      cpx_values: {
        preuse_qualification: this.getRelevantQualification(
          "preuse_qualification"
        ),
        separability_qualification: this.getRelevantQualification(
          "separability_qualification"
        ),
        deconstructability_qualification: this.getRelevantQualification(
          "deconstructability_qualification"
        ),
        reusability_qualification: this.getRelevantQualification(
          "reusability_qualification"
        ),
        deconstructability_score: this.getRelevantScore(
          "deconstructability_qualification"
        ),
        separability_score: this.getRelevantScore("separability_qualification"),
        preuse_score: this.getRelevantScore("preuse_qualification"),
        reusability_score: this.getRelevantScore("reusability_qualification"),
      },
      epd_groups: this.getEPDGroups(),
      building: buildingStore.buildingID as string,
      reference_unit: this.data.reference_unit
        ?.id as unknown as CustomBuildingProductReferenceUnitEnum,
    };
  }

  async updateCustomProduct() {
    const authHeader = await getLocalAuthHeader();
    const props: CustomBuildingProductIN = this.prepareData();
    return await productEditorApi.inventoryApiV1RoutersProductEditorEditCustomBuildingProduct(
      this.data.id,
      props,
      authHeader
    );
  }

  async deleteProduct(product_id: string) {
    const authHeader = await getLocalAuthHeader();
    return await productEditorApi.inventoryApiV1RoutersProductEditorDeleteCustomBuildingProduct(
      product_id,
      authHeader
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: BuildingProductStore;
  static getInstance(): BuildingProductStore {
    if (!BuildingProductStore.instance) {
      BuildingProductStore.instance = new BuildingProductStore();
    }
    return BuildingProductStore.instance;
  }
}

export const buildingProductStore = BuildingProductStore.getInstance();
