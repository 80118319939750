import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

const GeneratedNameOnline = () => {
  const { t } = useTranslation();

  const { manufacturerName, productGroupName, dimensionsValue, selectedColor } =
    blueprintCreateStore;

  React.useEffect(() => {
    const generatedName = `${productGroupName ?? ""} ${
      manufacturerName ?? ""
    } ${dimensionsValue ?? ""}`
      .trim()
      .replace(/\s+/g, " ");
    blueprintCreateStore.setGeneratedBlueprintName(generatedName);
  }, [manufacturerName, productGroupName, dimensionsValue]);

  const areNamesAvailable =
    productGroupName !== "" ||
    manufacturerName !== "" ||
    dimensionsValue !== "";

  return (
    <div className="mb-2 font-semibold">
      {areNamesAvailable ? (
        <div className="">
          <span id="productgroup_value" className="inline-block mr-1">
            {productGroupName}
          </span>
          {manufacturerName && (
            <span id="manufacturer_value" className="inline-block mr-1">
              {manufacturerName}
            </span>
          )}
          <span id="dimensions_value" className="inline-block mr-2">
            {dimensionsValue}
          </span>
          <span className="inline-block">
            {selectedColor && (
              <div
                className="h-4 w-4 rounded-sm translate-y-[2px]"
                style={{ backgroundColor: `${selectedColor.value}` }}
              ></div>
            )}
          </span>
        </div>
      ) : (
        t("audits.addNewBlueprint")
      )}
    </div>
  );
};

export default observer(GeneratedNameOnline);
