import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

export default function useAuditFormResetOnline() {
  return () => {
    blueprintCreateStore.setSelectedProductGroup(undefined);
    blueprintCreateStore.setSelectedManufacturer(undefined);
    blueprintCreateStore.setSelectedComponent(undefined);
    blueprintCreateStore.setGeneratedBlueprintName("");
    blueprintCreateStore.setDimensionsValue("");
    blueprintCreateStore.setProductGroupName("");
    blueprintCreateStore.setManufacturerName("");
    blueprintCreateStore.setAddedImages(undefined);
    blueprintCreateStore.setAddedComponents({});
    blueprintCreateStore.setAddedComponentImages(undefined);
    blueprintCreateStore.setSelectedColor(undefined);
    blueprintCreateStore.setStep(0);
    blueprintCreateStore.setComponentCurrentStep(0);
    blueprintCreateStore.setFilledSteps([]);
  };
}
