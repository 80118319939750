import React from "react";
import { IconRefresh } from "@tabler/icons-react";
import Button from "components/Button";

import { useTranslation } from "react-i18next";
import SuccessImage from "assets/images/success-image.svg";

export default function InfoScreen() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="tex-base font-semibold mb-8">
        {t("revit.welcomeMssg")}
      </div>
      <img src={SuccessImage} />
      <p
        className="my-8 text-gray-700 text-xs font-normal"
        dangerouslySetInnerHTML={{ __html: t("revit.loginAlert") }}
      />
      <div className="bg-gray-100 text-gray-500 rounded-md px-4 py-2 mb-8 min-w-fit text-xs font-medium">
        www.concular.app
      </div>
      <Button
        type="primary"
        trailingIcon={<IconRefresh width={15} stroke={3} />}
        width="fit-content"
        onClick={() => window.location.reload()}
        size="small"
      >
        {t("revit.tryAgain")}
      </Button>
    </div>
  );
}
