import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import Modal from "components/Modal";
import SimpleSelect from "components/SimpleSelect";
import Button from "components/Button";
import { organisationAccessRequestApi } from "api-client";
import useForm from "hooks/useForm";
import useToast from "hooks/useToast";
import Alert from "components/Alert";
import checkEmailValidation from "utils";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

interface Role {
  email: string;
  role: string;
}

const DOCUMENTATION_URL =
  "https://www.notion.so/concular/Rollen-und-Rechte-von-Nutzenden-9c5efeb1c888421394a95738353e427c?pvs=4";

const InviteMember = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const { t } = useTranslation();
  const { allRoles, clickedOrganisation } = organisationStore;
  const [data, setData] = useState<Role>({ email: "", role: "2" });
  const form = useForm(data, setData);
  const { authHeader } = userStore;
  const toast = useToast();
  const [showMandatoryAlert, setShowMandatoryAlert] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const sendInvite = (event: React.FormEvent) => {
    event.preventDefault();
    const hasError = checkErrors();
    if (!authHeader) return;
    if (!hasError && data && clickedOrganisation?.id) {
      organisationAccessRequestApi
        .concularApiV1RoutersOrganisationAccessRequestInviteUserOrAdminForOrg(
          clickedOrganisation.id,
          { email: data?.email, role: Number(data?.role) },
          authHeader
        )
        .then(() => {
          close();
          toast(
            t("organisationSettings.members.sendInvitationSuccessMsg"),
            "success"
          );
        })
        .catch((err) => toast(err.message, "warning"))
        .finally(() => setShowMandatoryAlert(false));
    }
  };

  function checkErrors() {
    if (!data?.email) {
      setShowMandatoryAlert(true);
      return true;
    }
    if (data.email && !checkEmailValidation(data.email)) {
      setShowMandatoryAlert(true);
      setErrorMessage(t("commons.errorEmailMessage"));
      return true;
    } else {
      setErrorMessage("");
      setShowMandatoryAlert(false);
      return false;
    }
  }

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      footerClassName={"relative"}
      className="p-0"
      title={
        <div
          dangerouslySetInnerHTML={{
            __html: t("organisationSettings.addMemberDialog.title", {
              organisation: clickedOrganisation?.name,
            }),
          }}
        ></div>
      }
      containerClassName={"md:min-w-[600px]"}
      closeButton
    >
      <form>
        <div className="p-6">
          <div className="flex w-full gap-2 justify-between">
            <Input
              labelName={t("organisationSettings.addMemberDialog.emailAddress")}
              containerClassName="w-full"
              placeholder={t("organisationSettings.addMemberDialog.enterEmail")}
              type="email"
              name="email"
              required
              errorMessage={
                errorMessage ||
                t("organisationSettings.addMemberDialog.mandatoryEmail")
              }
              showMandatoryAlert={showMandatoryAlert}
              onChange={form.handleChange}
            />
            {allRoles && allRoles.length > 0 ? (
              <SimpleSelect
                labelName={t("organisationSettings.addMemberDialog.role")}
                options={allRoles}
                current={data.role}
                containerClassName="min-w-[125px]"
                placeholder={t("organisationSettings.addMemberDialog.select")}
                name="role"
                onChange={form.handleChange}
              />
            ) : null}
          </div>
          <Alert
            type="info"
            description={t("organisationSettings.addMemberDialog.infoText")}
            actionButton={t(
              "organisationSettings.addMemberDialog.openDocumentation"
            )}
            actionButtonOnClick={() =>
              window.open(DOCUMENTATION_URL, "_blank", "noopener,noreferrer")
            }
          ></Alert>
        </div>
        <div className="bg-gray-50 flex justify-between p-5">
          <Button
            htmlType="submit"
            type="gray"
            width="fit-content"
            onClick={close}
          >
            {t("organisationSettings.addMemberDialog.cancel")}
          </Button>
          <Button type="primary" width="fit-content" onClick={sendInvite}>
            {t("organisationSettings.addMemberDialog.inviteMember")}
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default observer(InviteMember);
