import React from "react";
import { LoadingView } from "features/Audit/LoadingView";
import { observer } from "mobx-react-lite";
import { Outlet, useParams } from "react-router";
import {
  BlueprintSorting,
  caOnlineStore,
} from "store/AuditsOnline/CAOnlineStore";
import BlueprintListItemOnline from "features/AuditOnline/BlueprintListItemOnline";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "@heroicons/react/outline";
import SearchInput from "components/SearchInput";
import NotFound from "components/NotFound";
import ItemFilterMenu from "components/ActionMenu/ItemFilterMenu";
import { BlueprintListSortBy, SortOrder } from "api-client";

const BlueprintList = () => {
  const { audit_id } = useParams();
  const { t } = useTranslation();

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const [searchText, setSearchText] = React.useState("");

  const isInstanceOrBlueprint =
    location.pathname.includes("blueprint") ||
    location.pathname.includes("instance");

  const shouldRenderOutlet = isInstanceOrBlueprint && audit_id;

  const { blueprintSet, isLoading } = caOnlineStore;

  const fetchBlueprints = async () => {
    caOnlineStore.setBlueprints([]);
    caOnlineStore.setBlueprintOffset(0);
    await caOnlineStore.fetchBlueprints();
    caOnlineStore.setShouldUpdateBlueprintList(false);
  };

  React.useEffect(() => {
    fetchBlueprints();
  }, [caOnlineStore.shouldUpdateBlueprintList]);

  const handleScroll = () => {
    const container = containerRef.current;
    if (
      container &&
      container.scrollHeight - container.scrollTop === container.clientHeight
    ) {
      // User has scrolled to the bottom
      if (caOnlineStore.totalBPCount > blueprintSet.length) {
        // Fetch the next page if total items are less than the count
        fetchNextPage();
      }
    }
  };

  React.useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      // Clean up the event listener when the component is unmounted
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const fetchNextPage = async () => {
    if (isLoading) return;
    await caOnlineStore.fetchBlueprintNextPage();
  };

  const handleSearch = async (value: string) => {
    await caOnlineStore.fetchBlueprintSearch(value);
  };

  const handleSortSelection = (index: number) => {
    let blueprintSort: BlueprintSorting;
    if (index === 0) {
      blueprintSort = {
        sort_by: BlueprintListSortBy.Created,
        sort_order: SortOrder.Desc,
      };
    } else if (index === 1) {
      blueprintSort = {
        sort_by: BlueprintListSortBy.Created,
        sort_order: SortOrder.Asc,
      };
    } else if (index === 2) {
      blueprintSort = {
        sort_by: BlueprintListSortBy.Name,
        sort_order: SortOrder.Asc,
      };
    } else {
      blueprintSort = {
        sort_by: BlueprintListSortBy.Name,
        sort_order: SortOrder.Desc,
      };
    }
    caOnlineStore.setBlueprintSort(blueprintSort);
    fetchBlueprints();
  };

  if (!shouldRenderOutlet) {
    return (
      <>
        {caOnlineStore.isLoading ? (
          <LoadingView message="" />
        ) : blueprintSet.length === 0 ? (
          <NotFound noZIndex description={t("audits.noBlueprintsFound")} />
        ) : null}
        {blueprintSet.length > 0 && (
          <div
            ref={containerRef}
            className="relative mt-3 overflow-y-scroll max-h-[calc(100vh-270px)]"
          >
            <div className="pr-4">
              <SearchInput
                name="string_address"
                onChange={(value) => {
                  setSearchText(value);
                  handleSearch(value);
                }}
                value={searchText}
                placeholder={t("audits.searchBlueprints")}
              />
              <ItemFilterMenu onSelect={handleSortSelection} />
              {blueprintSet.map((item) => {
                return (
                  <div key={item.id}>
                    <BlueprintListItemOnline key={item.id} item={item} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {blueprintSet.length > 0 && (
          <div className="px-4 mt-2 pb-4 pt-2 text-gray-500 text-xs justify-end flex">
            {blueprintSet.length} / {caOnlineStore.totalBPCount}
          </div>
        )}

        <div className="flex justify-between pb-8 fixed md:sticky bottom-0">
          <Link to={`blueprints/add`}>
            <Button
              leadingIcon={<PlusIcon />}
              disabledClasses="w-full"
              className="!rounded-full"
            >
              {t("audits.addNewBlueprint")}
            </Button>
          </Link>
        </div>
      </>
    );
  }

  if (shouldRenderOutlet) return <Outlet />;
};

export default observer(BlueprintList);
