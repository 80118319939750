import React from "react";
import { observer } from "mobx-react-lite";
import { ManufacturerOUT, UserProfileOUT } from "api-client";
import ButtonAsDiv from "components/ButtonAsDiv";
import validateComponentForm from "features/Audit/handlers/validateComponentForm";
import {
  makeBlueprintRawModel,
  makeBlueprintPropertyModel,
} from "features/Audit/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { userStore } from "store/UserStore";

export const SubmitComponentButton = observer(() => {
  const { audit_id } = useParams();
  const { t } = useTranslation();
  const { userProfile } = userStore;

  const {
    addedComponents,
    selectedComponentProductGroup,
    addedComponentImages,
  } = blueprintCreateStore;

  const { manufacturers } = caOnlineStore;

  const handleComponentSubmit = React.useCallback(async () => {
    try {
      const form = validateComponentForm();
      if (!form) throw "form is undefined.";
      if (!selectedComponentProductGroup)
        throw "selectedComponent is undefined.";
      const componentFields = [
        ...selectedComponentProductGroup.required_fields,
        ...selectedComponentProductGroup.optional_fields,
      ];
      if (componentFields.length === 0) throw "componentFields.length is 0";
      const manufacturer: ManufacturerOUT | undefined =
        form.select_manufacturer && form.select_manufacturer.length > 0
          ? manufacturers?.find((m) => m.name === form.select_manufacturer)
          : undefined;

      const componentBlueprint = makeBlueprintRawModel(
        audit_id as string,
        selectedComponentProductGroup.id,
        userProfile as UserProfileOUT,
        {
          name: form.name,
          as_component_amount: Number(form.as_component_amount),
          manufacturer: manufacturer,
        }
      );
      componentBlueprint.blueprintproperty_set = componentFields.map(
        (field) => {
          return makeBlueprintPropertyModel(
            field.id,
            componentBlueprint.id,
            form[field.id]
          );
        }
      );
      const newAddedComponents = {
        ...addedComponents,
        [componentBlueprint.id]: componentBlueprint,
      };

      blueprintCreateStore.setAddedComponents(newAddedComponents);

      if (form.images_component && form.images_component.length > 0) {
        const newAddedComponentImages = {
          ...addedComponentImages,
          [componentBlueprint.id]: form.images_component as unknown as File[],
        };
        blueprintCreateStore.setAddedComponentImages(newAddedComponentImages);
      }

      blueprintCreateStore.setComponentCurrentStep(0);
      blueprintCreateStore.setSelectedComponentProductGroup(undefined);
      blueprintCreateStore.setSelectedComponent(undefined);
    } catch (err) {
      console.error(err);
    }
  }, [manufacturers, selectedComponentProductGroup]);

  return (
    <ButtonAsDiv onClick={handleComponentSubmit}>
      {t("audits.addNewComponent")}
    </ButtonAsDiv>
  );
});
