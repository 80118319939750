import { MinusCircleIcon } from "@heroicons/react/outline";
import {
  AuditOUT,
  BlueprintOUT,
  ImageAlbumOUT,
  ImageOUT,
  imagesApi,
} from "api-client";
import { db } from "api-client-local/db";
import { getLocalAuthHeader } from "api-client-local/utils";
import React from "react";
import ImageResizer from "../ImageResizer";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { useRecoilState } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import { AxiosRequestConfig } from "axios";
import { setLocalAudit } from "api-client-local/audits";
import { useConnectionStatus } from "hooks/useConnectionStatus";

interface Props {
  remoteImages: ImageOUT[];
  setRemoteImages: React.Dispatch<React.SetStateAction<ImageOUT[]>>;
  blueprint: BlueprintOUT;
}

export const RemoteImages = ({
  remoteImages,
  setRemoteImages,
  blueprint,
}: Props) => {
  const { t } = useTranslation();
  const [currentAudit, setCurrentAudit] = useRecoilState(currentAuditAtom);
  const [images, setImages] = React.useState<ImageOUT[]>([]);
  const [hasDragStarted, setHasDragStarted] = React.useState(false);
  const isNetworkAvailable = useConnectionStatus();

  React.useEffect(() => {
    const sorted = [...remoteImages].sort((a, b) => b.order - a.order);
    setImages(sorted);
  }, [remoteImages]);

  const onDeleteRemoteImage = (imageId: string) => {
    getLocalAuthHeader().then((authHeader) => {
      imagesApi
        .caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(imageId, authHeader)
        .then(() => {
          db.deletedImages.add({ id: imageId }).then(() => {
            setRemoteImages(remoteImages.filter((img) => img.id !== imageId));
          });
        });
    });
  };

  const getUpdatedAlbums = async (
    blueprintSet: BlueprintOUT[] | undefined,
    blueprintAlbums: Array<ImageAlbumOUT>
  ): Promise<BlueprintOUT[]> => {
    if (!blueprintSet) return [];
    if (!blueprintAlbums) return blueprintSet;

    return blueprintSet?.map((bp) => {
      if (bp.id === blueprint.id) {
        return {
          ...bp,
          albums: blueprintAlbums,
        };
      } else {
        return {
          ...bp,
          components: bp.components?.map((c) => {
            if (c.id === blueprint.id) {
              return {
                ...c,
                albums: blueprintAlbums,
              };
            }
            return c;
          }),
        };
      }
    });
  };

  const updateLocalAudit = async (blueprint_set: BlueprintOUT[]) => {
    const updatedAudit = {
      ...currentAudit,
      blueprint_set,
    } as AuditOUT;

    if (updatedAudit) {
      const localAudit = await setLocalAudit(updatedAudit);
      setCurrentAudit(localAudit);
    }
  };

  const onReorderRemoteImage = async (imageId: string, order: number) => {
    const authHeader = await getLocalAuthHeader();
    const response = await imagesApi.caApiV1RoutersImagesReorderImage(
      imageId,
      order,
      authHeader
    );
    if (response.status === 200) {
      const blueprintAlbums =
        await imagesApi.caApiV1RoutersImagesGetBlueprintAlbums(
          blueprint.id,
          authHeader as AxiosRequestConfig<unknown>
        );
      if (blueprintAlbums) {
        const updatedBlueprints = await getUpdatedAlbums(
          currentAudit?.blueprint_set,
          blueprintAlbums.data
        );

        await updateLocalAudit(updatedBlueprints);
      }
    }
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (images.length === 1) return;

    setHasDragStarted(false);

    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    // Calculate new order values based on the drop destination index
    if (result.destination.index === 0) {
      // If dropped at the 0th index, set the order to maxOrder + 1
      const allOrders = items.map((img) => img.order);
      const maxOrder = Math.max(...allOrders) + 1;
      const updatedItem = { ...reorderedItem, order: maxOrder };
      items.splice(result.destination.index, 1, updatedItem);
      setImages(items);
      await onReorderRemoteImage(updatedItem.id, updatedItem.order);
    } else {
      // If dropped at any other index, set the order accordingly
      const maxOrder = items[0].order; // Order of the 0th index item is the highest
      const updatedItem = {
        ...reorderedItem,
        order: maxOrder - result.destination.index,
      };
      items.splice(result.destination.index, 1, updatedItem);

      const updatedItems = [...items];

      // Check if other items have the same order, and adjust if necessary
      for (let i = result.destination.index + 1; i < updatedItems.length; i++) {
        if (updatedItems[i].order === updatedItem.order) {
          updatedItems[i] = {
            ...updatedItems[i],
            order: updatedItems[i].order - 1,
          };
          await onReorderRemoteImage(updatedItems[i].id, updatedItems[i].order);
        }
      }
      setImages(updatedItems);
      await onReorderRemoteImage(updatedItem.id, updatedItem.order);
    }
  };

  const handleOnDragStart = () => {
    setHasDragStarted(!hasDragStarted);
  };

  return (
    <>
      {images.length > 0 && (
        <>
          <p className="font-semibold text-sm mt-2">
            {t("audits.uploadedImagesLabel")}
          </p>
          <div>
            <DragDropContext
              enableDefaultSensors
              onDragStart={handleOnDragStart}
              onDragEnd={handleOnDragEnd}
            >
              <Droppable
                mode="standard"
                direction="horizontal"
                droppableId="blueprintImages"
              >
                {(provided) => (
                  <div
                    className="overflow-x-auto whitespace-nowrap"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {images.map((img: ImageOUT, index: number) => {
                      return (
                        <Draggable
                          isDragDisabled={
                            images.length === 1 || !isNetworkAvailable
                          }
                          key={img.id}
                          draggableId={img.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`bg-gray-100 border ${
                                index === 0 ? "border-dotted px-1" : ""
                              } max-w-[130px] my-3 inline-block mr-3 relative rounded-md`}
                              key={img.id}
                            >
                              <ImageResizer
                                width="500"
                                height="500"
                                className="object-fill !w-full !h-full rounded-sm"
                                src={img.image ?? ""}
                                alt={img.id}
                                isLink
                              />
                              {index === 0 && !hasDragStarted && (
                                <div className="text-[10px] text-gray-400 flex justify-center p-1">
                                  {t("audits.previewImage")}
                                </div>
                              )}
                              <button
                                className="text-white absolute w-5 h-5 -top-2 !z-100 -right-2 bg-red-700 rounded-full"
                                onClick={() => {
                                  onDeleteRemoteImage(img.id);
                                }}
                              >
                                <MinusCircleIcon />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}
    </>
  );
};
