import { ManufacturerOUT } from "api-client";
import React from "react";
import TextSearchInput from "components/TextSearchInput";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";

interface SelectProps {
  onChange?: (value: string | undefined, name?: string) => void;
  onReset?: () => void | Promise<void>;
  selected?: ManufacturerOUT | null | undefined;
}

function SelectManufacturerOnline(props: SelectProps) {
  const { manufacturers } = caOnlineStore;

  const { t } = useTranslation();
  if (!manufacturers || manufacturers.length === 0) {
    return (
      <div className="flex items-center">
        {t("audits.noLoadingManufacturers")}
      </div>
    );
  }
  return (
    <TextSearchInput<ManufacturerOUT>
      displayKey={"name"}
      selectedId={props.selected ? props.selected.id : undefined}
      onReset={props.onReset}
      handleChange={props.onChange}
      labelName={t("audits.selectManufacturer")}
      name="select_manufacturer"
      items={[...manufacturers].sort((a, b) => a.name.localeCompare(b.name))}
    />
  );
}

export default observer(SelectManufacturerOnline);
