import { db } from "api-client-local/db";
import {
  AuthConfig,
  populateFields,
  populateManufacturers,
  populateProductGroups,
} from "api-client-local/repositories";
import { getLocalAuthHeader } from "api-client-local/utils";
import useAuditFormReset from "hooks/useAuditFormReset";
import useToast from "hooks/useToast";
import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { manufacturersAtom, productGroupsAtom } from "store/atoms/audits";

const useManufacturerAndProductGroups = () => {
  const [productGroups, setProductGroups] = useRecoilState(productGroupsAtom);
  const [manufacturers, setManufacturers] = useRecoilState(manufacturersAtom);
  const [loading, setLoading] = React.useState("");
  const toast = useToast();
  const { t } = useTranslation();
  const reset = useAuditFormReset();

  React.useEffect(() => {
    if (productGroups.length === 0 || manufacturers.length === 0) {
      // only fetch it by default if there isn't any product groups
      fetchDataAsync(true);
    }
  }, []);

  const fetchDataAsync = async (shouldCheckLocal: boolean) => {
    // Checking in local DB if available
    if (shouldCheckLocal) {
      setLoading(t("audits.checkLocalRecords"));
      const productGroups = await db.product_groups.toArray();
      const manufacturers = await db.manufacturers.toArray();
      if (productGroups.length > 0 && manufacturers.length > 0) {
        setProductGroups(productGroups);
        setManufacturers(manufacturers);
        setLoading("");
        return;
      }
    }

    if (!navigator.onLine) {
      toast(t("audits.syncBar.noNetwork"), "attention");
      return;
    }
    if (loading !== "") return;
    const authHeader = (await getLocalAuthHeader()) as AuthConfig;
    try {
      /**
       * The order of calling below functions matter!
       * Make sure `populateFields` should call first before `populateProductGroups`
       * because slim product groups when fetched from server they process
       * the fields to get the real product groups.
       * This was the point where I was stuck for a while, but finally fixed!
       */

      setLoading(t("audits.fetchingFields"));
      await populateFields(authHeader);

      setLoading(t("audits.fetchingManufacturers"));
      const remoteManufacturers = await populateManufacturers(authHeader);
      setManufacturers(remoteManufacturers);

      setLoading(t("audits.fetchingPGs"));
      const remoteProductGroups = await populateProductGroups(authHeader);
      setProductGroups(remoteProductGroups);
    } catch (err) {
      console.error("fetchDataAsync Error: ", err);
    } finally {
      setLoading("");
      reset();
    }
  };

  return { manufacturers, productGroups, loading, fetchDataAsync };
};

export default useManufacturerAndProductGroups;
