import { useBanner } from "../context";
import YesOrNo from "./YesOrNo";
import React from "react";

export default function PreferencesBody() {
  const { preferences } = useBanner();
  const cellClass = `border border-slate-300 p-2`;
  return (
    <tbody>
      {preferences.map((pref, i) => {
        return (
          <tr key={i} className="align-top border border-slate-300 p-2">
            <td className={cellClass}>
              {i === preferences.length - 1 ? (
                ""
              ) : (
                <YesOrNo name={pref.title} />
              )}
            </td>
            <td className={`${cellClass} text-left pb-2`}>{pref.title}</td>
            <td className={cellClass}>{pref.description}</td>
            <td className={`${cellClass} hidden lg:table-cell`}>
              {pref.tools}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
}
