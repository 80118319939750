import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translations from "./translations";

export const storedLang = localStorage.getItem("lang");
export const selectedLang =
  storedLang || (navigator.language.startsWith("en-") ? "en" : "de");

i18n.use(initReactI18next).init({
  resources: translations,
  lng: selectedLang,
});

export default i18n;
