import React from "react";
import { useWindowSize } from "./useWindowSize";

const useWindowOverflow = () => {
  const windowSize = useWindowSize();
  const containerRef = React.useRef<HTMLDivElement>(null);

  const getTopPos = (y_pos: number | undefined, top_offset: number) => {
    if (!y_pos) return;
    let topPos = y_pos;
    const bottomPos = y_pos + Number(containerRef.current?.clientHeight);
    if (bottomPos > Number(windowSize.height)) {
      topPos -= bottomPos - Number(windowSize.height) + top_offset;
    }
    return topPos;
  };

  const getLeftPos = (x_pos: number | undefined, left_offset: number) => {
    if (!x_pos) return;
    let leftPos = x_pos;
    const rightPos = x_pos + Number(containerRef.current?.clientWidth);
    if (rightPos > Number(windowSize.width)) {
      leftPos -= rightPos - Number(windowSize.width) + left_offset;
    }
    return leftPos;
  };

  return {
    containerRef,
    getTopPos,
    getLeftPos,
  };
};

export default useWindowOverflow;
