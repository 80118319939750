import React, { FormEvent, useMemo, useRef, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import Button from "components/Button";
import Alert from "components/Alert";
import SearchSortTable from "components/Table";
import { ColumnDef } from "@tanstack/react-table";
import Badge from "components/Badge";
import { BuildingInviteeOUT } from "api-client";
import { IconX } from "@tabler/icons-react";
import Truncate from "components/Truncate";

interface IProps {
  invitedGuests: BuildingInviteeOUT[];
  sendInvite: (userEmail: string | undefined) => Promise<void>;
  removeInviteeFromBuilding: (guestId: string, email: string) => void;
}

const InvitedGuests = ({
  invitedGuests,
  sendInvite,
  removeInviteeFromBuilding,
}: IProps) => {
  const { t } = useTranslation();
  const formRef = useRef<HTMLFormElement>(null);

  function statusCell(info: {
    getValue: () => {
      modified: string;
    };
  }) {
    return (
      <>
        <Badge
          type="attention"
          size="small"
          className="text-sm min-w-max"
        >{`${t("shareBuilding.invitedOn")} ${
          moment(info?.getValue()?.modified).format("DD.MM.YYYY") || "-"
        }`}</Badge>
      </>
    );
  }

  function accountCell(info: {
    getValue: () => {
      email: string;
    };
  }) {
    return (
      <div className="text-gray-500">
        <Truncate className="max-w-[160px]">{info?.getValue()?.email}</Truncate>
      </div>
    );
  }

  function removeCell(info: {
    getValue: () => {
      id: string;
      email: string;
    };
  }) {
    return (
      <div className="flex justify-center">
        <Button
          type="danger"
          width="fit-content"
          leadingIcon={<IconX />}
          className="border-none"
          onClick={() =>
            removeInviteeFromBuilding(
              info?.getValue()?.id,
              info?.getValue()?.email
            )
          }
        ></Button>
      </div>
    );
  }

  const getColumns = () => {
    const columns = useMemo(
      () => [
        {
          accessorFn: (row: { email: string }) => row,
          id: "account",
          cell: (info: {
            getValue: () => {
              email: string;
            };
          }) => accountCell(info),
          header: () => <div>{t("commons.user")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorFn: (row: { modified: string }) => row,
          id: "Status",
          cell: (info: {
            getValue: () => {
              modified: string;
            };
          }) => statusCell(info),
          header: () => <div>{t("commons.status")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
        },
        {
          accessorFn: (row: { id: string; email: string }) => row,
          id: "Remove",
          cell: (info: {
            getValue: () => {
              id: string;
              email: string;
            };
          }) => removeCell(info),
          header: () => (
            <div className="m-auto">{t("shareBuilding.remove")}</div>
          ),
          enableColumnFilter: false,
          enableSorting: false,
          size: 60,
        },
      ],
      [invitedGuests]
    );
    return columns;
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (error) return;
    await sendInvite(formRef?.current?.email.value);
    formRef.current?.reset();
  };
  const [error, setError] = useState<string | undefined>();

  const validateEmail = React.useCallback((value: string | undefined) => {
    if (!value) {
      setError(undefined);
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      setError("Invalid email address");
    } else {
      setError(undefined);
    }
  }, []);

  return (
    <div className="p-3 py-6">
      <Alert
        type="info"
        title={t("shareBuilding.guestAccessTitle")}
        description={t("shareBuilding.guestAccessInfo")}
      />
      <form onSubmit={onSubmit} ref={formRef}>
        <div className="flex w-full gap-2 justify-between items-end mt-4 mb-5">
          <Input
            labelName={t("commons.email")}
            containerClassName="w-full"
            placeholder={t("shareBuilding.guestEmailPlaceholder")}
            type="email"
            name="email"
            size="large"
            onKeyup={validateEmail}
            errorMessage={error}
            noMargin
          />
          <Button
            htmlType="submit"
            type="primary"
            width="fit-content"
            className="font-normal min-w-max mt-5"
          >
            {t("shareBuilding.inviteGuests")}
          </Button>
        </div>
      </form>
      {invitedGuests.length > 0 && (
        <SearchSortTable
          data={invitedGuests}
          getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
          borderedStyle
          scrollDivClassName="max-h-[191px]"
          headerColumnStyle="h-[53px]"
        />
      )}
    </div>
  );
};

export default InvitedGuests;
