import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { UploadType } from ".";

type InfoContent = {
  id: string;
  heading: string;
  subHeading: string;
  url: string;
  buttonTitle: string;
};

export default function DownloadModelRequirements({
  uploadType,
}: {
  uploadType: UploadType | null;
}) {
  const { t, i18n } = useTranslation();

  const infoContents = useMemo(() => {
    return i18n
      .t<InfoContent[]>("mapping.infoContents", {
        returnObjects: true,
      })
      .find((item) => item.id === uploadType);
  }, []);

  return (
    <>
      <div className="text-sm font-normal text-gray-700 pb-4">
        {uploadType === "IFC"
          ? t("buildingMaterialResources.bimModelRequirements")
          : t("buildingMaterialResources.csvModelRequirements")}
      </div>
      {infoContents && (
        <Alert
          className="mb-4"
          key={infoContents.id}
          type="info"
          title={infoContents.heading}
          description={infoContents.subHeading}
          actionButton={infoContents.buttonTitle}
          actionButtonOnClick={() =>
            window.open(infoContents.url, "_blank", "noopener,noreferrer")
          }
        />
      )}
    </>
  );
}
