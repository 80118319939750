import clsx from "clsx";
import InputLabel from "components/InputLabel";
import useInvalidInput from "hooks/useInvalidInput";
import useSwitch from "hooks/useSwitch";
import React from "react";

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
  unit?: string;
  labelName?: string;
  containerClass?: string;
  enforceRequired?: boolean;
  handleChange?: (value: string, name?: string) => void | Promise<void>;
  errorMessage?: string;
  noMargin?: boolean;
  hasError?: boolean;
}

export default function TextInput(props: TextInputProps) {
  const [showError, switchShowError] = useSwitch(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  // To check validity after blur.
  function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
    if (props.required) {
      if (e.target.value.length === 0) switchShowError.on();
      else switchShowError.off();
    }
    props.onBlur && props.onBlur(e);
  }
  // To check validity after change.
  function handleChange(e: React.FocusEvent<HTMLInputElement>) {
    const isEmpty = e.target.value.length === 0;
    if (props.required && showError && !isEmpty) {
      switchShowError.off();
    }
    if (props.required && !showError && isEmpty) {
      switchShowError.on();
    }
    props.onChange && props.onChange(e);
    const validatedValue =
      (e.target.value.includes("-") && Number(props.min) > 0) ||
      (e.target.value === "0" && Number(props.min) > 0)
        ? ""
        : e.target.value;
    props.handleChange?.(validatedValue, props.name);
  }

  useInvalidInput(inputRef, (isInvalid) => {
    if (isInvalid) {
      switchShowError.on();
    } else {
      switchShowError.off();
    }
  });

  function validateValue(): string | number | readonly string[] | undefined {
    return (props.min && Number(props.value) < Number(props.min)) ||
      (props.max && Number(props.value) > Number(props.max)) ||
      (Number(props.min) > 0 && String(props.value).includes("-")) ||
      (Number(props.min) > 0 && props.value === 0)
      ? ""
      : props.value;
  }

  return (
    <div
      style={props.style}
      className={`pb-15 ${props.noMargin ? "mb-0" : "mb-4"} ${
        props.containerClass ?? ""
      }`}
    >
      {props.labelName && (
        <InputLabel
          showError={showError}
          required={props.required}
          labelName={props.labelName}
          name={props.name as string}
        />
      )}
      <div className="mt-0.5 relative flex items-center w-[100%] mx-auto">
        <input
          ref={inputRef}
          min={props.min}
          max={props.max}
          value={validateValue()}
          step={props.step}
          data-invalid="false"
          placeholder={props.placeholder}
          name={props.name}
          defaultValue={props.defaultValue}
          required={props.required && props.enforceRequired !== false}
          disabled={props.disabled}
          key={`text-input-key-${props.key ?? ""}-${props.defaultValue}`}
          onInvalid={() => switchShowError.on()}
          type={props.type}
          onChange={handleChange}
          onClick={props.onClick}
          onBlur={handleBlur}
          className={clsx(
            "appearance-none block w-full px-2 py-[7px] border border-gray-300 rounded-md",
            "shadow-sm placeholder-gray-400",
            "text-sm appearance-none focus:border-indigo-500",
            {
              "opacity-50": props.disabled,
            },
            {
              "border-red-700 focus:border-red-700":
                showError || props.hasError,
            },
            props.className
          )}
        />
        {props.unit && (
          <span
            className={`absolute right-[1px] flex items-center rounded-tr-md rounded-br-md bg-gray-100 border border-transparent h-[94%] px-2 text-sm ${
              showError || props.hasError
                ? "text-red-700 border-l-red-700"
                : "text-gray-400 border-l-gray-300"
            }`}
          >
            {props.unit}
          </span>
        )}
        {props.children}
      </div>
      {props.required && props.value === "" && props.errorMessage && (
        <label className="text-red-700 text-[12px]">{props.errorMessage}</label>
      )}
    </div>
  );
}
