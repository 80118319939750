import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import React from "react";
import { unitPower } from "utils";

interface CollapseProps {
  headerTitle: string;
  children: React.ReactNode;
  closeTitle?: string;
  headerClassName?: string;
  defaultClose?: boolean;
  containerClassName?: string;
  className?: string;
  headerValue?: string | number;
  unitValue?: string | number;
}

export default function Collapse({
  headerTitle,
  children,
  closeTitle,
  headerClassName,
  defaultClose,
  containerClassName,
  className,
  headerValue,
  unitValue,
}: CollapseProps) {
  return (
    <Disclosure defaultOpen={!defaultClose}>
      {({ open }) => (
        <div className={containerClassName}>
          <Disclosure.Button
            className={clsx(
              "flex justify-between items-center bg-gray-50 text-sm font-medium py-2 px-3 !mt-0 w-full",
              `${open ? "border-b-gray-100 border-b" : "border-b-gray-300"}`,
              headerClassName
            )}
          >
            {open ? headerTitle : closeTitle ?? headerTitle}
            <div className="flex items-center">
              {headerValue && (
                <div className="px-2 font-medium">
                  {headerValue} {unitPower(String(unitValue ?? "")) ?? ""}
                </div>
              )}
              <ChevronUpIcon
                width={16}
                className={clsx(
                  { "rotate-90 transform": !open },
                  "text-gray-700"
                )}
              />
            </div>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
            className={clsx("!mt-0", className)}
          >
            <Disclosure.Panel static>{children}</Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
}
