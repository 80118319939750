import BuildingProductEditor from "features/MappingTools/BuildingProductEditor";
import ProductEditorHeader from "features/MappingTools/BuildingProductEditor/ProductEditorHeader";
import ProductOverview from "features/MappingTools/BuildingProductEditor/ProductOverview";
import EditorLayout from "layouts/EditorLayout";
import React from "react";
import { useSetRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";

export default function ProductEditorPage() {
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);

  React.useEffect(() => {
    setSidebarOpened(false);
  }, []);

  return (
    <EditorLayout header={<ProductEditorHeader />}>
      <BuildingProductEditor />
      <ProductOverview />
    </EditorLayout>
  );
}
