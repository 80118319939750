import ButtonGroups, { BGItem } from "components/ButtonGroups";
import InputLabel from "components/InputLabel";
import SimpleSelect from "components/SimpleSelect";
import React from "react";
import { useTranslation } from "react-i18next";
import { certExportStore } from "store/CertExportStore";
import { SUPPORTING_DOCS } from "utils";

const buildingTypes: BGItem[] = [
  {
    id: "residential-building",
    name: "Residential building",
    name_de: "Wohngebäude",
  },
  {
    id: "non-residential-building",
    name: "Non-residential building",
    name_de: "Nicht-Wohngebäude",
  },
];
const levelOfRequirements: BGItem[] = [
  {
    id: "residential-building",
    name: "PLUS",
    name_de: "PLUS",
  },
  {
    id: "non-residential-building",
    name: "PREMIUM",
    name_de: "PREMIUM",
  },
];
const energeticStandards: BGItem[] = [
  {
    id: "residential_under_40",
    name: "< 40 QP in % of QP Ref",
    name_de: "< 40 QP in % von QP Ref",
  },
  {
    id: "residential_over_40",
    name: "> 40 QP in % of QP Ref",
    name_de: "> 40 QP in % von QP Ref",
  },
];
const buildingClasses = [
  { id: "1", name: "K1" },
  { id: "2", name: "K2" },
  { id: "3", name: "K3" },
  { id: "4", name: "K4" },
  { id: "5", name: "K5" },
  { id: "6", name: "K6" },
];
const AdditionalInfo = () => {
  const { additionalInfo } = certExportStore;
  const [selectedBuildingType, setSelectedBuildingType] = React.useState(
    buildingTypes.find(
      (item) =>
        item.name === additionalInfo.buildingType ||
        item.name_de === additionalInfo.buildingType
    )
  );
  const [selectedLevel, setSelectedLevel] = React.useState(
    levelOfRequirements.find(
      (item) => item.name === additionalInfo.levelOfRequirements
    )
  );
  const [selectedStandard, setSelectedStandard] = React.useState(
    energeticStandards.find(
      (item) =>
        item.name === additionalInfo.energeticStandard ||
        item.name_de === additionalInfo.energeticStandard
    )
  );

  const { t } = useTranslation();
  return (
    <>
      <div>
        <InputLabel
          labelName={t("certificationExport.buildingType")}
          name={"building_type"}
        />
        <ButtonGroups
          containerClassName="cursor-pointer mb-4"
          items={buildingTypes}
          onChange={(e) => {
            setSelectedBuildingType(e);
            certExportStore.setAdditionalInfoFieldAndValue(
              "buildingType",
              e?.name
            );
          }}
          current={selectedBuildingType}
        />
        {selectedBuildingType?.name?.includes("Residential building") && (
          <>
            <InputLabel
              labelName={t("certificationExport.energeticStandard")}
              name={"energetic_standard"}
            />
            <ButtonGroups
              containerClassName="cursor-pointer mb-4"
              items={energeticStandards}
              onChange={(e) => {
                setSelectedStandard(e);
                certExportStore.setAdditionalInfoFieldAndValue(
                  "energeticStandard",
                  e?.name
                );
              }}
              current={selectedStandard}
            />
          </>
        )}
        {selectedBuildingType?.name?.includes("Non-residential") && (
          <>
            <InputLabel
              labelName={t("certificationExport.levelOfRequirements")}
              name={"level_of_requirements"}
            />
            <ButtonGroups
              containerClassName="cursor-pointer mb-4"
              items={levelOfRequirements}
              onChange={(e) => {
                setSelectedLevel(e);
                certExportStore.setAdditionalInfoFieldAndValue(
                  "levelOfRequirements",
                  e?.name
                );
              }}
              current={selectedLevel}
            />
            <SimpleSelect
              className="rounded-sm"
              labelName={t("certificationExport.buildingClass")}
              options={buildingClasses}
              name="building_class"
              placeholder={t("certificationExport.buildingClassPlaceholder")}
              onChange={(value) =>
                certExportStore.handleOnDropdownChange(
                  "buildingClass",
                  buildingClasses,
                  value
                )
              }
              current={
                buildingClasses.find(
                  (item) => item.name === additionalInfo.buildingClass
                )?.id ?? ""
              }
            />
            <SupportingDocs />
          </>
        )}
      </div>
    </>
  );
};

const SupportingDocs = () => {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  return (
    <div className="text-gray-500 text-sm mb-4">
      <p>
        {isEnglish ? (
          <>
            Check out{" "}
            <a
              href={SUPPORTING_DOCS}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              supporting documents
            </a>{" "}
            to get help
          </>
        ) : (
          <>
            Nutzen Sie{" "}
            <a
              href={SUPPORTING_DOCS}
              target="_blank"
              rel="noopener noreferrer"
              className="font-bold underline"
            >
              diese Dokumente
            </a>
            , um Ihre LCA Klasse herauszufinden
          </>
        )}
      </p>
    </div>
  );
};

export default AdditionalInfo;
