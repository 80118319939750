import { AuditIN, AuditOUT, SlimFieldOUT } from "api-client/generated";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { db } from "./db";
import { LocalAudit } from "./db_interfaces";

export async function fetchThenStoreLocal<T>(request: Promise<AxiosResponse>) {
  return request.then((response) => {
    storeLocal(response.data);
    return response.data as T;
  });
}

export async function storeLocal(data: LocalAudit | AuditOUT | AuditIN) {
  if (Array.isArray(data)) {
    return await upsertMultiple(data);
  } else {
    return await db.audits.put(data);
  }
}

export async function upsertMultiple(data: AuditOUT[]) {
  try {
    await db.transaction("rw", db.audits, async () => {
      await Promise.all(
        data.map(async (item) => {
          const existingItem = await db.audits.get(item.id);
          if (existingItem) {
            await db.audits.update(item.id, item);
          } else {
            await db.audits.add(item);
          }
        })
      );
    });
  } catch (err) {
    console.error("(Dexie DB) (upsertMultiple)", err);
  }
}

export async function getLocalAuthHeader() {
  const headerKey = "current_user_metadata";
  const localKeys = Object.keys(localStorage).filter((key) =>
    key.includes(headerKey)
  );
  let authHeader: AxiosRequestConfig | undefined;

  if (localKeys.length > 0) {
    const metadata = JSON.parse(localStorage.getItem(localKeys[0]) || "");
    const token = metadata && metadata.body.id_token;
    if (token) {
      authHeader = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  }

  return authHeader;
}

export async function getProductGroupById(id: string) {
  return await db.product_groups.where({ id }).first();
}

export async function getFieldOUTsForSlimFieldOUTs(
  slimFieldOUTs: [SlimFieldOUT]
) {
  return db.fields
    .where("id")
    .equals(slimFieldOUTs.map((f) => f.id))
    .toArray();
}

export type TStorageInfo = {
  usage?: string;
  quota?: string;
  percentageUsed: number;
  percentageLeft: number;
};

export const getStorageCapacityInfo = async () => {
  let storageInfo: TStorageInfo = {
    percentageUsed: 0,
    percentageLeft: 0,
  };

  if ("storage" in navigator && "estimate" in navigator.storage) {
    const { usage, quota } = await navigator.storage.estimate();
    const percentageUsed = ((usage ?? 0) / (quota ?? 1)) * 100;
    const percentageLeft = 100 - percentageUsed;
    storageInfo = {
      percentageUsed,
      percentageLeft,
    };
  }

  return storageInfo;
};
