import React from "react";
import DropdownCheckbox, { DropdownOption } from "components/DropdownCheckbox";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";

export interface OtherPropertiesOption extends DropdownOption {
  subtitle?: {
    name?: string;
  };
}

export default observer(function OtherPropertiesFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: OtherPropertiesOption[];
  setSelected: React.Dispatch<React.SetStateAction<OtherPropertiesOption[]>>;
  removingItem?: OtherPropertiesOption;
  handleRemoveItem?: () => void;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const onChange = (value: OtherPropertiesOption[]) => setSelected(value);

  if (!searchStore.filtersOptions.other_properties) return <></>;
  return (
    <DropdownCheckbox<OtherPropertiesOption>
      displayKey={isEng ? "name" : "name_de"}
      detailKey={"subtitle"}
      required={true}
      handleSelect={onChange}
      labelName={t("mapping.otherProperties")}
      name="other_properties"
      items={searchStore.filtersOptions.other_properties}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="min-w-[146px]"
      checkedItems={selected}
      optionsClassName="!max-w-fit"
      noMargin
    />
  );
});
