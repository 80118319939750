import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { ColumnVisibilityState } from "store/IfcMapping/DME/types";

const COLUMN_VISIBILITY_STATE_NAME = "DME_columns";

export function replaceVisibilitySettings(): ColumnVisibilityState {
  /**
   * Takes existing visibility in local storage,
   * updates it with the given columns and then
   * stores it back in local storage
   */
  const savedState = retrieveSavedColumns();
  let visibleKeys: string[] = [];
  visibleKeys = getVisibleColumnKeys(savedState);
  if (!visibleKeys.length) {
    visibleKeys = dynamicMEStore.getDBSavedDefaultColumnsKeys();
  }
  const state: { [key: string]: boolean } = {};
  dynamicMEStore.dynamicColumns.forEach((el) => (state[el.key_name] = false));
  visibleKeys.forEach((key) => {
    // Columns that no longer exists are lost. e.g. ifc properties
    if (key in state) {
      state[key] = true;
    }
  });
  const visibleColumnsKeys = getVisibleColumnKeys(state);
  dynamicMEStore.setDefaultVisibleColumnProperties(visibleColumnsKeys);
  return state;
}

export function getVisibleColumnKeys(state: ColumnVisibilityState): string[] {
  /**
   * Returns only the columns marked as visible
   */
  const visibleColumnNames = Object.entries(state)
    .map((item) => {
      if (item[1] === true) return item[0];
    })
    .filter(Boolean) as string[];
  return visibleColumnNames;
}

export function saveColumnState(state: ColumnVisibilityState) {
  /**
   * Saves the state in the local storage
   */
  localStorage.setItem(COLUMN_VISIBILITY_STATE_NAME, JSON.stringify(state));
}

export function retrieveSavedColumns(): ColumnVisibilityState {
  /**
   * Returns the information from the local storage
   */
  const savedState = JSON.parse(
    localStorage.getItem(COLUMN_VISIBILITY_STATE_NAME) ?? "{}"
  );
  return savedState;
}
