import React from "react";
import { useTranslation } from "react-i18next";
import BPENotFoundIcon from "assets/images/icons/not-found.svg";

interface NotFoundProps {
  description?: string;
  noZIndex?: boolean;
}

export default function NotFound({ description, noZIndex }: NotFoundProps) {
  const { t } = useTranslation();

  return (
    <div className="flex h-full text-center items-center justify-center text-sm text-gray-500">
      <div
        className={`flex flex-col ${
          noZIndex ? "z-10" : "-z-10"
        } w-fit mt-16 items-center justify-center`}
      >
        <span>
          <img src={BPENotFoundIcon} className="pt-2" />
        </span>
        <span className="font-normal text-sm text-gray-500 p-4 w-96 text-center">
          {description ?? t("mapping.noResults")}
        </span>
      </div>
    </div>
  );
}
