import React, { Fragment, ReactNode, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import useSwitch from "hooks/useSwitch";
import { useTranslation } from "react-i18next";
import useInvalidInput from "hooks/useInvalidInput";
import { slugify } from "utils";
import clsx from "clsx";
import NotFoundImg from "assets/images/icons/not-found.svg";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import OptionItem from "./OptionItem";

export interface DropdownOption {
  id?: string | number;
  name: string;
  name_de?: string;
  icon?: ReactNode;
  title?: string;
  title_de?: string;
  value?: string;
  children?: DropdownOption[];
}
export interface DropdownWithSearchProps<T>
  extends React.HTMLProps<HTMLInputElement> {
  labelName?: string;
  items: T[];
  displayKey: keyof T;
  detailKey?: keyof T;
  handleChange?: (value: string) => void;
  handleSelect?: (value: T[], name?: string) => void;
  className?: string;
  containerClassName?: string;
  optionsClassName?: string;
  removeSelected?: T | null;
  handleRemoveSelected?: () => void;
  name?: string;
  height?: "small" | "large" | "middle";
  noMargin?: boolean;
  labelWithSearch?: boolean;
  labelWithOutSearch?: boolean;
  hideOptionsSearch?: boolean;
  checkedItems?: T[];
  searchClassName?: string;
  disabled?: boolean;
  keepOpen?: boolean;
}

export default function DropdownCheckbox<Item extends object>(
  props: DropdownWithSearchProps<Item>
) {
  const [selectedItems, setSelectedItems] = React.useState<Item[]>([]);
  const [query, setQuery] = React.useState("");
  const [showError, switchShowError] = useSwitch(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!props.checkedItems) return;
    setSelectedItems(props.checkedItems);
  }, [props.checkedItems]);

  function checkQueryExists(item: Item, filteredList: Item[]) {
    let resolved;
    if (props.displayKey in item) {
      resolved = item[props.displayKey];
    } else return;
    const foundedItem = slugify(resolved as unknown as string).includes(
      slugify(query)
    );
    foundedItem && filteredList.push(item);
    if ((item as DropdownOption).children?.length) {
      (item as DropdownOption).children?.map((rec) =>
        checkQueryExists(rec as Item, filteredList)
      );
    }
  }

  function filterWithQuery() {
    const filteredList: Item[] = [];
    props.items.forEach((item) => {
      checkQueryExists(item, filteredList);
    });
    return filteredList;
  }

  const filteredItems: Item[] = query === "" ? props.items : filterWithQuery();

  const details =
    filteredItems?.map(
      (item) =>
        props.detailKey &&
        (item[props.detailKey] as unknown as {
          name: string;
          name_de: string;
        })
    ) ?? [];

  useInvalidInput(inputRef, (isInvalid) => {
    if (isInvalid) {
      switchShowError.on();
    } else {
      switchShowError.off();
    }
  });

  useEffect(() => {
    if (!props.removeSelected || !props.checkedItems) return;
    const items = props.checkedItems.filter(
      (item: Item) => item !== props.removeSelected
    );
    setSelectedItems([...items]);
    props.handleRemoveSelected?.();
    props.handleSelect?.(items);
  }, [props.removeSelected]);

  function searchField() {
    return (
      <Combobox.Input
        ref={inputRef}
        onSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.handleChange && props.handleChange(e.target.value);
        }}
        data-invalid={"false"}
        required={props.required}
        autoCorrect="off"
        autoComplete="off"
        onInvalid={() => switchShowError.on()}
        name={props.name}
        className={`w-full pl-1 lg:text-sm leading-5 ring-0 outline-none border-none focus:ring-0 rounded-md ${props.searchClassName}`}
        displayValue={(item: Item) => {
          let result;
          if (!item) return "";
          if (props.displayKey in item) {
            result = item[props.displayKey];
          } else return "";
          return (result as unknown as string).toString();
        }}
        onChange={(event) => {
          switchShowError.off();
          setQuery(event.target.value);
        }}
        placeholder={props.placeholder}
      />
    );
  }

  function findAllParents(
    data: DropdownOption[],
    childId: string,
    parents: DropdownOption[] = []
  ) {
    let parentPaths: DropdownOption[][] = [];
    for (const item of data) {
      // If we find the child ID, add the current path of parents to the results
      if (item.id === childId) {
        parentPaths.push([...parents]);
      }

      // If item has children, continue searching in the nested structure
      if (item.children && item.children.length > 0) {
        const childPaths = findAllParents(item.children, childId, [
          ...parents,
          item,
        ]);
        parentPaths = parentPaths.concat(childPaths); // Collect all paths
      }
    }

    return parentPaths.flat();
  }

  function checkParentSelection(
    item: DropdownOption,
    selected: DropdownOption[],
    checked: boolean
  ) {
    const parents = findAllParents(
      props.items as DropdownOption[],
      item.id as string
    );
    if (parents) {
      const parentIDs = parents.map((rec) => rec.id);
      if (!checked) {
        const filteredItems = selected.filter(
          (rec) => !parentIDs.includes(rec.id)
        );
        return filteredItems;
      } else {
        const reversedParents = parents.reverse();
        const currentSelectedList = [...selected];
        const validSelectedParent = reversedParents.filter((rec) => {
          if (isAllNestedItemsSelected(rec, currentSelectedList)) {
            currentSelectedList.push(rec);
            return rec;
          }
        });
        const addedItems = [...validSelectedParent, ...selected];
        return addedItems;
      }
    }
    return selected;
  }

  const isAllNestedItemsSelected = (
    currentItem: DropdownOption,
    selected?: DropdownOption[]
  ) => {
    const currentItemChildrenIDs: string[] = [];
    getAllNestedChildrenIDs(currentItemChildrenIDs, currentItem);
    const selectedIDs = selected?.map((rec) => rec.id);
    const allExist = currentItemChildrenIDs.every((rec) =>
      selectedIDs?.includes(rec)
    );
    return allExist;
  };

  const handleOnClick = (checked: boolean, item: DropdownOption | Item) => {
    let selected: DropdownOption[] = [...(selectedItems as DropdownOption[])];
    if (checked) {
      selected = onSelectItem(selected, item);
    } else {
      selected = onDeselectItem(item);
    }
    selected = checkParentSelection(item as DropdownOption, selected, checked);
    props.handleSelect?.(selected as Item[]);
    setSelectedItems(selected as Item[]);
  };

  function onSelectItem(
    selected: DropdownOption[],
    item: Item | DropdownOption
  ) {
    selected.push(item as DropdownOption);
    checkChildrenOnSelect(item, selected);
    return selected;
  }

  function onDeselectItem(item: Item | DropdownOption) {
    const filteredItems = selectedItems.filter(
      (rec) => (rec as DropdownOption).id !== (item as DropdownOption).id
    ) as DropdownOption[];
    const result = checkChildrenOnDeselect(item, filteredItems);
    return result;
  }

  function checkChildrenOnDeselect(
    item: Item | DropdownOption,
    selectedItems: DropdownOption[]
  ) {
    const childrenIDs: string[] = [];
    getAllNestedChildrenIDs(childrenIDs, item);
    /* Filter to deselect child nodes */
    selectedItems = selectedItems.filter(
      (rec) => !childrenIDs.includes(rec.id as string)
    );
    return selectedItems;
  }

  function getAllNestedChildrenIDs(
    children_ids: string[],
    item: Item | DropdownOption
  ) {
    const itemChildren = (item as unknown as DropdownOption).children;
    if (itemChildren?.length) {
      children_ids.push(...(itemChildren.map((rec) => rec.id) as string[]));
      itemChildren.forEach((rec) => getAllNestedChildrenIDs(children_ids, rec));
    }
  }

  function checkChildrenOnSelect(
    item: Item | DropdownOption,
    selected: DropdownOption[]
  ) {
    const itemChildren = (item as unknown as DropdownOption).children;
    if (itemChildren?.length) {
      /* Select child nodes */
      const selected_ids = selected.map((rec) => rec.id);
      const newItems = itemChildren.filter(
        (rec) => !selected_ids.includes(rec.id)
      );
      newItems.forEach((rec) => checkChildrenOnSelect(rec, selected));
      selected.push(...newItems);
    }
  }

  function emptyState() {
    return (
      <div className="flex justify-center">
        <div className="w-[300px] flex flex-col -z-10 py-4 items-center justify-center">
          <span>
            <img alt="not-found" src={NotFoundImg} className="pt-2" />
          </span>
          <span className="font-normal text-sm text-gray-500 p-4 text-center">
            {t("commons.onDropDownNoSearchResult")}
          </span>
        </div>
      </div>
    );
  }

  const hasChildren = () => {
    return Boolean(
      filteredItems.find((item) => (item as unknown as DropdownOption).children)
    );
  };

  function optionListItems() {
    return (
      <Combobox.Options
        className={clsx(
          "absolute z-10 my-1 max-h-60 w-full overflow-auto rounded-md bg-white text-base !p-0",
          "shadow-lg border border-gray-300 min-w-auto w-auto focus:outline-none sm:text-sm",
          props.optionsClassName,
          { "cursor-not-allowed": props.disabled }
        )}
      >
        {!props.labelWithSearch && !props.hideOptionsSearch && (
          <div className="sticky top-0 bg-gray-50 p-1 z-10 border-b border-gray-200">
            <div
              className={clsx(
                "bg-white border-gray-300 mx-2 mt-1 border rounded-md mb-2",
                "flex justify-between pl-2 items-center pr-3 text-gray-900"
              )}
            >
              {searchField()}
              <IconSearch width={18} className="text-gray-400" />
            </div>
          </div>
        )}
        {filteredItems?.length === 0 && query !== ""
          ? emptyState()
          : filteredItems?.map((item, i) => (
              <OptionItem
                key={i}
                item={item}
                index={i}
                handleOnClick={handleOnClick}
                disabled={props.disabled}
                displayKey={props.displayKey}
                details={details[i]}
                checkedItems={selectedItems}
                query={query}
                hasChildren={hasChildren()}
                getAllNestedChildrenIDs={getAllNestedChildrenIDs}
              />
            ))}
      </Combobox.Options>
    );
  }

  function comboContent() {
    return (
      <div className={clsx("mt-1", props.className)}>
        <div
          className={clsx(
            "flex items-center cursor-pointer overflow-hidden rounded-md font-medium text-sm",
            "border  text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white h-large",
            showError ? "border border-red-700 text-red-700" : "",
            selectedItems?.length &&
              !props.labelWithSearch &&
              !props.labelWithOutSearch
              ? "border-indigo-700"
              : "border-gray-300",
            {
              "h-large": props.height === "large",
              "!h-middle": props.height === "middle",
              "!h-small": props.height === "small",
            }
          )}
        >
          <Combobox.Button
            className={clsx(
              "flex justify-between w-full h-full inset-y-0 right-0 items-center px-3",
              selectedItems?.length &&
                !props.labelWithSearch &&
                !props.labelWithOutSearch
                ? "text-indigo-700 bg-indigo-100"
                : "text-gray-700 bg-white"
            )}
          >
            {({ open }) => (
              <div className="flex justify-between w-full items-center">
                <div>
                  {props.labelName && (
                    <label
                      className="cursor-pointer pr-1"
                      htmlFor={props.name as string}
                    >
                      {props.labelName}
                    </label>
                  )}
                  {props.labelWithSearch && searchField()}
                </div>
                <IconChevronDown
                  className={clsx(
                    "h-5 w-4",
                    selectedItems?.length &&
                      !props.labelWithSearch &&
                      !props.labelWithOutSearch
                      ? "text-indigo-800"
                      : "text-gray-500",
                    open ? "rotate-180" : ""
                  )}
                  aria-hidden="true"
                />
              </div>
            )}
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery("")}
        >
          {optionListItems()}
        </Transition>
      </div>
    );
  }

  return (
    <div
      className={`${props.containerClassName ?? ""}${
        props.noMargin ? " mb-0" : " mb-4"
      }`}
    >
      {props?.keepOpen ? (
        <Combobox value={selectedItems} onChange={() => null} multiple>
          {comboContent()}
        </Combobox>
      ) : (
        <Combobox value={selectedItems} onChange={() => null}>
          {comboContent()}
        </Combobox>
      )}
    </div>
  );
}
