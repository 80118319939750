import { db } from "./db";
import { LocalImage } from "./db_interfaces";
import { ImageOUT } from "../api-client";

export async function getFirstLocalImageForSource(sourceId: string) {
  const images = await getLocalImagesForSource(sourceId);
  if (images.length > 0) {
    const sorted = images.sort((a, b) => b.order - a.order);
    return sorted[0];
  }
  return null;
}

export async function getLocalImagesForSource(sourceId: string) {
  const images = await db.images.where({ sourceId }).toArray();
  return images || [];
}

export function tryGenerateUrlForImage(image: ImageOUT | LocalImage | File) {
  if (image instanceof File) {
    return URL.createObjectURL(image);
  }
  if ("sourceType" in image) {
    return URL.createObjectURL(image.file);
  } else {
    return image.image;
  }
}

export async function deleteLocalImage(imageId: string) {
  await db.images.delete(imageId);
  return imageId;
}
