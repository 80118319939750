import React from "react";
import Button from "components/Button";
import { useLocation, useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";

export default function BimViewerHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="sm:flex w-full bg-white px-4 py-3.5 items-center justify-between border-b border-gray-300">
      <div id="editor-title" className="sm:flex mb-3 sm:mb-0">
        <div className="flex justify-between">
          <div className="text-xl font-semibold text-black mr-6 mb-2 sm:mb-0">
            {t("bimViewer.bimViewer")}
          </div>
          <Button
            width="fit-content"
            className="sm:hidden block"
            onClick={() => navigate("../material-resources", { replace: true })}
          >
            {t("mapping.done")}
          </Button>
        </div>
        {location.state ? (
          <Badge type="info" className="mr-6 mb-2 sm:mb-0">
            {`${location?.state}`}
          </Badge>
        ) : null}
      </div>
      <div className="flex">
        <Button
          width="fit-content"
          className="sm:block hidden"
          onClick={() => navigate("../material-resources", { replace: true })}
        >
          {t("mapping.done")}
        </Button>
      </div>
    </div>
  );
}
