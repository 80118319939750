import {
  EPDConformityOut,
  ManufacturerOUT,
  SimpleMaterialOut,
} from "api-client";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import { observer } from "mobx-react-lite";
import EPDTypeFilter from "../ProductSearch/Filters/EPDTypeFilter";
import MaterialCategoryFilter from "../ProductSearch/Filters/MaterialCategoryFilter";
import { Option } from "store/IfcMapping/BuildingProductStore";
import DataSourceFilter from "../ProductSearch/Filters/DataSourceFilter";
import ManufacturerFilter from "../ProductSearch/Filters/ManufacturerFilter";
import ConformityFilter from "../ProductSearch/Filters/ConformityFilter";
import clsx from "clsx";

export interface Filters {
  material_category?: SimpleMaterialOut[];
  epd_type?: Option[];
  dataset_source?: Option[];
  manufacturer?: ManufacturerOUT[];
  conformity?: EPDConformityOut[];
}

interface RemoveFilters {
  material_category?: SimpleMaterialOut;
  epd_type?: Option;
  dataset_source?: Option;
  manufacturer?: ManufacturerOUT;
  conformity?: EPDConformityOut;
}

const SearchFilters = observer(
  ({
    filters,
    setFilters,
    dataSourceDisabled,
  }: {
    filters: Filters;
    setFilters: (name: string, value: unknown[]) => void;
    dataSourceDisabled?: boolean;
  }) => {
    const { t } = useTranslation();
    const [removingItem, setRemovingItem] = useState<RemoveFilters>();

    function resetFilters() {
      Object.keys(filters).map((item) => setFilters(item, []));
    }

    React.useEffect(() => {
      resetFilters();
    }, []);

    const filterExist = (key: string) =>
      Object.keys(filters).find((item) => item === key);

    function addSelected(name: string, value: never[]) {
      setFilters(name, value);
    }

    function removeItem(name: string, value: never | undefined) {
      setRemovingItem((state) => ({
        ...state,
        [name]: value,
      }));
    }

    function filtersSelectors() {
      return (
        <div className="sm:flex flex-wrap relative">
          {filterExist("dataset_source") ? (
            <DataSourceFilter
              selected={filters?.dataset_source}
              setSelected={(value) =>
                addSelected("dataset_source", value as never)
              }
              removingItem={removingItem?.dataset_source}
              handleRemoveItem={() => removeItem("dataset_source", undefined)}
              disabled={dataSourceDisabled}
            />
          ) : null}
          {filterExist("manufacturer") ? (
            <ManufacturerFilter
              selected={filters?.manufacturer}
              setSelected={(value) =>
                addSelected("manufacturer", value as never)
              }
              removingItem={removingItem?.manufacturer}
              handleRemoveItem={() => removeItem("manufacturer", undefined)}
            />
          ) : null}
          {filterExist("conformity") ? (
            <ConformityFilter
              selected={filters?.conformity}
              setSelected={(value) => addSelected("conformity", value as never)}
              removingItem={removingItem?.conformity}
              handleRemoveItem={() => removeItem("conformity", undefined)}
            />
          ) : null}
          {filterExist("epd_type") ? (
            <EPDTypeFilter
              selected={filters?.epd_type}
              setSelected={(value) => addSelected("epd_type", value as never)}
              removingItem={removingItem?.epd_type}
              handleRemoveItem={() => removeItem("epd_type", undefined)}
            />
          ) : null}
          {filterExist("material_category") ? (
            <MaterialCategoryFilter
              selected={filters?.material_category}
              setSelected={(value) =>
                addSelected("material_category", value as never)
              }
              removingItem={removingItem?.material_category}
              handleRemoveItem={() =>
                removeItem("material_category", undefined)
              }
            />
          ) : null}
        </div>
      );
    }

    const showClear = Object.values(filters).find((item) => item.length);

    function selectedFiltersContent() {
      return (
        <div className="flex flex-wrap md:max-w-[650px] max-w-xs mt-1 mb-2 truncate">
          {Object.entries(filters).map((filter) =>
            filter[1].map((item: { name: string | number }, index: number) => (
              <Badge
                key={index}
                type="auto"
                size="large"
                className={clsx(
                  "bg-indigo-100 text-indigo-700 rounded-full mr-2 py-2 px-4 mb-2"
                )}
                onClose={() => removeItem(filter[0], item as never)}
                closable={
                  !(filter[0] === "dataset_source" && dataSourceDisabled)
                }
              >
                <span className="truncate">{item?.name}</span>
              </Badge>
            ))
          )}
        </div>
      );
    }

    return (
      <>
        <div className="border-b border-gray-200 flex items-center justify-center my-6">
          <span className="absolute bg-white px-3 text-sm font-normal text-gray-600">
            {t("mapping.filter")}
          </span>
        </div>

        <div className="flex items-center">
          {filtersSelectors()}
          {showClear && (
            <div
              onClick={() => resetFilters()}
              className="ml-8 text-xs text-gray-600 font-medium underline cursor-pointer"
            >
              {t("commons.resetFilters")}
            </div>
          )}
        </div>
        {selectedFiltersContent()}
      </>
    );
  }
);

export default React.memo(SearchFilters);
