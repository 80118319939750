import { EyeIcon, EyeOffIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import InputLabel from "components/InputLabel";
import React, { useState } from "react";

interface IProps {
  labelName?: string;
  value?: string;
  defaultValue?: string;
  type?: "string" | "number" | "boolean" | "url" | "email" | "password";
  onChange?: (value: string | undefined, name?: string) => void;
  onKeyup?: (value: string | undefined) => void;
  placeholder?: string;
  name?: string;
  className?: string;
  errorMessage?: string;
  typingError?: string;
  containerClassName?: string;
  noMargin?: boolean;
  noArrow?: boolean;
  size?: "large" | "middle" | "small";
  onBlur?: (name: string, emptyRequired?: boolean) => void;
  required?: boolean;
  infoText?: string;
  disable?: boolean;
  unit?: string;
  min?: number;
  max?: number;
  showMandatoryAlert?: boolean;
  testID?: string;
  inputDivClassName?: string;
}

const Input = React.forwardRef(
  (
    {
      labelName,
      value,
      defaultValue,
      type = "string",
      onChange,
      onKeyup,
      placeholder,
      name = "",
      className,
      errorMessage,
      typingError,
      containerClassName,
      noMargin,
      noArrow,
      size = "middle",
      onBlur,
      required,
      infoText,
      disable,
      unit,
      min,
      max,
      showMandatoryAlert,
      testID,
      inputDivClassName,
    }: IProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const [visiblePassword, setVisiblePassword] = useState(false);
    const handleOnBlur = () => {
      onBlur && onBlur(name, required && !value?.length);
    };

    function checkMandatoryAlert() {
      return (
        required &&
        (!value?.length || value === "undefined" || value === "null") &&
        showMandatoryAlert
      );
    }

    return (
      <div
        className={clsx(`pb-15 relative ${containerClassName}`, {
          "mb-4": !noMargin,
        })}
      >
        {labelName ? (
          <InputLabel
            showError={!!errorMessage && checkMandatoryAlert()}
            required={required}
            labelName={labelName ?? ""}
            name={name}
          />
        ) : null}
        <div
          className={clsx(
            "mt-0.5 relative flex items-center",
            inputDivClassName
          )}
        >
          <input
            ref={ref}
            defaultValue={defaultValue}
            type={visiblePassword ? "text" : type}
            className={clsx(
              "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md",
              "shadow-sm placeholder-gray-400 focus:outline-none",
              "focus:border-indigo-500 sm:text-sm appearance-none",
              `${className} ${noArrow || unit ? "number-no-arrow" : ""}`,
              {
                "h-large": size === "large",
                "h-middle": size === "middle",
                "h-small": size === "small",
                "!border-red-500": checkMandatoryAlert(),
                "opacity-50": disable,
              }
            )}
            value={min && Number(value) < Number(min) ? "" : value}
            name={name}
            onChange={(e) =>
              onChange && onChange(e.target.value, e.target.name)
            }
            placeholder={placeholder}
            onBlur={handleOnBlur}
            disabled={disable}
            onKeyUp={(e) =>
              onKeyup && onKeyup((e.target as HTMLInputElement).value)
            }
            min={min}
            max={max}
            test-id={testID}
          />
          {unit && (
            <span className="absolute right-4 text-sm text-gray-400">
              {unit}
            </span>
          )}
          {type === "password" &&
            (visiblePassword ? (
              <EyeOffIcon
                width={20}
                className="absolute right-3 text-gray-400"
                onClick={() => setVisiblePassword(false)}
              />
            ) : (
              <EyeIcon
                width={20}
                className="absolute right-3 text-gray-400"
                onClick={() => setVisiblePassword(true)}
              />
            ))}
        </div>
        {errorMessage && checkMandatoryAlert() && (
          <p className="text-red-700 font-light text-xs">{errorMessage}</p>
        )}
        {typingError && (
          <p className="text-red-700 font-light text-xs absolute">
            {typingError}
          </p>
        )}
        {(value?.length || !required) && infoText ? (
          <span className="text-gray-500">{infoText}</span>
        ) : null}
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
