import { RadioGroup } from "@headlessui/react";
import clsx from "clsx";
import ConfirmModal from "components/ConfirmModal";
import InputLabel from "components/InputLabel";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface BGItem {
  id: string;
  name?: string;
  name_de?: string;
  icon?: React.ReactNode;
}

interface ButtonGroupsProps {
  items: BGItem[];
  containerClassName?: string;
  optionClsssName?: string;
  groupClassName?: string;
  onChange: (e: BGItem | undefined) => void;
  current?: BGItem;
  disabled?: boolean;
  label?: string;
  hasConfirmation?: boolean;
  confirmTitle?: string;
  confirmButtonTitle?: string;
  confirmDescriptionMesage?: string;
}

interface ConfirmModalProps {
  open: boolean;
  value?: BGItem;
}

export default function ButtonGroups(props: ButtonGroupsProps) {
  const [selected, setSelected] = useState<BGItem | undefined>(props.current);
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";
  const [showConfirmModal, setShowConfirmModal] =
    React.useState<ConfirmModalProps>({
      open: false,
      value: undefined,
    });

  React.useEffect(() => {
    setSelected(props.items.find((item) => item.id === props.current?.id));
  }, [props.current]);

  function handleOnChange(value: BGItem) {
    if (props.hasConfirmation) {
      setShowConfirmModal({ open: true, value: value });
    } else {
      onConfirmChange(value);
    }
  }

  function onConfirmChange(value?: BGItem) {
    setSelected(value ?? showConfirmModal.value);
    props.onChange(value ?? showConfirmModal.value);
  }

  return (
    <div className={props.containerClassName}>
      {props.label && (
        <InputLabel
          labelName={props.label}
          name={props.label?.replaceAll(" ", "_")}
        />
      )}
      <RadioGroup
        value={selected}
        onChange={(value: BGItem) => handleOnChange(value)}
        className={clsx("mt-2", props.groupClassName)}
      >
        <div className="flex">
          {props.items.map((option, index) => (
            <RadioGroup.Option
              disabled={props.disabled}
              key={option.id}
              value={option}
              className={({ checked }) =>
                clsx(
                  selected && checked
                    ? "bg-indigo-600 text-white hover:bg-indigo-700"
                    : "bg-white text-gray-900 hover:bg-gray-50",
                  "flex cursor-pointer items-center justify-center py-2 px-3 text-sm",
                  "font-normal sm:flex-1 h-default border-gray-300 border",
                  {
                    "rounded-l-md": index === 0,
                    "rounded-r-md": index + 1 === props.items.length,
                    "border-l-0": index !== 0,
                  },
                  props.disabled ? "cursor-not-allowed" : "",
                  props.optionClsssName
                )
              }
            >
              <RadioGroup.Label as="span">
                {isEnglish ? option?.name : option?.name_de ?? option?.name}
                {option?.icon}
              </RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <ConfirmModal
        open={showConfirmModal.open}
        title={props.confirmTitle as string}
        confirmButtonTitle={props.confirmButtonTitle as string}
        descriptionMesage={props.confirmDescriptionMesage as string}
        close={() =>
          setShowConfirmModal({
            open: false,
            value: undefined,
          })
        }
        onConfirm={onConfirmChange}
      />
    </div>
  );
}
