import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { IconEdit } from "@tabler/icons-react";
import {
  buildingProductStore,
  EPDRowProps,
  Step,
} from "store/IfcMapping/BuildingProductStore";
import OverviewTile from "./OverviewTile";
import DatasetView, { EPDGroup } from "features/MappingTools/DatasetView";
import { EmptyStateOverview } from "features/MappingTools/utils";

export default observer(function ProductOverview() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  function createEpdsList(group: EPDRowProps[]) {
    return group?.map((item) => {
      return {
        factor: item?.factor ?? "-",
        epd_name: item.epd?.name_de || "-",
        material_name: item.material?.name || "-",
        dataset_source: item.epd?.dataset_source__name || "-",
        dataset_url: item.epd?.url || "-",
        recyclability_score: item.material?.recyclability_score || "-",
        recyclability_qualification:
          item.material?.recyclability_qualification || "-",
        reference_unit: item.epd?.reference_unit || "-",
        reference_size: item.epd?.reference_size || "-",
        conformity: item.epd?.conformity__name || "-",
        mass_value_m: item.epd?.mass_value_m || "-",
        mass_value_m2: item.epd?.mass_value_m2 || "-",
        mass_value_m3: item.epd?.mass_value_m3 || "-",
      };
    });
  }

  const epdGroups = buildingProductStore.data.groups.map((group) => {
    const epdsList = createEpdsList(group.epds);
    return {
      epds: epdsList,
      certifications: group.certifications,
      conformity: group.conformity,
    };
  });

  const hasOverview = () => {
    return Boolean(
      buildingProductStore.steps
        .map((_, index) => buildingProductStore.stepHasOverview(index))
        .find((item) => item)
    );
  };

  const stepOverview = (step: Step, index: number) => {
    return (
      <div
        className="border-b border-gray-200 pb-2 last-of-type:border-b-0"
        key={index}
      >
        <div className="flex justify-between mt-8 mb-5">
          <div className="text-base font-semibold">
            {t(`productEditor.${step.title}`)}
          </div>
          {buildingProductStore.activeStep !== index ? (
            <IconEdit
              width={22}
              className="text-gray-500 cursor-pointer hover:text-gray-800"
              stroke={2}
              onClick={() => buildingProductStore.setActiveStep(index)}
            />
          ) : null}
        </div>
        {step.title !== buildingProductStore.steps[2].title ? (
          <OverviewTile step={step} />
        ) : (
          epdData()
        )}
      </div>
    );
  };

  const epdData = () => {
    const ref_unit = buildingProductStore.data.reference_unit;
    return (
      <div>
        <label className="text-sm">{t("mapping.referenceUnit")}</label>
        <div className="text-sm font-normal pt-1 text-gray-500 pb-4">
          {isEng ? ref_unit?.name : ref_unit?.name_de}
        </div>
        {epdGroups?.length
          ? epdGroups.map((item, index) => (
              <DatasetView key={index} epdGroup={item as EPDGroup} />
            ))
          : null}
      </div>
    );
  };

  return (
    <div className="bg-white border-l border-gray-300 p-6 w-full min-h-[87vh] max-h-[calc(100vh-57px)] overflow-y-auto">
      <div className="text-lg font-semibold">{t("productEditor.overview")}</div>
      {hasOverview() ? (
        buildingProductStore.steps.map((step, index) =>
          buildingProductStore.stepHasOverview(index)
            ? stepOverview(step, index)
            : null
        )
      ) : (
        <EmptyStateOverview>
          {t("productEditor.noOverviewMsg").split("\n")[0]}
          <br />
          {t("productEditor.noOverviewMsg").split("\n")[1]}
        </EmptyStateOverview>
      )}
    </div>
  );
});
