import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import { useTranslation } from "react-i18next";
import ImageResizer from "./ImageResizer";
import { ArrowUpIcon } from "@heroicons/react/outline";
import { db } from "api-client-local/db";
import { AuditOUT, BlueprintOUT, InstanceOUT } from "api-client";

interface GenericListItemProps {
  itemUrl?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  itemImageUrl?: string;
  itemEditUrl?: string;
  className?: string;
  itemType?: "blueprint" | "instance";
  syncSuccess?: boolean;
  item: BlueprintOUT | InstanceOUT | AuditOUT;
  missingFieldsCount?: number;
  isComponent?: boolean;
}

export default function GenericListItem(
  props: React.PropsWithChildren<GenericListItemProps>
) {
  const { t } = useTranslation();

  const [idToSync, setIdToSync] = useState<string | undefined>(undefined);

  const canShowSyncIcon = async () => {
    const bpIdToSync = (await db.blueprintIds.toArray()).find(
      (bp) => bp.blueprintId === props.item.id
    );
    setIdToSync(bpIdToSync?.blueprintId);
  };

  useEffect(() => {
    canShowSyncIcon();
  }, [props.syncSuccess]);

  const itemTitle = "title" in props.item ? props.item.title : props.item.name;

  const WrappedContent = React.useCallback(() => {
    return (
      <>
        <div className="relative rounded-md basis-9 flex items-center w-8 h-8 mr-2">
          {idToSync && !props.syncSuccess && (
            <div className="w-5 h-5 bg-indigo-600 rounded-full absolute -top-1 -left-1 ">
              <ArrowUpIcon color="white" className="p-1 font-extrabold" />
            </div>
          )}
          {!props.itemImageUrl || props.itemImageUrl === "" ? (
            <div className="bg-gray-200 h-full w-8 rounded-md"></div>
          ) : (
            <ImageResizer src={props.itemImageUrl ?? ""} />
          )}
        </div>

        <span className="w-fit text-sm">
          {itemTitle}
          {props.missingFieldsCount && props.missingFieldsCount > 0 ? (
            <div className="group w-22 relative flex">
              <div className="flex items-center justify-center w-4 h-4 text-white mt-1 bg-red-500 rounded-3xl text-[10px]">
                {props.missingFieldsCount}
              </div>
              <span className="absolute -top-10 scale-0 transition-all rounded bg-red-500 p-2 text-[10px] text-white group-hover:scale-100">
                {props.missingFieldsCount} {t("commons.missingFields")}
              </span>
            </div>
          ) : null}
        </span>
      </>
    );
  }, [
    props.itemImageUrl,
    itemTitle,
    props.missingFieldsCount,
    idToSync,
    props.syncSuccess,
  ]);

  return (
    <div
      className={`py-2 flex justify-between border-b items-center ${
        props.className ?? ""
      }`}
    >
      {props.itemUrl && (
        <Link to={props.itemUrl} className="flex items-center flex-1">
          <WrappedContent />
        </Link>
      )}
      {props.onClick && (
        <div onClick={props.onClick} className="flex items-center flex-1">
          <WrappedContent />
        </div>
      )}
      <div className="flex items-center gap-3">
        {props.children}
        {!props.children && !props.isComponent && (
          <EditDeleteItemMenu
            id={props.itemEditUrl ? props.itemEditUrl : props.itemUrl ?? ""}
            onDelete={() => console.log(`(${props.item.id}) needs deletion.`)}
          />
        )}
      </div>
    </div>
  );
}
