import { observer } from "mobx-react-lite";
import React from "react";
import { useParams } from "react-router";
import { userStore } from "store/UserStore";
import Retool from ".";

const AuditMaterialSales = () => {
  const reportID = "97c93442-379b-11ef-9ab7-7b74b7a3b83b";
  const { id: BuildingId } = useParams();
  const { userProfile } = userStore;

  const embeddedData = {
    building_id: BuildingId,
    email: userProfile?.email,
    user_id: userProfile?.username,
    user_name: `${userProfile?.first_name} ${userProfile?.last_name}`,
  };

  return <Retool url={reportID} embeddedData={embeddedData} />;
};

export default observer(AuditMaterialSales);
