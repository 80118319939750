import React from "react";
import ItemComponentList from "features/Audit/ItemComponentList";
import AuditItemForm from "features/Audit/AuditItemForm";
import { useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  currentAuditAtom,
  manufacturersAtom,
  selectedManufacturerAtom,
} from "store/atoms/audits";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import handleBlueprintEdit, {
  EditBlueprintForm,
} from "./handlers/handleBlueprintEdit";
import { HandleBlueprintItemFormSubmitArgs } from "./handlers/handleBlueprintAdd";

export default function EditBlueprint() {
  const { id, audit_id, blueprint_id } = useParams();
  const { t } = useTranslation();
  const setAudit = useSetRecoilState(currentAuditAtom);
  useDisableScrollOnNumberInputs();
  const [selectedManufacturer, setSelectedManufacturer] = useRecoilState(
    selectedManufacturerAtom
  );

  const manufacturers = useRecoilValue(manufacturersAtom);
  const handleSubmit = React.useCallback(
    async (
      form: EditBlueprintForm,
      args: HandleBlueprintItemFormSubmitArgs
    ) => {
      const updatedAudit = await handleBlueprintEdit(
        form,
        args,
        (message) => args.setFormLoading(message !== "" ? t(message) : ""),
        manufacturers?.find((m) => m.name === form.select_manufacturer)
      );
      if (updatedAudit) {
        setAudit(updatedAudit);
        setSelectedManufacturer(null);
      }
    },
    [selectedManufacturer, manufacturers, blueprint_id]
  );

  return (
    <AuditItemForm<EditBlueprintForm>
      componentsMenu={<ItemComponentList />}
      redirect={`/buildings/${id}/audits/${audit_id}/blueprints/${blueprint_id}/instances`}
      title={t("audits.editBlueprint")}
      submitText={t("audits.saveBlueprint")}
      images={[]}
      handleSubmit={handleSubmit}
    />
  );
}
