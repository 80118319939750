import React from "react";
import {
  BlueprintOUT,
  InstanceOUT,
  ProductGroupOUT,
  SlimAuditOut,
  UserProfileOUT,
} from "api-client";
import Button from "components/Button";
import Form from "components/Form";
import ImageUploaderLocal from "components/ImageUploaderLocal";
import TextArea from "components/TextArea";
import TextInput from "components/TextInput";
import { useTranslation } from "react-i18next";
import AuditItemFieldsOnline from "./AuditItemFieldsOnline";
import { SetterOrUpdater } from "recoil";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import MultiSelect from "components/MultiSelect";
import TextEntrySelect from "components/TextEntrySelect";
import { LocalImage } from "../../api-client-local/db_interfaces";
import { DB_VARS, getDBVariable, setDBVariable } from "api-client-local";
import SelectManufacturer from "features/Audit/SelectManufacturer";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { observer } from "mobx-react-lite";
import AuditItemInputByIdOnline from "./AuditItemInputByIdOnline";
import FormLoadingContainer from "features/Audit/FormLoadingContainer";
import { useNavigate } from "react-router";
import { HandleBlueprintItemFormSubmitArgs } from "./models";
import { userStore } from "store/UserStore";

interface HandleAuditSubmitArgs {
  images: (File | LocalImage)[];
  selectedProductGroup: ProductGroupOUT;
  audit: SlimAuditOut;
  setFormLoading:
    | React.Dispatch<React.SetStateAction<string>>
    | SetterOrUpdater<string>;
  userProfile: UserProfileOUT;
  authHeader: AxiosRequestConfig<AxiosRequestHeaders>;
}

export interface HandleInstanceItemFormSubmitArgs
  extends HandleAuditSubmitArgs {
  selectedBlueprint: BlueprintOUT;
  instance_id?: string;
}

interface AuditItemFormProps<T> {
  title: string;
  submitText: string;
  isInstance?: boolean;
  images?: (File | LocalImage)[];
  handleSubmit: (
    form: T,
    args: HandleBlueprintItemFormSubmitArgs
  ) => Promise<void> | void;
  redirect?: string;
  handleSuccess?: (response: void) => Promise<void> | void;
  handleError?: (err: unknown) => void;
}

function AuditItemFormOnline<FormKeyVal>(
  props: React.PropsWithChildren<AuditItemFormProps<FormKeyVal>>
) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { authHeader, userProfile } = userStore;
  const [images, setImages] = React.useState<(File | LocalImage)[]>(
    props.images ?? []
  );
  const [formLoading, setFormLoading] = React.useState<string>("");

  const {
    manufacturers,
    selectedManufacturer,
    generatedBlueprintName,
    dimensionsValue,
    isComponent,
    selectedProductGroup,
    selectedBlueprint,
    selectedInstance,
    floorSet,
    roomSet,
    currentAudit: audit,
  } = caOnlineStore;

  const [selectedBP, setSelectedBP] = React.useState<
    BlueprintOUT | undefined
  >();
  const [selectedINS, setSelectedINS] = React.useState<
    InstanceOUT | undefined
  >();

  React.useEffect(() => {
    setSelectedBP(selectedBlueprint);
    setSelectedINS(selectedInstance);
  }, []);

  const handleRoomAdd = React.useCallback(async (value: string) => {
    const roomNames = getDBVariable(DB_VARS.ROOM_NAMES);
    let result: string[];
    if (!roomNames) {
      result = [value];
    } else {
      result = Array.from(new Set([...roomNames, value]));
    }
    setDBVariable(DB_VARS.ROOM_NAMES, result.toString());
  }, []);

  const handleSubmit = React.useCallback(
    async function (form: FormKeyVal) {
      let timer: ReturnType<typeof setTimeout>;
      try {
        setFormLoading(t("audits.sendingFormData"));
        if (!authHeader || !userProfile || !audit)
          return console.error(
            "authHeader",
            authHeader,
            "userProfile",
            userProfile,
            "audit",
            audit
          );
        if (!selectedProductGroup || !selectedBP)
          return console.error(
            "selectedBlueprint or selectedProductGroup undefined"
          );
        console.log("AuditItemForm.handleSubmit", form);
        const response = await props.handleSubmit(form, {
          images,
          audit,
          setFormLoading,
          authHeader,
          userProfile: {
            email: userProfile.email,
            first_name: userProfile.first_name,
            last_name: userProfile.last_name,
            username: userProfile.username,
          },
          selectedProductGroup,
          selectedBlueprint: selectedBP,
        });
        props.handleSuccess?.(response);
        props.redirect && navigate(props.redirect);
      } catch (err) {
        console.error(err);
        setFormLoading(t("audits.errorOccurred"));
        props.handleError?.(err);
      } finally {
        timer = setTimeout(() => {
          setFormLoading("");
          clearTimeout(timer);
        }, 2000);
      }
    },
    [authHeader, selectedBP, images]
  );

  React.useEffect(() => {
    if (props.images) {
      setImages(props.images);
    }
    return () => setImages([]);
  }, [props.images]);

  const formKey = () => {
    if (selectedINS) return selectedINS.id;
    if (selectedBP) return selectedBP.id;
    return "0";
  };

  function extractDimensions() {
    // Regular expression to match the dimensions pattern (e.g., "11x11x22")
    const regex = /(\d+x\d+x\d+)/;

    // Use the regex to extract the dimensions from the name
    const match = selectedBP?.name.match(regex);

    if (match) {
      return match[0];
    } else {
      return isComponent ? "" : "0x0x0"; // No dimensions found in the string
    }
  }

  function extractNameWithoutDimensions() {
    const regex = /\d+x\d+x\d+/;
    return selectedBP?.name.replace(regex, "").trim();
  }

  React.useEffect(() => {
    if (!dimensionsValue || dimensionsValue === "0x0x0") {
      caOnlineStore.setDimensionsValue(extractDimensions());
    }

    let selectedName = extractNameWithoutDimensions();
    if (selectedBP?.manufacturer?.name) {
      selectedName = selectedName
        ?.replaceAll(selectedBP?.manufacturer?.name, "")
        .trim();
    }

    const generatedName = `${selectedName} ${
      selectedManufacturer?.name ?? selectedBP?.manufacturer?.name ?? ""
    } ${dimensionsValue ?? ""}`
      .trim()
      .replace(/\s+/g, " ");

    caOnlineStore.setGeneratedBlueprintName(generatedName);
  }, [
    selectedManufacturer,
    selectedBP?.name,
    dimensionsValue,
    selectedBP?.manufacturer?.name,
  ]);

  function getDefaultProductName() {
    if (props.isInstance) {
      return extractNameWithoutDimensions();
    }

    if (isComponent) {
      return selectedBP?.name;
    }

    return generatedBlueprintName ?? selectedBP?.name;
  }

  return (
    <>
      <h3 className="pb-2">{props.title}</h3>
      <FormLoadingContainer formLoading={formLoading} />
      <Form<FormKeyVal>
        key={formKey()}
        className="pb-16"
        handleSubmit={handleSubmit}
      >
        {selectedINS && (
          <TextInput
            className="sm:py-2"
            name="name"
            defaultValue={selectedINS.name}
            labelName={`${t("audits.item")} Name`}
          />
        )}
        {props.isInstance && (
          <TextInput
            unit={selectedProductGroup?.unit}
            labelName={t("audits.amount")}
            type="number"
            name="instance_amount"
            defaultValue={selectedINS?.instance_amount}
          />
        )}
        {!selectedINS && selectedBP && (
          <TextInput
            className="sm:py-2"
            name="name"
            defaultValue={getDefaultProductName()}
            labelName={`${t("audits.blueprint")} Name`}
          />
        )}
        {!selectedINS && selectedBP && isComponent && (
          <TextInput
            containerClass="w-[49%]"
            className="sm:py-2"
            unit={selectedProductGroup?.unit}
            labelName={t("audits.amount")}
            type="number"
            defaultValue={selectedBP.as_component_amount}
            name="as_component_amount"
          />
        )}
        {props.children}
        {!props.isInstance && (
          <ImageUploaderLocal
            id="form-images"
            images={images}
            handleChange={(files) => setImages(files)}
            name="images"
            multiple
            labelName={t("audits.addImages")}
          />
        )}
        {props.isInstance ? (
          <>
            <AuditItemInputByIdOnline name={"Condition"} />
            {floorSet && floorSet.length > 0 ? (
              <MultiSelect
                containerClassName="flex-[100%]"
                defaultValues={selectedINS?.floor_ids ?? []}
                className="py-2 mb-2"
                required={floorSet.length > 0}
                labelName={t("audits.floors")}
                name="floor_ids"
                isFloorSet
                options={floorSet ?? []}
              />
            ) : null}
            <TextEntrySelect
              onNewEntry={handleRoomAdd}
              labelName={t("audits.room")}
              name="room_name"
              required
              defaultValue={selectedINS?.room_name ?? ""}
              options={roomSet ?? []}
            />
          </>
        ) : (
          <>
            <SelectManufacturer
              selected={selectedBP?.manufacturer}
              onReset={() => caOnlineStore.setSelectedManufacturer(undefined)}
              onChange={(value) => {
                const found = manufacturers?.find((m) => m.name === value);
                console.log("SelectManufacturer.found", found);
                if (found) {
                  caOnlineStore.setSelectedManufacturer(found);
                }
              }}
            />
            <AuditItemFieldsOnline filteredFields={["Condition"]} />
          </>
        )}
        <TextArea
          defaultValue={
            (props.isInstance
              ? selectedINS?.extra_info
              : selectedBP?.extra_info) ?? ""
          }
          name="extra_info"
          labelName={t("audits.moreSpecs")}
        />
        <Button disabledClasses="w-full" htmlType="submit">
          {props.submitText}
        </Button>
      </Form>
    </>
  );
}

export default observer(AuditItemFormOnline);
