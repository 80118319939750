import { IconExternalLink } from "@tabler/icons-react";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { EPDSearchOUT } from "api-client";
import Badge from "components/Badge";
import Truncate from "components/Truncate";
import { getCurrentReferenceValue } from "features/MappingTools/utils";
import React from "react";
import { useTranslation } from "react-i18next";

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  function referenceUnitCell(info: CellContext<EPDSearchOUT, unknown>) {
    const currentValue = getCurrentReferenceValue(
      info.row.original.reference_unit
    );
    return <div>{isEng ? currentValue?.name : currentValue?.name_de}</div>;
  }

  const columns = React.useMemo<ColumnDef<EPDSearchOUT>[]>(
    () => [
      {
        accessorFn: (row) => row.name_de,
        id: "name_de",
        cell: (info) => (
          <div className="cursor-pointer">
            <Truncate className="max-w-[150px]">
              {info.row.original.name_de ??
                (info.row.original?.name_en as string)}
            </Truncate>
          </div>
        ),
        header: () => (
          <div className="min-w-[128px]">{t("productEditor.name")}</div>
        ),
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        accessorFn: (row) => row.dataset_source__name,
        id: "dataset_source__name",
        cell: (info) => {
          return (
            <Badge
              type={info.row.original.dataset_source__name ? "auto" : "info"}
              className="!max-w-fit"
            >
              <div className="flex items-center">
                {info.row.original.dataset_source__name}
                {info.row.original.url ? (
                  <a
                    href={info.row.original.url}
                    target="_blank"
                    rel={"noreferrer"}
                    className="md:flex items-center cursor-pointer"
                    tabIndex={-1}
                  >
                    <IconExternalLink width={18} className="ml-2" stroke={3} />
                  </a>
                ) : null}
              </div>
            </Badge>
          );
        },
        header: () => <div>{t("productEditor.dataSource")}</div>,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        accessorFn: (row) => row.reference_unit,
        id: "reference_unit",
        cell: (info) => referenceUnitCell(info),
        header: () => <div>{t("productEditor.refUnit")}</div>,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        accessorFn: (row) => row.conformity__name,
        id: "conformity__name",
        cell: (info) => <div>{info.row.original.conformity__name}</div>,
        header: () => <div>{t("productEditor.conformity")}</div>,
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        accessorFn: (row) => row.data_type,
        id: "data_type",
        header: () => <div>{t("productEditor.EPDType")}</div>,
        cell: (info) => <div>{info.row.original.data_type}</div>,
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    []
  );
  return columns;
};

export default useColumns;
