import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { useInventory } from "./useInventory";
import { inventoryStore } from "store/InventoryStore";
import { AvailableColumnsOUT } from "api-client";
import Tooltip from "components/Tooltip";
import { getModuleStringB4, getModuleStringTotal } from "utils";
import { buildingStore } from "store/Building/BuildingStore";

const InventoryTableColumns = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const headerCell = (name: string) => (
    <div className="flex text-gray-500 items-center whitespace-nowrap">
      {name}
    </div>
  );

  const getGWPTooltipTotal = t("buildingInventory.modulesAlert", {
    moduleString: t(
      `commons.${getModuleStringTotal(
        buildingStore.getBuildingCertificationName()
      )}`
    ),
  });

  const getGWPTooltipB4 = t("buildingInventory.modulesAlert", {
    moduleString: t(
      `commons.${getModuleStringB4(
        buildingStore.getBuildingCertificationName()
      )}`
    ),
  });

  const columnCell = (col: AvailableColumnsOUT) => ({
    accessorFn: (row: { [key: string]: string }) => row[col.property_name],
    id: col.property_name,
    header: () => (
      <div className="flex">
        <span className="truncate">
          {isEng ? col.friendly_name_en : col.friendly_name_de}
        </span>
        {col.tooltip_active ? (
          <Tooltip
            content={
              col.property_name === "gwp_b4"
                ? getGWPTooltipB4
                : getGWPTooltipTotal
            }
          ></Tooltip>
        ) : null}
      </div>
    ),
    cell: (info: { row: { original: { [key: string]: string | number } } }) => (
      <>{String(info.row.original[col.property_name] ?? "-")}</>
    ),
    customFilter:
      col.filter_function === "Text"
        ? columnFilter(col.property_name)
        : minMaxFilter(col.property_name),
  });

  const groupCell = (id: string) => {
    const currentCategory = inventoryStore.categories.find(
      (cat) => cat.key === id
    );

    return {
      id: id,
      header: () =>
        headerCell(
          isEng ? currentCategory?.name ?? "" : currentCategory?.name_de ?? ""
        ),
      columns: currentCategory?.columns.map((col) => columnCell(col)),
    };
  };

  const { columnFilter, minMaxFilter } = useInventory();

  return useMemo<ColumnDef<{ [key: string]: string }>[]>(
    () => [
      ...inventoryStore.categories.map((category) => groupCell(category.key)),
    ],
    []
  );
};

export default InventoryTableColumns;
