import React from "react";

interface Props {
  width?: string;
  height?: string;
  alt?: string;
  className?: string;
  src: string;
  isLink?: boolean;
}

const ImageResizer = ({
  width = "100",
  height = "100",
  alt = "logo",
  className,
  src: imageUrl,
  isLink = false,
}: Props) => {
  const resizedImage =
    imageUrl && !imageUrl.includes("blob")
      ? `${imageUrl}?width=${width}&height=${height}`
      : imageUrl;

  const imageTag = () => {
    return (
      <img
        src={resizedImage}
        alt={alt}
        className={`w-8 h-8 rounded-md ${className}`}
      />
    );
  };

  return (
    <>
      {isLink ? (
        <a href={resizedImage} target="_blank" rel="noopener noreferrer">
          {imageTag()}
        </a>
      ) : (
        imageTag()
      )}
    </>
  );
};

export default ImageResizer;
