import React from "react";
import { ReactNode } from "react";
import clsx from "clsx";
import { ReactI18NextChild } from "react-i18next";

interface IProps {
  type?:
    | "primary"
    | "secondary"
    | "gray"
    | "danger"
    | "white"
    | "link"
    | "submit";
  className?: string;
  disable?: boolean;
  size?: "default" | "small";
  width?: "fit-container" | "fit-content";
  onClick?: (e: React.MouseEvent) => void;
  onSubmit?: (e: React.FormEvent) => void;
  children?: ReactNode | ReactI18NextChild;
  htmlType?: "button" | "submit" | "reset";
  loading?: boolean;
  style?: object;
  disabledClasses?: string;
  leadingIcon?: ReactNode;
  trailingIcon?: ReactNode;
  testID?: string;
}

const Button = React.forwardRef(
  (
    {
      type = "primary",
      className,
      disable,
      size = "default",
      width = "fit-container",
      onClick,
      onSubmit,
      children,
      htmlType = "button",
      loading = false,
      style,
      disabledClasses,
      leadingIcon,
      trailingIcon,
      testID,
    }: IProps,
    ref: React.Ref<HTMLButtonElement>
  ) => {
    return (
      <button
        className={clsx(
          "font-medium flex px-4 text-sm justify-center items-center",
          "tracking-tight rounded-md disabled:opacity-40 transition",
          {
            "bg-indigo-600 text-white enabled:hover:bg-indigo-700":
              type === "primary",
            "bg-indigo-100 text-indigo-700 enabled:hover:bg-indigo-200 shadow-sm":
              type === "secondary",
            "text-white": type === "white",
            "text-gray-800 enabled:hover:bg-gray-200 focus:outline-none border border-gray-300":
              type === "gray",
            "text-gray-700 enabled:hover:text-gray-900 bg-transparent enabled:hover:bg-transparent":
              type === "link",
            "h-default": size === "default",
            "h-small": size === "small",
            "w-full": width === "fit-container",
            "w-auto min-w-fit": width === "fit-content",
            "!w-10 !p-0 h-10": size === "default" && !children,
            "w-8 px-0": size === "small" && !children,
            "!rounded-full": !children,
            "text-red-700 enabled:hover:!bg-red-200 border-red-300 border":
              type === "danger",
            "cursor-not-allowed": disable,
          },
          className
        )
          .split(" ")
          .filter((className: string) =>
            disabledClasses
              ? !disabledClasses.split(" ").includes(className)
              : className
          )
          .join(" ")}
        ref={ref}
        disabled={disable}
        onClick={onClick}
        onSubmit={onSubmit}
        type={htmlType}
        style={{ ...style }}
        test-id={testID}
      >
        {leadingIcon && (
          <span
            className={clsx({
              "-ml-1 mr-2.5 w-5": size === "default",
              "-ml-0.5 mr-2 w-4": size === "small",
              "!mx-auto w-fit": !children,
            })}
          >
            {leadingIcon}
          </span>
        )}
        {children}
        {trailingIcon && (
          <span
            className={clsx({
              "ml-3 -mr-1": size === "default",
              "ml-2 -mr-0.5": size === "small",
              "mr-auto ml-auto": !children,
            })}
          >
            {trailingIcon}
          </span>
        )}
        {loading && (
          <svg
            className={clsx("animate-spin -mr-1 ml-3 h-4 w-4", {
              "text-white": type === "primary",
              "text-red-700": type === "danger",
              "text-indigo-700": type === "secondary",
              "text-gray-800": type === "gray",
              "text-gray-700": type === "link",
            })}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
