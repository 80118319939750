import { AuditOUT, InstancePropertyOUT } from "api-client";
import { setLocalAudit } from "api-client-local/audits";
import { checkAndAddBlueprintId, omit } from "utils";
import { handleFieldValueTypes, makeInstancePropertyModel } from "../utils";
import { HandleAuditEditArgs } from "./handleBlueprintEdit";
import { db } from "../../../api-client-local/db";
import { parseFloorIds } from "./handleInstanceAdd";

export type EditInstanceForm = {
  [field_id: string]: string | number | string[];
  instance_amount: number;
  extra_info: string;
  name: string;
  floor_ids: string[] | string;
  room_name: string;
};

interface handleInstanceEditArgs extends HandleAuditEditArgs {
  instance_id: string;
}

export default async function handleInstanceEdit(
  form: EditInstanceForm,
  args: handleInstanceEditArgs,
  loadingCallback: (m: string) => void
) {
  const properties = omit<EditInstanceForm>("images", form) as EditInstanceForm;

  const auditCopy: AuditOUT = JSON.parse(JSON.stringify(args.audit));
  const blueprint = auditCopy.blueprint_set.find(
    (b) => b.id === args.selectedBlueprint.id
  );

  if (!blueprint) {
    console.error("Blueprint not found.");
    return undefined;
  }

  const foundInstanceIndex = blueprint.instance_set.findIndex(
    (item) => item.id === args.instance_id
  );
  if (foundInstanceIndex === -1) {
    console.error(`"foundInstanceIndex" is -1`);
    return undefined;
  }

  let generatedFields = omit("name", properties) as EditInstanceForm;
  generatedFields = omit(
    "select_manufacturer",
    generatedFields
  ) as EditInstanceForm;
  generatedFields = omit("extra_info", generatedFields) as EditInstanceForm;
  generatedFields = omit("floor_ids", generatedFields) as EditInstanceForm;
  generatedFields = omit("room_name", generatedFields) as EditInstanceForm;
  generatedFields = omit(
    "instance_amount",
    generatedFields
  ) as EditInstanceForm;

  const instancePropertySet =
    blueprint.instance_set[foundInstanceIndex].instanceproperty_set;

  const nextProperties: InstancePropertyOUT[] = [];
  // If there are some generated fields added;
  if (Object.keys(generatedFields).length > 0)
    for (const fieldId in generatedFields) {
      const fieldValue = generatedFields[fieldId];
      const previouslyFound = instancePropertySet.find(
        (prop) => prop.field_id === fieldId
      );
      if (previouslyFound) {
        const updatedField = handleFieldValueTypes(fieldValue, previouslyFound);
        nextProperties.push(updatedField);
      } else {
        const createdField = makeInstancePropertyModel(
          fieldId,
          args.instance_id,
          fieldValue
        );
        nextProperties.push(createdField);
      }
    }

  // if (updatedProperties.some((prop) => prop === null)) {
  //   console.error(`Some IDs not found in instanceproperty_set.`);
  //   throw new Error(`Some IDs not found in instanceproperty_set.`);
  // }

  blueprint.instance_set[foundInstanceIndex].extra_info = form.extra_info ?? "";
  blueprint.instance_set[foundInstanceIndex].name = form.name;
  blueprint.instance_set[foundInstanceIndex].room_name = form.room_name;
  blueprint.instance_set[foundInstanceIndex].instance_amount = Number(
    form.instance_amount
  );

  blueprint.instance_set[foundInstanceIndex].floor_ids = parseFloorIds(
    form.floor_ids
  );
  blueprint.instance_set[foundInstanceIndex].instanceproperty_set =
    nextProperties;

  const newBlueprintSet = args.audit.blueprint_set.map((b) => {
    if (b.id === blueprint.id) {
      return { ...b, instance_set: blueprint.instance_set };
    } else return b;
  });

  loadingCallback("audits.uploadingItemData");
  console.log("newBlueprintSet", newBlueprintSet[0].instance_set);

  const auditRequest = {
    ...args.audit,
    id: blueprint.audit_id,
    blueprint_set: newBlueprintSet,
  };

  // const response = await auditsApi.caApiV1RoutersAuditUpdateAudit(
  //   auditRequest,
  //   args.authHeader
  // );

  const localAudit = await setLocalAudit(auditRequest);

  // TODOIMAGES
  if (args.images && args.images.length > 0) {
    for (const image of args.images) {
      db.images.add({
        id: window.crypto.randomUUID(),
        sourceType: "instance",
        sourceId: args.instance_id,
        file: image as File,
        deleted: false,
        uploaded: false,
        order: 0,
        albumName: "default",
      });
    }
  }

  await checkAndAddBlueprintId(args.selectedBlueprint.id);

  return localAudit;
}
