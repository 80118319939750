import React from "react";
import { FieldOptionOUT, FieldOUT } from "api-client";
import MultiSelect, { MultiSelectOption } from "components/MultiSelect";
import SimpleSelect, { SelectOption } from "components/SimpleSelect";
import TextInput from "components/TextInput";
import TextSearchInput from "components/TextSearchInput";
import { useTranslation } from "react-i18next";
import GeneratedCheckbox from "./GeneratedCheckbox";

export default function GeneratedInput(props: {
  data: FieldOUT;
  index: number;
  name?: string;
  required?: boolean;
  enforceRequired?: boolean;
  defaultValue?: string | number | string[] | null;
  handleChange?: (value: string) => void | Promise<void>;
  handleReset?: () => void | Promise<void>;
}) {
  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  const labelName =
    i18n.language === "en" ? props.data.name : props.data.name_de;

  // Default selected for select-like components
  let selected =
    props.defaultValue &&
    props.data.field_options.find(
      (f) => f[isEnglish ? "name" : "name_de"] === props.defaultValue
    );

  // Sorting for select-like components
  const sorted: FieldOptionOUT[] = [...(props.data.field_options || [])].sort(
    (a, b) =>
      (a[isEnglish ? "name" : "name_de"] ?? a.name).localeCompare(
        b[isEnglish ? "name" : "name_de"] ?? b.name
      )
  );

  // Checking for select-like components
  if (props.defaultValue && Array.isArray(props.defaultValue)) {
    const id = props.defaultValue[0];
    selected = props.data.field_options.find((f) => f.id === id);
  }

  switch (props.data.field_type) {
    case "string":
    case "number":
    case "text":
      return (
        <TextInput
          handleChange={props.handleChange}
          placeholder={props.data.field_type === "number" ? "1" : undefined}
          containerClass={
            props.data.field_type === "number" ? "flex-[42%]" : "flex-[100%]"
          }
          defaultValue={
            Array.isArray(props.defaultValue)
              ? props.defaultValue.join()
              : props.defaultValue === null
              ? undefined
              : props.defaultValue
          }
          // TODO: Re-think the requiredness of the fields
          required={props.required}
          enforceRequired={props.enforceRequired}
          name={props.name ?? props.data.id}
          style={{ order: props.data.order_of_appearance }}
          unit={
            props.data.field_options.length > 0
              ? props.data.field_options[0].value
              : undefined
          }
          labelName={labelName as string}
          type={props.data.field_type as "string" | "number" | undefined}
        />
      );
    case "single_select":
      if (props.data.field_options.length > 10) {
        return (
          <TextSearchInput<FieldOptionOUT>
            handleChange={props.handleChange}
            onReset={props.handleReset}
            containerClassName="flex-[100%]"
            style={{ order: props.data.order_of_appearance }}
            displayKey={isEnglish ? "name" : "name_de"}
            selectedId={selected ? selected.id : undefined}
            required={props.required}
            enforceRequired={props.enforceRequired}
            items={sorted}
            name={props.name ?? props.data.id}
            labelName={labelName as string}
          />
        );
      } else {
        return (
          <SimpleSelect
            style={{ order: props.data.order_of_appearance }}
            containerClassName="flex-[100%]"
            current={selected ? selected.id : undefined}
            required={props.required}
            enforceRequired={props.enforceRequired}
            labelName={labelName as string}
            name={props.name ?? props.data.id}
            options={[{ name: "" }, ...(sorted as SelectOption[])]}
          />
        );
      }
    case "multi_select":
      return (
        <MultiSelect
          containerClassName={`flex-[100%]`}
          style={{ order: props.data.order_of_appearance }}
          defaultValues={
            props.defaultValue &&
            Array.isArray(props.defaultValue) &&
            props.defaultValue.every((e) => typeof e === "string")
              ? (props.defaultValue as string[])
              : undefined
          }
          className={`py-2`}
          required={props.required}
          enforceRequired={props.enforceRequired}
          labelName={labelName as string}
          name={props.name ?? props.data.id}
          options={props.data.field_options as MultiSelectOption[]}
        />
      );
    case "boolean":
      return (
        <GeneratedCheckbox
          style={{ order: props.data.order_of_appearance }}
          containerClass="w-full"
          defaultChecked={props.defaultValue === "on"}
          required={props.required}
          labelName={labelName as string}
          name={props.name ?? props.data.id}
        />
      );
    default:
      return <div>Unknown field</div>;
  }
}
