import clsx from "clsx";
import { StepIconProps } from "@material-ui/core";

const CustomCircle = (props: StepIconProps) => {
  return (
    <div
      className={clsx(
        `
          ${
            props.active
              ? "bg-white border border-indigo-600 text-indigo-600"
              : props.completed
              ? "bg-indigo-600 border border-indigo-600 text-white"
              : "bg-gray-100 border border-gray-100 text-gray-500"
          }
          ${
            props.completed &&
            "bg-indigo-600 border border-indigo-600 text-white"
          }
      `,
        "flex rounded-full w-8 h-8 justify-center items-center self-center"
      )}
    >
      {props.icon}
    </div>
  );
};

export default CustomCircle;
