import React from "react";
import { useTranslation } from "react-i18next";
import IFCFileIcon from "assets/images/icons/ifc-file-icon.svg";
import CSVFileIcon from "assets/images/icons/csv-file-icon.svg";
import { UploadType } from ".";
import clsx from "clsx";
import XLSXFileIcon from "assets/images/icons/xlsx-file-icon.svg";
import Button from "components/Button";
import { IconArrowRight } from "@tabler/icons-react";
import RevitPluginDownloadAlert from "features/Revit/RevitPluginDownloadAlert";
import useFeatureFlag from "hooks/useFeatureFlag";

export default function SelectImportType(props: {
  setUploadType: React.Dispatch<React.SetStateAction<UploadType | null>>;
}) {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();

  const tiles = [
    {
      title: t("buildingMaterialResources.uploadBimModel"),
      icon: IFCFileIcon,
      description: t("buildingMaterialResources.uploadBimModelDesc"),
      onClick: () => props.setUploadType("IFC"),
      testId: "upload_bim",
    },
    {
      title: t("buildingMaterialResources.uploadTableData"),
      icon: CSVFileIcon,
      description: t("buildingMaterialResources.uploadTableDataDesc"),
      onClick: () => props.setUploadType("CSV"),
      testId: "upload_csv",
    },
  ];

  return (
    <div>
      <div className="md:grid md:grid-cols-2 gap-5">
        {tiles.map((item) => (
          <div
            className={clsx(
              "border border-gray-300 rounded-md p-6 hover:border-indigo-500 group",
              "hover:outline outline-indigo-500 outline-1 cursor-pointer"
            )}
            onClick={item.onClick}
            test-id={item.testId}
            key={item.testId}
          >
            <div className="text-base font-semibold">{item.title}</div>
            <div className="flex justify-center my-4 mx-auto">
              <img src={item.icon} />
              {item.icon.includes("csv-file-icon") && (
                <img src={XLSXFileIcon} />
              )}
            </div>
            <div className="text-sm font-normal min-h-[60px]">
              {item.description}
            </div>
            <div className="flex justify-end mt-4">
              <Button
                leadingIcon={<IconArrowRight />}
                type="link"
                className="text-gray-500 rounded-full p-2 group-hover:bg-indigo-100"
              ></Button>
            </div>
          </div>
        ))}
      </div>
      {isFeatureVisible("revit") ? <RevitPluginDownloadAlert /> : null}
    </div>
  );
}
