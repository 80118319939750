import {
  // Icon3dCubeSphere,
  // IconAlertCircle,
  IconCircle,
  IconCircleCheck,
  // IconCircleX,
  // IconFilter,
  // IconPlayerPlay,
  // IconSearch,
  // IconSparkles,
} from "@tabler/icons-react";
import { Row } from "@tanstack/react-table";
import { PropertyBrowserTreeNode } from "api-client";
import { useTranslation } from "react-i18next";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { ColumnVisibilityState } from "store/IfcMapping/DME/types";
import { replaceVisibilitySettings, saveColumnState } from "./columnVisibility";
import { useParams } from "react-router";

const useMappingEditor = () => {
  const { t } = useTranslation();
  const { ifc_id } = useParams();

  const checkRow = (row?: Row<unknown>) => {
    if (!row) return;
    const selectedIndexes = [
      ...dynamicMEStore.selectedRowIndexes,
      String(row?.index),
    ].sort((a, b) => a.localeCompare(b));
    dynamicMEStore.setSelectedRows(Array.from(new Set(selectedIndexes)));
  };

  const unCheckRow = (row?: Row<unknown>) => {
    if (!row) return;
    const selectedIndexes = dynamicMEStore.selectedRowIndexes.filter(
      (item) => item != String(row.index)
    );
    dynamicMEStore.setSelectedRows(selectedIndexes);
  };

  const contextMenuOptions = [
    {
      title: t("mappingEditor.checkProductLink"),
      options: [
        {
          label: t("mappingEditor.markAsChecked"),
          onClick: checkRow,
          icon: <IconCircleCheck size={18} />,
        },
        {
          label: t("mappingEditor.markAsNotChecked"),
          onClick: unCheckRow,
          icon: <IconCircle size={18} />,
        },
      ],
    },
    // {
    //   title: t("mappingEditor.defineRelevance"),
    //   options: [
    //     {
    //       label: t("mappingEditor.markAsRelevant"),
    //       onClick: checkRow,
    //       icon: <IconAlertCircle size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.markAsNotRelevant"),
    //       onClick: checkRow,
    //       icon: <IconCircleX size={18} />,
    //     },
    //   ],
    // },
    // {
    //   title: t("mappingEditor.performProductLinking"),
    //   options: [
    //     {
    //       label: t("mappingEditor.linkRegularlyBased"),
    //       onClick: checkRow,
    //       icon: <IconPlayerPlay size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.linkAIBased"),
    //       onClick: checkRow,
    //       icon: <IconSparkles size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.linkManually"),
    //       onClick: checkRow,
    //       icon: <IconSearch size={18} />,
    //     },
    //   ],
    // },
    // {
    //   title: t("mappingEditor.miscellaneous"),
    //   options: [
    //     {
    //       label: t("mappingEditor.highlightIn3DView"),
    //       onClick: checkRow,
    //       icon: <Icon3dCubeSphere size={18} />,
    //     },
    //     {
    //       label: t("mappingEditor.preFilterInTable"),
    //       onClick: checkRow,
    //       icon: <IconFilter size={18} />,
    //     },
    //   ],
    // },
  ];

  const buildColumnsVisibilityState = (): ColumnVisibilityState => {
    /**
     * Builds the current state using the available column groups and
     * visible columns
     */
    const previousState: ColumnVisibilityState = Object.keys(
      dynamicMEStore.columnsVisibilityState
    ).reduce((r, c) => Object.assign(r, { [c]: false }), {});

    const visibleColumnKeys = new Set(
      dynamicMEStore.visibleColumnProperties.map((e) => e.column_key)
    );

    const allColumns = dynamicMEStore.dynamicColumns.map((e) => {
      return { [e.key_name]: visibleColumnKeys.has(e.key_name) };
    });

    const reducedList = allColumns.reduce(
      (r, c) => Object.assign(r, c),
      previousState
    );
    return reducedList;
  };

  const updateColumnsVisibilitySavedState = () => {
    /**
     * Updates the available columns and the visible columns in one operation
     */
    const newState = replaceVisibilitySettings();
    saveColumnsVisibilityState(newState);
  };

  const saveColumnsVisibilityState = (newState?: {
    [key: string]: boolean;
  }) => {
    /**
     * Builds the current state of column visibility from the DMEStore
     * and save its in the local storage and in the DMEStore
     */
    let savingState: { [key: string]: boolean } = newState ?? {};
    if (!newState) {
      savingState = buildColumnsVisibilityState();
    }
    dynamicMEStore.setColumnsVisibilityState(savingState);
    saveColumnState(savingState);
  };

  const reorderListOfObjects = (
    listOfObjects: PropertyBrowserTreeNode[],
    sortingOrder: string[]
  ) => {
    listOfObjects.sort((a, b) => {
      const indexA = sortingOrder.indexOf(a.column_key);
      const indexB = sortingOrder.indexOf(b.column_key);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      } else if (indexA !== -1) {
        return -1;
      } else if (indexB !== -1) {
        return 1;
      } else {
        return 0;
      }
    });
  };

  function getColumnsOrderSavedState(): string[] | null {
    return JSON.parse(localStorage.getItem("DME_columns_order") ?? "null");
  }

  function retrieveOrderSavedState() {
    let orderState = getColumnsOrderSavedState();
    if (!orderState) {
      orderState = defaultColumnOrder();
    }

    reorderListOfObjects(dynamicMEStore.visibleColumnProperties, orderState);
    saveColumnsOrder();
  }

  function defaultColumnOrder(): string[] {
    const staticOrder: string[] = [
      "physical_object.component",
      "physical_object.material",
      "physical_object.area",
      "physical_object.volume",
      "match_calculations.mass",
      "match_calculations.gwp",
    ];
    const columnVisibility = buildColumnsVisibilityState();
    return staticOrder.filter((k) => columnVisibility[k]);
  }

  function saveColumnsOrder() {
    const columnsOrder = dynamicMEStore.visibleColumnProperties.map(
      (item) => item.column_key
    );
    dynamicMEStore.setColumnsOrder(columnsOrder);
    localStorage.setItem("DME_columns_order", JSON.stringify(columnsOrder));
  }

  function saveFilterRules() {
    localStorage.setItem(
      "DME_filter",
      JSON.stringify({
        ...dynamicMEStore.selectedFilterByItems,
        rules: dynamicMEStore.selectedFilterByItems.rules.map((item) => ({
          ...item,
          property: item.property?.column_key,
        })),
      })
    );
  }

  function retreiveFiltersState() {
    const savedState = getFilterRulesSavedState();
    if (savedState) {
      const allProperties = dynamicMEStore.getAllPropertiesFlat();
      const retrievedFilters = {
        ...savedState,
        rules: savedState.rules
          .map((rule: { property: string }) => ({
            ...rule,
            property: allProperties.find(
              (rec) => rec.column_key === rule.property
            ),
          }))
          .filter(
            (rule: { property: PropertyBrowserTreeNode | undefined }) =>
              rule.property
          ),
      };
      retrievedFilters.rules.length &&
        dynamicMEStore.setSelectedFilterByItems(retrievedFilters);
    }
  }

  function getFilterRulesSavedState() {
    return JSON.parse(localStorage.getItem("DME_filter") ?? "null");
  }

  function saveSortRules() {
    localStorage.setItem(
      "DME_sort",
      JSON.stringify(
        dynamicMEStore.selectedSortedByItems.map((item) => ({
          ...item,
          property: item.property?.column_key,
        }))
      )
    );
  }

  function getSortRulesSavedState() {
    return JSON.parse(localStorage.getItem("DME_sort") ?? "null");
  }

  function retreiveSortState() {
    const savedState = getSortRulesSavedState();
    if (savedState) {
      const retrievedSorts = savedState
        .map((item: { property: string }) => ({
          ...item,
          property: dynamicMEStore.visibleColumnProperties.find(
            (rec) => rec.column_key === item.property
          ),
        }))
        .filter((rule: { property: PropertyBrowserTreeNode }) => rule.property);
      retrievedSorts.length &&
        dynamicMEStore.setSelectedSortedByItems(retrievedSorts);
    }
  }

  function saveGroupingRules() {
    localStorage.setItem(
      "DME_groupby",
      JSON.stringify(
        dynamicMEStore.selectedGroupByItems.map((item) => item?.column_key)
      )
    );
  }

  function getGroupingRulesSavedState() {
    return JSON.parse(localStorage.getItem("DME_groupby") ?? "null");
  }

  function retrieveGroupingState() {
    /*
     * Returns only the properties exist
     */
    const savedState = getGroupingRulesSavedState();
    if (savedState) {
      const allProperties = dynamicMEStore.getAllPropertiesFlat();
      const retrievedFilters = savedState
        .map((key_name: string) => {
          return allProperties.find((rec) => rec.column_key === key_name);
        })
        .filter(Boolean);
      retrievedFilters.length &&
        dynamicMEStore.setSelectedGroupByItems(retrievedFilters);
    }
  }

  async function resetTable() {
    await dynamicMEStore.fetchObjectsList(0, true, ifc_id);
    dynamicMEStore.setSelectedRows([]);
  }

  function clearGrouping() {
    dynamicMEStore.removeAllGroupingRules();
    resetTable();
  }

  function save3DViewerState(open: boolean) {
    const currentState = get3DViewerState();
    currentState[String(ifc_id)] = open;
    localStorage.setItem("3D_viewer_state", JSON.stringify(currentState));
  }

  function get3DViewerState() {
    return JSON.parse(localStorage.getItem("3D_viewer_state") ?? "{}");
  }

  return {
    contextMenuOptions,
    buildColumnsVisibilityState,
    updateColumnsVisibilitySavedState,
    saveColumnsVisibilityState,
    getColumnsOrderSavedState,
    retrieveOrderSavedState,
    saveColumnsOrder,
    saveFilterRules,
    saveSortRules,
    retreiveFiltersState,
    retreiveSortState,
    saveGroupingRules,
    retrieveGroupingState,
    resetTable,
    clearGrouping,
    save3DViewerState,
    get3DViewerState,
  };
};

export default useMappingEditor;
