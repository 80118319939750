import {
  BlueprintOUT,
  FieldOptionOUT,
  ManufacturerOUT,
  ProductGroupOUT,
} from "api-client";
import { makeAutoObservable, reaction } from "mobx";
import { handleSmoothTopScroll } from "utils";

// Creating a separate Blueprint store
// as CAOnline store was getting bigger and bigger
class BlueprintCreateStore {
  stepCount = 4;
  stepperOpened: boolean = window.location.pathname.endsWith("/blueprints/add");
  step = 0;
  componentCurrentStep = 0;

  dimensionsValue = "";
  generatedBlueprintName = "";
  manufacturerName = "";
  productGroupName = "";
  selectedColor: FieldOptionOUT | undefined;

  selectedProductGroup: ProductGroupOUT | undefined;
  selectedManufacturer: ManufacturerOUT | undefined;

  selectedComponentProductGroup: ProductGroupOUT | undefined;

  selectedComponent: BlueprintOUT | undefined;
  addedComponents: { [id: string]: BlueprintOUT } = {};

  addedImages: File[] | undefined;
  addedComponentImages: { [id: string]: File[] } | undefined;
  formLoading = "";

  filledSteps: number[] = [];

  constructor() {
    makeAutoObservable(this);

    // effects in recoil is similar to reaction in mobX
    reaction(
      () => [this.step, this.componentCurrentStep],
      handleSmoothTopScroll
    );
  }

  setStepCount(stepCount: number) {
    this.stepCount = stepCount;
  }

  setStepperOpened(stepperOpened: boolean) {
    this.stepperOpened = stepperOpened;
  }

  setStep(newStep: number) {
    this.step = newStep;
  }

  setComponentCurrentStep(componentCurrentStep: number) {
    this.componentCurrentStep = componentCurrentStep;
  }

  setSelectedProductGroup(value: ProductGroupOUT | undefined) {
    this.selectedProductGroup = value;
  }

  setSelectedManufacturer(value: ManufacturerOUT | undefined) {
    this.selectedManufacturer = value;
  }

  setSelectedComponent(selectedComponent: BlueprintOUT | undefined) {
    this.selectedComponent = selectedComponent;
  }

  setSelectedComponentProductGroup(
    selectedComponentProductGroup: ProductGroupOUT | undefined
  ) {
    this.selectedComponentProductGroup = selectedComponentProductGroup;
  }

  setDimensionsValue(value: string) {
    this.dimensionsValue = value;
  }

  setGeneratedBlueprintName(value: string) {
    this.generatedBlueprintName = value;
  }

  setManufacturerName(name: string) {
    this.manufacturerName = name;
  }

  setProductGroupName(value: string) {
    this.productGroupName = value;
  }

  setSelectedColor(color: FieldOptionOUT | undefined) {
    this.selectedColor = color;
  }

  setAddedImages(addedImages: File[] | undefined) {
    this.addedImages = addedImages;
  }

  setAddedComponentImages(
    addedComponentImages:
      | {
          [id: string]: File[];
        }
      | undefined
  ) {
    this.addedComponentImages = addedComponentImages;
  }

  setFormLoading(formLoading: string) {
    this.formLoading = formLoading;
  }

  setAddedComponents(components: { [id: string]: BlueprintOUT }) {
    this.addedComponents = components;
  }

  setFilledSteps(steps: number[]) {
    this.filledSteps = steps;
  }

  get hasComponents() {
    return (
      (this.selectedProductGroup?.required_components &&
        this.selectedProductGroup?.required_components.length > 0) ||
      (this.selectedProductGroup?.optional_components &&
        this.selectedProductGroup?.optional_components.length > 0)
    );
  }

  get isAddingComponent() {
    return this.componentCurrentStep !== 0;
  }

  // Singleton instance of Blueprint Create Store
  static _instance: BlueprintCreateStore;

  static get getInstance(): BlueprintCreateStore {
    if (!BlueprintCreateStore._instance) {
      BlueprintCreateStore._instance = new BlueprintCreateStore();
    }
    return BlueprintCreateStore._instance;
  }
}

export const blueprintCreateStore = BlueprintCreateStore.getInstance;
