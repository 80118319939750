import { useBanner } from "../context";
import CookieButton from "./Button";
import CloseIcon from "./CloseIcon";
import Modal from "./Modal";
import PreferencesBody from "./PreferencesBody";
import PreferencesHeader from "./PreferencesHeader";
import React from "react";

export default function Preferences() {
  const { handlePrefSubmit, handleCancel, texts } = useBanner();
  return (
    <Modal id="cookies-settings" className="max-w-3xl">
      <div className="flex justify-between">
        <h2 className="font-semibold">{texts.title}</h2>
        <CloseIcon />
      </div>
      <div className="">
        <div className="text-sm my-4">{texts.description}</div>
        <form
          onSubmit={handlePrefSubmit}
          className="text-slate-600 text-xs"
          action=""
        >
          <table className="overflow-y-auto block max-h-[50vh] border-collapse border border-slate-300">
            <PreferencesHeader />
            <PreferencesBody />
          </table>
          <div className="flex justify-end mt-4">
            <CookieButton role="secondary" onClick={handleCancel}>
              {texts.cancel}
            </CookieButton>
            <CookieButton className="ml-4" type="submit">
              {texts.save}
            </CookieButton>
          </div>
        </form>
      </div>
    </Modal>
  );
}
