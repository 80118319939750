import React from "react";
import { ImageAlbumOUT } from "api-client";
import ImageResizer from "features/Audit/ImageResizer";
import { observer } from "mobx-react-lite";
import { getImageUrlFromAlbum } from "./utils";

interface ItemImageProps {
  itemId: string;
  itemAlbums: ImageAlbumOUT[];
}

export const ItemImage = observer(({ itemId, itemAlbums }: ItemImageProps) => {
  const [itemImageUrl, setItemImageUrl] = React.useState("");

  React.useEffect(() => {
    getImageUrlFromAlbum(itemId, itemAlbums, setItemImageUrl);
  }, []);

  return (
    <div className="relative rounded-md basis-9 flex items-center w-8 h-8 mr-3">
      {!itemImageUrl || itemImageUrl === "" ? (
        <div className="bg-gray-200 h-full w-8 rounded-md"></div>
      ) : (
        <ImageResizer src={itemImageUrl ?? ""} />
      )}
    </div>
  );
});
