import React from "react";
import Button from "components/Button";
import { useLocation, useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import Badge from "components/Badge";
import LinearLoading from "components/LinearLoading";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import { observer } from "mobx-react-lite";
import { IconArrowBackUp, IconSparkles } from "@tabler/icons-react";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { buildingStore } from "store/Building/BuildingStore";

export default observer(function MappingHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { fileMappingStatus, mappingPerformed } = ifcMappingStore;
  const location = useLocation();
  const { ifc_id } = useParams();
  const [loading, setLoading] = React.useState(false);

  function mappingProgressBar() {
    return !fileMappingStatus ? (
      skeleton()
    ) : (
      <div className="h-full w-[200px]">
        <div className="text-xs pb-1 text-gray-600">
          {fileMappingStatus?.mapped_objects}/{fileMappingStatus?.total}
          <span className="pl-2">{t("mapping.objectsMapped")}</span>
        </div>
        <div className="h-1 relative max-w-xl rounded-full overflow-hidden scrollbar">
          <div className="w-full h-full bg-green-100 absolute"></div>
          <div
            className="h-full bg-green-500 absolute"
            style={{
              width: `${
                (fileMappingStatus?.mapped_objects * 100) /
                fileMappingStatus?.total
              }%`,
            }}
          ></div>
        </div>
      </div>
    );
  }

  const { autoMappLoading, undoMappingLoading } = mappingEditorStore;

  const autoMapp = async () => {
    if (!ifc_id) return;
    mappingEditorStore.setAutoMappLoading(true);
    await ifcMappingStore.autoMappIFCFile(ifc_id);
    mappingEditorStore.setResetTable(!mappingEditorStore.resetTable);
  };

  const undoAutoMapp = async () => {
    if (!ifc_id) return;
    mappingEditorStore.setUndoMappingLoading(true);
    await ifcMappingStore.undoMappingIFCFile(ifc_id);
    mappingEditorStore.setResetTable(!mappingEditorStore.resetTable);
  };

  async function onDone() {
    if (mappingPerformed) {
      setLoading(true);
      await buildingStore.getAllMaterialResources();
      ifcMappingStore.setMappingPerformed(false);
      setLoading(false);
    }
    navigate("../material-resources", { replace: true });
  }

  return (
    <>
      <div
        id="editor-header"
        className="sm:flex w-full bg-white px-4 py-3.5 items-center justify-between border-b border-gray-300"
      >
        <div id="editor-title" className="sm:flex mb-3 sm:mb-0">
          <div className="flex justify-between">
            <div className="text-xl font-semibold text-black mr-6 mb-2 sm:mb-0">
              {t("mapping.mappingEditor")}
            </div>
            <Button
              width="fit-content"
              className="sm:hidden flex"
              onClick={onDone}
              loading={loading}
            >
              {t("mapping.done")}
            </Button>
          </div>
          {location.state ? (
            <Badge type="info" className="mr-6 mb-2 sm:mb-0">
              {`${location?.state}`}
            </Badge>
          ) : null}
          {mappingProgressBar()}
        </div>
        <div className="flex">
          <div className="mr-6 flex">
            <Button
              width="fit-content"
              type="link"
              htmlType="reset"
              className={`mr-0 border border-gray-300 ${
                fileMappingStatus?.automappped_objects
                  ? "border-r-0 rounded-tr-none rounded-br-none"
                  : ""
              }`}
              onClick={autoMapp}
              loading={!!autoMappLoading}
              leadingIcon={<IconSparkles className="text-gray-700" />}
              testID="automapping_button"
            >
              {fileMappingStatus?.automappped_objects
                ? t("mapping.doAutoMappingAgain")
                : t("mapping.tryOutAutoMapping")}
            </Button>
            {fileMappingStatus?.automappped_objects ? (
              <Button
                width="fit-content"
                type="link"
                htmlType="reset"
                className="border border-gray-300 rounded-tl-none rounded-bl-none"
                onClick={undoAutoMapp}
                leadingIcon={<IconArrowBackUp />}
                loading={undoMappingLoading}
                testID="undoautomapping_button"
              >
                {t("mapping.undo")}
              </Button>
            ) : null}
          </div>
          <Button
            width="fit-content"
            className="sm:flex hidden"
            onClick={onDone}
            testID={"donemapping_button"}
            loading={loading}
          >
            {t("mapping.done")}
          </Button>
        </div>
      </div>
      <div className="relative">
        {(autoMappLoading || undoMappingLoading) && (
          <LinearLoading type="success" />
        )}
      </div>
    </>
  );
});

function skeleton() {
  return (
    <div className="animate-pulse mt-1.5">
      <div className="w-[200px] h-[20px] bg-gray-200 rounded dark:bg-gray-700"></div>
    </div>
  );
}
