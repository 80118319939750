import React from "react";
import useMutationObserver from "./useMutationObserver";

export default function useInvalidInput(
  inputRef:
    | React.RefObject<HTMLInputElement>
    | React.RefObject<HTMLSelectElement>,
  callback: (isInvalid: boolean) => void
) {
  useMutationObserver(
    inputRef.current,
    (mutations) => {
      mutations.forEach((mutation) => {
        const value = (mutation.target as Element).getAttribute("data-invalid");
        callback(value === "true");
      });
    },
    { attributes: true, attributeFilter: ["data-invalid"] }
  );
}
