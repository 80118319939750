import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { DGNBStore } from "store/DGNBStore";
import { useTranslation } from "react-i18next";
import SearchInput from "components/SearchInput";
import SimpleSelect from "components/SimpleSelect";
import {
  constructionTypes,
  dgnb_type_of_operation,
  utilityUnits,
  dgnb_building_category,
  dgnb_deconstruction_type,
  dgnb_deconstruction_type_nein,
} from "features/Building/consts.d";
import TextInput from "components/TextInput";
import CustomDatePicker from "components/DatePicker";
import { locationsApi, SuggestOUT } from "api-client";
import { userStore } from "store/UserStore";
import { FormHelperLabel, StepHeading, valueFromOptions } from "./utils";
import ButtonGroups from "components/ButtonGroups";

const buildingCertificationOptions = [
  {
    id: "yes",
    name: "Yes",
    name_de: "Ja",
  },
  {
    id: "no",
    name: "No",
    name_de: "Nein",
  },
];

export const BuildingInfoForm = observer(() => {
  const store = DGNBStore.getInstance();
  const { dgnbPassport } = store;
  const { t } = useTranslation();
  const { authHeader } = userStore;

  const [hasNetAreaChanged, setHasNetAreaChanged] = useState<boolean>(false);
  const [hasBruttoAreaChanged, setHasBruttoAreaChanged] =
    useState<boolean>(false);

  const [locationSuggestions, setLocationSuggestions] =
    useState<Array<SuggestOUT>>();

  const searchAddress = (value: string) => {
    if (!authHeader) return;
    store.setDGNBPassFieldAndValue("address", value);
    locationsApi
      .concularApiV1RoutersLocationsSuggestPlaces(value, 5, 0, authHeader)
      .then((response) => {
        setLocationSuggestions(response.data.items);
      });
  };

  const showDismantlingComment =
    dgnbPassport.deconstruction_type &&
    dgnbPassport.deconstruction_type != dgnb_deconstruction_type_nein;

  return (
    <div className="w-full flex flex-col px-6">
      <StepHeading text={t("DGNB.building_info_heading")} />
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-1">
          <ButtonGroups
            label={t("DGNB.project_certification__label")}
            containerClassName="cursor-pointer mb-4 rounded"
            items={buildingCertificationOptions}
            onChange={(e) => {
              const value = e && e.id == "yes";
              store.setDGNBPassFieldAndValue(
                "project_certification_bool",
                value
              );
            }}
            current={
              dgnbPassport.project_certification_bool
                ? buildingCertificationOptions[0]
                : buildingCertificationOptions[1]
            }
          />
          <FormHelperLabel text={t("DGNB.project_certification__helper")} />
        </div>

        <div className="col-span-1">
          <TextInput
            className="rounded-md"
            labelName={t("DGNB.project_certification_description__label")}
            placeholder={t(
              "DGNB.project_certification_description__placeholder"
            )}
            type="text"
            name="project_certification_description"
            onChange={(e) => e.preventDefault()}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue(
                "project_certification_description",
                `${value}`
              )
            }
            value={dgnbPassport.project_certification_description ?? ""}
            noMargin
          />
          <FormHelperLabel
            text={t("DGNB.project_certification_description__helper")}
          />
        </div>

        <div className="col-span-1">
          <SearchInput
            className="rounded-md"
            labelName={t("DGNB.address__label")}
            name="string_address"
            noMargin
            onChange={searchAddress}
            onSelect={(address) => {
              store.setDGNBPassFieldAndValue("address", address.description);
            }}
            placeholder={t("DGNB.address__placeholder")}
            items={locationSuggestions}
            value={dgnbPassport.address?.trim()}
          />
          <FormHelperLabel text={t("DGNB.address__helper")} />
        </div>

        <div className="col-span-1">
          <CustomDatePicker
            value={dgnbPassport.date_of_construction ?? ""}
            name="date_of_construction"
            dateFormat="yyyy"
            placeholder={t("DGNB.date_of_construction__placeholder")}
            yearPicker
            isDgnb
            onChangeDgnb={(value) =>
              store.setDGNBPassFieldAndValue("date_of_construction", value)
            }
            label={t("DGNB.date_of_construction__label")}
          />
          <FormHelperLabel text={t("DGNB.date_of_construction__helper")} />
        </div>

        <div className="col-span-1">
          <CustomDatePicker
            value={dgnbPassport.permit_of_construction ?? ""}
            name="permit_of_construction"
            dateFormat="dd.MM.yyyy"
            placeholder={t("DGNB.permit_of_construction__placeholder")}
            isDgnb
            onChangeDgnb={(value) => {
              store.setDGNBPassFieldAndValue("permit_of_construction", value);
            }}
            label={t("DGNB.permit_of_construction__label")}
          />
          <FormHelperLabel text={t("DGNB.permit_of_construction__helper")} />
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.type_of_construction__label")}
            options={constructionTypes}
            name="type_of_construction"
            placeholder={t("DGNB.type_of_construction__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "type_of_construction",
                constructionTypes,
                value
              )
            }
            current={valueFromOptions(
              constructionTypes,
              dgnbPassport.type_of_construction
            )}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.type_of_construction__helper")} />
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.type_of_operation__label")}
            options={dgnb_type_of_operation}
            name="type_of_operation"
            onChange={(value) =>
              store.handleOnDropdownChange(
                "type_of_operation",
                dgnb_type_of_operation,
                value
              )
            }
            current={valueFromOptions(
              dgnb_type_of_operation,
              dgnbPassport.type_of_operation
            )}
            placeholder={t("DGNB.type_of_operation__placeholder")}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.type_of_operation__helper")} />
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.building_category__label")}
            options={dgnb_building_category}
            name="building_category"
            onChange={(value) =>
              store.handleOnDropdownChange(
                "building_category",
                dgnb_building_category,
                value
              )
            }
            current={valueFromOptions(
              dgnb_building_category,
              dgnbPassport.building_category
            )}
            placeholder={t("DGNB.building_category__placeholder")}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.building_category__helper")} />
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.deconstruction_type__label")}
            options={dgnb_deconstruction_type}
            name="deconstruction_type"
            onChange={(value) =>
              store.handleOnDropdownChange(
                "deconstruction_type",
                dgnb_deconstruction_type,
                value
              )
            }
            current={valueFromOptions(
              dgnb_deconstruction_type,
              dgnbPassport.deconstruction_type
            )}
            placeholder={t("DGNB.deconstruction_type__placeholder")}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.deconstruction_type__helper")} />
        </div>
        <div className="col-span-1">
          {showDismantlingComment && (
            <>
              <TextInput
                className="rounded-md"
                labelName={t("DGNB.deconstruction_type_method__label")}
                placeholder={t("DGNB.deconstruction_type_method__placeholder")}
                type="text"
                name="deconstruction_type_method"
                onChange={(e) => e.preventDefault()}
                handleChange={(value) =>
                  store.setDGNBPassFieldAndValue(
                    "deconstruction_type_method",
                    `${value}`
                  )
                }
                value={dgnbPassport.deconstruction_type_method ?? ""}
                noMargin
              />
              <FormHelperLabel
                text={t("DGNB.deconstruction_type_method__helper")}
              />
            </>
          )}
        </div>

        <div className="col-span-1">
          <TextInput
            className="rounded-md"
            unit={"m²"}
            labelName={t("DGNB.gross_floor_area__label")}
            placeholder={t("DGNB.gross_floor_area__placeholder")}
            type="number"
            min={1}
            name="gross_floor_area"
            onChange={(e) => {
              e.preventDefault();
              setHasBruttoAreaChanged(true);
            }}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue("gross_floor_area", `${value}`)
            }
            value={dgnbPassport.gross_floor_area ?? ""}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.gross_floor_area__helper")} />
          {hasBruttoAreaChanged && (
            <p className="font-[400] text-[14px] text-yellow-600">
              {t("DGNB.area_has_changed")}
            </p>
          )}
        </div>

        <div className="col-span-1">
          <TextInput
            className="rounded-md"
            unit={"m²"}
            required
            labelName={t("DGNB.net_room_area__label")}
            placeholder={t("DGNB.net_room_area__placeholder")}
            aria-errormessage={t("DGNB.net_room_area_error")}
            type="number"
            min={1}
            name="net_room_area"
            onChange={(e) => {
              e.preventDefault();
              setHasNetAreaChanged(true);
            }}
            handleChange={(value) =>
              store.setDGNBPassFieldAndValue("net_room_area", `${value}`)
            }
            value={dgnbPassport.net_room_area ?? ""}
            errorMessage={t("DGNB.net_room_area_error")}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.net_room_area__helper")} />
          {hasNetAreaChanged && (
            <p className="font-[400] text-[14px] text-yellow-600">
              {t("DGNB.area_has_changed")}
            </p>
          )}
        </div>
      </div>
      <div className="mt-4">
        <SimpleSelect
          className="rounded-md"
          labelName={t("DGNB.utility_unit__label")}
          options={utilityUnits}
          name="utility_unit"
          onChange={(value) =>
            store.handleOnDropdownChange("utility_unit", utilityUnits, value)
          }
          current={valueFromOptions(utilityUnits, dgnbPassport.utility_unit)}
          placeholder={t("DGNB.utility_unit__placeholder")}
          noMargin
        />
        <FormHelperLabel text={t("DGNB.utility_unit__helper")} />
      </div>
    </div>
  );
});
