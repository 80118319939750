import React from "react";
import clsx from "clsx";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";

const DGNBItem = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();

  return (
    <div key={0} className="w-full flex flex-row items-center justify-between">
      <div
        className={clsx(
          disabled ? "text-gray-400" : "text-gray-900",
          "font-[400]"
        )}
      >
        {t("DGNB.export_dgnb")}
      </div>
      {disabled && <Tooltip content={t("DGNB.export_disable_msg")} />}
    </div>
  );
};

export default DGNBItem;
