import {
  AuditOUT,
  BlueprintOUT,
  FieldOptionOUT,
  ManufacturerOUT,
  ProductGroupOUT,
} from "api-client";
import { atom } from "recoil";
import { getItemIDFromUrl, handleSmoothTopScroll } from "utils";
import { storeAudit } from "api-client-local/audits";
import { getLocalAudit } from "features/Audit/SyncBar/lib";

export const manufacturersAtom = atom<ManufacturerOUT[]>({
  key: "manufacturers",
  default: [],
});

export const productGroupsAtom = atom<ProductGroupOUT[]>({
  key: "productGroups",
  default: [],
});

export const addedComponentsAtom = atom<{
  [id: string]: BlueprintOUT;
}>({
  key: "addedComponents",
  default: {},
});

export const generatedBlueprintNameAtom = atom<string>({
  key: "generatedBlueprintName",
  default: "",
});

export const manufacturerNameAtom = atom<string>({
  key: "manufacturerName",
  default: "",
});

export const productGroupNameAtom = atom<string>({
  key: "productGroupName",
  default: "",
});

export const dimensionsValueAtom = atom<string>({
  key: "dimensionsValue",
  default: "",
});

export const selectedColorAtom = atom<FieldOptionOUT | undefined>({
  key: "selectedColor",
  default: undefined,
});

export const selectedProductGroupAtom = atom<ProductGroupOUT | null>({
  key: "selectedProductGroup",
  default: null,
});

export const selectedManufacturerAtom = atom<ManufacturerOUT | null>({
  key: "selectedManufacturer",
  default: null,
});

export const selectedComponentAtom = atom<BlueprintOUT | null>({
  key: "selectedComponent",
  default: null,
});

export const selectedComponentProductGroupAtom = atom<ProductGroupOUT | null>({
  key: "selectedComponentProductGroup",
  default: null,
});

export const stepCountAtom = atom<number>({
  key: "stepCount",
  default: 4,
});

export const stepperOpenedAtom = atom<boolean>({
  key: "stepperOpened",
  default: window.location.pathname.endsWith("/blueprints/add"),
});

export const stepAtom = atom<number>({
  key: "step",
  default: 0,
  effects: [
    ({ onSet }) => {
      onSet(handleSmoothTopScroll);
    },
  ],
});

export const componentCurrentStepAtom = atom<number>({
  key: "componentCurrentStep",
  default: 0,
  effects: [
    ({ onSet }) => {
      onSet(handleSmoothTopScroll);
    },
  ],
});

export const auditsAtom = atom<AuditOUT[] | null>({
  key: "audits",
  default: null,
  effects: [
    ({ onSet }) => {
      onSet(async (audits) => {
        if (!audits || audits.length === 0) return;
        await storeAudit(audits);
        // console.log("(IDB) Audits stored.", audits);
      });
    },
  ],
});

export const auditsLoadingAtom = atom<boolean>({
  key: "audits_loading",
  default: false,
});

export const currentAuditAtom = atom<AuditOUT | null>({
  key: "currentAudit",
  default: (async () => {
    const audit_id = getItemIDFromUrl("audits");
    if (audit_id) {
      const audit = await getLocalAudit(audit_id);
      return audit;
    } else {
      return null;
    }
  })(),
});

export const addedImagesAtom = atom<File[] | null>({
  key: "addedImages",
  default: null,
});

export const addedComponentImagesAtom = atom<{ [id: string]: File[] } | null>({
  key: "addedComponentImages",
  default: null,
});

export const formLoadingAtom = atom<string>({
  key: "formLoading",
  default: "",
});
