import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import Retool from ".";
import MainLayout from "layouts/MainLayout";
import { useParams } from "react-router";
import { userStore } from "store/UserStore";
import { buildingStore } from "store/Building/BuildingStore";

const UrbanMiningHub = () => {
  const reportID = "e0e080ae-3df9-11ef-a1af-0fc3830ce8f0";
  const { organisation_id } = useParams();
  const { userProfile } = userStore;

  const embeddedData = {
    organisation_id: organisation_id,
    email: userProfile?.email,
    user_id: userProfile?.username,
    user_name: `${userProfile?.first_name} ${userProfile?.last_name}`,
  };

  useEffect(() => {
    // deselect building from the menu
    buildingStore.setCurrentBuilding(undefined);
  }, [organisation_id]);

  return (
    <MainLayout headerTitle="Urban Mining Hub">
      <Retool url={reportID} embeddedData={embeddedData} />
    </MainLayout>
  );
};

export default observer(UrbanMiningHub);
