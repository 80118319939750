import React, { useEffect } from "react";
import Button from "components/Button";
import { certExportStore } from "store/CertExportStore";
import CheckCircleIcon from "assets/images/check-circle-green.svg";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon";
import useToast from "hooks/useToast";
import { IconAlertCircle } from "@tabler/icons-react";
import axios from "axios";
import CircularProgressBar from "components/CircularProgressBar";
import { observer } from "mobx-react";
import { buildingStore } from "store/Building/BuildingStore";
import Alert from "components/Alert";

interface MessageObject {
  message: string;
}

interface DatasetCompliaceProps {
  compliant_objects?: number;
  total_objects?: number;
}

const CreateExcelFile = observer(({ onFinish }: { onFinish: () => void }) => {
  const { currentBuilding } = buildingStore;
  const { t } = useTranslation();

  const [fileCreated, setFileCreated] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [alreadyProgressMessage, setAlreadyProgress] = React.useState("");
  const toast = useToast();
  const [datasetObjects, setDatasetObjects] =
    React.useState<DatasetCompliaceProps>({
      compliant_objects: 0,
      total_objects: 0,
    });

  const percentage = calculatePercentage(
    datasetObjects.compliant_objects,
    datasetObjects.total_objects
  );

  useEffect(() => {
    certExportStore.activeStep === 4 &&
      (async () => await getCompliantDataset())();
  }, [certExportStore.activeStep]);

  async function getCompliantDataset() {
    if (!currentBuilding) return;
    const useSimplifiedVersion =
      certExportStore.certExporter?.dgnbVersionValues?.find(
        (item) => item.id === "version-lca"
      )?.isChecked;

    await buildingStore
      .fetchCheckDatasetCompliance(
        currentBuilding?.id,
        certExportStore.certification_id,
        certExportStore.conformity_id,
        useSimplifiedVersion
      )
      .then(
        (response) =>
          response &&
          setDatasetObjects({
            compliant_objects: response.compliant_objects,
            total_objects: response.total_objects,
          })
      );
  }

  const handleCreate = async () => {
    if (!currentBuilding) return;
    try {
      setLoading(true);
      const response = await certExportStore.createCertificationExporterFromApi(
        currentBuilding.id
      );
      if (response?.data.success) {
        setFileCreated(true);
      } else {
        const msg = response?.data.message as MessageObject;
        if (msg) {
          console.log("RESPONSE MSG: ", msg.message);
          toast(msg.message, "attention");
          setAlreadyProgress(msg.message);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error(error);
        const msg = error?.response?.data.message?.message as string;
        if (msg) {
          console.log("RESPONSE MSG: ", msg);
          setAlreadyProgress(msg);
          toast(msg, "attention");
        }
      } else {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const LoadingView = () => {
    return (
      <div className="flex items-center justify-center w-16 h-[91px]">
        <LoadingIcon type="primary" className="w-[100%] h-[100%]" />
      </div>
    );
  };

  const fileNotReady = () =>
    !fileCreated && !loading && !alreadyProgressMessage;

  const showDescription = Boolean(
    fileNotReady() && datasetObjects.total_objects
  );

  return (
    <>
      <div className="items-center justify-center flex flex-col p-6">
        {(loading || !datasetObjects.total_objects) && <LoadingView />}
        {showDescription && (
          <>
            <div className="flex">
              <div>
                <div className="text-base text-gray-800 pb-3 font-semibold">
                  {t("certificationExport.linkedObjectsInfo", {
                    linked_objects: datasetObjects.compliant_objects ?? "",
                    total_objects: datasetObjects.total_objects ?? "",
                  })}
                </div>
                <div className="text-xs font-normal">
                  {t("certificationExport.linkedObjectsSubtitle")}
                </div>
              </div>
              <div className="flex items-center">
                <CircularProgressBar
                  size={40}
                  strokeWidth={8}
                  textStyle="!text-sm font-semibold text-gray-700 !mt-0 mr-0 ml-1"
                  activeStroke="#EF4444"
                  percent={percentage}
                  classsName="!w-20 !h-20 flex items-center"
                />
              </div>
            </div>

            <div className="mt-4">
              <Alert
                className="border-yellow-100"
                description={t("certificationExport.donutChartWarning")}
                type="attention"
                actionButton={t(
                  "certificationExport.donutChartWarningButtonTitle"
                )}
                actionButtonOnClick={() => {
                  window.open(
                    "https://www.notion.so/concular/kobilanzierung-f-r-Zertifizierungen-0fac08a5324b495b853952a493308b95",
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              />
            </div>
          </>
        )}

        {fileCreated && (
          <div className="space-y-4 text-center items-center flex flex-col justify-center w-[80%]">
            <img className="w-12" src={CheckCircleIcon} alt="check-circle" />
            <label className="font-[600] text-lg text-gray-900">
              {t("certificationExport.fileCreated")}
            </label>
            <label className="font-[400] text-sm text-gray-700">
              {t("certificationExport.fileCreatedDescription")}
            </label>
            <Button className="w-full" type="primary" onClick={onFinish}>
              {t("certificationExport.iUnderstand")}
            </Button>
          </div>
        )}

        {alreadyProgressMessage && (
          <div className="space-y-4 text-center items-center flex flex-col justify-center w-[80%]">
            <IconAlertCircle className="w-12 h-12" color="red" />
            <label className="font-[600] text-lg text-gray-900">
              {t("certificationExport.fileInProgress")}
            </label>
            <label className="font-[400] text-sm text-gray-700">
              {alreadyProgressMessage}
            </label>
            <Button type="primary" onClick={onFinish}>
              {t("certificationExport.iUnderstand")}
            </Button>
          </div>
        )}
      </div>

      {fileNotReady() && (
        <div className="bg-gray-50 p-4 mt-2 flex justify-end">
          <Button
            width="fit-content"
            className="px-14"
            type="primary"
            onClick={handleCreate}
          >
            {t("certificationExport.createExcelFile")}
          </Button>
        </div>
      )}
    </>
  );
});

const calculatePercentage = (
  compliant: number | undefined,
  total: number | undefined
) => {
  let numerator = 0;
  let denominator = 1;

  if (compliant) {
    numerator = Math.max(Number(compliant), 0);
  }
  if (total) {
    denominator = Math.max(Number(total), 1);
  }

  return Math.round((100 * numerator) / denominator);
};

export default CreateExcelFile;
