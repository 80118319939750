import React from "react";
import { MinusCircleIcon } from "@heroicons/react/outline";
import { BlueprintOUT } from "api-client";
import { LocalImage } from "api-client-local/db_interfaces";
import {
  deleteLocalImage,
  getLocalImagesForSource,
  tryGenerateUrlForImage,
} from "api-client-local/images";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { db } from "api-client-local/db";
import ImageResizer from "../ImageResizer";
import { useTranslation } from "react-i18next";

interface Props {
  blueprint: BlueprintOUT;
  localImages: LocalImage[];
  setLocalImages: React.Dispatch<React.SetStateAction<LocalImage[]>>;
}

export const LocalImages = ({
  blueprint,
  localImages,
  setLocalImages,
}: Props) => {
  const [images, setImages] = React.useState<LocalImage[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    const sorted = [...localImages].sort((a, b) => b.order - a.order);
    setImages(sorted);
  }, [localImages]);

  const onDeleteLocalImage = (imageId: string) => {
    deleteLocalImage(imageId).then(() => {
      getLocalImagesForSource(blueprint.id).then((localImages) => {
        setLocalImages(localImages);
      });
    });
  };

  const handleOnDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    if (images.length === 1) return;

    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    if (result.destination.index === 0) {
      // If dropped at the 0th index, set the order to maxOrder + 1
      const allOrders = items.map((img) => img.order);
      const maxOrder = Math.max(...allOrders) + 1;
      const updatedItem = { ...reorderedItem, order: maxOrder };
      items.splice(result.destination.index, 1, updatedItem);
      setImages(items);
      items.forEach(async (item) => {
        await db.images.update(item.id, {
          order: item.order,
        });
      });
    } else {
      // If dropped at any other index, set the order accordingly
      const maxOrder = items[0].order; // Order of the 0th index item is the highest
      const updatedItem = {
        ...reorderedItem,
        order: maxOrder - result.destination.index,
      };
      items.splice(result.destination.index, 1, updatedItem);

      const updatedItems = [...items];

      // Check if other items have the same order, and adjust if necessary
      for (let i = result.destination.index + 1; i < updatedItems.length; i++) {
        if (updatedItems[i].order === updatedItem.order) {
          updatedItems[i] = {
            ...updatedItems[i],
            order: updatedItems[i].order - 1,
          };
          await db.images.update(updatedItems[i].id, {
            order: updatedItems[i].order,
          });
        }
      }
      setImages(updatedItems);
      updatedItems.forEach(async (item) => {
        await db.images.update(item.id, { order: item.order });
      });
    }
  };

  return (
    <>
      {images.length > 0 && (
        <>
          <p className="font-semibold text-sm">
            {t("audits.localImagesLabel")}
          </p>
          <div>
            <DragDropContext enableDefaultSensors onDragEnd={handleOnDragEnd}>
              <Droppable
                mode="standard"
                direction="horizontal"
                droppableId="localImages"
              >
                {(provided) => (
                  <div
                    className="whitespace-nowrap overflow-x-auto"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {images.map((img, index) => {
                      return (
                        <Draggable
                          isDragDisabled={images.length === 1}
                          key={img.id}
                          draggableId={img.id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              className="border max-w-[130px] my-3 inline-block mr-3 relative rounded-sm"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <ImageResizer
                                width="500"
                                height="500"
                                className="object-cover !w-full !h-[120px] rounded-sm"
                                src={tryGenerateUrlForImage(img) ?? ""}
                                alt={img.id}
                                isLink
                              />
                              <button
                                className="text-white absolute w-5 h-5 -top-1 !z-100 -right-2 bg-red-700 rounded-full"
                                onClick={() => {
                                  onDeleteLocalImage(img.id);
                                }}
                              >
                                <MinusCircleIcon />
                              </button>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </>
      )}
    </>
  );
};
