import React from "react";

interface CookieButtonProps extends React.HTMLProps<HTMLButtonElement> {
  role?: string;
  type?: "button" | "submit" | "reset";
  testID?: string;
}

export default function CookieButton(
  props: React.PropsWithChildren<CookieButtonProps>
) {
  const isPrimary = props.role === "secondary" ? false : true;
  const bgColor = isPrimary ? `bg-indigo-600` : `bg-indigo-300`;
  const hoverColor = isPrimary ? `bg-indigo-700` : `bg-indigo-400`;
  const textColor = isPrimary ? `text-white` : `text-indigo-600`;
  const focusColor = `focus:ring-indigo-500 focus:ring-offset-indigo-200`;
  const classNames = `px-4 py-2 ${bgColor} hover:${hoverColor} ${focusColor} ${textColor} transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg ${props.className}`;
  return (
    <button
      type={props.type}
      onClick={props.onClick}
      className={classNames}
      test-id={props.testID}
    >
      {props.children}
    </button>
  );
}
