import React from "react";
import BuildingHeaderButtons from "./Buttons";
import BuildingHeaderName from "./Name";
import BuildingHeaderAddress from "./Address";
import { stepperOpenedAtom } from "store/atoms/audits";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";
import CalculationsRunningIndicator from "./CalculationsRunningIndicator";
import CalculationStatusIndicator from "./CalculationStatusIndicator";

export default observer(function BuildingHeader() {
  const { currentBuilding } = buildingStore;
  const stepperOpened = useRecoilValue(stepperOpenedAtom);
  const { instance_id, blueprint_id } = useParams();

  return (
    <div className="flex justify-between flex-row items-center px-4 md:px-5 w-full">
      <div className="flex md:items-center flex-col md:flex-row">
        {currentBuilding && !stepperOpened && !instance_id && !blueprint_id && (
          <>
            <BuildingHeaderName name={currentBuilding.name} />
            <BuildingHeaderAddress building={currentBuilding} />
          </>
        )}
        {buildingStore.calculationsStatus.inProgress ? (
          <CalculationsRunningIndicator className="ml-4" />
        ) : (
          <CalculationStatusIndicator />
        )}
      </div>
      <BuildingHeaderButtons />
    </div>
  );
});
