import React from "react";
import {
  IconCheck,
  IconExclamationMark,
  IconInfoCircle,
  IconReload,
} from "@tabler/icons-react";
import Modal, { ModalProps } from "components/Modal";
import { useTranslation } from "react-i18next";
import CircularProgressBar from "components/CircularProgressBar";
import clsx from "clsx";
import Button from "components/Button";
import ImporterSidebar from "./ImporterSidebar";
import { b6ImporterStore } from "store/B6ImporterStore";
import { observer } from "mobx-react-lite";
import useToast from "hooks/useToast";
import CalculationOverview from "./CalculationOverview";
import LoadingIcon from "components/LoadingIcon";
import { buildingDashboardStore } from "store/Building/BuildingDashboardStore";

const B6Importer = ({ open, close }: ModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [initialLoading, setInitialLoading] = React.useState(true);
  const [reportLoading, setReportLoading] = React.useState(false);

  const onSaveClicked = async () => {
    await b6ImporterStore
      .saveFile()
      .then((res) => {
        res?.success && toast(t("b6Importer.successConfirm"), "success");
        close();
      })
      .catch((error) => toast(error?.message, "warning"));
  };

  React.useEffect(() => {
    !b6ImporterStore.uploadedFileID && setInitialLoading(true);
    setReportLoading(true);
    const timer = setTimeout(async () => {
      await b6ImporterStore.fetchReportData(b6ImporterStore.uploadedFileID);
      setInitialLoading(false);
      setReportLoading(false);
    }, 2000);

    if (!open) {
      clearTimeout(timer);
      const reloadDashboardTimeout = setTimeout(() => {
        if (b6ImporterStore.uploadedFileID) {
          buildingDashboardStore.initialFetch();
        }
        clearTimeout(reloadDashboardTimeout);
        b6ImporterStore.reset();
      }, 2000);
    }
  }, [b6ImporterStore.uploadedFileID, open]);

  function uploadingView() {
    if (b6ImporterStore.uploadError?.error) {
      return errorMessage();
    }

    return (
      <div className="py-4 flex flex-col items-center">
        {b6ImporterStore.uploadProgress !== 100 ? (
          <>
            <div className="bg-white flex w-full py-1 items-center justify-center">
              <CircularProgressBar percent={b6ImporterStore.uploadProgress} />
            </div>
            <div className="mt-4 text-center text-xs font-normal text-gray-700 h-6">
              {t("b6Importer.fileUploading")}
            </div>
          </>
        ) : (
          <>
            <div
              className={clsx(
                "bg-green-100 w-16 h-16 rounded-full p-4 mb-2 flex justify-center",
                "items-center transition animate-fadeInDown"
              )}
            >
              <IconCheck className="text-green-600" />
            </div>
            <div className="mt-4 text-center text-xs font-normal text-gray-700 h-6">
              {t("b6Importer.fileProcessing")}
            </div>
          </>
        )}
      </div>
    );
  }

  function errorMessage() {
    const missingColumn =
      Number(b6ImporterStore?.uploadError?.columns?.length) > 1
        ? b6ImporterStore?.uploadError?.columns?.slice(-1)[0]
        : b6ImporterStore?.uploadError?.columns;
    const missingColumns =
      Number(b6ImporterStore?.uploadError?.columns?.length) > 1
        ? b6ImporterStore?.uploadError?.columns?.slice(0, -1).join(", ")
        : "";

    return (
      <div className="flex flex-col justify-center items-center">
        <div className="text-red-700 flex items-center justify-center text-xs font-medium">
          <IconExclamationMark className="bg-red-100 min-w-[24px] min-h-[24px] p-1 rounded-full mr-2" />
          {t(
            `b6Importer.${
              b6ImporterStore?.uploadError?.error
                ? Number(b6ImporterStore.uploadError.columns?.length) > 1
                  ? b6ImporterStore.uploadError.error
                  : b6ImporterStore.uploadError?.error?.slice(0, -1)
                : "errors.general"
            }`,
            {
              missing_column: missingColumn,
              missing_columns: missingColumns,
              wrong_sheet: b6ImporterStore?.uploadError?.sheet,
            }
          )}
        </div>
        {b6ImporterStore.hasLatestConfirmedReport ? (
          <Button
            type="gray"
            leadingIcon={<IconReload />}
            className="mt-4"
            width="fit-content"
            onClick={onClickReloadLatest}
          >
            {t("b6Importer.showLastCalculatedResults")}
          </Button>
        ) : null}
      </div>
    );
  }

  async function onClickReloadLatest() {
    b6ImporterStore.setUploading(false);
    await b6ImporterStore.fetchReportData();
  }

  function loadingView() {
    return (
      <div className="p-6 w-full flex flex-col items-center justify-center">
        <div className="bg-white flex w-full py-2 items-center justify-center mb-1">
          <LoadingIcon className="!h-[55px] !w-[55px]" />
        </div>
        {!initialLoading ? (
          <div className="mt-4 text-center text-xs font-normal text-gray-700 h-6">
            {t("b6Importer.fileProcessing")}
          </div>
        ) : null}
      </div>
    );
  }

  function blankView() {
    return initialLoading || reportLoading ? (
      loadingView()
    ) : (
      <div className="p-6 w-full flex items-center justify-center">
        {b6ImporterStore.uploading === true ? (
          uploadingView()
        ) : (
          <div className="flex items-center justify-center text-xs font-medium text-indigo-700">
            <IconInfoCircle
              className="bg-indigo-100 w-6 h-6 p-1 rounded-full mr-2"
              stroke={3}
            />
            {t("b6Importer.pleaseUploadText")}
          </div>
        )}
      </div>
    );
  }

  function footer(): React.ReactNode {
    return (
      <div className="flex w-full justify-between">
        <Button width="fit-content" type="gray" onClick={close}>
          {t("commons.cancel")}
        </Button>
        <Button
          width="fit-content"
          type="primary"
          onClick={onSaveClicked}
          disable={!!b6ImporterStore?.uploadError?.error}
        >
          {t("commons.save")}
        </Button>
      </div>
    );
  }

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      title={t("b6Importer.b6ImporterTitle")}
      containerClassName="min-w-[calc(100vw-100px)]"
      closeButton
      footer={footer()}
    >
      <div className="flex justify-start">
        <ImporterSidebar />
        {b6ImporterStore.fileReport ? <CalculationOverview /> : blankView()}
      </div>
    </Modal>
  );
};

export default observer(B6Importer);
