import React from "react";
import { BlueprintOUT, ManufacturerOUT } from "api-client";
import Button from "components/Button";
import ButtonAsDiv from "components/ButtonAsDiv";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  addedComponentImagesAtom,
  addedComponentsAtom,
  componentCurrentStepAtom,
  manufacturersAtom,
  selectedComponentAtom,
  selectedComponentProductGroupAtom,
  stepAtom,
  stepCountAtom,
} from "store/atoms/audits";
import {
  makeBlueprintPropertyModel,
  scrollToFirstInvalidInput,
} from "../../../features/Audit/utils";
import validateComponentForm from "features/Audit/handlers/validateComponentForm";
import { filledStepsAtom } from "components/Stepper";
import { checkAndAddBlueprintId } from "utils";

export const SubmitButton = React.forwardRef<HTMLButtonElement>(
  (_props, ref) => {
    const { t } = useTranslation();
    return (
      <Button
        ref={ref}
        htmlType="submit"
        className="min-w-32"
        disabledClasses="w-full"
      >
        {t("audits.createBlueprint")}
      </Button>
    );
  }
);

SubmitButton.displayName = "SubmitButton";

export function EditComponentButton() {
  const { t } = useTranslation();
  const { blueprint_id } = useParams();
  const setComponentStep = useSetRecoilState(componentCurrentStepAtom);
  const [selectedComponent, setSelectedComponent] = useRecoilState(
    selectedComponentAtom
  );
  const setAddedComponents = useSetRecoilState(addedComponentsAtom);
  const manufacturers = useRecoilValue(manufacturersAtom);
  const [selectedComponentProductGroup, setSelectedComponentProductGroup] =
    useRecoilState(selectedComponentProductGroupAtom);
  const setAddedComponentImages = useSetRecoilState(addedComponentImagesAtom);

  const handleComponentEdit = React.useCallback(async () => {
    if (!selectedComponent) return;
    try {
      const form = validateComponentForm();
      if (!form) throw "form is undefined.";
      if (!selectedComponentProductGroup)
        throw "selectedComponent is undefined.";
      const componentFields = [
        ...selectedComponentProductGroup.required_fields,
        ...selectedComponentProductGroup.optional_fields,
      ];
      if (componentFields.length === 0) throw "componentFields.length is 0";
      const manufacturer: ManufacturerOUT | undefined =
        form.select_manufacturer && form.select_manufacturer.length > 0
          ? manufacturers?.find((m) => m.name === form.select_manufacturer)
          : undefined;

      setAddedComponents((prev: { [id: string]: BlueprintOUT }) => {
        const editedComponent = {
          ...selectedComponent,
          name: form.name,
          as_component_amount: Number(form.as_component_amount),
          manufacturer: manufacturer as ManufacturerOUT,
        };
        editedComponent.blueprintproperty_set = componentFields.map((field) => {
          return makeBlueprintPropertyModel(
            field.id,
            editedComponent.id,
            form[field.id]
          );
        });
        return {
          ...prev,
          [selectedComponent.id]: editedComponent,
        };
      });
      if (form.images_component && form.images_component.length > 0) {
        setAddedComponentImages((prev) => ({
          ...prev,
          [selectedComponent.id]: form.images_component as unknown as File[],
        }));
      }
      if (blueprint_id) {
        await checkAndAddBlueprintId(blueprint_id);
      }
      setComponentStep(0);
      setSelectedComponentProductGroup(null);
      setSelectedComponent(null);
    } catch (err) {
      console.error(err);
    }
  }, [manufacturers, selectedComponent, selectedComponentProductGroup]);

  return (
    <ButtonAsDiv onClick={handleComponentEdit}>
      {t("audits.editComponent")}
    </ButtonAsDiv>
  );
}

export const ContinueButton = React.forwardRef(
  (_props, ref: React.Ref<HTMLDivElement>) => {
    const [step, setStep] = useRecoilState(stepAtom);
    const { t } = useTranslation();
    const setFilledSteps = useSetRecoilState(filledStepsAtom);

    return (
      <ButtonAsDiv
        ref={ref}
        className="w-full md:w-auto"
        onClick={() => {
          const validated = validateStep(step, setStep);
          if (validated) {
            setFilledSteps((prev) => Array.from(new Set([...prev, step])));
          }
        }}
      >
        {t("audits.continue")}
      </ButtonAsDiv>
    );
  }
);

ContinueButton.displayName = "ContinueButton";

export const SkipButton = () => {
  const [step, setStep] = useRecoilState(stepAtom);
  const { t } = useTranslation();
  const setFilledSteps = useSetRecoilState(filledStepsAtom);
  const steps = useRecoilValue(stepCountAtom);

  return (
    <ButtonAsDiv
      className="w-full md:w-auto"
      color="secondary"
      onClick={() => {
        const validated = validateStep(step);
        if (validated) {
          setStep(steps - 1);
          setFilledSteps([...Array.from({ length: steps - 1 }).keys()]);
        }
      }}
    >
      {t("audits.skipDetails")}
    </ButtonAsDiv>
  );
};

export function BackComponentsButton() {
  const setComponentStep = useSetRecoilState(componentCurrentStepAtom);
  const setSelectedComponentProductGroup = useSetRecoilState(
    selectedComponentProductGroupAtom
  );
  const { t } = useTranslation();

  return (
    <Button
      onClick={() => {
        setComponentStep(0);
        setSelectedComponentProductGroup(null);
      }}
      type="secondary"
      disabledClasses="w-full"
    >
      {t("audits.back")}
    </Button>
  );
}

export function BackButton() {
  const setStep = useSetRecoilState(stepAtom);
  const { t } = useTranslation();
  return (
    <ButtonAsDiv
      color="secondary"
      onClick={() => {
        setStep((prev) => prev - 1);
      }}
    >
      {t("audits.back")}
    </ButtonAsDiv>
  );
}

function validateStep(step: number, setStep?: SetterOrUpdater<number>) {
  try {
    const stepForm = document.getElementById(`step-${step + 1}`);
    if (!stepForm) throw new Error(`Step form is not found for (${step})`);
    const inputs = Array.from(
      stepForm.querySelectorAll("input[required], select[required]")
    ) as HTMLInputElement[];

    const emptyInputs = inputs.filter((i) => i.value === "");

    // TODO_AUDIT: Be sure to check invalid state for select component
    if (emptyInputs.length > 0) {
      scrollToFirstInvalidInput(emptyInputs);
      for (let i = 0; i < emptyInputs.length; i++) {
        emptyInputs[i].setAttribute("data-invalid", "true");
      }
      return;
    }

    setStep?.((prev) => prev + 1);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
