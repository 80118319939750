import BimViewer from "features/BimViewer";
import BimViewerHeader from "features/BimViewer/BimViewerHeader";
import React from "react";
import { useSetRecoilState } from "recoil";
import { sidebarOpenedAtom } from "store/atoms";

const BimViewerPage = () => {
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);

  React.useEffect(() => {
    setSidebarOpened(false);
  }, []);

  return (
    <div className="fixed top-0 left-0 bg-gray-100 w-screen min-h-screen max-h-min z-20">
      <BimViewerHeader />
      <BimViewer />
    </div>
  );
};

export default BimViewerPage;
