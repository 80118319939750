import React, { useContext, useEffect } from "react";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import {
  Auth0ProviderOptions,
  GetIdTokenClaimsOptions,
  IdToken,
  useAuth0,
  User,
} from "@auth0/auth0-react";
import { userApi } from "api-client";
import { UserbackProvider } from "@userback/react";
import { authHeaderAtom } from "store/atoms";
import { SetterOrUpdater, useSetRecoilState } from "recoil";
import { localStorageKeys } from "store/enums";
import { observer } from "mobx-react-lite";
import { organisationStore } from "store/OrganisationStore";
import { buildingStore } from "store/Building/BuildingStore";
import { UserProfile, userStore } from "store/UserStore";
import { useLocation } from "react-router";
import useSignout from "hooks/useSignout";

export const authConfig: Auth0ProviderOptions = {
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  audience: import.meta.env.VITE_AUTH0_AUDIENCE,
  scope: "read:current_user update:current_user_metadata",
  redirectUri: window.location.origin,
  cacheLocation: "localstorage",
  useRefreshTokens: true,
};

interface UserState {
  user: User | undefined;
}

const UserContext: React.Context<UserState | Record<string, never>> =
  React.createContext({});

export const UserProvider = observer(
  ({ children }: React.PropsWithChildren) => {
    const { user, getIdTokenClaims } = useAuth0();
    const { initialLoading, userProfile } = userStore;
    const setAuthHeaderAtom = useSetRecoilState(authHeaderAtom);
    const location = useLocation();

    const logout = useSignout();

    React.useEffect(() => {
      if (location.search.includes("iss")) {
        // forced logout after Email verification
        logout();
      }
    }, [location.search]);

    useEffect(() => {
      if (!user) return;
      (async () => {
        await initialUserFetch(getIdTokenClaims, setAuthHeaderAtom);
      })();
    }, [user?.email]);

    React.useEffect(() => {
      if (!userProfile?.email) return;
      (async () => {
        await organisationStore.initialFetch();
        await buildingStore.initialFetch();
        userStore.setInitialLoading(false);
      })();
    }, [userProfile?.email]);

    React.useEffect(() => {
      if (!userProfile?.first_name || initialLoading) return;
      const isOnboarded = Boolean(
        organisationStore.organisations.length ||
          (buildingStore.sharedBuildings.items.length &&
            userProfile?.first_name)
      );
      userStore.setOnboarded(isOnboarded);
      userStore.setOnboardingLoading(false);
    }, [userProfile?.first_name, initialLoading]);

    const contextValues: UserState = {
      user,
    };

    return (
      <UserContext.Provider value={contextValues}>
        <UserbackProvider token={import.meta.env.VITE_USERBACK_TOKEN}>
          {children}
        </UserbackProvider>
      </UserContext.Provider>
    );
  }
);

export const useUser = (): UserState | Record<string, never> =>
  useContext(UserContext);

async function initialUserFetch(
  getIdTokenClaims: (
    options?: GetIdTokenClaimsOptions | undefined
  ) => Promise<IdToken | undefined>,
  setAuthHeaderAtom: SetterOrUpdater<AxiosRequestConfig<AxiosRequestHeaders> | null>
) {
  try {
    const { audience, scope } = authConfig;
    const idToken = await getIdTokenClaims({ audience, scope });

    if (!idToken) {
      return;
    }

    const authHeaderConfig = {
      headers: { Authorization: `Bearer ${idToken?.__raw}` },
    };
    if (idToken) {
      userStore.setVerified(idToken.email_verified);
    }
    userStore.setAuthHeader(authHeaderConfig);
    setAuthHeaderAtom(authHeaderConfig);
    const response = await userApi.concularApiV1RoutersUserGetUserProfile(
      authHeaderConfig
    );

    userStore.setUserProfile(response.data as unknown as UserProfile);
    const res =
      await userApi.concularApiV1RoutersUserGetUserSsoTokenForUserback(
        authHeaderConfig
      );
    userStore.setUserbackToken(res.data.sso_jwt);
  } catch (error) {
    console.warn("UserContext", error);
  }
}
