import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { UPCOMING_OPERATION_LIST } from "../../consts.d";
import i18n from "languages/i18n";
import moment from "moment";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";

export default observer(function LifeCycleTile() {
  const { t } = useTranslation();
  const { currentBuilding } = buildingStore;

  function getUpcomingOperation() {
    return UPCOMING_OPERATION_LIST.find(
      (item) => item.id === currentBuilding?.upcoming_operation
    );
  }

  return (
    <div
      className="bg-white pl-4 py-4 rounded-lg border border-gray-300 md:col-span-2 mb-4"
      test-id="lifecycle_lcp"
    >
      <div className="flex justify-between items-center">
        <div className="font-semibold text-gray-900">
          {t("buildingPassport.lifeCycle")}
        </div>
      </div>
      <div className="text-xs pr-3">
        <div
          className={clsx("flex justify-between py-3", {
            "border-b":
              currentBuilding?.upcoming_operation !==
              UPCOMING_OPERATION_LIST[0].id,
          })}
        >
          <div className="pr-1">
            <div className="text-gray-600">
              {t("addBuilding.planned_operation_heading")}
            </div>
            <div className="text-gray-500">
              {i18n.language === "de"
                ? getUpcomingOperation()?.name_de ?? "--"
                : getUpcomingOperation()?.name ?? "--"}
            </div>
          </div>
          <div className="pr-1">
            <div className="text-gray-600">
              {t("addBuilding.estimatedStart")}
            </div>
            <div className="text-gray-500">
              {currentBuilding?.start_date
                ? moment(currentBuilding?.start_date).format("MM.YYYY")
                : "--"}
            </div>
          </div>
          <div className="pr-1">
            <div className="text-gray-600">{t("addBuilding.estimatedEnd")}</div>
            <div className="text-gray-500">
              {currentBuilding?.end_date
                ? moment(currentBuilding?.end_date).format("MM.YYYY")
                : "--"}
            </div>
          </div>
        </div>
        {currentBuilding?.upcoming_operation !==
          UPCOMING_OPERATION_LIST[0].id && (
          <div className="pt-3">
            <div className="text-gray-600">
              {t("buildingPassport.yearOfConstruction")}
            </div>
            <div className="text-gray-500">
              {currentBuilding?.construction_year ?? "--"}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});
