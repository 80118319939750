import React from "react";
import { currentAuditAtom, stepperOpenedAtom } from "store/atoms/audits";
import { useRecoilValue } from "recoil";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { Link, useLocation, useParams } from "react-router-dom";
import useAuditFormReset from "hooks/useAuditFormReset";
import { useTranslation } from "react-i18next";

export default function AuditBackButton() {
  const { id, audit_id, blueprint_id, instance_id } = useParams();
  const audit = useRecoilValue(currentAuditAtom);
  const stepperOpened = useRecoilValue(stepperOpenedAtom);
  const reset = useAuditFormReset();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const getNavigationRoute = () => {
    if (instance_id || pathname.includes(`/blueprints/${blueprint_id}/add`)) {
      return `/buildings/${id}/audits/${audit_id}/blueprints/${blueprint_id}/instances`;
    }

    return `/buildings/${id}/audits/${audit_id}`;
  };

  return (
    <Link
      to={getNavigationRoute()}
      onClick={reset}
      className="flex justify-between items-center w-full"
    >
      <div className="flex">
        <ChevronLeftIcon className="mr-2" width={16} />
        {audit?.title && (
          <span className="text-sm whitespace-pre overflow-hidden text-ellipsis">
            {audit?.title ? audit.title : t("audits.backToAudit")}
          </span>
        )}
      </div>
      {stepperOpened && (
        <button
          onClick={reset}
          className="bg-gray-100 h-7 px-2 rounded-md text-xs"
        >
          {t("addBuilding.cancel")}
        </button>
      )}
    </Link>
  );
}
