import React, { SetStateAction, Dispatch } from "react";
import { useTranslation } from "react-i18next";
import ActionMenu from "components/ActionMenu";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { isMobileSize } from "utils";
import { useNavigate } from "react-router";
import useToast from "hooks/useToast";
import AddEditBuilding from "../AddEditBuilding";
import DeleteBuilding from "../BuildingPassport/DeleteBuilding";
import ConfirmModal from "components/ConfirmModal";
import { userBuildingApi } from "api-client";
import { observer } from "mobx-react";
import { buildingStore } from "store/Building/BuildingStore";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { userStore } from "store/UserStore";
import B6Importer from "../B6Importer";

interface BuildingHeaderSettingsProps {
  setOpenShare: Dispatch<SetStateAction<boolean>>;
}

export default observer(function BuildingHeaderSettings(
  props: BuildingHeaderSettingsProps
) {
  const { t } = useTranslation();
  const { isSharedBuilding, sharedBuildings, currentBuilding } = buildingStore;
  const navigate = useNavigate();
  const { authHeader } = userStore;
  const toast = useToast();
  const [openDelete, setOpenDelete] = React.useState(false);
  const [openLeave, setOpenLeave] = React.useState(false);
  const [openB6Importer, setOpenB6Importer] = React.useState(false);

  const actionMenu = {
    items: isSharedBuilding
      ? [<span key={0}>{t("shareBuilding.leaveBuilding")}</span>]
      : [
          <span key={0}>{t("buildingPassport.editBuilding")}</span>,
          <span key={1}>{t("buildingPassport.deleteBuilding")}</span>,
          <span key={2}>{t("b6Importer.b6ImporterTitle")}</span>,
        ],
    itemsDisabled: isSharedBuilding
      ? [false]
      : [false, !currentBuilding?.exports_available],
  };

  const { items, itemsDisabled } = actionMenu;

  const actions = isSharedBuilding
    ? [() => setOpenLeave(true)]
    : [
        () => buildingSettingsStore.setOpenEditBuilding(true),
        () => setOpenDelete(true),
        () => setOpenB6Importer(true),
      ];

  if (isMobileSize() && !isSharedBuilding) {
    items.push(
      <span key={2} className="sm:hidden">
        {t("buildingPassport.shareBuilding")}
      </span>
    );
    actions.push(() => props.setOpenShare(true));
  }

  const leaveBuilding = async () => {
    if (!currentBuilding) return;
    const userBuildingId = sharedBuildings.items.find(
      (building) => building.building.id === currentBuilding.id
    )?.id;
    if (userBuildingId && authHeader) {
      await userBuildingApi
        .concularApiV1RoutersUserBuildingsLeaveBuilding(
          userBuildingId,
          authHeader
        )
        .then((response) => {
          if (response.status === 200) {
            toast(t("shareBuilding.successfullyLeftBuilding"), "success");
            buildingStore.getSharedBuildings();
            navigate("/dashboard", { replace: true });
          }
        })
        .catch((error) => {
          toast(t("shareBuilding.errorLeavingBuilding"), "warning");
          console.error("leaveBuilding.error", error);
        });
    }
  };

  return (
    <>
      <ActionMenu
        items={items}
        itemDisabled={itemsDisabled}
        onSelect={(index) => actions[index]()}
        icon={
          <DotsVerticalIcon
            width={20}
            className="text-gray-500 hover:text-black -mr-2"
          />
        }
        itemsClassName={"!min-w-[195px] cursor-pointer"}
      />

      {buildingSettingsStore.openEditBuilding && (
        <AddEditBuilding
          open={buildingSettingsStore.openEditBuilding}
          building={currentBuilding}
          close={() => buildingSettingsStore.setOpenEditBuilding(false)}
          type="edit"
        />
      )}
      {openDelete && <DeleteBuilding open={openDelete} close={setOpenDelete} />}
      {openLeave && (
        <ConfirmModal
          open={openLeave}
          close={() => setOpenLeave(false)}
          title={t("shareBuilding.wantToLeaveBuilding")}
          descriptionMesage={t("shareBuilding.leaveBuildingWarningMessage")}
          confirmButtonTitle={t("shareBuilding.leaveBuilding")}
          onConfirm={leaveBuilding}
        />
      )}

      <B6Importer
        open={openB6Importer}
        close={() => setOpenB6Importer(false)}
      />
    </>
  );
});
