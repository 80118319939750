import React from "react";
import XLSXFileIcon from "assets/images/icons/xlsx-file-icon.svg";
import CSVFileIcon from "assets/images/icons/csv-file-icon.svg";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

export default function SelectFileType(props: {
  setSelected: React.Dispatch<React.SetStateAction<string>>;
}) {
  const { t } = useTranslation();

  const tileStyle = clsx(
    "border border-gray-300 p-7 w-full rounded-md cursor-pointer",
    "flex flex-col items-center justify-center text-base text-gray-700 font-semibold",
    "hover:border-indigo-500 hover:outline outline-indigo-500 outline-1"
  );

  const setFileType = (e: React.MouseEvent) =>
    props.setSelected(e.currentTarget.id);

  return (
    <>
      <div className="text-base font-semibold pb-6">
        {t("buildingInventory.selecFileTypeText")}
      </div>
      <div className="flex justify-between gap-6 w-full">
        <div id="xlsx" className={tileStyle} onClick={setFileType}>
          {t("commons.excelFile")}
          <img src={XLSXFileIcon} className="pt-2" />
        </div>
        <div id="csv" className={tileStyle} onClick={setFileType}>
          {t("commons.csvFile")}
          <img src={CSVFileIcon} className="pt-2" />
        </div>
      </div>
    </>
  );
}
