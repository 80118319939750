import React from "react";
import SearchSortTable from "components/Table";
import { ColumnDef, Row } from "@tanstack/react-table";
import useTableColumns from "./useTableColumns";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { useParams } from "react-router";
import BulkActionBar from "./BulkActionBar/BulkActionBar";
import FiltersBar from "./Filters";
import useMappingEditor from "./useMappingEditor";
import EmptyState from "./layout/EmptyState";
import { observer } from "mobx-react-lite";
import { SingleItem } from "api-client";
import { bimViewerStore } from "store/BimViewerStore";
import clsx from "clsx";

const DMETableView = () => {
  const { ifc_id } = useParams();
  const { updateColumnsVisibilitySavedState, retrieveOrderSavedState } =
    useMappingEditor();
  const [hoveredRow, setHoveredRow] = React.useState<SingleItem>();

  React.useEffect(() => {
    initialCall();
  }, []);

  React.useEffect(() => {
    update3DViewerWithGroupsHovering();
  }, [dynamicMEStore.hoveredGroupsGlobalIDs, hoveredRow]);

  React.useEffect(() => {
    updateSelectedGroupsGlobalIfcIDs();
  }, [dynamicMEStore.selectedRows, dynamicMEStore.open3DViewer]);

  async function initialCall() {
    /**
     * This could be reduced to one API call in the future since
     * they are different structures of the same data
     */
    await Promise.all([
      dynamicMEStore.fetchColumns(ifc_id),
      dynamicMEStore.getProperties(ifc_id),
    ]);
    updateColumnsVisibilitySavedState();
    retrieveOrderSavedState();
  }

  function update3DViewerWithGroupsHovering() {
    if (!dynamicMEStore.open3DViewer) return;
    const hoveredIDs = hoveredRow
      ? dynamicMEStore.hoveredGroupsGlobalIDs
      : undefined;

    bimViewerStore.setHovered(
      hoveredIDs,
      undefined,
      dynamicMEStore.selectedRows.length > 0
    );
  }

  function updateSelectedGroupsGlobalIfcIDs() {
    if (!dynamicMEStore.open3DViewer) return;
    if (dynamicMEStore.selectedRows.length && dynamicMEStore.hasGrouping()) {
      dynamicMEStore.fetchGroupsGlobalIfcIds(ifc_id);
    } else {
      dynamicMEStore.setSelectedGroupsGlobalIDs([]);
    }
  }

  const getColumns = () => {
    return useTableColumns();
  };

  const getData = async (start: number, _: number, update?: boolean) => {
    await dynamicMEStore.fetchObjectsList(start, update, ifc_id);
  };

  const handleOnClickRow = (row: Row<unknown>) => {
    if (dynamicMEStore.hasGrouping()) return;
    dynamicMEStore.setCurrentRow(row);
    dynamicMEStore.setOpenedObjectDetails(true);
  };

  const handleOnHoverRow = (row: Row<unknown> | null) => {
    if (!dynamicMEStore.open3DViewer) return;
    const currentRow = row?.original as SingleItem;
    setHoveredRow(currentRow);
    if (dynamicMEStore.hasGrouping()) {
      row && dynamicMEStore.fetchGroupsGlobalIfcIds(ifc_id, currentRow, true);
      !row && dynamicMEStore.setHoveredGroupsGlobalIDs([]);
    } else {
      bimViewerStore.setHovered(
        row ? [currentRow?.ifc_global_id as string] : undefined,
        (dynamicMEStore.currentRow?.original as SingleItem)?.ifc_global_id,
        dynamicMEStore.selectedRows.length > 0
      );
    }
  };

  const noResultsForFiltering = () =>
    dynamicMEStore.selectedFilterByItems.rules.length &&
    dynamicMEStore.data.items.length === 0 &&
    !dynamicMEStore.dataFetchLoading &&
    !dynamicMEStore.errorFetchingObjects;

  const showTable = !dynamicMEStore.errorFetchingObjects;
  const deviderIndexes = [
    0,
    ...(dynamicMEStore.validGroupByRules.length
      ? [dynamicMEStore.validGroupByRules.length + 1]
      : []),
  ];

  if (!dynamicMEStore.visibleColumnProperties?.length) return <></>;
  return (
    <div
      className={clsx(
        "flex flex-col items-center w-full",
        dynamicMEStore.open3DViewer ? "h-[calc(100%-18px)]" : "h-full"
      )}
    >
      <FiltersBar />
      {dynamicMEStore.errorFetchingObjects && <EmptyState />}
      {noResultsForFiltering() ? (
        <EmptyState />
      ) : (
        showTable && (
          <SearchSortTable
            getColumns={getColumns as () => ColumnDef<unknown, unknown>[]}
            data={dynamicMEStore.data.items.map((item) => ({
              ...item.values,
              id: item.id,
              product_match: item.product_match,
              ifc_global_id: item.ifc_global_id,
              object_count: item.object_count,
            }))}
            hasGroupHeader
            enableColumnResizing
            totalDBRowCount={dynamicMEStore.data.count}
            dataFetchLoading={dynamicMEStore.dataFetchLoading}
            fetchData={getData}
            enableTooltip
            scrollDivClassName="max-h-full w-full bg-white"
            customColumnStyle={clsx(
              "!py-0 [&:nth-of-type(1)]:px-0 !font-medium h-[40px] border-r-gray-50 border-r text-gray-900",
              dynamicMEStore.hasGrouping() ? "cursor-auto" : "cursor-pointer"
            )}
            headerDivStyle="!py-1.5 font-semibold"
            headerColumnStyle="!py-0"
            customContainerStyle="border-b"
            groupHeaderClassName="!pt-0 !pb-0 !px-0"
            enableRowSelection
            onRowSelect={(e) => dynamicMEStore.setSelectedRows(e)}
            noFooterBar
            hasHoverStyle
            onRowClick={handleOnClickRow}
            selectedRows={dynamicMEStore.selectedRowIndexes}
            selectAllChecked={dynamicMEStore.selectAllChecked}
            hiddenColumns={dynamicMEStore.columnsVisibilityState}
            columnsOrder={dynamicMEStore.tableColumnsOrder}
            currentRowID={String(
              (dynamicMEStore.currentRow?.original as SingleItem)?.id
            )}
            disableUserInteraction={dynamicMEStore.interactionDisabled}
            onHoveredRow={handleOnHoverRow}
            deviderIndexes={deviderIndexes}
          />
        )
      )}
      <BulkActionBar />
    </div>
  );
};

export default observer(DMETableView);
