import { atom } from "recoil";
import { getItemIDFromUrl, isTabletSize } from "utils";
import { localStorageKeys } from "../enums";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { setDBVariable } from "api-client-local";
import { Report } from "features/MappingTools/utils";

export const openMQPopupAtom = atom({
  key: "openMQPopup",
  default: false,
});
export const modalQualityAtom = atom<Report>({
  key: "report",
  default: {
    imported: 0,
    total: 0,
    percentage: 0,
  },
});

export const sidebarOpenedAtom = atom({
  key: localStorageKeys.sidebarOpened,
  default:
    localStorage.getItem(localStorageKeys.sidebarOpened) === "true"
      ? isTabletSize()
        ? false
        : true
      : false,
  effects: [
    ({ onSet }) => {
      onSet((value) => {
        if (!isTabletSize()) {
          localStorage.setItem(
            localStorageKeys.sidebarOpened,
            value !== null ? value.toString() : "false"
          );
        }
      });
    },
  ],
});

export const authHeaderAtom =
  atom<AxiosRequestConfig<AxiosRequestHeaders> | null>({
    key: "authHeader",
    default: null,
    effects: [
      ({ onSet }) => {
        onSet((value) => {
          setDBVariable("authHeader", value as string);
        });
      },
    ],
  });

export const buildingIdAtom = atom({
  key: "buildingId",
  default: getItemIDFromUrl("buildings"),
});

export const openUserSettingAtom = atom({
  key: "userSetting",
  default: false,
});
