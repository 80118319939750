import React from "react";
import clsx from "clsx";
import Tooltip from "components/Tooltip";
import { useTranslation } from "react-i18next";

const ExportInventoryItem = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  return (
    <div key={1} className="w-full flex flex-row items-center justify-between">
      <div
        className={clsx(
          disabled ? "text-gray-400" : "text-gray-900",

          "font-[400]"
        )}
      >
        {t("buildingInventory.exportInventory")}
      </div>
      {disabled && (
        <Tooltip content={t("buildingInventory.tooltipDisableMsg")} />
      )}
    </div>
  );
};

export default ExportInventoryItem;
