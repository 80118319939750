import React from "react";
import { IconSearch } from "@tabler/icons-react";
import clsx from "clsx";

export default function SearchField(props: {
  placeholder: string;
  onClick: () => void;
  hasError?: boolean;
}) {
  return (
    <div
      className={clsx(
        "mt-0.5 w-full border border-gray-300 h-9 rounded-md items-center flex justify-between px-2 text-gray-500 cursor-pointer",
        "whitespace-nowrap",
        { "border-red-700": props.hasError }
      )}
      onClick={props.onClick}
    >
      <span
        className={clsx("text-sm text-gray-400 pr-1", {
          "text-red-400": props.hasError,
        })}
      >
        {props.placeholder}
      </span>
      <IconSearch
        className={clsx({
          "text-red-600": props.hasError,
        })}
      />
    </div>
  );
}
