import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { IconCheck } from "@tabler/icons-react";
import { buildingStore } from "store/Building/BuildingStore";
import { isMobileSize } from "utils";

export default observer(function CalculationStatusIndicator() {
  const { t } = useTranslation();
  if (
    !buildingStore.buildingMappingStatus.hasReport ||
    !buildingStore.calculationsStatus.id ||
    isMobileSize()
  )
    return null;

  return (
    <div className="flex items-center ml-3 text-sm text-green-600">
      <div className="bg-green-100 rounded-full mr-2 p-1">
        <IconCheck size={15} stroke={3} />
      </div>
      <span>{t("dashboard.evaluationsAreUpToDate")}</span>
    </div>
  );
});
