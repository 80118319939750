import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import clsx from "clsx";

export interface TagsOption {
  id?: string | number;
  name: string;
}

interface IProps {
  items: TagsOption[];
  setItems?: (e: TagsOption[]) => void;
  regEx?: RegExp;
  placeholder?: string;
  labelName?: string;
  description?: string;
  addItem: (e: string) => void;
  removeItem: (e: string) => void;
}

const TagsInput = ({
  items,
  setItems,
  // eslint-disable-next-line no-useless-escape
  regEx,
  placeholder,
  labelName,
  description,
  addItem,
  removeItem,
}: IProps) => {
  const [tags, setTags] = useState<TagsOption[]>([]);
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    setItems && setItems([...tags]);
  }, [tags.length]);

  useEffect(() => {
    setTags([...items]);
  }, [items]);

  const handleChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setValue(value);
    if (!regEx) return;
    if (
      value.length > 0 &&
      value.length < 256 &&
      regEx &&
      regEx.test(value) === true
    ) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const onKeyDownPressed = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (
        value.length > 0 &&
        value.length < 256 &&
        regEx &&
        regEx.test(value) === true
      ) {
        saveItem(value);
      } else {
        setError(true);
      }
      if (!regEx) {
        saveItem(value);
        setError(false);
      }
    }
  };

  function saveItem(value: string | undefined) {
    if (value && !tags.find((item: TagsOption) => item.name === value)) {
      addItem
        ? addItem(value)
        : setTags((state) => [...state, { id: "", name: value }]);
    }
    setValue("");
  }

  const removeTag = (item: TagsOption) => {
    removeItem
      ? removeItem(String(item.id))
      : setTags([...tags.filter((tag) => tag.name !== item.name)]);
  };

  return (
    <div>
      {labelName && <span>{labelName}</span>}
      <div
        className={clsx(
          "appearance-none block w-full px-3 pt-2 border border-gray-300 rounded-md relative",
          "shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500",
          "focus:border-indigo-500 sm:text-sm appearance-none min-h-[52px] flex justify-start flex-wrap"
        )}
      >
        {tags.map((item: TagsOption, index: number) => (
          <div
            key={index}
            className={clsx(
              "text-sm py-2 pl-4 pr-3 items-center rounded-full",
              "bg-indigo-50 text-indigo-700 flex mr-2 mb-2"
            )}
          >
            {item.name}
            <XIcon
              width={18}
              className="ml-2 text-indigo-400 cursor-pointer"
              onClick={() => removeTag(item)}
            />
          </div>
        ))}
        <input
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          onKeyDown={(e: React.KeyboardEvent) => onKeyDownPressed(e)}
          className={clsx(
            "min-h-full focus:outline-none px-1 h-8 mb-2 border-none w-auto"
          )}
        />
        {value && (
          <div
            className={clsx(
              "absolute -bottom-10 border z-10 px-4 border-gray-300 rounded-md",
              "left-0 min-h-[36px] flex items-center bg-white w-full cursor-pointer",
              { "border-red-500": error && regEx }
            )}
            onClick={() => !error && saveItem(value)}
          >
            {value}
          </div>
        )}
      </div>
      {description && <div className="text-gray-500 pt-2">{description}</div>}
    </div>
  );
};

export default TagsInput;
