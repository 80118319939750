import { filledStepsAtom } from "components/Stepper";
import { useSetRecoilState } from "recoil";
import {
  addedComponentImagesAtom,
  addedComponentsAtom,
  addedImagesAtom,
  componentCurrentStepAtom,
  dimensionsValueAtom,
  generatedBlueprintNameAtom,
  manufacturerNameAtom,
  productGroupNameAtom,
  selectedColorAtom,
  selectedComponentProductGroupAtom,
  selectedManufacturerAtom,
  selectedProductGroupAtom,
  stepAtom,
} from "store/atoms/audits";

export default function useAuditFormReset() {
  const setStep = useSetRecoilState(stepAtom);
  const setComponentStep = useSetRecoilState(componentCurrentStepAtom);
  const setAddedComponents = useSetRecoilState(addedComponentsAtom);
  const setAddedImages = useSetRecoilState(addedImagesAtom);
  const setAddedComponentImages = useSetRecoilState(addedComponentImagesAtom);
  const setGeneratedBlueprintName = useSetRecoilState(
    generatedBlueprintNameAtom
  );

  const setManufacturerName = useSetRecoilState(manufacturerNameAtom);
  const setProductGroupName = useSetRecoilState(productGroupNameAtom);
  const setDimensionsValue = useSetRecoilState(dimensionsValueAtom);
  const setSelectedComponent = useSetRecoilState(
    selectedComponentProductGroupAtom
  );
  const setSelectedManufacturer = useSetRecoilState(selectedManufacturerAtom);
  const setSelectedProductGroup = useSetRecoilState(selectedProductGroupAtom);
  const setSelectedColor = useSetRecoilState(selectedColorAtom);
  const setFilledSteps = useSetRecoilState(filledStepsAtom);

  return () => {
    setSelectedProductGroup(null);
    setSelectedManufacturer(null);
    setSelectedComponent(null);
    setGeneratedBlueprintName("");
    setDimensionsValue("");
    setProductGroupName("");
    setManufacturerName("");
    setAddedImages(null);
    setAddedComponents({});
    setAddedComponentImages(null);
    setSelectedColor(undefined);
    setStep(0);
    setComponentStep(0);
    setFilledSteps([]);
  };
}
