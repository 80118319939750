import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  addedComponentImagesAtom,
  addedComponentsAtom,
  componentCurrentStepAtom,
  productGroupsAtom,
  selectedComponentAtom,
  selectedComponentProductGroupAtom,
  selectedProductGroupAtom,
} from "store/atoms/audits";
import { omit } from "utils";
import { ContinueButton, SkipButton } from "../navigation";
import ComponentListItem from "features/Audit/ComponentListItem";
import { BlueprintOUT, ProductGroupOUT } from "api-client";
import { useTranslation } from "react-i18next";
import GenericListItem from "features/Audit/GenericListItem";
import EditDeleteItem from "components/ActionMenu/EditDeleteItemMenu";
import { tryGenerateUrlForImage } from "api-client-local/images";

export default function AddComponents() {
  const { t } = useTranslation();
  const selectedProductGroup = useRecoilValue(selectedProductGroupAtom);
  const productGroups = useRecoilValue(productGroupsAtom);
  const setSelectedComponent = useSetRecoilState(selectedComponentAtom);
  const setSelectedComponentProductGroup = useSetRecoilState(
    selectedComponentProductGroupAtom
  );
  const setStep = useSetRecoilState(componentCurrentStepAtom);
  const addedComponents = useRecoilValue(addedComponentsAtom);
  const addedComponentImages = useRecoilValue(addedComponentImagesAtom);

  const requiredComponents = selectedProductGroup
    ? selectedProductGroup.required_components
    : null;
  const optionalComponents = selectedProductGroup
    ? selectedProductGroup.optional_components
    : null;

  const handleAddedComponentRouting = (
    component: BlueprintOUT,
    pg?: ProductGroupOUT
  ) => {
    setStep(1);
    setSelectedComponent(component);
    pg && setSelectedComponentProductGroup(pg);
  };

  return (
    <div className="grid gap-x-4 pb-16">
      {Object.keys(addedComponents).length === 0 && (
        <h2 className="text-xs uppercase leading-4 mb-2">Add Components</h2>
      )}

      {Object.keys(addedComponents).length > 0 && (
        <div className="pb-4">
          <div className="flex text-xs justify-between uppercase ">
            <h3>{t("audits.addedComponents")}</h3>
            <h3 className="mr-5">{t("audits.ratio")}</h3>
          </div>
          {Object.values(addedComponents).map((addedComponent) => {
            return (
              <AddedComponentListItem
                productGroups={productGroups}
                key={addedComponent.id}
                addedComponent={addedComponent}
                addedComponentImages={addedComponentImages}
                handleAddedComponentRouting={handleAddedComponentRouting}
              />
            );
          })}
        </div>
      )}
      <div>
        {requiredComponents &&
          requiredComponents.length > 0 &&
          requiredComponents.map((componentProductGroup) => (
            <ComponentListItem
              key={componentProductGroup.id}
              componentProductGroup={componentProductGroup}
              setStep={setStep}
              setSelectedComponentProductGroup={
                setSelectedComponentProductGroup
              }
            />
          ))}
        {optionalComponents &&
          optionalComponents.length > 0 &&
          optionalComponents.map((componentProductGroup) => (
            <ComponentListItem
              key={componentProductGroup.id}
              componentProductGroup={componentProductGroup}
              isOptional
              setStep={setStep}
              setSelectedComponentProductGroup={
                setSelectedComponentProductGroup
              }
            />
          ))}

        {(!optionalComponents || !requiredComponents) && (
          <div className="text-sm">
            No components found, you can skip this step.
          </div>
        )}
        <div className="flex justify-evenly pt-6 gap-4">
          <SkipButton />
          <ContinueButton />
        </div>
      </div>
    </div>
  );
}

interface AddedComponentListItemProps {
  addedComponent: BlueprintOUT;
  handleAddedComponentRouting: (
    component: BlueprintOUT,
    pg?: ProductGroupOUT
  ) => void;
  addedComponentImages: { [id: string]: File[] } | null;
  productGroups: undefined | ProductGroupOUT[];
}

function AddedComponentListItem({
  productGroups,
  addedComponent,
  addedComponentImages,
  handleAddedComponentRouting,
}: AddedComponentListItemProps) {
  const selectedProductGroup = useRecoilValue(selectedProductGroupAtom);
  const addedComponentProductGroup = productGroups?.find(
    (pg) => pg.id === selectedProductGroup?.id
  );
  const setAddedComponents = useSetRecoilState(addedComponentsAtom);
  const setAddedComponentImages = useSetRecoilState(addedComponentImagesAtom);

  const [itemImage, setItemImage] = React.useState<string>("");
  React.useEffect(() => {
    if (!addedComponentImages || !addedComponent) return;
    const componentImages =
      addedComponentImages[addedComponent.id] ?? undefined;
    if (!componentImages || componentImages.length === 0) return;
    setItemImage(tryGenerateUrlForImage(componentImages[0]) ?? "");
  }, [addedComponentImages]);

  const handleComponentDelete = React.useCallback(() => {
    setAddedComponents((prev) => {
      return omit(addedComponent.id, prev);
    });
    setAddedComponentImages((prev) => {
      if (!prev) return prev;
      return omit(addedComponent.id, prev);
    });
  }, [addedComponent]);

  const handleRouting = () => {
    handleAddedComponentRouting(addedComponent, addedComponentProductGroup);
  };

  return (
    <GenericListItem
      className="cursor-pointer"
      item={addedComponent}
      key={addedComponent.id}
      itemImageUrl={itemImage ?? ""}
      onClick={() => {
        handleAddedComponentRouting(addedComponent, addedComponentProductGroup);
      }}
    >
      <div className="text-xs">
        <span>{addedComponent.as_component_amount} </span>
        <span>
          {addedComponentProductGroup?.unit}/{selectedProductGroup?.unit}
        </span>
      </div>

      <EditDeleteItem
        onEdit={handleRouting}
        direction="bottom"
        id={addedComponent.id}
        onDelete={handleComponentDelete}
      />
    </GenericListItem>
  );
}
