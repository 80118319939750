import React from "react";
import { BlueprintOUT, ProductGroupOUT } from "api-client";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import ComponentListItemOnline from "features/AuditOnline/ComponentListItemOnline";
import { ContinueButton } from "../navigation/ContinueButton";
import AddedComponentListItem from "./AddedComponentListItem";
import SkipButton from "../navigation/SkipButton";

const AddComponents = () => {
  const { t } = useTranslation();

  const { selectedProductGroup, addedComponents, addedComponentImages } =
    blueprintCreateStore;
  const { productGroups } = caOnlineStore;

  const requiredComponents = selectedProductGroup
    ? selectedProductGroup.required_components
    : null;
  const optionalComponents = selectedProductGroup
    ? selectedProductGroup.optional_components
    : null;

  const handleAddedComponentRouting = (
    component: BlueprintOUT,
    pg?: ProductGroupOUT
  ) => {
    blueprintCreateStore.setComponentCurrentStep(1);
    blueprintCreateStore.setSelectedComponent(component);
    pg && blueprintCreateStore.setSelectedComponentProductGroup(pg);
  };

  return (
    <div className="grid gap-x-4 pb-16">
      {Object.keys(addedComponents).length === 0 && (
        <h2 className="text-xs uppercase leading-4 mb-2">Add Components</h2>
      )}

      {Object.keys(addedComponents).length > 0 && (
        <div className="pb-4">
          <div className="flex text-xs justify-between uppercase ">
            <h3>{t("audits.addedComponents")}</h3>
            <h3 className="mr-5">{t("audits.ratio")}</h3>
          </div>
          {Object.values(addedComponents).map((addedComponent, index) => {
            return (
              <AddedComponentListItem
                productGroups={productGroups}
                key={`added-${index}`}
                addedComponent={addedComponent}
                addedComponentImages={addedComponentImages}
                handleAddedComponentRouting={handleAddedComponentRouting}
              />
            );
          })}
        </div>
      )}
      <div>
        {requiredComponents &&
          requiredComponents.length > 0 &&
          requiredComponents.map((componentProductGroup) => (
            <ComponentListItemOnline
              key={componentProductGroup.id}
              componentProductGroup={componentProductGroup}
            />
          ))}
        {optionalComponents &&
          optionalComponents.length > 0 &&
          optionalComponents.map((componentProductGroup) => (
            <ComponentListItemOnline
              key={componentProductGroup.id}
              componentProductGroup={componentProductGroup}
              isOptional
            />
          ))}

        {(!optionalComponents || !requiredComponents) && (
          <div className="text-sm">
            No components found, you can skip this step.
          </div>
        )}
        <div className="flex justify-evenly pt-6 gap-4">
          <SkipButton />
          <ContinueButton />
        </div>
      </div>
    </div>
  );
};

export default observer(AddComponents);
