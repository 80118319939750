import DebouncedInput from "components/DebounceInput";
import React from "react";
import { observer } from "mobx-react-lite";

function MinMaxFilter({
  columnKey,
  handleChange,
}: {
  columnKey?: string;
  handleChange: (min: string | number, max: string | number) => void;
}) {
  const [minValue, setMinValue] = React.useState<string | number>("");
  const [maxValue, setMaxValue] = React.useState<string | number>("");

  React.useEffect(() => {
    handleChange(minValue, maxValue);
  }, [minValue, maxValue]);

  return (
    <>
      <div className="flex max-w-[300px] min-w-[120px] w-fit space-x-2 mt-2">
        <DebouncedInput
          name={columnKey + "_min"}
          showIcon={false}
          type="number"
          value={minValue ?? ""}
          onChange={(value) => setMinValue(value)}
          height="middle"
          placeholder="Min"
        />
        <DebouncedInput
          name={columnKey + "_max"}
          showIcon={false}
          type="number"
          value={maxValue ?? ""}
          onChange={(value) => setMaxValue(value)}
          height="middle"
          placeholder="Max"
        />
      </div>
    </>
  );
}

export default observer(MinMaxFilter);
