import React from "react";
import { useParams } from "react-router-dom";
import PingAnimation from "components/PingAnimation";
import { addValuesToFieldsFromProperties } from "./utils";
import GeneratedFields from "./GeneratedFields";
import useBlueprint from "hooks/useBlueprint";

export default function AuditItemFields(props: { filteredFields?: string[] }) {
  const { blueprint_id, instance_id } = useParams();
  const { selectedProductGroup, selectedBlueprint } =
    useBlueprint(blueprint_id);

  if (!selectedProductGroup || !selectedBlueprint) {
    return <PingAnimation color="gray" size="small" />;
  }

  const foundInstance = instance_id
    ? selectedBlueprint.instance_set.find(
        (instance) => instance.id === instance_id
      )
    : null;

  const requiredDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.required_fields.filter((f) =>
      props.filteredFields ? props.filteredFields.includes(f.id) === false : f
    ),
    foundInstance
      ? foundInstance.instanceproperty_set
      : selectedBlueprint.blueprintproperty_set
  );

  const optionalDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.optional_fields
      .filter((f) =>
        props.filteredFields ? props.filteredFields.includes(f.id) === false : f
      )
      .filter((f) => f.name !== "Condition"),
    foundInstance
      ? foundInstance.instanceproperty_set
      : selectedBlueprint.blueprintproperty_set
  );

  return (
    <GeneratedFields
      generatesDimensions
      requiredDefaultValues={requiredDefaultValues}
      optionalDefaultValues={optionalDefaultValues}
      required_fields={selectedProductGroup.required_fields.filter((f) =>
        props.filteredFields
          ? props.filteredFields.includes(f.name as string) === false
          : f
      )}
      optional_fields={selectedProductGroup.optional_fields.filter((f) =>
        props.filteredFields
          ? props.filteredFields.includes(f.name as string) === false
          : f
      )}
    />
  );
}
