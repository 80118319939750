import { CertificationOut } from "api-client";
import DropdownCheckbox from "components/DropdownCheckbox";
import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { searchStore } from "store/IfcMapping/SearchStore";

export default observer(function CertificationFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: CertificationOut[];
  setSelected: React.Dispatch<React.SetStateAction<CertificationOut[]>>;
  removingItem?: CertificationOut;
  handleRemoveItem?: () => void;
}) {
  const { t } = useTranslation();

  const onChange = (value: CertificationOut[]) => setSelected(value);

  React.useEffect(() => {
    (async () => {
      await ifcMappingStore.fetchCertificationData();
      searchStore.setFilterOptions({
        ...searchStore.filtersOptions,
        certifications: ifcMappingStore.certificationOptions.filter(
          (item) => item.name !== ""
        ),
      });
    })();
  }, []);

  if (!searchStore.filtersOptions.certifications) return <></>;
  return (
    <DropdownCheckbox<CertificationOut>
      displayKey={"name"}
      required={true}
      handleSelect={onChange}
      labelName={t("mapping.certification")}
      name="certifications"
      items={searchStore.filtersOptions.certifications.filter(
        (item) => item.name !== "Keine"
      )}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px]"
      checkedItems={selected}
      optionsClassName="z-40 !max-w-fit"
      noMargin
    />
  );
});
