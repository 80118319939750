import {
  AuditOUT,
  BlueprintOUT,
  InstanceOUT,
  ProductGroupOUT,
} from "api-client";
import React from "react";
import { useParams } from "react-router";
import { useRecoilValue } from "recoil";
import {
  currentAuditAtom,
  manufacturersAtom,
  productGroupsAtom,
} from "store/atoms/audits";

interface UseBlueprintState {
  selectedBlueprint: BlueprintOUT | undefined;
  selectedProductGroup: ProductGroupOUT | undefined;
  selectedInstance: InstanceOUT | undefined;
  isComponent: boolean;
}

export default function useBlueprint(blueprint_id: string | undefined) {
  const { instance_id } = useParams();
  const productGroups = useRecoilValue(productGroupsAtom);
  const manufacturers = useRecoilValue(manufacturersAtom);
  const audit = useRecoilValue(currentAuditAtom);
  const [blueprintState, setBlueprintState] = React.useState<UseBlueprintState>(
    getBlueprintState(audit, blueprint_id, productGroups, instance_id)
  );

  React.useEffect(() => {
    if (!audit || !blueprint_id) return;
    const nextState = getBlueprintState(
      audit,
      blueprint_id,
      productGroups,
      instance_id
    );
    setBlueprintState(nextState);
  }, [audit, productGroups, blueprint_id, manufacturers, instance_id]);

  return {
    blueprint_id,
    audit,
    productGroups,
    ...blueprintState,
  };
}

function checkIfBlueprintIsComponent(blueprint: BlueprintOUT, audit: AuditOUT) {
  const allComponents = audit.blueprint_set.map((b) => b.components).flat(2);
  const foundAsComponent = allComponents.find((c) => c?.id === blueprint.id);
  return !!foundAsComponent;
}

function getBlueprintState(
  audit: AuditOUT | null,
  blueprint_id: string | undefined,
  productGroups: ProductGroupOUT[] | undefined,
  instance_id: string | undefined
) {
  let blueprintState: UseBlueprintState = {
    selectedBlueprint: undefined,
    selectedProductGroup: undefined,
    isComponent: false,
    selectedInstance: undefined,
  };
  if (!audit) return blueprintState;
  let foundBlueprint = audit.blueprint_set.find((bp) => bp.id === blueprint_id);
  let parentProductGroupId = foundBlueprint?.product_group_id;
  if (!foundBlueprint) {
    // found the culprint here for the components
    console.log(
      `Checking if Blueprint ID belongs to a component ${blueprint_id}`
    );
    for (const bp of audit.blueprint_set) {
      if (bp.components && bp.components.length > 0) {
        for (const comp of bp.components) {
          if (comp.id === blueprint_id) {
            parentProductGroupId = bp.product_group_id;
            foundBlueprint = comp;
            break;
          }
        }
      }
    }
  }
  const foundBlueprintIsComponent = foundBlueprint
    ? checkIfBlueprintIsComponent(foundBlueprint, audit)
    : false;

  let foundProductGroup =
    foundBlueprint && productGroups
      ? productGroups.find((el) => el.id === foundBlueprint?.product_group_id)
      : undefined;

  if (foundBlueprintIsComponent) {
    foundProductGroup =
      foundBlueprint && productGroups
        ? productGroups.find((el) => el.id === parentProductGroupId)
        : undefined;
  }

  if (!foundProductGroup) {
    console.log(
      `product_group_id not found ${foundBlueprint?.product_group_id}`
    );
  }

  const foundInstance = foundBlueprint
    ? foundBlueprint.instance_set.find((e) => e.id === instance_id)
    : undefined;

  blueprintState = {
    selectedBlueprint: foundBlueprint,
    selectedProductGroup: foundProductGroup,
    isComponent: foundBlueprintIsComponent,
    selectedInstance: foundInstance,
  };

  return blueprintState;
}
