import React from "react";
import QNGLogo from "assets/images/qng-logo.svg";
import DGNBLogo from "assets/images/DGNB.svg";
import { ArrowRightIcon } from "@heroicons/react/outline";
import { certExportStore } from "store/CertExportStore";

const options = [
  {
    id: "qng-1",
    title: "Qualitätssiegel Nachhaltiges Bauen (QNG)",
    icon: `${QNGLogo}`,
  },
  {
    id: "dgnb-1",
    title: "Deutsche Gesellschaft für Nachhaltiges Bauen (DGNB)",
    icon: `${DGNBLogo}`,
  },
];

const ChooseCertSystem = () => {
  const handleClick = (value: string) => {
    certExportStore.setCertExportFieldAndValue("certSystem", value);
    if (value.includes("QNG")) {
      certExportStore.setCertAndConformityId("QNG");
      certExportStore.setActiveStep(certExportStore.activeStep + 3);
    } else {
      certExportStore.setActiveStep(certExportStore.activeStep + 1);
    }
  };

  return (
    <>
      {options.map((option) => (
        <div
          onClick={() => handleClick(option.title)}
          key={option.id}
          className="hover:bg-gray-50 cursor-pointer flex flex-col border border-gray-300 mb-4 last-of-type:mb-0 rounded-md w-full p-4 items-center justify-center"
        >
          <img src={option.icon} alt={option.id} />
          <span className="w-full text-right text-gray-700 mt-8 items-center justify-end flex">
            {option.title} <ArrowRightIcon className="ml-4" width={16} />
          </span>
        </div>
      ))}
    </>
  );
};

export default ChooseCertSystem;
