import React from "react";
import Input from "components/Input";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import Checkbox from "components/Checkbox";
import InputLabel from "components/InputLabel";
import TagsInput from "components/TagsInput";

const Step1 = () => {
  const { t } = useTranslation();

  const addTag = (value: string) =>
    componentsStore.onChangeData("tags_automapper", [
      ...componentsStore.data.tags_automapper,
      {
        id: String(componentsStore.data.tags_automapper.length),
        name: value,
      },
    ]);

  const removeTag = (value: string) =>
    componentsStore.onChangeData(
      "tags_automapper",
      componentsStore.data.tags_automapper.filter((item) => item.id !== value)
    );

  return (
    <>
      <div className="text-lg font-semibold mb-4 mt-2">
        {t("productEditor.generalInformation")}
      </div>
      <div className="bg-white border-gray-300 border p-6 rounded-md">
        <Input
          name="name"
          labelName={`${t("productEditor.name")}*`}
          placeholder={t("productEditor.enterName")}
          onChange={(value: string | undefined, name?: string) =>
            componentsStore.onChangeData(name as string, value)
          }
          value={componentsStore.data.name}
        />
        <Checkbox
          labelElement={t("components.ifc_is_external")}
          onChange={(e) =>
            componentsStore.onChangeData(
              "ifc_is_external",
              e.currentTarget.checked
            )
          }
          checked={componentsStore.data.ifc_is_external}
          id="ifc_is_external"
        />
        <Checkbox
          labelElement={t("components.ifc_is_loadbearing")}
          onChange={(e) =>
            componentsStore.onChangeData(
              "ifc_is_loadbearing",
              e.currentTarget.checked
            )
          }
          checked={componentsStore.data.ifc_is_loadbearing}
          id="ifc_is_loadbearing"
        />
        <div className="mt-3">
          <InputLabel
            labelName={t("components.tags_automapper")}
            name="tags_automapper"
          />
          <TagsInput
            items={componentsStore.data.tags_automapper}
            addItem={addTag}
            removeItem={removeTag}
            placeholder={t("productEditor.addTags")}
          />
          <div className="text-gray-500 text-sm mt-1.5">
            {t("components.autoMappingHint")}
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(Step1);
