import ButtonAsDiv from "components/ButtonAsDiv";
import { validateStep } from "features/AuditOnline/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

export const ContinueButton = React.forwardRef(
  (_props, ref: React.Ref<HTMLDivElement>) => {
    const { t } = useTranslation();
    const { filledSteps, step } = blueprintCreateStore;

    return (
      <ButtonAsDiv
        ref={ref}
        className="w-full md:w-auto"
        onClick={() => {
          const validated = validateStep(step);
          if (validated) {
            const newFilledSteps = Array.from(new Set([...filledSteps, step]));
            blueprintCreateStore.setFilledSteps(newFilledSteps);
          }
        }}
      >
        {t("audits.continue")}
      </ButtonAsDiv>
    );
  }
);

ContinueButton.displayName = "ContinueButton";
