import { makeAutoObservable } from "mobx";
import { AddEditBuildingForm, CompliantObjectsProps } from "./types";

export class BuildingSettingsStore {
  buildingFormDetails: AddEditBuildingForm = {};
  showMandatoryAlert = false; //showMandatoryAlert is being used to show an alert for mandatory fields in the add building form
  imagesToRemove: string[] = [];
  openEditBuilding = false;
  compliantObjects: CompliantObjectsProps = {};
  buildingAlreadyUpdated: boolean = false;

  updateBuildingDetails(buildingFormDetails: AddEditBuildingForm) {
    this.buildingFormDetails = { ...buildingFormDetails };
  }

  setshowMandatoryAlert(showMandatoryAlert: boolean) {
    this.showMandatoryAlert = showMandatoryAlert;
  }

  setCompliantObjects(compliantObjects: CompliantObjectsProps) {
    this.compliantObjects = compliantObjects;
  }

  setImagesToRemove(imagesToRemove: string[]) {
    this.imagesToRemove = imagesToRemove;
  }

  setOpenEditBuilding(openEditBuilding: boolean) {
    this.openEditBuilding = openEditBuilding;
  }

  setBuildingAlreadyUpdated(buildingAlreadyUpdated: boolean) {
    this.buildingAlreadyUpdated = buildingAlreadyUpdated;
  }

  static instance: BuildingSettingsStore;

  constructor() {
    makeAutoObservable(this);
  }

  static getInstance(): BuildingSettingsStore {
    if (!BuildingSettingsStore.instance) {
      BuildingSettingsStore.instance = new BuildingSettingsStore();
    }
    return BuildingSettingsStore.instance;
  }
}

export const buildingSettingsStore = BuildingSettingsStore.getInstance();
