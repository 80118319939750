import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  generatedBlueprintNameAtom,
  dimensionsValueAtom,
  manufacturerNameAtom,
  productGroupNameAtom,
} from "store/atoms/audits";
import TextInput from "components/TextInput";
import TextArea from "components/TextArea";
import { BackButton, SubmitButton } from "../navigation";
import { useTranslation } from "react-i18next";

export default function Step4() {
  const { t } = useTranslation();
  const [name, setName] = useRecoilState(generatedBlueprintNameAtom);
  const manufacturerName = useRecoilValue(manufacturerNameAtom);
  const productGroupName = useRecoilValue(productGroupNameAtom);
  const dimensionsValue = useRecoilValue(dimensionsValueAtom);

  return (
    <div className="">
      <TextInput
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setName(e.target.value);
        }}
        spellCheck="false"
        onBlur={() => {
          if (name === "") {
            setName(
              `${productGroupName ?? ""} ${manufacturerName ?? ""} ${
                dimensionsValue ?? ""
              }`.trim()
            );
          }
        }}
        className="py-5"
        value={name ? name.replace(/\s+/g, " ") : ""}
        labelName="Name"
        name="descriptive_name"
      />
      <div className=" text-gray-700 grid mb-4">
        <span className="mb-1 text-xs">{t("audits.generatedName")}</span>
        <div className="p-2 h-9 text-sm bg-gray-200 rounded-sm py-2  text-gray-400">
          {name}
        </div>
      </div>
      <TextArea name="extra_info" labelName="More Specifications" />
      <div className="flex justify-evenly py-6">
        <BackButton />
        <SubmitButton />
      </div>
    </div>
  );
}
