import { AxiosResponse } from "axios";
import {
  AvailableColumnsOUT,
  ColumnValueSchemaIN,
  FriendlyNameGroupOUT,
  summaryAPI,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { CustomFilterItem } from "components/Table/BeFilter";
import { makeAutoObservable } from "mobx";
import { SortingState } from "@tanstack/react-table";

export const INVENTORY_FIXED_COLUMN = "object_name";
export interface Category extends FriendlyNameGroupOUT {
  visibility: { [key: string]: boolean };
}

class InventoryStore {
  categories: Category[] = [];
  columns: AvailableColumnsOUT[] = [];
  data: { [key: string]: string }[] = [];
  sortedData: CustomFilterItem[] = [];
  limit = 100;
  offset = 0;
  totalCount = 0;
  loadingMore = false;
  buildingId = "";
  defaultColumnFilterVal = {
    [INVENTORY_FIXED_COLUMN]: "",
  };
  columnFilters: {
    [x: string]: string | number;
  } = this.defaultColumnFilterVal;
  filterValues: { [key: string]: string }[] = [];
  filterLoading = true;
  sorting: SortingState = [];
  debugColumnsList: string[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setBuildingId(buildingId?: string) {
    this.buildingId = buildingId ?? "";
  }

  setData(data: { [key: string]: string }[]) {
    this.data = data;
  }

  setFilterValues(filterValues: { [key: string]: string }[]) {
    this.filterValues = filterValues;
  }

  setFiltersLoading(filtersLoading: boolean) {
    this.filterLoading = filtersLoading;
  }

  setOffset(offset: number) {
    this.offset = offset;
  }

  setTotalCount(totalCount: number) {
    this.totalCount = totalCount;
  }

  setLoadingMore(loading: boolean) {
    this.loadingMore = loading;
  }

  setCategories(categories: Category[]) {
    this.categories = categories;
  }

  setColumns(columns: AvailableColumnsOUT[]) {
    this.columns = columns;
  }

  setSorting(sorting: SortingState) {
    this.sorting = sorting;
  }

  setSearchFilters(colKey: string, value: string) {
    const filter = { [colKey]: value };
    this.columnFilters = filter;
  }

  setMinMaxFilters(colKey: string, min: number | string, max: string | number) {
    const minKey = `${colKey}__from`;
    const maxKey = `${colKey}__until`;
    const filters: { [key: string]: number | string } = {};

    if (min != "") {
      filters[minKey] = min;
    }

    if (max != "") {
      filters[maxKey] = max;
    }

    this.columnFilters = filters;
  }

  resetInventoryFilters() {
    this.columnFilters = this.defaultColumnFilterVal;
  }

  setColumnsVisibility() {
    this.categories.forEach((category: Category) => {
      category.visibility = category.columns
        .map((column) => ({
          [column.property_name]:
            column.property_name === INVENTORY_FIXED_COLUMN
              ? true
              : column.in_default_view,
        }))
        .reduce((r, c) => Object.assign(r, c), {});
    });
  }

  setDebugColumnsList(debugColumnsList: string[]) {
    this.debugColumnsList = debugColumnsList;
  }

  async fetchColumns() {
    try {
      await this.fetchGroups();
      const columns = this.categories.map((item) => item.columns).flat(1);
      this.setColumns(columns);
      await this.fetchColumnsFiltersItems();
    } catch (error) {
      console.error("Failed to fetch columns:", error);
    }
  }

  async fetchSummaryAPIResponse(filterColumnName: {
    [key: string]: string | string[] | number;
  }) {
    if (!this.buildingId) {
      console.log(
        "Building ID not available or undefined! @InventoryStore: --> fetchSummaryAPIResponse",
        "Probably due to page refresh until all resources being loaded from context or Local States"
      );
      return null;
    }

    const sort: { [key: string]: string } | undefined = this.sorting?.length
      ? {
          value: this.sorting[0].id,
          direction: this.sorting[0].desc ? "DESC" : "ASC",
        }
      : undefined;

    try {
      const requestBody = {
        columns: this.columns.map((item) => item.property_name),
        sort_by: sort?.value,
        sort_direction: sort?.direction,
        filters: {
          building_id: this.buildingId,
          ...filterColumnName,
        },
      };

      const authHeader = await getLocalAuthHeader();
      const dataResponse =
        await summaryAPI.inventoryApiV1RoutersSummaryGetInfoTable(
          requestBody,
          this.limit,
          this.offset,
          authHeader
        );

      return dataResponse.data;
    } catch (error) {
      console.error("Failed to fetch data:", error);
      return null;
    }
  }

  getConvertedData(items: Array<object>) {
    return items.map((item: object) => {
      const convertedItem: { [key: string]: string } = {};
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          convertedItem[key] = (item as { [key: string]: string })[key];
        }
      }
      return convertedItem;
    });
  }

  async fetchColumnData(filterColumnName: { [key: string]: string | number }) {
    this.setOffset(0);
    const dataResponse = await this.fetchSummaryAPIResponse(filterColumnName);
    if (dataResponse) {
      const { items, count } = dataResponse;
      this.setData(this.getConvertedData(items));
      this.setTotalCount(count);
    }
  }

  async fetchColumnsFiltersItems() {
    const authHeader = await getLocalAuthHeader();
    const textColumns = this.columns
      .filter((item) => item.filter_function === "Text")
      .map((item) => item.property_name);
    const filterColumns = textColumns.flat(1);
    const columnValueSchemaIN: ColumnValueSchemaIN = {
      columns: filterColumns,
      building_id: this.buildingId,
    };
    await summaryAPI
      .inventoryApiV1RoutersSummaryGetColumnValueDistinct(
        columnValueSchemaIN,
        authHeader
      )
      .then((response: AxiosResponse) => {
        this.setFilterValues(response.data);
        this.setFiltersLoading(false);
      })
      .catch((err) => console.log(err, "Error on fetchSummaryFiltersItems"));
  }

  async loadMoreData(filterColumnName: { [key: string]: string | number }) {
    if (this.offset >= this.totalCount) {
      return;
    }

    this.setLoadingMore(true);
    const dataResponse = await this.fetchSummaryAPIResponse(filterColumnName);
    if (dataResponse) {
      const { items } = dataResponse;
      const convertedItems = this.getConvertedData(items);
      if (this.offset) {
        this.setData([...this.data, ...convertedItems]);
      } else {
        this.setData([...convertedItems]);
      }
      this.setLoadingMore(false);
    }
  }

  async exportSummary(fileType: string, columns: string[]) {
    const authHeader = await getLocalAuthHeader();
    const columnValueSchema = {
      columns: columns,
      building_id: this.buildingId,
    };
    return await summaryAPI
      .inventoryApiV1RoutersSummaryExportObjectCalculationCsv(
        fileType,
        columnValueSchema,
        authHeader
      )
      .then((response: AxiosResponse) => response.data.url)
      .catch((err) => console.error("error.exportSummary", err));
  }

  async fetchGroups() {
    const authHeader = await getLocalAuthHeader();
    await summaryAPI
      .inventoryApiV1RoutersSummaryGetFriendlyNameGroups(authHeader)
      .then((response: AxiosResponse) => {
        const categories = response.data.map((item: Category) => {
          item.columns = item.columns.filter((item) => item.is_available);
          return item;
        });
        this.setCategories(categories);
        this.setColumnsVisibility();
      })
      .catch((err) => console.error("error.SummaryGetFriendlyNameGroups", err));
  }

  static instance: InventoryStore;

  static getInstance(): InventoryStore {
    if (!InventoryStore.instance) {
      InventoryStore.instance = new InventoryStore();
    }
    return InventoryStore.instance;
  }
}

export const inventoryStore = InventoryStore.getInstance();
