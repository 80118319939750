import React from "react";
import { IconRefresh } from "@tabler/icons-react";
import Button from "components/Button";
import Heading from "components/Heading";
import ExportTable from "features/Building/BuildingExports/ExportTable";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { skeleton } from "utils";
import { buildingStore } from "store/Building/BuildingStore";

const Exports = observer(() => {
  const { t } = useTranslation();

  React.useEffect(() => {
    handleUpdate();
    return () =>
      buildingStore.setExportedCertifications({ loading: false, items: [] });
  }, [buildingStore.buildingID]);

  const handleUpdate = async () => {
    await buildingStore.getBuildingExportCertifications().then((data) => {
      const items = data?.map((item) => {
        return {
          ...item,
          filename: item.filename?.split("/").slice(-1)[0],
        };
      });
      items &&
        buildingStore.setExportedCertifications({
          loading: false,
          items: items,
        });
    });
  };

  function headerSection() {
    return (
      <div className="md:flex justify-between">
        <div>
          <Heading size="title">{t("exports.title")}</Heading>
          <Heading size="subtitle">{t("exports.subtitle")}</Heading>
        </div>
        <Button
          type="secondary"
          leadingIcon={<IconRefresh width={20} />}
          width="fit-content"
          className="ml-4"
          onClick={handleUpdate}
          testID="update_exports"
        >
          {t("exports.updateList")}
        </Button>
      </div>
    );
  }

  return (
    <div className="p-6">
      {headerSection()}
      {buildingStore.exportedCertifications.loading ? (
        skeleton()
      ) : (
        <ExportTable />
      )}
    </div>
  );
});

export default Exports;
