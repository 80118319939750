import React from "react";
import { observer } from "mobx-react-lite";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";
import { IconExclamationMark } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

export default observer(function ErrorMessages({
  datasetIndex,
}: {
  datasetIndex: number;
}) {
  const { t } = useTranslation();

  const epdValidationError = () => {
    return buildingProductStore.hasEpdValidationError(datasetIndex);
  };

  const factorValidationError = () => {
    return buildingProductStore.hasFactorValidationError(datasetIndex);
  };

  const materialValidationError = () => {
    return buildingProductStore.hasMaterialValidationError(datasetIndex);
  };

  const anyErrors = () => {
    return (
      epdValidationError() ||
      factorValidationError() ||
      materialValidationError()
    );
  };

  function checkOtherErrors() {
    return factorValidationError() ? (
      <>
        {t("productEditor.factorEmptyError")}
        <br />
      </>
    ) : (
      hasMaterialError()
    );
  }

  function hasMaterialError() {
    return materialValidationError() ? (
      <>
        {t("productEditor.materialEmptyError")}
        <br />
      </>
    ) : null;
  }

  if (!anyErrors()) return <></>;
  return (
    <div className="text-red-700 text-sm flex items-center pt-2 pb-1">
      <IconExclamationMark
        width={15}
        className="mr-3 bg-red-100 rounded-full h-6 w-6 p-1"
      />
      {epdValidationError() ? (
        <>
          {t("productEditor.epdEmptyError")}
          <br />
        </>
      ) : (
        checkOtherErrors()
      )}
    </div>
  );
});
