import React, { useMemo } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { roundNumber } from "utils";
import { b6ImporterStore } from "store/B6ImporterStore";

const BOLD_CELL = "Summe B6";

const useB6ImporterTableColumns = () => {
  const { t } = useTranslation();

  const calculateValue = (yearly_value: string, cellValue: string) => {
    const roundedNumber = roundNumber(Number(cellValue));
    return (
      <span className={yearly_value === BOLD_CELL ? "font-semibold" : ""}>
        {roundedNumber}
      </span>
    );
  };

  function headerCell(title: string, subtitle: string) {
    return (
      <div className="whitespace-nowrap">
        {title}
        <br />
        <span className="text-xs text-gray-500">{`${subtitle}/${
          b6ImporterStore.reportType?.id === "imported" ? "a" : "(m²a)"
        }`}</span>
      </div>
    );
  }

  return useMemo<ColumnDef<{ [key: string]: string }>[]>(
    () => [
      {
        accessorFn: (row) => row.yearly,
        id: "yearly",
        cell: (info) => (
          <div
            className={`whitespace-nowrap ${
              info.row.original.yearly === BOLD_CELL ? "font-semibold" : ""
            }`}
          >
            {info.row.original.yearly}
          </div>
        ),
        header: () => <div>{t("b6Importer.annually")}</div>,
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.energy_category,
        id: "energy_category",
        cell: (info) => (
          <span
            className={
              info.row.original.yearly === BOLD_CELL ? "font-semibold" : ""
            }
          >
            {info.row.original.energy_category}
          </span>
        ),
        header: () => "",
        enableColumnFilter: false,
        enableSorting: false,
        maxWidth: 250,
      },
      {
        accessorFn: (row) => row.energy,
        id: "energy",
        cell: (info) =>
          calculateValue(info.row.original.yearly, info.row.original.energy),
        header: () => headerCell(t("b6Importer.energy"), "kWh"),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.pe_penrt,
        id: "pe_penrt",
        cell: (info) =>
          calculateValue(info.row.original.yearly, info.row.original.pe_penrt),
        header: () => headerCell("PE, ne (PENRT)", "kWh"),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        accessorFn: (row) => row.gwp,
        id: "gwp",
        cell: (info) =>
          calculateValue(info.row.original.yearly, info.row.original.gwp),
        header: () => headerCell("GWP", "kg CO2e"),
        enableColumnFilter: false,
        enableSorting: false,
      },
    ],
    []
  );
};

export default useB6ImporterTableColumns;
