import React from "react";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import ActionMenu from ".";
import { useTranslation } from "react-i18next";

interface ItemFilterMenuProps {
  onSelect: (i: number) => void;
}

export default function ItemFilterMenu(props: ItemFilterMenuProps) {
  const { t } = useTranslation();
  return (
    <div className="relative flex w-fit items-center z-40">
      <ActionMenu
        className="text-xs mb-2"
        icon={
          <div className="flex">
            <AdjustmentsIcon className="mr-2" width={16} />
            <span className="mr-2">{t("audits.sortBy")}</span>
          </div>
        }
        direction="bottom-right"
        items={[
          <div className="text-xs" key={0}>
            {t("audits.date")} <small>({t("audits.newToOld")})</small>
          </div>,
          <div className="text-xs" key={1}>
            {t("audits.date")} <small>({t("audits.oldToNew")})</small>
          </div>,
          <div className="text-xs" key={2}>
            Name (A-Z)
          </div>,
          <div className="text-xs" key={3}>
            Name (Z-A)
          </div>,
        ]}
        onSelect={(i) => props.onSelect(i)}
      />
    </div>
  );
}
