import React, { useState } from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import Modal, { ModalProps } from "components/Modal";
import clsx from "clsx";
import { IconInfoCircle, IconX } from "@tabler/icons-react";
import { ExclamationIcon } from "@heroicons/react/outline";

interface ConfirmModalProps extends ModalProps {
  title: string;
  descriptionMesage: string;
  confirmButtonTitle: string;
  onConfirm: () => Promise<void> | void;
  type?: "warning" | "info" | "error" | "success";
  buttonClassName?: string;
  containerClassName?: string;
  hideCancel?: boolean;
  cancelTitle?: string;
}

export default function ConfirmModal({
  open,
  close,
  title,
  descriptionMesage,
  confirmButtonTitle,
  onConfirm,
  type = "warning",
  buttonClassName,
  containerClassName,
  hideCancel,
  cancelTitle,
}: ConfirmModalProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const confirm = async () => {
    setLoading(true);
    await onConfirm?.();
    setLoading(false);
    close();
  };

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      containerClassName={clsx("md:min-w-[500px]", containerClassName)}
      inProgress={loading}
      closeButton={false}
      footer={
        <div className="flex w-full justify-end">
          {!hideCancel ? (
            <div className="mr-6">
              {!loading && (
                <Button width="fit-content" type="gray" onClick={close}>
                  {cancelTitle ?? t("addBuilding.cancel")}
                </Button>
              )}
            </div>
          ) : null}
          <Button
            width="fit-content"
            type="primary"
            onClick={confirm}
            loading={loading}
            className={buttonClassName}
          >
            {confirmButtonTitle}
          </Button>
        </div>
      }
    >
      <div className="p-6 flex w-full">
        <div>
          <span
            className={clsx("min-w-fit block p-2 rounded-full", {
              "bg-yellow-100": type === "warning",
            })}
          >
            {type === "warning" && (
              <ExclamationIcon
                width={24}
                className="text-yellow-600"
                strokeWidth={3}
              />
            )}
            {type === "info" && (
              <IconInfoCircle
                width={40}
                className="text-indigo-600 bg-indigo-100 w-10 h-10 rounded-full p-2.5"
                stroke={3}
              />
            )}
          </span>
        </div>
        <div className="text-gray-700 text-sm font-normal pl-4">
          <div className="text-gray-900 text-lg font-semibold pb-2 flex justify-between">
            <span>{title}</span>
            <div>
              <IconX
                className={
                  "!w-7 !h-7 px-0 text-gray-500 hover:bg-gray-200 rounded-full p-1 transition cursor-pointer"
                }
                onClick={close}
              />
            </div>
          </div>
          {descriptionMesage}
        </div>
      </div>
    </Modal>
  );
}
