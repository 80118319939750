import React from "react";
import RevitIcon from "assets/images/icons/revit.svg";
import { IconDownload } from "@tabler/icons-react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

export default function RevitPluginDashboardTile() {
  const { t, i18n } = useTranslation();

  const downloadPlugin = () =>
    window.location.replace(import.meta.env.VITE_REVIT_APP as string);

  return (
    <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden">
      <div className="text-base text-gray-900 font-semibold p-4">
        {t("revit.revitPlugin")}
      </div>
      <div className="flex p-4 items-start">
        <img src={RevitIcon} className="min-w-[112px]" />
        <div className="text-sm font-medium text-gray-700 pl-4">
          {i18n
            .t<{ title: string; desc: string }[]>("revit.dashboard", {
              returnObjects: true,
            })
            .map((item) => (
              <div className="mb-2" key={item.title}>
                <div className="text-gray-900 text-sm font-semibold">
                  {item.title}
                </div>
                <div className="text-gray-700 text-sm font-normal">
                  {item.desc}
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="flex items-center justify-end bg-gray-50 p-3 text-base cursor-pointer">
        <Button
          type="secondary"
          leadingIcon={<IconDownload />}
          width="fit-content"
          onClick={downloadPlugin}
          testID="downloadPluginDashboard_button"
        >
          {t("revit.downloadPlugin")}
        </Button>
      </div>
    </div>
  );
}
