import React from "react";
import { DropdownOption, DropdownWithSearchProps } from ".";
import { Combobox } from "@headlessui/react";
import clsx from "clsx";
import { IconMinus, IconPlus } from "@tabler/icons-react";

interface OptionItem<T> extends Omit<DropdownWithSearchProps<T>, "items"> {
  item: T;
  index: number;
  handleOnClick: (item: T) => void;
  query: string | number;
  hasChildren?: boolean;
}

interface TreeViewNode {
  id: string;
  name?: string;
  isOpen: boolean;
  childrenNodes?: TreeViewNode[];
}

function OptionItem<Item extends object>(props: OptionItem<Item>) {
  const { item, index, handleOnClick, query } = props;

  const createNodeElement = (item?: DropdownOption): TreeViewNode => {
    return {
      ...item,
      id: item?.id as string,
      name: item?.name,
      isOpen: false,
      childrenNodes: item?.children?.map((rec) => createNodeElement(rec)) ?? [],
    };
  };

  React.useEffect(() => {
    setTreeNode(createNodeElement(item as DropdownOption));
  }, [props.query]);

  const [treeNode, setTreeNode] = React.useState<TreeViewNode>(
    createNodeElement(item as DropdownOption)
  );

  const toggleNode = (clickedNode: TreeViewNode) => {
    setTreeNode((prevTreeData) => {
      if (prevTreeData === clickedNode) {
        return { ...prevTreeData, isOpen: !prevTreeData.isOpen };
      } else if (
        prevTreeData.childrenNodes &&
        prevTreeData.childrenNodes.length > 0
      ) {
        return {
          ...prevTreeData,
          childrenNodes: toggleNodeInTree(
            prevTreeData.childrenNodes,
            clickedNode
          ),
        };
      } else {
        return prevTreeData;
      }
    });
  };

  const toggleNodeInTree = (
    childrenNodes: TreeViewNode[],
    clickedNode: TreeViewNode
  ): TreeViewNode[] => {
    return childrenNodes.map((child) => {
      if (child === clickedNode) {
        return { ...child, isOpen: !child.isOpen };
      } else if (child.childrenNodes && child.childrenNodes.length > 0) {
        return {
          ...child,
          childrenNodes: toggleNodeInTree(child.childrenNodes, clickedNode),
        };
      } else {
        return child;
      }
    });
  };

  function itemContent(i: number, item: Item) {
    return (
      <Combobox.Option
        key={i}
        className={clsx(
          "relative py-0.5",
          props.disabled ? "cursor-not-allowed" : "cursor-pointer"
        )}
        value={item}
        disabled={props.disabled}
        onClick={() => handleOnClick(item)}
      >
        {({ selected }) => (
          <>
            <label
              className={clsx(
                "flex truncate items-center",
                "group/dropdownItem text-gray-700 hover:bg-indigo-50 py-2 px-4 hover:text-indigo-700",
                selected ? "font-medium" : "font-normal",
                props.disabled
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-100 cursor-pointer"
              )}
            >
              <div className={clsx("flex items-center justify-between w-full")}>
                {(item as unknown as DropdownOption).icon && (
                  <div className="mr-2 min-w-[16px] flex justify-center">
                    {(item as unknown as DropdownOption).icon}
                  </div>
                )}
                <span>
                  {props.displayKey in item
                    ? (item[props.displayKey] as unknown as string)
                    : null}
                </span>
                <span className="text-gray-500 group-hover/dropdownItem:text-indigo-500 ml-3">
                  {(item[props.detailKey as keyof Item] as string) ?? ""}
                </span>
              </div>
            </label>
          </>
        )}
      </Combobox.Option>
    );
  }

  return (
    <>
      <div
        className={clsx(
          "flex cursor-pointer items-center hover:bg-gray-100",
          props.hasChildren && !query ? "pl-3" : ""
        )}
      >
        <div
          className={clsx(props.hasChildren && !query ? "min-w-[18px]" : "")}
        >
          {treeNode.childrenNodes?.length && !query ? (
            <span
              className="cursor-pointer text-gray-500"
              onClick={() => toggleNode(treeNode)}
            >
              {treeNode.isOpen ? (
                <IconMinus width={18} height={18} />
              ) : (
                <IconPlus width={18} height={18} />
              )}
            </span>
          ) : null}
        </div>
        <div className="flex overflow-hidden w-full">
          <div className="text-gray-800 text-sm font-medium truncate w-full">
            {itemContent(index, item)}
          </div>
        </div>
      </div>
      {treeNode.childrenNodes &&
      treeNode.isOpen &&
      !query &&
      treeNode.id === (item as DropdownOption).id ? (
        <ul className="w-full ml-3">
          {treeNode.childrenNodes.map((child, index) => (
            <OptionItem
              key={index}
              item={child as Item}
              index={index}
              handleOnClick={handleOnClick}
              disabled={props.disabled}
              displayKey={props.displayKey}
              query={query}
              hasChildren={props.hasChildren}
            />
          ))}
        </ul>
      ) : null}
    </>
  );
}

export default OptionItem;
