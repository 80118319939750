import React, { ReactNode } from "react";

interface IProps {
  size: "title" | "subtitle";
  children: ReactNode;
  className?: string;
}

const Heading = ({ size, children, className }: IProps) => {
  return (
    <>
      {size === "title" && (
        <h6
          className={`mt-0 mb-2 text-xl font-semibold leading-normal ${
            className ?? ""
          }`}
        >
          {children}
        </h6>
      )}
      {size === "subtitle" && (
        <h6
          className={`mt-0 mb-2 text-sm font-normal text-gray-700 ${
            className ?? ""
          }`}
        >
          {children}
        </h6>
      )}
    </>
  );
};

export default Heading;
