import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import { EditBlueprintForm, HandleBlueprintItemFormSubmitArgs } from "./models";
import AuditItemFormOnline from "./AuditItemFormOnline";
import { observer } from "mobx-react-lite";
import { handleBlueprintEditOnline } from "./utils";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { BlueprintOUT } from "api-client";

const EditBlueprintOnline = () => {
  const { id, audit_id, blueprint_id } = useParams();
  const { t } = useTranslation();
  useDisableScrollOnNumberInputs();

  const navigate = useNavigate();

  useEffect(() => {
    caOnlineStore.setIsBlueprintEditMode(true);

    return () => {
      caOnlineStore.setIsBlueprintEditMode(false);
    };
  }, []);

  const handleSubmit = async (
    form: EditBlueprintForm,
    args: HandleBlueprintItemFormSubmitArgs
  ) => {
    const blueprint = await handleBlueprintEditOnline(form, args, (message) =>
      console.log("Message", message)
    );

    if (blueprint) {
      caOnlineStore.setShouldUpdateBlueprintList(true);
      caOnlineStore.setSelectedBPOrComponents(blueprint as BlueprintOUT);
      navigate(
        `/buildings/${id}/audits-online/${audit_id}/blueprints/${blueprint.id}/instances`
      );
    }
  };

  return (
    <AuditItemFormOnline<EditBlueprintForm>
      redirect={`/buildings/${id}/audits-online/${audit_id}/blueprints/${blueprint_id}/instances`}
      title={t("audits.editBlueprint")}
      submitText={t("audits.saveBlueprint")}
      images={[]}
      handleSubmit={handleSubmit}
    />
  );
};

export default observer(EditBlueprintOnline);
