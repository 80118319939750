import { BuildingOUT } from "api-client";
import clsx from "clsx";
import ImageResizer from "features/Audit/ImageResizer";
import React from "react";

export default function BuildingLogo({ building }: { building: BuildingOUT }) {
  return (
    <div
      className={clsx(
        "rounded bg-gray-300 text-white w-6 h-6 text-sm flex justify-center items-center overflow-hidden"
      )}
    >
      {building?.images?.length ? (
        <ImageResizer
          src={building?.images[0].image ?? ""}
          alt={building?.name}
          className="object-contain"
        />
      ) : (
        `${building?.name?.substring(0, 1).toUpperCase()}`
      )}
    </div>
  );
}
