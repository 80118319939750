import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Transition } from "@headlessui/react";
import {
  IconAlertTriangle,
  IconCheck,
  IconExclamationMark,
  IconX,
} from "@tabler/icons-react";

export type NotificationType = "attention" | "warning" | "success";

export type NotificationMessage = {
  id: string;
  type: NotificationType;
  message: string;
};
interface IProps {
  toastList: NotificationMessage[];
  position?: "top" | "bottom";
  className?: string;
  onClickButton?: () => void;
  buttonText?: string;
}

const Toast = ({
  toastList,
  position = "top",
  className,
  ...props
}: IProps) => {
  const [list, setList] = useState(toastList);

  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(toastList[0].id);
      }
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [list]);

  const deleteToast = (id: string) => {
    const listItemIndex = list.findIndex((e) => e.id === id);
    const toastListItem = toastList.findIndex((e) => e.id === id);
    list.splice(listItemIndex, 1);
    toastList.splice(toastListItem, 1);
    setList([...list]);
  };

  return (
    <div
      id="toast-container"
      className={clsx("fixed h-large flex-col right-5 z-40", {
        "top-5": position === "top",
        "bottom-16": position === "bottom",
      })}
    >
      {list.map((toast) => {
        return (
          <Transition
            show={true}
            enter="transition-opacity duration-75"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            key={toast.id}
          >
            <div
              className={clsx(
                "p-4 mb-4 w-[320px] max-w-xs text-gray-80 rounded-lg",
                "rounded-lg",
                {
                  "bg-yellow-50 border border-yellow-600":
                    toast.type === "attention",
                  "bg-green-50 border border-green-600":
                    toast.type === "success",
                  "bg-red-50 border border-red-600": toast.type === "warning",
                },
                { className }
              )}
            >
              <div className="flex items-center ">
                <div
                  className={clsx(
                    "inline-flex flex-shrink-0 justify-center items-center w-10 h-10 rounded-full",
                    {
                      "bg-yellow-100": toast.type === "attention",
                      "bg-green-100": toast.type === "success",
                      "bg-red-100": toast.type === "warning",
                    }
                  )}
                >
                  {toast.type === "attention" && (
                    <IconAlertTriangle
                      className="h-5 w-5 text-yellow-800"
                      aria-hidden="true"
                    />
                  )}
                  {toast.type === "warning" && (
                    <IconExclamationMark
                      className="h-5 w-5 text-red-400"
                      aria-hidden="true"
                    />
                  )}
                  {toast.type === "success" && (
                    <IconCheck
                      className="h-5 w-5 text-green-600"
                      aria-hidden="true"
                    />
                  )}
                </div>

                <div className="ml-3 text-sm font-normal">{toast.message}</div>

                <button
                  type="button"
                  onClick={() => deleteToast(toast.id)}
                  className={clsx(
                    "ml-auto -mx-1.5 -my-1.5 text-gray-500 hover:text-gray-900 rounded-lg",
                    "inline-flex items-center justify-center h-8 w-8"
                  )}
                >
                  <IconX className="w-5 h-5" />
                </button>
              </div>
              {props.buttonText ? (
                <div className="flex justify-end">
                  <button
                    onClick={props.onClickButton}
                    className={clsx(
                      "px-3 py-1 mt-2 rounded cursor-pointer transition",
                      {
                        "bg-yellow-200 hover:bg-yellow-300 text-yellow-700":
                          toast.type === "attention",
                        "bg-green-200 hover:bg-green-300 text-green-700":
                          toast.type === "success",
                        "bg-red-200 hover:bg-red-300 text-red-700":
                          toast.type === "warning",
                      }
                    )}
                  >
                    {props.buttonText}
                  </button>
                </div>
              ) : null}
            </div>
          </Transition>
        );
      })}
    </div>
  );
};

export default Toast;
