import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading";
import { LockClosedIcon } from "@heroicons/react/solid";
import Button from "components/Button";
import Alert from "components/Alert";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export default function ChangePassword() {
  const { t } = useTranslation();
  const { user } = useAuth0();
  const [message, setMessage] = useState("");

  const handleChangePassword = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!user || !user.email) {
      return console.error("User is undefined to change password.");
    }
    const message = await triggerPasswordReset(user.email);
    if (message) {
      setMessage(message);
    } else {
      setMessage("An error occured.");
    }
  };

  useEffect(() => {
    return setMessage("");
  }, []);

  return (
    <>
      <Header />
      <div className="p-5 text-sm h-[calc(100vh-200px)] overflow-y-scroll scrollbar">
        {/* <div className='mb-6 text-lg font-semibold'>Profile</div> */}
        <>
          <div className="flex justify-start pt-1">
            <Button
              type="primary"
              width="fit-content"
              onClick={handleChangePassword}
              leadingIcon={<LockClosedIcon />}
            >
              {t("userSettings.changePassword")}
            </Button>
          </div>
          {message !== "" && (
            <Alert
              type={message.includes("error") ? "attention" : "success"}
              title={message}
              dismiss={false}
              className="flex-row mt-4"
            />
          )}
        </>
      </div>
    </>
  );
}

function Header() {
  const { t } = useTranslation();

  return (
    <div className="flex p-5 border-b border-gray-300 items-center">
      <LockClosedIcon width={20} />
      <Heading size="title" className="pl-2 !mb-0">
        {t("userSettings.password")}
      </Heading>
    </div>
  );
}

async function triggerPasswordReset(email: string) {
  try {
    const response = await axios.post(
      `https://${
        import.meta.env.VITE_AUTH0_DOMAIN
      }/dbconnections/change_password`,
      JSON.stringify({
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
        connection: "Username-Password-Authentication",
        email,
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
