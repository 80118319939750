import { useUser } from "contexts/user";
import { useFlagsmith } from "flagsmith-react";

export default function useFeatureFlag() {
  const { hasFeature, getValue } = useFlagsmith();
  const { user } = useUser();

  const isFeatureVisible = (id: string): boolean => {
    const valuesList = String(getValue(id)).split(",");
    const isCurrentUserEmail = Boolean(
      valuesList.find((item) => item.trim() === user?.email) ?? false
    );
    return hasFeature(id) || isCurrentUserEmail;
  };

  return {
    isFeatureVisible,
  };
}
