import React from "react";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";

interface IProps {
  type: "success" | "attention" | "warning" | "info" | "auto";
  className?: string;
  labelName?: string;
  size?: "small" | "middle" | "large" | "auto";
  closable?: boolean;
  onClose?: () => void;
  children?: string | React.ReactNode;
}

export default function Badge({
  type = "success",
  className,
  labelName,
  size = "small",
  closable,
  onClose,
  children,
}: IProps) {
  return (
    <div
      className={clsx(
        "inline-flex items-center whitespace-nowrap rounded-full font-medium max-w-[100%]",
        {
          "bg-green-100 text-green-800": type === "success",
          "bg-yellow-100 text-yellow-800": type === "attention",
          "bg-red-100 text-red-800": type === "warning",
          "bg-gray-200 text-gray-700": type === "info",
          "bg-indigo-100 text-indigo-700": type === "auto",
          "px-3 text-xs py-1.5 h-small": size === "small",
          "px-3.5 text-sm py-2 h-middle": size === "middle",
          "px-4 rounded-full text-sm py-3 h-large": size === "large",
          "flex text-xs py-0 h-auto justify-center w-fit": size === "auto",
        },
        className
      )}
    >
      {labelName || children}
      {closable && (
        <button
          type="button"
          className={clsx(
            "flex-shrink-0 rounded-full inline-flex items-center justify-center cursor-pointer",
            {
              "bg-green-100 text-green-800": type === "success",
              "bg-yellow-100 text-yellow-800": type === "attention",
              "bg-red-100 text-red-800": type === "warning",
              "bg-gray-100 text-gray-800": type === "info",
              "w-5 h-5 ml-1.5": size === "large",
              "w-4 h-4 ml-1": size === "middle",
              "h-4 w-4 ml-0.5": size === "small",
            }
          )}
          onClick={onClose}
        >
          <span className="sr-only">Remove small option</span>
          <XIcon width={size === "large" ? 20 : 18} />
        </button>
      )}
    </div>
  );
}
