import React from "react";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { NUMBER_OF_STEPS } from "./utils";

export default function handleBlueprintInvalidOnline(
  e: React.FormEvent<HTMLFormElement>
) {
  const elementName = (e.target as HTMLInputElement | HTMLSelectElement).name;
  const failedSteps = [];
  for (let i = 1; i <= NUMBER_OF_STEPS; i++) {
    const found = document.querySelector(`#step-${i} [name='${elementName}']`);
    found && failedSteps.push(i);
  }
  const matchedStep = failedSteps[0];
  if (matchedStep && matchedStep === 3) {
    blueprintCreateStore.setStep(2); //3
    return blueprintCreateStore.setComponentCurrentStep(1);
  }
  if (matchedStep) {
    return blueprintCreateStore.setStep(Number(matchedStep) - 1);
  }
  console.error(`matchedStep number not working (${matchedStep}).`);
}
