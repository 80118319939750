import { FieldOUT } from "api-client";

export function getPropertyName(field: FieldOUT, language: string) {
  let propName = undefined;
  if (field && field[language === "en" ? "name" : "name_de"]) {
    propName = field[language === "en" ? "name" : "name_de"];
  }
  return propName;
}

export function getUnit(field: FieldOUT) {
  let unit = undefined;
  if (
    field &&
    field.field_options &&
    field.field_options.length > 0 &&
    field.field_options[0].value
  ) {
    unit = field.field_options[0].value;
  }
  return unit;
}
