import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import Tooltip from "components/Tooltip";

interface Props {
  onExpandAll?: () => void;
  onCollapseAll?: () => void;
  title: string;
  tooltip?: string | React.ReactNode;
  showButtons?: boolean;
}

const iconClassName =
  "px-1 rounded-sm border border-gray-300 flex flex-col -space-y-2 items-center cursor-pointer hover:bg-gray-100";

const ListSectionHeader = ({
  onExpandAll,
  onCollapseAll,
  title,
  tooltip,
  showButtons,
}: Props) => {
  return (
    <div className="mt-2 flex items-center justify-between font-[600] text-gray-900 text-[16px] py-2">
      <div className="flex items-center">
        {title}
        {tooltip && <Tooltip tooltipClassName="!w-[250px]" content={tooltip} />}
      </div>
      {onExpandAll && onCollapseAll && showButtons && (
        <div className="flex">
          <span
            onKeyDown={onExpandAll}
            onClick={onExpandAll}
            className={`${iconClassName} mr-2`}
          >
            <ChevronUpIcon width={16} color="#000" />
            <ChevronDownIcon width={16} color="#000" />
          </span>
          <span
            onKeyDown={onCollapseAll}
            onClick={onCollapseAll}
            className={iconClassName}
          >
            <ChevronDownIcon width={16} color="#000" />
            <ChevronUpIcon width={16} color="#000" />
          </span>
        </div>
      )}
    </div>
  );
};

export default ListSectionHeader;
