import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import DMETableView from "../DMETableView";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import {
  IconArrowsDiagonal,
  IconArrowsDiagonalMinimize2,
  IconX,
} from "@tabler/icons-react";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import useMappingEditor from "../useMappingEditor";

interface Panel {
  id: string;
  icon?: React.ReactNode;
  defaultSize?: number;
  min?: number;
  max?: number;
}

function PanelLayout() {
  const { t } = useTranslation();
  const { save3DViewerState } = useMappingEditor();
  const DME3DViewer = lazy(() => import("../DME3DViewer"));

  const [panels, setPanels] = React.useState([
    { id: "3DView", icon: <></>, defaultSize: 0, min: 0, max: 0 },
    {
      id: "tabularView",
      icon: <></>,
      defaultSize: 100,
      min: 100,
      max: 100,
    },
  ]);

  const iconMinimize = (index: number) => (
    <IconArrowsDiagonalMinimize2
      size={15}
      className="cursor-pointer"
      onClick={() => minimize(index)}
    />
  );

  const iconMaximize = (index: number) => (
    <IconArrowsDiagonal
      size={15}
      className="cursor-pointer"
      onClick={() => maximize(index)}
    />
  );

  const close3DViewer = () => {
    panels[0].min = 0;
    panels[0].max = 0;
    panels[0].defaultSize = 0;
    panels[1].min = 100;
    panels[1].max = 100;
    panels[1].defaultSize = 100;
    setPanels([...panels]);
  };

  const open3DViewer = () => {
    viewOneThird();
  };

  const minimize = (index: number) => {
    if (index === 0) {
      viewOneThird();
    } else {
      viewTwoThird();
    }
  };

  const maximize = (index: number) => {
    if (index === 0) {
      viewTwoThird();
    } else {
      viewOneThird();
    }
  };

  function viewOneThird() {
    panels[0].min = 34;
    panels[0].max = 66;
    panels[0].defaultSize = 34;
    panels[0].icon = iconMaximize(0);
    panels[1].min = 66;
    panels[1].max = 66;
    panels[1].defaultSize = 66;
    panels[1].icon = iconMinimize(1);
    setPanels([...panels]);
  }

  function viewTwoThird() {
    panels[0].min = 55;
    panels[0].max = 55;
    panels[0].defaultSize = 55;
    panels[0].icon = iconMinimize(0);
    panels[1].min = 45;
    panels[1].max = 45;
    panels[1].defaultSize = 45;
    panels[1].icon = iconMaximize(1);
    setPanels([...panels]);
  }

  const onDragStart = () => {
    panels[0].min = 30;
    panels[0].max = 60;
    panels[1].min = 45;
    panels[1].max = 70;
    setPanels([...panels]);
  };

  React.useEffect(() => {
    dynamicMEStore.open3DViewer ? open3DViewer() : close3DViewer();
  }, [dynamicMEStore.open3DViewer]);

  React.useEffect(() => {
    return () => dynamicMEStore.setOpen3DViewer(false);
  }, []);

  const onClickClose3DViewer = () => {
    dynamicMEStore.setOpen3DViewer(false);
    save3DViewerState(false);
  };

  const panelHeader = (index: number) => {
    return (
      <div className="flex w-full justify-between px-4 text-xs items-center font-semibold bg-gray-100 border-y border-gray-400">
        <span>{t(`mappingEditor.${panels[index].id}`)}</span>
        <div className="flex items-center">
          <div>{panels[index].icon}</div>
          {index === 0 && (
            <IconX
              size={16}
              className="cursor-pointer hover:text-gray-600 ml-1"
              onClick={onClickClose3DViewer}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <PanelGroup
      autoSaveId="example"
      direction="vertical"
      className="!h-full !min-h-[100px] flex flex-col"
    >
      {dynamicMEStore.open3DViewer ? (
        <>
          <Panel
            collapsible={false}
            minSize={panels[0].min}
            maxSize={panels[0].max}
            defaultSize={panels[0].defaultSize}
          >
            {panelHeader(0)}
            <Suspense>
              <DME3DViewer height="h-full" />
            </Suspense>
          </Panel>
          <PanelResizeHandle className="cursor-move" onDragging={onDragStart} />
        </>
      ) : null}
      <Panel
        collapsible={false}
        minSize={panels[1].min}
        maxSize={panels[1].max}
        defaultSize={panels[1].defaultSize}
      >
        {dynamicMEStore.open3DViewer && panelHeader(1)}
        <DMETableView />
      </Panel>
    </PanelGroup>
  );
}

export default observer(PanelLayout);
