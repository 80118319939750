import React from "react";
import {
  IconArrowsSort,
  IconFilter,
  IconTrashX,
  IconX,
} from "@tabler/icons-react";
import SortByPropertyBrowser, { SortProps } from "../SortByPropertyBrowser";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import FilterByPropertyBrowser, {
  FilterRule,
} from "../FilterByPropertyBrowser";
import useFeatureFlag from "hooks/useFeatureFlag";
import { CollectionIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import GroupByPropertyBrowser from "../GroupByPropertyBrowser";
import { DropdownOption } from "components/DropdownBrowser";
import { observer } from "mobx-react";
import {
  EMPTY_FILTER_RULE,
  EMPTY_SORT_ROW,
  PROPERTY_RULE_OPERATORS,
} from "../../const";
import Tooltip from "components/Tooltip";
import { PropertyBrowserTreeNode } from "api-client";
import useMappingEditor from "../../useMappingEditor";

type FilterItem = SortProps | DropdownOption | FilterRule;

function AdvancedFilterView() {
  const { isFeatureVisible } = useFeatureFlag();
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { clearGrouping } = useMappingEditor();

  const selectedItemStyle =
    "flex text-indigo-700 bg-indigo-100 rounded-3xl px-2 py-[1px] items-center mr-2";

  function filterItem(
    item: FilterItem,
    index: number,
    removeItem: (item: FilterItem) => void
  ) {
    return (
      <Tooltip
        key={index}
        content={displayFilterCondition(item as FilterRule)}
        truncate
      >
        <div className={selectedItemStyle}>
          {isEng
            ? (item as FilterRule).property?.name
            : (item as FilterRule).property?.name_de}
          <IconX
            size={18}
            stroke={3}
            className="ml-1.5 text-indigo-500 cursor-pointer hover:text-indigo-800 transition"
            onClick={() => removeItem(item)}
          />
        </div>
      </Tooltip>
    );
  }

  function groupingItem(property: PropertyBrowserTreeNode) {
    const name = isEng ? property?.name : property?.name_de;
    return (
      <Tooltip key={property.id} content={name} truncate>
        <div className={selectedItemStyle}>
          {name}
          <IconX
            size={18}
            stroke={3}
            className="ml-1.5 text-indigo-500 cursor-pointer hover:text-indigo-800 transition"
            onClick={() => dynamicMEStore.removeGroupingRule(property.id)}
          />
        </div>
      </Tooltip>
    );
  }

  function displayFilterCondition(filterItem: FilterRule) {
    const propertyName = isEng
      ? filterItem.property?.name
      : filterItem.property?.name_de;
    const operation = PROPERTY_RULE_OPERATORS.find(
      (e) => e.id == filterItem.operator
    );

    if (!operation) {
      return propertyName;
    }
    return `${propertyName} ${isEng ? operation.name : operation.name_de} ${
      filterItem.value
    }`;
  }

  function removeFilterItem(item: FilterItem) {
    const filteredValues = dynamicMEStore.selectedFilterByItems.rules.filter(
      (rec) =>
        rec.property?.column_key !== (item as FilterRule).property?.column_key
    );
    dynamicMEStore.setSelectedFilterByItems({
      ...dynamicMEStore.selectedFilterByItems,
      rules: filteredValues.length
        ? filteredValues
        : [{ ...EMPTY_FILTER_RULE }],
    });
  }

  function removeSortItem(item: FilterItem) {
    const filteredValues = dynamicMEStore.selectedSortedByItems.filter(
      (rec) =>
        rec.property?.column_key !== (item as SortProps).property?.column_key
    );
    dynamicMEStore.setSelectedSortedByItems(
      filteredValues.length ? filteredValues : [{ ...EMPTY_SORT_ROW }]
    );
  }

  function groupByTile() {
    return (
      <div className="flex items-center font-semibold text-gray-500 border-b border-gray-100 py-1">
        <div className="flex rounded-sm bg-gray-500 text-white pr-1 py-1">
          <span className="w-6 flex justify-center">
            <CollectionIcon width={16} strokeWidth={3} />
          </span>
          <span>
            {dynamicMEStore.validGroupByRules?.length
              ? `${t("mappingEditor.grouped")} ${t("mappingEditor.by")}: `
              : t("mappingEditor.group")}
          </span>
        </div>
        {dynamicMEStore.selectedGroupByItems.length > 0 ? (
          <div className="flex ml-3 items-center">
            {dynamicMEStore.selectedGroupByItems.map((item) =>
              groupingItem(item)
            )}
            <IconTrashX
              size={18}
              stroke={3}
              className="cursor-pointer transition hover:text-gray-900 mr-0.5"
              onClick={clearGrouping}
            />
          </div>
        ) : null}
        <GroupByPropertyBrowser advancedMode />
      </div>
    );
  }

  function filterByTile() {
    return (
      <div className="flex items-center font-semibold text-gray-500 border-b border-gray-100 py-1">
        <span className="w-6 flex justify-center">
          <IconFilter width={16} stroke={3} />
        </span>
        <span className="mr-2">
          {dynamicMEStore.validFilterRules?.rules.length
            ? `${t("mappingEditor.filtered")} ${t("mappingEditor.by")}: `
            : t("mappingEditor.filter")}
        </span>
        {Number(dynamicMEStore.validFilterRules?.rules?.length) > 0 ? (
          <div className="flex ml-1 items-center">
            {dynamicMEStore.selectedFilterByItems?.rules.map((item, index) => {
              if (item.property && item.value) {
                return filterItem(item, index, removeFilterItem);
              }
            })}
            <IconTrashX
              size={18}
              stroke={3}
              className="cursor-pointer hover:text-gray-900 transition mr-0.5"
              onClick={() => dynamicMEStore.removeAllFilteringRules()}
            />
          </div>
        ) : null}
        <FilterByPropertyBrowser />
      </div>
    );
  }

  function sortByTile() {
    return (
      <div className="flex items-center font-semibold text-gray-500 py-1">
        <span className="w-6 flex justify-center">
          <IconArrowsSort width={16} stroke={3} />
        </span>
        <span className="mr-2">
          {dynamicMEStore.validSortingRules?.length
            ? `${t("mappingEditor.sorted")} ${t("mappingEditor.by")}: `
            : t("mappingEditor.sort")}
        </span>
        {Number(dynamicMEStore.validSortingRules?.length) > 0 ? (
          <div className="flex ml-1 items-center">
            {dynamicMEStore.selectedSortedByItems.map((item, index) => {
              if (item.property) return filterItem(item, index, removeSortItem);
            })}
            <IconTrashX
              size={18}
              stroke={3}
              className="cursor-pointer hover:text-gray-900 transition mr-0.5"
              onClick={() => dynamicMEStore.removeAllSortingRules()}
            />
          </div>
        ) : null}
        <SortByPropertyBrowser />
      </div>
    );
  }

  return (
    <div className="w-full pr-3">
      {isFeatureVisible("mapping_editor_groupby") ? groupByTile() : null}
      {filterByTile()}
      {sortByTile()}
    </div>
  );
}

export default observer(AdvancedFilterView);
