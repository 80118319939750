import { DownloadIcon, RefreshIcon } from "@heroicons/react/outline";
import { AuditOUT } from "api-client";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import PingAnimation from "components/PingAnimation";
import GenericListItem from "features/Audit/GenericListItem";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  auditsAtom,
  auditsLoadingAtom,
  currentAuditAtom,
} from "store/atoms/audits";
import { downloadJSON, slugify } from "utils";
import { handleAuditZip } from "../../features/Audit/SyncBar/performExport";
import { performRestore } from "features/Audit/SyncBar/performRestore";
import ConfirmationPopup from "features/Audit/ConfirmationPopup";
import useToast from "hooks/useToast";
import { NotificationType } from "components/Toast";
import { db } from "api-client-local/db";
import { handleAuditDelete } from "features/Audit/utils";
import NotFound from "components/NotFound";

export default function Audits() {
  return (
    <React.Suspense
      fallback={
        <div className="h-56 flex flex-col justify-center">
          <PingAnimation size="large" color="gray" />
        </div>
      }
    >
      <AuditsSuspensed />
    </React.Suspense>
  );
}

function AuditsSuspensed() {
  const { id: building_id, audit_id } = useParams();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [audits, setAudits] = useRecoilState(auditsAtom);
  const auditsLoading = useRecoilValue(auditsLoadingAtom);
  const [isExporting, setExporting] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const shouldRenderOutlet =
    audit_id || (!audit_id && pathname.endsWith("audits/add"));

  async function handleAuditDownload(id: string) {
    const audit = (await db.audits.get(id)) as AuditOUT;
    if (audit) {
      downloadJSON(audit, slugify(audit.title));
    }
  }

  const setCurrentAudit = useSetRecoilState(currentAuditAtom);
  const [open, setOpen] = React.useState(false);
  const [auditId, setAuditId] = React.useState("");
  const [auditTitle, setAuditTitle] = React.useState("");
  const [isDeleteAudit, setisDeleteAudit] = React.useState(false);

  const restore = React.useCallback(async () => {
    setOpen(false);
    const response = await performRestore({
      audit_id: auditId,
      setCurrentAudit,
      t,
    });
    toast(response.message, response.status as NotificationType);
  }, [auditId]);

  const deleteAudit = async () => {
    if (!auditId) {
      throw new Error("Audit Id not found for deletion");
    }
    if (!building_id) {
      throw new Error("Building id undefined");
    }
    setOpen(false);
    const result = await handleAuditDelete(auditId);
    const message = result
      ? t("audits.auditDeleteSuccess")
      : t("audits.auditDeleteFailed");
    toast(message, result ? "success" : "warning");
    clearAndNavigate();
  };

  const clearAndNavigate = () => {
    const filteredAudits = audits?.filter((audit) => audit.id !== auditId);
    if (filteredAudits && filteredAudits.length > 0) {
      setAudits(filteredAudits);
    } else {
      setAudits(null);
      setisDeleteAudit(false);
      setAuditId("");
      setAuditTitle("");
      setCurrentAudit(null);
      navigate(`/buildings/${building_id}/passport`);
    }
  };

  return (
    <div className="p-4 relative">
      <ConfirmationPopup
        open={open}
        setOpen={setOpen}
        title={
          isDeleteAudit
            ? `${t("audits.auditDeleteConfirm")} - ${auditTitle}`
            : t("audits.restore")
        }
        onPressYes={isDeleteAudit ? deleteAudit : restore}
      />
      {shouldRenderOutlet ? (
        <Outlet />
      ) : (
        <div className="p-2">
          {auditsLoading && (
            <div className="h-56 flex flex-col justify-center">
              <PingAnimation size="large" color="gray" />
            </div>
          )}
          {!auditsLoading && audits?.length === 0 ? (
            <NotFound noZIndex description={t("audits.noAuditsFound")} />
          ) : (
            <div className="flex justify-between">
              <h1 className="text-xl font-semibold leading-normal">
                {t("audits.audits")}
              </h1>
            </div>
          )}
          {isExporting && (
            <div className="text-lg font-semibold mt-3 mb-2 text-center">
              {t("audits.exportingZip")}
            </div>
          )}
          <div className="my-4">
            {audits?.map((audit) => {
              return (
                <GenericListItem key={audit.id} item={audit} itemUrl={audit.id}>
                  <EditDeleteItemMenu
                    className="min-w-[320px]"
                    id={audit.id}
                    onDelete={() => {
                      setisDeleteAudit(true);
                      setAuditId(audit.id);
                      setAuditTitle(audit.title);
                      setOpen(true);
                    }}
                    items={[
                      <div
                        onClick={() => handleAuditDownload(audit.id)}
                        key={1}
                        className="flex flex-wrap"
                      >
                        <DownloadIcon width={15} className="mr-3" />
                        <span>
                          {t("buildingMaterialResources.downloadAs")} JSON
                        </span>
                      </div>,
                      <div
                        onClick={() => handleAuditZip(audit.id, setExporting)}
                        key={1}
                        className="flex"
                      >
                        <DownloadIcon width={15} className="mr-3" />
                        <span>
                          {t("buildingMaterialResources.downloadAs")} ZIP
                        </span>
                      </div>,
                      <div
                        onClick={() => {
                          setAuditId(audit.id);
                          setOpen(true);
                        }}
                        key={2}
                        className="flex"
                      >
                        <RefreshIcon width={15} className="mr-3" />
                        <span>
                          {t("buildingMaterialResources.clearStorage")}
                        </span>
                      </div>,
                    ]}
                  />
                </GenericListItem>
              );
            })}
          </div>
          <AuditUploader />
        </div>
      )}
    </div>
  );
}

function AuditUploader() {
  const { id } = useParams();
  const [audits, setAudits] = useRecoilState(auditsAtom);

  function handleUpload(e: ChangeEvent<HTMLInputElement>) {
    const reader = new FileReader();
    reader.onload = onReaderLoad;
    if (e.target.files && e.target.files.length > 0) {
      reader.readAsText(e.target.files[0]);
    }
  }

  const onReaderLoad = React.useCallback(
    (event: ProgressEvent<FileReader>) => {
      if (!event.target || !event.target.result) return;
      console.log(id, event.target.result);
      const data = JSON.parse(event.target.result as string);
      data.building_id = id;
      db.audits.add(data);
      setAudits((prev) => {
        if (prev) {
          return [...prev, data];
        } else {
          return [data];
        }
      });
    },
    [id, audits, setAudits]
  );
  return (
    <div className="auditUploader hidden">
      <input type="file" onChange={handleUpload} name="jsonupload" id="" />
    </div>
  );
}
