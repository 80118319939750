import { TrashIcon } from "@heroicons/react/solid";
import { buildingsApi } from "api-client";
import Button from "components/Button";
import Modal from "components/Modal";
import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { buildingStore } from "store/Building/BuildingStore";
import { userStore } from "store/UserStore";

export default observer(function DeleteBuilding({
  open,
  close,
}: {
  open: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const { authHeader } = userStore;
  const { currentBuilding } = buildingStore;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const deleteBuilding = useCallback(() => {
    if (!authHeader) return;
    setLoading(true);
    currentBuilding?.id &&
      buildingsApi
        .concularApiV1RoutersBuildingsDeleteBuilding(
          currentBuilding?.id,
          authHeader
        )
        .then(async () => {
          await buildingStore.getOrganisationBuildings();
          navigate("/dashboard");
        })
        .finally(() => setLoading(false));
  }, []);

  const closeModal = useCallback(() => close(false), []);

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      containerClassName="min-w-fit"
      title={t("addBuilding.wantToDelete")}
      inProgress={loading}
      closeButton={false}
      footer={
        <>
          <div>
            {!loading && (
              <Button width="fit-content" type="link" onClick={closeModal}>
                {t("addBuilding.cancel")}
              </Button>
            )}
          </div>
          <Button
            width="fit-content"
            type="danger"
            leadingIcon={<TrashIcon />}
            onClick={deleteBuilding}
            loading={loading}
          >
            {t("addBuilding.deletePermanently")}
          </Button>
        </>
      }
    >
      <div className="p-6">{t("addBuilding.deleteWarning")}</div>
    </Modal>
  );
});
