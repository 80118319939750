import clsx from "clsx";
import InputLabel from "components/InputLabel";
import useInvalidInput from "hooks/useInvalidInput";
import i18n from "languages/i18n";
import React, { useEffect, useState } from "react";

export interface SelectOption {
  id?: string | number;
  name: string;
  name_de?: string;
  name_en?: string;
  value?: string;
}
interface IProps {
  id?: string;
  className?: string;
  containerClassName?: string;
  labelName?: string;
  options: SelectOption[];
  multiple?: boolean;
  required?: boolean;
  enforceRequired?: boolean;
  clearOption?: boolean;
  current?: number | string | undefined;
  size?: "large" | "middle" | "small";
  name?: string;
  onChange?: (value: string | undefined, name?: string) => void;
  type?: "primary" | "default";
  style?: React.CSSProperties;
  noMargin?: boolean;
  placeholder?: string;
  onBlur?: (name: string) => void;
  disable?: boolean;
  showMandatoryAlert?: boolean;
  errorMessage?: string;
  testID?: string;
  displayKey?: keyof SelectOption;
}

const SimpleSelect = React.forwardRef(
  (
    {
      id,
      className,
      containerClassName,
      labelName,
      options,
      current,
      multiple,
      required,
      enforceRequired,
      clearOption,
      size = "middle",
      style,
      name = "",
      onChange,
      type = "primary",
      noMargin,
      placeholder,
      onBlur,
      disable,
      showMandatoryAlert,
      errorMessage,
      testID,
      displayKey,
    }: IProps,
    ref: React.Ref<HTMLSelectElement>
  ) => {
    const [selected, setSelected] = useState<number | string | undefined>(
      current || placeholder
    );
    const [showError, setShowError] = React.useState(false);

    const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const currentValue = event.target.value;
      setSelected(currentValue);
      onChange && onChange(currentValue, event.target.name);
    };

    const selectRef = React.useRef<HTMLSelectElement>(null);

    useInvalidInput(selectRef, (isInvalid) => {
      if (isInvalid) {
        setShowError(true);
      } else {
        setShowError(false);
      }
    });

    useEffect(() => {
      setSelected(current || placeholder);
    }, [current]);

    function checkMandatoryAlert() {
      return required && selected === placeholder && showMandatoryAlert;
    }

    return (
      <div
        style={style}
        className={clsx(`${containerClassName ?? ""}`, { "mb-4": !noMargin })}
      >
        {labelName && (
          <InputLabel
            labelName={labelName}
            name={id as string}
            required={required}
            showError={showError || checkMandatoryAlert()}
          />
        )}
        <div className="mx-auto">
          <select
            name={name}
            ref={ref ?? selectRef}
            data-invalid="false"
            onInvalid={() => setShowError(true)}
            required={required && enforceRequired !== false}
            multiple={multiple}
            className={clsx(
              "block w-full py-1 pl-2 pr-10 mt-0.5 text-sm border-gray-300 truncate",
              "rounded-md focus:outline-none focus:border-indigo-500",
              {
                "h-large": size === "large",
                "h-middle": size === "middle",
                "h-small": size === "small",
                "border-black	bg-transparent": type === "default",
              },
              `${className}`,
              {
                "text-gray-500": selected === placeholder || disable,
                "!border-red-500": checkMandatoryAlert(),
                "cursor-pointer": !disable,
              }
            )}
            value={selected}
            onChange={handleOnChange}
            placeholder={placeholder}
            onBlur={(e: React.FocusEvent<HTMLSelectElement>) => {
              if (required) {
                setShowError(e.currentTarget.value.length === 0);
              }
              onBlur && onBlur(name);
            }}
            disabled={disable}
            test-id={testID}
          >
            {placeholder && <option disabled>{placeholder}</option>}
            {!required && clearOption && <option value={""}>-</option>}
            {options.map((rec, index) => (
              <option key={index} value={rec.id}>
                {displayKey
                  ? rec[displayKey]
                  : i18n.language === "de"
                  ? rec.name_de ?? rec.name
                  : rec.name_en ?? rec.name}
              </option>
            ))}
          </select>
        </div>
        {errorMessage && checkMandatoryAlert() && (
          <p className="text-red-700 font-light text-xs">{errorMessage}</p>
        )}
      </div>
    );
  }
);

SimpleSelect.displayName = "SimpleSelect";

export default SimpleSelect;
