import React from "react";
import { BlueprintOUT, ProductGroupOUT } from "api-client";
import { useTranslation } from "react-i18next";
import { addValuesToFieldsFromProperties } from "../utils";
import { hasUUIDPattern, isNumeric } from "utils";
import { getPropertyName, getUnit } from "./common";
import { useParams } from "react-router";

interface Props {
  blueprint: BlueprintOUT;
  selectedProductGroup: ProductGroupOUT;
  hasExtraInfo: boolean;
}

export const OptionalFields = ({
  blueprint,
  selectedProductGroup,
  hasExtraInfo,
}: Props) => {
  const { i18n } = useTranslation();
  const { blueprint_id } = useParams();

  const optionalFields = React.useMemo(() => {
    if (!blueprint || !blueprint_id || !selectedProductGroup) return null;

    return blueprint.blueprintproperty_set.filter((prop) =>
      selectedProductGroup.optional_fields.find((f) => f.id === prop.field_id)
    );
  }, [blueprint.id]);

  const optionalDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.optional_fields,
    blueprint.blueprintproperty_set
  );

  return (
    <div
      className={`grid sm:grid-cols-2 gap-x-4 ${hasExtraInfo ? "" : "pb-4"}`}
    >
      {selectedProductGroup &&
        optionalFields &&
        optionalFields.map((p, i) => {
          // const val =
          let val = optionalDefaultValues[i];
          if (hasUUIDPattern(optionalDefaultValues[i])) {
            const found = selectedProductGroup.optional_fields[
              i
            ].field_options.find((opt) => (val as string).includes(opt.id));
            if (found) {
              val = found[i18n.language === "en" ? "name" : "name_de"];
            }
          }

          const isFieldDefined =
            selectedProductGroup.optional_fields &&
            selectedProductGroup.optional_fields[i];

          const unit = isFieldDefined
            ? getUnit(selectedProductGroup.optional_fields[i])
            : undefined;

          const propName = isFieldDefined
            ? getPropertyName(
                selectedProductGroup.optional_fields[i],
                i18n.language
              )
            : undefined;

          if (
            (val && val === "") ||
            (val && Array.isArray(val) && val.length === 0)
          )
            return null;

          if (!propName) return null;

          return (
            <div
              className="text-sm flex justify-between py-2 border-b-2"
              key={p.id}
            >
              <span className="text-gray-500">{propName}</span>
              <div className="text-right">
                {val}{" "}
                {unit &&
                  unit.length <= 10 &&
                  unit !== val &&
                  isNumeric(val) && (
                    <span>{hasUUIDPattern(unit) ? "" : unit}</span>
                  )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
