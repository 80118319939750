import Button from "components/Button";
import Modal from "components/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

interface ICPProps {
  title: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onPressYes: () => void;
}

const ConfirmationPopup = (props: ICPProps) => {
  const { title, open, setOpen, onPressYes } = props;

  const { t } = useTranslation();
  return (
    <>
      <Modal
        title={title}
        closeButton
        isOpen={open}
        setIsOpen={setOpen}
        containerClassName="w-full"
        footerClassName="!bg-white"
        footer={
          <div className="w-full flex items-end justify-between">
            <Button
              className="max-w-sm"
              type="secondary"
              onClick={() => setOpen(false)}
            >
              {t("cookieBanner.texts.cancel")}
            </Button>
            <span className="p-4" />
            <Button type="primary" onClick={onPressYes}>
              {t("cookieBanner.texts.yes")}
            </Button>
          </div>
        }
      >
        <>
          <div className="p-4">{t("audits.areYouSure")}</div>
        </>
      </Modal>
    </>
  );
};

export default ConfirmationPopup;
