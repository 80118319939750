import React from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Table } from "@tanstack/react-table";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

function ColumnToggleButtons({ table }: { table: Table<unknown> }) {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end">
      <div className="relative flex group justify-end text-sm">
        <Button
          type="link"
          width="fit-content"
          className="py-0 px-0 flex items-center"
        >
          {t("commons.columns")}
          <ChevronDownIcon width={20} className="pl-1" />
        </Button>
        <div className="border bg-white border-gray-300 top-10 z-20 rounded absolute hidden group-hover:inline-block whitespace-nowrap">
          <div className="pt-0.5 px-2 pb-1 border-b border-gray-300">
            <label>
              <input
                {...{
                  type: "checkbox",
                  checked: table.getIsAllColumnsVisible(),
                  onChange: table.getToggleAllColumnsVisibilityHandler(),
                }}
              />
              <span className="pl-1">{t("commons.toggleAll")}</span>
            </label>
          </div>
          <div className="pb-1.5">
            {table.getAllLeafColumns().map((column) => {
              return (
                <div key={column.id} className="px-2 pt-0.5">
                  <label>
                    <input
                      {...{
                        type: "checkbox",
                        checked: column.getIsVisible(),
                        onChange: column.getToggleVisibilityHandler(),
                      }}
                    />
                    <span className="pl-1">
                      {`${column.id
                        .charAt(0)
                        .toUpperCase()}${column.id.substring(1)}`}
                    </span>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ColumnToggleButtons;
