import { AuditOUT, repositoriesApi } from "api-client";
import { db } from "api-client-local/db";
import { getLocalAuthHeader } from "api-client-local/utils";
import useCAOnlyFeature from "hooks/useCAOnlyFeature";
import React from "react";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";
import { buildingStore } from "store/Building/BuildingStore";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { organisationStore } from "store/OrganisationStore";
import {
  auditsAtom,
  auditsLoadingAtom,
  manufacturersAtom,
  productGroupsAtom,
} from "store/atoms/audits";

export function useBuildings() {
  const setManufacturers = useSetRecoilState(manufacturersAtom);
  const setProductGroups = useSetRecoilState(productGroupsAtom);
  const setAuditsAtom = useSetRecoilState(auditsAtom);
  const setAuditsLoadingAtom = useSetRecoilState(auditsLoadingAtom);
  const { currentBuilding } = buildingStore;
  const { caOnlyFeature } = useCAOnlyFeature();
  const { id: buildingID } = useParams();

  React.useEffect(() => {
    buildingStore.setBuildingID(buildingID);
  }, [buildingID]);

  React.useEffect(() => {
    ifcMappingStore.fetchCertificationData();
  }, []);

  React.useEffect(() => {
    if (!buildingSettingsStore.buildingAlreadyUpdated) return;
    const timer = setTimeout(() => {
      buildingSettingsStore.setBuildingAlreadyUpdated(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [buildingSettingsStore.buildingAlreadyUpdated]);

  async function fetchLocalPGsAndManufacturers() {
    const productGroups = await db.product_groups.toArray();
    if (productGroups.length > 0) {
      setProductGroups(productGroups);
    }
    const manufacturers = await db.manufacturers.toArray();
    if (manufacturers.length > 0) {
      setManufacturers(manufacturers);
    }
  }

  async function fetchBuildingAuditsLocal() {
    try {
      const neededId = currentBuilding ? currentBuilding.id : undefined;
      if (!neededId) throw Error(`buildingId undefined`);
      const audits: AuditOUT[] = (await db.audits.toArray()) as AuditOUT[];
      return audits.filter((a) => a.building_id === neededId);
    } catch (error) {
      console.error("fetchBuildingAuditsLocal", error);
      return [];
    }
  }

  async function fetchBuildingAudits(): Promise<AuditOUT[]> {
    const authHeader = await getLocalAuthHeader();
    const neededId = currentBuilding?.id ?? undefined;
    if (!neededId) throw Error(`buildingId undefined`);
    const response =
      await repositoriesApi.caApiV1RoutersRepositoryGetAuditsByBuildingId(
        neededId,
        authHeader
      );
    return response.data;
  }

  async function refreshOnChangeBuilding() {
    if (!currentBuilding) return;

    setAuditsLoadingAtom(true);
    const currentOrg = organisationStore.organisations.find(
      (item) => item.id === currentBuilding?.organisation_id
    );

    if (!caOnlyFeature) {
      organisationStore.setCurrentOrganisation(currentOrg);
      !buildingStore.materialResources.loading &&
        buildingStore.setMaterialResources({
          ...buildingStore.materialResources,
          loading: true,
        });
      await buildingStore.getAllMaterialResources();
      await buildingStore.getBuildingMappingStatus();
    }

    setAuditsAtom([]);
    await fetchLocalPGsAndManufacturers();

    const audits = await fetchBuildingAuditsLocal();
    await processLocalAudits(audits);
    setAuditsLoadingAtom(false);
  }

  async function processLocalAudits(audits: AuditOUT[]) {
    if (audits.length === 0) {
      fetchBuildingAudits().then((remoteAudits) => {
        if (remoteAudits.length > 0) {
          setAuditsAtom(remoteAudits);
        } else {
          setAuditsAtom([]);
        }
      });
    } else {
      setAuditsAtom(audits);
      fetchBuildingAudits()
        .then((remoteAudits) => processAudits(remoteAudits, audits))
        .catch((err) => console.error(err));
    }
  }

  async function processAudits(remoteAudits: AuditOUT[], audits: AuditOUT[]) {
    let newAudits = audits;
    const newServerAudititems = [];
    if (remoteAudits.length > audits.length) {
      for (const item of remoteAudits) {
        const found = audits.some((elem) => elem.id === item.id);
        if (!found) {
          newServerAudititems.push(item);
        }
      }
      if (newServerAudititems.length > 0) {
        newAudits = [...audits, ...newServerAudititems];
        setAuditsAtom(newAudits);
      }
    }
    // Checking server floor_set and update local audits floor_set accordingly
    const serverFloorSets = remoteAudits.map((audit) => audit.floor_set);
    // Update local audits with serverFloorSets
    const updatedAudits = newAudits.map((audit) => {
      return {
        ...audit,
        floor_set: serverFloorSets
          .map((floors) => floors)
          .flat(2)
          .filter((item) => item.audit_id === audit.id),
      };
    });
    setAuditsAtom(updatedAudits);
  }

  return {
    refreshOnChangeBuilding,
  };
}
