import { PlaceDetailsOUT } from "api-client";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { buildingStore } from "store/Building/BuildingStore";
import { placeDetailsToString } from "utils";

export default observer(function LocationTile() {
  const { t } = useTranslation();
  const { currentBuilding } = buildingStore;

  function getAddress() {
    if (!currentBuilding) return "--";

    const address = placeDetailsToString({
      ...currentBuilding?.address,
      postal_code: currentBuilding.postal_code,
      region: currentBuilding.region,
    } as PlaceDetailsOUT);
    return address !== "" ? address : "--";
  }

  return (
    <div
      className="bg-white px-4 rounded-lg flex justify-between overflow-hidden border border-gray-300 col-span-1 mb-4"
      test-id="location_lcp"
    >
      <div className="py-4 w-full">
        <div className="flex justify-between items-center">
          <div className="font-semibold text-gray-900">
            {t("buildingPassport.location")}
          </div>
        </div>
        <div className="flex text-xs">
          <div>
            <div className="py-3">
              <div className="text-gray-600">
                {t("buildingPassport.address")}
              </div>
              <div className="text-gray-500">{getAddress()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
