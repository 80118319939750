import {
  ComponentComplianceOUT,
  ComponentCreateIN,
  ComponentCreateOUT,
  ComponentDetailsByFileOut,
  ComponentReferenceUnitEnum,
  componentsApi,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { TagsOption } from "components/TagsInput";
import { REFERENCE_UNITS } from "features/MappingTools/consts";
import { makeAutoObservable } from "mobx";
import { buildingStore } from "../Building/BuildingStore";
import { BGItem } from "components/ButtonGroups";
import { buildingProductStore, OptionsList } from "./BuildingProductStore";

export interface Step {
  title: string;
  fields: string[];
}

const step1 = {
  title: "generalInformation",
  fields: [
    "name",
    "component_din_category",
    "ifc_is_external",
    "ifc_is_loadbearing",
    "tags_automapper",
  ],
};

const step2 = {
  title: "refrenceUnitAndConstructionPRsCs",
  fields: ["reference_unit", "children"],
};

const step3 = {
  title: "circularity",
  fields: [
    "preuse_qualification",
    "deconstructability_qualification",
    "reusability_qualification",
  ],
};

export interface CustomComponent {
  id: string;
  name: string;
  building: string;
  ifc_is_external?: boolean;
  ifc_is_loadbearing?: boolean;
  tags_automapper: TagsOption[];
  reference_unit: BGItem;
  childrenList: ComponentDetailsByFileOut[];
  preuse_qualification?: string;
  deconstructability_qualification: string;
  reusability_qualification?: string;
  compliance?: ComponentComplianceOUT;
  component_din_category?: string;
}

const initialData: CustomComponent = {
  id: "",
  name: "",
  building: "",
  ifc_is_external: false,
  ifc_is_loadbearing: false,
  tags_automapper: [],
  reference_unit: REFERENCE_UNITS[0],
  childrenList: [],
  preuse_qualification: "",
  deconstructability_qualification: "",
  reusability_qualification: "",
  compliance: undefined,
  component_din_category: "",
};

class ComponentsStore {
  activeStep = 0;
  steps: Step[] = [step1, step2, step3];
  data: CustomComponent = initialData;
  openedSearchInCE = false;
  optionsLists: OptionsList = {
    preuse_qualification: [],
    reusability_qualification: [],
  };
  onTheFlyProductAdded: ComponentDetailsByFileOut | undefined = undefined;

  setActiveStep(step: number) {
    this.activeStep = step;
  }

  private prepareData() {
    const props: ComponentCreateIN = {
      name: this.data.name,
      tags_automapper: this.data.tags_automapper.map((item) => item.name),
      ifc_is_external: this.data.ifc_is_external,
      ifc_is_loadbearing: this.data.ifc_is_loadbearing,
      reference_unit: this.data.reference_unit
        ?.id as ComponentReferenceUnitEnum,
      children: this.data.childrenList.map((item) => {
        return {
          product_id: item.id as string,
          unit_conversion: Number(item.unit_conversion),
          comment: item.comment,
        };
      }),
      building: buildingStore.buildingID,
    };
    return props;
  }

  private getCPXValues() {
    const preuseQualification = buildingProductStore.getRelevantQualification(
      "preuse_qualification",
      this.data.preuse_qualification
    );
    const reusabilityQualification =
      buildingProductStore.getRelevantQualification(
        "reusability_qualification",
        this.data.reusability_qualification
      );
    const preuseScore = buildingProductStore.getRelevantScore(
      "preuse_qualification",
      this.data.preuse_qualification
    );
    const reusabilityScore = buildingProductStore.getRelevantScore(
      "reusability_qualification",
      this.data.reusability_qualification
    );
    return {
      deconstructability_qualification:
        buildingProductStore.getRelevantQualification(
          "deconstructability_qualification",
          this.data.deconstructability_qualification
        ),
      deconstructability_score: buildingProductStore.getRelevantScore(
        "deconstructability_qualification",
        this.data.deconstructability_qualification
      ),
      preuse_qualification:
        preuseQualification === "undefined" ? null : preuseQualification,
      preuse_score: Number.isNaN(preuseScore) ? null : preuseScore,
      reusability_qualification:
        reusabilityQualification === "undefined"
          ? null
          : reusabilityQualification,
      reusability_score: Number.isNaN(reusabilityScore)
        ? null
        : reusabilityScore,
    };
  }

  async registerComponent(): Promise<ComponentCreateOUT | void> {
    const props: ComponentCreateIN = this.prepareData();
    const authHeader = await getLocalAuthHeader();
    return await componentsApi
      .inventoryApiV1RoutersComponentsCreateComponent(props, authHeader)
      .then((response) => {
        if (response.data) return response.data;
      });
  }

  async updateComponent() {
    const props: ComponentCreateIN = this.prepareData();
    const authHeader = await getLocalAuthHeader();
    return await componentsApi.inventoryApiV1RoutersComponentsEditComponent(
      this.data.id,
      props,
      authHeader
    );
  }

  setData(data: CustomComponent) {
    this.data = data;
  }

  setOnTheFlyProductAdded(
    onTheFlyProductAdded: ComponentDetailsByFileOut | undefined
  ) {
    this.onTheFlyProductAdded = onTheFlyProductAdded;
  }

  resetData() {
    this.data = initialData;
  }

  onChangeData(
    name: string,
    value:
      | string
      | boolean
      | []
      | undefined
      | TagsOption[]
      | ComponentDetailsByFileOut[]
      | ComponentComplianceOUT
      | BGItem
      | null
      | undefined
  ) {
    if (name) this.data[name as keyof CustomComponent] = value as never;
  }

  getValueFromObject(key: string, isEng?: boolean) {
    const value = Object.entries(this.data).find(
      (item) => item[0] === key
    )?.[1];
    if (key === "tags_automapper") {
      return value.map((item: TagsOption) => item.name).join(", ");
    } else if (key === "reference_unit") {
      return isEng ? value?.name : value?.name_de;
    } else if (buildingProductStore.isAnItemOfOptions(key)) {
      return buildingProductStore.optionsLists[key]?.find(
        (item) => item.id === value
      )?.name;
    } else {
      return value;
    }
  }

  stepHasOverview(step: number) {
    if (step === 1) {
      return Boolean(
        Object.values(this.data.childrenList?.[0]?.id ?? {})?.find(
          (item) => item
        )
      );
    } else {
      return Boolean(
        this.steps[step].fields
          .map((key) => this.getValueFromObject(key))
          .find((item) => item)
      );
    }
  }

  checkStepValidity(index: number) {
    if (index === 0) {
      return this.data.name;
    } else if (index === 1) {
      return (
        this.data.reference_unit &&
        this.data.childrenList?.length > 1 &&
        this.data.childrenList?.filter(
          (item) => item.unit_conversion && Number(item.unit_conversion) > 0
        ).length === this.data.childrenList?.length
      );
    } else if (index === 2) {
      return this.data.deconstructability_qualification !== "";
    }
  }

  checkSaveValidation(circularityStepExist: boolean) {
    const steps = circularityStepExist ? this.steps : [step1, step2];
    return (
      Array.from(Array(steps.length).keys())
        .map((item) => this.checkStepValidity(item))
        .filter((item) => item).length === steps.length
    );
  }

  async addChildItem(item: ComponentDetailsByFileOut) {
    this.onChangeData("childrenList", [...this.data.childrenList, item]);
    await componentsStore.getComponentCompliance();
    await componentsStore.getComponentDINCategory();
  }

  onChangeChildParams(
    key: keyof ComponentDetailsByFileOut,
    index: number,
    value?: string
  ) {
    this.data.childrenList[index] = {
      ...this.data.childrenList[index],
      [key]: value,
    };
  }

  setOpenedSearchInCE(openedSearchInCE: boolean) {
    this.openedSearchInCE = openedSearchInCE;
  }

  async getComponentCompliance() {
    if (!this.data.childrenList.length) {
      this.onChangeData("compliance", undefined);
      return;
    }
    const authHeader = await getLocalAuthHeader();
    const productIds = this.data.childrenList.map((item) => item.id as string);
    await componentsApi
      .inventoryApiV1RoutersComponentsCheckComponentCompliance(
        {
          product_ids: productIds,
        },
        authHeader
      )
      .then((response) => this.onChangeData("compliance", response.data));
  }

  async getComponentDINCategory() {
    if (!this.data.childrenList.length) {
      this.onChangeData("component_din_category", undefined);
      return;
    }
    const authHeader = await getLocalAuthHeader();
    const productIds = this.data.childrenList.map((item) => item.id as string);
    await componentsApi
      .inventoryApiV1RoutersComponentsCheckComponentDinCategory(
        { product_ids: productIds },
        authHeader
      )
      .then((response) =>
        this.onChangeData("component_din_category", response.data.din_category)
      );
  }

  async deleteComponent(component_id: string) {
    const authHeader = await getLocalAuthHeader();
    return await componentsApi.inventoryApiV1RoutersComponentsDeleteComponent(
      component_id,
      authHeader
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: ComponentsStore;
  static getInstance(): ComponentsStore {
    if (!ComponentsStore.instance) {
      ComponentsStore.instance = new ComponentsStore();
    }
    return ComponentsStore.instance;
  }
}

export const componentsStore = ComponentsStore.getInstance();
