import React from "react";
import { observer } from "mobx-react-lite";
import {
  buildingProductStore,
  Step,
} from "store/IfcMapping/BuildingProductStore";
import { useTranslation } from "react-i18next";

export default observer(function OverviewTile({ step }: { step: Step }) {
  const { t } = useTranslation();

  const checkValue = (value: string | []) => {
    if (String(value) === "true") {
      return t("productEditor.yes");
    } else if (String(value) === "false") {
      return t("productEditor.no");
    } else {
      return value;
    }
  };

  return (
    <div className="flex text-gray-600 flex-wrap">
      {step.fields.map((key, index) =>
        buildingProductStore.getValueFromObject(key) ? (
          <div className="mr-5" key={index}>
            <label className="text-sm">
              {t(
                `productEditor.${
                  key.includes("qualification") ? key.split("_")[0] : key
                }`
              )}
            </label>
            <div className="text-sm font-normal pt-1 text-gray-500 pb-4">
              {checkValue(buildingProductStore.getValueFromObject(key))}
            </div>
          </div>
        ) : null
      )}
    </div>
  );
});
