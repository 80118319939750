import { Row } from "@tanstack/react-table";
import React from "react";

export interface ContextMenuState {
  x: number;
  y: number;
  row: Row<unknown>;
}

const useContextMenu = () => {
  const [contextMenu, setContextMenu] = React.useState<ContextMenuState | null>(
    null
  );

  const handleRightClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    row: Row<unknown>
  ) => {
    event.preventDefault();
    setContextMenu({
      x: event.clientX,
      y: event.clientY,
      row,
    });
  };

  const closeContextMenu = () => setContextMenu(null);

  React.useEffect(() => {
    document.addEventListener("click", closeContextMenu);
    return () => {
      document.removeEventListener("click", closeContextMenu);
    };
  }, []);

  return {
    contextMenu,
    handleRightClick,
    closeContextMenu,
  };
};

export default useContextMenu;
