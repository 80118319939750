import {
  DotsVerticalIcon,
  DuplicateIcon,
  PencilAltIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { ActionMenuProps } from ".";
import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

interface EditDeleteItemProps extends Partial<ActionMenuProps> {
  id: string;
  url?: string;
  direction?: string;
  className?: string;
  withDuplicate?: boolean;
  withMultiSelect?: boolean;
  items?: React.ReactElement[];
  onEdit?: () => void;
  onDelete: (id: string) => void | Promise<void>;
  onMultiSelect?: (id: string) => void | Promise<void>;
  onDuplicate?: (id: string) => void | Promise<void>;
}

export default function EditDeleteItem(props: EditDeleteItemProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [renderedItems] = React.useState(
    [
      <div className="flex space-x-3 justify-start" key={0}>
        <PencilAltIcon width={15} />
        <span>{t("audits.edit")}</span>
      </div>,
      props.withDuplicate ? (
        <div className="flex space-x-3 justify-start" key={0}>
          <DuplicateIcon width={15} />
          <span>{t("audits.duplicate")}</span>
        </div>
      ) : null,
      ...(props.items ? props.items : []),
      <div className="flex space-x-3 justify-start" key={0}>
        <TrashIcon width={15} className="text-red-400" />
        <span className="text-red-400">{t("audits.delete")}</span>
      </div>,
    ].filter((e) => e !== null)
  );

  const handleAction = React.useCallback(
    (index: number) => {
      // On first, it always navigates to ID which should be edit URL.
      if (index == 0) {
        if (props.onEdit) {
          return props.onEdit();
        }
        return navigate(props.url ? props.url : props.id);
      }
      // If last item
      if (
        index === renderedItems.length - 1 &&
        (!props.withMultiSelect || !props.withDuplicate)
      ) {
        console.log("Fired delete", props.id);
        return props.onDelete(props.id);
      }

      if (index === 1 && props.withDuplicate) {
        console.log("Fired withDuplicate", props.id);
        return props.onDuplicate ? props.onDuplicate(props.id) : undefined;
      }

      if (
        (index === 1 && props.withMultiSelect) ||
        (index === 2 && props.withMultiSelect)
      ) {
        console.log("Fired withMultiSelect", props.id);
        return props.onMultiSelect ? props.onMultiSelect(props.id) : undefined;
      }

      if (index === renderedItems.length - 1) {
        console.log("Fired delete", props.id);
        return props.onDelete(props.id);
      }

      // If other indexes
      props.onSelect ? props.onSelect(index) : undefined;
    },
    [props.id]
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={`flex justify-end w-full`}>
      <IconButton aria-label="more" aria-haspopup="true" onClick={handleClick}>
        <DotsVerticalIcon
          width={18}
          className="text-gray-500 hover:text-black"
        />
      </IconButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {renderedItems.map((option, index) => (
          <MenuItem
            key={`${index}`}
            onClick={() => {
              handleAction(index);
              handleClose();
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
