import React from "react";
import { DropdownOption, DropdownWithSearchProps } from ".";
import clsx from "clsx";
import { IconCheck, IconChevronRight } from "@tabler/icons-react";
import { Combobox } from "@headlessui/react";
import Tooltip from "components/Tooltip";

interface OptionItem<T> extends Omit<DropdownWithSearchProps<T>, "items"> {
  item: T;
  index: number;
  handleOnClick: (item: T) => void;
  details?: T | null;
  query: string;
  selected?: boolean;
  hasChildren?: boolean;
}

function OptionItem<Item extends object>(props: OptionItem<Item>) {
  const { item, index, handleOnClick, details, query, selected } = props;

  function itemContent(item: Item) {
    return (
      <label className="truncate cursor-pointer">
        <div className="flex items-center">
          {props.hasChildren ? null : (
            <IconCheck
              size={15}
              className={clsx("mr-1", selected ? "visible" : "invisible")}
            />
          )}
          {(item as DropdownOption).icon && (
            <div className="mr-2 min-w-[16px] flex justify-center">
              {(item as DropdownOption).icon}
            </div>
          )}
          <div>
            {props.displayKey in item ? (
              <Tooltip truncate className="max-w-[400px]">
                {(item[props.displayKey] as string) || " "}
              </Tooltip>
            ) : null}
            <span className="text-gray-500 group-hover:text-indigo-500">
              {(item[props.detailKey as keyof Item] as string) ?? ""}
            </span>
          </div>
        </div>

        {details ? (
          <div className="text-gray-500 whitespace-normal pl-[19px] group-hover:text-indigo-500">
            {details && props.displayKey === "name"
              ? (details as DropdownOption)?.name
              : (details as DropdownOption)?.name_de ??
                (details as DropdownOption)?.name}
          </div>
        ) : null}
      </label>
    );
  }

  const optionWrapperStyle = `relative pr-4 ${
    props.disabled ? "cursor-not-allowed" : "cursor-pointer"
  }`;

  return (
    <div
      className={clsx(
        "flex cursor-pointer group items-center text-gray-700 hover:bg-indigo-50 px-4 py-2 hover:text-indigo-700"
      )}
      onClick={() => handleOnClick(item)}
    >
      <div className="flex overflow-hidden text-sm font-medium truncate w-full">
        {props.hasChildren ? (
          <div key={index} className={optionWrapperStyle}>
            {itemContent(item)}
          </div>
        ) : (
          <Combobox.Option
            key={index}
            value={item}
            className={optionWrapperStyle}
          >
            {itemContent(item)}
          </Combobox.Option>
        )}
      </div>
      {props.hasChildren && !query ? <IconChevronRight size={20} /> : null}
    </div>
  );
}

export default OptionItem;
