import React from "react";
import { PlusIcon } from "@heroicons/react/outline";
import { ColumnDef } from "@tanstack/react-table";
import { InstanceOUT } from "api-client";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import SearchSortTable from "components/Table";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import Button from "components/Button";
import { observer } from "mobx-react-lite";
import useToast from "hooks/useToast";

export const BlueprintInstanceList = observer(() => {
  const navigate = useNavigate();
  const { id, audit_id, blueprint_id } = useParams();
  const { selectedProductGroup, floorSet } = caOnlineStore;

  const [instanceLoading, setInstanceLoading] = React.useState(false);
  const [renderedItems, setRenderedItems] = React.useState<InstanceOUT[]>([]);
  const { t } = useTranslation();
  const toast = useToast();

  const handleDuplicate = async (id: string) => {
    setInstanceLoading(true);
    await caOnlineStore.duplicateInstance(id);
    setInstanceLoading(false);
  };

  const handleDelete = async (id: string) => {
    setInstanceLoading(true);

    try {
      const resp = await caOnlineStore.deleteInstanceSet(id);
      if (resp.status === 200) {
        setRenderedItems(caOnlineStore.instanceSet);
        toast(t("audits.successfullyDeleted"), "success");
      } else {
        toast(t("commons.genericErrorMessage"), "warning");
      }
    } catch (error) {
      if (caOnlineStore.selectedBlueprint)
        setRenderedItems(caOnlineStore.selectedBlueprint?.instance_set);
    }
    setInstanceLoading(false);
  };

  React.useEffect(() => {
    (async () => {
      if (!blueprint_id) return;

      setInstanceLoading(true);
      try {
        await caOnlineStore.fetchInstanceSet(blueprint_id);
        setRenderedItems(caOnlineStore.instanceSet);
      } catch (error) {
        if (caOnlineStore.selectedBlueprint)
          setRenderedItems(caOnlineStore.selectedBlueprint?.instance_set);
      }
      setInstanceLoading(false);
    })();
  }, [blueprint_id, renderedItems.length]);

  const blueprintRoute = `/buildings/${id}/audits-online/${audit_id}/blueprints/${blueprint_id}`;
  const addItemRoute = `${blueprintRoute}/add`;

  const handleInstanceEdit = (instanceId: string) => {
    const selectedInstance = renderedItems.find((ins) => ins.id === instanceId);
    caOnlineStore.setSelectedInstance(selectedInstance!);
    navigate(instanceId);
  };

  const useColumns = () => {
    const columns = React.useMemo<ColumnDef<InstanceOUT>[]>(
      () => [
        {
          accessorFn: (row) => row.name,
          id: "instanceName",
          cell: (info) => (
            <div
              className="cursor-pointer"
              onClick={() => handleInstanceEdit(info.row.original.id)}
            >
              {info.row.original.name ?? info.row.original.name_de}
            </div>
          ),
          header: () => <div>{t("audits.instanceTableHeadings.name")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
          size: 250,
        },
        {
          accessorFn: (row) => row.floor_ids,
          id: "floor",
          cell: (info) => {
            const floorIds = info.row.original.floor_ids ?? [];
            const filteredFloors = floorSet.filter((floor) =>
              floorIds.includes(floor.id)
            );
            if (!filteredFloors || filteredFloors.length === 0) {
              return <>-</>;
            }
            return (
              <div>{filteredFloors.map((floor) => floor.name).join(", ")}</div>
            );
          },
          header: () => <div>{t("audits.instanceTableHeadings.floor")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.room_name,
          id: "room",
          cell: (info) => <div>{info.row.original.room_name}</div>,
          header: () => <div>{t("audits.instanceTableHeadings.room")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.instance_amount,
          id: "amount",
          cell: (info) => (
            <div>{`${info.row.original.instance_amount} ${
              selectedProductGroup?.unit ?? ""
            }`}</div>
          ),
          header: () => <div>{t("audits.instanceTableHeadings.amount")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row,
          id: "editDeleteActions",
          header: () => <div>{""}</div>,
          cell: (info) => (
            <EditDeleteItemMenu
              withDuplicate
              id={info.row.original.id}
              onEdit={() => handleInstanceEdit(info.row.original.id)}
              onDuplicate={handleDuplicate}
              onDelete={handleDelete}
            />
          ),
          enableColumnFilter: false,
          enableSorting: false,
          sizw: 50,
        },
      ],
      []
    );
    return columns;
  };

  return (
    <div className="my-4">
      <div>
        {renderedItems && selectedProductGroup && renderedItems.length > 0 && (
          <div className="pb-5 pt-5">
            <div className="font-semibold mb-6 text-lg">
              {t("audits.items")}
            </div>
            <SearchSortTable
              scrollDivClassName="!max-h-[320px] !z-10"
              data={renderedItems}
              getColumns={useColumns as () => ColumnDef<unknown, unknown>[]}
              borderedStyle
              zebraStriped
              dataFetchLoading={instanceLoading}
            />
          </div>
        )}

        {renderedItems.length === 0 && (
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-500">
              {t("audits.noItemsCreated")}
            </span>
            <Link
              onClick={() => caOnlineStore.setSelectedInstance(undefined)}
              to={addItemRoute}
            >
              <Button leadingIcon={<PlusIcon />} disabledClasses="w-full">
                {t("audits.addItems")}
              </Button>
            </Link>
          </div>
        )}

        {renderedItems.length > 0 && (
          <Link
            onClick={() => caOnlineStore.setSelectedInstance(undefined)}
            to={addItemRoute}
            className="w-fit z-10 md:block mt-2 bottom-4"
          >
            <Button
              leadingIcon={<PlusIcon />}
              disabledClasses="w-full"
              className="!rounded-full"
            >
              {t("audits.addItems")}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
});
