import React from "react";
import { ProductGroupOUT } from "api-client";
import { useRecoilValue } from "recoil";
import { productGroupsAtom } from "store/atoms/audits";

export default function useAuditProductGroup(id: string) {
  const productGroups = useRecoilValue(productGroupsAtom);
  const [pg, setPg] = React.useState<ProductGroupOUT>();
  React.useEffect(() => {
    setPg(
      productGroups
        ? productGroups.find((p: ProductGroupOUT) => p.id === id)
        : undefined
    );
  }, [productGroups]);
  return pg;
}
