import { db } from "../../../api-client-local/db";
import { AuditOUT, auditsApi } from "../../../api-client";
import { setLocalAudit } from "../../../api-client-local/audits";
import { TFunction } from "react-i18next";
import { getLocalAuthHeader } from "api-client-local/utils";

export interface IRestoreProps {
  setCurrentAudit: (value: AuditOUT) => void;
  audit_id: string;
  t: TFunction<"translation", undefined>;
}

/**
 * Clears local audit and replaces it with what's on the server
 * @param props
 */
export async function performRestore(props: IRestoreProps) {
  const { audit_id, setCurrentAudit, t } = props;
  if (!audit_id) {
    throw Error(`audit_id not found for sync.`);
  }

  try {
    const authHeader = await getLocalAuthHeader();
    await clearLocalAudits();

    const serverAuditResponse = await auditsApi.caApiV1RoutersAuditGetAudit(
      audit_id,
      authHeader
    );
    const audit = serverAuditResponse.data as AuditOUT;
    if (audit) {
      setLocalAudit(audit);
      setCurrentAudit(audit);
      return {
        status: "success",
        message: t("audits.restoreSuccess"),
      };
    }
  } catch (error) {
    return {
      status: "fail",
      message: `${t("audits.restoreFailed")} - ${error}`,
    };
  }

  return {
    status: "fail",
    message: `${t("audits.restoreFailed")}`,
  };
}

export async function clearLocalAudits() {
  await db.images.clear();
  await db.audits.clear();
  await db.blueprintIds.clear();
  await db.instances.clear();
}
