import { WifiIcon } from "@heroicons/react/outline";
import { useConnectionStatus } from "hooks/useConnectionStatus";
import React from "react";

const InternetConnectionInfo: React.FC = () => {
  const isNetworkAvailable = useConnectionStatus();

  const iconColor = isNetworkAvailable ? "indigo" : "gray";
  const badgeColor = isNetworkAvailable ? "bg-green-500" : "bg-red-500";

  return (
    <div className="flex">
      <WifiIcon color={iconColor} className="w-5" />
      <span className={`w-2 h-2 rounded-full ${badgeColor}`} />
    </div>
  );
};

export default InternetConnectionInfo;
