import React from "react";
import ListSectionHeader from "./ListSectionHeader";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { numberCustomFormat } from "utils";
import { detailViewV2Store } from "store/IfcMapping/DetailViewV2Store";
import { observer } from "mobx-react-lite";
import { Financial } from "api-client";

interface Props {
  financial: Financial | null | undefined;
  onReload?: () => void;
}

const FinancialListItem = ({ financial, onReload }: Props) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const isObjectMapped = detailViewV2Store.isObjectMapped;

  const key = isEng
    ? financial?.field_name
    : financial?.field_name_de ?? financial?.field_name;
  const value = financial?.value ?? 0;

  return (
    <>
      <ListSectionHeader title={t("detailView.reportsTab.financial")} />
      <>
        {financial && isObjectMapped ? (
          <ul className="border border-gray-300 rounded-md p-0 ">
            <li
              className={`text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
            >
              {key}
              <div className="text-sm font-normal">
                {numberCustomFormat(value ?? 0)}
              </div>
            </li>
          </ul>
        ) : (
          <div className="mt-2">
            <Alert
              type="info"
              description={t("detailView.reportsTab.noFinantialTooltip")}
              actionButton={t("detailView.reportsTab.reload")}
              actionButtonOnClick={onReload}
            />
          </div>
        )}
      </>
    </>
  );
};

export default observer(FinancialListItem);
