import React from "react";
import LoadingIcon from "components/LoadingIcon";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { isMobileSize } from "utils";
import clsx from "clsx";

export default observer(function CalculationsRunningIndicator(props: {
  className?: string;
}) {
  const { t } = useTranslation();

  if (isMobileSize()) return;

  return (
    <div className={clsx("flex items-center ml-3 text-sm", props.className)}>
      <div className="text-gray-500 mr-2">
        {t("dashboard.calculationsAreInProgress")}
      </div>
      <LoadingIcon type="primary" />
    </div>
  );
});
