import React, { useEffect } from "react";
import ObjectsTable from "./ObjectsTable";
import { useParams } from "react-router";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import SettingsBar from "./SettingsBar";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import useToast from "hooks/useToast";
import { useMappingTools } from "./useMappingTools";

const MappingEditor = observer(() => {
  const { ifc_id } = useParams();
  const { groupByOptions } = useMappingTools();
  const toast = useToast();

  const getMappingStatusAsync = async () => {
    if (!ifc_id) return;
    await ifcMappingStore.getObjectsMappingStatus(ifc_id);
    if (ifcMappingStore.errorMessage) {
      toast(ifcMappingStore.errorMessage, "warning");
    }
    mappingEditorStore.setGroupBy([String(groupByOptions[2].id)]);
  };

  useEffect(() => {
    getMappingStatusAsync();
    return () => {
      ifcMappingStore.clearErrorMessage();
      mappingEditorStore.setMappingStatusSelected("");
      localStorage.removeItem("mapping_status");
    };
  }, []);

  return (
    <div
      className={clsx(
        "border border-gray-300 rounded-md text-gray-600 text-xs relative"
      )}
    >
      <SettingsBar />
      <ObjectsTable />
    </div>
  );
});

export default React.memo(MappingEditor);
