import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  value: number;
  unit: string;
  componentAmount?: number;
}

export default function ItemAmount({ value, unit, componentAmount }: Props) {
  const { t } = useTranslation();

  const instanceUnit =
    unit !== "pcs" ? (
      <span>
        {unit.endsWith("3") || unit.endsWith("2") ? (
          <>
            {unit.slice(0, unit.length - 1)}
            <sup>{unit[unit.length - 1]}</sup>
          </>
        ) : (
          unit
        )}
      </span>
    ) : (
      t("audits.pieces")
    );

  return (
    <div className="text-xs flex space-x-1">
      <span>{value} </span>
      <span>{instanceUnit}</span>
      {componentAmount && (
        <span className="text-xs ml-1">
          ({componentAmount} {instanceUnit}/{t("audits.blueprint")})
        </span>
      )}
    </div>
  );
}
