import deDE from "./locales/de/index.json";
import enGB from "./locales/en/index.json";

export default {
  en: {
    translation: enGB,
  },
  de: {
    translation: deDE,
  },
};
