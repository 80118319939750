import { ChevronDownIcon } from "@heroicons/react/solid";
import DebouncedInput from "components/DebounceInput";
import React from "react";

export interface CustomFilterItem {
  id: string;
  name: string;
}
function CustomFilter({
  name,
  items = [],
  handleChange,
  className,
  placeholder,
}: {
  name: string;
  items: CustomFilterItem[];
  handleChange: (value: string | number) => void;
  className?: string;
  placeholder?: string;
}) {
  const firstValue = items.length ? items[0] : null;
  const [filterValue, setFilterValue] = React.useState<string | number>("");

  React.useEffect(() => {
    handleChange(filterValue);
  }, [filterValue]);

  const sortedList = items.sort((a, b) => a.name.localeCompare(b.name));

  return typeof firstValue === "number" ? (
    <></>
  ) : (
    <div className={className}>
      <datalist id={name + "list"}>
        {sortedList
          .slice(0, 5000)
          .map((item: CustomFilterItem, index: number) => (
            <option value={item.name} key={index} />
          ))}
      </datalist>
      <DebouncedInput
        type="text"
        icon={
          <ChevronDownIcon
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        }
        value={(filterValue ?? "") as string}
        onChange={(value) => setFilterValue(value)}
        placeholder={placeholder ?? ""}
        list={name + "list"}
        height="middle"
      />
    </div>
  );
}

export default CustomFilter;
