import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import clsx from "clsx";
import { PropertyBrowserTreeNode } from "api-client";
import ReactDOM from "react-dom";
import { IconChevronDown } from "@tabler/icons-react";

export interface SimpleColumnsMenuProps<T> {
  items: T[];
  handleSelect: (value: T) => void;
  isEng: boolean;
  containerClassName?: string;
  current?: T;
  wrapperID?: string;
  leftOffset?: number;
  parentScrollTop?: number;
  scrollAreaHeight?: number;
  placeholder?: string;
  icon?: React.ReactElement;
  isOpen?: (open: boolean) => void;
}

export default function SimpleColumnsMenu<Item extends PropertyBrowserTreeNode>(
  props: SimpleColumnsMenuProps<Item>
) {
  const inputRef = React.useRef<HTMLDivElement>(null);
  const wrapperEl = document.getElementById(props.wrapperID as string);

  function calcTopPosition() {
    return Math.round(
      Number(inputRef.current?.getBoundingClientRect().top) -
        Number(wrapperEl?.getBoundingClientRect().y) +
        Number(inputRef.current?.getBoundingClientRect().height)
    );
  }

  function menuContent() {
    return (
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Listbox.Options
          className={clsx(
            "z-10 absolute mt-1 max-h-60 !max-w-fit min-w-[260px] overflow-auto rounded-md",
            "sm:text-sm border border-gray-300 bg-white py-1 text-base shadow-lg focus:outline-none"
          )}
          style={{
            top: wrapperEl ? calcTopPosition() : 15,
            left: Number(props.leftOffset),
          }}
        >
          {props.items.map((item, itemIdx) => (
            <Listbox.Option
              key={itemIdx}
              className={({ active }) =>
                `relative cursor-pointer select-none py-2 pl-4 pr-4 group/listBox ${
                  active
                    ? "bg-indigo-50 text-indigo-700"
                    : "bg-white text-gray-900"
                }`
              }
              value={item}
            >
              {({ selected }) => (
                <div className="flex flex-col">
                  <span
                    className={`block truncate ${
                      selected ? "font-medium" : "font-normal"
                    }`}
                  >
                    {props.isEng ? item.name : item.name_de}
                  </span>
                  <span className="block truncate text-xs text-gray-500 group-hover/listBox:text-indigo-500">
                    {props.isEng ? item.group_name : item.group_name_de}
                  </span>
                </div>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Transition>
    );
  }

  return (
    <div className={props.containerClassName} ref={inputRef}>
      <Listbox value={props.current} onChange={props.handleSelect}>
        <Listbox.Button className="flex items-center">
          {({ open }) => {
            props.isOpen?.(open);

            return props.icon ? (
              props.icon
            ) : (
              <div
                className={clsx(
                  "rounded-md border border-gray-300 px-2 py-1.5 h-9 !max-w-fit min-w-[260px] bg-white",
                  "flex items-center justify-between"
                )}
              >
                <span>
                  {(props.isEng
                    ? props.current?.name
                    : props.current?.name_de) ?? (
                    <span className="text-gray-500">{props?.placeholder}</span>
                  )}
                </span>
                <IconChevronDown
                  className={clsx("h-5 w-5", open ? "rotate-180" : "")}
                />
              </div>
            );
          }}
        </Listbox.Button>
        {wrapperEl
          ? ReactDOM.createPortal(menuContent(), wrapperEl)
          : menuContent()}
      </Listbox>
    </div>
  );
}
