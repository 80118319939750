import { SortingState } from "@tanstack/react-table";
import { ProductSearchOutput } from "api-client";
import { DropdownOption } from "components/DropdownCheckbox";
import { TextSearchItem } from "components/TextSearchInput";
import { ObjectsResponse } from "store/IfcMapping/types";
import { CurrentRow } from "features/MappingTools/ObjectsTable";
import { dynamicColumnsBorder } from "features/MappingTools/utils";
import { makeAutoObservable, runInAction } from "mobx";
import { ifcMappingStore } from "./IFCMappingStore";

export interface ProductsProps {
  count: number;
  items: ProductSearchOutput[];
}

export interface InfoColumns {
  length: boolean;
  width: boolean;
  height: boolean;
  area: boolean;
  volume: boolean;
}

export interface CalculatedColumns {
  gwp: boolean;
  mass: boolean;
}

class MEStore {
  autoMappLoading = false;
  undoMappingLoading = false;
  resetChildTable: { [key: string]: boolean } = {};
  resetTable = false;
  showAllObjects = false;
  mappingStatusSelected = "";
  selectedGroupByItems: DropdownOption[] = [];
  removeSelectedGroupByItem: DropdownOption | undefined = undefined;
  groupBy: string[] = [];
  columnsFilter: { [key: string]: string } = {
    ifc_entity: "",
    material: "",
    component: "",
  };
  filterItems: { [key: string]: TextSearchItem[] } = {
    ifc_entity: [],
    material: [],
    component: [],
  };
  objectsData: ObjectsResponse = {
    items: [],
    count: 0,
  };
  dataFetchLoading = false;
  sorting: SortingState = [];
  currentRow: CurrentRow = {
    parent: undefined,
    row: undefined,
  };
  infoColumns: InfoColumns = {
    length: true,
    width: true,
    height: true,
    area: true,
    volume: true,
  };
  calculatedColumns: CalculatedColumns = {
    gwp: true,
    mass: true,
  };
  mappingMatchLoading: boolean = false;
  openedDetailsPopup: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentRow(currentRow: CurrentRow) {
    this.currentRow = currentRow;
  }

  setAutoMappLoading(autoMappLoading: boolean) {
    this.autoMappLoading = autoMappLoading;
  }

  setUndoMappingLoading(undoMappingLoading: boolean) {
    this.undoMappingLoading = undoMappingLoading;
  }

  setResetChildTable(resetChildTable: { [key: string]: boolean }) {
    this.resetChildTable = resetChildTable;
  }

  setShowAllObjects(showAllObjects: boolean) {
    runInAction(() => {
      this.showAllObjects = showAllObjects;
    });
  }

  setMappingMatchLoading(mappingMatchLoading: boolean) {
    this.mappingMatchLoading = mappingMatchLoading;
  }

  setMappingStatusSelected(mappingStatusSelected: string) {
    this.mappingStatusSelected = mappingStatusSelected;
  }

  setSelectedGroupByItems(selectedGroupByItems: DropdownOption[]) {
    this.selectedGroupByItems = selectedGroupByItems;
  }

  setRemoveSelectedGroupByItem(
    removeSelectedGroupByItem: DropdownOption | undefined
  ) {
    this.removeSelectedGroupByItem = removeSelectedGroupByItem;
  }

  setColumnFilters(key: string, value: string) {
    this.columnsFilter = { ...this.columnsFilter, [key]: value };
  }

  setObjectsData(response: ObjectsResponse) {
    this.objectsData = response;
  }

  setFilterItems(key: string, items: TextSearchItem[]) {
    this.filterItems = { ...this.filterItems, [key]: items };
  }

  setResetTable(resetTable: boolean) {
    this.resetTable = resetTable;
  }

  setGroupBy(groupBy: string[]) {
    this.groupBy = groupBy;
  }

  setDataFetchLoading(dataFetchLoading: boolean) {
    this.dataFetchLoading = dataFetchLoading;
  }

  setSorting(sorting: SortingState) {
    this.sorting = sorting;
  }

  setInfoColumns(infoColumns: InfoColumns) {
    this.infoColumns = infoColumns;
  }

  setCalculatedColumns(calculatedColumns: CalculatedColumns) {
    this.calculatedColumns = calculatedColumns;
  }

  setOpenedDetailsPopup(openedDetailsPopup: boolean) {
    this.openedDetailsPopup = openedDetailsPopup;
  }

  getHiddenColumns = () => {
    const groupBy = this.selectedGroupByItems.map((item) => String(item.id));
    return {
      component: !!groupBy?.find((item) => item === "component"),
      ifc_entity: !!groupBy?.find((item) => item === "ifc_entity"),
      floor: !!groupBy?.find((item) => item === "floor"),
      material: !!groupBy?.find((item) => item === "material"),
      predefinedType: !!groupBy?.find((item) => item === "predefinedType"),
      length: this.infoColumns.length,
      width: this.infoColumns.width,
      height: this.infoColumns.height,
      area: this.infoColumns.area,
      volume: this.infoColumns.volume,
      gwp: this.calculatedColumns.gwp,
      mass: this.calculatedColumns.mass,
    };
  };

  borderStyle(staticColumnsCount: number) {
    const groupByColumnsCount = Number(this.selectedGroupByItems.length);

    const calculatedColumnsVisible =
      Object.values(this.calculatedColumns).filter((item) => item).length > 0;

    const infoColumnsCount = Object.values(this.infoColumns).filter(
      (item) => item
    ).length;

    const infoColumnsBorder = infoColumnsCount
      ? dynamicColumnsBorder(staticColumnsCount + groupByColumnsCount)
      : "";

    const calculatedColumnsBorder = calculatedColumnsVisible
      ? dynamicColumnsBorder(
          staticColumnsCount + groupByColumnsCount + infoColumnsCount
        )
      : "";

    return `${infoColumnsBorder} ${calculatedColumnsBorder}`;
  }

  async fetchObjects(
    start: number,
    size: number,
    update?: boolean,
    ifc_id?: string
  ) {
    if (this.dataFetchLoading) return;
    const sortBy = this.sorting?.length ? this.sorting[0]?.id ?? "" : "";
    const sortDirection = this.sorting?.length
      ? this.sorting[0]?.desc
        ? "DESC"
        : "ASC"
      : "";

    if (this.showAllObjects) {
      await this.getAllObjectsData(
        start,
        size,
        update,
        sortBy,
        sortDirection,
        ifc_id
      );
    } else if (this.groupBy.length) {
      await this.getObjectsGroupedByData(
        start,
        size,
        update,
        sortBy,
        sortDirection,
        ifc_id
      );
    }
  }

  async getAllObjectsData(
    start: number,
    size: number,
    update?: boolean,
    sortBy?: string,
    sortDirection?: string,
    ifc_id?: string
  ) {
    if (!ifc_id) return;
    this.setDataFetchLoading(true);
    const receivedData = await ifcMappingStore.getAllObjects(
      ifc_id,
      this.mappingStatusSelected,
      this.columnsFilter.ifc_entity,
      this.columnsFilter.material,
      this.columnsFilter.component,
      sortBy,
      sortDirection,
      size,
      start
    );
    this.setDataFetchLoading(false);
    this.updateData(receivedData, update);
  }

  async getObjectsGroupedByData(
    start: number,
    size: number,
    update?: boolean,
    sortBy?: string,
    sortDirection?: string,
    ifc_id?: string
  ) {
    if (!ifc_id) return;
    this.setDataFetchLoading(true);
    const receivedData = await ifcMappingStore.getCategoriesGrouped(
      ifc_id,
      this.groupBy?.join(",") || "",
      this.mappingStatusSelected,
      this.columnsFilter.ifc_entity,
      this.columnsFilter.material,
      this.columnsFilter.component,
      sortBy,
      sortDirection,
      size,
      start
    );
    this.setDataFetchLoading(false);
    this.updateData(receivedData, update);
  }

  updateData(receivedData: void | ObjectsResponse, update?: boolean) {
    if (update && (receivedData as ObjectsResponse)?.items) {
      this.setObjectsData({
        items: [...(receivedData as ObjectsResponse).items],
        count: (receivedData as ObjectsResponse).count,
      });
    } else if ((receivedData as ObjectsResponse)?.items) {
      this.setObjectsData({
        items: [
          ...this.objectsData.items,
          ...(receivedData as ObjectsResponse).items,
        ],
        count: (receivedData as ObjectsResponse).count,
      });
    }
  }

  // Singleton instance of Mapping Editor Store
  static instance: MEStore;

  static getInstance(): MEStore {
    if (!MEStore.instance) {
      MEStore.instance = new MEStore();
    }
    return MEStore.instance;
  }
}

export const mappingEditorStore = MEStore.getInstance();
