import React, { useEffect, useState } from "react";
import MainLayout from "layouts/MainLayout";
import { useNavigate } from "react-router";
import clsx from "clsx";
import Alert from "components/Alert";
import { useTranslation } from "react-i18next";
import { useAuth0, User } from "@auth0/auth0-react";
import gettingStartImage from "assets/images/getting_started_tile.svg";
import feedbackImage from "assets/images/feedback-icon.svg";
import requestImage from "assets/images/feature_request_tile.svg";
import AddEditBuilding from "features/Building/AddEditBuilding";
import RevitPluginDashboardTile from "features/Revit/RevitPluginDashboardTile";
import useFeatureFlag from "hooks/useFeatureFlag";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import InitialLoading from "./Onboarding/InitialLoading";
import { userStore } from "store/UserStore";

const Dashboard = observer(() => {
  const { user } = useAuth0<User>();
  const { onboarded, userProfile } = userStore;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showAddBuilding, setShowAddBuilding] = useState(false);
  const { isFeatureVisible } = useFeatureFlag();

  useEffect(() => {
    !onboarded && navigate("/onboarding", { replace: true });
  }, [onboarded]);

  useEffect(() => {
    buildingStore.setCurrentBuilding(undefined);
  }, []);

  function featureRequestTile() {
    return (
      <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden">
        <div className="text-base text-gray-900 font-semibold p-4">
          {t("dashboard.featureRequests")}
        </div>
        <div className="flex p-4">
          <img width={120} src={requestImage} />
          <div className="ml-6 text-sm">
            <div className="font-semibold text-gray-900">
              {t("dashboard.haveAWish")}
            </div>
            <div className="text-gray-700 mb-2">
              {t("dashboard.haveAWishText")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function feedbackTile() {
    return (
      <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden">
        <div className="text-base text-gray-900 font-semibold p-4">
          {t("dashboard.feedbackSupport")}
        </div>
        <div className="flex p-4">
          <img width={120} src={feedbackImage} />
          <div className="ml-6 text-sm">
            <div className="font-semibold text-gray-900">
              {t("dashboard.feedbackSupportTitle")}
            </div>
            <div className="text-gray-700 mb-2">
              {t("dashboard.feedbackSupportText")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function gettingStatedTile() {
    return (
      <div className="border mb-6 border-gray-300 bg-white rounded-lg overflow-hidden">
        <div className="text-base text-gray-900 font-semibold p-4">
          {t("dashboard.gettingStarted")}
        </div>
        <div className="flex p-4">
          <img width={120} src={gettingStartImage} />
          <div className="ml-6 text-sm">
            <div className="font-semibold text-gray-900">
              {t("dashboard.createABuilding")}
            </div>
            <div className="text-gray-700 mb-2">
              {t("dashboard.createABuildingText")}
            </div>
            <div className="font-semibold text-gray-900">
              {t("dashboard.UploadAndMap")}
            </div>
            <div className="text-gray-700 mb-2">
              {t("dashboard.UploadAndMapText")}
            </div>
            <div className="font-semibold text-gray-900">
              {t("dashboard.getResults")}
            </div>
            <div className="text-gray-700 mb-2">
              {t("dashboard.getResultsText")}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!userProfile?.first_name) return <InitialLoading />;
  return (
    <MainLayout className="px-6 flex justify-center">
      <div className="flex flex-col items-center min-h-screen">
        <h2
          className={clsx(
            "px-3 lg:mb-3 tracking-wide text-center font-medium text-sm",
            "sm:text-xl lg:text-4xl text-gray-90 mt-8"
          )}
        >
          <div>{`${t("dashboard.hello")} ${
            userProfile ? userProfile.first_name : user?.given_name
          },`}</div>
          <div>{t("dashboard.welcomeBack")}</div>
        </h2>
        <Alert
          className="my-8 hidden"
          type="info"
          title={t("dashboard.alertMessage")}
        />
        <div className="mb-8 mt-6 md:grid grid-cols-2 gap-5">
          <div>
            {gettingStatedTile()}
            {isFeatureVisible("revit") ? <RevitPluginDashboardTile /> : null}
          </div>
          <div>
            {feedbackTile()}
            {featureRequestTile()}
          </div>
        </div>
      </div>
      {showAddBuilding && (
        <AddEditBuilding
          open={showAddBuilding}
          close={() => setShowAddBuilding(false)}
          type="add"
        />
      )}
    </MainLayout>
  );
});

export default Dashboard;
