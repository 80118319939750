import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { BuildingOUT } from "api-client";
import { isTabletSize } from "utils";
import { sidebarOpenedAtom } from "store/atoms";
import { useSetRecoilState } from "recoil";
import Collapse from "components/Collapse";
import SortedBuildingsList from "./SortedBuildingsList";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";

const SharedBuildingsList = observer(() => {
  const { t } = useTranslation();
  const { currentBuilding, sharedBuildings } = buildingStore;
  const navigate = useNavigate();
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);
  const { id } = useParams();
  const showBuildingDetail = (building: BuildingOUT) => {
    if (!building) return;
    buildingStore.setCurrentBuilding(building);
    navigate(`/buildings/${building.id}`);
    if (isTabletSize()) {
      setSidebarOpened(false);
    }
  };

  useEffect(() => {
    const current = sharedBuildings.items.find(
      (item) => item.building.id === id
    );
    current &&
      !currentBuilding &&
      buildingStore.setCurrentBuilding(current.building);
  }, [sharedBuildings]);

  if (!sharedBuildings.items?.length) return <></>;

  return (
    <Collapse
      headerTitle={`${t("dashboard.sharedBuildings")} (${
        sharedBuildings.items.length
      })`}
      headerClassName="border-b"
    >
      <SortedBuildingsList
        buildings={sharedBuildings.items.map((item) => item.building)}
        showDetail={showBuildingDetail}
        className="px-3 text-sm pt-2"
      />
    </Collapse>
  );
});

export default SharedBuildingsList;
