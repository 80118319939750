import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useUser } from "contexts/user";
import { useTranslation } from "react-i18next";
import AuthLayout from "layouts/AuthLayout";
import Alert from "components/Alert";
import Button from "components/Button";
import ProfileMenu from "features/User/ProfileMenu";
import { userApi } from "api-client";
import useToast from "hooks/useToast";
import InitialLoading from "./InitialLoading";
import { userStore } from "store/UserStore";
import { observer } from "mobx-react-lite";

const Verify = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const { userProfile, verified, authHeader } = userStore;
  const { t } = useTranslation();
  const toast = useToast();

  useEffect(() => {
    if (!verified) return;
    return navigate("/onboarding", { replace: true });
  }, [verified, userProfile]);

  const resendVerification = async () => {
    if (authHeader) {
      try {
        const response =
          await userApi.concularApiV1RoutersUserResendVerificationEmail(
            authHeader
          );
        if (response.status === 200) {
          toast(t("onboarding.verifyEmailButtonSuccess"), "success");
        }
      } catch (err: unknown) {
        console.error(err);
        toast(t("onboarding.verifyEmailButtonError"), "warning");
      }
    }
  };

  if (verified === undefined) return <InitialLoading />;
  return (
    <AuthLayout className="max-h-screen">
      <div className="mx-auto w-3/4 px-4 sm:px">
        <Alert
          type="success"
          title={t("onboarding.successfullyRegistered")}
          className="mx-auto pb-8 mb-8"
        >
          <div className="text-sm">
            {t("onboarding.successfullyRegisteredMessage")}
          </div>
        </Alert>
        <Alert
          type="attention"
          title={t("onboarding.verifyEmailTitle")}
          className="mx-auto pb-8 mb-8"
        >
          <div className="text-sm">
            {t("onboarding.verifyEmailMessage1")}
            <span className="font-semibold">{user?.email}</span> <br />
            {t("onboarding.verifyEmailMessage2")}
          </div>
        </Alert>

        <Button
          width="fit-content"
          className="mx-auto"
          onClick={resendVerification}
        >
          {t("onboarding.verifyEmailButton")}
        </Button>
        <div className="flex absolute left-1 top-2">
          <ProfileMenu onboarding />
        </div>
      </div>
    </AuthLayout>
  );
};

export default observer(Verify);
