import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthLayout from "layouts/AuthLayout";
import JoinOrganisation from "features/Organisation/JoinOrganisation";
import ProfileMenu from "features/User/ProfileMenu";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/Button";
import Alert from "components/Alert";
import Input from "components/Input";
import { userApi } from "api-client";
import Checkbox from "components/Checkbox";
import { observer } from "mobx-react-lite";
import InitialLoading from "./InitialLoading";
import { userStore } from "store/UserStore";

const Onboarding = observer(() => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const {
    authHeader,
    initialLoading,
    onboardingLoading,
    onboarded,
    verified,
    userProfile,
  } = userStore;
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const givenNameRef = React.useRef<HTMLInputElement>(null);
  const familyNameRef = React.useRef<HTMLInputElement>(null);
  const optinRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (verified || initialLoading) return;
    navigate("/verify", { replace: true });
  }, [verified]);

  useEffect(() => {
    if (!onboarded) return;
    return navigate("/dashboard", { replace: true });
  }, [onboarded]);

  const handleChange = () => {
    if (errorMessage === "") return;
    setErrorMessage("");
  };

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      if (!givenNameRef.current || !familyNameRef.current || !optinRef.current)
        return;
      const first_name = givenNameRef.current.value;
      const last_name = familyNameRef.current.value;
      const terms_accepted = optinRef.current.checked;
      if (!first_name || first_name === "" || first_name.length < 2) {
        return setErrorMessage(t("onboarding.firstNameError"));
      }
      if (!last_name || last_name === "" || last_name.length < 2) {
        return setErrorMessage(t("onboarding.lastNameError"));
      }
      if (!terms_accepted) {
        return setErrorMessage(t("onboarding.optInError"));
      }
      if (!authHeader) return;
      const response = await userApi.concularApiV1RoutersUserUpdateUserProfile(
        { first_name, last_name, terms_accepted },
        authHeader
      );
      if (response.status < 400) {
        userStore.setUserProfile(
          userProfile
            ? {
                ...userProfile,
                first_name,
                last_name,
                terms_accepted,
              }
            : null
        );
      } else {
        throw response.statusText;
      }
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage(err as string);
    }
  };

  if (initialLoading) return <InitialLoading />;
  if (!userProfile?.first_name)
    return (
      <AuthLayout
        noLogo={!!userProfile?.first_name && !!userProfile?.last_name}
        className="max-h-screen"
      >
        <form onSubmit={submit} className="w-full p-6">
          <Input
            ref={givenNameRef}
            labelName={`${t("commons.firstName")}*`}
            name="given_name"
            defaultValue={user?.given_name}
            placeholder={t("commons.enterYourFirstName")}
            onChange={handleChange}
            containerClassName="w-full"
          />
          <Input
            ref={familyNameRef}
            labelName={`${t("commons.lastName")}*`}
            name="family_name"
            defaultValue={user?.family_name}
            placeholder={t("commons.enterYourLastName")}
            onChange={handleChange}
            containerClassName="w-full"
          />

          <Checkbox
            ref={optinRef}
            labelElement={
              <div
                dangerouslySetInnerHTML={{
                  __html: t("onboarding.optintermsandprivacypolicy", {
                    link: "/terms-conditions",
                    class: "text-indigo-600 hover:text-indigo-700",
                  }),
                }}
              ></div>
            }
            id="terms_accepted"
            defaultChecked={false}
          />

          <div className="flex pt-4 items-center justify-center">
            <Button type="primary" htmlType="submit" width="fit-content">
              {t("onboarding.continue")}
            </Button>
          </div>
        </form>
        <div className="flex absolute left-1 top-2">
          <ProfileMenu onboarding />
        </div>
        {errorMessage !== "" && (
          <Alert
            type="warning"
            title={errorMessage}
            className="mt-7 max-w-[500px]"
          />
        )}
      </AuthLayout>
    );
  if (onboardingLoading) return <InitialLoading />;
  if (userProfile?.first_name && !onboarded)
    return (
      <AuthLayout noLogo>
        <JoinOrganisation onboarding />
        <div className="flex absolute left-1 top-2">
          <ProfileMenu onboarding />
        </div>
      </AuthLayout>
    );
});

export default Onboarding;
