import React from "react";
import { useTranslation } from "react-i18next";

export default function InputLabel(props: {
  name: string;
  labelName: string;
  required?: boolean;
  showError?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <label
      title={props.required ? t("audits.requiredField") : undefined}
      htmlFor={props.name}
      className={`text-xs sm:text-sm mb-1 flex items-center ${
        props.showError ? "text-red-700" : "text-gray-700"
      }`}
    >
      {props.labelName} {props.required && <span>*</span>}
    </label>
  );
}
