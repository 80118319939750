import React from "react";
import Checkbox from "components/Checkbox";
import { useTranslation } from "react-i18next";
import { certExportStore } from "store/CertExportStore";

const ConfigureDGNBVersion = () => {
  const { certExporter } = certExportStore;

  const { i18n } = useTranslation();
  const isEnglish = i18n.language === "en";

  return (
    <>
      <div>
        {certExporter.dgnbVersionValues?.map((option) => (
          <div key={option.id}>
            <Checkbox
              id={option.id}
              checked={option.isChecked}
              labelElement={isEnglish ? option.value : option.value_de}
              onChange={() => certExportStore.handleCheckboxChange(option.id)}
              containerClassName="py-1"
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default ConfigureDGNBVersion;
