import { ArrowLeftIcon } from "@heroicons/react/outline";
import Form from "components/Form";
import useAuditFormReset from "hooks/useAuditFormReset";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  addedComponentImagesAtom,
  addedComponentsAtom,
  addedImagesAtom,
  componentCurrentStepAtom,
  currentAuditAtom,
  formLoadingAtom,
  generatedBlueprintNameAtom,
  selectedComponentProductGroupAtom,
  selectedManufacturerAtom,
  selectedProductGroupAtom,
  stepAtom,
  stepCountAtom,
  stepperOpenedAtom,
} from "store/atoms/audits";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import FormLoadingContainer from "features/Audit/FormLoadingContainer";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import handleBlueprintInvalid from "features/Audit/handlers/handleBlueprintInvalid";
import handleBlueprintAdd from "features/Audit/handlers/handleBlueprintAdd";
import Stepper, { filledStepsAtom } from "components/Stepper";
import useViewportZoomLock from "hooks/useViewportZoomLock";
import GeneratedName from "features/Audit/GeneratedName";
import { LoadingView } from "features/Audit/LoadingView";
import { userStore } from "store/UserStore";
import { observer } from "mobx-react-lite";
import useManufacturerAndProductGroups from "./useManufacturerAndProductGroups";

export interface BlueprintAddForm {
  select_manufacturer: string;
  select_product_group: string;
  descriptive_name: string;
  extra_info: string;
  [key: string]: string;
}

export default observer(function BlueprintAdd() {
  const { userProfile, authHeader } = userStore;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [step, setStep] = useRecoilState(stepAtom);
  const [componentStep, setComponentStep] = useRecoilState(
    componentCurrentStepAtom
  );
  const filledSteps = useRecoilValue(filledStepsAtom);
  const addedComponents = useRecoilValue(addedComponentsAtom);
  const addedImages = useRecoilValue(addedImagesAtom);
  const addedComponentImages = useRecoilValue(addedComponentImagesAtom);
  const setSteps = useSetRecoilState(stepCountAtom);
  const generatedBlueprintName = useRecoilValue(generatedBlueprintNameAtom);
  const setSelectedComponent = useSetRecoilState(
    selectedComponentProductGroupAtom
  );
  const [formLoading, setFormLoading] = useRecoilState(formLoadingAtom);
  const [audit, setAudit] = useRecoilState(currentAuditAtom);
  const selectedProductGroup = useRecoilValue(selectedProductGroupAtom);
  const selectedManufacturer = useRecoilValue(selectedManufacturerAtom);

  const setStepperOpened = useSetRecoilState(stepperOpenedAtom);
  const { loading } = useManufacturerAndProductGroups();

  const hasComponents =
    (selectedProductGroup?.required_components &&
      selectedProductGroup?.required_components.length > 0) ||
    (selectedProductGroup?.optional_components &&
      selectedProductGroup?.optional_components.length > 0);

  const isAddingComponent = componentStep !== 0;

  const reset = useAuditFormReset();
  const steps = React.useMemo(() => {
    const _steps = [
      <Step1 key={0} />,
      <Step2 key={1} />,
      <Step3 key={2} />,
      <Step4 key={3} />,
    ];
    return hasComponents ? _steps : _steps.filter((step) => step.key !== "2");
  }, [hasComponents]);

  useDisableScrollOnNumberInputs();
  useViewportZoomLock();

  React.useEffect(() => {
    setSteps(steps.length);
  }, [steps]);

  React.useEffect(() => {
    setStepperOpened(true);
    return () => {
      setStepperOpened(false);
      reset();
    };
  }, []);

  const handleInvalidSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      handleBlueprintInvalid(e, setStep, setComponentStep);
    },
    []
  );

  const handleSubmit = async (form: BlueprintAddForm) => {
    if (!audit) return console.error(`"audit" undefined.`);
    if (!authHeader) return console.error(`"authHeader" undefined.`);
    if (!userProfile) return console.error(`"userProfile" undefined.`);
    if (!selectedProductGroup)
      return console.error(`"selectedProductGroup" undefined.`);

    const result = await handleBlueprintAdd(
      form,
      {
        selectedProductGroup,
        selectedManufacturer,
        addedComponents,
        setStep,
        setComponentStep,
        userProfile: {
          email: userProfile.email,
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
          username: userProfile.username,
        },
        audit,
        images: addedImages as File[],
        addedComponentImages,
        setAudit,
        authHeader,
        building_id: id,
        generatedBlueprintName,
      },
      (message) => setFormLoading(message !== "" ? t(message) : "")
    );
    if (!result) return console.error(`Error in blueprint.add form.`);
    navigate(
      `/buildings/${id}/audits/${audit.id}/blueprints/${result.id}/instances`
    );
    reset();
  };

  return (
    <div className="pt-2">
      <FormLoadingContainer formLoading={formLoading} />
      {loading !== "" ? (
        <div className="items-center justify-center flex h-[50vh]">
          <LoadingView message={loading} />
        </div>
      ) : (
        <>
          <div className="flex font-semibold pb-2 justify-between relative">
            {isAddingComponent ? (
              <div
                onClick={() => {
                  setComponentStep(0);
                  setSelectedComponent(null);
                }}
                className="cursor-pointer text-sm h-5 text-indigo-700 py-[26.5px] flex items-center"
              >
                <ArrowLeftIcon className="h-4 w-4 mr-2" />{" "}
                {t("audits.backToComponents")}
              </div>
            ) : (
              <Stepper
                filledSteps={filledSteps}
                activeStep={step}
                onClick={setStep}
                length={steps.length}
              />
            )}
          </div>
          <GeneratedName />
          <Form<BlueprintAddForm>
            onInvalid={handleInvalidSubmit}
            handleSubmit={handleSubmit}
          >
            <div className="overflow-hidden">
              <div
                style={{ transform: `translateX(-${step * 100}%)` }}
                className="whitespace-nowrap transition-transform duration-300"
              >
                {steps.map((page, i) => {
                  return (
                    <div
                      key={i}
                      id={`step-${i + 1}`}
                      className={`w-full inline-block align-top pb-2 px-1`}
                    >
                      {page}
                    </div>
                  );
                })}
              </div>
            </div>
          </Form>
        </>
      )}
    </div>
  );
});

export const DataFetcherStrip = ({
  onDataFetching,
}: {
  onDataFetching: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      onClick={onDataFetching}
      className="bg-white w-fit p-2 rounded-md flex items-center mb-2"
    >
      <b className="underline text-sm font-[500] mr-1 cursor-pointer">
        {t("audits.reloadProductData")}
      </b>
      <p className="text-sm font-[400]">{t("audits.reloadProductData2")}</p>
    </div>
  );
};
