import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { IconAlertTriangle, IconInfoCircle } from "@tabler/icons-react";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { observer } from "mobx-react-lite";
import useFeatureFlag from "hooks/useFeatureFlag";

export default observer(function ComponentEditorFooter() {
  const { t } = useTranslation();
  const { isFeatureVisible } = useFeatureFlag();
  const lastStep = isFeatureVisible("component_editor_circularity_step")
    ? 2
    : 1;
  const goNextPage = () =>
    componentsStore.setActiveStep(componentsStore.activeStep + 1);

  const goPreviousPage = () =>
    componentsStore.setActiveStep(componentsStore.activeStep - 1);

  const showHint = () => {
    if (componentsStore.activeStep === 1) {
      return componentsStore.checkStepValidity(1) ? null : (
        <>
          <IconAlertTriangle
            stroke={3}
            className="text-yellow-600 bg-yellow-100 p-1 rounded-full font-semibold min-w-[24px] h-6"
          />
          <div className="text-sm font-normal text-yellow-600 ml-2">
            {t("components.footerHint2")}
          </div>
        </>
      );
    } else {
      return (
        <>
          <IconInfoCircle
            stroke={3}
            className="text-indigo-600 bg-indigo-100 p-1 rounded-full font-semibold min-w-[24px] h-6"
          />
          <div className="text-sm font-normal text-indigo-600 ml-2">
            {t("components.footerHint1")}
          </div>
        </>
      );
    }
  };

  return (
    <div className="sm:flex bottom-0 absolute w-full bg-white px-4 py-2 items-center justify-between border-t border-gray-300">
      {componentsStore.activeStep !== 0 ? (
        <Button
          width="fit-content"
          className="block"
          type="gray"
          onClick={goPreviousPage}
        >
          {t("commons.back")}
        </Button>
      ) : null}
      <div className="flex items-center w-full justify-center pl-4">
        {showHint()}
      </div>
      {componentsStore.activeStep !== lastStep ? (
        <Button
          width="fit-content"
          className="block"
          type="primary"
          onClick={goNextPage}
          disable={
            !componentsStore.checkStepValidity(componentsStore.activeStep)
          }
        >
          {t("commons.next")}
        </Button>
      ) : null}
    </div>
  );
});
