import React from "react";
import TextInput from "components/TextInput";
import TextArea from "components/TextArea";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { BackButton } from "../navigation/BackButton";
import { SubmitButton } from "../navigation/SubmitButton";

const Step4ExtraInfo = () => {
  const { t } = useTranslation();

  const {
    generatedBlueprintName: name,
    manufacturerName,
    productGroupName,
    dimensionsValue,
  } = blueprintCreateStore;

  return (
    <div className="">
      <TextInput
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          blueprintCreateStore.setGeneratedBlueprintName(e.target.value);
        }}
        spellCheck="false"
        onBlur={() => {
          if (name === "") {
            blueprintCreateStore.setGeneratedBlueprintName(
              `${productGroupName ?? ""} ${manufacturerName ?? ""} ${
                dimensionsValue ?? ""
              }`.trim()
            );
          }
        }}
        className="py-5"
        value={name ? name.replace(/\s+/g, " ") : ""}
        labelName="Name"
        name="descriptive_name"
      />
      <div className=" text-gray-700 grid mb-4">
        <span className="mb-1 text-xs">{t("audits.generatedName")}</span>
        <div className="p-2 h-9 text-sm bg-gray-200 rounded-sm py-2  text-gray-400">
          {name}
        </div>
      </div>
      <TextArea name="extra_info" labelName="More Specifications" />
      <div className="flex justify-evenly py-6">
        <BackButton />
        <SubmitButton />
      </div>
    </div>
  );
};

export default observer(Step4ExtraInfo);
