import React from "react";
import ImageUploaderLocal from "components/ImageUploaderLocal";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { ContinueButton } from "../navigation/ContinueButton";
import SelectProductGroupOnline from "features/AuditOnline/SelectProductGroupOnline";
import SelectManufacturerOnline from "features/AuditOnline/SelectManufacturerOnline";
import SkipButton from "../navigation/SkipButton";

const Step1ProductGroupSelect = () => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const { manufacturers, productGroups } = caOnlineStore;

  function handleManufacturerChange(value?: string) {
    const found = manufacturers?.find(
      (manufacturer) => manufacturer.name === value
    );
    if (!found) return;
    blueprintCreateStore.setSelectedManufacturer(found);
    blueprintCreateStore.setManufacturerName(found.name.trim());
  }

  const handleProuductGroupChange = (value?: string) => {
    if (!value) {
      blueprintCreateStore.setProductGroupName("");
    }
    const found = productGroups?.find((group) => {
      return group[isEng ? "name" : "name_de"] === value;
    });
    if (!found) return;
    blueprintCreateStore.setSelectedProductGroup(found);
    blueprintCreateStore.setAddedComponents({});
    blueprintCreateStore.setProductGroupName(
      found[isEng ? "name" : "name_de"].trim()
    );
    blueprintCreateStore.setFilledSteps([]);
  };

  return (
    <>
      <div className="grid">
        <SelectProductGroupOnline
          onReset={() => {
            blueprintCreateStore.setGeneratedBlueprintName("");
            blueprintCreateStore.setFilledSteps([]);
          }}
          onChange={handleProuductGroupChange}
        />
        <ImageUploaderLocal
          id="blueprint-images"
          handleChange={(files) =>
            blueprintCreateStore.setAddedImages(files as File[])
          }
          // required
          name="images"
          multiple
          labelName={t("audits.addImages")}
        />
        <SelectManufacturerOnline
          onReset={() => blueprintCreateStore.setManufacturerName("")}
          onChange={handleManufacturerChange}
        />
      </div>
      <div className="flex justify-evenly gap-4">
        <SkipButton />
        <ContinueButton />
      </div>
    </>
  );
};

export default observer(Step1ProductGroupSelect);
