import { globalStore } from "store/GlobalStore";
import { NotificationType } from "components/Toast";

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
    (
      +c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
    ).toString(16)
  );
}

const useToast = () => {
  const toast = (message: string, type: NotificationType) => {
    globalStore.setToastList([
      ...globalStore.toastList,
      { id: uuidv4(), message, type },
    ]);
  };

  return toast;
};

export default useToast;
