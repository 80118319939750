import { ArrowRightIcon, RefreshIcon } from "@heroicons/react/outline";
import { ExclamationIcon, XIcon } from "@heroicons/react/solid";
import { TStorageInfo, getStorageCapacityInfo } from "api-client-local/utils";
import React, { useEffect, useState } from "react";
import InternetConnectionInfo from "./InternetConnectionInfo";
import ActionMenu from "components/ActionMenu";
import { IconFileExport } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import useSyncStatus from "./SyncBar/useSyncStatus";

interface IStorageInfoProps {
  onExport: () => void;
  onResetStorage: () => void;
  onSyncAndClearStorage: () => void;
  showTooltip: boolean;
  onToggleTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}

const StorageInformation = ({
  onExport,
  onResetStorage,
  onSyncAndClearStorage,
  showTooltip,
  onToggleTooltip,
}: IStorageInfoProps) => {
  const { status } = useSyncStatus();
  const { t } = useTranslation();
  const [storageInfo, setStorageInfo] = useState<TStorageInfo>();
  const [calculating, setCalculating] = useState(false);

  useEffect(() => {
    async function fetchStorageInfo() {
      setCalculating(true);
      const info = await getStorageCapacityInfo();
      setStorageInfo(info);
      setTimeout(() => {
        setCalculating(false);
      }, 1000);
    }
    fetchStorageInfo();
  }, [status]);

  const percentageLeft = Number(storageInfo?.percentageLeft.toFixed(2) ?? "0");
  const percentageUsed = Number(storageInfo?.percentageUsed.toFixed(2) ?? "0");
  const radius = 30;
  const circumference = 70 * Math.PI;
  const offset = circumference - (percentageUsed / 100) * circumference;

  const [renderedItems] = React.useState([
    <>
      <IconFileExport
        className="w-5 h-5 mr-4"
        strokeWidth={2}
        color={"#000000"}
      />
      {t("audits.export")}
    </>,
    <>
      <RefreshIcon className="w-5 h-5 mr-4" strokeWidth={2} color={"#000000"} />
      {t("buildingMaterialResources.clearStorage")}
    </>,
  ]);

  const handleActionSelect = React.useCallback((index: number) => {
    if (index == 0) {
      onExport();
    } else {
      onResetStorage();
    }
  }, []);

  const warningIcon =
    percentageLeft <= 35 ? (
      <ExclamationIcon className="w-4 h-4 text-yellow-400" strokeWidth={3} />
    ) : null;

  const storageProgress = (
    <div className="relative">
      <svg
        className={`svg-indicator w-12 h-12 relative transform translate-x-1 translate-y-1 -rotate-90`}
        viewBox="0 0 100 100"
      >
        <circle
          className="svg-indicator-track text-gray-200 stroke-current"
          cx="50"
          cy="50"
          r={radius}
          strokeWidth="8"
          fill="transparent"
        />
        <circle
          className={`svg-indicator-indication ${
            percentageLeft <= 35 ? "text-red-600" : "text-indigo-600"
          }  stroke-current`}
          cx="50"
          cy="50"
          r={radius}
          strokeWidth="8"
          strokeLinecap="round"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          fill="transparent"
        />
      </svg>
      <div
        onClick={() => onToggleTooltip(!showTooltip)}
        className="cursor-pointer absolute w-4 top-3 left-2"
      >
        {warningIcon}
      </div>
    </div>
  );

  return (
    <div className="relative flex items-center pl-2">
      <InternetConnectionInfo />
      {calculating ? (
        <span className="pl-2 text-xs">{t("audits.calculatingStorage")}</span>
      ) : (
        <div className="relative flex w-fit items-center">
          {storageProgress}
          <div className="flex flex-col ml-2">
            <span className="font-medium md:text-md text-sm ">
              {t("audits.localStorage")}
            </span>
            <span className="flex text-indigo-500 w-fit pr-6 items-center font-semibold text-xs">
              {Number(percentageLeft.toFixed(2) ?? "0")}% {t("audits.left")}
            </span>
          </div>
          <ActionMenu
            dotSize={22}
            className={`flex-1 flex justify-end ml-4`}
            direction={"bottom"}
            onSelect={handleActionSelect}
            items={renderedItems}
            itemClassName={"w-[180px]"}
          />
          {showTooltip && (
            <div className="z-30 w-[310px] md:w-[360px] text-sm tooltip flex flex-col absolute transform -translate-x-28 md:-translate-x-40 translate-y-28 md:translate-y-28 p-4 bg-white rounded-md shadow-md">
              <span
                className={`${
                  percentageLeft <= 35 ? "text-red-500" : "text-black"
                } flex font-medium items-center justify-between`}
              >
                <span className="flex items-center">
                  <span className="mr-2">{warningIcon}</span>
                  {t("audits.percentageLeft", {
                    percentage: percentageLeft,
                  })}
                </span>
                <XIcon
                  className="self-end w-7 h-7 px-0 text-gray-500 hover:bg-gray-200 rounded-full p-1 transition cursor-pointer"
                  onClick={() => onToggleTooltip(false)}
                />
              </span>
              <span className="mt-2">{t("audits.syncChanges1")}</span>
              <span>{t("audits.syncChanges2")}</span>
              <span
                onClick={() => {
                  onToggleTooltip(false);
                  onSyncAndClearStorage();
                }}
                className="flex mt-2 items-center text-indigo-600 font-bold cursor-pointer"
              >
                <p>{t("audits.syncAction")}</p>
                <ArrowRightIcon
                  color="#4238C2"
                  className="w-5 ml-2 font-bold"
                />
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StorageInformation;
