import React from "react";
import { useTranslation } from "react-i18next";
import { ProductGroupOUT } from "api-client";
import { observer } from "mobx-react-lite";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import ButtonAsDiv from "components/ButtonAsDiv";

interface ComponentListItemProps {
  componentProductGroup: ProductGroupOUT;
  isOptional?: boolean;
}

const ComponentListItemOnline = ({
  isOptional,
  componentProductGroup,
}: ComponentListItemProps) => {
  const { i18n } = useTranslation();

  const handleAddComponentRouting = React.useCallback(() => {
    blueprintCreateStore.setComponentCurrentStep(1);
    blueprintCreateStore.setSelectedComponentProductGroup(
      componentProductGroup
    );
    blueprintCreateStore.setSelectedComponent(undefined);
  }, [componentProductGroup.id]);

  return (
    <div className="grid gap-2">
      <ButtonAsDiv
        data-required={true}
        onClick={handleAddComponentRouting}
        color={"outline"}
        className={`mb-2 !justify-start rounded-md col-start-1 col-end-13  ${
          isOptional ? "text-gray-500 !border-gray-300" : ""
        }`}
      >
        <span className="mr-2 text-xl -translate-y-[1px]">+ </span>
        {(i18n.language === "en"
          ? componentProductGroup.name
          : componentProductGroup.name_de) ?? componentProductGroup.name}{" "}
        {isOptional ? null : (
          <span className="ml-1 text-red-700 text-lg">*</span>
        )}
      </ButtonAsDiv>
    </div>
  );
};

export default observer(ComponentListItemOnline);
