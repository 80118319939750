import React, { useCallback, useEffect, useState } from "react";
import InvitedGuests from "./InvitedGuests";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import Tabs from "components/Tabs";
import ManageGuests from "./ManageGuests";
import useToast from "hooks/useToast";
import {
  BuildingInviteeOUT,
  buildinginviteesApi,
  BuildingMemberOUT,
  BuildingOUT,
  userBuildingApi,
} from "api-client";
import moment from "moment";
import Button from "components/Button";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

const ShareBuilding = observer(
  ({
    openShare,
    setOpenShare,
  }: {
    openShare: boolean;
    setOpenShare: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const { t } = useTranslation();
    const { authHeader } = userStore;
    const { currentBuilding, sharedBuildingsUserRoles } = buildingStore;
    const toast = useToast();
    const [invitedGuests, setInvitedGuests] = useState<BuildingInviteeOUT[]>(
      []
    );
    const [confirmedGuests, setConfirmedGuests] = useState<BuildingMemberOUT[]>(
      []
    );
    const [inviteError, setInviteError] = useState({
      type: "",
      open: false,
      email: "",
    });

    const getAllInvitedUsers = useCallback(
      (currentBuilding: BuildingOUT) => {
        if (!currentBuilding || !authHeader)
          return console.error("undefined-currentBuilding");
        buildinginviteesApi
          .concularApiV1RoutersBuildingInviteesGetAllInvitesForBuilding(
            currentBuilding.id,
            authHeader
          )
          .then((response) => {
            if (response.data) {
              setInvitedGuests(response.data);
            }
          })
          .catch((err) => {
            toast(t("shareBuilding.errorInvitees"), "warning");
            console.error("getAllInvitedUsers.error", err);
          });
      },
      [currentBuilding?.id]
    );

    const getAllConfirmedUsers = useCallback(
      (currentBuilding: BuildingOUT) => {
        if (!currentBuilding || !authHeader)
          return console.error("undefined-currentBuilding");

        userBuildingApi
          .concularApiV1RoutersUserBuildingsGetBuildingUsers(
            currentBuilding.id,
            100,
            0,
            authHeader
          )
          .then((response) => {
            if (response.data.items) {
              setConfirmedGuests(response.data.items);
            }
          })
          .catch((err) => {
            toast(t("shareBuilding.errorMembers"), "warning");
            console.error("getAllConfirmedUsers.error", err);
          });
      },
      [currentBuilding?.id]
    );

    const sendInvite = async (userEmail: string | undefined) => {
      if (userEmail && currentBuilding?.id && sharedBuildingsUserRoles) {
        const userRole = sharedBuildingsUserRoles.find(
          (role) => role.role_name === "Guest"
        );
        if (userRole && authHeader) {
          await buildinginviteesApi
            .concularApiV1RoutersBuildingInviteesInviteUserForBuilding(
              {
                building_id: currentBuilding.id,
                email: userEmail,
                role: parseInt(userRole.role_type, 10),
              },
              authHeader
            )
            .then((response) => {
              if (response.status === 201 || response.status === 202) {
                getAllInvitedUsers(currentBuilding);
                toast(
                  t("shareBuilding.succeccfullyInvited", { email: userEmail }),
                  "success"
                );
                getAllConfirmedUsers(currentBuilding);
              }
            })
            .catch((err) => {
              if (err.response?.status === 409) {
                setInviteError({
                  type: err.response.data?.message?.message.includes("guest")
                    ? "guest"
                    : checkForOrganisationError(),
                  open: true,
                  email: userEmail,
                });
              } else {
                toast(t("shareBuilding.invitationError"), "warning");
                console.error("InviteUserForBuilding.error", err);
              }

              function checkForOrganisationError(): string {
                return err.response.data?.message?.message.includes(
                  "organisation"
                )
                  ? "organisation"
                  : "invited";
              }
            });
        }
      }
    };

    const removeInviteeFromBuilding = (guestId: string, guestEmail: string) => {
      if (currentBuilding && guestId && guestEmail && authHeader) {
        buildinginviteesApi
          .concularApiV1RoutersBuildingInviteesRemoveUserFromInviteeList(
            guestId,
            authHeader
          )
          .then((response) => {
            if (response.status === 200) {
              toast(
                `User ${guestEmail} ${t("shareBuilding.succesfullyRemoved")}`,
                "success"
              );
              getAllInvitedUsers(currentBuilding);
              getAllConfirmedUsers(currentBuilding);
            }
          })
          .catch((err) => {
            toast(
              `User ${guestEmail} ${t("shareBuilding.errorRemove")}`,
              "warning"
            );
            console.error("removeInvitee.error", err);
          });
      }
    };

    const removeGuestFromBuilding = (guestId: string, guestEmail: string) => {
      if (currentBuilding && guestId && guestEmail && authHeader) {
        userBuildingApi
          .concularApiV1RoutersUserBuildingsLeaveBuilding(guestId, authHeader)
          .then((response) => {
            if (response.status === 200) {
              toast(
                `User ${guestEmail} ${t("shareBuilding.succesfullyRemoved")}`,
                "success"
              );
              getAllInvitedUsers(currentBuilding);
              getAllConfirmedUsers(currentBuilding);
            }
          })
          .catch((err) => {
            toast(
              `User ${guestEmail} ${t("shareBuilding.errorRemove")}`,
              "warning"
            );
            console.error("removeGuest.error", err);
          });
      }
    };

    useEffect(() => {
      if (!currentBuilding) return;
      getAllInvitedUsers(currentBuilding);
      getAllConfirmedUsers(currentBuilding);
    }, [currentBuilding?.id]);

    const handleErrorTypeTitle = () => {
      switch (inviteError.type) {
        case "guest":
          return t("shareBuilding.alreadyGuestError", {
            email: inviteError.email,
          });
        case "organisation":
          return t("shareBuilding.alreadyMember", {
            email: inviteError.email,
          });
        case "invited":
          return t("shareBuilding.alreadyInvited", {
            email: inviteError.email,
          });
        default:
          return "";
      }
    };

    const handleErrorTypeMessage = () => {
      switch (inviteError.type) {
        case "guest":
          return t("shareBuilding.guestErrorMessage", {
            email: inviteError.email,
          });
        case "organisation":
          return t("shareBuilding.alreadyMemberError", {
            email: inviteError.email,
          });
        case "invited":
          return t("shareBuilding.alreadyInvitedError", {
            email: inviteError.email,
            date: moment(
              invitedGuests.find((guest) => guest.email === inviteError.email)
                ?.modified
            ).format("DD.mm.yyyy"),
          });
        default:
          return "";
      }
    };

    const closeInviteError = () =>
      setInviteError((state) => ({ ...state, open: false }));

    const resendInvite = React.useCallback(() => {
      const user = invitedGuests.find(
        (guest) => guest.email === inviteError.email
      );
      if (user?.id && currentBuilding && authHeader) {
        buildinginviteesApi
          .concularApiV1RoutersBuildingInviteesResendInvitationEmail(
            user?.id,
            authHeader
          )
          .then(() => {
            getAllInvitedUsers(currentBuilding);
            toast(
              `${t("shareBuilding.succeccfullyInvited", {
                email: user.email,
              })}`,
              "success"
            );
          })
          .catch((err) => {
            toast(t("shareBuilding.errorInvitees"), "warning");
            console.error("getAllInvitedUsers.error", err);
          })
          .finally(() =>
            setInviteError((state) => ({ ...state, open: false }))
          );
      }
    }, []);

    return (
      <Modal
        isOpen={openShare}
        setIsOpen={setOpenShare}
        title={t("shareBuilding.shareBuildingWithGuests")}
        disableOutsideClick
        scrollable
        className="max-h-[500px]"
        containerClassName="sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl"
      >
        <Tabs
          options={[
            {
              item: t("shareBuilding.inviteGuests"),
              content: (
                <InvitedGuests
                  invitedGuests={invitedGuests}
                  sendInvite={sendInvite}
                  removeInviteeFromBuilding={removeInviteeFromBuilding}
                />
              ),
            },
            {
              item: t("shareBuilding.manageGuests"),
              content: (
                <ManageGuests
                  confirmedGuests={confirmedGuests}
                  removeGuestFromBuilding={removeGuestFromBuilding}
                />
              ),
            },
          ]}
          buttonTabStyle
          listClassName="px-3 pt-6"
        />
        <Modal
          isOpen={inviteError.open}
          setIsOpen={closeInviteError}
          title={handleErrorTypeTitle()}
          containerClassName="md:min-w-[600px]"
          closeButton
          footer={
            inviteError.type === "invited" ? (
              <>
                <Button
                  type="link"
                  width="fit-content"
                  onClick={closeInviteError}
                >
                  {t("commons.cancel")}
                </Button>
                <Button
                  type="primary"
                  width="fit-content"
                  onClick={resendInvite}
                >
                  {t("organisationSettings.members.resendInvitation")}
                </Button>
              </>
            ) : null
          }
        >
          <div className="p-6">{handleErrorTypeMessage()}</div>
        </Modal>
      </Modal>
    );
  }
);

export default ShareBuilding;
