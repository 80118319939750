import React from "react";

export default function useMutationObserver(
  target: HTMLElement | null,
  callback: (mutations: MutationRecord[]) => void,
  options: MutationObserverInit = {
    attributes: true,
    childList: true,
    subtree: true,
  }
) {
  const [observer, setObserver] = React.useState<MutationObserver | null>(null);

  React.useEffect(() => {
    if (observer) return;
    const observerObj = new MutationObserver(callback);
    setObserver(observerObj);
  }, [callback, options, setObserver]);

  React.useEffect(() => {
    if (!observer) return;
    if (!target) return;
    observer.observe(target, options);
    return () => {
      if (!observer) return;
      observer.disconnect();
    };
  }, [observer]);
}
