import React from "react";
import AppRoutes from "routes";
import { authConfig, UserProvider } from "contexts/user";
import { Auth0Provider } from "@auth0/auth0-react";
import CookieBanner, { hasConsented } from "components/CookieBanner";
import { useTranslation } from "react-i18next";
import { BannerProps } from "components/CookieBanner/types";
import { RecoilRoot } from "recoil";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import TooltipPopup from "components/Tooltip/TooltipPopup";

export default function App() {
  const { t } = useTranslation();
  const queryClient = new QueryClient();
  const cookieSettings = t("cookieBanner", {
    returnObjects: true,
  }) as BannerProps;

  return (
    <Auth0Provider {...authConfig}>
      {hasConsented() ? null : (
        <CookieBanner
          texts={cookieSettings.texts}
          preferences={cookieSettings.preferences}
          onConsent={(allowed, index, name) => {
            if (allowed === false) {
              // using the index as key due to multinlingual names
              localStorage.setItem(`cookie-${index}`, `${name}`);
            }
            // reload the page after consent to allow changes take effect
            window.location.reload();
          }}
        />
      )}
      <RecoilRoot>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <AppRoutes />
            <TooltipPopup />
          </QueryClientProvider>
        </UserProvider>
      </RecoilRoot>
    </Auth0Provider>
  );
}
