import { BlueprintPropertyOUT, FieldOUT, ProductGroupOUT } from "api-client";
import GeneratedInput from "features/Audit/GeneratedInput";
import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { dimensionsValueAtom, selectedColorAtom } from "store/atoms/audits";

interface GeneratedFieldsProps extends Partial<ProductGroupOUT> {
  blueprintproperty_set?: BlueprintPropertyOUT[];
  requiredDefaultValues?: (string | number | string[] | null | undefined)[];
  optionalDefaultValues?: (string | number | string[] | null | undefined)[];
  generatesDimensions?: boolean;
  generatesColor?: boolean;
}

const GeneratedFields = React.forwardRef(
  (props: GeneratedFieldsProps, ref: React.Ref<HTMLDivElement>) => {
    const numericInputs =
      props.generatesDimensions && props.required_fields
        ? props.required_fields
            .filter((field) => field.field_type === "number")
            .slice(0, 3)
        : null;

    const [dimensionsValue, setDimensionsValue] =
      useRecoilState(dimensionsValueAtom);
    const setSelectedColor = useSetRecoilState(selectedColorAtom);

    function handleColorChange(value: string, field?: FieldOUT) {
      if (!field) return console.log(`Field for color not found.`);
      const color = field.field_options.find(
        (opt) => opt.name === value || opt.name_de === value
      );
      console.log(`generated.color`, color);
      setSelectedColor(color);
    }

    function handleDimensionChangeForFirstThreeNumerics(
      field: FieldOUT,
      value: string
    ) {
      const isOneOfFirstThreeNumerics = numericInputs?.find(
        (input) => input.id === field.id
      );
      if (!isOneOfFirstThreeNumerics) return;
      const fieldIndex = numericInputs?.findIndex(
        (input) => input.id === field.id
      );
      const newDimensions = handleDimensionChange(
        value,
        dimensionsValue,
        fieldIndex
      );
      setDimensionsValue(newDimensions);
    }

    const requiredFieldsIds = (props.required_fields || []).map((f) => f.id);

    return (
      <div
        ref={ref}
        id={props.id ?? undefined}
        className="flex flex-wrap gap-x-4 pb-4"
      >
        {props.required_fields &&
          props.required_fields.length > 0 &&
          props.required_fields.map((field, i) => {
            if (field.name === "Color") {
              return (
                <GeneratedInput
                  required
                  enforceRequired={false}
                  handleChange={handleColorChange}
                  key={field.id}
                  index={i}
                  defaultValue={
                    props.requiredDefaultValues
                      ? props.requiredDefaultValues[i]
                      : undefined
                  }
                  data={field}
                />
              );
            } else {
              return (
                <GeneratedInput
                  required
                  enforceRequired={false}
                  handleChange={
                    props.generatesDimensions
                      ? (value) =>
                          handleDimensionChangeForFirstThreeNumerics(
                            field,
                            value
                          )
                      : undefined
                  }
                  key={field.id}
                  index={i}
                  defaultValue={
                    props.requiredDefaultValues
                      ? props.requiredDefaultValues[i]
                      : undefined
                  }
                  data={field}
                />
              );
            }
          })}
        {props.optional_fields &&
          props.optional_fields.length > 0 &&
          props.optional_fields.map((field, i) => {
            if (requiredFieldsIds.includes(field.id)) {
              return;
            }

            return (
              <GeneratedInput
                key={field.id}
                enforceRequired={false}
                index={i}
                defaultValue={
                  props.optionalDefaultValues
                    ? props.optionalDefaultValues[i]
                    : undefined
                }
                data={field}
              />
            );
          })}
      </div>
    );
  }
);

GeneratedFields.displayName = "GeneratedFields";

export default GeneratedFields;

function handleDimensionChange(
  value: string | number,
  dimensions: string,
  fieldIndex?: number
) {
  if (dimensions === "") {
    if (fieldIndex === 1) {
      return `0x${value ?? 0}x0`;
    }
    if (fieldIndex === 2) {
      return `0x0x${value ?? 0}`;
    }
    return `${value ?? 0}x0x0`;
  } else {
    //
    const splitted = dimensions.split("x");
    if (fieldIndex === 1) {
      return `${splitted[0] ?? "0"}x${value ?? 0}x${splitted[2] ?? "0"}`;
    }
    if (fieldIndex === 2) {
      return `${splitted[0] ?? "0"}x${splitted[1] ?? "0"}x${value}`;
    }
    return `${value ?? 0}x${splitted[1] ?? "0"}x${splitted[2] ?? "0"}`;
  }
}
