import React from "react";
import { observer } from "mobx-react-lite";
import ComponentsTreeIcon from "assets/images/components-tree.svg";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import Alert from "components/Alert";
import { useTranslation } from "react-i18next";

const CustomComponentsEmptyState = () => {
  const { t } = useTranslation();

  return (
    <div className="flex max-w-[600px] items-center mb-3">
      {componentsStore.openedSearchInCE ? (
        <Alert
          type="info"
          className="bg-white p-0 border-none"
          description={t("mapping.emptyStateComponentsInCE")}
        />
      ) : (
        <>
          <img src={ComponentsTreeIcon} alt="components-tree" />
          <div className="pl-8 text-base">
            <div className="text-gray-700 pb-2 font-medium">
              {t("mapping.emptyStateComponentsWithoutFilter")}
            </div>
            <div className="text-gray-500 font-normal">
              {t("mapping.emptyStateComponentsWithoutFilter2")}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default observer(CustomComponentsEmptyState);
