import { ErrorLocation } from "./lib";
import { AuditOUT, BlueprintOUT, InstanceOUT } from "../../../api-client";
import { useTranslation } from "react-i18next";
import React from "react";

export function MissingFieldsList({
  missingFields,
  currentAudit,
}: {
  missingFields: ErrorLocation[];
  currentAudit: AuditOUT | null;
}) {
  const { t } = useTranslation();
  return (
    <div className="py-2 px-4 absolute  max-h-32 z-10 top-8 w-full overflow-y-scroll bg-red-100 text-xs">
      {missingFields.map((field, i) => {
        let foundBlueprint: BlueprintOUT | undefined = undefined;
        let missingValue = undefined;
        let foundInstance: InstanceOUT | undefined = undefined;
        const blueprint_set_index = field.loc.findIndex(
          (l) => l === "blueprint_set"
        );
        const instance_set_index = field.loc.findIndex(
          (l) => l === "instance_set"
        );
        const instance_prop_set_index = field.loc.findIndex(
          (l) => l === "instanceproperty_set"
        );
        if (blueprint_set_index !== -1) {
          const blueprintIndex = field.loc[blueprint_set_index + 1] as number;
          foundBlueprint = currentAudit?.blueprint_set[blueprintIndex];
          if (instance_set_index !== -1 && foundBlueprint) {
            const instanceIndex = field.loc[instance_set_index + 1] as number;
            foundInstance = foundBlueprint.instance_set[instanceIndex];
            if (instance_prop_set_index !== -1) {
              const propIndex = field.loc[
                instance_prop_set_index + 1
              ] as number;
              console.log(foundInstance, propIndex);
              missingValue = foundInstance.instanceproperty_set[propIndex];
              console.log(
                "missingValue",
                missingValue,
                foundInstance.instanceproperty_set
              );
            } else {
              missingValue = field.loc[instance_set_index + 2] as number;
            }
          } else {
            missingValue = field.loc[blueprint_set_index + 2] as number;
          }
        }
        return (
          <div key={i}>
            {foundBlueprint
              ? `${t("audits.blueprint")}: ${foundBlueprint.name} -`
              : ""}{" "}
            {foundInstance
              ? `${t("audits.item")}: ${foundInstance.name} -`
              : ""}{" "}
            {missingValue ? `'${missingValue}'` : ""}
          </div>
        );
      })}
    </div>
  );
}
