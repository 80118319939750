import React from "react";
import { detailViewV2Store } from "store/IfcMapping/DetailViewV2Store";
import InfoListItem from "./StackListItem/InfoListItem";
import CircularityListItem from "./StackListItem/CircularityListItem";
import FinancialListItem from "./StackListItem/FinancialListItem";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import EmptyState from "components/EmptyState";
import LifeCycleListItem from "./StackListItem/LifeCycleListItem";
import IFCPropertiesListItem from "./StackListItem/IFCPropertiesListItem";
import { InventoryPhysicalObjectDetailOut, SingleItem } from "api-client";
import Button from "components/Button";
import { IconRefresh } from "@tabler/icons-react";
import { MaterialFileObject } from "store/IfcMapping/types";

interface Props {
  object?: InventoryPhysicalObjectDetailOut | MaterialFileObject | SingleItem;
  onReloadReportTiles: () => void;
}

const DetailViewV2Contents = ({ object, onReloadReportTiles }: Props) => {
  const {
    infoTileData,
    reportsTileData,
    ifcPropertiesData,
    currentIndex,
    loading,
  } = detailViewV2Store;
  const { t } = useTranslation();

  React.useEffect(() => {
    if (object) {
      detailViewV2Store.setPhysicalObject(object);
      detailViewV2Store.setObjectId(object.id);
    }
  }, [object]);

  function emptyStateContents(
    title: string,
    alertText: string,
    alertType: "attention" | "info" | undefined,
    type: "table" | "chart" | "inventory" | undefined
  ) {
    return (
      <div className="flex flex-col items-center min-h-[calc(100vh-360px)] justify-center">
        <EmptyState
          noBorder
          title={title}
          alertType={alertType}
          alertText={alertText}
          type={type}
          hideMEButton
          loading={loading}
        />
        <Button
          className="w-fit"
          type="primary"
          width="fit-content"
          onClick={onReloadReportTiles}
          leadingIcon={<IconRefresh />}
        >
          {t("emptyState.reloadReport")}
        </Button>
      </div>
    );
  }

  function apiError() {
    return (
      <div className="text-red-700 text-center flex-col text-sm flex items-center py-4 px-4 bg-gray-100 border rounded-md shadow-sm">
        {`${detailViewV2Store.apiError}`}
        <Button
          className="w-fit mt-4"
          type="primary"
          width="fit-content"
          onClick={onReloadReportTiles}
          leadingIcon={<IconRefresh />}
        >
          {t("detailView.reportsTab.reload")}
        </Button>
      </div>
    );
  }

  function reportsTileContents() {
    if (!detailViewV2Store.reportsTileData) {
      return emptyStateContents(
        t("detailView.reportsTab.noReportsAvailableTitle"),
        t("detailView.reportsTab.noReportsAvailableMessage"),
        "attention",
        "table"
      );
    }

    return (
      <>
        <LifeCycleListItem
          onReload={onReloadReportTiles}
          mass={reportsTileData?.mass}
          lcaIndicatorValues={reportsTileData?.lca_indicator_values}
        />
        <CircularityListItem circularity={reportsTileData?.circularity} />
        <FinancialListItem
          financial={reportsTileData?.financial}
          onReload={onReloadReportTiles}
        />
      </>
    );
  }

  return (
    <div className="text-gray-600 pb-8" key={`_${currentIndex + 1}`}>
      {detailViewV2Store.apiError ? (
        apiError()
      ) : (
        <>
          {currentIndex === 0 && infoTileData && (
            <InfoListItem infoItem={infoTileData} />
          )}
          {currentIndex === 1 && reportsTileContents()}
          {currentIndex === 2 && ifcPropertiesData && (
            <IFCPropertiesListItem ifcPropertiesData={ifcPropertiesData} />
          )}
        </>
      )}
    </div>
  );
};

export default observer(DetailViewV2Contents);
