import React from "react";
import { createPortal } from "react-dom";
import usePortal from "./usePortal";

/**
 * @example
 * <Portal id="modal">
 *   <p>Thinking with portals</p>
 * </Portal>
 */
const Portal = ({
  id,
  className,
  children,
}: React.PropsWithChildren<{ id: string; className?: string }>) => {
  const target = usePortal(id, className);
  return createPortal(children, target);
};

export default Portal;
