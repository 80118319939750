import React from "react";
import { ComponentDetailsByFileOut } from "api-client";
import { observer } from "mobx-react-lite";
import ComponentChildOverview from "../ComponentChildOverview";
import ProductDetails from "./ProductDetails";
import { getCurrentReferenceValue } from "../utils";
import { useTranslation } from "react-i18next";
import { searchStore } from "store/IfcMapping/SearchStore";

const ComponentDetails = () => {
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { currentDetailNode: currentComponentNode } = searchStore;

  const referenceUnit = getCurrentReferenceValue(
    currentComponentNode?.reference_unit
  );

  return currentComponentNode?.type === "PRODUCT" ? (
    <ProductDetails epdGroupsData={currentComponentNode.epd_groups} />
  ) : (
    currentComponentNode?.children?.map((item, index) => (
      <ComponentChildOverview
        key={index}
        childItem={item as unknown as ComponentDetailsByFileOut}
        referenceUnit={isEng ? referenceUnit?.name : referenceUnit?.name_de}
        hideInfoIcon
      />
    ))
  );
};

export default observer(ComponentDetails);
