import React from "react";
import { useTranslation } from "react-i18next";
import { numberCustomFormat } from "utils";
import { InfoItemProps } from "store/IfcMapping/DetailViewV2Store";
import Tooltip from "components/Tooltip";
import { useLocation } from "react-router";

interface SectionProps {
  items: InfoItemProps[];
}

const ListSection = ({ items }: SectionProps) => {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const location = useLocation();

  function getValueTranslationForTrueFalse(value: string | number | undefined) {
    if (typeof value === "number") {
      return numberCustomFormat(value);
    }
    if (typeof value === "string" && value.includes("True")) {
      return t("mapping.true");
    }
    if (typeof value === "string" && value.includes("False")) {
      return t("mapping.false");
    }

    return value;
  }

  function tooltipContents(field_name?: string | null | undefined) {
    if (!String(location.state).includes(".IFC")) return null;

    if (field_name?.includes("Area"))
      return <Tooltip content={t("detailView.infoTab.areaTooltip")} />;

    if (field_name?.includes("Volume"))
      return <Tooltip content={t("detailView.infoTab.volumeTooltip")} />;
  }

  return (
    <ul className="border border-gray-300 rounded-md p-0 ">
      {items?.map((item) => {
        const field_name = isEng
          ? item.field_name
          : item.field_name_de ?? item.field_name;
        if (item.value !== null && field_name !== null && item.value !== "") {
          const value = getValueTranslationForTrueFalse(item.value);
          return (
            <li
              key={field_name}
              className={`border-b text-sm font-medium border-gray-300 text-gray-600 last:border-b-0 p-2 flex justify-between items-center`}
            >
              <div className="flex items-center">
                {field_name}
                {tooltipContents(item.field_name)}
              </div>
              <div className="pl-2 text-sm font-normal max-w-[250px] text-right">
                {value}
              </div>
            </li>
          );
        }
      })}
    </ul>
  );
};

export default ListSection;
