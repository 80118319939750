import React from "react";
import clsx from "clsx";

interface PingProps {
  size?: "small" | "large";
  color?: "green" | "gray" | "red";
  className?: string;
  text?: string;
}

const PingAnimation = ({
  size = "large",
  color = "gray",
  className,
  text,
}: PingProps) => {
  return (
    <div className={className ?? ""}>
      <div
        className={clsx("flex mx-auto mb-2", {
          "h-12 w-12": size === "large",
          "h-8 w-8": size === "small",
        })}
      >
        <span
          className={clsx(
            "animate-ping absolute inline-flex rounded-full opacity-75",
            {
              "bg-gray-400": color === "gray",
              "bg-green-600": color === "green",
              "bg-red-500": color === "red",
              "h-12 w-12": size === "large",
              "h-8 w-8": size === "small",
            }
          )}
        ></span>
        <span
          className={clsx("relative inline-flex rounded-full", {
            "bg-gray-400": color === "gray",
            "bg-green-600": color === "green",
            "bg-red-500": color === "red",
            "h-12 w-12": size === "large",
            "h-8 w-8": size === "small",
          })}
        ></span>
      </div>
      <div className="mt-4 text-center text-gray-500 text-sm">{text}</div>
    </div>
  );
};

export default PingAnimation;
