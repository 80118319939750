import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "components/Modal";
import clsx from "clsx";
import Button from "components/Button";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import UploadFile from "./UploadFile";
import SelectImportType from "./SelectImportType";

interface IFCFileUploadProps {
  open?: boolean;
  setClose?: React.Dispatch<React.SetStateAction<boolean>>;
  modal?: boolean;
}

export type UploadType = "IFC" | "CSV";

const UploadData = ({ open, setClose, modal }: IFCFileUploadProps) => {
  const { t } = useTranslation();
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<UploadType | null>(null);

  function content() {
    return (
      <div className="relative p-6">
        {uploading || uploadType ? (
          <UploadFile
            uploadType={uploadType}
            setUploading={setUploading}
            uploading={uploading}
            setClose={setClose}
          />
        ) : (
          <SelectImportType setUploadType={setUploadType} />
        )}
      </div>
    );
  }

  const titleForCurrentView = () => {
    if (uploading) {
      return t("commons.uploadFile");
    } else {
      switch (uploadType) {
        case "IFC":
          return t("buildingMaterialResources.uploadBimModel");
        case "CSV":
          return t("buildingMaterialResources.uploadTableData");
        default:
          return t("buildingMaterialResources.uploadFileTitle");
      }
    }
  };

  function footerContent() {
    return uploadType && !uploading ? (
      <Button
        leadingIcon={<ArrowLeftIcon />}
        type="gray"
        width="fit-content"
        className="text-lg font-normal hover:!bg-gray-200"
        onClick={() => setUploadType(null)}
      >
        {t("commons.back")}
      </Button>
    ) : null;
  }

  return (
    <>
      {modal && open ? (
        <Modal
          isOpen={open}
          setIsOpen={() => setClose?.(false)}
          containerClassName={clsx("h-fit", uploading ? "max-w-xs" : "")}
          closeClassName="absolute top-4 right-4"
          disableOutsideClick
          title={titleForCurrentView()}
          footer={footerContent()}
          style={uploadType ? { maxWidth: "32rem" } : { maxWidth: "52rem" }}
        >
          {content()}
        </Modal>
      ) : (
        content()
      )}
    </>
  );
};

export default UploadData;
