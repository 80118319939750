import React, { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";

export interface ModalProps {
  open: boolean;
  close: () => void;
}
interface MProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  closeButton?: ReactNode;
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  title?: string | ReactNode;
  titleBorderBottom?: boolean;
  footer?: ReactNode;
  scrollable?: boolean;
  disableOutsideClick?: boolean;
  footerClassName?: string;
  closeClassName?: string;
  inProgress?: boolean;
  position?: "center" | "top";
  leadingIcon?: ReactNode;
  style?: React.CSSProperties;
}

const Modal = ({
  children,
  isOpen,
  setIsOpen,
  closeButton,
  className,
  containerClassName,
  title,
  footer,
  scrollable,
  disableOutsideClick,
  footerClassName,
  closeClassName,
  inProgress,
  position = "center",
  leadingIcon,
  titleBorderBottom = true,
  style,
}: MProps) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-30 flex justify-start"
        onClose={disableOutsideClick || inProgress ? () => null : setIsOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto text-gray-900">
          <div
            className={clsx(
              "flex justify-center min-h-full p-4 text-center sm:p-0",
              {
                "items-center": position === "center",
                "items-start": position === "top",
              }
            )}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={clsx(
                  "relative bg-white rounded-lg text-left p-0 pointer-events-auto",
                  "overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-sm sm:w-full",
                  containerClassName
                )}
                style={style}
              >
                {closeButton && !title && (
                  <div
                    className={clsx(
                      "flex justify-end cursor-pointer z-10",
                      closeClassName,
                      { "relative py-3 top-2 right-2": !closeClassName }
                    )}
                  >
                    <XIcon
                      className="w-7 h-7 px-0 text-gray-500 hover:bg-gray-200 rounded-full p-1 transition"
                      onClick={() => setIsOpen(false)}
                    />
                  </div>
                )}
                {title ? (
                  <div
                    className={`px-6 py-4 items-center ${
                      titleBorderBottom ? "border-b border-gray-300" : ""
                    } flex justify-between`}
                  >
                    <div className="flex items-center font-semibold text-lg">
                      {leadingIcon}
                      {title}
                    </div>
                    {(disableOutsideClick && !inProgress
                      ? true
                      : title && closeButton) && (
                      <XIcon
                        width={32}
                        className={`p-1 cursor-pointer text-gray-500 hover:bg-gray-200 rounded-full transition`}
                        onClick={() => setIsOpen(false)}
                      />
                    )}
                  </div>
                ) : null}
                <div
                  className={`${
                    scrollable
                      ? "max-h-[calc(100vh-210px)] overflow-y-auto overflow-x-hidden"
                      : ""
                  } ${className} ${footer && "pb-[72px]"}`}
                >
                  {children}
                </div>
                {footer ? (
                  <div
                    className={clsx(
                      "px-6 py-4 h-[72px] bg-gray-50 flex justify-between",
                      "items-center fixed left-0 right-0 bottom-0",
                      footerClassName
                    )}
                  >
                    {footer}
                  </div>
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
