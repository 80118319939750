import {
  ComponentDetailsByFileOut,
  ProductSearchInput,
  ProductSearchOutput,
  SearchEngineCategoryOUT,
  componentsApi,
  inventoryProductsApi,
  searchAPI,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { AxiosError, AxiosResponse } from "axios";
import { DropdownOption } from "components/DropdownCheckbox";
import { makeAutoObservable } from "mobx";
import { ifcMappingStore } from "./IFCMappingStore";
import { buildingStore } from "store/Building/BuildingStore";
import {
  OTHER_PROPERTIES_OPTIONS,
  SEARCH_TABS_LIST,
} from "features/MappingTools/consts";
import {
  CompliantItemsInfo,
  FiltersOptions,
  ProductsProps,
  SearchTabsProps,
} from "./types";

const compliantInitialState = {
  hasMore: true,
  totalCompliant: 0,
  compliant: 0,
  nonCompliant: 0,
};

export const SEARCH_PAGE_SIZE = 25;

export interface OpenDetailsMenu {
  open: boolean;
  product?: ProductSearchOutput | null;
}

class SearchStore {
  filtersOptions: FiltersOptions = {
    OKB_categories: [],
    DIN_categories: [],
    other_properties: OTHER_PROPERTIES_OPTIONS,
    materials: [],
    conformity: [],
    certifications: [],
    manufacturers: [],
  };
  currentSearchPage = 1;
  searchInput: ProductSearchInput = {
    query: "",
  };
  openProductSearch = false;
  productSearchLoading = false;
  selectedTab = SEARCH_TABS_LIST[0];
  searchResults: ProductsProps = {
    count: 0,
    items: [],
  };
  compliantItemsInfo: CompliantItemsInfo = compliantInitialState;
  searchError: boolean = false;
  currentDetailNode: ComponentDetailsByFileOut | undefined = undefined;
  openDetails: OpenDetailsMenu = {
    open: false,
    product: undefined,
  };
  componentDetails: ComponentDetailsByFileOut | undefined = undefined;

  setOpenProductSearch(openEPDSearch: boolean) {
    this.openProductSearch = openEPDSearch;
  }

  setSelectedTab(selectedTab: SearchTabsProps) {
    this.selectedTab = selectedTab;
  }

  setSearchInput(searchInput: ProductSearchInput) {
    this.searchInput = searchInput;
  }

  setProductSearchLoading(productSearchLoading: boolean) {
    this.productSearchLoading = productSearchLoading;
  }

  resetSearchResults() {
    this.searchResults = { count: 0, items: [] };
  }

  setSearchResults(searchResults: ProductsProps) {
    this.searchResults = searchResults;
  }

  updateSearchResults(count: number, newProducts: ProductSearchOutput[]) {
    this.searchResults = {
      count: count,
      items: this.searchResults.items.concat(newProducts),
    };
  }

  setCurrentSearchPage(page: number) {
    this.currentSearchPage = page;
  }

  setCompliantItemsInfo(compliantItemsInfo: CompliantItemsInfo) {
    this.compliantItemsInfo = compliantItemsInfo;
  }

  setSearchError(searchError: boolean) {
    this.searchError = searchError;
  }

  resetCompliantItemsInfo() {
    this.setCompliantItemsInfo(compliantInitialState);
  }

  setCurrentDetailNode(currentComponentNode?: ComponentDetailsByFileOut) {
    this.currentDetailNode = currentComponentNode;
  }

  setFilterOptions(filtersOptions: FiltersOptions) {
    this.filtersOptions = filtersOptions;
  }

  setOpenDetails(openDetails: OpenDetailsMenu) {
    this.openDetails = openDetails;
  }

  setComponentDetails(componentDetails: ComponentDetailsByFileOut | undefined) {
    this.componentDetails = componentDetails;
  }

  updateCompliantInfo(currentCount: number, totalCount: number) {
    const reachedEndOfCompliant =
      this.compliantItemsInfo.hasMore && currentCount === totalCount;
    if (reachedEndOfCompliant) {
      this.setCompliantItemsInfo({
        ...this.compliantItemsInfo,
        hasMore: false,
        totalCompliant: totalCount,
      });
      this.setCurrentSearchPage(0);
    }
    this.setCompliantItemsInfo({
      ...this.compliantItemsInfo,
      compliant: this.compliantItemsInfo.hasMore
        ? this.searchResults.items.length
        : this.compliantItemsInfo.totalCompliant,
      nonCompliant: this.compliantItemsInfo.hasMore
        ? 0
        : this.searchResults.items.length -
          this.compliantItemsInfo.totalCompliant,
    });
  }

  async getProducts(page?: number) {
    const pageNumber = page ?? this.currentSearchPage;
    const authHeader = await getLocalAuthHeader();
    const searchInputs = {
      ...this.searchInput,
      building_id: buildingStore.buildingID,
      product_source: this.selectedTab.source,
      building_compliance: this.compliantItemsInfo.hasMore,
      type: this.selectedTab.type,
    };
    return await searchAPI
      .inventoryApiV1RoutersSearchEngineSearchProducts(
        searchInputs,
        pageNumber,
        SEARCH_PAGE_SIZE,
        authHeader
      )
      .then((res: AxiosResponse) => {
        const data = res.data;
        if (data) {
          this.updateSearchResults(
            data.total_count_without_compliance_filter,
            data.items
          );
          this.setCurrentSearchPage(pageNumber);
        } else {
          this.resetSearchResults();
        }
        this.updateCompliantInfo(this.searchResults.items.length, data.count);
        this.setSearchError(false);
      })
      .catch((error: AxiosError) => {
        console.error(error, "error.SearchEngineSearchProducts");
        ifcMappingStore.setErrorMessage((error as Error).message);
        this.setSearchError(true);
      });
  }

  async getDinCategories() {
    const authHeader = await getLocalAuthHeader();
    return await searchAPI
      .inventoryApiV1RoutersSearchEngineProductDinCategories(
        undefined,
        authHeader
      )
      .then((response) => {
        this.setFilterOptions({
          ...this.filtersOptions,
          DIN_categories: response.data?.map((item) => createOptionItem(item)),
        });
      })
      .catch((error) => {
        console.log("getDinCategories.error", error);
      });

    function createOptionItem(item: SearchEngineCategoryOUT): DropdownOption {
      return {
        id: item.id,
        name: `${item.category_number} ${item.name}`,
        value: item.name,
        children: item?.children
          ?.map((rec) => createOptionItem(rec))
          .sort((a, b) => a.name.localeCompare(b.name)),
      } as DropdownOption;
    }
  }

  async getProductDetails(productId: string, ifcFileId?: string) {
    if (!ifcFileId) return;
    const authHeader = await getLocalAuthHeader();
    return inventoryProductsApi
      .inventoryApiV1RoutersProductsDetailsByFile(
        productId,
        ifcFileId,
        authHeader
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) =>
        console.error(err, "err.inventoryApiV1RoutersProductsDetailsByFile")
      );
  }

  async getComponentDetails(componentId: string, ifcFileId?: string) {
    if (!ifcFileId) return;
    const authHeader = await getLocalAuthHeader();
    return componentsApi
      .inventoryApiV1RoutersComponentsDetailsByFile(
        componentId,
        ifcFileId,
        authHeader
      )
      .then((response) => {
        this.setComponentDetails(response.data);
        return response.data;
      })
      .catch((err) =>
        console.error(err, "err.inventoryApiV1RoutersComponentsDetailsByFile")
      );
  }

  hasAnyFilters() {
    return (
      this.searchInput.query ||
      this.searchInput.certifications?.length ||
      this.searchInput.din_category?.length ||
      this.searchInput.epd_conformity ||
      this.searchInput.is_external ||
      this.searchInput.is_load_bearing ||
      this.searchInput.material_name?.length
    );
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: SearchStore;

  static getInstance(): SearchStore {
    if (!SearchStore.instance) {
      SearchStore.instance = new SearchStore();
    }
    return SearchStore.instance;
  }
}
export const searchStore = SearchStore.getInstance();
