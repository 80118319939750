import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { IconEdit } from "@tabler/icons-react";
import { componentsStore, Step } from "store/IfcMapping/ComponentsStore";
import { EmptyStateOverview } from "features/MappingTools/utils";
import OverviewTile from "./OverviewTile";
import ComplianceOverview from "./ComplianceOverview";
import ComponentChildOverview from "../../ComponentChildOverview";
import useFeatureFlag from "hooks/useFeatureFlag";

export default observer(function ProductOverview() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { isFeatureVisible } = useFeatureFlag();

  const hasOverview = () => {
    return Boolean(
      componentsStore.steps
        .map((_, index) => componentsStore.stepHasOverview(index))
        .find((item) => item)
    );
  };

  const stepOverview = (step: Step, index: number) => {
    if (!isFeatureVisible("component_editor_circularity_step") && index === 2)
      return <></>;
    return (
      <div
        className="border-b border-gray-200 mb-4 pb-2 last-of-type:border-b-0"
        key={index}
      >
        {index === 1 ? <ComplianceOverview /> : null}
        <div className="flex justify-between mt-6 mb-5">
          <div className="text-base font-semibold">
            {t(`components.${step.title}`)}
          </div>
          {componentsStore.activeStep !== index ? (
            <IconEdit
              width={22}
              className="text-gray-500 cursor-pointer hover:text-gray-800"
              stroke={2}
              onClick={() => componentsStore.setActiveStep(index)}
            />
          ) : null}
        </div>
        <OverviewTile step={step} />

        {index == 1 && componentsStore.data.childrenList.length > 0
          ? componentsStore.data.childrenList.map((item) => (
              <ComponentChildOverview
                childItem={item}
                key={item.id}
                referenceUnit={componentsStore.getValueFromObject(
                  "reference_unit",
                  isEng
                )}
              />
            ))
          : null}
      </div>
    );
  };

  return (
    <div className="bg-white border-l border-gray-300 p-6 w-full min-h-[87vh] max-h-[calc(100vh-57px)] overflow-y-auto">
      <div className="text-lg font-semibold">{t("productEditor.overview")}</div>
      {hasOverview() ? (
        componentsStore.steps.map((step, index) =>
          componentsStore.stepHasOverview(index)
            ? stepOverview(step, index)
            : null
        )
      ) : (
        <EmptyStateOverview>
          {t("components.noOverviewMsg").split("\n")[0]}
          <br />
          {t("components.noOverviewMsg").split("\n")[1]}
        </EmptyStateOverview>
      )}
    </div>
  );
});
