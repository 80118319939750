import React, { FormEvent, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "components/Checkbox";
import { observer } from "mobx-react-lite";
import {
  Category,
  INVENTORY_FIXED_COLUMN,
  inventoryStore,
} from "store/InventoryStore";
import useFeatureFlag from "hooks/useFeatureFlag";
import { AvailableColumnsOUT } from "api-client";

export default observer(function TableColumnSettings({
  columnsState,
  setColumnsState,
  title,
}: {
  columnsState: Category[];
  setColumnsState: React.Dispatch<React.SetStateAction<Category[]>>;
  title?: string;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { isFeatureVisible } = useFeatureFlag();

  React.useEffect(() => {
    setColumnsState([
      ...inventoryStore.categories.map((category) => ({ ...category })),
    ]);
  }, [inventoryStore.categories]);

  const isAllChecked = (column: Category) => {
    return Object.values(column.visibility)?.every((value) => value === true);
  };

  function updateColumnSettings(categories: Category[]) {
    setColumnsState([...categories]);
  }

  function handleChange(
    e: FormEvent<HTMLInputElement>,
    index: number,
    column: string
  ) {
    columnsState[index].visibility = {
      ...columnsState[index].visibility,
      [column]: e.currentTarget.checked,
    };
    updateColumnSettings(columnsState);
  }

  function handleSelectAll(e: FormEvent<HTMLInputElement>, index: number) {
    columnsState[index].visibility = changeObjectValues(
      { ...columnsState[index].visibility },
      e.currentTarget.checked
    );
    updateColumnSettings(columnsState);
  }

  const changeObjectValues = (
    object: { [key: string]: boolean },
    value: boolean
  ) => {
    Object.keys(object).forEach(function (key) {
      if (key !== INVENTORY_FIXED_COLUMN) {
        object[key] = value;
      }
    });
    return { ...object };
  };

  function showCategoryColumns(category: Category, index: number) {
    return category.columns.map((column: AvailableColumnsOUT) => {
      return column.property_name === INVENTORY_FIXED_COLUMN ||
        (!isFeatureVisible("inventory_debug") && column.in_debug) ? (
        <Fragment key={column.property_name} />
      ) : (
        <Checkbox
          key={column.property_name}
          id={column.property_name}
          checked={category.visibility[column.property_name]}
          labelElement={String(
            isEng ? column.friendly_name_en : column.friendly_name_de
          )}
          onChange={(e) => handleChange(e, index, column.property_name)}
          containerClassName="py-1"
        />
      );
    });
  }

  return (
    <div className="overflow-y-auto max-h-[calc(100vh-240px)]">
      {title ? (
        <div className="px-6 pt-6 text-base font-semibold">{title}</div>
      ) : null}
      <div className="p-6 grid-cols-3 grid gap-6">
        {columnsState.map((category: Category, index: number) => {
          if (!isFeatureVisible("inventory_debug") && category.key === "debug")
            return <Fragment key={category.key} />;
          return (
            <div key={category.key}>
              <div className="text-base text-gray-700 font-semibold mb-1">
                {isEng ? category.name : category.name_de}
              </div>
              <div className="text-sm text-gray-600 font-normal mb-1">
                {t(`buildingInventory.categories.${category.key}_subtitle`)}
              </div>
              <Checkbox
                checked={isAllChecked(category)}
                id={`${category.key}_all`}
                labelElement={t("buildingInventory.selectAll")}
                containerClassName="border-b border-gray-300 mb-2"
                onChange={(e) => handleSelectAll(e, index)}
              />
              {showCategoryColumns(category, index)}
            </div>
          );
        })}
      </div>
    </div>
  );
});
