import React from "react";
import clsx from "clsx";
import ProfileMenu from "features/User/ProfileMenu";
import UserSettings from "features/User/Settings";
import { useRecoilState, useSetRecoilState } from "recoil";
import { openUserSettingAtom, sidebarOpenedAtom } from "store/atoms";
import { isTabletSize } from "utils";
import Button from "components/Button";
import { PlusIcon } from "@heroicons/react/solid";
import Modal from "components/Modal";
import JoinOrganisation from "features/Organisation/JoinOrganisation";
import { useTranslation } from "react-i18next";

const SidebarBottom = () => {
  const [openSettings, setOpenSettings] = useRecoilState(openUserSettingAtom);
  const setSidebarOpened = useSetRecoilState(sidebarOpenedAtom);
  const { t } = useTranslation();

  function handleOpenSettings() {
    setOpenSettings(true);
    if (isTabletSize()) {
      setSidebarOpened(false);
    }
  }

  const [openJoin, setOpenJoin] = React.useState(false);
  const closeJoinModal = () => {
    setOpenJoin(false);
  };

  const openJoinModal = () => {
    setOpenJoin(true);
    if (isTabletSize()) {
      setSidebarOpened(false);
    }
  };

  function joinOrCreateOrganization() {
    return (
      <div
        className={clsx(
          "flex py-4 text-sm px-3 text-gray-700 transition",
          " border-t border-gray-300"
        )}
      >
        <Button
          trailingIcon={<PlusIcon width={15} className="text-gray-700" />}
          type="gray"
          size="small"
          width="fit-content"
          onClick={openJoinModal}
          className="!px-3"
        >
          {t("dashboard.joinOrCreateOrganisations")}
        </Button>

        <Modal
          isOpen={openJoin}
          setIsOpen={setOpenJoin}
          containerClassName="md:max-w-3xl px-3"
          closeButton
        >
          <JoinOrganisation close={closeJoinModal} />
        </Modal>
      </div>
    );
  }

  return (
    <>
      {joinOrCreateOrganization()}
      <div
        className={clsx(
          "flex-1 z-10 lg:inset-y-0 lg:flex max-h-[61px] text-sm bottom-0 !top-auto bg-white",
          "lg:w-[300px] h-auto lg:flex-col lg:border-r lg:border-gray-300 border-t"
        )}
      >
        <div className="flex justify-between py-4 px-3">
          <ProfileMenu openSettings={handleOpenSettings} />
          <UserSettings
            open={openSettings}
            close={() => setOpenSettings(false)}
          />
        </div>
      </div>
    </>
  );
};

export default SidebarBottom;
