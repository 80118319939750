import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { revitStore } from "store/RevitStore";
import { useTranslation } from "react-i18next";
import { BuildingOUT } from "api-client";
import Tooltip from "components/Tooltip";
import { buildingStore } from "store/Building/BuildingStore";

export default observer(function StepBuildings() {
  const { t } = useTranslation();
  const [buildings, setBuildings] = useState<BuildingOUT[]>([]);

  useEffect(() => {
    if (!revitStore.currentOrganisation?.id) return;
    const buildings = buildingStore.getCurrentOrganisationBuildings(
      revitStore.currentOrganisation?.id
    );
    setBuildings(buildings ?? []);
  }, []);

  const selectBuilding = (building: BuildingOUT | undefined) => {
    revitStore.setCurrentBuilding(building);
    revitStore.setActiveStep(revitStore.activeStep + 1);
  };

  function buildingsContent() {
    return buildings?.length
      ? buildings.map((item) => (
          <div
            key={item.id}
            className="border border-gray-200 mb-2 rounded-md flex px-2 py-[6px] items-center cursor-pointer"
            onClick={() => selectBuilding(item)}
          >
            <div className="whitespace-nowrap text-xs font-normal transition hover:text-black">
              <Tooltip truncate className="max-w-[160px]">
                {item.name}
              </Tooltip>
            </div>
          </div>
        ))
      : skeleton();
  }

  return (
    <>
      <div className="bg-gray-100 p-2 mb-4 rounded-md flex items-center mt-4">
        <span className="text-xs text-gray-600 font-medium">
          {t("revit.organisation")}
        </span>
        <span className="pl-3 text-xs text-gray-500 font-normal">
          {revitStore.currentOrganisation?.name}
        </span>
      </div>
      {buildingsContent()}
    </>
  );
});

function skeleton() {
  return (
    <div className="animate-pulse">
      <div className="h-8 w-full bg-gray-200 rounded-lg dark:bg-gray-700 mb-2.5"></div>
    </div>
  );
}
