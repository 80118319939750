import React from "react";
import DropdownBrowser from "components/DropdownBrowser";
import { useTranslation } from "react-i18next";
import {
  IconFilter,
  IconFilterOff,
  IconFilterPlus,
  IconTrash,
} from "@tabler/icons-react";
import clsx from "clsx";
import SimpleSelect from "components/SimpleSelect";
import {
  EMPTY_FILTER_RULE,
  LOGICAL_OPERATORS,
  PROPERTY_RULE_OPERATORS,
  PROPERTY_RULE_OPERATORS_NUMERIC,
  PROPERTY_RULE_OPERATORS_TEXTUAL,
} from "../../const";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import {
  ColumnSuggestionsOUT,
  LogicalOperatorENUM,
  PropertyBrowserTreeNode,
} from "api-client";
import useWindowOverflow from "hooks/useWindowOverflow";
import { FilterRule } from ".";
import { observer } from "mobx-react-lite";
import LinearLoading from "components/LinearLoading";
import DropdownWithSearch from "components/DropdownWithSearch";
import DebouncedInput from "components/DebounceInput";

const wrapperID = "filter-wrapper";

function FilterMenu({
  cursorPosition,
  items,
  onChangeProperty,
}: {
  cursorPosition: { x: number; y: number };
  items: PropertyBrowserTreeNode[];
  onChangeProperty: (value: PropertyBrowserTreeNode[], index: number) => void;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const { getLeftPos, containerRef } = useWindowOverflow();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const [scrollPos, setScrollPos] = React.useState<number>(0);

  const onScrollDiv = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const containerElement = e.target as HTMLDivElement;
    setScrollPos(containerElement.scrollTop);
  };

  const onChangeOperator = (value: string | undefined, index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].operator = String(value);
    dynamicMEStore.updateFilterRules(rules);
  };

  const onChangeTerm = (value: string | number | undefined, index: number) => {
    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules[index].value = String(value);
    dynamicMEStore.updateFilterRules(rules);
  };

  const addRule = () => {
    dynamicMEStore.updateFilterRules([
      ...dynamicMEStore.selectedFilterByItems.rules,
      { ...EMPTY_FILTER_RULE },
    ]);
    scrollAreaRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const removeCurrentRow = (index: number) => {
    if (index == 0 && dynamicMEStore.selectedFilterByItems.rules.length <= 1) {
      dynamicMEStore.removeAllFilteringRules();
      return;
    }

    const rules = [...dynamicMEStore.selectedFilterByItems.rules];
    rules.splice(index, 1);
    dynamicMEStore.updateFilterRules(rules);
  };

  const onChangeLogicalOperator = (value: string | undefined) => {
    dynamicMEStore.setSelectedFilterByItems({
      ...dynamicMEStore.selectedFilterByItems,
      logical_operator: value as LogicalOperatorENUM,
    });
  };

  const filterRow = (rule: FilterRule, index: number) => {
    const shouldHideValueField = ["IS_EMPTY", "IS_NOT_EMPTY"].includes(
      rule.operator
    );
    let properties = PROPERTY_RULE_OPERATORS;
    if (rule.property) {
      properties = rule.property.is_numeric
        ? PROPERTY_RULE_OPERATORS_NUMERIC
        : PROPERTY_RULE_OPERATORS_TEXTUAL;
    }
    return (
      <div
        className="flex items-center py-2 px-3 gap-2 min-w-[595px] hover:bg-indigo-100 transition group/filterItem"
        key={index}
      >
        {index === 0 ? (
          <span className="min-w-[85px] text-right">
            {t("mappingEditor.when")}
          </span>
        ) : (
          <SimpleSelect
            options={LOGICAL_OPERATORS}
            noMargin
            className="!mb-0 !mt-0"
            current={dynamicMEStore.selectedFilterByItems.logical_operator}
            containerClassName="min-w-[85px]"
            onChange={(value) => onChangeLogicalOperator(value)}
          />
        )}
        <DropdownBrowser<PropertyBrowserTreeNode>
          displayKey={isEng ? "name" : "name_de"}
          detailKey={isEng ? "classification" : "classification_de"}
          handleSelect={(value) => onChangeProperty(value, index)}
          name="filter_by"
          items={items}
          checkedItems={rule.property ? [rule.property] : []}
          placeholder={t("mappingEditor.findProperty")}
          optionsClassName="!max-w-fit min-w-[246px]"
          containerClassName="ml-1 relative bg-white"
          singleSelect
          hasSelectInput
          selectInputClassName="max-w-[134px] w-[134px]"
          fixedPos
          wrapperID={wrapperID}
          leftOffset={110}
          parentScrollTop={scrollPos}
          scrollAreaHeight={420}
        />
        <SimpleSelect
          options={properties}
          noMargin
          className="!mb-0 !mt-0 min-w-[120px] max-w-[120px]"
          onChange={(value) => onChangeOperator(value, index)}
          current={rule.operator}
        />
        {rule.suggestedValues?.length && !shouldHideValueField ? (
          <DropdownWithSearch<ColumnSuggestionsOUT>
            items={rule.suggestedValues}
            displayKey="value"
            detailKey="value_count"
            labelName={t("commons.selectValue")}
            value={rule.value}
            noMargin
            height="middle"
            handleSelect={(selected) =>
              onChangeTerm(String(selected.value), index)
            }
            placeholder={t("mappingEditor.search")}
            containerClassName="max-w-[160px] min-w-[160px]"
            onChangeSearchTerm={(value) => value && onChangeTerm(value, index)}
            handleRemoveValue={() => onChangeTerm("", index)}
          />
        ) : (
          <DebouncedInput
            containerClassName="mt-0 max-w-[160px] min-w-[160px]"
            height="middle"
            onChange={(value) => onChangeTerm(value, index)}
            value={rule.value}
            hidden={shouldHideValueField}
            icon={<></>}
            placeholder={t("mappingEditor.enterValue")}
            className="placeholder-gray-500"
          />
        )}
        <IconTrash
          className="cursor-pointer ml-2 transition group-hover/filterItem:text-indigo-700 min-w-[20px]"
          size={20}
          onClick={() => removeCurrentRow(index)}
        />
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "absolute z-5 my-1 w-full overflow-visible rounded-md bg-white text-sm text-gray-700 !max-w-fit",
        "shadow-lg border border-gray-300 min-w-auto w-auto max-w-md focus:outline-none sm:text-sm"
      )}
      ref={containerRef}
      style={{
        left: getLeftPos(cursorPosition.x, 20),
      }}
      id={wrapperID}
    >
      <div className="flex items-center px-4 py-1 rounded-t-md border-b border-gray-300 bg-gray-50 text-xs text-gray-500">
        <IconFilter size={19} className="mr-2" stroke={2.5} />
        {`${t("mappingEditor.filtered")} ${t("mappingEditor.by")}`}
      </div>
      {dynamicMEStore.dataFetchLoading && (
        <LinearLoading type="auto" containerClassName="top-7 h-1" />
      )}
      <div
        className={clsx("pt-1.5 max-h-[325px]", {
          "overflow-y-auto":
            dynamicMEStore.selectedFilterByItems.rules.length > 6,
        })}
        ref={scrollContainerRef}
        onScroll={onScrollDiv}
      >
        {dynamicMEStore.selectedFilterByItems.rules.map((rule, index) =>
          filterRow(rule, index)
        )}
        <div className="h-1.5" ref={scrollAreaRef}></div>
      </div>
      <div className="border-t border-gray-200 transition text-gray-700 hover:text-gray-900">
        <div
          className="flex px-4 py-2 items-center cursor-pointer text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group/rule"
          onClick={addRule}
        >
          <IconFilterPlus
            size={20}
            className="mr-2 group-hover/rule:text-indigo-700"
          />
          <span>{t("mappingEditor.addFilterRule")}</span>
        </div>
        <div
          className={clsx(
            "flex px-4 py-2 items-center cursor-pointer",
            dynamicMEStore.validFilterRules
              ? " text-gray-700 hover:text-indigo-700 hover:bg-indigo-100 group/remove"
              : "pointer-events-none cursor-not-allowed text-gray-300"
          )}
          onClick={() => dynamicMEStore.removeAllFilteringRules()}
        >
          <IconFilterOff
            className="mr-2 group-hover/remove:text-indigo-700"
            size={20}
          />
          <span>{t("mappingEditor.removeFilterRules")}</span>
        </div>
      </div>
    </div>
  );
}

export default observer(FilterMenu);
