import {
  BlueprintIN,
  BlueprintOUT,
  BlueprintPropertyOUT,
  BlueprintUpdateIN,
  ImageAlbumOUT,
  InstanceIN,
  InstancePropertyOUT,
  ManufacturerOUT,
  ProductGroupOUT,
  imagesApi,
} from "api-client";
import { LocalImage } from "api-client-local/db_interfaces";
import { getFirstLocalImageForSource } from "api-client-local/images";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { BlueprintAddForm } from "pages/AuditOnline/BlueprintAddOnline";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import {
  EditBlueprintForm,
  HandleAuditEditArgs,
  HandleBlueprintSubmitArgs,
} from "./models";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import retrieveFields from "features/Audit/handlers/retrieveFields";
import {
  handleFieldValueTypes,
  makeBlueprintPropertyModel,
  scrollToFirstInvalidInput,
} from "features/Audit/utils";
import {
  S3PresignedPost,
  renameFileIfLong,
  uploadToS3,
} from "features/Audit/SyncBar/performSync";
import {
  InstanceAddForm,
  parseFloorIds,
} from "features/Audit/handlers/handleInstanceAdd";
import validateAddedComponents from "features/Audit/handlers/validateAddedComponents";
import { omit } from "utils";

export const NUMBER_OF_STEPS = 4;

const setUpLocalImageIfAny = (
  itemId: string,
  setItemImageUrl: React.Dispatch<React.SetStateAction<string>>
) => {
  getFirstLocalImageForSource(itemId).then((image) => {
    if (image) {
      setItemImageUrl(URL.createObjectURL(image.file));
    }
  });
};

export const getImageUrlFromAlbum = (
  itemId: string,
  itemAlbums: ImageAlbumOUT[],
  setItemImageUrl: React.Dispatch<React.SetStateAction<string>>
) => {
  const albums = [...itemAlbums];
  if (albums && albums.length > 0) {
    const highestOrderImage = [...albums[0].images].sort(
      (a, b) => b.order - a.order
    );
    const remoteImageUrl = highestOrderImage[0]?.image;
    if (remoteImageUrl) {
      setItemImageUrl(remoteImageUrl);
    } else {
      setUpLocalImageIfAny(itemId, setItemImageUrl);
    }
  } else {
    setUpLocalImageIfAny(itemId, setItemImageUrl);
  }
};

export const handleBlueprintDuplicate = async (id: string) => {
  await caOnlineStore.duplicateBlueprint(id);
};
export const handleBlueprintDelete = async (id: string) => {
  await caOnlineStore.deleteBlueprint(id);
};
export const handleBlueprintMultiselect = async (id: string) => {
  // TODO: will think about multiselect logic for online and create a mass delete button
  console.log("Multiselect: Id", id);
};

export default async function handleBlueprintSubmitOnline(
  form: BlueprintAddForm,
  args: HandleBlueprintSubmitArgs,
  loadingCallback: (message: string) => void
) {
  try {
    if (!args.authHeader) throw new Error("authHeader undefined.");
    if (!args.selectedProductGroup)
      throw new Error("selectedProductGroup undefined.");

    const areComponentsValid = await validateAddedComponents(
      args.selectedProductGroup,
      args.addedComponents
    );
    console.log("Components are valid?", areComponentsValid);
    if (!areComponentsValid) {
      blueprintCreateStore.setStep(2);
      return blueprintCreateStore.setComponentCurrentStep(0);
    }

    const { foundFields } = retrieveFields(
      form,
      args.selectedProductGroup.required_fields,
      args.selectedProductGroup.optional_fields
    );

    const blueprintProperties = foundFields.map((found) => {
      const model = {
        field_id: found.field.id,
        value_field_option_ids: [],
      };
      return handleFieldValueTypes<typeof model>(
        found.value,
        model
      ) as unknown as BlueprintPropertyOUT;
    });

    if (!args.userProfile || !args.audit_id) {
      throw new Error("userProfile or audit_id undefined.");
    }

    const components: BlueprintIN[] = Object.values(args.addedComponents).map(
      (c) => {
        return {
          audit_id: caOnlineStore.currentAudit.id,
          extra_info: c.extra_info,
          name: c.name,
          name_de: c.name_de,
          blueprintproperty_set: c.blueprintproperty_set,
          manufacturer_id: c.manufacturer?.id ?? null,
          product_group_id: c.product_group_id,
          components: [],
          as_component_amount: c.as_component_amount ?? 1,
        };
      }
    );

    const blueprint: BlueprintIN = {
      extra_info: form.extra_info,
      name: args.generatedBlueprintName ?? "",
      name_de: args.generatedBlueprintName ?? "",
      blueprintproperty_set: blueprintProperties,
      components: components,
      manufacturer_id: args.selectedManufacturer?.id ?? null,
      audit_id: caOnlineStore.currentAudit.id,
      product_group_id: args.selectedProductGroup.id,
      as_component_amount: 1,
    };

    loadingCallback("audits.sendingFormData");

    const uploadedBlueprint = await caOnlineStore.createBlueprint(blueprint);
    if (args.images) {
      await handleBlueprintImagesUpload(
        args.images,
        loadingCallback,
        uploadedBlueprint.id,
        args.authHeader
      );
    }

    if (args.addedComponentImages) {
      console.log("args.addedComponentImages:: ", args.addedComponentImages);
      const compImages = Object.values(args.addedComponentImages).flat(2);

      uploadedBlueprint.components?.forEach(async (comp) => {
        await handleBlueprintImagesUpload(
          compImages,
          loadingCallback,
          comp.id,
          args.authHeader
        );
      });
    }

    return uploadedBlueprint.id;
  } catch (err) {
    loadingCallback("audits.errorOccured");
    console.error(err);
    const timer = setTimeout(() => {
      loadingCallback("");
      clearTimeout(timer);
    }, 2000);
    return false;
  } finally {
    loadingCallback("");
  }
}

async function handleBlueprintImagesUpload(
  images: (File | LocalImage)[],
  loadingCallback: (message: string) => void,
  uploadedBlueprintId: string,
  authHeader: AxiosRequestConfig<AxiosRequestHeaders> | undefined
) {
  console.log("args.images", images);
  loadingCallback("audits.uploadingBlueprintImages");

  const blueprintImages: LocalImage[] = [];
  for (const image of images) {
    blueprintImages.push({
      id: window.crypto.randomUUID(),
      sourceType: "blueprint",
      sourceId: uploadedBlueprintId,
      file: image as File,
      deleted: false,
      uploaded: false,
      order: 0,
      albumName: "default",
    });
  }

  await Promise.all(
    blueprintImages.map(async (image) => {
      try {
        const s3UploadedImageUrl = await performImageUploadOnline(
          image,
          authHeader
        );
        console.log("S3 uploaded: ", s3UploadedImageUrl);
      } catch (error) {
        console.error("IMG Upload error: ", error);
      }
    })
  );
}

async function registerAlbumImages(
  image: LocalImage,
  filename: string,
  authHeader: unknown
) {
  const registeredAlbum =
    await imagesApi.caApiV1RoutersImagesRegisterImageToBlueprint(
      image.sourceId,
      filename,
      image.order,
      authHeader as AxiosRequestConfig<unknown>
    );
  return registeredAlbum.data;
}

export async function performImageUploadOnline(
  image: LocalImage,
  authHeader: unknown
) {
  if (image.sourceType != "blueprint" && image.sourceType != "instance") {
    console.log("Unsupported local image source type");
    return;
  }
  const renamedFile = renameFileIfLong(image.file);
  const registerBlueprintImage = await registerAlbumImages(
    image,
    renamedFile.name,
    authHeader
  );

  const s3ImageUrl = await uploadToS3(
    registerBlueprintImage.upload_url_config as S3PresignedPost,
    image.file
  );

  return s3ImageUrl;
}

export function createInstanceByFormOnline(
  form: InstanceAddForm,
  blueprintId: string,
  pg: ProductGroupOUT
) {
  const { foundFields } = retrieveFields(
    form,
    pg.required_fields,
    pg.optional_fields
  );

  const instanceProperties = foundFields.map((found) => {
    const model = {
      field_id: found.field.id,
      value_field_option_ids: [],
    };

    return handleFieldValueTypes<typeof model>(
      found.value,
      model
    ) as unknown as InstancePropertyOUT;
  });

  const roomId = caOnlineStore.roomSet.find(
    (r) => r.name === form.room_name
  )?.id;

  const instance: InstanceIN = {
    name: form.name,
    room_id: roomId ?? "",
    floor_ids: parseFloorIds(form.floor_ids),
    extra_info: form.extra_info,
    instance_amount: Number(form.instance_amount),
    blueprint_id: blueprintId,
    instanceproperty_set: instanceProperties,
  };

  return instance;
}

export function checkIfBlueprintIsComponent(blueprint: BlueprintOUT) {
  const allComponents = caOnlineStore.blueprintSet
    .map((b) => b.components)
    .flat(2);
  const foundAsComponent = allComponents.find((c) => c?.id === blueprint?.id);
  return !!foundAsComponent;
}

export async function handleBlueprintEditOnline(
  form: EditBlueprintForm,
  args: HandleAuditEditArgs,
  loadingCallback: (m: string) => void,
  selectedManufacturer?: ManufacturerOUT
) {
  console.log(
    "handleBlueprintEdit.form",
    form,
    args.selectedBlueprint.blueprintproperty_set,
    args.selectedProductGroup.optional_fields
  );
  form = omit<EditBlueprintForm>("images", form);
  const conditionField = args.selectedProductGroup.optional_fields.find(
    (f) => f.name === "Condition"
  );
  console.log("handleBlueprintEdit.conditionField", conditionField);
  const blueprintPropertySet = conditionField
    ? args.selectedBlueprint.blueprintproperty_set.filter(
        (p) => p.field_id !== conditionField.id
      )
    : args.selectedBlueprint.blueprintproperty_set;

  const nextProperties: BlueprintPropertyOUT[] = [];
  let generatedFields = omit("name", form) as EditBlueprintForm;
  generatedFields = omit("extra_info", generatedFields) as EditBlueprintForm;
  generatedFields = omit(
    "as_component_amount",
    generatedFields
  ) as EditBlueprintForm;
  generatedFields = omit(
    "select_manufacturer",
    generatedFields
  ) as EditBlueprintForm;

  console.log("handleBlueprintEdit.generatedFields", generatedFields);
  for (const fieldId in generatedFields) {
    const fieldValue = generatedFields[fieldId];
    const previouslyFound = blueprintPropertySet.find(
      (prop) => prop.field_id === fieldId
    );
    if (previouslyFound) {
      const isEmptySelectField =
        previouslyFound.value_field_option_ids &&
        (previouslyFound.value_field_option_ids as []).length > 0 &&
        typeof fieldValue === "string" &&
        fieldValue.length === 0;

      const updatedField = handleFieldValueTypes(
        fieldValue,
        previouslyFound,
        Boolean(isEmptySelectField)
      );
      nextProperties.push(updatedField);
    } else {
      const createdField = makeBlueprintPropertyModel(
        fieldId,
        args.selectedBlueprint.id,
        fieldValue
      );
      nextProperties.push(createdField);
    }
  }

  const componentsIN = args.selectedBlueprint.components?.map((comp) => {
    return {
      ...comp,
      manufacturer_id: comp.manufacturer?.id ?? null,
    };
  });

  const blueprintIN: BlueprintUpdateIN = {
    name: form.name as string,
    manufacturer_id:
      selectedManufacturer?.id ?? args.selectedBlueprint.manufacturer?.id ?? "",
    extra_info: form.extra_info as string,
    as_component_amount: Number(form.as_component_amount) || 1,
    blueprintproperty_set: nextProperties,
    audit_id: caOnlineStore.currentAudit.id,
    components: componentsIN as unknown as BlueprintUpdateIN[],
    name_de: (form.name_de as string) ?? "",
    product_group_id: args.selectedBlueprint.product_group_id,
  };

  if (args.images.length > 0) {
    await handleBlueprintImagesUpload(
      args.images,
      loadingCallback,
      args.selectedBlueprint.id,
      args.authHeader
    );
  }

  const response = await caOnlineStore.updateBlueprint(
    args.selectedBlueprint.id,
    blueprintIN
  );

  return response.data;
}

export function validateStep(step: number) {
  try {
    const stepForm = document.getElementById(`step-${step + 1}`);
    if (!stepForm) throw new Error(`Step form is not found for (${step})`);
    const inputs = Array.from(
      stepForm.querySelectorAll("input[required], select[required]")
    ) as HTMLInputElement[];

    const emptyInputs = inputs.filter((i) => i.value === "");

    // TODO_AUDIT: Be sure to check invalid state for select component
    if (emptyInputs.length > 0) {
      scrollToFirstInvalidInput(emptyInputs);
      for (let i = 0; i < emptyInputs.length; i++) {
        emptyInputs[i].setAttribute("data-invalid", "true");
      }
      return;
    }

    blueprintCreateStore.setStep(step + 1);
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
