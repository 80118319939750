import React from "react";
import { Switch } from "@headlessui/react";
import { ifcApi, IFCFileOUT } from "api-client";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import { buildingStore } from "store/Building/BuildingStore";
import { userStore } from "store/UserStore";

interface ToggleProps {
  ifcFile: IFCFileOUT;
  disabled: boolean;
  checked: boolean;
}

const FileToggle = observer(({ ifcFile, disabled, checked }: ToggleProps) => {
  const { authHeader } = userStore;

  async function onChangeMultipleActiveFiles(state: boolean) {
    if (!buildingStore.currentBuilding || !authHeader) {
      return console.error("undefined.handleChange.currentBuilding");
    }
    await ifcApi
      .ifcApiV1RoutersIfcFileUpdateReportingStatus(
        ifcFile.id,
        state ? "ACTIVE" : "HIDDEN",
        authHeader
      )
      .then(async () => {
        await buildingStore.getAllMaterialResources();
        await buildingStore.getOrganisationBuildings();
      })
      .catch((err) => {
        console.error(err, "IfcFileUpdateReportingStatus");
      });
  }

  const handleChange = async (state: boolean) => {
    await onChangeMultipleActiveFiles(state);
    await buildingStore.getBuildingMappingStatus();
  };

  return (
    <Switch.Group>
      <div className="flex items-center z-1">
        <Switch
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          className={clsx(
            checked ? "bg-indigo-600" : "bg-gray-200",
            "relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none",
            { "cursor-not-allowed": disabled }
          )}
        >
          <span
            className={`${
              checked ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
});

export default FileToggle;
