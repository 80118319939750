import Tooltip from "components/Tooltip";
import { useUser } from "contexts/user";
import useSignout from "hooks/useSignout";
import React from "react";
import { useTranslation } from "react-i18next";

export default function RevitLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user } = useUser();
  const signOut = useSignout();
  const { t } = useTranslation();

  return (
    <div>
      <div className="bg-gray-50 px-4 py-2 flex justify-end items-center text-gray-500 text-xs font-medium">
        <span className="mr-3">{user?.email}</span>
        <div
          className="mr-2 underline cursor-pointer"
          onClick={signOut}
          test-id="revitLogout_button"
        >
          {t("commons.logout")}
        </div>
        <Tooltip content={t("revit.logoutTolltip")} />
      </div>
      {children}
    </div>
  );
}
