import React from "react";
import DebouncedInput from "components/DebounceInput";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

export default function SearchBar(props: {
  onChange: (value: string | number) => void;
  value: string;
  onCancel: () => void;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex justify-between">
      <DebouncedInput
        containerClassName="w-full mr-2 md:min-w-[700px] mb-2"
        onChange={props.onChange}
        value={props.value}
      />
      <Button type="gray" onClick={props.onCancel} width="fit-content">
        {t("commons.cancel")}
      </Button>
    </div>
  );
}
