import React from "react";
import { LocalStorageKey, hasConsented } from ".";
import { camelize } from "./components/YesOrNo";
import { BannerContextModel, BannerProps, BannerState } from "./types";

const BannerContext = React.createContext({} as BannerContextModel);

export function BannerProvider(props: React.PropsWithChildren<BannerProps>) {
  const [state, setState] = React.useState<BannerState>({
    prefsOpened: false,
    alertTriggered: false,
    consented: hasConsented(),
  });

  const requiredOptions = props.preferences.filter((opt) => opt.tools !== "");

  const optionNames = requiredOptions.map((opt) => camelize(opt.title));

  const togglePreferences = () => {
    if (state.prefsOpened) {
      document.body.classList.remove("overflow-hidden");
    } else {
      document.body.classList.add("overflow-hidden");
    }
    return setState((prev) => ({ ...prev, prefsOpened: !prev.prefsOpened }));
  };

  const setConsent = (consents: boolean[]) => {
    optionNames.forEach((name, i) => {
      const allowed = consents[i];
      props.onConsent(allowed, i, name);
    });
    localStorage.setItem(LocalStorageKey, "true");
    return setState((prev) => ({ ...prev, consented: true }));
  };

  const goBack = () => {
    return setState((prev) => ({ ...prev, alertTriggered: false }));
  };

  const forceCancel = () => {
    return setState((prev) => ({
      ...prev,
      alertTriggered: false,
      prefsOpened: false,
    }));
  };

  const acceptAll = () => {
    // Consent as all true
    return setConsent(new Array(optionNames.length).fill(true));
  };

  const handlePrefSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const consents = optionNames.map((name) => {
      if (formData.get(name) === "true") {
        return true;
      } else {
        return false;
      }
    });
    return setConsent(consents);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!state.consented) {
      return setState((prev) => ({ ...prev, alertTriggered: true }));
    } else {
      return forceCancel();
    }
  };

  const bannerContext = {
    consented: state.consented,
    prefsOpened: state.prefsOpened,
    alertTriggered: state.alertTriggered,
    togglePreferences,
    headers: props.texts.headers
      ? props.texts.headers
      : ["Allow", "Category", "Purpose", "Tools"],
    preferences: props.preferences,
    texts: props.texts,
    handlePrefSubmit,
    handleCancel,
    goBack,
    onConsent: props.onConsent,
    forceCancel,
    acceptAll,
  };

  return (
    <BannerContext.Provider value={bannerContext}>
      {props.children}
    </BannerContext.Provider>
  );
}

export function useBanner() {
  return React.useContext(BannerContext);
}
