import React from "react";
import { ProductGroupOUT } from "api-client";
import TextSearchInput from "components/TextSearchInput";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";

interface SelectProps {
  onChange: (value: string | undefined, name?: string) => void;
  onReset: () => void | Promise<void>;
}

function SelectProductGroupOnline(props: SelectProps) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";

  const { productGroups } = caOnlineStore;
  if (!productGroups || productGroups.length === 0) {
    return <div className="flex items-center">{t("audits.noLoadingPGs")}</div>;
  }
  const list = [...productGroups].sort((a, b) =>
    a[isEng ? "name" : "name_de"].localeCompare(b[isEng ? "name" : "name_de"])
  );

  return (
    <TextSearchInput<ProductGroupOUT>
      displayKey={isEng ? "name" : "name_de"}
      required={true}
      handleChange={props.onChange}
      onReset={props.onReset}
      labelName={t("audits.selectProductGroup")}
      name="select_product_group"
      items={list}
    />
  );
}

export default observer(SelectProductGroupOnline);
