import { BlueprintOUT, ProductGroupOUT } from "api-client";
import { tryGenerateUrlForImage } from "api-client-local/images";
import EditDeleteItem from "components/ActionMenu/EditDeleteItemMenu";
import GenericListItem from "features/Audit/GenericListItem";
import { observer } from "mobx-react-lite";
import React from "react";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

interface AddedComponentListItemProps {
  addedComponent: BlueprintOUT;
  handleAddedComponentRouting: (
    component: BlueprintOUT,
    pg?: ProductGroupOUT
  ) => void;
  addedComponentImages: { [id: string]: File[] } | undefined;
  productGroups: undefined | ProductGroupOUT[];
}

const AddedComponentListItem = ({
  productGroups,
  addedComponent,
  addedComponentImages,
  handleAddedComponentRouting,
}: AddedComponentListItemProps) => {
  const { selectedProductGroup, addedComponents } = blueprintCreateStore;
  const addedComponentProductGroup = productGroups?.find(
    (pg) => pg.id === selectedProductGroup?.id
  );

  const [itemImage, setItemImage] = React.useState<string>("");
  React.useEffect(() => {
    if (!addedComponentImages || !addedComponent) return;
    const componentImages =
      addedComponentImages[addedComponent.id] ?? undefined;
    if (!componentImages || componentImages.length === 0) return;
    setItemImage(tryGenerateUrlForImage(componentImages[0]) ?? "");
  }, [addedComponentImages]);

  const handleComponentDelete = React.useCallback(() => {
    const newAddedComponents = { ...addedComponents };
    const newAddedComponentImages = {
      ...addedComponentImages,
    };

    delete newAddedComponents[addedComponent.id];
    delete newAddedComponentImages[addedComponent.id];

    blueprintCreateStore.setAddedComponents(newAddedComponents);
    blueprintCreateStore.setAddedComponentImages(newAddedComponentImages);
  }, [addedComponent]);

  const handleRouting = () => {
    handleAddedComponentRouting(addedComponent, addedComponentProductGroup);
  };

  return (
    <GenericListItem
      className="cursor-pointer"
      item={addedComponent}
      key={addedComponent.id}
      itemImageUrl={itemImage ?? ""}
      onClick={() => {
        handleAddedComponentRouting(addedComponent, addedComponentProductGroup);
      }}
    >
      <div className="text-xs">
        <span>{addedComponent.as_component_amount} </span>
        <span>
          {addedComponentProductGroup?.unit}/{selectedProductGroup?.unit}
        </span>
      </div>

      <EditDeleteItem
        onEdit={handleRouting}
        direction="bottom"
        id={addedComponent.id}
        onDelete={handleComponentDelete}
      />
    </GenericListItem>
  );
};

export default observer(AddedComponentListItem);
