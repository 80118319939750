import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import Button from "components/Button";
import Modal from "components/Modal";

const SettingsLayout = ({
  open,
  close,
  hasError,
  children,
}: {
  open: boolean;
  close: () => void;
  hasError?: boolean;
  children: ReactNode;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Modal
        isOpen={open}
        setIsOpen={close}
        containerClassName="md:max-w-4xl !p-0"
        footer={
          <>
            <Button type="link" width="fit-content" onClick={close}>
              {t("commons.close")}
            </Button>
            <div className="text-red-600 text-sm flex">
              {hasError && (
                <>
                  <ExclamationCircleIcon width={19} className="mr-2" />
                  {t("commons.closeErrorMessage")}
                </>
              )}
            </div>
          </>
        }
      >
        {children}
      </Modal>
    </>
  );
};
export default SettingsLayout;
