import Button from "components/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

export function BackComponentsButton() {
  const { setComponentCurrentStep, setSelectedComponentProductGroup } =
    blueprintCreateStore;

  const { t } = useTranslation();

  return (
    <Button
      onClick={() => {
        setComponentCurrentStep(0);
        setSelectedComponentProductGroup(undefined);
      }}
      type="secondary"
      disabledClasses="w-full"
    >
      {t("audits.back")}
    </Button>
  );
}
