import {
  AuditOUT,
  BlueprintIN,
  BlueprintListSortBy,
  BlueprintOUT,
  BlueprintPropertyOUT,
  BlueprintUpdateIN,
  FieldOUT,
  FieldOptionOUT,
  FloorOUT,
  ImageAlbumOUT,
  ImageOUT,
  InstanceIN,
  InstanceOUT,
  InstancePropertyOUT,
  InstanceUpdateIN,
  ManufacturerOUT,
  ProductGroupOUT,
  RoomCreateIN,
  RoomOUT,
  SlimAuditOut,
  SlimBlueprintOUT,
  SortOrder,
  imagesApi,
  onlineAPi,
} from "api-client";
import { db } from "api-client-local/db";
import {
  AuthConfig,
  populateFields,
  populateManufacturers,
  populateProductGroups,
} from "api-client-local/repositories";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable } from "mobx";

/**
 * CAOnline Store is meant to be handling all online APIs
 */

export interface BlueprintSorting {
  sort_by: BlueprintListSortBy;
  sort_order: SortOrder;
}
class CAOnlineStore {
  isOnline = true;
  isLoading = false;

  buildingId = "";
  auditId = "";

  // Product group dropdown pagination - PG => Product Groups
  currentPGPage = 1;
  totalPGCount = 0;

  // Manufacturers dropdown pagination - MAN => Manufacturers
  currentMANPage = 1;
  totalMANCount = 0;

  // Blueprints listing pagination - BP => Blueprints
  blueprintLimit = 10;
  blueprintOffset = 0;
  totalBPCount = 0;
  blueprintSort: BlueprintSorting = {
    sort_by: BlueprintListSortBy.Created,
    sort_order: SortOrder.Desc,
  };

  selectedColor: FieldOptionOUT | undefined;
  fields: FieldOUT[] = [];
  manufacturers: ManufacturerOUT[] = [];
  selectedManufacturer: ManufacturerOUT | undefined;
  productGroups: ProductGroupOUT[] = [];
  selectedProductGroup: ProductGroupOUT | undefined;

  audits: SlimAuditOut[] = [];
  currentAudit: SlimAuditOut = this.initCurrentAudit();
  auditsError = "";

  dimensionsValue = "";
  generatedBlueprintName = "";
  blueprintSet: SlimBlueprintOUT[] = [];
  selectedBlueprint: BlueprintOUT | undefined;
  isComponent = false;
  components: SlimBlueprintOUT[] = [];
  floorSet: FloorOUT[] = [];
  roomSet: RoomOUT[] = [];
  albums: ImageAlbumOUT[] = [];
  blueprintImages: ImageOUT[] = [];
  blueprintPropertySet: BlueprintPropertyOUT[] = [];
  instanceSet: InstanceOUT[] = [];
  selectedInstance: InstanceOUT | undefined;
  instancePropertySet: InstancePropertyOUT[] = [];

  shouldUpdateBlueprintList = false;
  isBlueprintEditMode = false;

  constructor() {
    makeAutoObservable(this);
  }

  initCurrentAudit(): SlimAuditOut {
    return {
      id: "",
      building_id: "",
      created: "",
      created_by: {
        email: "",
        first_name: "",
        last_name: "",
        username: "",
      },
      modified: "",
      title: "",
    };
  }

  setBlueprintSort(blueprintSort: BlueprintSorting) {
    this.blueprintSort = blueprintSort;
  }

  setIsBlueprintEditMode(value: boolean) {
    this.isBlueprintEditMode = value;
  }

  setShouldUpdateBlueprintList(value: boolean) {
    this.shouldUpdateBlueprintList = value;
  }

  setCurrentPGPage(page: number) {
    this.currentPGPage = page;
  }

  setTotalPGCount(count: number) {
    this.totalPGCount = count;
  }

  setFields(value: FieldOUT[]) {
    this.fields = value;
  }

  setProductGroups(value: ProductGroupOUT[]) {
    this.productGroups = value;
  }

  setSelectedProductGroup(value: ProductGroupOUT) {
    this.selectedProductGroup = value;
  }

  setManufacturers(value: ManufacturerOUT[]) {
    this.manufacturers = value;
  }

  setSelectedManufacturer(value: ManufacturerOUT | undefined) {
    this.selectedManufacturer = value;
  }

  setSelectedColor(value: FieldOptionOUT | undefined) {
    this.selectedColor = value;
  }

  setCurrentMANPage(page: number) {
    this.currentMANPage = page;
  }

  setTotalMANCount(count: number) {
    this.totalMANCount = count;
  }

  setBlueprintLimit(limit: number) {
    this.blueprintLimit = limit;
  }

  setBlueprintOffset(offset: number) {
    this.blueprintOffset = offset;
  }

  setTotalBPCount(count: number) {
    this.totalBPCount = count;
  }

  setIsOnline(value: boolean) {
    this.isOnline = value;
  }

  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  setAudits(value: SlimAuditOut[]) {
    this.audits = value;
  }

  setCurrentAudit(value: SlimAuditOut) {
    this.currentAudit = value;
  }

  setBlueprints(value: SlimBlueprintOUT[]) {
    this.blueprintSet = value;
  }

  setBlueprintImages(value: ImageOUT[]) {
    this.blueprintImages = value;
  }

  setSelectedBlueprint(value: BlueprintOUT) {
    this.selectedBlueprint = value;
  }

  setGeneratedBlueprintName(value: string) {
    this.generatedBlueprintName = value;
  }

  setDimensionsValue(value: string) {
    this.dimensionsValue = value;
  }

  setIsComponent(isComp: boolean) {
    this.isComponent = isComp;
  }

  setFloors(value: FloorOUT[]) {
    this.floorSet = value;
  }

  setRooms(value: RoomOUT[]) {
    this.roomSet = value;
  }

  setAlbums(value: ImageAlbumOUT[]) {
    this.albums = value;
  }

  setBlueprintPropertySet(value: BlueprintPropertyOUT[]) {
    this.blueprintPropertySet = value;
  }

  setInstancePropertySet(value: InstancePropertyOUT[]) {
    this.instancePropertySet = value;
  }

  setInstanceSet(value: InstanceOUT[]) {
    this.instanceSet = value;
  }

  setSelectedInstance(value: InstanceOUT | undefined) {
    this.selectedInstance = value;
  }

  setBuildingId(buildingId: string) {
    this.buildingId = buildingId;
  }

  setAuditId(id: string) {
    this.auditId = id;
  }

  /**
   * This method is for example setting up individual members of the audits like blueprints[]
   * or the inner arrays of audits
   *
   * @param field
   * @param value
   */
  setCAStoreFieldAndValue(
    field: keyof AuditOUT | keyof InstanceOUT,
    value:
      | FloorOUT[]
      | RoomOUT[]
      | InstanceOUT[]
      | BlueprintPropertyOUT[]
      | InstancePropertyOUT[]
      | ImageAlbumOUT[]
      | string
      | undefined
  ) {
    this.audits = {
      ...this.audits,
      [field]: value,
    };
  }

  /**
   * Example of fetching Audits from API if online else fetch from local DB
   *
   */
  async fetchAudits(buildingId: string) {
    if (!buildingId) return;

    if (this.isOnline) {
      const authHeader = await getLocalAuthHeader();
      const response = await onlineAPi.caApiV1RoutersCaOnlineAuditListAudits(
        buildingId,
        100,
        0,
        authHeader
      );
      if (response.data.items.length > 0) {
        this.setBlueprints([]);
        await this.fetchFieldsAndPgs();
        this.setAudits(response.data.items);
      } else {
        this.setAudits([]);
      }
    }
  }

  async fetchFloorSet() {
    if (this.isOnline) {
      const auditId = this.currentAudit.id;
      const authHeader = await getLocalAuthHeader();
      const response = await onlineAPi.caApiV1RoutersCaOnlineAuditListFloors(
        auditId,
        100,
        0,
        authHeader
      );
      this.setFloors(response.data.items);
    }
  }

  async fetchRoomSet() {
    if (this.isOnline) {
      const auditId = this.currentAudit.id;
      const authHeader = await getLocalAuthHeader();
      const response = await onlineAPi.caApiV1RoutersCaOnlineAuditListRooms(
        auditId,
        100,
        0,
        authHeader
      );
      this.setRooms(response.data.items);
    }
  }

  updateBlueprintSet(value: SlimBlueprintOUT[]) {
    this.blueprintSet = this.blueprintSet.concat(value);
  }

  storeNewlyCreatedBlueprint(value: SlimBlueprintOUT) {
    this.blueprintSet.unshift(value);
    this.setShouldUpdateBlueprintList(true);
  }

  filterBlueprintAfterDeletion(blueprintId: string) {
    this.blueprintSet = this.blueprintSet.filter((bp) => bp.id !== blueprintId);
  }
  filterComponentsAfterDeletion(compId: string) {
    const parentBlueprint = this.blueprintSet.find((b) =>
      b?.components?.find((c) => c.id === compId)
    );
    const newBlueprintSet = this.blueprintSet
      .map((blueprint) => {
        if (
          parentBlueprint &&
          blueprint.components &&
          blueprint.id === parentBlueprint.id
        ) {
          const componentInParent = blueprint.components.find(
            (c) => c.id === compId
          );
          if (componentInParent) {
            const newComponentList = blueprint.components.filter(
              (c) => c.id !== compId
            );
            blueprint = JSON.parse(JSON.stringify(blueprint));
            blueprint.components = newComponentList;
            return blueprint;
          } else if (blueprint.id === compId) {
            return undefined;
          } else {
            console.error(
              `Component index not found in parent. Please check data.`
            );
            return blueprint;
          }
        } else return blueprint;
      })
      .filter((b) => b !== undefined && b.id !== compId) as SlimBlueprintOUT[];

    this.blueprintSet = newBlueprintSet;
  }

  async fetchBlueprints() {
    if (this.isOnline) {
      this.setIsLoading(true);
      const response = await this.blueprintAPIResponse(0);
      this.setBlueprints(response.data.items);
      this.setTotalBPCount(response.data.count);
      this.setIsLoading(false);
    }
  }

  async fetchBlueprintImages(blueprintId: string) {
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineBlueprintGetBlueprintImages(
        blueprintId,
        authHeader
      );
    this.setBlueprintImages(response.data);
  }

  async deleteBlueprintImage(imageId: string) {
    const authHeader = await getLocalAuthHeader();
    const response =
      await imagesApi.caApiV1RoutersImagesDeleteImageFromBlueprintAlbum(
        imageId,
        authHeader
      );
    if (response.status === 200) {
      const filteredImages = this.blueprintImages.filter(
        (img) => img.id !== imageId
      );
      this.setBlueprintImages(filteredImages);
    }
  }

  async fetchBlueprintNextPage() {
    this.setIsLoading(true);
    const nextoffset = this.blueprintOffset + this.blueprintLimit;
    const response = await this.blueprintAPIResponse(nextoffset);
    this.updateBlueprintSet(response.data.items);
    this.setBlueprintOffset(nextoffset);
    this.setTotalBPCount(response.data.count);
    this.setIsLoading(false);
  }

  async fetchBlueprintSearch(searchTerm: string) {
    this.setIsLoading(true);
    const response = await this.blueprintSearchAPIResponse(0, searchTerm);
    this.setBlueprints(response.data.items);
    this.setTotalBPCount(response.data.count);
    this.setIsLoading(false);
  }

  async createBlueprint(blueprintIN: BlueprintIN) {
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineBlueprintCreateBlueprint(
        this.currentAudit.id,
        blueprintIN,
        authHeader
      );
    const createdBP = response.data;
    this.storeNewlyCreatedBlueprint(createdBP);
    return createdBP;
  }

  async updateBlueprint(blueprintId: string, blueprintIN: BlueprintUpdateIN) {
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineBlueprintUpdateBlueprint(
        blueprintId,
        blueprintIN,
        authHeader
      );

    return response;
  }

  async duplicateBlueprint(blueprintId: string) {
    this.setIsLoading(true);
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineBlueprintDuplicateBlueprint(
        blueprintId,
        authHeader
      );
    const duplicatedBlueprint = response.data;
    this.blueprintSet.unshift(duplicatedBlueprint);

    this.setIsLoading(false);
  }

  async deleteBlueprint(blueprintId: string) {
    this.setIsLoading(true);
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(
        this.currentAudit.id,
        blueprintId,
        authHeader
      );
    if (response.status === 200) {
      this.filterBlueprintAfterDeletion(blueprintId);
    } else {
      console.error(`failed to delete Blueprint ID - ${blueprintId}`);
    }
    this.setIsLoading(false);
  }

  async deleteComponent(compId: string) {
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineBlueprintDeleteBlueprint(
        this.currentAudit.id,
        compId,
        authHeader
      );
    if (response.status === 200) {
      this.filterComponentsAfterDeletion(compId);
    } else {
      console.error(`failed to delete Component ID - ${compId}`);
    }
  }

  async blueprintAPIResponse(offset: number) {
    const auditId = this.currentAudit.id;
    const authHeader = await getLocalAuthHeader();
    const { sort_by, sort_order } = this.blueprintSort;
    return await onlineAPi.caApiV1RoutersCaOnlineBlueprintListBlueprints(
      auditId,
      sort_order,
      sort_by,
      this.blueprintLimit,
      offset,
      authHeader
    );
  }

  async blueprintSearchAPIResponse(offset: number, searchTerm: string) {
    const auditId = this.currentAudit.id;
    const authHeader = await getLocalAuthHeader();
    return await onlineAPi.caApiV1RoutersCaOnlineBlueprintSearchBlueprints(
      auditId,
      searchTerm,
      this.blueprintLimit,
      offset,
      authHeader
    );
  }

  async fetchBlueprintDetails(blueprintId?: string) {
    if (!blueprintId) return;

    if (this.isOnline) {
      const authHeader = (await getLocalAuthHeader()) as AuthConfig;
      const response =
        await onlineAPi.caApiV1RoutersCaOnlineBlueprintGetBlueprintDetails(
          blueprintId,
          authHeader
        );

      const selectedBlueprint = response.data;
      this.setSelectedBPOrComponents(selectedBlueprint);
    }
  }

  setSelectedBPOrComponents(selectedBlueprint: BlueprintOUT) {
    const selectedProductGroup = this.productGroups.find(
      (el) => el.id === selectedBlueprint.product_group_id
    );
    this.setSelectedProductGroup(selectedProductGroup!);
    this.setSelectedBlueprint(selectedBlueprint);
  }

  async fetchFieldsAndPgs() {
    const authHeader = (await getLocalAuthHeader()) as AuthConfig;

    const fields = await db.fields.toArray();
    if (fields.length === 0) {
      await populateFields(authHeader);
    }
    let manufacturers = await db.manufacturers.toArray();
    if (manufacturers.length === 0) {
      manufacturers = await populateManufacturers(authHeader);
    }
    this.setManufacturers(manufacturers);

    let productGroups = await db.product_groups.toArray();
    if (productGroups.length === 0) {
      productGroups = await populateProductGroups(authHeader);
    }

    this.setProductGroups(productGroups);
  }

  async fetchInstanceSet(blueprintId: string) {
    const authHeader = (await getLocalAuthHeader()) as AuthConfig;
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineInstanceListInstances(
        blueprintId,
        100,
        0,
        authHeader
      );
    this.setInstanceSet(response.data.items);
  }

  updateInstanceSet(instanceId: string) {
    const updatedInstanceSet = this.instanceSet.filter(
      (ins) => ins.id !== instanceId
    );
    if (this.selectedBlueprint) {
      this.selectedBlueprint = {
        ...this.selectedBlueprint,
        instance_set: updatedInstanceSet,
      };
    }
    this.instanceSet = updatedInstanceSet;
  }

  async deleteInstanceSet(instanceId: string) {
    const authHeader = (await getLocalAuthHeader()) as AuthConfig;
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineInstanceDeleteInstance(
        this.currentAudit.id,
        instanceId,
        authHeader
      );
    if (response.status === 200) {
      this.updateInstanceSet(instanceId);
    }
    return response;
  }

  async createInstanceSet(instanceIN: InstanceIN) {
    const authHeader = (await getLocalAuthHeader()) as AuthConfig;
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineInstanceCreateInstance(
        instanceIN,
        authHeader
      );
    if (response.status !== 200) {
      console.error("Error creating new instance: ", response);
    } else {
      this.setShouldUpdateBlueprintList(true);
    }
  }

  async updateInstance(instanceId: string, instanceIN: InstanceUpdateIN) {
    const authHeader = (await getLocalAuthHeader()) as AuthConfig;
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineInstanceUpdateInstance(
        instanceId,
        instanceIN,
        authHeader
      );
    if (response.status !== 200) {
      console.error("Error updating instance: ", instanceId, response);
    }
  }

  setDuplicateInstanceSet(duplicatedInstance: InstanceOUT) {
    this.instanceSet.unshift(duplicatedInstance);
    if (this.selectedBlueprint) {
      this.selectedBlueprint.instance_set.unshift(duplicatedInstance);
    }
  }

  async duplicateInstance(instanceId: string) {
    const authHeader = await getLocalAuthHeader();
    const response =
      await onlineAPi.caApiV1RoutersCaOnlineInstanceDuplicateInstance(
        instanceId,
        authHeader
      );
    this.setDuplicateInstanceSet(response.data);
  }

  updateRoomSet(roomOUT: RoomOUT) {
    this.roomSet.push(roomOUT);
  }

  async createRoomAndGetId(roomName: string) {
    const authHeader = await getLocalAuthHeader();
    const roomIN: RoomCreateIN = {
      audit_id: this.currentAudit.id,
      name: roomName,
    };
    const response = await onlineAPi.caApiV1RoutersCaOnlineRoomCreateRoom(
      roomIN,
      authHeader
    );

    const roomOUT = response.data;
    this.updateRoomSet(roomOUT);
    return roomOUT.id;
  }

  // Singleton instance of CA Store
  static _instance: CAOnlineStore;

  static get getInstance(): CAOnlineStore {
    if (!CAOnlineStore._instance) {
      CAOnlineStore._instance = new CAOnlineStore();
    }
    return CAOnlineStore._instance;
  }
}

export const caOnlineStore = CAOnlineStore.getInstance;
