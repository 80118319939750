import React from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  dimensionsValueAtom,
  generatedBlueprintNameAtom,
  manufacturerNameAtom,
  productGroupNameAtom,
  selectedColorAtom,
} from "store/atoms/audits";

export default function GeneratedName() {
  const { t } = useTranslation();
  const setGeneratedBlueprintName = useSetRecoilState(
    generatedBlueprintNameAtom
  );
  const manufacturerName = useRecoilValue(manufacturerNameAtom);
  const productGroupName = useRecoilValue(productGroupNameAtom);
  const dimensionsValue = useRecoilValue(dimensionsValueAtom);
  const selectedColor = useRecoilValue(selectedColorAtom);

  React.useEffect(() => {
    const generatedName = `${productGroupName ?? ""} ${
      manufacturerName ?? ""
    } ${dimensionsValue ?? ""}`
      .trim()
      .replace(/\s+/g, " ");
    console.log("generatedName", generatedName);
    setGeneratedBlueprintName(generatedName);
  }, [manufacturerName, productGroupName, dimensionsValue]);

  return (
    <div className="mb-2 font-semibold">
      {productGroupName !== "" ||
      manufacturerName !== "" ||
      dimensionsValue !== "" ? (
        <div className="">
          <span id="productgroup_value" className="inline-block mr-1">
            {productGroupName}
          </span>
          {manufacturerName && (
            <span id="manufacturer_value" className="inline-block mr-1">
              {manufacturerName}
            </span>
          )}
          <span id="dimensions_value" className="inline-block mr-2">
            {dimensionsValue}
          </span>
          <span className="inline-block">
            {selectedColor && (
              <div
                className="h-4 w-4 rounded-sm translate-y-[2px]"
                style={{ backgroundColor: `${selectedColor.value}` }}
              ></div>
            )}
          </span>
        </div>
      ) : (
        t("audits.addNewBlueprint")
      )}
    </div>
  );
}
