import { DGNBStore } from "store/DGNBStore";
import { observer } from "mobx-react-lite";
import SimpleSelect from "components/SimpleSelect";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "components/DatePicker";
import {
  building_type_pollution_measures,
  building_type_pollution_comment,
  building_type_pollution,
  building_type_pollution_quality,
  dgnb_lvl_pollution,
  dgnb_lvl_pollution_quality,
  pollution_usage,
  dgnb_report_pollution,
  dgnb_result_report_pollution,
  building_type_pollution_measures_comment,
  dgnb_report_pollution_vorhanden,
} from "features/Building/consts.d";
import TextInput from "components/TextInput";
import { FormHelperLabel, StepHeading, valueFromOptions } from "./utils";

const PollutantReport = () => {
  const store = DGNBStore.getInstance();
  const { dgnbPassport } = store;

  const { t } = useTranslation();

  const showClassificationComment =
    dgnbPassport.building_type_pollution_measure ==
    building_type_pollution_measures_comment;
  const showBuildingPollutionTypeComment =
    dgnbPassport.building_type_pollution == building_type_pollution_comment;

  const showDateReportPollution =
    dgnbPassport.report_pollution &&
    dgnbPassport.report_pollution == dgnb_report_pollution_vorhanden;

  return (
    <div className="w-full h-[100%] flex flex-col px-6">
      <StepHeading text={t("DGNB.pollutant_report_heading")} />

      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.building_type_pollution_measure__label")}
            options={building_type_pollution_measures}
            name="building_type_pollution_measure"
            placeholder={t("DGNB.building_type_pollution_measure__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "building_type_pollution_measure",
                building_type_pollution_measures,
                value
              )
            }
            current={valueFromOptions(
              building_type_pollution_measures,
              dgnbPassport.building_type_pollution_measure
            )}
            noMargin
          />
          <FormHelperLabel
            text={t("DGNB.building_type_pollution_measure__helper")}
          />
        </div>

        <div className="col-span-1">
          {showClassificationComment && (
            <>
              <TextInput
                className="rounded-md"
                labelName={t(
                  "DGNB.building_type_pollution_measure_comment__label"
                )}
                placeholder={t(
                  "DGNB.building_type_pollution_measure_comment__placeholder"
                )}
                type="text"
                min={1}
                name="building_type_pollution_measure_explanation"
                onChange={(e) => e.preventDefault()}
                handleChange={(value: string) =>
                  store.setDGNBPassFieldAndValue(
                    "building_type_pollution_measure_explanation",
                    `${value}`
                  )
                }
                value={
                  dgnbPassport.building_type_pollution_measure_explanation ?? ""
                }
                noMargin
              />
              <FormHelperLabel
                text={t("DGNB.building_type_pollution_measure_comment__helper")}
              />
            </>
          )}
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.building_type_pollution__label")}
            options={building_type_pollution}
            name="building_type_pollution"
            placeholder={t("DGNB.building_type_pollution__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "building_type_pollution",
                building_type_pollution,
                value
              )
            }
            current={valueFromOptions(
              building_type_pollution,
              dgnbPassport.building_type_pollution
            )}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.building_type_pollution__helper")} />
        </div>

        <div className="col-span-1">
          {showBuildingPollutionTypeComment && (
            <>
              <TextInput
                className="rounded-md"
                labelName={t("DGNB.building_type_pollution_comment__label")}
                placeholder={t(
                  "DGNB.building_type_pollution_comment__placeholder"
                )}
                type="text"
                name="building_type_pollution_explanation"
                onChange={(e) => e.preventDefault()}
                handleChange={(value: string) =>
                  store.setDGNBPassFieldAndValue(
                    "building_type_pollution_explanation",
                    `${value}`
                  )
                }
                value={dgnbPassport.building_type_pollution_explanation ?? ""}
                noMargin
              />
              <FormHelperLabel
                text={t("DGNB.building_type_pollution_comment__helper")}
              />
            </>
          )}
        </div>

        <div className="col-span-2">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.building_type_pollution_quality__label")}
            options={building_type_pollution_quality}
            name="building_type_pollution_quality"
            placeholder={t("DGNB.building_type_pollution_quality__placeholder")}
            onChange={(value) =>
              store.handleOnDropdownChange(
                "building_type_pollution_quality",
                building_type_pollution_quality,
                value
              )
            }
            current={valueFromOptions(
              building_type_pollution_quality,
              dgnbPassport.building_type_pollution_quality
            )}
            noMargin
          />
          <FormHelperLabel
            text={t("DGNB.building_type_pollution_quality__helper")}
          />
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.lvl_pollution__label")}
            options={dgnb_lvl_pollution}
            name="lvl_pollution"
            placeholder={t("DGNB.lvl_pollution__placeholder")}
            noMargin
            onChange={(value) =>
              store.handleOnDropdownChange(
                "lvl_pollution",
                dgnb_lvl_pollution,
                value
              )
            }
            current={
              dgnb_lvl_pollution.find(
                (item) => item.name === dgnbPassport.lvl_pollution
              )?.id ?? ""
            }
          />
          <FormHelperLabel text={t("DGNB.lvl_pollution__helper")} />
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.lvl_pollution_quality__label")}
            options={dgnb_lvl_pollution_quality}
            name="lvl_pollution_quality"
            placeholder={t("DGNB.lvl_pollution_quality__placeholder")}
            noMargin
            onChange={(value) =>
              store.handleOnDropdownChange(
                "lvl_pollution_quality",
                dgnb_lvl_pollution_quality,
                value
              )
            }
            current={valueFromOptions(
              dgnb_lvl_pollution_quality,
              dgnbPassport.lvl_pollution_quality
            )}
          />
          <FormHelperLabel text={t("DGNB.lvl_pollution_quality__helper")} />
        </div>

        <div className="col-span-2">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.pollution_usage__label")}
            options={pollution_usage}
            name="pollution_usage"
            placeholder={t("DGNB.pollution_usage__placeholder")}
            noMargin
            onChange={(value) =>
              store.handleOnDropdownChange(
                "pollution_usage",
                pollution_usage,
                value
              )
            }
            current={valueFromOptions(
              pollution_usage,
              dgnbPassport.pollution_usage
            )}
          />
          <FormHelperLabel text={t("DGNB.pollution_usage__helper")} />
        </div>

        <div className="col-span-2">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.report_pollution__label")}
            options={dgnb_report_pollution}
            name="report_pollution"
            placeholder={t("DGNB.report_pollution__placeholder")}
            noMargin
            onChange={(value) =>
              store.handleOnDropdownChange(
                "report_pollution",
                dgnb_report_pollution,
                value
              )
            }
            current={valueFromOptions(
              dgnb_report_pollution,
              dgnbPassport.report_pollution
            )}
          />
          <FormHelperLabel text={t("DGNB.report_pollution__helper")} />
        </div>

        <div className="col-span-1">
          {showDateReportPollution && (
            <>
              <CustomDatePicker
                popperPlacement="auto"
                value={dgnbPassport.date_report_pollution ?? ""}
                name="date_report_pollution"
                dateFormat="dd.MM.yyyy"
                placeholder="DD.MM.YYYY"
                isDgnb
                onChangeDgnb={(value) =>
                  store.setDGNBPassFieldAndValue("date_report_pollution", value)
                }
                label={t("DGNB.date_report_pollution__label")}
              />
              <FormHelperLabel text={t("DGNB.date_report_pollution__helper")} />
            </>
          )}
        </div>

        <div className="col-span-1">
          <SimpleSelect
            className="rounded-md"
            labelName={t("DGNB.result_report_pollution__label")}
            options={dgnb_result_report_pollution}
            name="result_report_pollution"
            onChange={(value) =>
              store.handleOnDropdownChange(
                "result_report_pollution",
                dgnb_result_report_pollution,
                value
              )
            }
            current={valueFromOptions(
              dgnb_result_report_pollution,
              dgnbPassport.result_report_pollution
            )}
            placeholder={t("DGNB.result_report_pollution__placeholder")}
            noMargin
          />
          <FormHelperLabel text={t("DGNB.result_report_pollution__helper")} />
        </div>
      </div>
    </div>
  );
};

export default observer(PollutantReport);
