import React from "react";
import Modal from "components/Modal";
import CircularProgressBar from "components/CircularProgressBar";
import Alert from "components/Alert";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue } from "recoil";
import { openMQPopupAtom, modalQualityAtom } from "store/atoms";
import { IFC_ENTITIES_URL, REQUIREMENTS_URL } from "utils";
import { Report } from "features/MappingTools/utils";

export function ModalQualityPopup() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useRecoilState(openMQPopupAtom);
  const report = useRecoilValue(modalQualityAtom) as Report;

  const { imported, total, percentage } = report;
  const activeStroke =
    percentage <= 25 ? "#EF4444" : percentage <= 50 ? "#F59E0B" : "#10B981";

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      closeButton
      title={t("buildingMaterialResources.modalQuality")}
      disableOutsideClick
      containerClassName="sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl min-w-[706px]"
    >
      <div className="items-center justify-center py-4 px-2">
        <div className="w-full pb-4 flex items-center justify-center">
          <CircularProgressBar
            textStyle="text-xl text-gray-700 p-2 font-semibold"
            activeStroke={activeStroke}
            percent={percentage}
          />
        </div>

        <p className="px-4 pt-1 pb-4 text-gray-700 text-base text-center font-normal">
          {t("buildingMaterialResources.modalQualityTooltip", {
            imported,
            total,
          })}
        </p>
        {percentage < 75 ? (
          <div className="px-4 pt-2 pb-1">
            <Alert
              type="attention"
              title={t("buildingMaterialResources.poorModelAlertTitle")}
              description={t(
                "buildingMaterialResources.poorModelAlertDescription"
              )}
              actionButton={t(
                "buildingMaterialResources.showModelRequirements"
              )}
              actionButtonOnClick={() =>
                window.open(REQUIREMENTS_URL, "_blank", "noopener,noreferrer")
              }
            />
          </div>
        ) : null}
        <div className="px-4 py-2">
          <Alert
            type="info"
            description={t(
              "buildingMaterialResources.modalQualityAlertDescription"
            )}
            actionButton={t("buildingMaterialResources.showListOfIFCEntities")}
            actionButtonOnClick={() =>
              window.open(IFC_ENTITIES_URL, "_blank", "noopener,noreferrer")
            }
          />
        </div>
      </div>
    </Modal>
  );
}
