import React from "react";
import { BuildingOUT, ifcApi, MaterialResourceOUT } from "api-client";
import { DownloadIcon } from "@heroicons/react/solid";
import { TrashIcon } from "@heroicons/react/outline";
import useToast from "hooks/useToast";
import ActionMenu from "components/ActionMenu";
import { useNavigate } from "react-router";
import { Icon3dCubeSphere } from "@tabler/icons-react";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";
import { useTranslation } from "react-i18next";

interface MaterialResourcesActionsProps {
  file: MaterialResourceOUT;
  openSettings?: () => void;
  disabled: boolean;
}

export const MaterialResourcesActions = observer(
  ({ file, disabled = false }: MaterialResourcesActionsProps) => {
    const { authHeader } = userStore;
    const toast = useToast();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const deleteFiles = () => {
      if (!authHeader) return console.error("authHeader undefined");
      ifcApi
        .ifcApiV1RoutersIfcFileDeleteIfcFile(file.id, authHeader)
        .then(async () => {
          await buildingStore.getAllMaterialResources();
          await buildingStore
            .getCurrentBuildingDetails()
            .then((response: void | BuildingOUT) => {
              response && buildingStore.setCurrentBuilding(response);
            });
          toast(t("buildingMaterialResources.deleteFileSuccess"), "success");
        })
        .catch((err) => toast(err.message, "warning"));
    };

    const goTo3DViewer = () =>
      navigate(`../viewer/${file.id}`, {
        replace: true,
        state: `${file.name}.${file.file_type ?? ""}`,
      });

    const menuItems = [
      ...(file.file_processed_xkt && !disabled
        ? [
            <div
              key={0}
              onClick={goTo3DViewer}
              className="flex items-center px-4 py-1 w-full"
            >
              <Icon3dCubeSphere width={15} className="mr-3" />
              {t("buildingMaterialResources.openIn3DViewer")}
            </div>,
          ]
        : []),
      <a
        key={1}
        href={file.file as string}
        className="flex items-center px-4 py-1.5 w-full"
        download
      >
        <DownloadIcon width={15} className="mr-3" />
        {t("buildingMaterialResources.download")}
      </a>,
      <div
        key={2}
        onClick={deleteFiles}
        className="flex items-center px-4 py-1.5 w-full"
      >
        <TrashIcon width={15} className="mr-3" />
        {t("buildingMaterialResources.delete")}
      </div>,
    ];

    return (
      <ActionMenu
        dotSize={18}
        className="z-1"
        items={menuItems}
        direction="top"
        itemsClassName="!-bottom-2 !right-3"
        itemClassName="!p-0 min-w-[200px]"
      />
    );
  }
);
