import React from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import useAuditFormResetOnline from "./useAuditFormResetOnline";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

const AuditBackButtonOnline = () => {
  const { id, audit_id, blueprint_id, instance_id } = useParams();
  const { currentAudit } = caOnlineStore;
  const { stepperOpened } = blueprintCreateStore;
  const reset = useAuditFormResetOnline();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const getNavigationRoute = () => {
    if (instance_id || pathname.includes(`/blueprints/${blueprint_id}/add`)) {
      return `/buildings/${id}/audits-online/${audit_id}/blueprints/${blueprint_id}/instances`;
    }

    return `/buildings/${id}/audits-online/${audit_id}`;
  };

  return (
    <Link
      to={getNavigationRoute()}
      onClick={reset}
      className="flex justify-between items-center w-full"
    >
      <div className="flex">
        <ChevronLeftIcon className="mr-2" width={16} />
        <span className="text-sm whitespace-pre overflow-hidden text-ellipsis">
          {currentAudit.title}
        </span>
      </div>
      {stepperOpened && (
        <button
          onClick={reset}
          className="bg-gray-100 h-7 px-2 rounded-md text-xs"
        >
          {t("addBuilding.cancel")}
        </button>
      )}
    </Link>
  );
};

export default observer(AuditBackButtonOnline);
