import React from "react";
import { Outlet, useLocation, useParams } from "react-router";
import EditBlueprint from "features/Audit/EditBlueprint";

export default function Blueprint() {
  const { blueprint_id } = useParams();
  const { pathname } = useLocation();
  const isIndexRoute = pathname.endsWith(blueprint_id as string);
  return <>{isIndexRoute ? <EditBlueprint /> : <Outlet />}</>;
}
