import { auditsApi, InstanceOUT } from "api-client";
import { setLocalAudit } from "api-client-local/audits";
import { makeInstanceRawModel } from "features/Audit/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import useBlueprint from "./useBlueprint";
import useMultiselect from "./useMultiselect";
import { getLocalAuthHeader } from "../api-client-local/utils";
import { checkAndAddBlueprintId } from "utils";
import { userStore } from "store/UserStore";

export default function useAuditInstanceActions(
  selectedIds: string[],
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>,
  setItems: React.Dispatch<React.SetStateAction<InstanceOUT[]>>,
  setFormLoading: React.Dispatch<React.SetStateAction<string>>
) {
  const { audit_id, blueprint_id } = useParams();
  const { audit, selectedBlueprint, selectedProductGroup } =
    useBlueprint(blueprint_id);
  const { t } = useTranslation();
  const setAudit = useSetRecoilState(currentAuditAtom);

  const { userProfile } = userStore;

  const handleDuplicate = React.useCallback(
    async (id: string) => {
      try {
        if (
          !selectedBlueprint ||
          !selectedProductGroup ||
          !userProfile ||
          !audit
        ) {
          console.log(`selectedBlueprint`, selectedBlueprint);
          console.log(`selectedProductGroup`, selectedProductGroup);
          console.log(`userProfile`, userProfile);
          console.log(`audit`, audit);
          throw new Error(`Blueprint or pg or userProfile not selected`);
        }
        const oldInstance = selectedBlueprint.instance_set.find(
          (e) => e.id === id
        );
        if (!oldInstance) {
          throw new Error(`oldInstance not found in ${selectedBlueprint.id}`);
        }
        const newInstance = makeInstanceRawModel(
          selectedBlueprint.id,
          userProfile,
          oldInstance
        );

        const blueprintIndex = audit.blueprint_set.findIndex(
          (b) => b.id === selectedBlueprint.id
        );
        if (blueprintIndex === -1) {
          throw new Error(`Blueprint index not found`);
        }

        const auditCopy = JSON.parse(JSON.stringify(audit)) as typeof audit;
        if (!auditCopy) {
          throw new Error(`auditCopy not created`);
        }

        auditCopy.blueprint_set[blueprintIndex].instance_set.push(newInstance);

        const auditRequest = {
          ...auditCopy,
          id: audit.id,
          blueprint_set: auditCopy.blueprint_set,
        };

        const updatedLocalAudit = await setLocalAudit(auditRequest);
        // TODO: Implement image duplication
        // await handleInstanceLocalImagesDuplicate(oldInstance, newInstance);
        setAudit(updatedLocalAudit);
        await checkAndAddBlueprintId(blueprint_id ?? "");
      } catch (err) {
        console.error(err);
      }
    },
    [audit, blueprint_id, selectedProductGroup, selectedBlueprint]
  );

  const handleDelete = React.useCallback(
    async function (id: string) {
      if (!audit || !audit_id) return;
      try {
        setFormLoading(t("audits.successfullyDeleted"));
        const localAuthHeader = await getLocalAuthHeader();
        try {
          await auditsApi.caApiV1RoutersAuditDeleteInstance(
            id,
            localAuthHeader
          );
        } catch (e) {
          console.error("Failed to delete instance from remote", e, id);
        }

        let instancesAfterDeletion: InstanceOUT[];
        setItems((prev) => {
          instancesAfterDeletion = prev.filter((item) => item.id !== id);
          return instancesAfterDeletion;
        });

        const auditCopy = JSON.parse(JSON.stringify(audit)) as typeof audit;
        const blueprintIndex = auditCopy.blueprint_set.findIndex(
          (b) => b.id === blueprint_id
        );
        if (blueprintIndex === -1) {
          throw new Error(`Blueprint not found in blueprint_set`);
        }
        const newSet = auditCopy.blueprint_set[
          blueprintIndex
        ].instance_set.filter((item) => item.id !== id);

        auditCopy.blueprint_set[blueprintIndex].instance_set = newSet;

        const auditRequest = {
          ...auditCopy,
          id: audit_id,
          blueprint_set: auditCopy.blueprint_set,
        };

        const updatedAudit = await setLocalAudit(auditRequest);
        setAudit(updatedAudit);
        setSelectedIds([]);

        await checkAndAddBlueprintId(blueprint_id ?? "");

        setFormLoading("");
      } catch (err) {
        console.error(err);
      }
    },
    [audit, blueprint_id, selectedProductGroup, selectedBlueprint]
  );

  const handleReset = React.useCallback(
    async function (id: string) {
      if (!audit || !audit_id) return;
      try {
        setFormLoading("Successfully Reset");
        const localAuthHeader = await getLocalAuthHeader();
        try {
          await auditsApi.caApiV1RoutersAuditGetAudit(id, localAuthHeader);
        } catch (e) {
          console.error("Failed to reset instance from remote", e, id);
        }

        let instancesAfterDeletion: InstanceOUT[];
        setItems((prev) => {
          instancesAfterDeletion = prev.filter((item) => item.id !== id);
          return instancesAfterDeletion;
        });

        const auditCopy = JSON.parse(JSON.stringify(audit)) as typeof audit;
        const blueprintIndex = auditCopy.blueprint_set.findIndex(
          (b) => b.id === blueprint_id
        );
        if (blueprintIndex === -1) {
          throw new Error(`Blueprint not found in blueprint_set`);
        }
        const newSet = auditCopy.blueprint_set[blueprintIndex].instance_set.map(
          (item) => item
        );

        auditCopy.blueprint_set[blueprintIndex].instance_set = newSet;

        const auditRequest = {
          ...auditCopy,
          id: audit_id,
          blueprint_set: auditCopy.blueprint_set,
        };

        const updatedAudit = await setLocalAudit(auditRequest);
        setAudit(updatedAudit);
        setSelectedIds([]);

        setFormLoading("");
      } catch (err) {
        console.error(err);
      }
    },
    [audit, blueprint_id, selectedProductGroup, selectedBlueprint]
  );

  const handleMultiSelect = useMultiselect(selectedIds, setSelectedIds);
  return { handleDelete, handleDuplicate, handleMultiSelect, handleReset };
}
