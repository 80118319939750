import React from "react";
import EPDRow from "./EPDRow";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";
import { observer } from "mobx-react-lite";
import clsx from "clsx";
import ErrorMessages from "./ErrorMessages";

export default observer(function Dataset({
  datasetIndex,
}: {
  datasetIndex: number;
}) {
  const { t } = useTranslation();

  const isLastRow = (index: number) => {
    return (
      buildingProductStore.data?.groups?.[datasetIndex]?.epds?.length ===
      index + 1
    );
  };

  const checkRowsValidity = () => {
    return (
      buildingProductStore.checkEpdValidationErrors() ||
      buildingProductStore.checkFactorValidationErrors() ||
      buildingProductStore.checkMaterialValidationErrors()
    );
  };

  const addRow = () => {
    if (!checkRowsValidity()) {
      buildingProductStore.addEpdRow(datasetIndex);
    }
  };

  const handleOnRemoveOrReset = () => {
    buildingProductStore.data.groups?.length === 1
      ? buildingProductStore.clearDataset()
      : buildingProductStore.removeDataset(datasetIndex);
  };

  return (
    <div className="bg-white border-gray-300 border p-6 rounded-md mb-4 last-of-type:mb-0 overflow-x-auto">
      {buildingProductStore.data?.groups?.[datasetIndex]?.epds?.map(
        (row, rowIndex) => (
          <EPDRow
            key={rowIndex}
            row={row}
            showLabels={rowIndex === 0}
            datasetIndex={datasetIndex}
            rowIndex={rowIndex}
            className={clsx("pt-1.5 pb-2 border-b border-gray-100", {
              "border-b-0": isLastRow(rowIndex),
            })}
          />
        )
      )}
      <ErrorMessages datasetIndex={datasetIndex} />
      <div className="flex gap-4 text-gray-700 mt-3">
        <Button
          type="gray"
          size="small"
          leadingIcon={<IconPlus className="text-gray-700" width={15} />}
          onClick={addRow}
        >
          {t("productEditor.addEpd")}
        </Button>
        <Button
          type="danger"
          size="small"
          leadingIcon={<IconTrash width={15} />}
          className="!w-60 whitespace-nowrap"
          onClick={handleOnRemoveOrReset}
        >
          {buildingProductStore.data.groups?.length === 1
            ? t("productEditor.resetDataset")
            : t("productEditor.removeDataset")}
        </Button>
      </div>
    </div>
  );
});
