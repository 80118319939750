import React from "react";
import { useRecoilValue } from "recoil";
import {
  selectedComponentProductGroupAtom,
  addedComponentsAtom,
  addedComponentImagesAtom,
  selectedProductGroupAtom,
  selectedComponentAtom,
} from "store/atoms/audits";
import GeneratedFields from "../../../../features/Audit/GeneratedFields";
import TextInput from "components/TextInput";
import PingAnimation from "components/PingAnimation";
import { addValuesToFieldsFromProperties } from "../../../../features/Audit/utils";
import ImageUploaderLocal from "components/ImageUploaderLocal";
import { BackComponentsButton, EditComponentButton } from "../navigation";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import SelectManufacturer from "features/Audit/SelectManufacturer";
import { ManufacturerOUT } from "api-client";
import { observer } from "mobx-react-lite";
import SubmitComponentButton from "./SubmitComponentButton";

export default observer(function AddComponent() {
  const { i18n, t } = useTranslation();
  const selectedComponentProductGroup = useRecoilValue(
    selectedComponentProductGroupAtom
  );
  const addedComponents = useRecoilValue(addedComponentsAtom);
  const selectedComponent = useRecoilValue(selectedComponentAtom);
  const selectedProductGroup = useRecoilValue(selectedProductGroupAtom);
  const addedComponentImages = useRecoilValue(addedComponentImagesAtom);

  useDisableScrollOnNumberInputs();

  const addedBefore = React.useMemo(() => {
    if (!selectedComponent) return false;
    return Object.keys(addedComponents).includes(selectedComponent.id);
  }, [selectedComponent]);

  const foundImages = React.useMemo(() => {
    if (addedBefore && addedComponentImages && selectedComponent) {
      return addedComponentImages[selectedComponent.id];
    }
    return null;
  }, [addedBefore, addedComponentImages, selectedComponent]);

  const ComponentPropertyFields = React.useCallback(() => {
    if (!selectedComponentProductGroup) return null;
    if (addedBefore && selectedComponent) {
      const addedComponentProps =
        addedComponents[selectedComponent.id].blueprintproperty_set;
      const requiredDefaultValues = addValuesToFieldsFromProperties(
        selectedComponentProductGroup.required_fields,
        addedComponentProps
      );
      const optionalDefaultValues = addValuesToFieldsFromProperties(
        selectedComponentProductGroup.optional_fields,
        addedComponentProps
      );
      return (
        <GeneratedFields
          requiredDefaultValues={requiredDefaultValues}
          optionalDefaultValues={optionalDefaultValues}
          required_fields={selectedComponentProductGroup.required_fields}
          optional_fields={selectedComponentProductGroup.optional_fields}
        />
      );
    }
    return (
      <GeneratedFields
        required_fields={selectedComponentProductGroup.required_fields}
        optional_fields={selectedComponentProductGroup.optional_fields}
      />
    );
  }, [selectedComponentProductGroup, selectedComponent, addedBefore]);

  if (
    !selectedComponentProductGroup ||
    selectedComponentProductGroup === null
  ) {
    return (
      <div>
        <PingAnimation size="small" color="gray" />
      </div>
    );
  }

  return (
    <>
      <TextInput
        type="text"
        className="sm:py-2"
        defaultValue={
          addedBefore && selectedComponent
            ? selectedComponent.name
            : i18n.language === "en"
            ? selectedComponentProductGroup?.name
            : selectedComponentProductGroup?.name_de
        }
        labelName="Name"
        name="name"
      />
      <TextInput
        containerClass="w-[49%]"
        className="sm:py-2 "
        unit={`${selectedComponentProductGroup?.unit}/${selectedProductGroup?.unit}`}
        labelName={t("audits.amount")}
        type="number"
        defaultValue={selectedComponent?.as_component_amount}
        name="as_component_amount"
      />
      <SelectManufacturer
        selected={selectedComponent?.manufacturer as ManufacturerOUT}
      />
      <ImageUploaderLocal
        id="component-images"
        images={foundImages ?? undefined}
        name="images_component"
        multiple
        labelName={t("audits.addImages")}
      />
      {ComponentPropertyFields()}
      <div className="flex justify-evenly pt-6">
        <BackComponentsButton />
        {addedBefore ? <EditComponentButton /> : <SubmitComponentButton />}
      </div>
    </>
  );
});
