import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BlueprintOUT } from "api-client";
import { PlusCircleIcon } from "@heroicons/react/outline";
import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import GenericListItem from "features/Audit/GenericListItem";
import useAuditProductGroup from "hooks/useAuditProductGroup";
import ItemAmount from "./ItemAmount";
import { getFirstLocalImageForSource } from "../../api-client-local/images";
import { countMissingRequiredFields } from "./utils";

interface BlueprintListItemProps {
  item: BlueprintOUT;
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  syncSuccess: boolean;
  handlers: {
    handleDuplicate: (id: string) => Promise<void>;
    handleMultiSelect: (id: string) => void;
    handleDelete: (id: string) => Promise<void>;
  };
}

export default function BlueprintListItem(props: BlueprintListItemProps) {
  return (
    <React.Suspense
      fallback={
        <div className="h-9 my-2 w-full rounded-md bg-gradient-to-r from-gray-200 to-gray-100 background-animate"></div>
      }
    >
      <BlueprintListItemComponent {...props} />
    </React.Suspense>
  );
}

function BlueprintListItemComponent(props: BlueprintListItemProps) {
  if (props.item.components && props.item.components.length > 0) {
    return (
      <>
        <ListItem {...props} />
        {props.item.components.map((comp) => {
          return (
            <ListItemComponent
              key={comp.id}
              selectedItems={props.selectedItems}
              component={comp}
              syncSuccess={props.syncSuccess}
              blueprint={props.item}
            />
          );
        })}
      </>
    );
  }
  return <ListItem {...props} />;
}

function ListItem(props: BlueprintListItemProps) {
  const { t } = useTranslation();
  const pg = useAuditProductGroup(props.item.product_group_id);

  let instanceAmount = 0;
  if (props.item.instance_set) {
    for (const instance of props.item.instance_set) {
      instanceAmount += instance.instance_amount;
    }
  }

  const [itemImageUrl, setItemImageUrl] = useState("");
  const [errorCount, setErrorCount] = useState(0);

  const getMissingAsync = async () => {
    const missingFields = await countMissingRequiredFields(props.item);
    if (missingFields) {
      setErrorCount(missingFields.length);
    }
  };

  useEffect(() => {
    const albums = [...props.item.albums];
    if (albums && albums.length > 0) {
      const highestOrderImage = [...albums[0].images].sort(
        (a, b) => b.order - a.order
      );
      const remoteImageUrl = highestOrderImage[0]?.image;
      if (remoteImageUrl) {
        setItemImageUrl(remoteImageUrl);
      } else {
        getFirstLocalImageForSource(props.item.id).then((image) => {
          if (image) {
            setItemImageUrl(URL.createObjectURL(image.file));
          }
        });
      }
    } else {
      getFirstLocalImageForSource(props.item.id).then((image) => {
        if (image) {
          setItemImageUrl(URL.createObjectURL(image.file));
        }
      });
    }

    getMissingAsync();
  }, [props.item.id]);

  return (
    <GenericListItem
      itemImageUrl={itemImageUrl}
      itemUrl={`blueprints/${props.item.id}/instances`}
      itemEditUrl={`blueprints/${props.item.id}`}
      item={props.item}
      syncSuccess={props.syncSuccess}
      missingFieldsCount={errorCount}
    >
      {pg && <ItemAmount value={instanceAmount} unit={pg.unit} />}
      <Link
        className="flex bg-indigo-100 p-2 rounded-md text-xs text-indigo-700 hover:bg-indigo-200"
        to={`blueprints/${props.item.id}/add`}
      >
        <PlusIcon width={18} />
      </Link>

      {props.selectedItems.length > 0 && (
        <input
          checked={props.selectedItems.includes(props.item.id)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.setSelectedItems((prev) =>
              e.target.checked
                ? Array.from(new Set([...prev, props.item.id]))
                : prev.filter((id) => id !== props.item.id)
            );
          }}
          className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white  text-indigo-500 checked:bg-indigo-600 checked:border-indigo-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          type="checkbox"
        />
      )}
      {/* TODO: Enable duplication for now, will create tickets to resolve it's issues */}
      <EditDeleteItemMenu
        withDuplicate
        withMultiSelect
        id={props.item.id}
        url={`blueprints/${props.item.id}`}
        onDuplicate={props.handlers.handleDuplicate}
        onMultiSelect={props.handlers.handleMultiSelect}
        onDelete={props.handlers.handleDelete}
        items={[
          <Link key={1} className="flex" to={`blueprints/${props.item.id}/add`}>
            <PlusCircleIcon width={15} className="mr-3" />
            {t("audits.add")}
          </Link>,
        ]}
      />
    </GenericListItem>
  );
}

function ListItemComponent(props: {
  component: BlueprintOUT;
  selectedItems: string[];
  syncSuccess: boolean;
  blueprint: BlueprintOUT;
}) {
  const selectedComponent = props.component;
  const pg = useAuditProductGroup(props.blueprint.product_group_id);
  const componentAmount = selectedComponent.as_component_amount;
  const instanceAmount =
    props.blueprint.instance_set
      .map((ins) => ins.instance_amount)
      .reduce((a, c) => a + c, 0) * componentAmount;

  const [itemImageUrl, setItemImageUrl] = useState("");

  useEffect(() => {
    const highestOrderImage = [
      ...(selectedComponent.albums[0]?.images ?? []),
    ].sort((a, b) => b.order - a.order);
    const remoteImageUrl = highestOrderImage[0]?.image ?? "";
    if (remoteImageUrl) {
      setItemImageUrl(remoteImageUrl);
    } else {
      getFirstLocalImageForSource(selectedComponent.id).then((image) => {
        if (image) {
          setItemImageUrl(URL.createObjectURL(image.file));
        }
      });
    }
  }, []);

  return (
    <GenericListItem
      itemImageUrl={itemImageUrl}
      className="ml-4"
      item={selectedComponent}
      itemUrl={`blueprints/${selectedComponent.id}/instances`}
      syncSuccess={props.syncSuccess}
      isComponent={true}
    >
      {pg && (
        <div className="pr-4">
          <ItemAmount
            value={instanceAmount}
            unit={pg.unit}
            componentAmount={componentAmount}
          />
        </div>
      )}
    </GenericListItem>
  );
}
