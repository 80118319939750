import React from "react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { useSetRecoilState } from "recoil";
import { openUserSettingAtom } from "store/atoms";

export default function TermsAndConditions() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const setOpenSettings = useSetRecoilState(openUserSettingAtom);

  const onDone = () => {
    navigate(-1);
    location.state === "settings" && setOpenSettings(true);
  };

  return (
    <>
      <div className="sticky top-0 flex justify-between w-full bg-white px-4 py-3.5 items-center border-b border-gray-300">
        <div className="text-xl font-semibold">
          {t("userSettings.termsAndConditionsTitle")}
        </div>
        <Button width="fit-content" onClick={onDone}>
          {t("userSettings.done")}
        </Button>
      </div>
      <div className="text-gray-900 p-12 bg-gray-50 flex justify-center">
        <div className="max-w-[1280px]">
          <div className="mb-8">
            <div className="text-2xl font-semibold pb-2">
              Allgemeine Vertragsbedingungen Concular Software
            </div>
            <div className="text-gray-500 text-base font-normal">
              Stand: Januar 2024
            </div>
          </div>
          <div className="mb-8">
            <div className="font-semibold text-lg pb-2">
              1. Geltungsbereich dieser AGB
            </div>
            <p className="text-gray-600">
              Diese Allgemeinen Geschäftsbedingungen (AGB) gelten für die
              Vertragsbeziehung zwischen Concular und dem Auftraggeber über die
              Nutzung der Concular-Software und eventuell dazugehörigen
              Beratungsleistungen. Concular widerspricht der Geltung Allgemeiner
              Geschäftsbedingungen der Auftraggeber:in - auch für die Zukunft.
            </p>
          </div>
          <div className="mb-8">
            <div className="font-semibold text-lg pb-2">
              2. Laufzeit und Kündigung
            </div>
            <p className="text-gray-600">
              Bei der Concular-Software handelt es sich um einen
              Software-Vertrag auf Lizenzbasis mit einer Laufzeit von einem
              Jahr. Sofern keine schriftliche Kündigung innerhalb der im Vertrag
              festgelegten Kündigungsfrist vorliegt, verlängert sich der Vertrag
              automatisch.
            </p>
          </div>
          <div className="mb-8">
            <div className="font-semibold text-lg pb-2">
              3. Datenschutz und -sicherheit
            </div>
            <p className="text-gray-600">
              Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner, z.B.
              Kunden und Interessenten (zusammenfassend bezeichnet als
              “Vertragspartner”) im Rahmen von vertraglichen und vergleichbaren
              Rechtsverhältnissen sowie damit verbundenen Maßnahmen und im
              Rahmen der Kommunikation mit den Vertragspartnern (oder
              vorvertraglich), z.B., um Anfragen zu beantworten.
            </p>
            <p className="text-gray-600">
              Wir verarbeiten diese Daten, um unsere vertraglichen
              Verpflichtungen zu erfüllen. Dazu gehören insbesondere die
              Verpflichtungen zur Erbringung der vereinbarten Leistungen,
              etwaige Aktualisierungspflichten und Abhilfe bei Gewährleistungs-
              und sonstigen Leistungsstörungen. Darüber hinaus verarbeiten wir
              die Daten zur Wahrung unserer Rechte und zum Zwecke der mit diesen
              Pflichten verbundenen Verwaltungsaufgaben sowie der
              Unternehmensorganisation. Darüber hinaus verarbeiten wir die Daten
              auf Grundlage unserer berechtigten Interessen an einer
              ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung
              sowie an Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner
              und unseres Geschäftsbetriebes vor Missbrauch, Gefährdung ihrer
              Daten, Geheimnisse, Informationen und Rechte (z.B. zur Beteiligung
              von Telekommunikations-, Transport- und sonstigen Hilfsdiensten
              sowie Subunternehmern, Banken, Steuer- und Rechtsberatern,
              Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des
              geltenden Rechts geben wir die Daten von Vertragspartnern nur
              insoweit an Dritte weiter, als dies für die vorgenannten Zwecke
              oder zur Erfüllung gesetzlicher Pflichten erforderlich ist. Über
              weitere Formen der Verarbeitung, z.B. zu Marketingzwecken, werden
              die Vertragspartner im Rahmen dieser Datenschutzerklärung
              informiert.
            </p>
            <p className="text-gray-600">
              Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen
              wir den Vertragspartnern vor oder im Rahmen der Datenerhebung,
              z.B. in Onlineformularen, durch besondere Kennzeichnung (z.B.
              Farben) bzw. Symbole (z.B. Sternchen o.ä.), oder persönlich mit.
            </p>
            <p className="text-gray-600">
              Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs-
              und vergleichbarer Pflichten, d.h., grundsätzlich nach Ablauf von
              4 Jahren, es sei denn, dass die Daten in einem Kundenkonto
              gespeichert werden, z.B., solange sie aus vertraglichen oder
              gesetzlichen Gründen der Archivierung aufbewahrt werden müssen.
              Die gesetzliche Aufbewahrungsfrist beträgt bei steuerrechtlich
              relevanten Unterlagen sowie bei Handelsbüchern, Inventaren,
              Eröffnungsbilanzen, Jahresabschlüssen, die zum Verständnis dieser
              Unterlagen erforderlichen Arbeitsanweisungen und sonstigen
              Organisationsunterlagen und Buchungsbelegen zehn Jahre sowie bei
              empfangenen Handels- und Geschäftsbriefen und Wiedergaben der
              abgesandten Handels- und Geschäftsbriefe sechs Jahre. Die Frist
              beginnt mit Ablauf des Kalenderjahres, in dem die letzte
              Eintragung in das Buch gemacht, das Inventar, die
              Eröffnungsbilanz, der Jahresabschluss oder der Lagebericht
              aufgestellt, der Handels- oder Geschäftsbrief empfangen oder
              abgesandt worden oder der Buchungsbeleg entstanden ist, ferner die
              Aufzeichnung vorgenommen worden ist oder die sonstigen Unterlagen
              entstanden sind.
            </p>
            <p className="text-gray-600">
              Soweit wir zur Erbringung unserer Leistungen Drittanbieter oder
              Plattformen einsetzen, gelten im Verhältnis zwischen den Nutzern
              und den Anbietern die Geschäftsbedingungen und Datenschutzhinweise
              der jeweiligen Drittanbieter oder Plattformen.
            </p>
            <p className="text-gray-600 pt-2">
              <ol className="mb-2 pl-3">
                <li className="list-outside list-item list-disc ml-2">
                  <b>Verarbeitete Datenarten: </b>
                  Bestandsdaten (z.B. Namen, Adressen); Zahlungsdaten (z.B.
                  Bankverbindungen, Rechnungen, Zahlungshistorie); Kontaktdaten
                  (z.B. E-Mail, Telefonnummern); Vertragsdaten (z.B.
                  Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten
                  (z.B. besuchte Webseiten, Interesse an Inhalten,
                  Zugriffszeiten); Meta-/Kommunikationsdaten (z.B.
                  Geräte-Informationen, IP-Adressen).
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Betroffene Personen: </b>
                  Kunden; Interessenten; Geschäfts- und Vertragspartner.
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Zwecke der Verarbeitung: </b>
                  Erbringung vertraglicher Leistungen und Kundenservice;
                  Kontaktanfragen und Kommunikation; Sicherheitsmaßnahmen;
                  Direktmarketing; Reichweitenmessung; Tracking; Büro- und
                  Organisationsverfahren; Konversionsmessung (Messung der
                  Effektivität von Marketingmaßnahmen); Verwaltung und
                  Beantwortung von Anfragen; Feedback; Marketing; Profile mit
                  nutzerbezogenen Informationen (Erstellen von Nutzerprofilen);
                  Bereitstellung unseres Onlineangebotes und
                  Nutzerfreundlichkeit sowie Informationstechnische
                  Infrastruktur.
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Rechtsgrundlagen: </b>
                  Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                  S. 1 lit. b) DSGVO); Rechtliche Verpflichtung (Art. 6 Abs. 1
                  S. 1 lit. c) DSGVO); Berechtigte Interessen (Art. 6 Abs. 1 S.
                  1 lit. f) DSGVO).
                </li>
              </ol>
              <b>
                Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und
                Diensten:
              </b>
              <ol className="mb-2 pl-3">
                <li className="list-outside list-item list-disc ml-2">
                  <b>Kundenkonto: </b>
                  Vertragspartner können innerhalb unseres Onlineangebotes ein
                  Konto anlegen (z.B. Kunden- bzw. Nutzerkonto, kurz
                  “Kundenkonto”). Falls die Registrierung eines Kundenkontos
                  erforderlich ist, werden Vertragspartner hierauf ebenso
                  hingewiesen wie auf die für die Registrierung erforderlichen
                  Angaben. Die Kundenkonten sind nicht öffentlich und können von
                  Suchmaschinen nicht indexiert werden. Im Rahmen der
                  Registrierung sowie anschließender Anmeldungen und Nutzungen
                  des Kundenkontos speichern wir die IP-Adressen der Kunden
                  nebst den Zugriffszeitpunkten, um die Registrierung nachweisen
                  und etwaigem Missbrauch des Kundenkontos vorbeugen zu können.
                  Wenn Kunden ihr Kundenkonto gekündigt haben, werden die das
                  Kundenkonto betreffenden Daten gelöscht, vorbehaltlich, deren
                  Aufbewahrung ist aus gesetzlichen Gründen erforderlich. Es
                  obliegt den Kunden, ihre Daten bei erfolgter Kündigung des
                  Kundenkontos zu sichern; <b>Rechtsgrundlagen: </b>{" "}
                  Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                  S. 1 lit. b) DSGVO).
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Wirtschaftliche Analysen und Marktforschung: </b>
                  Aus betriebswirtschaftlichen Gründen und um Markttendenzen,
                  Wünsche der Vertragspartner und Nutzer erkennen zu können,
                  analysieren wir die uns vorliegenden Daten zu
                  Geschäftsvorgängen, Verträgen, Anfragen, etc., wobei in die
                  Gruppe der betroffenen Personen Vertragspartner,
                  Interessenten, Kunden, Besucher und Nutzer unseres
                  Onlineangebotes fallen können. Die Analysen erfolgen zum Zweck
                  betriebswirtschaftlicher Auswertungen, des Marketings und der
                  Marktforschung (z.B. zur Bestimmung von Kundengruppen mit
                  unterschiedlichen Eigenschaften). Dabei können wir, sofern
                  vorhanden, die Profile von registrierten Nutzern samt ihrer
                  Angaben, z.B. zu in Anspruch genommenen Leistungen,
                  berücksichtigen. Die Analysen dienen alleine uns und werden
                  nicht extern offenbart, sofern es sich nicht um anonyme
                  Analysen mit zusammengefassten, also anonymisierten Werten
                  handelt. Ferner nehmen wir Rücksicht auf die Privatsphäre der
                  Nutzer und verarbeiten die Daten zu den Analysezwecken
                  möglichst pseudonym und, sofern machbar, anonym (z.B. als
                  zusammengefasste Daten); Rechtsgrundlagen: Berechtigte
                  Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Projekt- und Entwicklungsleistungen: </b>
                  Wir verarbeiten die Daten unserer Kunden sowie Auftraggeber
                  (nachfolgend einheitlich als “Kunden” bezeichnet), um ihnen
                  die Auswahl, den Erwerb bzw. die Beauftragung der gewählten
                  Leistungen oder Werke sowie verbundener Tätigkeiten als auch
                  deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder
                  Erbringung zu ermöglichen. Die erforderlichen Angaben sind als
                  solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
                  Vertragsschlusses gekennzeichnet und umfassen die zur
                  Leistungserbringung und Abrechnung benötigten Angaben sowie
                  Kontaktinformationen, um etwaige Rücksprachen halten zu
                  können. Soweit wir Zugang zu Informationen der Endkunden,
                  Mitarbeitern oder anderer Personen erhalten, verarbeiten wir
                  diese im Einklang mit den gesetzlichen und vertraglichen
                  Vorgaben; <b>Rechtsgrundlagen: </b>
                  Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1
                  S. 1 lit. b) DSGVO).
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Angebot von Software- und Plattformleistungen: </b>
                  Wir verarbeiten die Daten unserer Nutzer, angemeldeter und
                  etwaiger Testnutzer (nachfolgend einheitlich als “Nutzer”
                  bezeichnet), um ihnen gegenüber unsere vertraglichen
                  Leistungen erbringen zu können sowie auf Grundlage
                  berechtigter Interessen, um die Sicherheit unseres Angebotes
                  gewährleisten und es weiterentwickeln zu können. Die
                  erforderlichen Angaben sind als solche im Rahmen des
                  Auftrags-, Bestell- bzw. vergleichbaren Vertragsschlusses
                  gekennzeichnet und umfassen die zur Leistungserbringung und
                  Abrechnung benötigten Angaben sowie Kontaktinformationen, um
                  etwaige Rücksprachen halten zu können;{" "}
                  <b>Rechtsgrundlagen: </b> Vertragserfüllung und
                  vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Technische Dienstleistungen: </b>
                  Wir verarbeiten die Daten unserer Kunden sowie Auftraggeber
                  (nachfolgend einheitlich als “Kunden” bezeichnet), um ihnen
                  die Auswahl, den Erwerb bzw. die Beauftragung der gewählten
                  Leistungen oder Werke sowie verbundener Tätigkeiten als auch
                  deren Bezahlung und Zurverfügungstellung bzw. Ausführung oder
                  Erbringung zu ermöglichen. Die erforderlichen Angaben sind als
                  solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
                  Vertragsschlusses gekennzeichnet und umfassen die zur
                  Leistungserbringung und Abrechnung benötigten Angaben sowie
                  Kontaktinformationen, um etwaige Rücksprachen halten zu
                  können. Soweit wir Zugang zu Informationen der Endkunden,
                  Mitarbeitern oder anderer Personen erhalten, verarbeiten wir
                  diese im Einklang mit den gesetzlichen und vertraglichen
                  Vorgaben; <b>Rechtsgrundlagen: </b> Vertragserfüllung und
                  vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
                </li>
                <li className="list-outside list-item list-disc ml-2">
                  <b>Datensicherheit: </b>
                  Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
                  Berücksichtigung des Stands der Technik, der
                  Implementierungskosten und der Art, des Umfangs, der Umstände
                  und der Zwecke der Verarbeitung sowie der unterschiedlichen
                  Eintrittswahrscheinlichkeiten und des Ausmaßes der Bedrohung
                  der Rechte und Freiheiten natürlicher Personen geeignete
                  technische und organisatorische Maßnahmen, um ein dem Risiko
                  angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen
                  gehören insbesondere die Sicherung der Vertraulichkeit,
                  Integrität und Verfügbarkeit von Daten durch Kontrolle des
                  physischen und elektronischen Zugangs zu den Daten als auch
                  des sie betreffenden Zugriffs, der Eingabe, der Weitergabe,
                  der Sicherung der Verfügbarkeit und ihrer Trennung. Des
                  Weiteren haben wir Verfahren eingerichtet, die eine
                  Wahrnehmung von Betroffenenrechten, die Löschung von Daten und
                  Reaktionen auf die Gefährdung der Daten gewährleisten. Ferner
                  berücksichtigen wir den Schutz personenbezogener Daten bereits
                  bei der Entwicklung bzw. Auswahl von Hardware, Software sowie
                  Verfahren entsprechend dem Prinzip des Datenschutzes, durch
                  Technikgestaltung und durch datenschutzfreundliche
                  Voreinstellungen.
                </li>
              </ol>
            </p>
          </div>
          <div className="mb-8">
            <div className="font-semibold text-lg pb-2">4. Haftung</div>
            <p className="text-gray-600">
              Concular haftet auf Schadensersatz nur bei Vorsatz und grober
              Fahrlässigkeit. Bei einfacher Fahrlässigkeit haftet Concular
              jedoch
            </p>
            <p className="text-gray-600">
              a) unbeschränkt für n aus der Verletzung des Lebens, des Körpers
              oder der Gesundheit, b) für Sach- oder Vermögensschäden aus der
              Verletzung einer wesentlichen Vertragspflicht (Verpflichtung,
              deren Erfüllung die ordnungsgemäße Durchführung des Vertrags
              überhaupt erst ermöglicht und auf deren Einhaltung die AG
              regelmäßig vertraut und vertrauen darf) der Höhe nach beschränkt
              auf die bei Vertragsschluss vorhersehbaren und vertragstypischen
              Schäden.
            </p>
            <p className="text-gray-600">
              Diese Haftungsbeschränkungen und -ausschlüsse gelten nicht, soweit
              Concular einen Mangel arglistig verschwiegen oder eine Garantie
              für die Beschaffenheit einer Sache oder sonstigen Leistung
              übernommen hat oder nach dem Produkthaftungsgesetz haftet. Eine
              Änderung der Beweislast zum Nachteil des Käufers ist mit den
              vorstehenden Regelungen nicht verbunden.
            </p>
          </div>
          <div className="mb-8">
            <div className="font-semibold text-lg pb-2">
              5. Schlussbestimmungen
            </div>
            <ul className="pl-3 text-gray-500">
              <li className="list-outside list-item list-decimal ml-2">
                Die Abtretung oder rechtsgeschäftliche Verpfändung von
                Ansprüchen der AG gegen Concular ist nur mit in Textform
                erteilter Zustimmung von Concular zulässig.
              </li>
              <li className="list-outside list-item list-decimal ml-2">
                Ist die AG Kunde Kaufmann, eine juristische Person des
                öffentlichen Rechts oder ein öffentlich-rechtliches
                Sondervermögen, ist Gerichtsstand für alle Streitigkeiten aus
                und im Zusammenhang mit dem Vertrag Berlin, soweit nicht
                gesetzlich ein ausschließlicher Gerichtsstand begründet ist.
                Diese Gerichtsstandsvereinbarung gilt nicht für das
                Mahnverfahren.
              </li>
              <li className="list-outside list-item list-decimal ml-2">
                Mündliche Vereinbarungen sind nur wirksam, wenn sie von Concular
                in Textform bestätigt wurden.
              </li>
              <li className="list-outside list-item list-decimal ml-2">
                Gerichtsstand für Streitigkeiten ist Stuttgart Bad Cannstatt
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
