import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import Heading from "components/Heading";
import Modal from "components/Modal";
import Button from "components/Button";
import { LockClosedIcon, UserIcon } from "@heroicons/react/solid";
import MyAccount from "./MyAccount";
import ChangePassword from "./ChangePassword";
import { ExternalLinkIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router";

const UserSettings = ({
  open,
  close,
}: {
  open: boolean;
  close: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  function tabPanels() {
    return (
      <Tab.Panels className={"w-full"}>
        <Tab.Panel>
          <MyAccount />
        </Tab.Panel>
        <Tab.Panel>
          <ChangePassword />
        </Tab.Panel>
      </Tab.Panels>
    );
  }

  function tabList() {
    return (
      <Tab.List
        className={clsx(
          "flex flex-col justify-items-start border-r border-gray-300 min-w-[300px] cursor-pointer"
        )}
      >
        <Heading size="title" className="p-5 border-b border-gray-300 !mb-0">
          {t("userSettings.title")}
        </Heading>
        <div className="flex flex-col justify-between h-full">
          <div>
            <Tab as={Fragment}>
              {({ selected }) => (
                <div
                  className={`flex px-5 py-4 border-b border-gray-300 focus:outline-none items-center ${
                    selected ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  <UserIcon width={20} />
                  <span className="pl-2">{t("userSettings.myAccount")}</span>
                </div>
              )}
            </Tab>
            <Tab as={Fragment}>
              {({ selected }) => (
                <div
                  className={`flex px-5 py-4 border-b border-gray-300 focus:outline-none items-center  ${
                    selected ? "bg-gray-100" : "bg-white"
                  }`}
                >
                  <LockClosedIcon width={20} />
                  <span className="pl-2">{t("userSettings.password")}</span>
                </div>
              )}
            </Tab>
          </div>
          {privacyPolicyTermsOfUse()}
        </div>
      </Tab.List>
    );
  }

  function privacyPolicyTermsOfUse() {
    return (
      <div className="p-6 text-gray-700 text-sm">
        <div
          className="flex py-1"
          onClick={() => navigate("/terms-conditions", { state: "settings" })}
        >
          <ExternalLinkIcon width={20} className="mr-2" />
          {t("userSettings.termsAndConditions")}
        </div>
      </div>
    );
  }

  return (
    <Modal
      isOpen={open}
      setIsOpen={close}
      containerClassName="md:max-w-6xl !p-0"
      footer={
        <Button type="link" width="fit-content" onClick={close}>
          {t<string>("userSettings.close")}
        </Button>
      }
    >
      <Tab.Group>
        <div className="flex">
          {tabList()}
          {tabPanels()}
        </div>
      </Tab.Group>
    </Modal>
  );
};

export default UserSettings;
