import { ConcularProperty, IFCProperties } from "api-client";
import React from "react";
import ListSectionHeader from "./ListSectionHeader";
import { useTranslation } from "react-i18next";
import Alert from "components/Alert";
import { CIRCULARITY_GUIDE_URL } from "utils";
import AllPropertiesListItem from "./AllPropertiesListItem";
import clsx from "clsx";

interface Props {
  ifcPropertiesData: IFCProperties;
}

const IFCPropertiesListItem = ({ ifcPropertiesData }: Props) => {
  const { t } = useTranslation();

  const itemSingularElement = (item: ConcularProperty) => {
    return (
      <li
        key={item.field_name}
        className={`border-b text-sm font-medium border-gray-300 text-gray-600 p-2 flex justify-between items-center`}
      >
        {item.field_name}
        <div className="text-sm font-normal text-right max-w-[200px]">
          {item.value ?? ""}
        </div>
      </li>
    );
  };

  return (
    <>
      <ListSectionHeader
        title={t("detailView.ifcProperties.concularProperties")}
        tooltip={t("detailView.ifcProperties.concularPropertiesTooltip")}
      />
      <ul
        className={clsx("rounded-md p-0 ", {
          "border border-gray-300":
            ifcPropertiesData.concular_properties?.length,
        })}
      >
        {ifcPropertiesData.concular_properties?.length ? (
          ifcPropertiesData.concular_properties.map((item) =>
            itemSingularElement(item)
          )
        ) : (
          <Alert
            type="info"
            description={t(
              "detailView.ifcProperties.noConcularPropertiesAlert"
            )}
            actionButton={t("detailView.ifcProperties.noConcularAlertButton")}
            actionButtonOnClick={() =>
              window.open(
                CIRCULARITY_GUIDE_URL,
                "_blank",
                "noopener,noreferrer"
              )
            }
          />
        )}
      </ul>
      {ifcPropertiesData.all_properties && (
        <AllPropertiesListItem
          allProperties={ifcPropertiesData.all_properties}
        />
      )}
    </>
  );
};

export default IFCPropertiesListItem;
