import { BlueprintOUT, ImageOUT } from "api-client";
import { LocalImage } from "api-client-local/db_interfaces";
import React from "react";
import { RemoteImages } from "./RemoteImages";
import { LocalImages } from "./LocalImages";
import { useParams } from "react-router";
import { db } from "api-client-local/db";
import { getLocalImagesForSource } from "api-client-local/images";

interface Props {
  blueprint: BlueprintOUT;
}

export const BlueprintImages = ({ blueprint }: Props) => {
  const [localImages, setLocalImages] = React.useState<LocalImage[]>([]);
  const [remoteImages, setRemoteImages] = React.useState<ImageOUT[]>([]); // TODO: Implement multiple albums.

  const { blueprint_id } = useParams();

  const getLocalDeletedImageIds = async () => {
    return (await db.deletedImages.toArray()).map((img) => img.id) ?? [];
  };

  const setupFilteredImages = async (albumImages: ImageOUT[]) => {
    const deletedImageIds = await getLocalDeletedImageIds();
    const filteredImages = albumImages.filter(
      (img) => !deletedImageIds.includes(img.id)
    );
    setRemoteImages(filteredImages);
  };

  React.useEffect(() => {
    if (!blueprint) return;
    if (!blueprint_id) return;

    if (blueprint.albums.length > 0) {
      const albumImages: ImageOUT[] = [];
      blueprint.albums.forEach(async (album) => {
        if (album.images.length > 0) {
          albumImages.push(...album.images);
        }
      });

      setupFilteredImages(albumImages);
    }

    getLocalImagesForSource(blueprint_id).then((localImages) => {
      setLocalImages(localImages);
    });
  }, [blueprint.id]);

  return (
    <>
      <div
        className={`flex justify-between py-2 ${
          localImages.length > 0 ? "" : "lg:border-b-2"
        }`}
      ></div>
      <RemoteImages
        remoteImages={remoteImages}
        setRemoteImages={setRemoteImages}
        blueprint={blueprint}
      />
      <LocalImages
        blueprint={blueprint}
        localImages={localImages}
        setLocalImages={setLocalImages}
      />
    </>
  );
};
