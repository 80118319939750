import { FieldOUT } from "api-client";

export default function retrieveFields(
  form: { [key: string]: string | number | string[] },
  required_fields: FieldOUT[],
  optional_fields: FieldOUT[]
) {
  const formKeys = Object.keys(form);
  const foundFields = [];
  const notFoundFields = [];
  for (let i = 0; i < formKeys.length; i++) {
    const found = [...required_fields, ...optional_fields].find(
      (field) => field.id === formKeys[i]
    );
    if (found) {
      foundFields.push({ field: found, value: form[formKeys[i]] });
    } else {
      notFoundFields.push({
        name: formKeys[i],
        value: form[formKeys[i]],
      });
    }
  }
  return { foundFields, notFoundFields };
}
