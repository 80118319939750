import { AuditOUT, RoomOUT } from "api-client";
import { getDBVariable, DB_VARS } from "api-client-local";
import React from "react";

export default function useAuditRooms(
  audit: AuditOUT | null,
  audits: AuditOUT[] | null
) {
  const [roomSet, setRoomSet] = React.useState(audit?.room_set);

  React.useEffect(() => {
    if (!audit || !audits) return;
    const allRooms = audits.map((aud) => aud.room_set).flat(2);
    const hasRooms = allRooms.length > 0;
    const localRooms = getDBVariable(DB_VARS.ROOM_NAMES);
    if (!localRooms || localRooms.length === 0) return;
    const roomNames = hasRooms
      ? Array.from(new Set([...allRooms.map((r) => r.name), ...localRooms]))
      : Array.from(new Set([...localRooms]));
    const result = roomNames.map((rName) => {
      const found = allRooms.find((r) => r.name === rName);
      if (found) {
        return found;
      } else {
        return { id: "temp", name: rName, audit_id: audit.id };
      }
    });
    setRoomSet(result as RoomOUT[]);
    return () => {
      setRoomSet([]);
    };
  }, [audit, audits]);

  return roomSet;
}
