import React, { useEffect, useState } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchSortTable from "components/Table";
import useObjectsTableColumns from "./useObjectsTableColumns";
import { useParams } from "react-router";
import ProductSearch from "../ProductSearch";
import useObjectsTable from "./useObjectsTable";
import { observer } from "mobx-react-lite";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import { MaterialFileObject, ObjectsResponse } from "store/IfcMapping/types";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { searchStore } from "store/IfcMapping/SearchStore";
import { SEARCH_TABS_LIST } from "../consts";

interface RenderRelatedObjects {
  parentRow: Row<unknown>;
  setOpenDetails: React.Dispatch<React.SetStateAction<boolean>>;
}

const fetchSize = 10;

const ChildTable = observer(
  ({ parentRow, setOpenDetails }: RenderRelatedObjects) => {
    const { ifc_id } = useParams();

    const [childData, setChildData] = React.useState<ObjectsResponse>();
    const [loading, setLoading] = React.useState(false);
    const { unmatch, updateCategoryMappingStatus } = useObjectsTable();
    const [openProductSearch, setOpenProductSearch] = useState(false);
    const fetchChildData = async (start: number, size: number) => {
      await getChildData(start, size, data.original);
    };

    useEffect(() => {
      (async () => getChildData(0, fetchSize, data.original, true))();
      mappingEditorStore.setCurrentRow({
        parent: parentRow,
        row: mappingEditorStore.currentRow.row,
      });
      return () => setChildData(undefined);
    }, []);

    useEffect(() => {
      reloadData();
    }, [
      mappingEditorStore.resetChildTable[
        (parentRow.original as MaterialFileObject).id ?? ""
      ],
    ]);

    function reloadData() {
      childData?.items && getChildData(0, fetchSize, data.original);
    }

    const updateMappingStatus = async (response: MaterialFileObject) => {
      const object: MaterialFileObject = mappingEditorStore.currentRow.row
        ?.original as MaterialFileObject;
      object.mass = response.mass;
      object.gwp = response.gwp;
      object.area = response.area;
      object.volume = response.volume;
      object.product_match = response.product_match;
      await updateCategoryMappingStatus(object);
    };

    const unmatchAndUpdateMappingStatus = async (row: Row<unknown>) => {
      await unmatch(row);
      await updateCategoryMappingStatus(row.original as MaterialFileObject);
    };

    const useGetChildColumns = () => {
      return useObjectsTableColumns(
        false,
        setOpenDetails,
        openSearch,
        unmatchAndUpdateMappingStatus
      );
    };

    const openSearch = () => {
      searchStore.setSelectedTab(SEARCH_TABS_LIST[0]);
      setOpenProductSearch(true);
    };

    const getChildData = async (
      start: number,
      size: number,
      currentObject: MaterialFileObject,
      reset?: boolean
    ) => {
      const groupBy = mappingEditorStore.groupBy;
      if (!ifc_id || !groupBy) return;
      setLoading(true);
      const oldItems = start === 0 || reset ? [] : childData?.items ?? [];
      if (oldItems.length && oldItems.length == childData?.count) return;
      const receivedData = await ifcMappingStore.getObjectsFromCategory(
        ifc_id,
        groupBy?.join(",") || "",
        "",
        "",
        size,
        start,
        groupBy.find((item) => item === "component") &&
          (currentObject?.component as string),
        groupBy.find((item) => item === "material") &&
          (currentObject?.material as string),
        groupBy.find((item) => item === "ifc_entity") &&
          (currentObject?.ifc_entity as string),
        mappingEditorStore.mappingStatusSelected
      );
      setLoading(false);
      receivedData &&
        setChildData(() => ({
          items: [...oldItems, ...receivedData.items],
          count: receivedData?.count,
        }));
    };

    if (!parentRow && !mappingEditorStore.showAllObjects) return <></>;
    const data = parentRow as Row<MaterialFileObject>;
    if (mappingEditorStore.showAllObjects && data.getIsExpanded()) {
      data.toggleExpanded();
    }

    return (
      <div className="transition-all duration-100 ease-in bg-gray-50 pb-2 pl-10">
        <SearchSortTable
          data={childData?.items ?? []}
          getColumns={useGetChildColumns as () => ColumnDef<unknown, unknown>[]}
          headerRowStyle={mappingEditorStore.borderStyle(1)}
          customRowStyle={mappingEditorStore.borderStyle(1)}
          customContainerStyle={"!border-l"}
          customColumnStyle="last:border-l !py-1"
          fetchData={fetchChildData}
          scrollDivClassName="max-h-[calc(100vh/3)] border-t"
          hideHeader
          hiddenColumns={mappingEditorStore.getHiddenColumns()}
          skeletonLoading={loading && !childData?.count}
          totalDBRowCount={childData?.count ?? 0}
          dataFetchLoading={loading}
          fetchSize={fetchSize}
        />
        {openProductSearch && (
          <ProductSearch
            open={openProductSearch}
            close={() => setOpenProductSearch(false)}
            refresh={updateMappingStatus}
          />
        )}
      </div>
    );
  }
);

export default ChildTable;
