import { CategoryFullOut } from "api-client";
import DropdownCheckbox, { DropdownOption } from "components/DropdownCheckbox";
import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { searchStore } from "store/IfcMapping/SearchStore";

export interface OKBCategoryType extends DropdownOption {
  parent_category?: { name: string; name_de?: string };
  filter: string;
}

export default observer(function OKBCategoryFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: OKBCategoryType[];
  setSelected: React.Dispatch<React.SetStateAction<OKBCategoryType[]>>;
  removingItem: OKBCategoryType | undefined;
  handleRemoveItem: () => void;
}) {
  const { t } = useTranslation();

  const onChange = (value: OKBCategoryType[]) => setSelected(value);

  React.useEffect(() => {
    (async () => {
      const results = await ifcMappingStore.getCategories("material_general");
      if (!results) return;
      const options: OKBCategoryType[] = results.map(
        (item: CategoryFullOut) => ({
          id: item.id,
          filter: item.id ?? "",
          name: `${item.name}`,
          parent_category: {
            name: ``,
          },
        })
      );
      searchStore.setFilterOptions({
        ...searchStore.filtersOptions,
        OKB_categories: options,
      });
    })();
  }, []);

  if (!searchStore.filtersOptions.OKB_categories) return <></>;
  return (
    <DropdownCheckbox<OKBCategoryType>
      displayKey={"name"}
      detailKey={"parent_category"}
      required={true}
      handleSelect={onChange}
      labelName={`${t("mapping.OKBCategories")}`}
      name="OKB_categories"
      items={searchStore.filtersOptions.OKB_categories}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px] !mb-3"
      optionsClassName="z-40"
      checkedItems={selected}
    />
  );
});
