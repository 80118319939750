import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import ButtonGroups, { BGItem } from "components/ButtonGroups";
import { REFERENCE_UNITS } from "features/MappingTools/consts";
import Button from "components/Button";
import { IconPlus } from "@tabler/icons-react";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import AddedChild from "./AddedChild";
import ProductSearch from "features/MappingTools/ProductSearch";

const Step2 = () => {
  const { t } = useTranslation();

  const onClickAdd = () => {
    componentsStore.setOpenedSearchInCE(true);
  };

  const onChangeReferenceUnit = (value: BGItem | undefined) => {
    componentsStore.onChangeData("reference_unit", value);
  };

  const currentRef =
    REFERENCE_UNITS.find(
      (item) => item.id === componentsStore.data.reference_unit.id
    ) || REFERENCE_UNITS[0];

  return (
    <>
      <div className="text-lg font-semibold mb-4 mt-2">
        {t("components.refrenceUnitAndConstructionPRsCs")}
      </div>
      <div className="text-gray-700 text-sm mb-6">
        {t("components.step2Subtitle")}
      </div>
      <div className="bg-white border-gray-300 border p-6 rounded-md mb-6">
        <ButtonGroups
          items={REFERENCE_UNITS.filter((item) => item.id !== "KG")}
          onChange={onChangeReferenceUnit}
          current={currentRef}
          containerClassName="w-fit"
          label={`${t("components.reference_unit")}*`}
        />
        <div className="text-gray-700 text-sm mt-2">
          {t("components.reference_unit_desc")}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center">
        {componentsStore.data.childrenList?.map((item, index) => (
          <AddedChild childItem={item} key={index} rowIndex={index} />
        ))}
        <div className="h-[98px] w-full border border-dashed border-indigo-600 rounded-md flex items-center justify-center">
          <Button
            type="secondary"
            width="fit-content"
            leadingIcon={<IconPlus />}
            onClick={onClickAdd}
          >
            {t("components.addProductComponent")}
          </Button>
        </div>
        <ProductSearch
          open={componentsStore.openedSearchInCE}
          close={() => componentsStore.setOpenedSearchInCE(false)}
        />
      </div>
    </>
  );
};

export default observer(Step2);
