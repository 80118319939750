import React from "react";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";
import ProductsListContainer from "../ProductsListContainer";
import RowsList, { RowsListProps } from "../SearchRowsList";
import { buildingStore } from "store/Building/BuildingStore";
import {
  IconCopy,
  IconEdit,
  IconInfoCircle,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Button from "components/Button";
import { useNavigate, useParams } from "react-router";
import EmptyState from "../EmptyState";
import { RemoveStateProps } from "./CustomProducts";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import useToast from "hooks/useToast";
import ConfirmModal from "components/ConfirmModal";
import { componentsStore } from "store/IfcMapping/ComponentsStore";
import { ProductSearchOutput } from "api-client";
import Tooltip from "components/Tooltip";
import ProductDetailsPopup from "features/MappingTools/ProductDetailsPopup";
import useComponentActions from "./useComponentActions";

const CustomComponents = (props: RowsListProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const { ifc_id } = useParams();
  const { duplicateComponent, editComponent } = useComponentActions();
  const [removeItemState, setRemoveItemState] = React.useState<
    RemoveStateProps | undefined
  >();

  React.useEffect(() => {
    buildingStore.getBuildingCalculationsStatus();
  }, []);

  const openComponentEditor = () =>
    navigate(`${location.pathname}/component-editor`);

  function shouldShowEmptyView() {
    return (
      !searchStore.productSearchLoading &&
      searchStore.searchResults.items.length === 0
    );
  }

  const removeComponent = async () => {
    if (!removeItemState?.id) return;
    const filteredItems = searchStore.searchResults.items.filter(
      (item) => item.id !== removeItemState?.id
    );
    await componentsStore
      .deleteComponent(removeItemState?.id)
      .then(async () => {
        searchStore.setSearchResults({
          ...searchStore.searchResults,
          items: filteredItems,
        });
        toast(t("components.successMsgOnDelete"), "success");
        await mappingEditorStore.fetchObjects(0, 25, true, ifc_id);
      })
      .catch((err) => {
        toast(err, "warning");
        console.error(err, "componentsDelete");
      });
  };

  const settingsItems = (product: ProductSearchOutput | null | undefined) => {
    return [
      {
        name: t("mapping.showDetails"),
        icon: <IconInfoCircle width={18} className="mr-2" />,
        onClick: () =>
          searchStore.setOpenDetails({ open: true, product: product }),
        className: "text-gray-700",
        disabled: false,
      },
      {
        name: (
          <div className="flex justify-between w-full">
            {t("mapping.editComponent")}
            {product?.is_part_of_a_component ? (
              <Tooltip content={t("components.componentNotEditable")} />
            ) : null}
          </div>
        ),
        icon: <IconEdit width={15} className="mr-2 min-w-[15px]" />,
        onClick: () => editComponent(product?.id),
        className: "text-gray-700",
        disabled:
          product?.is_part_of_a_component ||
          buildingStore.calculationsStatus.inProgress ||
          componentsStore.openedSearchInCE,
      },
      {
        name: t("mapping.duplicateComponent"),
        icon: <IconCopy width={15} className="mr-2" />,
        onClick: () => duplicateComponent(product?.id),
        className: "text-gray-700",
        disabled: componentsStore.openedSearchInCE,
      },
      {
        name: t("mapping.removeComponent"),
        icon: <IconTrash width={15} className="mr-2" />,
        onClick: () => {
          if (product?.is_part_of_a_component) {
            setRemoveItemState({
              open: true,
              id: null,
              name: null,
            });
          } else {
            setRemoveItemState({
              open: true,
              id: product?.id,
              name: product?.name,
            });
          }
        },
        className: "text-red-700",
        disabled:
          buildingStore.calculationsStatus.inProgress ||
          componentsStore.openedSearchInCE,
      },
    ];
  };

  function headerBar() {
    return (
      <div className="h-[66px]">
        {searchStore.searchResults.items.length ? (
          <div
            className={clsx(
              "flex justify-between p-3 items-center text-base rounded-md shadow-md relative",
              "bg-gradient-to-r from-cyan-50 border border-gray-100"
            )}
          >
            <span className="font-normal text-gray-600 text-sm">
              {t("mapping.createComponentMsg")}
            </span>
            <Button
              width="fit-content"
              type="primary"
              leadingIcon={<IconPlus />}
              onClick={openComponentEditor}
            >
              {t("mapping.createComponent")}
            </Button>
          </div>
        ) : null}
      </div>
    );
  }

  return (
    <>
      {componentsStore.openedSearchInCE ? null : headerBar()}
      <ProductsListContainer
        className={
          componentsStore.openedSearchInCE
            ? "!mt-0"
            : "!mt-2 !h-[calc(100vh-430px)]"
        }
      >
        {shouldShowEmptyView() ? (
          <EmptyState />
        ) : (
          <RowsList
            match={props.match}
            settingsItems={settingsItems}
            settingsDisabled={[
              false,
              buildingStore.calculationsStatus.inProgress ||
                componentsStore.openedSearchInCE,
              componentsStore.openedSearchInCE,
              buildingStore.calculationsStatus.inProgress ||
                componentsStore.openedSearchInCE,
            ]}
            containerClassName={
              componentsStore.openedSearchInCE ? "mt-0" : "mt-5"
            }
          />
        )}
      </ProductsListContainer>
      {removeItemState?.open && removeItemState?.id ? (
        <ConfirmModal
          type="warning"
          title={t("mapping.removeComponentTitle", {
            name: removeItemState.name,
          })}
          open={removeItemState?.open}
          close={() => setRemoveItemState(undefined)}
          onConfirm={removeComponent}
          confirmButtonTitle={t("mapping.removeProductBtn", {
            name: t("mapping.component"),
          })}
          descriptionMesage={t("mapping.removeComponentDesc")}
          containerClassName="!min-w-[600px]"
          buttonClassName="bg-red-600 hover:!bg-red-700"
        ></ConfirmModal>
      ) : null}
      {removeItemState?.open && !removeItemState.id ? (
        <ConfirmModal
          type="warning"
          title={t("mapping.removeProductForbidenTitle", {
            name: t("mapping.component"),
          })}
          open={removeItemState?.open}
          close={() => setRemoveItemState(undefined)}
          hideCancel
          onConfirm={() => setRemoveItemState(undefined)}
          confirmButtonTitle={t("mapping.closeNote")}
          descriptionMesage={t("components.cannotDeleteComponentMsg")}
          containerClassName="!min-w-[500px]"
        ></ConfirmModal>
      ) : null}
      <ProductDetailsPopup
        product={searchStore.openDetails.product}
        open={searchStore.openDetails.open}
        close={() =>
          searchStore.setOpenDetails({ open: false, product: undefined })
        }
      ></ProductDetailsPopup>
    </>
  );
};

export default observer(CustomComponents);
