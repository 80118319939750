import {
  ContextMenu,
  Entity,
  PickResult,
  Scene,
  TreeViewPlugin,
  Viewer,
} from "@xeokit/xeokit-sdk";
import { TreeViewNode } from "@xeokit/xeokit-sdk/types/plugins/TreeViewPlugin/TreeViewNode";
import { useTranslation } from "react-i18next";
import { bimViewerStore } from "store/BimViewerStore";

interface TreeViewPluginProps {
  withNodeTree: (
    arg0: string[],
    arg1: (treeViewNode: { objectId: string | number }) => void
  ) => void;
}

export default function useBimViewerContextMenu() {
  const { t } = useTranslation();

  const showAll = {
    title: t("bimViewer.showAll"),
    getEnabled: function (context: { viewer: { scene: Scene } }) {
      const scene = context.viewer.scene;
      return scene.numVisibleObjects < scene.numObjects;
    },
    doAction: function (context: { viewer: { scene: Scene } }) {
      const scene = context.viewer.scene;
      scene.setObjectsVisible(scene.objectIds, true);
      scene.setObjectsXRayed(scene.xrayedObjectIds, false);
      scene.setObjectsSelected(scene.selectedObjectIds, false);
    },
  };

  const showOthers = {
    title: t("bimViewer.showOthers"),
    doAction: function (context: {
      viewer: { scene: Scene };
      treeViewPlugin: TreeViewPluginProps;
      treeViewNode: string[];
    }) {
      const scene = context.viewer.scene;
      scene.setObjectsVisible(scene.objectIds, true);
      scene.setObjectsXRayed(scene.xrayedObjectIds, false);
      scene.setObjectsSelected(scene.selectedObjectIds, false);
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.visible = false;
            }
          }
        }
      );
    },
  };

  const show = {
    title: t("bimViewer.show"),
    doAction: function (context: {
      treeViewPlugin: TreeViewPluginProps;
      treeViewNode: string[];
      viewer: { scene: { objects: { [x: string]: Entity } } };
    }) {
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = context.viewer.scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.visible = true;
              entity.xrayed = false;
              entity.selected = false;
            }
          }
        }
      );
    },
  };

  const hideAll = {
    title: t("bimViewer.hideAll"),
    getEnabled: function (context: {
      viewer: { scene: { visibleObjectIds: string[] } };
    }) {
      return context.viewer.scene.visibleObjectIds.length > 0;
    },
    doAction: function (context: {
      viewer: {
        scene: {
          setObjectsVisible: (arg0: string[], arg1: boolean) => void;
          visibleObjectIds: string[];
        };
      };
    }) {
      context.viewer.scene.setObjectsVisible(
        context.viewer.scene.visibleObjectIds,
        false
      );
    },
  };

  const hideOthersTree = {
    title: t("bimViewer.hideOthers"),
    doAction: function (context: {
      viewer: { scene: Scene };
      treeViewPlugin: TreeViewPluginProps;
      treeViewNode: string[];
    }) {
      const scene = context.viewer.scene;
      scene.setObjectsVisible(scene.visibleObjectIds, false);
      scene.setObjectsXRayed(scene.xrayedObjectIds, false);
      scene.setObjectsSelected(scene.selectedObjectIds, false);
      scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.visible = true;
            }
          }
        }
      );
    },
  };

  const hideOthers = {
    title: t("bimViewer.hideOthers"),
    doAction: function (context: { viewer: Viewer; entity: Entity }) {
      const viewer = context.viewer;
      const scene = viewer.scene;
      const entity = context.entity;
      const metaObject = viewer.metaScene.metaObjects[entity.id];
      if (!metaObject) {
        return;
      }
      scene.setObjectsVisible(scene.visibleObjectIds, false);
      scene.setObjectsXRayed(scene.xrayedObjectIds, false);
      scene.setObjectsSelected(scene.selectedObjectIds, false);
      scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
      metaObject.withMetaObjectsInSubtree((metaObject) => {
        const entity = scene.objects[metaObject.id];
        if (entity) {
          entity.visible = true;
        }
      });
    },
  };

  const hideTree = {
    title: t("bimViewer.hide"),
    getEnabled: function (context: { entity: { visible: boolean } }) {
      return !context.entity || context.entity.visible;
    },
    doAction: function (context: {
      treeViewPlugin: TreeViewPluginProps;
      treeViewNode: string[];
      viewer: { scene: { objects: { [x: string]: Entity } } };
    }) {
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = context.viewer.scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.visible = false;
            }
          }
        }
      );
    },
  };

  const hide = {
    title: t("bimViewer.hide"),
    getEnabled: function (context: { entity: { visible: boolean } }) {
      return context.entity.visible;
    },
    doAction: function (context: { entity: { visible: boolean } }) {
      context.entity.visible = false;
    },
  };

  const viewFitAllTree = {
    title: t("bimViewer.viewFitAll"),
    doAction: function (context: { viewer: Viewer }) {
      const scene = context.viewer.scene;
      context.viewer.cameraFlight.flyTo({
        projection: "perspective",
        aabb: scene.getAABB([]),
        duration: 0.5,
      });
    },
  };

  const viewFitAll = {
    title: t("bimViewer.viewFitAll"),
    doAction: zoomToAll,
  };

  const viewFitTree = {
    title: t("bimViewer.viewFit"),
    doAction: function (context: {
      viewer: Viewer;
      treeViewPlugin: {
        withNodeTree: (
          arg0: TreeViewNode,
          arg1: (treeViewNode: TreeViewNode) => void
        ) => void;
      };
      treeViewNode: TreeViewNode;
    }) {
      const scene = context.viewer.scene;
      const objectIds: string[] = [];
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: TreeViewNode) => {
          if (treeViewNode.objectId) {
            objectIds.push(treeViewNode.objectId as string);
          }
        }
      );
      scene.setObjectsVisible(objectIds, true);
      scene.setObjectsHighlighted(objectIds, true);
      context.viewer.cameraFlight.flyTo(
        {
          projection: "perspective",
          aabb: scene.getAABB(objectIds),
          duration: 0.5,
        },
        () => {
          setTimeout(function () {
            scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
          }, 500);
        }
      );
    },
  };

  const viewFit = {
    title: t("bimViewer.viewFit"),
    doAction: function (context: { viewer: Viewer; entity: Entity }) {
      const viewer = context.viewer;
      const scene = viewer.scene;
      const entity = context.entity;
      viewer.cameraFlight.flyTo(
        {
          aabb: entity.aabb,
          duration: 0.5,
        },
        () => {
          setTimeout(function () {
            scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
          }, 500);
        }
      );
    },
  };

  const xRayTree = {
    title: "X-Ray",
    getEnabled: function (context: { entity: { xrayed: boolean } }) {
      return !context.entity || !context.entity.xrayed;
    },
    doAction: function (context: {
      treeViewPlugin: {
        withNodeTree: (
          arg0: string[],
          arg1: (treeViewNode: { objectId: string | number }) => void
        ) => void;
      };
      treeViewNode: string[];
      viewer: { scene: { objects: { [x: string]: Entity } } };
    }) {
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = context.viewer.scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.xrayed = true;
              entity.visible = true;
              entity.selected = false;
            }
          }
        }
      );
    },
  };

  const xRay = {
    title: "X-Ray",
    getEnabled: function (context: { entity: Entity }) {
      return !context.entity.xrayed;
    },
    doAction: function (context: { entity: { xrayed: boolean } }) {
      context.entity.xrayed = true;
    },
  };

  const undoXrayTree = {
    title: t("bimViewer.undoXray"),
    doAction: function (context: {
      treeViewPlugin: {
        withNodeTree: (
          arg0: string[],
          arg1: (treeViewNode: { objectId: string | number }) => void
        ) => void;
      };
      treeViewNode: string[];
      viewer: Viewer;
    }) {
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode) => {
          if (treeViewNode.objectId) {
            const entity = context.viewer.scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.xrayed = false;
            }
          }
        }
      );
    },
  };

  const undoXray = {
    title: t("bimViewer.undoXray"),
    getEnabled: function (context: { entity: { xrayed: boolean } }) {
      return context.entity.xrayed;
    },
    doAction: function (context: { entity: { xrayed: boolean } }) {
      context.entity.xrayed = false;
    },
  };

  const xRayOthersTree = {
    title: t("bimViewer.xRayOthers"),
    doAction: function (context: {
      viewer: { scene: Scene };
      treeViewPlugin: {
        withNodeTree: (
          arg0: string[],
          arg1: (treeViewNode: { objectId: string | number }) => void
        ) => void;
      };
      treeViewNode: string[];
    }) {
      const scene = context.viewer.scene;
      scene.setObjectsVisible(scene.objectIds, true);
      scene.setObjectsXRayed(scene.objectIds, true);
      scene.setObjectsSelected(scene.selectedObjectIds, false);
      scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.xrayed = false;
            }
          }
        }
      );
    },
  };

  const xRayOthers = {
    title: t("bimViewer.xRayOthers"),
    doAction: function (context: { viewer: Viewer; entity: Entity }) {
      const viewer = context.viewer;
      const scene = viewer.scene;
      const entity = context.entity;
      const metaObject = viewer.metaScene.metaObjects[entity.id];
      if (!metaObject) {
        return;
      }
      scene.setObjectsVisible(scene.objectIds, true);
      scene.setObjectsXRayed(scene.objectIds, true);
      scene.setObjectsSelected(scene.selectedObjectIds, false);
      scene.setObjectsHighlighted(scene.highlightedObjectIds, false);
      metaObject.withMetaObjectsInSubtree((metaObject) => {
        const entity = scene.objects[metaObject.id];
        if (entity) {
          entity.xrayed = false;
        }
      });
    },
  };

  const resetXray = {
    title: t("bimViewer.resetXray"),
    getEnabled: function (context: {
      viewer: { scene: { numXRayedObjects: number } };
    }) {
      return context.viewer.scene.numXRayedObjects > 0;
    },
    doAction: function (context: {
      viewer: {
        scene: {
          setObjectsXRayed: (arg0: string[], arg1: boolean) => void;
          xrayedObjectIds: string[];
        };
      };
    }) {
      context.viewer.scene.setObjectsXRayed(
        context.viewer.scene.xrayedObjectIds,
        false
      );
    },
  };

  const selectTree = {
    title: t("bimViewer.select"),
    getEnabled: function (context: { entity: { selected: boolean } }) {
      return !context.entity || !context.entity.selected;
    },
    doAction: function (context: {
      treeViewPlugin: TreeViewPluginProps;
      treeViewNode: string[];
      viewer: { scene: { objects: { [x: string]: Entity } } };
    }) {
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = context.viewer.scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.selected = true;
              entity.visible = true;
              entity.xrayed = false;
            }
          }
        }
      );
    },
  };

  const select = {
    title: t("bimViewer.select"),
    getEnabled: function (context: { entity: { selected: boolean } }) {
      return !context.entity.selected;
    },
    doAction: function (context: { entity: { selected: boolean } }) {
      context.entity.selected = true;
    },
  };

  const undoSelectTree = {
    title: t("bimViewer.undoSelect"),
    getEnabled: function (context: { entity: { selected: boolean } }) {
      return !context.entity || context.entity.selected;
    },
    doAction: function (context: {
      treeViewPlugin: TreeViewPluginProps;
      treeViewNode: string[];
      viewer: { scene: { objects: { [x: string]: Entity } } };
    }) {
      context.treeViewPlugin.withNodeTree(
        context.treeViewNode,
        (treeViewNode: { objectId: string | number }) => {
          if (treeViewNode.objectId) {
            const entity = context.viewer.scene.objects[treeViewNode.objectId];
            if (entity) {
              entity.selected = false;
            }
          }
        }
      );
    },
  };

  const undoSelect = {
    title: t("bimViewer.undoSelect"),
    getEnabled: function (context: { entity: { selected: boolean } }) {
      return context.entity.selected;
    },
    doAction: function (context: { entity: { selected: boolean } }) {
      context.entity.selected = false;
    },
  };

  const clearSelection = {
    title: t("bimViewer.clearSelection"),
    getEnabled: function (context: {
      viewer: { scene: { numSelectedObjects: number } };
    }) {
      return context.viewer.scene.numSelectedObjects > 0;
    },
    doAction: function (context: {
      viewer: {
        scene: {
          setObjectsSelected: (arg0: string[], arg1: boolean) => void;
          selectedObjectIds: string[];
        };
      };
    }) {
      context.viewer.scene.setObjectsSelected(
        context.viewer.scene.selectedObjectIds,
        false
      );
    },
  };

  const showInTree = {
    title: t("bimViewer.showInTree"),
    doAction: function (context: {
      entity: { id: string[] };
      treeViewPlugin: { showNode: (arg0: string[]) => void };
    }) {
      const objectId = context.entity.id;
      context.treeViewPlugin.showNode(objectId);
    },
  };

  function zoomToAll() {
    bimViewerStore.zoomToBuilding();
  }

  function createTreeViewContextMenu(treeView: TreeViewPlugin | undefined) {
    const treeViewContextMenu = new ContextMenu({
      items: [
        [viewFitTree, viewFitAllTree],
        [hideTree, hideOthersTree, hideAll],
        [show, showOthers, showAll],
        [xRayTree, undoXrayTree, xRayOthersTree, resetXray],
        [selectTree, undoSelectTree, clearSelection],
      ],
    });

    treeView?.on("contextmenu", (e) => {
      treeViewContextMenu.context = {
        viewer: e.viewer,
        treeViewPlugin: e.treeViewPlugin,
        treeViewNode: e.treeViewNode,
        entity: e.viewer.scene.objects[e.treeViewNode.objectId],
      };

      treeViewContextMenu.show(e.event.pageX, e.event.pageY);
    });
  }

  function createObjectsContextMenu() {
    return new ContextMenu({
      items: [
        [viewFit, viewFitAll, showInTree],
        [hide, hideOthers, hideAll, showAll],
        [xRay, undoXray, xRayOthers, resetXray],
        [select, undoSelect, clearSelection],
      ],
      enabled: true,
    });
  }

  function createCanvasContextMenu(viewer: Viewer | undefined) {
    if (!viewer) return;
    return new ContextMenu({
      enabled: true,
      context: {
        viewer: viewer,
      },
      items: [[hideAll, showAll], [viewFitAll]],
    });
  }

  function controlRightMenuOnViewer(
    viewer: Viewer | undefined,
    objectContextMenu: ContextMenu,
    treeView: TreeViewPlugin | undefined,
    canvasContextMenu: ContextMenu | undefined
  ) {
    if (!viewer) return;
    viewer.cameraControl.on("rightClick", function (e) {
      const hit: PickResult | null = viewer.scene.pick({
        canvasPos: e.canvasPos,
      });

      if (hit && hit.entity && hit.entity.isObject) {
        objectContextMenu.context = {
          viewer: viewer,
          treeViewPlugin: treeView,
          entity: hit.entity,
        };

        objectContextMenu.show(e.event.pageX, e.event.pageY);
      } else if (canvasContextMenu) {
        canvasContextMenu.context = {
          viewer: viewer,
        };
        canvasContextMenu.show(e.event.pageX, e.event.pageY);
      }

      e.event.preventDefault();
    });
  }

  return {
    createTreeViewContextMenu,
    createObjectsContextMenu,
    createCanvasContextMenu,
    controlRightMenuOnViewer,
  };
}
