import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  addedComponentsAtom,
  addedImagesAtom,
  generatedBlueprintNameAtom,
  manufacturerNameAtom,
  manufacturersAtom,
  productGroupNameAtom,
  productGroupsAtom,
  selectedManufacturerAtom,
  selectedProductGroupAtom,
} from "store/atoms/audits";
import SelectProductGroup from "../../../../features/Audit/SelectProductGroup";
import SelectManufacturer from "../../../../features/Audit/SelectManufacturer";
import ImageUploaderLocal from "components/ImageUploaderLocal";
import { ContinueButton, SkipButton } from "../navigation";
import { useTranslation } from "react-i18next";
import { filledStepsAtom } from "components/Stepper";
import { ProductGroupOUT } from "api-client";
import { DataFetcherStrip } from "..";
import useManufacturerAndProductGroups from "../useManufacturerAndProductGroups";
import { LoadingView } from "features/Audit/LoadingView";

export default function Step1() {
  const manufacturers = useRecoilValue(manufacturersAtom);
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const productGroups = useRecoilValue(productGroupsAtom);
  const setAddedComponents = useSetRecoilState(addedComponentsAtom);
  const setSelectedProductGroup = useSetRecoilState(selectedProductGroupAtom);
  const setSelectedManufacturer = useSetRecoilState(selectedManufacturerAtom);
  const setBlueprintName = useSetRecoilState(generatedBlueprintNameAtom);
  const setProductGroupName = useSetRecoilState(productGroupNameAtom);
  const setManufacturerName = useSetRecoilState(manufacturerNameAtom);
  const setAddedImages = useSetRecoilState(addedImagesAtom);
  const setFilledSteps = useSetRecoilState(filledStepsAtom);

  const { fetchDataAsync, loading } = useManufacturerAndProductGroups();

  function handleManufacturerChange(value?: string) {
    const found = manufacturers?.find(
      (manufacturer) => manufacturer.name === value
    );
    if (!found) return;
    setSelectedManufacturer(found);
    setManufacturerName(found.name.trim());
  }

  const handleProuductGroupChange = (value?: string) => {
    const found = productGroups?.find((group: ProductGroupOUT) => {
      return group[isEng ? "name" : "name_de"] === value;
    });
    if (!found) return;
    setSelectedProductGroup(found);
    setAddedComponents({});
    setProductGroupName(found[isEng ? "name" : "name_de"].trim());
    setFilledSteps([]);
  };

  if (loading) {
    return (
      <div className="items-center justify-center flex h-[50vh]">
        <LoadingView message={loading} />
      </div>
    );
  }

  return (
    <>
      <div className="grid">
        <SelectProductGroup
          onReset={() => {
            setBlueprintName("");
            setFilledSteps([]);
          }}
          onChange={handleProuductGroupChange}
        />
        <ImageUploaderLocal
          id="blueprint-images"
          handleChange={(files) => setAddedImages(files as File[])}
          // required
          name="images"
          multiple
          labelName={t("audits.addImages")}
        />
        <SelectManufacturer
          onReset={() => setManufacturerName("")}
          onChange={handleManufacturerChange}
        />
      </div>
      <div className="flex justify-evenly gap-4 pt-4 pb-2">
        <SkipButton />
        <ContinueButton />
      </div>
      <DataFetcherStrip onDataFetching={() => fetchDataAsync(false)} />
    </>
  );
}
