import React from "react";
import BPENotFoundIcon from "assets/images/icons/not-found.svg";
import { useTranslation } from "react-i18next";

export default function EmptyState() {
  const { t } = useTranslation();

  return (
    <div className="md:mt-16 flex flex-col max-w-lg items-center justify-center border border-gray-300 rounded-md bg-white p-4">
      <div className="text-sm text-gray-700 font-[500] py-2">
        {t("exports.noExports")}
      </div>
      <img src={BPENotFoundIcon} className="pt-2" />
      <div className="text-sm text-gray-500 font-[400] py-2">
        {t("exports.noExportMsg")}
      </div>
    </div>
  );
}
