import React from "react";
import clsx from "clsx";

export default function LoadingIcon({
  type = "primary",
  className,
}: {
  type?:
    | "primary"
    | "secondary"
    | "gray"
    | "danger"
    | "white"
    | "link"
    | "submit";
  className?: string;
}) {
  return (
    <svg
      className={clsx(
        "animate-spin h-4 w-4",
        {
          "text-indigo-600": type === "primary",
          "text-red-700": type === "danger",
          "text-indigo-400": type === "secondary",
          "text-gray-800": type === "gray",
          "text-gray-700": type === "link",
        },
        className
      )}
      width="101"
      height="100"
      viewBox="0 0 101 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="opacity-25"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1447 14.6447C24.5215 5.26784 37.2392 0 50.5 0L50.5 11.1125C29.0223 11.1125 11.6111 28.5236 11.6111 50.0014C11.6111 71.4791 29.0223 88.8903 50.5 88.8903L50.5 100C37.2392 100 24.5215 94.7322 15.1447 85.3553C5.76784 75.9785 0.5 63.2608 0.5 50C0.5 36.7392 5.76784 24.0215 15.1447 14.6447Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8553 85.3553C76.4785 94.7322 63.7608 100 50.5 100V90C72.5914 90 90.5 72.0914 90.5 50C90.5 27.9086 72.5914 10 50.5 10L50.5 0C63.7608 2.58198e-06 76.4785 5.26785 85.8553 14.6447C95.2322 24.0215 100.5 36.7392 100.5 50C100.5 63.2608 95.2322 75.9785 85.8553 85.3553Z"
        fill="currentColor"
      />
    </svg>
  );
}
