import React from "react";
import { useTranslation } from "react-i18next";
import { CogIcon, UserIcon } from "@heroicons/react/outline";
import { userOrganisationsApi } from "api-client";
import clsx from "clsx";
import { useNavigate } from "react-router";
import UpTray from "assets/images/icons/UpTray.svg";
import ActionMenu from "components/ActionMenu";
import useToast from "hooks/useToast";
import { AxiosResponse } from "axios";
import { organisationStore } from "store/OrganisationStore";
import { observer } from "mobx-react-lite";
import { userStore } from "store/UserStore";

const OrganisationSettingsMenu = ({
  openMembers,
  openSettings,
  isAdmin,
  multipleAdmin,
}: {
  openMembers: () => void;
  openSettings: () => void;
  isAdmin: boolean;
  multipleAdmin: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authHeader } = userStore;
  const { clickedOrganisation } = organisationStore;

  const toast = useToast();

  async function leaveOrganisation() {
    if (!clickedOrganisation?.id || !authHeader) return;
    return await userOrganisationsApi
      .concularApiV1RoutersUserOrganisationsLeaveOrganisation(
        clickedOrganisation.id,
        authHeader
      )
      .then(async (response: AxiosResponse) => {
        if (response.data.success) {
          await organisationStore.getAllOrganisations().then(() => {
            organisationStore.setCurrentOrganisation(undefined);
            navigate("/dashboard", { replace: true });
            toast(
              t("organisationSettings.myOrganisation.leftOrganisationSuccess"),
              "success"
            );
          });
        } else {
          toast(response.data.message.message, "warning");
        }
      })
      .catch((err) => {
        console.error(err, "leave organisation");
        toast(t("commons.genericErrorMessage"), "warning");
      });
  }

  const menuItems = [
    ...(isAdmin
      ? [
          <div
            key={0}
            className="flex px-4 py-2 w-full"
            onClick={() => openMembers()}
          >
            <UserIcon width={15} className="mr-3" />
            {t("commons.members")}
          </div>,
          <div
            key={1}
            className="flex px-4 py-2 w-full"
            onClick={() => openSettings()}
          >
            <CogIcon width={15} className="mr-3" />
            {t("commons.settings")}
          </div>,
        ]
      : []),
    <div
      key={2}
      className={clsx("flex w-full px-4 pt-2 py-2", {
        "border-t border-gray-300": isAdmin,
        "opacity-50 pointer-events-none": !multipleAdmin && isAdmin,
      })}
      onClick={() => leaveOrganisation()}
    >
      <img src={UpTray} className="rotate-90 mr-3" width={15} />
      {t("commons.leave")}
    </div>,
  ];

  return (
    <ActionMenu items={menuItems} itemClassName="!p-0 w-full"></ActionMenu>
  );
};

export default observer(OrganisationSettingsMenu);
