import React from "react";

export default function useMultiselect(
  selectedIds: string[],
  setSelectedIds: React.Dispatch<React.SetStateAction<string[]>>
) {
  const handleMultiSelect = (id: string) => {
    setSelectedIds((prev) =>
      selectedIds.includes(id)
        ? prev.filter((el) => el !== id)
        : Array.from(new Set([...prev, id]))
    );
  };

  return handleMultiSelect;
}
