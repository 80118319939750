import { AuditOUT, BlueprintOUT } from "api-client";
import { setLocalAudit } from "api-client-local/audits";
import { checkAndAddBlueprintId } from "utils";

export default async function handleComponentDelete(
  id: string,
  audit: AuditOUT
) {
  const parentBlueprint = audit.blueprint_set.find(
    (b) => b.components && b.components.find((c) => c.id === id)
  );

  const newBlueprintSet = audit.blueprint_set
    .map((blueprint) => {
      if (
        parentBlueprint &&
        blueprint.components &&
        blueprint.id === parentBlueprint.id
      ) {
        const componentInParent = blueprint.components.find((c) => c.id === id);
        if (componentInParent) {
          const newComponentList = blueprint.components.filter(
            (c) => c.id !== id
          );
          blueprint = JSON.parse(JSON.stringify(blueprint));
          blueprint.components = newComponentList;
          return blueprint;
        } else if (blueprint.id === id) {
          return undefined;
        } else {
          console.error(
            `Component index not found in parent. Please check data.`
          );
          return blueprint;
        }
      } else return blueprint;
    })
    .filter((b) => b !== undefined && b.id !== id) as BlueprintOUT[];

  const auditRequest = {
    ...audit,
    id: audit.id,
    blueprint_set: newBlueprintSet,
  };
  const updatedLocalAudit = await setLocalAudit(auditRequest);
  await checkAndAddBlueprintId(parentBlueprint?.id ?? "");
  return updatedLocalAudit;
}
