import React from "react";
import EmptyState from "./EmptyState";
import SearchSortTable from "components/Table";
import { BuildingReportOUT, UserProfileOUT } from "api-client";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Button from "components/Button";
import { IconDownload, IconTrash } from "@tabler/icons-react";
import { buildingStore } from "store/Building/BuildingStore";
import useToast from "hooks/useToast";
import ConfirmModal from "components/ConfirmModal";

const DEFAULT_SORT = [
  {
    id: "creation_date",
    desc: true,
  },
];

function ExportTable() {
  const { t } = useTranslation();
  const [fileName, setFileName] = React.useState("");
  const [deleteLoading, setDeleteLoading] = React.useState(false);
  const [reportId, setReportId] = React.useState("");
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const toast = useToast();

  const getName = (userProfile: UserProfileOUT | undefined) => {
    if (!userProfile) return "-";
    const { first_name, last_name, email, username } = userProfile;
    // check priority wise and display the name
    if (first_name && last_name) return first_name + " " + last_name;
    if (first_name) return first_name;
    if (last_name) return last_name;
    if (username) return username;
    if (email) return email;
    return "-";
  };

  const isDisabled = (status: string) => {
    switch (status) {
      case "NEW":
      case "IN_PROGRESS":
        return true;
      case "DONE":
        return false;
      default:
        return true;
    }
  };

  const getColor = (status: string) => {
    switch (status) {
      case "NEW":
      case "IN_PROGRESS":
        return "bg-yellow-100 text-yellow-800";
      case "DONE":
        return "bg-green-100 text-green-800";
      default:
        return "bg-red-100 text-red-800";
    }
  };

  const getStatusText = (status: string) => {
    switch (status) {
      case "NEW":
      case "IN_PROGRESS":
        return t("exports.inProgress");
      case "DONE":
        return t("exports.ready");
      default:
        return t("exports.failed");
    }
  };

  const onClickDelete = (id: string, filename: string | undefined | null) => {
    setReportId(id);
    setFileName(filename ?? "");
    setOpenDeleteModal(true);
  };

  function actionsCell(info: CellContext<BuildingReportOUT, unknown>) {
    const id = info.row.original.id;
    const url = info.row.original.download_url;
    const filename = info.row.original.filename;
    const status = info.row.original.status;
    return (
      <div
        className={`flex items-center space-x-2 ${
          deleteLoading ? "opacity-50" : ""
        }`}
      >
        <Button
          disable={isDisabled(status) || deleteLoading}
          onClick={() => window.open(String(url), "_blank", "noreferrer")}
          className="border border-gray-300 disabled:cursor-not-allowed"
          leadingIcon={<IconDownload width={24} />}
          type="link"
        >
          {t("exports.downloadXLSX")}
        </Button>
        <Button
          disable={isDisabled(status) || deleteLoading}
          onClick={() => onClickDelete(id, filename)}
          className="!h-9 !w-9 border border-red-300 !-p-1 !rounded-md disabled:cursor-not-allowed"
          leadingIcon={<IconTrash width={20} color={"red"} />}
          type="link"
        ></Button>
      </div>
    );
  }

  const useColumns = () => {
    const columns = React.useMemo<ColumnDef<BuildingReportOUT>[]>(
      () => [
        {
          accessorFn: (row) => row.filename,
          id: "fileName",
          cell: (info) => (
            <div className="break-all">{info.row.original.filename}</div>
          ),
          header: () => <div>{t("exports.name")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
          size: 250,
        },
        {
          accessorFn: (row) => row.type,
          id: "type",
          cell: (info) => <div>{info.row.original.type}</div>,
          header: () => <div>{t("exports.type")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.creation_date,
          id: "creation_date",
          cell: (info) => (
            <div>
              {moment(info.row.original.creation_date).format(
                "DD.MM.yyyy, HH:mm"
              )}
            </div>
          ),
          header: () => <div>{t("exports.creationDate")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.created_by,
          id: "createdBy",
          cell: (info) => (
            <div>{getName(info.row.original.created_by as UserProfileOUT)}</div>
          ),
          header: () => <div>{t("exports.createdBy")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.status,
          id: "status",
          cell: (info) => {
            const status = info.row.original.status;
            return (
              <div>
                <div
                  className={`${getColor(
                    status
                  )} w-fit flex items-center justify-center rounded-full text-[14px] font-[600] px-3 py-2`}
                >
                  {getStatusText(status)}
                </div>
              </div>
            );
          },
          header: () => <div>{t("exports.status")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
          size: 250,
          enablePinning: true,
        },
        {
          accessorFn: (row) => row.download_url,
          id: "actions",
          cell: (info) => actionsCell(info),
          header: () => <div>{t("exports.actions")}</div>,
          enableColumnFilter: false,
          enableSorting: false,
          size: 280,
        },
      ],
      []
    );
    return columns;
  };

  const handleDelete = async () => {
    if (reportId === "") return;
    if (deleteLoading) return;
    setOpenDeleteModal(false);
    setDeleteLoading(true);
    try {
      const response = await buildingStore.deleteBuildingExportedCertification(
        reportId
      );
      if (response) {
        toast(t("exports.deleteSuccess"), "success");
        buildingStore.setExportedCertifications({
          loading: false,
          items: buildingStore.exportedCertifications.items.filter(
            (item) => item.id !== reportId
          ),
        });
      } else {
        toast(t("exports.deleteFailed"), "attention");
      }
    } catch (error) {
      toast(error as string, "attention");
    } finally {
      setDeleteLoading(false);
      setReportId("");
      setFileName("");
    }
  };

  return (
    <>
      <ConfirmModal
        title={t("exports.deleteTitle", {
          filename: fileName,
        })}
        confirmButtonTitle={t("exports.removeExport")}
        descriptionMesage={t("exports.deleteDescription")}
        open={openDeleteModal}
        close={() => setOpenDeleteModal(false)}
        onConfirm={handleDelete}
        containerClassName="!min-w-[600px]"
      ></ConfirmModal>
      <div className="mt-3 justify-center flex">
        {buildingStore.exportedCertifications.items.length > 0 ? (
          <SearchSortTable
            scrollDivClassName="!z-10 w-full"
            data={buildingStore.exportedCertifications.items}
            getColumns={useColumns as () => ColumnDef<unknown, unknown>[]}
            borderedStyle
            zebraStriped
            defaultSort={DEFAULT_SORT}
          />
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
}

export default ExportTable;
