import {
  AuditIN,
  AuditOUT,
  BlueprintOUT,
  BlueprintPropertyOUT,
  ManufacturerOUT,
  ProductGroupOUT,
} from "api-client";
import { handleFieldValueTypes, makeBlueprintPropertyModel } from "../utils";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";

import { checkAndAddBlueprintId, omit } from "utils";
import { SetterOrUpdater } from "recoil";
import { setLocalAudit } from "api-client-local/audits";
import { db } from "../../../api-client-local/db";
import { LocalImage } from "../../../api-client-local/db_interfaces";

export interface HandleAuditEditArgs {
  audit: AuditOUT;
  setFormLoading:
    | React.Dispatch<React.SetStateAction<string>>
    | SetterOrUpdater<string>;
  images: (File | LocalImage)[];
  authHeader: AxiosRequestConfig<AxiosRequestHeaders>;
  selectedProductGroup: ProductGroupOUT;
  selectedBlueprint: BlueprintOUT;
}

export type EditBlueprintForm = {
  [field_id: string]: string | number | string[];
};

export default async function handleBlueprintEdit(
  form: EditBlueprintForm,
  args: HandleAuditEditArgs,
  loadingCallback: (m: string) => void,
  selectedManufacturer?: ManufacturerOUT
) {
  console.log(
    "handleBlueprintEdit.form",
    form,
    args.selectedBlueprint.blueprintproperty_set,
    args.selectedProductGroup.optional_fields
  );
  form = omit<EditBlueprintForm>("images", form);
  const conditionField = args.selectedProductGroup.optional_fields.find(
    (f) => f.name === "Condition"
  );
  console.log("handleBlueprintEdit.conditionField", conditionField);
  const blueprintPropertySet = conditionField
    ? args.selectedBlueprint.blueprintproperty_set.filter(
        (p) => p.field_id !== conditionField.id
      )
    : args.selectedBlueprint.blueprintproperty_set;

  const nextProperties: BlueprintPropertyOUT[] = [];
  let generatedFields = omit("name", form) as EditBlueprintForm;
  generatedFields = omit("extra_info", generatedFields) as EditBlueprintForm;
  generatedFields = omit(
    "as_component_amount",
    generatedFields
  ) as EditBlueprintForm;
  generatedFields = omit(
    "select_manufacturer",
    generatedFields
  ) as EditBlueprintForm;

  console.log("handleBlueprintEdit.generatedFields", generatedFields);
  for (const fieldId in generatedFields) {
    const fieldValue = generatedFields[fieldId];
    const previouslyFound = blueprintPropertySet.find(
      (prop) => prop.field_id === fieldId
    );
    if (previouslyFound) {
      const isEmptySelectField =
        previouslyFound.value_field_option_ids &&
        (previouslyFound.value_field_option_ids as []).length > 0 &&
        typeof fieldValue === "string" &&
        fieldValue.length === 0;

      const updatedField = handleFieldValueTypes(
        fieldValue,
        previouslyFound,
        Boolean(isEmptySelectField)
      );
      nextProperties.push(updatedField);
    } else {
      const createdField = makeBlueprintPropertyModel(
        fieldId,
        args.selectedBlueprint.id,
        fieldValue
      );
      nextProperties.push(createdField);
    }
  }

  const updatedBlueprintData = {
    name: form.name as string,
    manufacturer: selectedManufacturer,
    extra_info: form.extra_info as string,
    as_component_amount: Number(form.as_component_amount) || 1,
    blueprintproperty_set: nextProperties,
  };

  // To detect if blueprint object is located inside a components array of a blueprint.
  // Because while updating the blueprint, we need to update same data inside parentBlueprint.components as well
  const parentBlueprint = args.audit.blueprint_set.find(
    (b) =>
      b.components &&
      b.components.find((c) => c.id === args.selectedBlueprint.id)
  );

  const newBlueprintSet = args.audit.blueprint_set.map((blueprint) => {
    if (blueprint.id === args.selectedBlueprint.id) {
      return {
        ...blueprint,
        ...updatedBlueprintData,
      };
    } else if (
      parentBlueprint &&
      blueprint.components &&
      blueprint.id === parentBlueprint.id
    ) {
      const componentInParent = blueprint.components.find(
        (c) => c.id === args.selectedBlueprint.id
      );

      if (componentInParent) {
        const newComponentList = [
          ...blueprint.components.filter(
            (c) => c.id !== args.selectedBlueprint.id
          ),
          {
            ...componentInParent,
            ...updatedBlueprintData,
          },
        ];
        blueprint = JSON.parse(JSON.stringify(blueprint));
        blueprint.components = newComponentList as BlueprintOUT[];
        return blueprint;
      } else {
        console.error(
          `Component index not found in parent. Please check data.`
        );
        return blueprint;
      }
    } else return blueprint;
  });

  const auditRequest = {
    ...args.audit,
    id: args.selectedBlueprint.audit_id,
    blueprint_set: newBlueprintSet,
  };

  const localAudit = await setLocalAudit(auditRequest as AuditIN);

  if (args.images && args.images.length > 0) {
    console.log("args.images", args.images);
    loadingCallback("audits.uploadingBlueprintImages");
    // Create the the images in CADexie.

    for (const image of args.images) {
      let imageFile: File;
      if (image instanceof File) {
        imageFile = image;
      } else if ("file" in image) {
        imageFile = image.file;
      } else {
        console.error("Problem with image:", image);
        continue;
      }
      db.images.add({
        id: window.crypto.randomUUID(),
        sourceType: "blueprint",
        sourceId: args.selectedBlueprint.id,
        file: imageFile,
        deleted: false,
        uploaded: false,
        order: 0,
        albumName: "default",
      });
    }
    // await handleAuditLocalItemFormImages(
    //   args,
    //   loadingCallback,
    //   (defaultAlbum as LocalImageAlbumOUT) ?? undefined
    // );
  }

  await checkAndAddBlueprintId(args.selectedBlueprint.id);

  return localAudit;
}
