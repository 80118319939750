import { RetoolEmbedOUT, reportBuildingApi } from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable } from "mobx";

class RetoolStore {
  async getEmbedUrl(id: string): Promise<RetoolEmbedOUT> {
    const authHeader = await getLocalAuthHeader();
    const embedded = await reportBuildingApi.reportApiV1RoutersBuildingEmbedUrl(
      id,
      authHeader
    );

    return embedded.data;
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: RetoolStore;
  static getInstance(): RetoolStore {
    if (!RetoolStore.instance) {
      RetoolStore.instance = new RetoolStore();
    }
    return RetoolStore.instance;
  }
}

export const retoolStore = RetoolStore.getInstance();
