import React from "react";
import { useTranslation } from "react-i18next";
import Heading from "components/Heading";
import { UserIcon } from "@heroicons/react/solid";
import Input from "components/Input";
import SimpleSelect from "components/SimpleSelect";
import i18n from "languages/i18n";
import { useAuth0 } from "@auth0/auth0-react";
import Alert from "components/Alert";
import Button from "components/Button";
import { userApi } from "api-client";
import { UserProfile, userStore } from "store/UserStore";

const languagesList = [
  { id: "1", name: "English", name_de: "Englisch", value: "en" },
  { id: "2", name: "German", name_de: "Deutsch", value: "de" },
];

export default function MyAccount() {
  const { t } = useTranslation();
  const { userProfile, authHeader } = userStore;
  const { user } = useAuth0();
  const givenNameRef = React.useRef<HTMLInputElement>(null);
  const familyNameRef = React.useRef<HTMLInputElement>(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [langValue, setLangValue] = React.useState(
    localStorage.getItem("lang") ?? i18n.language
  );
  const [languageChanged, setLanguageChanged] = React.useState(false);

  const changeLanguage = (value: string | undefined) => {
    setLangValue(value === languagesList[0].id ? "en" : "de");
    setLanguageChanged(true);
  };

  const handleChange = () => {
    if (errorMessage === "" && successMessage === "") return;
    setSuccessMessage("");
    setErrorMessage("");
  };

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    if (!user || !authHeader) return;
    try {
      i18n.changeLanguage(langValue);
      localStorage.setItem("lang", langValue);
      if (!givenNameRef.current || !familyNameRef.current) return;
      const first_name = givenNameRef.current.value;
      const last_name = familyNameRef.current.value;
      if (!first_name || first_name === "" || first_name.length < 2) {
        return setErrorMessage(t("onboarding.firstNameError"));
      }
      if (!last_name || last_name === "" || last_name.length < 2) {
        return setErrorMessage(t("onboarding.lastNameError"));
      }
      const response = await userApi.concularApiV1RoutersUserUpdateUserProfile(
        { first_name, last_name },
        authHeader
      );
      if (response.status < 400) {
        userStore.setResetUser(true);
        const updatedProfile = {
          ...userProfile,
          first_name: first_name,
          last_name: last_name,
        } as UserProfile;
        userStore.setUserProfile(updatedProfile);
        setSuccessMessage(t("buildingMaterialResources.mappingSuccessMessage"));
        languageChanged && window.location.reload();
      } else {
        throw response.statusText;
      }
    } catch (err: unknown) {
      console.error(err);
      setErrorMessage(err as string);
    }
  }

  return (
    <>
      <Header />
      <form
        onSubmit={handleSubmit}
        className="p-5 text-sm h-[calc(100vh-200px)] overflow-y-scroll scrollbar"
      >
        <div className="mb-6 text-lg font-semibold">
          {t("userSettings.profile")}
        </div>
        <div className="flex gap-4 w-full">
          <Input
            ref={givenNameRef}
            labelName={t("userSettings.firstName")}
            placeholder={t("userSettings.firstNamePlaceholder")}
            containerClassName="w-full"
            name="given_name"
            onChange={handleChange}
            defaultValue={
              userProfile ? userProfile.first_name : user?.given_name
            }
          />
          <Input
            ref={familyNameRef}
            labelName={t("userSettings.lastName")}
            placeholder={t("userSettings.lastNamePlaceholder")}
            containerClassName="w-full"
            name="family_name"
            onChange={handleChange}
            defaultValue={
              userProfile ? userProfile.last_name : user?.family_name
            }
          />
        </div>
        <Input labelName="Email" value={user?.email} disable />
        <SimpleSelect
          name="locale"
          current={languagesList.find((item) => item.value === langValue)?.id}
          options={languagesList}
          labelName={t("userSettings.language")}
          onChange={changeLanguage}
        />
        <Button type="primary" htmlType="submit" width="fit-content">
          {t("commons.save")}
        </Button>
        {errorMessage !== "" && (
          <Alert type="warning" title={errorMessage} className="mt-7" />
        )}
        {successMessage !== "" && errorMessage === "" && (
          <Alert type="success" title={successMessage} className="mt-7" />
        )}
      </form>
    </>
  );
}

function Header() {
  const { t } = useTranslation();

  return (
    <div className="flex p-5 border-b border-gray-300 items-center">
      <UserIcon width={20} />
      <Heading size="title" className="pl-2 !mb-0">
        {t("userSettings.myAccount")}
      </Heading>
    </div>
  );
}
