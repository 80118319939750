import React from "react";
import { ContextMenuState } from "./useContextMenu";
import { Row } from "@tanstack/react-table";
import useWindowOverflow from "hooks/useWindowOverflow";

export interface ContextMenuItem {
  label: string;
  icon?: React.ReactNode;
  onClick: (row?: Row<unknown>) => void;
}

export interface ContextMenuOption {
  title?: string;
  options: ContextMenuItem[];
}

const ContextMenu = ({
  info,
  items,
  onClose,
}: {
  info: ContextMenuState | null;
  items: ContextMenuOption[];
  onClose: () => void;
}) => {
  const { getTopPos, getLeftPos, containerRef } = useWindowOverflow();

  return (
    <div
      className="absolute bg-white border border-gray-300 z-10 rounded-md text-xs whitespace-nowrap"
      style={{
        top: getTopPos(info?.y, 25),
        left: getLeftPos(info?.x, 20),
      }}
      ref={containerRef}
    >
      <ul className="list-none">
        {items.map(
          (item: ContextMenuOption, index: React.Key | null | undefined) => (
            <>
              <div className="bg-gray-50 text-gray-500 px-4 py-1 border-b first-of-type:border-t-0 border-t">
                {item.title}
              </div>
              {item.options.map((rec) => (
                <li
                  key={index}
                  className="cursor-pointer px-4 py-2 flex items-center hover:bg-indigo-50 hover:text-indigo-700"
                  onClick={() => {
                    rec.onClick(info?.row);
                    onClose();
                  }}
                >
                  {rec?.icon ? (
                    <span className="mr-1.5">{rec.icon}</span>
                  ) : null}
                  {rec.label}
                </li>
              ))}
            </>
          )
        )}
      </ul>
    </div>
  );
};

export default ContextMenu;
