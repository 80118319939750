import React from "react";
import { observer } from "mobx-react-lite";
import SimpleSelect from "components/SimpleSelect";
import CustomDatePicker from "components/DatePicker";
import { useTranslation } from "react-i18next";
import { UPCOMING_OPERATION_LIST } from "features/Building/consts.d";
import { BuildingFormOnchange } from "./GeneralInformation";
import moment from "moment";
import { buildingSettingsStore } from "store/Building/BuildingSettingsStore";

export default observer(function PlannedOperation({
  handleChange,
}: BuildingFormOnchange) {
  const { t } = useTranslation();

  const onChangeConstructionYear = (
    value: string | undefined | Date,
    name: string | undefined
  ) => {
    const year = Number(moment(value).format("yyyy"));
    handleChange(year, name);
  };

  return (
    <>
      <div className="text-xl font-semibold mt-3 mb-2">
        {t("addBuilding.planned_operation_heading")}
      </div>

      <SimpleSelect
        labelName={t("addBuilding.upcomingOperation")}
        options={UPCOMING_OPERATION_LIST}
        containerClassName="w-full"
        name="upcoming_operation"
        onChange={handleChange}
        placeholder={t("addBuilding.upcomingOperationPlacehoder")}
        current={
          buildingSettingsStore.buildingFormDetails.upcoming_operation
            ? UPCOMING_OPERATION_LIST.find(
                (item) =>
                  item.id ===
                  buildingSettingsStore.buildingFormDetails.upcoming_operation
              )?.id
            : undefined
        }
      />
      {buildingSettingsStore.buildingFormDetails.upcoming_operation !==
        UPCOMING_OPERATION_LIST[0].id && (
        <CustomDatePicker
          value={
            buildingSettingsStore.buildingFormDetails.construction_year
              ? String(
                  buildingSettingsStore.buildingFormDetails.construction_year
                )
              : ""
          }
          name="construction_year"
          dateFormat="yyyy"
          placeholder="YYYY"
          yearPicker
          onChange={onChangeConstructionYear}
          label={t("addBuilding.yearOfConstruction")}
        />
      )}
      <div className="flex justify-between gap-4 mt-4">
        <CustomDatePicker
          value={buildingSettingsStore.buildingFormDetails.start_date ?? ""}
          name="start_date"
          dateFormat="MM.yyyy"
          placeholder="MM.YYYY"
          maxDate={buildingSettingsStore.buildingFormDetails.end_date ?? ""}
          monthYearPicker
          onChange={handleChange}
          label={t("addBuilding.estimatedStart")}
        />
        <CustomDatePicker
          label={t("addBuilding.estimatedEnd")}
          monthYearPicker
          name="end_date"
          onChange={handleChange}
          dateFormat="MM.yyyy"
          placeholder="MM.YYYY"
          minDate={
            buildingSettingsStore.buildingFormDetails.start_date as string
          }
          value={buildingSettingsStore.buildingFormDetails.end_date ?? ""}
        />
      </div>
    </>
  );
});
