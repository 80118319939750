import React from "react";
import OrganisationLogo from "features/Organisation/OrganisationLogo";
import { observer } from "mobx-react";
import { revitStore } from "store/RevitStore";
import { OrganisationOUT } from "api-client";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import Tooltip from "components/Tooltip";
import { organisationStore } from "store/OrganisationStore";
import { buildingStore } from "store/Building/BuildingStore";

export default observer(function StepOrganisations() {
  const { organisations } = organisationStore;
  const { t } = useTranslation();

  const selectOrganisation = (organisation: OrganisationOUT) => {
    revitStore.setCurrentOrganisation(organisation);
    revitStore.setActiveStep(revitStore.activeStep + 1);
  };

  function goToNextStep() {
    revitStore.setSkipOrganisation(true);
    revitStore.setCurrentOrganisation(organisations[0]);
    revitStore.setActiveStep(revitStore.activeStep + 1);
  }

  function numberOfBuildings(buildingsCount: number | undefined) {
    if (buildingsCount) {
      return `${buildingsCount} ${
        buildingsCount === 1 ? t("revit.building") : t("revit.buildings")
      }`;
    } else {
      return t("revit.noBuildings");
    }
  }

  function organisationRow(item: OrganisationOUT, index: number) {
    const buildingsCount = buildingStore.getCurrentOrganisationBuildings(
      item.id
    )?.length;

    return (
      <div
        key={item.id}
        className="border border-gray-200 mb-2 rounded-md px-2 py-[6px] cursor-pointer"
      >
        <div className="flex justify-between items-center">
          <div
            className={clsx("flex items-center w-full", {
              "pointer-events-none opacity-30": buildingsCount === 0,
            })}
            onClick={() => selectOrganisation(item)}
            test-id={`organisationRow_${index}`}
          >
            <OrganisationLogo organisation={item} />
            <div className="pl-3 whitespace-nowrap text-xs font-normal transition hover:text-black">
              <Tooltip truncate className="max-w-[160px]">
                {item.name}
              </Tooltip>
            </div>
            <div className="bg-gray-200 text-xs font-medium rounded-lg px-2 py-[1px] ml-4">
              {numberOfBuildings(buildingsCount)}
            </div>
          </div>
          {buildingsCount === 0 ? (
            <Tooltip
              content={t("revit.noBuildingsTooltip")}
              iconClassName="text-gray-500"
            />
          ) : null}
        </div>
      </div>
    );
  }

  if (organisations.length === 1) goToNextStep();
  return (
    <>{organisations.map((item, index) => organisationRow(item, index))}</>
  );
});
