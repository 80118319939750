import { UserProfileOUT } from "api-client";
import { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { makeAutoObservable } from "mobx";

export interface UserProfile extends UserProfileOUT {
  terms_accepted: boolean;
}

type AuthHeader = AxiosRequestConfig<AxiosRequestHeaders> | null;
class UserStore {
  authHeader: AuthHeader = null;
  userbackToken: string | undefined;
  onboarded: boolean | undefined = undefined;
  verified: boolean | undefined;
  initialLoading: boolean = true;
  onboardingLoading: boolean = true;
  resetUser: boolean = false;
  userProfile: UserProfile | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthHeader(authHeader: AuthHeader) {
    this.authHeader = authHeader;
  }

  setUserbackToken(userbackToken: string) {
    this.userbackToken = userbackToken;
  }

  setOnboarded(onboarded: boolean) {
    this.onboarded = onboarded;
  }

  setVerified(verified: boolean | undefined) {
    this.verified = verified;
  }

  setInitialLoading(initialLoading: boolean) {
    this.initialLoading = initialLoading;
  }

  setOnboardingLoading(onboardingLoading: boolean) {
    this.onboardingLoading = onboardingLoading;
  }

  setResetUser(resetUser: boolean) {
    this.resetUser = resetUser;
  }

  setUserProfile(userProfile: UserProfile | null) {
    this.userProfile = userProfile;
  }

  static instance: UserStore;

  static getInstance(): UserStore {
    if (!UserStore.instance) {
      UserStore.instance = new UserStore();
    }
    return UserStore.instance;
  }
}

export const userStore = UserStore.getInstance();
