import React, { useEffect, useState } from "react";
import FilesDropZone from "components/FilesDropZone";
import DownloadModelRequirements from "./DownloadModelRequirements";
import IFCFileIcon from "assets/images/icons/ifc-file-icon.svg";
import CSVFileIcon from "assets/images/icons/csv-file-icon.svg";
import { UploadType } from ".";
import { AxiosProgressEvent } from "axios";
import CircularProgressBar from "components/CircularProgressBar";
import { inventoryFileAPI } from "api-client";
import { useParams } from "react-router";
import useToast from "hooks/useToast";
import { useTranslation } from "react-i18next";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";
import { userStore } from "store/UserStore";

export default observer(function UploadFile(props: {
  uploadType: UploadType | null;
  uploading?: boolean;
  setUploading: React.Dispatch<React.SetStateAction<boolean>>;
  setClose?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [currentFile, setCurrentFile] = useState<File>();
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const { id } = useParams();
  const { authHeader } = userStore;
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => await uploadFile())();
    return () => setCurrentFile?.(undefined);
  }, [currentFile]);

  async function uploadFile() {
    if (!currentFile) return;
    props.setUploading(true);
    if (id) {
      (async () => {
        const onUploadProgress = (event: AxiosProgressEvent) => {
          const percentage = Math.round(
            (100 * event.loaded) / (event.total as number)
          );
          setUploadProgress(percentage);
        };
        try {
          if (props.uploadType === "IFC") {
            await uploadIfcFile(onUploadProgress);
          } else if (props.uploadType === "CSV") {
            await uploadCsvFile(onUploadProgress);
          }
          await buildingStore.getAllMaterialResources();
          toast(t("buildingMaterialResources.uploadSucces"), "success");
        } catch (err: unknown) {
          onUploadError(err);
        }
        props.setClose?.(false);
      })();
    }
  }

  function onUploadError(err: unknown) {
    props.setUploading(false);
    console.error(err);
    if (err instanceof Error) {
      toast(err.message, "warning");
    }
  }

  async function uploadCsvFile(
    onUploadProgress: (event: AxiosProgressEvent) => void
  ) {
    if (!id || !currentFile) return;
    await inventoryFileAPI.inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryFile(
      id,
      currentFile,
      props.uploadType ?? undefined,
      {
        ...authHeader,
        onUploadProgress,
      }
    );
  }

  async function uploadIfcFile(
    onUploadProgress: (event: AxiosProgressEvent) => void
  ) {
    if (!id || !currentFile) return;
    const response =
      await inventoryFileAPI.inventoryApiV1RoutersInventoryFileUploadAndProcessInventoryIfcCsvFile(
        id,
        currentFile,
        {
          ...authHeader,
          onUploadProgress,
        }
      );
    ifcMappingStore.updateFileMappingStatus?.(response.data);
  }

  function uploadingView() {
    return (
      <div className="py-4">
        <div className="bg-white flex w-full py-1 items-center justify-center">
          {currentFile?.name ?? ""}
          <CircularProgressBar percent={uploadProgress} />
        </div>
        <div className="mt-4 text-center text-md text-gray-700 h-6">
          {uploadProgress === 100
            ? t("buildingMaterialResources.processing")
            : null}
        </div>
      </div>
    );
  }

  function uploadExcelOrCSV() {
    return (
      <>
        <DownloadModelRequirements uploadType={props.uploadType} />
        <FilesDropZone
          setFile={setCurrentFile}
          icon={props.uploadType === "IFC" ? IFCFileIcon : CSVFileIcon}
          acceptedTypes={
            props.uploadType === "IFC"
              ? [".ifc", ".zip"]
              : [".csv", ".xlsx", ".zip"]
          }
          showAlertForIFC
        />
      </>
    );
  }

  if (props.uploading) return uploadingView();
  return uploadExcelOrCSV();
});
