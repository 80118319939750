import React from "react";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { observer } from "mobx-react-lite";
import { EditInstanceForm } from "features/Audit/handlers/handleInstanceEdit";
import AuditItemFormOnline from "features/AuditOnline/AuditItemFormOnline";
import { handleFieldValueTypes } from "features/Audit/utils";
import retrieveFields from "features/Audit/handlers/retrieveFields";
import { InstanceUpdateIN } from "api-client";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { HandleBlueprintItemFormSubmitArgs } from "features/AuditOnline/models";
import { parseFloorIds } from "features/Audit/handlers/handleInstanceAdd";

const InstanceOnline = () => {
  const { id, audit_id, blueprint_id, instance_id } = useParams();
  const { t } = useTranslation();
  useDisableScrollOnNumberInputs();

  const handleInstanceUpdate = async (
    form: EditInstanceForm,
    args: HandleBlueprintItemFormSubmitArgs
  ) => {
    if (!instance_id) return console.error("instance_id undefined.");
    if (!blueprint_id) return console.error("blueprint_id undefined.");

    const { foundFields } = retrieveFields(
      form,
      args.selectedProductGroup.required_fields,
      args.selectedProductGroup.optional_fields
    );

    const instanceProperties = foundFields.map((found) => {
      const model = {
        field_id: found.field.id,
        value_field_option_ids: [],
      };

      return handleFieldValueTypes<typeof model>(found.value, model);
    });

    let roomId = caOnlineStore.roomSet.find(
      (r) => r.name === form.room_name
    )?.id;

    if (!roomId) {
      roomId = await caOnlineStore.createRoomAndGetId(form.room_name);
    }

    const floorIds = parseFloorIds(form.floor_ids) ?? [];

    const updatedInstance: InstanceUpdateIN = {
      instanceproperty_set: instanceProperties,
      name: form.name ?? "",
      name_de: form.name,
      room_id: roomId,
      blueprint_id: blueprint_id,
      extra_info: form.extra_info,
      floor_ids: floorIds,
      instance_amount: form.instance_amount,
      album_ids: [],
    };

    await caOnlineStore.updateInstance(instance_id, updatedInstance);
  };

  return (
    <AuditItemFormOnline<EditInstanceForm>
      redirect={`/buildings/${id}/audits-online/${audit_id}/blueprints/${blueprint_id}/instances`}
      title={t("audits.editItem")}
      isInstance={true}
      submitText={t("audits.saveItem")}
      images={[]}
      handleSubmit={handleInstanceUpdate}
    />
  );
};

export default observer(InstanceOnline);
