import { SearchIcon } from "@heroicons/react/solid";
import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { SuggestOUT } from "../../api-client";

interface IProps {
  labelName?: string;
  value?: string;
  items?: SuggestOUT[];
  onChange?: (value: string, name?: string) => void;
  onSelect?: (address: SuggestOUT, name?: string) => void;
  placeholder?: string;
  name?: string;
  className?: string;
  errorMessage?: string;
  containerClassName?: string;
  noMargin?: boolean;
  noArrow?: boolean;
  size?: "large" | "middle" | "small";
  testID?: string;
}

const SearchInput = ({
  labelName,
  items,
  value,
  onChange,
  onSelect,
  placeholder,
  name,
  noMargin,
  className,
  containerClassName,
  size = "middle",
  testID,
}: IProps) => {
  const [showItems, setShowItems] = useState<boolean>(false);

  useEffect(() => {
    setShowItems(!!items?.length);
  }, [items]);

  const itemClicked = (item: SuggestOUT) => {
    onSelect && onSelect(item, name);
    setShowItems(false);
  };

  return (
    <div
      className={clsx(
        "mb-4 relative",
        { "!mb-0": noMargin },
        containerClassName
      )}
    >
      {labelName && (
        <label htmlFor="email" className="block text-sm text-gray-700">
          {labelName}
        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm overflow-visible">
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="text"
          name={name}
          value={value}
          className={clsx(
            "focus:border-indigo-500 block w-full pr-10 text-sm border-gray-300 rounded-md",
            className,
            {
              "h-large": size === "large",
              "h-middle": size === "middle",
              "h-small": size === "small",
            }
          )}
          placeholder={placeholder}
          onChange={(e) => onChange && onChange(e.target.value, name)}
          test-id={testID}
        />
      </div>
      {showItems ? (
        <div
          className={clsx(
            "absolute min-h-[40px] w-full max-h-[210px] overflow-y-scroll scrollbar",
            "top-16 rounded-md py-2 bg-white border shadow-md left-0 right-0 visible z-10"
          )}
        >
          {items?.map((item: SuggestOUT, index: number) => (
            <div
              key={index}
              className="px-4 py-1 hover:bg-gray-100 cursor-pointer"
              onClick={() => itemClicked(item)}
            >
              {item.description}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SearchInput;
