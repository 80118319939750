import React, { useEffect } from "react";
import { IconCheck } from "@tabler/icons-react";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { revitStore } from "store/RevitStore";
import { observer } from "mobx-react";
import { useNavigate } from "react-router";
import RevitLayout from "./RevitLayout";

interface RevitProps {
  lang: string;
}

export default observer(function RevitConnectedView({ lang }: RevitProps) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang === "de") {
      i18n.changeLanguage("de");
    }
  }, []);

  const disconnect = () => {
    window.location.href = "concular://reset_revit";
    revitStore.setActiveStep(0);
    revitStore.setConnected(false);
    navigate("/revit-setup");
  };

  return (
    <RevitLayout>
      <div className="flex flex-col items-center p-6 min-h-screen">
        <div className="tex-lg font-semibold mb-8">
          {t("revit.pluginSuccessfullyConnected")}
        </div>
        <IconCheck className="text-green-600 bg-green-100 w-16 h-16 rounded-full p-4 mb-8" />
        <div className="mb-8 text-gray-700 text-xs font-normal">
          {t("revit.closePanelInfo")}
        </div>

        <Button
          type="primary"
          width="fit-content"
          onClick={disconnect}
          size="small"
        >
          {t("revit.connnectToAnotherBuilding")}
        </Button>
        <div className="text-xs font-normal text-gray-500 mt-2">
          {t("revit.youWillDisconnected")}
        </div>
      </div>
    </RevitLayout>
  );
});
