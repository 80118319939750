import { BuildingOUT } from "api-client";
import clsx from "clsx";
import SearchInput from "components/SearchInput";
import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";
import { buildingStore } from "store/Building/BuildingStore";
import BuildingLogo from "./BuildingLogo";
import useFeatureFlag from "hooks/useFeatureFlag";

export default observer(function SortedBuildingList({
  buildings,
  showDetail,
  className,
}: {
  buildings: BuildingOUT[];
  showDetail: (building: BuildingOUT) => void;
  className?: string;
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const { isFeatureVisible } = useFeatureFlag();

  const { currentBuilding } = buildingStore;
  const { t } = useTranslation();

  const sortedBuildinds = buildings
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  const filteredList = searchTerm.length
    ? sortedBuildinds.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : sortedBuildinds;

  const checkCountOfBuildings = () => {
    if (sortedBuildinds.length === 1)
      return `${sortedBuildinds.length} ${t("buildingPassport.building")}`;
    else if (sortedBuildinds.length > 1)
      return `${sortedBuildinds.length} ${t("buildingPassport.buildings")}`;
    else return t("buildingPassport.noBuilding");
  };

  return (
    <div className={className}>
      {isFeatureVisible("urban_mining_hub") ? (
        <div className="border-t border-gray-200 flex justify-center items-center my-2">
          <span className="bg-white -mt-3 px-3">{checkCountOfBuildings()}</span>
        </div>
      ) : null}
      {sortedBuildinds.length > 10 ? (
        <SearchInput
          containerClassName="!mb-3"
          placeholder={t("buildingPassport.searchForBuilding")}
          onChange={(value) => setSearchTerm(value)}
        />
      ) : null}
      {sortedBuildinds.length === 0 ? (
        <div className="text-gray-500 py-1">
          {t("buildingPassport.noBuildingExist")}
        </div>
      ) : null}
      {filteredList.map((building: BuildingOUT) => (
        <div
          key={building.id}
          className={clsx(
            "flex p-2 mb-0.5 items-center cursor-pointe hover:bg-indigo-50 rounded transition cursor-pointer",
            { "bg-indigo-50": currentBuilding?.id === building.id }
          )}
          onClick={() => showDetail(building)}
        >
          <BuildingLogo building={building} />
          <div className="pl-2 whitespace-nowrap font-normal truncate max-w-[180px]">
            {building?.name}
          </div>
        </div>
      ))}
      {searchTerm.length && !filteredList.length ? (
        <div className="text-gray-500 pb-1">
          {t("buildingPassport.noBuildingFound")}
        </div>
      ) : null}
    </div>
  );
});
