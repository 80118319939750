import JSZip from "jszip";
import { db } from "../../../api-client-local/db";
import { LocalImage } from "../../../api-client-local/db_interfaces";
import { AuditOUT } from "../../../api-client";
import { saveAs } from "file-saver";
import React from "react";
import { StatusName } from "./useSyncStatus";

/**
 * Creates a zip file with the current audit and all images
 * @param audit_id the audit to export
 * @param setStatus status update
 */
export async function performExport(
  audit_id: string,
  setStatus: (value: React.SetStateAction<StatusName>) => void
) {
  if (!audit_id) return;
  await handleAuditZip(audit_id, (isExporting) => {
    setStatus(isExporting ? "updating" : "updated");
  });
  setTimeout(() => {
    setStatus("initial");
  }, 2500);
}

/**
 * Export zip file of the audit
 * @param id
 * @param setExporting
 */
export async function handleAuditZip(
  id: string,
  setExporting: (a: boolean) => void
) {
  setExporting(true);
  const zip = new JSZip();
  const imgFolder = zip.folder("images");

  await db.images
    .toArray()
    .then((images: LocalImage[]) => {
      const metadata = images.map((e) => {
        return { ...e, fileName: e.file.name };
      });
      zip.file("images.json", JSON.stringify(metadata));

      images.forEach(async (image) => {
        if (image.sourceType != "blueprint" && image.sourceType != "instance") {
          console.log("Unsupported local image source type");
          return;
        }

        if (imgFolder) {
          imgFolder.file(image.file.name, image.file, { base64: true });
        }
      });
    })
    .catch((err) => console.log(err));

  const audit = (await db.audits.get(id)) as AuditOUT;
  zip.file("audit.json", JSON.stringify(audit));

  zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, `audit-${id}.zip`);
    setExporting(false);
  });
}
