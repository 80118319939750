import Modal from "components/Modal";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DGNBStepper } from "./DGNBStepper";
import { DGNBStore } from "store/DGNBStore";
import clsx from "clsx";

export type DGNBModalPropTypes = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const DGNBModal = (props: DGNBModalPropTypes) => {
  const { t } = useTranslation();
  const [titleHidden, setTitleHidden] = useState(false);
  const title = titleHidden ? null : t("DGNB.dngb_modal_heading");
  const dgnbStore = DGNBStore.getInstance();

  useEffect(() => {
    dgnbStore.fetchLatestDgnbValues();
    dgnbStore.setActiveStep(0);
  }, []);

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.setOpen}
      closeButton
      title={title}
      disableOutsideClick
      className="max-h-[80vh]"
      containerClassName={clsx(
        "md:min-w-[50rem]",
        "sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl"
      )}
    >
      <DGNBStepper
        openModal={props.open}
        setOpenModal={props.setOpen}
        setTitleHidden={setTitleHidden}
      />
    </Modal>
  );
};

export default DGNBModal;
