import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "languages/i18n";
import ImageResizer from "features/Audit/ImageResizer";
import Button from "components/Button";
import { ArrowsExpandIcon } from "@heroicons/react/solid";
import ImageViewer from "react-simple-image-viewer";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";

interface BuildingImage {
  id: string;
  image: string;
}

export default observer(function BuildingDataTile() {
  const { t } = useTranslation();
  const { currentBuilding } = buildingStore;
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const closeImageViewer = useCallback(() => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  }, []);

  const handleOpenImageViewer = useCallback(() => openImageViewer(0), []);
  const openImageViewer = useCallback((index: number) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  return (
    <div
      className={clsx(
        "bg-white pl-4 rounded-lg flex justify-between md:h-[175px] mb-4",
        "overflow-hidden border border-gray-300 col-span-3"
      )}
      test-id="buildingdata_lcp"
    >
      <div className="py-4 w-full">
        <div className="font-semibold text-gray-900">
          {t("buildingPassport.buildingData")}
        </div>
        <div className="text-xs pr-4">
          <div className="py-3 border-b">
            <div className="text-gray-600">
              {t("buildingPassport.buildingType")}
            </div>
            <div className="text-gray-500">
              {i18n.language === "de"
                ? currentBuilding?.building_type?.name_de
                : currentBuilding?.building_type?.name ?? "--"}
            </div>
          </div>
          <div className="pt-3 md:flex">
            <div>
              <div className="text-gray-600">
                {t("buildingPassport.grossFloorArea")}
              </div>
              <div className="text-gray-500">
                {currentBuilding?.gross_floor_area
                  ? `${currentBuilding?.gross_floor_area}m²`
                  : "--"}
              </div>
            </div>
            <div className="md:ml-7">
              <div className="text-gray-600">
                {t("buildingPassport.netRoomArea")}
              </div>
              <div className="text-gray-500">
                {currentBuilding?.net_floor_area
                  ? `${currentBuilding?.net_floor_area}m²`
                  : "--"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {currentBuilding?.images && currentBuilding?.images.length > 0 && (
        <div onClick={handleOpenImageViewer} className="h-full w-full relative">
          <ImageResizer
            width="150"
            height="150"
            src={currentBuilding?.images[0]?.image ?? ""}
            className={
              "!w-full object-cover !h-full cursor-pointer !rounded-tl-none !rounded-bl-none"
            }
            alt=""
          />
          <Button
            type="secondary"
            width="fit-content"
            leadingIcon={<ArrowsExpandIcon />}
            className={"absolute left-3 bottom-3 opacity-80"}
            onClick={handleOpenImageViewer}
          >
            {t("buildingPassport.images")}
          </Button>
        </div>
      )}
      {isViewerOpen && currentBuilding?.images && (
        <div className="z-30">
          <ImageViewer
            src={currentBuilding?.images.map(
              (item: BuildingImage) => item.image + "?width=500&height=500"
            )}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
          />
        </div>
      )}
    </div>
  );
});
