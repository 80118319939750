import React from "react";
import PingAnimation from "components/PingAnimation";

export default function InitialLoading() {
  return (
    <div className="min-h-screen justify-center flex items-center">
      <PingAnimation size="large" color="gray" />
    </div>
  );
}
