import { DGNBPassportIN, dgnbApi } from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";

export default async function handleDgnbPassportGetLatest(
  buildingId: string
): Promise<DGNBPassportIN> {
  const localAuthHeader = await getLocalAuthHeader();

  try {
    const response = await dgnbApi.reportApiV1RoutersDgnbPassportLatest(
      buildingId,
      localAuthHeader
    );
    return response.data[0]?.input_data;
  } catch (e) {
    console.error("Failed to fetch latest DGNB Passport", e, buildingId);
  }
  return {} as DGNBPassportIN;
}
