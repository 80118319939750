import React from "react";
import { useTranslation } from "react-i18next";
import { certExportStore } from "store/CertExportStore";

const ChooseDGNBVersion = () => {
  const { t } = useTranslation();

  const handleClick = (value: string) => {
    certExportStore.setCertExportFieldAndValue("dgnbVersion", value);
    if (value.includes("2023")) {
      certExportStore.setCertAndConformityId("DGNB 2023");
      certExportStore.setActiveStep(certExportStore.activeStep + 2);
    } else {
      certExportStore.setCertAndConformityId("DGNB 2018");
      certExportStore.setActiveStep(certExportStore.activeStep + 1);
    }
  };

  return (
    <>
      <div className="py-3 pb-0 flex items-center text-center">
        <span
          onClick={() => handleClick("Version 2018")}
          className={`hover:bg-gray-50 cursor-pointer text-gray-700 p-2 border border-gray-300 rounded-md w-[45%]`}
        >
          Version 2018
        </span>
        <span className="w-[10%] text-gray-400">{t("commons.or")}</span>
        <span
          onClick={() => handleClick("Version 2023")}
          className="hover:bg-gray-50 cursor-pointer text-gray-700 p-2 border border-gray-300 rounded-md w-[45%]"
        >
          Version 2023
        </span>
      </div>
    </>
  );
};

export default ChooseDGNBVersion;
