import React from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import Button from "components/Button";
import { PlusSmIcon } from "@heroicons/react/outline";
import Dataset from "./Dataset";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";
import ButtonGroups, { BGItem } from "components/ButtonGroups";
import { REFERENCE_UNITS } from "features/MappingTools/consts";

export default observer(function EPDdataStep() {
  const { t } = useTranslation();

  React.useEffect(() => {
    buildingProductStore.checkSaveValidity();
  }, []);

  buildingProductStore.checkStepValidity(2);

  const onChangeReferenceUnit = (value: BGItem | undefined) => {
    if (buildingProductStore.anyFactorAdded()) {
      buildingProductStore.resetFactors();
    }
    buildingProductStore.onChangeData("reference_unit", value);
  };

  return (
    <>
      <div className="text-lg font-semibold mb-2">
        {t("mapping.referenceUnitAndEnvironmentalData")}
      </div>
      <div className="text-sm font-normal text-gray-700 mb-6">
        {t("productEditor.epdDataDescription")}
      </div>
      <div className="bg-white border-gray-300 border p-6 rounded-md mb-6">
        <ButtonGroups
          items={REFERENCE_UNITS.filter((item) => item.id !== "KG")}
          onChange={onChangeReferenceUnit}
          current={REFERENCE_UNITS.find(
            (item) => item.id === buildingProductStore.data.reference_unit?.id
          )}
          containerClassName="w-fit"
          label={`${t("components.reference_unit")}*`}
          confirmTitle={t("productEditor.confirmModalTitle")}
          confirmButtonTitle={t("productEditor.changeReferenceUnit")}
          confirmDescriptionMesage={t("productEditor.changeReferenceUnitDesc")}
          hasConfirmation={buildingProductStore.anyFactorAdded()}
        />
        <div className="text-gray-500 text-sm mt-2">
          {t("components.reference_unit_desc")}
        </div>
      </div>
      {buildingProductStore.data.groups?.map((_, index) => (
        <Dataset key={index} datasetIndex={index} />
      ))}
      <div className="p-6 text-center w-auto flex flex-col items-center">
        <Button
          type="secondary"
          leadingIcon={<PlusSmIcon />}
          width="fit-content"
          className="max-w-fit justify-center items-center"
          onClick={() => buildingProductStore.addDataset()}
          disable={buildingProductStore.thirdStepValidionErrors}
        >
          {t("productEditor.addDataset")}
        </Button>
        <div className="text-gray-400 mt-4 text-sm max-w-md text-left">
          {t("productEditor.datasetDescription")}
        </div>
      </div>
    </>
  );
});
