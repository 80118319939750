import axios, { AxiosRequestConfig } from "axios";
import { AuditIN, AuditOUT, auditsApi, imagesApi } from "../../../api-client";
import { db } from "../../../api-client-local/db";
import { LocalImage } from "../../../api-client-local/db_interfaces";
import { ErrorLocation, getLocalAudit, handleAuditUpdate } from "./lib";
import { getLocalAuthHeader } from "api-client-local/utils";
import { setLocalAudit } from "api-client-local/audits";

export interface PerformSyncProps {
  setStatus: (message: string) => void;
  setCurrentAudit: (value: AuditOUT) => void;
  audit_id: string | undefined;
  setMissingFields: (value: ErrorLocation[]) => void;
  error?: unknown;
}

export interface S3PresignedPost {
  url: string;
  fields: Record<string, string>;
}

/**
 * Do the sync
 * @param props
 */
export async function performSync(props: PerformSyncProps) {
  const { audit_id, setStatus, setCurrentAudit } = props;
  if (!audit_id) {
    throw Error(`audit_id not found for sync.`);
  }

  if (!window.navigator.onLine) {
    setStatus("noNetwork");
    return setTimeout(() => {
      setStatus("initial");
    }, 2500);
  }
  const foundAudit = await getLocalAudit(audit_id);
  const authHeader = await getLocalAuthHeader();
  const limitTo = (await getLimitTo(foundAudit)) ?? [];
  const audit_in: AuditIN = {
    id: foundAudit.id,
    blueprint_set: foundAudit.blueprint_set,
    limit_to: limitTo,
  };

  console.log("Limiting updates to: " + limitTo);
  await handleAuditUpdate(audit_in, authHeader);
  setStatus("uploadingImages");
  await handleImageUploads(authHeader);
  setStatus("receivingUpdates");
  const auditWithImagesResponse = await auditsApi.caApiV1RoutersAuditGetAudit(
    audit_id,
    authHeader
  );

  if (auditWithImagesResponse.data) {
    await deleteSyncedBlueprintIds(foundAudit);
    await setLocalAudit(auditWithImagesResponse.data);
    setCurrentAudit(auditWithImagesResponse.data);
  } else {
    setStatus("error");
  }
}

/**
 * To identify the items that were modified by the user
 * and must be saved by the backend.
 * So we are checking if the current audit's blueprint_set has any id in the local Dexie db (blueprintIds table) then push that id to limit_to
 * @param audit the current audit that user is in
 * @returns limitTo as string[] containing list of blueprint ids that were modified
 */
async function getLimitTo(audit: AuditOUT) {
  const blueprintSet = audit.blueprint_set.filter(
    (bp) => bp.audit_id === audit.id
  );
  try {
    const blueprints = blueprintSet?.map((item) => item);
    const limitTo: string[] = [];
    await db.transaction("rw", db.blueprintIds, async () => {
      for (const blueprint of blueprints) {
        const foundID = await db.blueprintIds
          .where("blueprintId")
          .equals(blueprint.id)
          .first();

        if (foundID) {
          limitTo.push(foundID.blueprintId);
        }

        // check if id belongs to the component
        if (blueprint.components) {
          for (const component of blueprint.components) {
            const compFoundID = await db.blueprintIds
              .where("blueprintId")
              .equals(component.id)
              .first();
            if (compFoundID) {
              limitTo.push(compFoundID.blueprintId);
            }
          }
        }
      }
    });
    return limitTo;
  } catch (err) {
    console.error("(Dexie DB) Error - getLimitTo(): ", err);
  }
}

/**
 * If the records are synced successfully, we need to remove that
 * particular blueprint_Id from blueprintIds table in local Dexie DB
 * @param audit the current audit that user is in
 */
async function deleteSyncedBlueprintIds(audit: AuditOUT) {
  const blueprintSet = audit.blueprint_set.filter(
    (bp) => bp.audit_id === audit.id
  );
  try {
    const blueprints = blueprintSet?.map((item) => item);

    await db.transaction("rw", db.blueprintIds, async () => {
      for (const blueprint of blueprints) {
        await db.blueprintIds
          .where("blueprintId")
          .equals(blueprint.id)
          .delete();

        // check if id belongs to the component
        if (blueprint.components) {
          for (const component of blueprint.components) {
            await db.blueprintIds
              .where("blueprintId")
              .equals(component.id)
              .delete();
          }
        }
      }
    });
  } catch (err) {
    console.error("(Dexie DB) Error removing records", err);
  }
}

/**
 * Rename files if it's too long. Needed because upload key can become longer than 100 chars
 * @param file
 */
export const renameFileIfLong = (file: File) => {
  if (file.name.length > 10) {
    const nameSplit = file.name.split(".");
    let filename = nameSplit[0];
    const extension = nameSplit[1];
    filename = filename.substring(0, 10);
    const newFileName = `${filename}.${extension}`;
    const renamedFile = new File([file], newFileName, { type: file.type });
    console.log("Renamed file: ", renamedFile.name, renamedFile.name.length);
    return renamedFile;
  }

  return file;
};

/**
 * Upload all images
 * @param authHeader
 */
export async function handleImageUploads(authHeader: unknown) {
  const images: LocalImage[] = await db.images.toArray();
  // Use Promise.all to wait for all performImageUpload calls to complete.
  await Promise.all(
    images.map(async (image) => {
      try {
        await performImageUpload(image, authHeader);
      } catch (error) {
        console.log("IMG Upload error: ", error);
      }
    })
  );
}

async function registerAlbumImages(
  image: LocalImage,
  filename: string,
  authHeader: unknown
) {
  const registeredAlbum =
    await imagesApi.caApiV1RoutersImagesRegisterImageToBlueprint(
      image.sourceId,
      filename,
      image.order,
      authHeader as AxiosRequestConfig<unknown>
    );
  return registeredAlbum.data;
}

export async function performImageUpload(
  image: LocalImage,
  authHeader: unknown
) {
  if (image.sourceType != "blueprint" && image.sourceType != "instance") {
    console.log("Unsupported local image source type");
    return;
  }
  const renamedFile = renameFileIfLong(image.file);
  const registerBlueprintImage = await registerAlbumImages(
    image,
    renamedFile.name,
    authHeader
  );

  const uploadS3Response = await uploadToS3(
    registerBlueprintImage.upload_url_config as S3PresignedPost,
    image.file
  );
  if (uploadS3Response) {
    await db.images.delete(image.id);
  }
  console.log(`Uploaded image - ${uploadS3Response}`);
}

export async function uploadToS3(
  uploadUrlConfig: S3PresignedPost,
  file: string | Blob
) {
  const { url, fields } = uploadUrlConfig;
  const payload = new FormData();

  Object.entries(fields).forEach(([key, val]) => {
    payload.append(key, val as string);
  });

  payload.append("file", file);
  await axios.post(url, payload);
  return `${url}${fields["key"]}`;
}
