import React, { useEffect } from "react";
import { MaterialFileObject } from "store/IfcMapping/types";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";
import DetailsView from "./DetailsView";
import { ifcMappingStore } from "store/IfcMapping/IFCMappingStore";
import { SingleItem } from "api-client";

export default observer(function MEObjectDetail({
  open,
  close,
  object,
  DMEObject,
  refresh,
  isDynamicME,
}: {
  open: boolean;
  close: () => void;
  object?: MaterialFileObject;
  DMEObject?: SingleItem;
  refresh?: (item: MaterialFileObject) => Promise<void> | void;
  isDynamicME?: boolean;
}) {
  const { ifc_id } = useParams();

  useEffect(() => {
    fetchData();
  }, [object, open]);

  async function fetchData() {
    if (!open || !object?.id) return;
    await ifcMappingStore.getObjectDetails(object.id);
  }

  const unmatch = async () => {
    if (!ifc_id || !object?.id) return;
    const response = await ifcMappingStore.matchObject(ifc_id, object.id);
    const returnedData = response?.[0] as MaterialFileObject;
    if (returnedData.product_match) return;
    await ifcMappingStore.getObjectDetails(object.id);
    if (object.total_objects) {
      object.products = [];
    } else {
      object.product_match = null;
    }
    await refresh?.(object);
  };

  const refreshAfterMatching = async (item: MaterialFileObject) => {
    if (!object?.id) return;
    await ifcMappingStore.getObjectDetails(object.id);
    if (item.product_match) {
      if (object?.total_objects) {
        object.products = [item.product_match];
      } else {
        object.product_match = item.product_match;
        object.gwp = item.gwp;
        object.mass = item.mass;
      }
      await refresh?.(object);
    }
  };

  return (
    <DetailsView
      open={open}
      close={close}
      object={object}
      DMEObject={DMEObject}
      unmatch={unmatch}
      refreshAfterMatching={refreshAfterMatching}
      isDynamicME={isDynamicME}
    />
  );
});
