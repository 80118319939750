import clsx from "clsx";
import React from "react";
import useOutsideClick from "hooks/useOutsideClick";
import {
  CheckCircleIcon,
  ChevronDownIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import InputLabel from "components/InputLabel";
import { useTranslation } from "react-i18next";

export interface MultiSelectOption {
  id?: string | number;
  name: string;
  name_de?: string;
}

interface MultiSelectProps extends React.HTMLProps<HTMLInputElement> {
  options: MultiSelectOption[];
  labelName?: string;
  enforceRequired?: boolean;
  defaultValues?: string[];
  containerClassName?: string;
  isFloorSet?: boolean;
}

export default function MultiSelect(props: MultiSelectProps) {
  const ref = React.useRef(null);
  const { i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const containerRef = React.useRef(null);
  const optionsRef = React.useRef(null);
  const [showOptions, setShowOptions] = React.useState(false);
  const [values, setValues] = React.useState<string[]>([]);
  const [showError, setShowError] = React.useState(false);

  React.useEffect(() => {
    if (props.defaultValues) {
      setValues(props.defaultValues);
    }
    if (!props.isFloorSet) {
      [...props.options].sort((a, b) =>
        (isEng ? a.name : a.name_de ?? a.name).localeCompare(
          isEng ? b.name : b.name_de ?? b.name
        )
      );
    }
  }, [props.defaultValues]);

  function handleValue(e: React.MouseEvent) {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("data-option-value");
    if (!id) return;
    setValues((prev: string[]) => {
      let copy = [...prev];
      const foundIndex = copy.findIndex((value) => value === id);
      if (foundIndex !== -1) {
        copy[foundIndex] = "";
      } else {
        copy = [...copy, id];
      }
      return copy.filter((el) => el !== "");
    });
  }

  useOutsideClick(optionsRef, () => {
    setShowOptions(false);
  });

  return (
    <div
      ref={containerRef}
      style={props.style}
      className={`${
        props.containerClassName ?? ""
      } mb-4 relative max-w-[calc(100vw-32px)]`}
    >
      {props.labelName && (
        <InputLabel
          showError={showError}
          required={props.required}
          labelName={props.labelName}
          name={props.name as string}
        />
      )}
      <input
        onInvalid={() => setShowError(true)}
        data-invalid="false"
        required={props.required && props.enforceRequired !== false}
        type="text"
        className="opacity-0 pointer-events-none h-1 w-1 absolute"
        name={props.name}
        ref={ref}
        defaultValue={values.join()}
      />
      <div
        onClick={(e) => {
          setShowError(false);
          if (e.target === e.currentTarget) {
            setShowOptions(true);
          }
        }}
        className={clsx(
          "w-full py-1 items-center flex overflow-x-scroll pl-3 !h-12 pr-10 mt-0.5 text-base bg-white border border-gray-300",
          "rounded-md focus:outline-none focus:border-indigo-500 sm:text-sm h-middle overflow-hidden",
          { "border-red-700": showError },
          props.className
        )}
      >
        {values &&
          values.length > 0 &&
          (
            values.map((v) =>
              props.options.find((opt) => opt.id === v)
            ) as MultiSelectOption[]
          ).map((found, i) => {
            if (!found) return <></>;
            let nameString = isEng
              ? found?.name ?? ""
              : found?.name_de ?? found.name ?? "";
            nameString =
              nameString ?? found?.name ?? found?.name_de ?? found?.name;

            return (
              <div key={i}>
                {nameString && nameString.length > 0 && (
                  <span className="px-2 py-[2px] flex items-center mr-2 bg-indigo-200 whitespace-nowrap text-indigo-600 rounded-md text-sm sm:text-xs">
                    <XCircleIcon
                      onClick={handleValue}
                      data-option-value={found?.id}
                      className="h-3 cursor-pointer w-3 mr-1"
                    />
                    {nameString}
                  </span>
                )}
              </div>
            );
          })}
        <div className="h-6 w-6 text-gray-500 bg-white rounded-full cursor-pointer absolute right-2">
          <ChevronDownIcon
            onClick={() => setShowOptions(true)}
            className="p-1"
          />
        </div>
        <div
          ref={optionsRef}
          className={`${
            showOptions ? "" : "hidden"
          } bg-white w-full border shadow-sm absolute sm:text-sm text-base left-0 mt-1 rounded-md py-2 top-14 overflow-y-scroll max-h-36 z-10`}
        >
          {props.options.map((option, i) => {
            const isAdded = values.includes(option.id as string);
            let nameString = isEng ? option.name : option.name_de;
            nameString = nameString ?? option.name ?? option.name_de;
            return (
              <div
                key={i}
                onClick={handleValue}
                className={`py-1 ${
                  isAdded ? "" : "text-gray-400"
                } flex items-center cursor-pointer px-2 text-sm hover:bg-gray-100`}
                data-option-value={option.id as string}
              >
                <div className="h-4 w-4 mr-2">
                  {isAdded && (
                    <CheckCircleIcon className="h-full text-indigo-400" />
                  )}
                </div>
                <span>{nameString}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
