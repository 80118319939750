import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import { InstanceOUT } from "api-client";
import { useTranslation } from "react-i18next";
import {
  EyeIcon,
  EyeOffIcon,
  PencilIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import BlueprintOverview from "features/Audit/blueprintOverview/BlueprintOverview";
import useAuditInstanceActions from "hooks/useAuditInstanceActions";
import FormLoadingContainer from "features/Audit/FormLoadingContainer";
import useBlueprint from "hooks/useBlueprint";
import ItemComponentList from "features/Audit/ItemComponentList";
import PingAnimation from "components/PingAnimation";
import SearchSortTable from "components/Table";
import { ColumnDef } from "@tanstack/react-table";
import { useRecoilValue } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import EditDeleteItemMenu from "components/ActionMenu/EditDeleteItemMenu";
import { DataFetcherStrip } from "./BlueprintAdd";
import { LoadingView } from "features/Audit/LoadingView";
import { unitPower } from "utils";
import useManufacturerAndProductGroups from "./BlueprintAdd/useManufacturerAndProductGroups";

export default function BlueprintDetail() {
  const { id, audit_id, blueprint_id } = useParams();
  const { t } = useTranslation();
  const { selectedBlueprint, selectedProductGroup, isComponent } =
    useBlueprint(blueprint_id);
  const [formLoading, setFormLoading] = React.useState<string>("");
  const [overviewHidden, setOverviewHidden] = React.useState(false);

  const blueprintRoute = `/buildings/${id}/audits/${audit_id}/blueprints/${blueprint_id}`;

  const { loading, fetchDataAsync } = useManufacturerAndProductGroups();

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const scrollToTop = () => scrollRef.current?.scrollTo({ top: 0 });

  React.useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      {loading !== "" ? (
        <div className="items-center justify-center flex h-[50vh]">
          <LoadingView message={loading} />
        </div>
      ) : selectedBlueprint ? (
        <div ref={scrollRef} className="mb-20">
          <FormLoadingContainer formLoading={formLoading} />

          <div className="flex my-4 font-semibold">
            <button
              title={t("audits.showDetails")}
              onClick={() => setOverviewHidden(!overviewHidden)}
              className="text-gray-500 mr-2 h-7 flex items-center bg-gray-200 rounded-md px-2"
            >
              {overviewHidden ? (
                <EyeIcon width={18} />
              ) : (
                <EyeOffIcon width={18} />
              )}
              <span className="ml-2 text-xs">{t("audits.showDetails")}</span>
            </button>
            <Link
              className="text-xs px-2 h-7 flex items-center bg-indigo-100 rounded-md text-indigo-700 enabled:hover:bg-indigo-200"
              to={blueprintRoute}
            >
              <PencilIcon width={14} />
              <span className="ml-2 text-xs">{t("audits.editBlueprint")}</span>
            </Link>
          </div>

          <h1 className="text-base font-semibold">{selectedBlueprint.name}</h1>

          {!isComponent && <InstanceListArea setFormLoading={setFormLoading} />}

          {selectedProductGroup && (
            <BlueprintOverview
              selectedBlueprint={selectedBlueprint}
              selectedProductGroup={selectedProductGroup}
              className={overviewHidden ? "hidden" : ""}
            />
          )}

          <ItemComponentList />
          {!isComponent && (
            <DataFetcherStrip onDataFetching={() => fetchDataAsync(false)} />
          )}
        </div>
      ) : (
        <PingAnimation
          className="min-h-[50vh] flex flex-col justify-center"
          text={t("audits.loadingBlueprint")}
        />
      )}
    </>
  );
}

function InstanceListArea(props: {
  setFormLoading: React.Dispatch<React.SetStateAction<string>>;
}) {
  const navigate = useNavigate();
  const { id, audit_id, blueprint_id } = useParams();
  const { selectedBlueprint, selectedProductGroup } =
    useBlueprint(blueprint_id);

  const [selectedItems, setSelectedItems] = React.useState<string[]>([]);
  const [renderedItems, setRenderedItems] = React.useState<InstanceOUT[]>([]);
  const { t } = useTranslation();

  const { handleDelete, handleDuplicate, handleMultiSelect } =
    useAuditInstanceActions(
      selectedItems,
      setSelectedItems,
      setRenderedItems,
      props.setFormLoading
    );

  React.useEffect(() => {
    if (!selectedBlueprint) return;
    setRenderedItems(selectedBlueprint.instance_set);
  }, [selectedBlueprint]);

  const blueprintRoute = `/buildings/${id}/audits/${audit_id}/blueprints/${blueprint_id}`;
  const addItemRoute = `${blueprintRoute}/add`;
  const audit = useRecoilValue(currentAuditAtom);

  function shouldShowOnTop(index: number) {
    if (renderedItems.length > 2)
      return (
        index === renderedItems.length - 1 || index === renderedItems.length - 2
      );
    return false;
  }

  const useColumns = () => {
    const columns = React.useMemo<ColumnDef<InstanceOUT>[]>(
      () => [
        {
          accessorFn: (row) => row.name,
          id: "instanceName",
          cell: (info) => (
            <div
              className="cursor-pointer"
              onClick={() => navigate(info.row.original.id)}
            >
              {info.row.original.name ?? info.row.original.name_de}
            </div>
          ),
          header: () => <div>{t("audits.instanceTableHeadings.name")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
          size: 250,
        },
        {
          accessorFn: (row) => row.floor_ids,
          id: "floor",
          cell: (info) => {
            const floorIds = info.row.original.floor_ids ?? [];
            const filteredFloors = audit?.floor_set.filter((floor) =>
              floorIds.includes(floor.id)
            );
            if (!filteredFloors || filteredFloors.length === 0) {
              return <>-</>;
            }
            return (
              <div>
                {filteredFloors
                  .sort((a, b) => a.level - b.level)
                  .map((floor) => floor.name)
                  .join(", ")}
              </div>
            );
          },
          header: () => <div>{t("audits.instanceTableHeadings.floor")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.room_name,
          id: "room",
          cell: (info) => <div>{info.row.original.room_name}</div>,
          header: () => <div>{t("audits.instanceTableHeadings.room")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row.instance_amount,
          id: "amount",
          cell: (info) => (
            <div>
              {`${info.row.original.instance_amount} `}
              {unitPower(selectedProductGroup?.unit ?? "")}
            </div>
          ),
          header: () => <div>{t("audits.instanceTableHeadings.amount")}</div>,
          enableColumnFilter: false,
          enableSorting: true,
        },
        {
          accessorFn: (row) => row,
          id: "editDeleteActions",
          header: () => <div>{""}</div>,
          cell: (info) => (
            <EditDeleteItemMenu
              direction={shouldShowOnTop(info.row.index) ? "top" : "bottom"}
              withMultiSelect
              withDuplicate
              id={info.row.original.id}
              onDuplicate={handleDuplicate}
              onMultiSelect={handleMultiSelect}
              onDelete={handleDelete}
            />
          ),
          enableColumnFilter: false,
          enableSorting: false,
          size: 50,
        },
      ],
      []
    );
    return columns;
  };

  return (
    <div className="my-4">
      <div>
        {renderedItems && selectedProductGroup && renderedItems.length > 0 && (
          <div className="pb-5 pt-5">
            <div className="font-semibold mb-6 text-lg">
              {t("audits.items")}
            </div>
            <SearchSortTable
              scrollDivClassName="!max-h-[320px] !z-10"
              data={renderedItems}
              getColumns={useColumns as () => ColumnDef<unknown, unknown>[]}
              borderedStyle
              zebraStriped
            />
          </div>
        )}

        {renderedItems.length === 0 && (
          <div className="flex justify-between items-center">
            <span className="text-sm text-gray-500">
              {t("audits.noItemsCreated")}
            </span>
            <Link to={addItemRoute}>
              <Button leadingIcon={<PlusIcon />} disabledClasses="w-full">
                {t("audits.addItems")}
              </Button>
            </Link>
          </div>
        )}

        {renderedItems.length > 0 && (
          <Link to={addItemRoute} className="w-fit z-10 md:block mt-2 bottom-4">
            <Button
              leadingIcon={<PlusIcon />}
              disabledClasses="w-full"
              className="!rounded-full"
            >
              {t("audits.addItems")}
            </Button>
          </Link>
        )}

        {selectedItems.length > 0 && (
          <div className="flex justify-between mt-2">
            <Button
              onClick={async () =>
                await Promise.all(selectedItems.map(handleDelete))
              }
              type="danger"
              className="ml-auto"
              disabledClasses="w-full"
            >
              {t("audits.delete")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
