import React, { useEffect, useState } from "react";
import { ColumnDef, Row } from "@tanstack/react-table";
import SearchSortTable from "components/Table";
import ProductSearch from "../ProductSearch";
import { useParams } from "react-router";
import useObjectsTableColumns from "./useObjectsTableColumns";
import ChildTable from "./ChildTable";
import useObjectsTable from "./useObjectsTable";
import { observer } from "mobx-react-lite";
import { mappingEditorStore } from "store/IfcMapping/MEStore";
import MEObjectDetail from "../ObjectDetailsSlideOver";
import clsx from "clsx";
import { searchStore } from "store/IfcMapping/SearchStore";
import { SEARCH_TABS_LIST } from "../consts";
import { useMappingTools } from "../useMappingTools";
import { MaterialFileObject } from "store/IfcMapping/types";

export interface CurrentRow {
  parent: Row<unknown> | undefined;
  row: Row<unknown> | undefined;
}

const DEFAULT_SORT = [
  {
    id: "material",
    desc: false,
  },
];

const ObjectsTable = observer(() => {
  const [openDetails, setOpenDetails] = useState<boolean>(false);
  const { ifc_id } = useParams();
  const [firstLoad, setFirstLoad] = useState(true);
  const { groupByOptions } = useMappingTools();
  const {
    updateMappingStatus,
    unmatch,
    updateCategoryMappingStatus,
    getAllFiltersItems,
    filtersLoading,
    storeColumnSizes,
    getStoredColumnSizes,
  } = useObjectsTable();

  async function refreshData() {
    await getData(0, 25, true);
  }

  useEffect(() => {
    (async () => {
      await refreshData();
    })();
    mappingEditorStore.setAutoMappLoading(false);
    mappingEditorStore.setUndoMappingLoading(false);
  }, [
    mappingEditorStore.groupBy,
    mappingEditorStore.showAllObjects,
    mappingEditorStore.resetTable,
  ]);

  useEffect(() => {
    if (firstLoad) return;
    (async () => await refreshData())();
  }, [
    mappingEditorStore.columnsFilter,
    mappingEditorStore.mappingStatusSelected,
    mappingEditorStore.sorting,
  ]);

  useEffect(() => {
    (async () => await getAllFiltersItems())();
    setFirstLoad(true);
    return () => reset();

    function reset() {
      mappingEditorStore.setObjectsData({
        items: [],
        count: 0,
      });
      setFirstLoad(true);
      mappingEditorStore.setGroupBy([String(groupByOptions[2].id)]);
    }
  }, []);

  const useGetParentColumns = () => {
    return useObjectsTableColumns(true, setOpenDetails, openSearch, unmatch);
  };

  const openSearch = () => {
    searchStore.setSelectedTab(SEARCH_TABS_LIST[0]);
    searchStore.setOpenProductSearch(true);
  };

  const getData = async (start: number, size: number, update?: boolean) => {
    await mappingEditorStore.fetchObjects(start, size, update, ifc_id);
    setFirstLoad(false);
  };

  const renderSubComponent = ({ row }: { row: Row<unknown> }) => {
    return <ChildTable parentRow={row} setOpenDetails={setOpenDetails} />;
  };

  if (filtersLoading) return <></>;
  return (
    <>
      <SearchSortTable
        customContainerStyle="m-0 p-0"
        scrollDivClassName={clsx(
          "mt-0 border-b-none border-t-none border-l-0 border-r-0 !max-h-[calc(100vh-450px)]",
          "sm:!max-h-[calc(100vh-250px)]"
        )}
        headerColumnStyle="border-l first:border-l-0 z-3"
        headerRowStyle="[&>*:nth-child(2)]:!border-l-0"
        headerDivStyle="border-b-0"
        headerContainerStyle="z-3"
        customRowStyle={mappingEditorStore.borderStyle(2)}
        customColumnStyle="first:pl-3 last:border-l !py-1"
        getColumns={useGetParentColumns as () => ColumnDef<unknown, unknown>[]}
        fetchData={getData}
        data={mappingEditorStore.objectsData?.items}
        skeletonLoading={firstLoad}
        dataFetchLoading={mappingEditorStore.dataFetchLoading}
        getRowCanExpand={() => true}
        renderSubComponent={renderSubComponent}
        hiddenColumns={mappingEditorStore.getHiddenColumns()}
        totalDBRowCount={mappingEditorStore.objectsData?.count}
        fullHeightSkeleton
        onSortChanged={(state) => mappingEditorStore.setSorting(state)}
        defaultSort={DEFAULT_SORT}
        enableColumnResizing
        onSizeChanged={storeColumnSizes}
        columnSizingState={getStoredColumnSizes()}
      />
      <MEObjectDetail
        open={openDetails}
        close={() => setOpenDetails(false)}
        refresh={updateCategoryMappingStatus}
        object={
          mappingEditorStore.currentRow.row?.original as MaterialFileObject
        }
      />

      {searchStore.openProductSearch && (
        <ProductSearch
          open={searchStore.openProductSearch}
          close={() => searchStore.setOpenProductSearch(false)}
          refresh={updateMappingStatus}
        />
      )}
    </>
  );
});

export default ObjectsTable;
