import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ShareBuilding from "../ShareBuilding/index";
import Button from "components/Button";
import { UserAddIcon } from "@heroicons/react/solid";
import { useLocation } from "react-router";
import BuildingHeaderSettings from "./Settings";
import Export from "./ExportMenu";
import { observer } from "mobx-react-lite";
import { buildingStore } from "store/Building/BuildingStore";

export default observer(function BuildingHeaderButtons() {
  const { isSharedBuilding } = buildingStore;
  const { currentBuilding } = buildingStore;
  const [openShare, setOpenShare] = useState(false);

  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <div className="flex items-center">
      {currentBuilding && !isSharedBuilding && !pathname.includes("audits") && (
        <Button
          type="secondary"
          width="fit-content"
          leadingIcon={<UserAddIcon aria-hidden="true" />}
          className="mr-3 hidden sm:flex py-4"
          size="small"
          onClick={() => setOpenShare(true)}
          testID="sharebuilding_button"
        >
          {t("buildingPassport.shareBuildingButton")}
        </Button>
      )}
      {currentBuilding && !pathname.includes("audits") && <Export />}
      {currentBuilding && (
        <BuildingHeaderSettings setOpenShare={setOpenShare} />
      )}
      {openShare && (
        <ShareBuilding openShare={openShare} setOpenShare={setOpenShare} />
      )}
    </div>
  );
});
