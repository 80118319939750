import React from "react";
import ButtonAsDiv from "components/ButtonAsDiv";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";

export function BackButton() {
  const { step } = blueprintCreateStore;
  const { t } = useTranslation();
  return (
    <ButtonAsDiv
      color="secondary"
      onClick={() => {
        blueprintCreateStore.setStep(step - 1);
      }}
    >
      {t("audits.back")}
    </ButtonAsDiv>
  );
}
