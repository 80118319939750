import React from "react";
import handleInstanceEdit, {
  EditInstanceForm,
} from "features/Audit/handlers/handleInstanceEdit";
import AuditItemForm from "features/Audit/AuditItemForm";
import { useParams } from "react-router";
import { useSetRecoilState } from "recoil";
import { currentAuditAtom } from "store/atoms/audits";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";

export default function Instance() {
  const { id, audit_id, blueprint_id, instance_id } = useParams();
  // const { defaultAlbum } = useAuditItemAlbums(instance_id, "instance");
  const setAudit = useSetRecoilState(currentAuditAtom);
  const { t } = useTranslation();
  useDisableScrollOnNumberInputs();

  // const [images, setImages] = React.useState<LocalImage[]>([]);
  React.useEffect(() => {
    if (instance_id) {
      // getLocalImagesForSource(instance_id).then((images) => setImages(images));
    }
  }, [instance_id]);

  return (
    <AuditItemForm<EditInstanceForm>
      redirect={`/buildings/${id}/audits/${audit_id}/blueprints/${blueprint_id}/instances`}
      title={t("audits.editItem")}
      isInstance={true}
      submitText={t("audits.saveItem")}
      images={[]}
      handleSubmit={async (form, args) => {
        if (!instance_id) return console.error("instance_id undefined.");
        const updatedAudit = await handleInstanceEdit(
          form,
          { ...args, instance_id },
          (message) => args.setFormLoading(message !== "" ? t(message) : "")
        );
        if (updatedAudit) setAudit(updatedAudit);
      }}
    />
  );
}
