import { Step, StepLabel, Stepper } from "@material-ui/core";
import { IconArrowLeft } from "@tabler/icons-react";
import { apiKey } from "api-client";
import Button from "components/Button";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { revitStore } from "store/RevitStore";
import { StepperCustomCircle } from "utils";
import StepBuildings from "./StepBuildings";
import StepOrganisations from "./StepOrganisations";
import StepSync from "./StepSync";
import { userStore } from "store/UserStore";
import { useUser } from "contexts/user";

export default observer(function ConfigureConnection() {
  const { t } = useTranslation();
  const { user } = useUser();
  const { authHeader } = userStore;

  const steps: { title: string; content: React.ReactNode }[] = [
    { title: t("revit.selectOrganisation"), content: <StepOrganisations /> },
    { title: t("revit.selectBuilding"), content: <StepBuildings /> },
    { title: t("revit.syncObjects"), content: <StepSync /> },
  ];

  const handleConnect = async () => {
    if (!authHeader) return;
    await apiKey
      .concularApiV1RoutersApiKeyGenerateApiKey(authHeader)
      .then((response) => {
        const url = `concular://setup_revit?api_token=${response.data.token}&organization_id=${revitStore.currentOrganisation?.id}&building_id=${revitStore.currentBuilding?.id}&user_email=${user?.email}&building_name=${revitStore.currentBuilding?.name}`;
        window.location.href = url;
      })
      .catch((err) =>
        console.error(err, "err.concularApiV1RoutersApiKeyGenerateApiKey")
      );
  };

  function footerButtons() {
    return (
      <div className="flex justify-between mt-7 fixed bottom-0 border-gray-100 border-t px-4 py-3 bg-white left-0 w-full">
        {revitStore.skipOrganisation && revitStore.activeStep === 1 ? null : (
          <Button
            type="gray"
            leadingIcon={<IconArrowLeft width={15} stroke={3} />}
            width="fit-content"
            size="small"
            onClick={() => revitStore.setActiveStep(revitStore.activeStep - 1)}
          >
            {t("commons.back")}
          </Button>
        )}
        {revitStore.activeStep === 2 ? (
          <Button
            type="primary"
            width="fit-content"
            size="small"
            onClick={handleConnect}
            testID="understand_button"
          >
            {t("revit.iUnderstand")}
          </Button>
        ) : null}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full relative">
      <div className="tex-base font-semibold">
        {t("revit.configureConnection")}
      </div>
      <div className="w-full mb-10">
        <Stepper
          activeStep={revitStore.activeStep}
          alternativeLabel
          style={{
            backgroundColor: "transparent",
            margin: "0 auto",
            padding: "12px",
          }}
        >
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel
                color="black"
                StepIconComponent={StepperCustomCircle}
              />
            </Step>
          ))}
        </Stepper>
        <div className="text-xs font-medium text-gray-500 text-center mb-6">
          {t("revit.helloUser", { use_name: user?.nickname })}
        </div>
        <div key={steps[revitStore.activeStep].title}>
          <div className="tex-sm text-gray-800 font-semibold mb-6">
            {steps[revitStore.activeStep].title}
          </div>
          {steps[revitStore.activeStep].content}

          {revitStore.activeStep !== 0 ? footerButtons() : null}
        </div>
      </div>
    </div>
  );
});
