import React, { useState } from "react";
import ActionMenu from "components/ActionMenu";
import { useTranslation } from "react-i18next";
import { IconFileExport, IconChevronDown } from "@tabler/icons-react";
import DGNBModal from "features/Building/BuildingMaterialResources/DGNB/DGNBModal";
import ExportInventory from "../../BuildingInventory/ExportInventory";
import useFeatureFlag from "hooks/useFeatureFlag";
import CertificateExporter from "../../BuildingMaterialResources/CertExport";
import { buildingStore } from "store/Building/BuildingStore";
import { observer } from "mobx-react-lite";
import DGNBItem from "./DGNBItem";
import ExportInventoryItem from "./ExportInventoryItem";
import CertificationExporterItem from "./CertificationExporterItem";

export default observer(function Export() {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const [openDGNBPass, setOpenDGNBPass] = useState(false);
  const [openCertificateExport, setOpenCertificateExport] = useState(false);
  const [openExportInventory, setOpenExportInventory] = useState(false);
  const { isFeatureVisible } = useFeatureFlag();
  const exportIsUnavailable = isFeatureVisible(
    "certification_exports_unavailable"
  );
  const { buildingMappingStatus, currentBuilding } = buildingStore;
  const exportDisabled =
    currentBuilding?.exports_available === false ||
    buildingMappingStatus.hasReport === false;

  const generatePDFMenu = () => {
    return (
      <div className="text-gray-900 font-[400]">
        {t("dashboard.generatePDF")}
      </div>
    );
  };

  const actionMenu = {
    items: [
      <DGNBItem key={0} disabled={exportDisabled} />,
      <ExportInventoryItem key={1} disabled={exportDisabled} />,
      <CertificationExporterItem
        key={2}
        disabled={exportDisabled}
        isUnavailable={exportIsUnavailable}
      />,
      ...(isFeatureVisible("generate_pdf") ? [generatePDFMenu()] : []),
    ],
    itemsDisabled: [
      exportDisabled,
      exportDisabled,
      exportDisabled || exportIsUnavailable,
    ],
  };

  const actions = [
    () => setOpenDGNBPass(true),
    () => setOpenExportInventory(true),
    () => setOpenCertificateExport(true),
    async () => await buildingStore.getPPDFReport(),
  ];

  const { items, itemsDisabled } = actionMenu;

  return (
    <>
      <ActionMenu
        itemDisabled={itemsDisabled}
        items={items}
        onSelect={(index) => actions[index]()}
        icon={
          <div
            aria-disabled={true}
            className={`h-8 flex flex-row cursor-pointer shadow-sm mr-4`}
          >
            <div className="h-full px-2 flex flex-row  bg-indigo-600 rounded-bl-md rounded-tl-md items-center">
              <IconFileExport color="white" className="py-0.5" />
              <p className="text-white ml-2 font-medium text-[14px] pr-1">
                {t("DGNB.export")}
              </p>
            </div>
            <IconChevronDown
              color="white"
              className="px-0.5 h-full ml-0.5 bg-indigo-600 rounded-br-md rounded-tr-md"
            />
          </div>
        }
        itemsClassName={`${
          isEng ? "!min-w-[270px]" : "!min-w-[340px]"
        } top-7 shadow-sm`}
        testID="export_button"
      />
      {openDGNBPass && (
        <DGNBModal open={openDGNBPass} setOpen={setOpenDGNBPass} />
      )}
      {openCertificateExport && (
        <CertificateExporter
          open={openCertificateExport}
          setOpen={setOpenCertificateExport}
        />
      )}
      {openExportInventory && (
        <ExportInventory
          open={openExportInventory}
          close={() => setOpenExportInventory(false)}
        />
      )}
    </>
  );
});
