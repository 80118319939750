import React from "react";
import ButtonGroups from "components/ButtonGroups";
import SearchSortTable from "components/Table";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { b6ImporterStore } from "store/B6ImporterStore";
import useB6ImporterTableColumns from "./useB6ImporterTableColumns";
import { ColumnDef } from "@tanstack/react-table";
import moment from "moment";
import { buildingStore } from "store/Building/BuildingStore";

function CalculationOverview() {
  const { t } = useTranslation();
  const switchItems = [
    {
      id: "imported",
      name: t("b6Importer.importedFromExcel"),
    },
    {
      id: "calculated",
      name: t("b6Importer.calculatedResultsForNRF"),
    },
  ];

  const usedData = [
    {
      id: "NRFOfBuilding",
      value: buildingStore.currentBuilding?.net_floor_area,
    },
    {
      id: "uploadedFile",
      value: b6ImporterStore.fileReport?.input_file,
    },
    {
      id: "uploadedOn",
      value: moment(b6ImporterStore.fileReport?.created).isValid()
        ? moment(b6ImporterStore.fileReport?.created).format("DD.MM.yy, HH:mm")
        : "",
    },
    {
      id: "calculatedOn",
      value: moment(b6ImporterStore.fileReport?.calculation_date).isValid()
        ? moment(b6ImporterStore.fileReport?.calculation_date).format(
            "DD.MM.yy, HH:mm"
          )
        : "",
    },
    {
      id: "reasonForCalculation",
      value: b6ImporterStore.fileReport?.calculation_reason ?? "-",
    },
  ];

  React.useEffect(() => {
    b6ImporterStore.setReportType(switchItems[0]);
  }, []);

  if (!b6ImporterStore.fileReport || !b6ImporterStore.reportType) return <></>;
  return (
    <div className="p-6 w-full items-center overflow-auto max-h-[calc(100vh-240px)]">
      <div className="mb-6">
        <div className="text-base font-semibold mb-2 text-gray-800">
          {t("b6Importer.calculationOverview")}
        </div>
        <div className="text-xs font-normal mb-2 text-gray-700">
          {t("b6Importer.calculationOverviewSubtitle")}
        </div>
      </div>
      <div className="mb-4">
        <div className="text-base font-semibold mb-2 text-gray-800">
          {t("b6Importer.dataUsedForCalculation")}
        </div>
        <div className="flex flex-wrap">
          {usedData.map((item, index) => (
            <div key={index} className="mr-4 text-sm">
              <div className="text-gray-600 mb-1 font-medium">
                {t(`b6Importer.${item.id}`)}
              </div>
              <div className="text-gray-500 font-normal mb-2">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <ButtonGroups
          items={switchItems}
          onChange={(e) => b6ImporterStore.setReportType(e)}
          current={b6ImporterStore.reportType}
          containerClassName="mb-2"
        ></ButtonGroups>
        <SearchSortTable
          data={
            b6ImporterStore.reportType?.id === switchItems[0].id
              ? (b6ImporterStore.fileReport.raw_values as unknown as object[])
              : (b6ImporterStore.fileReport
                  .multiplied_values as unknown as object[])
          }
          getColumns={
            useB6ImporterTableColumns as () => ColumnDef<unknown, unknown>[]
          }
          borderedStyle
          headerDivStyle="py-2"
          enableTooltip
        ></SearchSortTable>
      </div>
    </div>
  );
}

export default observer(CalculationOverview);
