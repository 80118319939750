import { BlueprintOUT, ProductGroupOUT } from "api-client";
import React from "react";
import { hasUUIDPattern, isNumeric } from "utils";
import { getPropertyName, getUnit } from "./common";
import { addValuesToFieldsFromProperties } from "../utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

interface Props {
  blueprint: BlueprintOUT;
  selectedProductGroup: ProductGroupOUT;
}

export const RequiredFields = ({ blueprint, selectedProductGroup }: Props) => {
  const { i18n } = useTranslation();

  const { blueprint_id } = useParams();

  const requiredFields = React.useMemo(() => {
    if (!blueprint || !blueprint_id || !selectedProductGroup) return null;

    return blueprint.blueprintproperty_set.filter((prop) =>
      selectedProductGroup.required_fields.find((f) => f.id === prop.field_id)
    );
  }, [blueprint.id]);

  const requiredDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.required_fields,
    blueprint.blueprintproperty_set
  );

  return (
    <div className="grid sm:grid-cols-2 gap-x-4">
      {selectedProductGroup &&
        requiredFields &&
        requiredFields.map((p, i) => {
          // const val =
          let val = requiredDefaultValues[i];
          if (hasUUIDPattern(requiredDefaultValues[i])) {
            const found = selectedProductGroup.required_fields[
              i
            ].field_options.find((opt) => (val as string).includes(opt.id));
            if (found) {
              val = found[i18n.language === "en" ? "name" : "name_de"];
            }
          }

          const isFieldDefined =
            selectedProductGroup.required_fields &&
            selectedProductGroup.required_fields[i];

          const unit = isFieldDefined
            ? getUnit(selectedProductGroup.required_fields[i])
            : undefined;

          const propName = isFieldDefined
            ? getPropertyName(
                selectedProductGroup.required_fields[i],
                i18n.language
              )
            : undefined;

          if (!propName) return null;

          return (
            <div
              className="text-sm flex justify-between py-2 border-b-2"
              key={p.id}
            >
              <span className="w-1/2 text-gray-500">{propName}</span>
              <div className="w-1/2">
                {val}{" "}
                {unit &&
                  unit.length <= 10 &&
                  unit !== val &&
                  isNumeric(val) && (
                    <span>{hasUUIDPattern(unit) ? "" : unit}</span>
                  )}
              </div>
            </div>
          );
        })}
    </div>
  );
};
