import { BuildingOUT, OrganisationOUT } from "api-client";
import { makeAutoObservable } from "mobx";

class RevitStore {
  activeStep = 0;
  currentOrganisation: OrganisationOUT | undefined = undefined;
  currentBuilding: BuildingOUT | undefined = undefined;
  connected = false;
  skipOrganisation = false;

  setActiveStep(step: number) {
    this.activeStep = step;
  }

  setCurrentOrganisation(currentOrganisation: OrganisationOUT | undefined) {
    this.currentOrganisation = currentOrganisation;
  }

  setCurrentBuilding(currentBuilding: BuildingOUT | undefined) {
    this.currentBuilding = currentBuilding;
  }

  setConnected(connected: boolean) {
    this.connected = connected;
  }

  setSkipOrganisation(skipOrganisation: boolean) {
    this.skipOrganisation = skipOrganisation;
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: RevitStore;
  static getInstance(): RevitStore {
    if (!RevitStore.instance) {
      RevitStore.instance = new RevitStore();
    }
    return RevitStore.instance;
  }
}

export const revitStore = RevitStore.getInstance();
