import React, { ReactNode } from "react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import { unitPower } from "utils";
import { Transition } from "@headlessui/react";
import Tooltip from "components/Tooltip";

interface Props {
  title: string;
  value?: string | number | null | undefined;
  unit?: string | null | undefined;
  isOpen: boolean;
  toggleCollapse: () => void;
  children: ReactNode;
  isLastIndex: boolean;
  tooltip?: string;
}

export const CustomCollapse = ({
  title,
  value,
  unit,
  isOpen,
  toggleCollapse,
  children,
  isLastIndex,
  tooltip,
}: Props) => {
  return (
    <div className="rounded-md text-[14px]">
      <div
        onClick={toggleCollapse}
        onKeyDown={toggleCollapse}
        className={`cursor-pointer ${
          !isLastIndex && !isOpen
            ? "border-b border-gray-300"
            : isOpen
            ? "border-b border-gray-100"
            : ""
        }  flex items-center justify-between bg-transparent p-2`}
      >
        <div className="font-medium">{title}</div>
        <div className="flex items-center">
          {value && (
            <div className="px-2">
              {value} {unitPower(String(unit ?? "")) ?? ""}
            </div>
          )}
          {tooltip ? (
            <Tooltip content={tooltip} className="!-ml-1 mr-1"></Tooltip>
          ) : null}
          <ChevronUpIcon
            width={16}
            className={clsx(
              { "rotate-90 transform": !isOpen },
              "text-gray-700"
            )}
          />
        </div>
      </div>
      <Transition
        show={isOpen ? true : false}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
        className={"!mt-0"}
      >
        <div
          className={`${isOpen ? "block" : "hidden"} ${
            !isLastIndex ? "last:border-b" : ""
          }`}
        >
          {children}
        </div>
      </Transition>
    </div>
  );
};
