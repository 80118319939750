import React from "react";
import { useTranslation } from "react-i18next";
import { BlueprintOUT, ProductGroupOUT } from "api-client";
import { RequiredFields } from "./RequiredFields";
import { OptionalFields } from "./OptionalFields";
import { BlueprintImages } from "./BlueprintImages";

export default function BlueprintOverview(props: {
  className?: string;
  selectedBlueprint: BlueprintOUT;
  selectedProductGroup: ProductGroupOUT;
}) {
  const { t } = useTranslation();

  if (!props.selectedBlueprint || !props.selectedProductGroup) return null;

  const hasExtraInfo = React.useMemo(() => {
    if (props.selectedBlueprint && props.selectedBlueprint.extra_info) {
      return true;
    }
    return false;
  }, [props.selectedBlueprint]);

  if (!props.selectedBlueprint || !props.selectedProductGroup) return null;

  return (
    <div className={`${props.className ?? ""} my-2`}>
      <BlueprintImages blueprint={props.selectedBlueprint} />
      <span className="font-semibold text-sm">
        {t("audits.specifications")}
      </span>
      <RequiredFields
        blueprint={props.selectedBlueprint}
        selectedProductGroup={props.selectedProductGroup}
      />
      <OptionalFields
        blueprint={props.selectedBlueprint}
        selectedProductGroup={props.selectedProductGroup}
        hasExtraInfo={hasExtraInfo}
      />
      {hasExtraInfo ? (
        <div className="pb-4">
          <div className="text-sm justify-between py-2 border-b-2">
            <div className="inline mr-4">{t("audits.moreSpecs")}</div>
            <div className="inline">{props.selectedBlueprint.extra_info}</div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
