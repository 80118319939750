import Button from "components/Button";
import React from "react";
import { useTranslation } from "react-i18next";

export const SubmitButton = React.forwardRef<HTMLButtonElement>(
  (_props, ref) => {
    const { t } = useTranslation();
    return (
      <Button
        ref={ref}
        htmlType="submit"
        className="min-w-32"
        disabledClasses="w-full"
      >
        {t("audits.createBlueprint")}
      </Button>
    );
  }
);

SubmitButton.displayName = "SubmitButton";
