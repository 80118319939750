import InputLabel from "components/InputLabel";
import React from "react";

interface IProps extends React.HTMLProps<HTMLInputElement> {
  labelName?: string;
  enforceRequired?: boolean;
  containerClass?: string;
  handleChange?: (value: string) => void | Promise<void>;
}

const GeneratedCheckbox = React.forwardRef(
  (
    { labelName, containerClass, ...props }: IProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const inputRequired = props.required && props.enforceRequired !== false;
    return (
      <div
        style={props.style}
        className={`pb-15 mb-2 flex ${containerClass ?? ""}`}
      >
        <input
          className="mr-2 h-4 w-4 ml-1 rounded-md border-gray-300 border checked:text-indigo-600 focus:outline-indigo-600"
          ref={ref}
          type="checkbox"
          required={inputRequired}
          {...props}
        />
        {labelName && (
          <InputLabel
            required={props.required}
            labelName={labelName}
            name={props.name as string}
          />
        )}
      </div>
    );
  }
);

GeneratedCheckbox.displayName = "GeneratedCheckbox";

export default GeneratedCheckbox;
