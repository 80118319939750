import { SimpleMaterialOut } from "api-client";
import DropdownCheckbox from "components/DropdownCheckbox";
import React from "react";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";

export default observer(function MaterialCategoryFilter({
  selected,
  setSelected,
  removingItem,
  handleRemoveItem,
}: {
  selected?: SimpleMaterialOut[];
  setSelected: (value: SimpleMaterialOut[]) => void;
  removingItem: SimpleMaterialOut | undefined;
  handleRemoveItem: () => void;
}) {
  const onChangeCategories = (value: SimpleMaterialOut[]) => setSelected(value);

  React.useEffect(() => {
    (async () => await buildingProductStore.fetchMaterialCategories())();
  }, []);

  if (!buildingProductStore.filtersOptions.material_categories) return <></>;
  return (
    <DropdownCheckbox<SimpleMaterialOut>
      displayKey={"name"}
      required={true}
      handleSelect={onChangeCategories}
      labelName={t("mapping.materialCategory")}
      name="material_category"
      items={buildingProductStore.filtersOptions.material_categories}
      removeSelected={removingItem}
      handleRemoveSelected={handleRemoveItem}
      containerClassName="mr-0 sm:mr-3 min-w-[146px] !mb-3"
      optionsClassName="z-40"
      checkedItems={selected}
    />
  );
});
