import React from "react";
import { useParams } from "react-router-dom";
import { addValuesToFieldsFromProperties } from "features/Audit/utils";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { observer } from "mobx-react-lite";
import GeneratedFieldsOnline from "./GeneratedFieldsOnline";
import PingAnimation from "components/PingAnimation";

const AuditItemFieldsOnline = (props: { filteredFields?: string[] }) => {
  const { instance_id } = useParams();
  const { selectedProductGroup, selectedBlueprint } = caOnlineStore;

  if (!selectedProductGroup || !selectedBlueprint) {
    return <PingAnimation size="large" color="gray" />;
  }

  const foundInstance = instance_id
    ? selectedBlueprint.instance_set.find(
        (instance) => instance.id === instance_id
      )
    : null;

  const requiredDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.required_fields.filter((f) =>
      props.filteredFields ? props.filteredFields.includes(f.id) === false : f
    ),
    foundInstance
      ? foundInstance.instanceproperty_set
      : selectedBlueprint.blueprintproperty_set
  );

  const optionalDefaultValues = addValuesToFieldsFromProperties(
    selectedProductGroup.optional_fields
      .filter((f) =>
        props.filteredFields ? props.filteredFields.includes(f.id) === false : f
      )
      .filter((f) => f.name !== "Condition"),
    foundInstance
      ? foundInstance.instanceproperty_set
      : selectedBlueprint.blueprintproperty_set
  );

  return (
    <GeneratedFieldsOnline
      generatesDimensions
      requiredDefaultValues={requiredDefaultValues}
      optionalDefaultValues={optionalDefaultValues}
      required_fields={selectedProductGroup.required_fields.filter((f) =>
        props.filteredFields
          ? props.filteredFields.includes(f.name as string) === false
          : f
      )}
      optional_fields={selectedProductGroup.optional_fields.filter((f) =>
        props.filteredFields
          ? props.filteredFields.includes(f.name as string) === false
          : f
      )}
    />
  );
};

export default observer(AuditItemFieldsOnline);
