import { NotificationMessage } from "components/Toast";
import { makeAutoObservable } from "mobx";

export interface ElementPos {
  left: number | string | undefined;
  top: number | string | undefined;
}

export interface Tooltip {
  content?: string | React.ReactNode;
  children?: string | React.ReactNode;
  active?: boolean;
  position?: ElementPos;
  direction?: string;
  delay?: number;
  className?: string;
  whiteTheme?: boolean;
  hoverOnTooltip?: boolean;
  disableStickOnHover?: boolean;
  align?: "right";
}
class GlobalStore {
  tooltip: Tooltip | undefined = undefined;
  tootipWrapperHover = false;
  toastList: NotificationMessage[] = [];

  setTooltipContent(tooltip: Tooltip | undefined) {
    this.tooltip = tooltip;
  }

  setTootipWrapperHover(tootipWrapperHover: boolean) {
    this.tootipWrapperHover = tootipWrapperHover;
  }

  setToastList(toastList: NotificationMessage[]) {
    this.toastList = toastList;
  }

  constructor() {
    makeAutoObservable(this);
  }

  static instance: GlobalStore;

  static getInstance(): GlobalStore {
    if (!GlobalStore.instance) {
      GlobalStore.instance = new GlobalStore();
    }
    return GlobalStore.instance;
  }
}

export const globalStore = GlobalStore.getInstance();
