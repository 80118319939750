import { useBanner } from "../context";
import React from "react";

export function camelize(str: string) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

export default function YesOrNo(props: { name: string }) {
  const { texts } = useBanner();
  return (
    <>
      <label className="flex">
        <input
          className="mr-1 mb-2"
          type="radio"
          name={camelize(props.name)}
          aria-label={`Allow ${props.name} tracking`}
          required
          defaultValue="true"
        />
        <span>{texts.yes}</span>
      </label>
      <label className="flex">
        <input
          className="mr-1"
          type="radio"
          name={camelize(props.name)}
          aria-label={`Disallow ${props.name} tracking`}
          required
          defaultValue="false"
        />
        <span>{texts.no}</span>
      </label>
    </>
  );
}
