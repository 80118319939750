import {
  BlueprintOUT,
  BlueprintPropertyOUT,
  FieldOUT,
  InstanceOUT,
  InstancePropertyOUT,
  ProductGroupOUT,
  UserProfileOUT,
  onlineAPi,
} from "api-client";
import { db } from "api-client-local/db";
import { getLocalImagesForSource } from "api-client-local/images";
import {
  getLocalAuthHeader,
  getProductGroupById,
} from "api-client-local/utils";
import { hasUUIDPattern, isISODate, isNumeric } from "utils";

export function addValuesToFieldsFromProperties(
  fields: FieldOUT[],
  blueprintProperties: (BlueprintPropertyOUT | InstancePropertyOUT)[]
) {
  if (blueprintProperties.length === 0) {
    console.warn("properties are empty.");
    return [];
  }
  return fields.map((field) => {
    const found = blueprintProperties.find(
      (comp) => comp.field_id === field.id
    );
    if (!found) {
      // console.log("Field not found", field.id, field, blueprintProperties);
      return null;
    }
    return found.value_datetime
      ? found.value_datetime
      : found.value_numeric
      ? found.value_numeric
      : found.value_string
      ? found.value_string
      : found.value_field_option_ids;
  });
}

export function makeInstanceRawModel(
  blueprintId: string,
  user: UserProfileOUT,
  args?: Partial<InstanceOUT>
): InstanceOUT {
  const now = new Date().toISOString();
  // Clones the set with new IDs.
  const instanceproperty_set =
    args && args.instanceproperty_set && args.instanceproperty_set.length > 0
      ? args.instanceproperty_set.map((p) => ({
          ...p,
          id: window.crypto.randomUUID(),
        }))
      : [];

  const model = {
    name: args?.name ?? "",
    name_de: args?.name_de ?? "",
    extra_info: args?.extra_info ?? "",
    blueprint_id: blueprintId,
    created: now,
    floor_ids: args?.floor_ids ?? [],
    modified: now,
    room_name: args?.room_name ?? "",
    created_by: user,
    instanceproperty_set: instanceproperty_set,
    instance_amount: args?.instance_amount ?? 1,
    albums: [],
  };
  return { ...model, id: window.crypto.randomUUID() } as InstanceOUT;
}

export function makeBlueprintRawModel(
  auditId: string,
  productGroupId: string,
  user: UserProfileOUT,
  args?: Partial<BlueprintOUT>
): BlueprintOUT {
  const now = new Date().toISOString();
  const blueprintId = window.crypto.randomUUID();
  // Clones the set with new IDs.
  const blueprintproperty_set =
    args && args.blueprintproperty_set && args.blueprintproperty_set.length > 0
      ? args.blueprintproperty_set.map((props) => ({
          ...props,
          id: window.crypto.randomUUID(),
          blueprint_id: blueprintId,
        }))
      : [];
  // Clones the set with new IDs.
  const instance_set =
    args && args.instance_set && args.instance_set.length > 0
      ? args.instance_set.map((i) => makeInstanceRawModel(blueprintId, user, i))
      : [];

  // Recursively clones the set with new IDs.
  const components =
    args && args.components && args.components.length > 0
      ? args.components.map((c) =>
          makeBlueprintRawModel(auditId, c.product_group_id, user, c)
        )
      : [];

  const albums = args?.albums?.map((album) => {
    return {
      ...album,
      id: window.crypto.randomUUID(),
      images: album.images.map((img) => {
        return {
          ...img,
          id: window.crypto.randomUUID(),
        };
      }),
    };
  });
  console.log("newBlueprint.albums...: ", albums);

  const model: Partial<BlueprintOUT> = {
    name: args?.name ?? "",
    name_de: args?.name_de ?? "",
    extra_info: args?.extra_info ?? "",
    audit_id: auditId,
    product_group_id: productGroupId,
    created: now,
    modified: now,
    created_by: user,
    instance_set: instance_set,
    components: components,
    manufacturer: args?.manufacturer ?? undefined,
    blueprintproperty_set: blueprintproperty_set,
    as_component_amount: args?.as_component_amount ?? 1,
    albums: [],
  };
  return { ...model, id: blueprintId } as BlueprintOUT;
}

export async function handleBlueprintLocalImagesDuplicate(
  oldBlueprint: BlueprintOUT,
  newBlueprint: BlueprintOUT
) {
  const oldBlueprintLocalImages = await getLocalImagesForSource(
    oldBlueprint.id
  );
  oldBlueprintLocalImages.forEach((localImage) => {
    db.images.add({
      id: window.crypto.randomUUID(),
      sourceType: "blueprint",
      sourceId: newBlueprint.id,
      file: localImage.file,
      deleted: false,
      uploaded: false,
      order: 0,
      albumName: "default",
    });
  });

  const newBlueprintLocalImages = await getLocalImagesForSource(
    newBlueprint.id
  );

  console.log("newBlueprintLocalImages: ", newBlueprintLocalImages);
}

type PropertyValue = string | number | string[];

export function makeBlueprintPropertyModel(
  fieldId: string,
  blueprintId: string,
  value: PropertyValue
): BlueprintPropertyOUT {
  const now = new Date().toISOString();
  const model = {
    id: window.crypto.randomUUID(),
    blueprint_id: blueprintId,
    created: now,
    modified: now,
    field_id: fieldId,
    value_field_option_ids: [],
  };

  return handleFieldValueTypes<typeof model>(
    value,
    model
  ) as unknown as BlueprintPropertyOUT;
}

export function makeInstancePropertyModel(
  fieldId: string,
  instanceId: string,
  value: string | number | string[]
): InstancePropertyOUT {
  const now = new Date().toISOString();
  const model = {
    id: window.crypto.randomUUID(),
    instance_record_id: instanceId,
    created: now,
    modified: now,
    field_id: fieldId,
    value_field_option_ids: [],
  };

  return handleFieldValueTypes<typeof model>(
    value,
    model
  ) as unknown as InstancePropertyOUT;
}

export function handleFieldValueTypes<Model>(
  value: string | number | string[],
  model: Model,
  isEmptySelectField?: boolean
) {
  if (isEmptySelectField) {
    return {
      ...model,
      value_string: null,
      value_field_option_ids: [],
    };
  }
  if (Array.isArray(value)) {
    return { ...model, value_field_option_ids: value };
  }
  if (typeof value === "number" || isNumeric(value)) {
    return { ...model, value_numeric: Number(value) };
  }
  if (isISODate(value)) {
    return { ...model, value_datetime: value };
  }
  if (hasUUIDPattern(value)) {
    const hasComma = value.includes(",");
    const result = hasComma ? value.split(",") : [value];
    return {
      ...model,
      value_field_option_ids: result,
    };
  }
  return { ...model, value_string: value };
}

export function scrollToFirstInvalidInput(
  inputs: (HTMLInputElement | HTMLSelectElement)[]
) {
  const input = inputs[0];
  const inputYCoord =
    input.getBoundingClientRect().top + document.documentElement.scrollTop;
  window.scrollTo({
    top: inputYCoord - 110,
    behavior: "smooth",
  });
}

export async function countMissingRequiredFields(
  blueprint: BlueprintOUT
): Promise<FieldOUT[]> {
  const productGroup = await getProductGroupById(blueprint.product_group_id);
  if (!productGroup) {
    return [];
  }
  const requiredFields = productGroup.required_fields;
  const missingFields = requiredFields.filter((f) => {
    const blueprintProperty = blueprint.blueprintproperty_set.find(
      (p) => p.field_id === f.id
    );
    if (blueprintProperty) {
      let found = true;
      if (f.field_type === "string") {
        if (blueprintProperty.value_string) {
          found = false;
        }
      }
      if (f.field_type === "number") {
        if (blueprintProperty.value_numeric) {
          found = false;
        }
      }
      if (f.field_type === "single_select") {
        if (
          (blueprintProperty.value_field_option_ids &&
            (blueprintProperty.value_field_option_ids as []).length !== 0) ||
          blueprintProperty.value_string
        ) {
          found = false;
        }
      }
      if (f.field_type === "multi_select") {
        if (
          (blueprintProperty.value_field_option_ids &&
            (blueprintProperty.value_field_option_ids as []).length !== 0) ||
          blueprintProperty.value_string
        ) {
          found = false;
        }
      }
      return found;
    } else {
      return true;
    }
  });
  return missingFields;
}

export async function handleAuditDelete(auditId: string) {
  await db.audits.delete(auditId);
  const authHeader = await getLocalAuthHeader();
  try {
    const response = await onlineAPi.caApiV1RoutersCaOnlineAuditDeleteAudit(
      auditId,
      authHeader
    );
    if (response.status === 200) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error deleting audit: ", auditId, error);
    return false;
  }
}

export function filterValidFields(
  selectedProductGroup: ProductGroupOUT | undefined
) {
  if (!selectedProductGroup?.blueprint_field_set) return;
  return selectedProductGroup.blueprint_field_set;
}
