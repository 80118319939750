import React from "react";
import { ManufacturerOUT } from "api-client";
import ButtonAsDiv from "components/ButtonAsDiv";
import validateComponentForm from "features/Audit/handlers/validateComponentForm";
import { makeBlueprintPropertyModel } from "features/Audit/utils";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";

export function EditComponentButton() {
  const { t } = useTranslation();

  const {
    selectedComponent,
    addedComponents,
    selectedComponentProductGroup,
    addedComponentImages,
  } = blueprintCreateStore;

  const { manufacturers } = caOnlineStore;

  const handleComponentEdit = React.useCallback(async () => {
    if (!selectedComponent) return;
    try {
      const form = validateComponentForm();
      if (!form) throw "form is undefined.";
      if (!selectedComponentProductGroup)
        throw "selectedComponent is undefined.";
      const componentFields = [
        ...selectedComponentProductGroup.required_fields,
        ...selectedComponentProductGroup.optional_fields,
      ];
      if (componentFields.length === 0) throw "componentFields.length is 0";
      const manufacturer: ManufacturerOUT | undefined =
        form.select_manufacturer && form.select_manufacturer.length > 0
          ? manufacturers?.find((m) => m.name === form.select_manufacturer)
          : undefined;

      const editedComponent = {
        ...selectedComponent,
        name: form.name,
        as_component_amount: Number(form.as_component_amount),
        manufacturer: manufacturer ?? null,
      };
      editedComponent.blueprintproperty_set = componentFields.map((field) => {
        return makeBlueprintPropertyModel(
          field.id,
          editedComponent.id,
          form[field.id]
        );
      });
      blueprintCreateStore.setAddedComponents({
        ...addedComponents,
        [selectedComponent.id]: editedComponent,
      });

      if (form.images_component && form.images_component.length > 0) {
        blueprintCreateStore.setAddedComponentImages({
          ...addedComponentImages,
          [selectedComponent.id]: form.images_component as unknown as File[],
        });
      }
      blueprintCreateStore.setComponentCurrentStep(0);
      blueprintCreateStore.setSelectedComponentProductGroup(undefined);
      blueprintCreateStore.setSelectedComponent(undefined);
    } catch (err) {
      console.error(err);
    }
  }, [manufacturers, selectedComponent, selectedComponentProductGroup]);

  return (
    <ButtonAsDiv onClick={handleComponentEdit}>
      {t("audits.editComponent")}
    </ButtonAsDiv>
  );
}
