import { ArrowLeftIcon } from "@heroicons/react/outline";
import Form from "components/Form";
import React from "react";
import { useTranslation } from "react-i18next";
import Step1ProductGroupSelect from "./steps/Step1ProductGroupSelect";
import Step2BlueprintForm from "./steps/Step2BlueprintForm";
import Step3Components from "./steps/Step3Components";
import Step4ExtraInfo from "./steps/Step4ExtraInfo";
import FormLoadingContainer from "features/Audit/FormLoadingContainer";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import Stepper from "components/Stepper";
import useViewportZoomLock from "hooks/useViewportZoomLock";
import useAuditFormResetOnline from "features/AuditOnline/useAuditFormResetOnline";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import handleBlueprintInvalidOnline from "features/AuditOnline/handleBlueprintInvalidOnline";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router";
import GeneratedNameOnline from "features/AuditOnline/GeneratedNameOnline";
import handleBlueprintSubmitOnline from "features/AuditOnline/utils";
import { caOnlineStore } from "store/AuditsOnline/CAOnlineStore";
import { HandleBlueprintSubmitArgs } from "features/AuditOnline/models";
import { userStore } from "store/UserStore";

export interface BlueprintAddForm {
  select_manufacturer: string;
  select_product_group: string;
  descriptive_name: string;
  extra_info: string;
  [key: string]: string;
}

const BlueprintAddOnline = () => {
  const { userProfile, authHeader } = userStore;
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    step,
    // componentCurrentStep, // is = componentStep from old code
    filledSteps,
    addedComponents,
    addedImages,
    addedComponentImages,
    generatedBlueprintName,
    formLoading,
    selectedProductGroup,
    selectedManufacturer,
    hasComponents,
    isAddingComponent,
  } = blueprintCreateStore;

  const reset = useAuditFormResetOnline();
  const steps = React.useMemo(() => {
    const _steps = [
      <Step1ProductGroupSelect key={0} />,
      <Step2BlueprintForm key={1} />,
      <Step3Components key={2} />,
      <Step4ExtraInfo key={3} />,
    ];
    return hasComponents ? _steps : _steps.filter((step) => step.key !== "2");
  }, [hasComponents]);

  useDisableScrollOnNumberInputs();
  useViewportZoomLock();

  React.useEffect(() => {
    blueprintCreateStore.setStepCount(steps.length);
  }, [steps]);

  React.useEffect(() => {
    blueprintCreateStore.setStepperOpened(true);
    caOnlineStore.setIsBlueprintEditMode(false);
    return () => {
      blueprintCreateStore.setStepperOpened(false);
      reset();
    };
  }, []);

  const handleInvalidSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      handleBlueprintInvalidOnline(e);
    },
    []
  );

  const handleSubmit = async (form: BlueprintAddForm) => {
    if (userProfile === null || !authHeader) return;

    const auditId = caOnlineStore.currentAudit.id;

    const args: HandleBlueprintSubmitArgs = {
      selectedProductGroup,
      selectedManufacturer,
      addedComponents,
      userProfile,
      images: addedImages,
      addedComponentImages,
      authHeader,
      building_id: id,
      generatedBlueprintName,
      audit_id: auditId,
    };
    const blueprintId = await handleBlueprintSubmitOnline(
      form,
      args,
      (message) =>
        blueprintCreateStore.setFormLoading(message !== "" ? t(message) : "")
    );

    if (blueprintId) {
      blueprintCreateStore.setFormLoading("");
      navigate(
        `/buildings/${id}/audits-online/${auditId}/blueprints/${blueprintId}/instances`
      );
      reset();
    }
  };

  return (
    <div className="pt-2">
      <FormLoadingContainer formLoading={formLoading} />
      <>
        <div className="flex font-semibold pb-2 justify-between relative">
          {isAddingComponent ? (
            <div
              onClick={() => {
                blueprintCreateStore.setComponentCurrentStep(0);
                blueprintCreateStore.setSelectedComponent(undefined);
              }}
              className="cursor-pointer text-sm h-5 text-indigo-700 py-[26.5px] flex items-center"
            >
              <ArrowLeftIcon className="h-4 w-4 mr-2" />{" "}
              {t("audits.backToComponents")}
            </div>
          ) : (
            <Stepper
              filledSteps={filledSteps}
              activeStep={step}
              onClick={(i: number) => blueprintCreateStore.setStep(i)}
              length={steps.length}
            />
          )}
        </div>
        <GeneratedNameOnline />
        <Form<BlueprintAddForm>
          onInvalid={handleInvalidSubmit}
          handleSubmit={handleSubmit}
        >
          <div className="overflow-hidden">
            <div
              style={{ transform: `translateX(-${step * 100}%)` }}
              className="whitespace-nowrap transition-transform duration-300"
            >
              {steps.map((page, i) => {
                return (
                  <div
                    key={i}
                    id={`step-${i + 1}`}
                    className={`w-full inline-block align-top pb-2 px-1`}
                  >
                    {page}
                  </div>
                );
              })}
            </div>
          </div>
        </Form>
      </>
    </div>
  );
};

export default observer(BlueprintAddOnline);
