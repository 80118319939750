import {
  buildingDashboardApi,
  DashboardBuildingCPX,
  DashboardBuildingEnvironmental,
  DashboardBuildingEnvironmentalIN,
  DashboardBuildingMass,
  DashboardBuildingMaterialValue,
  DashboardBuildingOperationalEnergy,
  DashboardBuildingGeneralEmissions,
} from "api-client";
import { getLocalAuthHeader } from "api-client-local/utils";
import { makeAutoObservable, runInAction } from "mobx";
import { buildingStore } from "./BuildingStore";
import { SelectOption } from "components/SimpleSelect";

export const categorySystemOptions: SelectOption[] = [
  {
    id: "material_name",
    name: "Material",
    name_de: "Materialien",
    value: "material_name",
  },
  {
    id: "ifc_entity",
    name: "IFC Entity",
    name_de: "IFC Entitäten",
    value: "ifc_entity",
  },
  {
    id: "din_category_name",
    name: "DIN 276",
    name_de: "DIN 276",
    value: "din_category_name",
  },
];

export class BuildingDashboardStore {
  cpxInfo: DashboardBuildingCPX | null = null;
  operationalEnergyInfo: DashboardBuildingOperationalEnergy | null = null;

  // -- Emissions panel
  emissionsInfo: DashboardBuildingGeneralEmissions | null = null;
  get emissionsAxisRange(): number[] {
    const findMin = (list: number[]) => {
      return list.reduce((prev, current) => Math.min(prev, current), 0);
    };
    const findMax = (list: number[]) => {
      return list.reduce((prev, current) => Math.max(prev, current), 0);
    };
    let minGwp = 0,
      maxGwp = 0,
      minPenrt = 0,
      maxPenrt = 0;

    if (this.emissionsInfo?.gwp_traces) {
      const points = this.emissionsInfo.gwp_traces
        .map((e) => e.y)
        .flat() as number[];
      minGwp = findMin(points);
      maxGwp = findMax(points);
    }

    if (this.emissionsInfo?.operational_energy_traces) {
      const points = this.emissionsInfo.operational_energy_traces
        .map((e) => e.y)
        .flat() as number[];
      minPenrt = findMin(points);
      maxPenrt = findMax(points);
    }

    const range = [Math.min(minGwp, minPenrt), Math.max(maxGwp, maxPenrt)];
    return range;
  }

  // -- Environmental panel
  environmentalInfo: DashboardBuildingEnvironmental | null = null;
  environmentalCategorySystemSelected: string = "material_name";
  environmentalUnitSelected: string = "kg CO2e";
  environmentalUnitOptions: SelectOption[] = [
    {
      id: "kg CO2e/m2*a",
      name: "kg CO2e/m2*a",
      name_de: "kg CO2e/m2*a",
      value: "kg CO2e/m2*a",
    },
    {
      id: "kg CO2e/m2",
      name: "kg CO2e/m2",
      name_de: "kg CO2e/m2",
      value: "kg CO2e/m2",
    },
    { id: "kg CO2e", name: "kg CO2e", name_de: "kg CO2e", value: "kg CO2e" },
  ];

  // -- Mass panel
  massInfo: DashboardBuildingMass | null = null;
  massCategorySystemSelected: string = "ifc_entity";
  massUnitSelected: string = "kg";
  massUnitOptions: SelectOption[] = [
    { id: "kg", name: "kg", name_de: "kg", value: "kg" },
    { id: "kg/m2", name: "kg/m2", name_de: "kg/m2", value: "kg/m2" },
    { id: "t", name: "t", name_de: "t", value: "t" },
  ];

  // -- Material value panel
  materialValueInfo: DashboardBuildingMaterialValue | null = null;
  materialValueCategorySelected: string = "material_name";

  get materialValuePercentage(): number | null {
    if (
      !this.materialValueInfo ||
      !this.materialValueInfo.object_count_with_match
    ) {
      return null;
    }
    const numerator =
      this.materialValueInfo.object_count_with_match_and_material_price ?? 0;
    const denominator = this.materialValueInfo.object_count_with_match;

    const result = (numerator / denominator) * 100;
    return result;
  }

  get massWithValuePercentage(): number | null {
    if (!this.materialValueInfo || !this.materialValueInfo.total_mass) {
      return null;
    }
    const numerator =
      this.materialValueInfo.total_mass_with_material_price ?? 0;
    const denominator = this.materialValueInfo.total_mass;

    const result = (numerator / denominator) * 100;
    return result;
  }

  get currentBuildingID(): string | undefined {
    return buildingStore.currentBuilding?.id;
  }

  get environmentalDashboardConfig(): DashboardBuildingEnvironmentalIN {
    return {
      unit: this.environmentalUnitSelected,
      category_system: this.environmentalCategorySystemSelected,
    };
  }

  setEnvironmentalUnitSelected(newValue: string) {
    runInAction(() => {
      this.environmentalUnitSelected = newValue;
      this.fetchEnvironmental(this.currentBuildingID);
    });
  }

  setEnvironmentalCategorySystemSelected(newValue: string) {
    runInAction(() => {
      this.environmentalCategorySystemSelected = newValue;
      this.fetchEnvironmental(this.currentBuildingID);
    });
  }

  setMassUnitSelected(newValue: string) {
    runInAction(() => {
      this.massUnitSelected = newValue;
      this.fetchMass(this.currentBuildingID);
    });
  }

  setMassCategorySystemSelected(newValue: string) {
    runInAction(() => {
      this.massCategorySystemSelected = newValue;
      this.fetchMass(this.currentBuildingID);
    });
  }

  setMaterialValueCategorySelected(newValue: string) {
    runInAction(() => {
      this.materialValueCategorySelected = newValue;
      this.fetchMaterialValue(this.currentBuildingID);
    });
  }

  async fetchMaterialValue(
    building_id: string | undefined
  ): Promise<DashboardBuildingMaterialValue | void> {
    if (!building_id || !this.materialValueCategorySelected) return;
    const authHeader = await getLocalAuthHeader();
    return await buildingDashboardApi
      .reportApiV1RoutersDashboardBuildingMaterialValue(
        building_id,
        { category_system: this.materialValueCategorySelected },
        authHeader
      )
      .then((response) => response.data)
      .then((value) =>
        runInAction(() => {
          this.materialValueInfo = value;
        })
      )
      .catch((err) => console.error(err, "error.fetchMaterialValue"));
  }

  async fetchCpx(
    building_id: string | undefined
  ): Promise<DashboardBuildingCPX | void> {
    if (!building_id) return;
    const authHeader = await getLocalAuthHeader();
    return buildingDashboardApi
      .reportApiV1RoutersDashboardBuildingCpx(building_id, authHeader)
      .then((response) => response.data)
      .then((value) =>
        runInAction(() => {
          this.cpxInfo = value;
        })
      )
      .catch((err) => console.error(err, "error.fetchCpx"));
  }

  async fetchEnvironmental(
    building_id: string | undefined
  ): Promise<DashboardBuildingEnvironmental | void> {
    if (!building_id) return;
    const authHeader = await getLocalAuthHeader();
    return buildingDashboardApi
      .reportApiV1RoutersDashboardBuildingEnvironmental(
        building_id,
        {
          unit: this.environmentalUnitSelected,
          category_system: this.environmentalCategorySystemSelected,
        },
        authHeader
      )
      .then((response) => response.data)
      .then((value) =>
        runInAction(() => {
          this.environmentalInfo = value;
        })
      )
      .catch((err) => console.error(err, "error.fetchEnvironmental"));
  }

  async fetchMass(
    building_id: string | undefined
  ): Promise<DashboardBuildingMass | void> {
    if (!building_id) return;
    const authHeader = await getLocalAuthHeader();
    return buildingDashboardApi
      .reportApiV1RoutersDashboardBuildingMass(
        building_id,
        {
          category_system: this.massCategorySystemSelected,
          unit: this.massUnitSelected,
        },
        authHeader
      )
      .then((response) => response.data)
      .then((value) =>
        runInAction(() => {
          this.massInfo = value;
        })
      )
      .catch((err) => console.error(err, "error.fetchEnvironmental"));
  }

  async fetchOperationalEnergy(
    building_id: string | undefined
  ): Promise<DashboardBuildingOperationalEnergy | void> {
    if (!building_id) return;
    const authHeader = await getLocalAuthHeader();
    return buildingDashboardApi
      .reportApiV1RoutersDashboardBuildingOperationalEnergy(
        building_id,
        authHeader
      )
      .then((response) => response.data)
      .then((value) =>
        runInAction(() => {
          this.operationalEnergyInfo = value;
        })
      )
      .catch((err) => console.error(err, "error.fetchOperationalEnergy"));
  }

  async fetchGeneralEmissions(
    building_id: string | undefined
  ): Promise<DashboardBuildingGeneralEmissions | void> {
    if (!building_id) return;
    const authHeader = await getLocalAuthHeader();
    return buildingDashboardApi
      .reportApiV1RoutersDashboardBuildingGeneralEmissions(
        building_id,
        authHeader
      )
      .then((response) => response.data)
      .then((value) =>
        runInAction(() => {
          this.emissionsInfo = value;
        })
      )
      .catch((err) => console.error(err, "error.fetchGeneralEmissions"));
  }

  async initialFetch() {
    const buildingId = this.currentBuildingID;
    await Promise.all([
      this.fetchMaterialValue(buildingId),
      this.fetchCpx(buildingId),
      this.fetchEnvironmental(buildingId),
      this.fetchGeneralEmissions(buildingId),
      this.fetchMass(buildingId),
      this.fetchOperationalEnergy(buildingId),
    ]);
  }

  static instance: BuildingDashboardStore;

  constructor() {
    makeAutoObservable(this);
  }

  static getInstance(): BuildingDashboardStore {
    if (!BuildingDashboardStore.instance) {
      BuildingDashboardStore.instance = new BuildingDashboardStore();
    }
    return BuildingDashboardStore.instance;
  }
}

export const buildingDashboardStore = BuildingDashboardStore.getInstance();
