import React from "react";
import DynamicMappingEditor from "features/MappingTools/DynamicMappingEditor";
import MappingEditorHeader from "features/MappingTools/DynamicMappingEditor/layout/MappingEditorHeader";
import MappingEditorFooter from "features/MappingTools/DynamicMappingEditor/layout/MappingEditorFooter";

const MappingEditorPage = () => {
  return (
    <div className="bg-white flex flex-col h-screen">
      <MappingEditorHeader />
      <DynamicMappingEditor />
      <MappingEditorFooter />
    </div>
  );
};

export default MappingEditorPage;
