import React from "react";
import { observer } from "mobx-react-lite";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import GroupByMenu from "./GroupByMenu";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import clsx from "clsx";
import DropdownBrowser from "components/DropdownBrowser";
import { PropertyBrowserTreeNode } from "api-client";
import { useTranslation } from "react-i18next";

export default observer(function GroupByPropertyBrowser(props: {
  advancedMode?: boolean;
}) {
  const { t, i18n } = useTranslation();
  const isEng = i18n.language === "en";
  const openIconRef = React.useRef<HTMLDivElement>(null);
  const filteredItems = dynamicMEStore.filterNotSelectedItems(
    dynamicMEStore.properties,
    dynamicMEStore.selectedGroupByItems
  );

  const closeView = () => dynamicMEStore.setOpenGroupBy(false);

  React.useEffect(() => {
    document.addEventListener("click", closeView);
    return () => {
      document.removeEventListener("click", closeView);
    };
  }, []);

  const handleOpenMenu = () => {
    dynamicMEStore.setOpenGroupBy(!dynamicMEStore.openGroupBy);
  };

  const onSelectColumn = (selectedItems: PropertyBrowserTreeNode[]) => {
    dynamicMEStore.setSelectedGroupByItems(selectedItems);
    dynamicMEStore.reOrderGroupingColumns();
  };

  const clientRect = openIconRef.current?.getBoundingClientRect();

  return (
    <div className="overflow-visible" onClick={(e) => e.stopPropagation()}>
      <div ref={openIconRef}>
        {!dynamicMEStore.validGroupByRules?.length ? (
          <DropdownBrowser<PropertyBrowserTreeNode>
            displayKey={isEng ? "name" : "name_de"}
            detailKey={isEng ? "classification" : "classification_de"}
            handleSelect={onSelectColumn}
            items={filteredItems}
            checkedItems={dynamicMEStore.selectedGroupByItems}
            placeholder={t("mappingEditor.findProperty")}
            optionsClassName="!max-w-fit min-w-[246px] !right-0"
            titleOrIcon={
              <IconPlus
                className={clsx("h-4 w-4 cursor-pointer", {
                  "ml-2": props.advancedMode,
                })}
              />
            }
            isOpen={(open: boolean) => dynamicMEStore.setOpenGroupBy(open)}
          />
        ) : (
          <IconChevronDown
            className={clsx(
              "h-5 w-5 cursor-pointer",
              dynamicMEStore.openGroupBy ? "rotate-180" : ""
            )}
            onClick={handleOpenMenu}
          />
        )}
      </div>
      {dynamicMEStore.openGroupBy &&
      dynamicMEStore.validGroupByRules?.length ? (
        <GroupByMenu
          cursorPosition={{
            x: Number(clientRect?.x),
            y: Number(clientRect?.y),
          }}
          items={filteredItems}
          onSelectColumn={onSelectColumn}
        />
      ) : null}
    </div>
  );
});
