import Tooltip from "components/Tooltip";
import React from "react";

export default function BuildingHeaderName({ name }: { name: string }) {
  return (
    <div className="text-sm md:text-base font-semibold leading-4 ">
      <Tooltip
        truncate
        className="sm:max-w-[360px] xs:max-w-[150px] md:max-w-fit"
        tooltipClassName="!w-fit"
        delay={100}
      >
        {name}
      </Tooltip>
    </div>
  );
}
