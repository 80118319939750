import React from "react";
import { observer } from "mobx-react-lite";
import { searchStore } from "store/IfcMapping/SearchStore";
import ProductSearch from "../ProductSearch";
import { dynamicMEStore } from "store/IfcMapping/DME/DMEStore";
import { InventoryCategoryAggregateOut, SingleItem } from "api-client";
import MEObjectDetail from "../ObjectDetailsSlideOver";
import { useParams } from "react-router";
import PanelLayout from "./layout/PanelLayout";
import ProductDetailsPopup from "../ProductDetailsPopup";
import { buildingProductStore } from "store/IfcMapping/BuildingProductStore";

const DynamicMappingEditor = observer(() => {
  const { ifc_id } = useParams();

  React.useEffect(() => {
    dynamicMEStore.getFileCalculationsStatus(ifc_id);
    buildingProductStore.fetchCPXValues();

    const interval = setInterval(
      () => {
        dynamicMEStore.getFileCalculationsStatus(ifc_id);
      },
      dynamicMEStore.fileCalculationsStatus ? 5000 : 10000
    );

    return () => clearInterval(interval);
  }, []);

  async function matchProductAndUpdate(
    product_id: string,
    current_object: SingleItem
  ) {
    if (dynamicMEStore.selectAllChecked && !current_object) {
      await dynamicMEStore.runFileOperation(ifc_id, "manual_match", product_id);
    } else {
      await dynamicMEStore.runSelectedObjectOperations(
        ifc_id,
        "manual_match",
        product_id,
        dynamicMEStore.matchForSelectedItems ? undefined : current_object
      );
    }
  }

  const onCloseObjectDetailView = () => {
    dynamicMEStore.setOpenedObjectDetails(false);
    dynamicMEStore.setCurrentRow(undefined);
  };

  const onCloseProductDetailView = () => {
    dynamicMEStore.setOpenedProductDetailsPopup({ open: false });
  };

  return (
    <div className="flex-grow">
      <PanelLayout />
      {searchStore.openProductSearch && (
        <ProductSearch
          open={searchStore.openProductSearch}
          close={() => searchStore.setOpenProductSearch(false)}
          currentRow={
            dynamicMEStore.currentRow?.original as InventoryCategoryAggregateOut
          }
          matchProductAndUpdate={matchProductAndUpdate}
          isDynamicME
        />
      )}
      <MEObjectDetail
        open={dynamicMEStore.openedObjectDetails}
        close={onCloseObjectDetailView}
        DMEObject={dynamicMEStore.getCurrentRowObject()}
        isDynamicME
      />
      <ProductDetailsPopup
        product={null}
        productId={dynamicMEStore.openedProductDetailsPopup.id}
        productName={dynamicMEStore.openedProductDetailsPopup.name}
        productType={dynamicMEStore.openedProductDetailsPopup.type}
        open={dynamicMEStore.openedProductDetailsPopup.open}
        close={onCloseProductDetailView}
      />
    </div>
  );
});

export default React.memo(DynamicMappingEditor);
