import Button from "components/Button";
import Modal, { ModalProps } from "components/Modal";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps extends ModalProps {
  maxBuildingNo: number;
}

const MaxBuildingCountAlert = (props: IProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={props.open}
      setIsOpen={props.close}
      closeButton
      title={t("dashboard.max_building_heading")}
      disableOutsideClick
      containerClassName="sm:my-8 sm:max-w-sm sm:w-full md:max-w-xl"
    >
      <div>
        <p className="px-6 pt-6 font-[400] text-[14px]">
          {t("dashboard.max_building_message", {
            maxBuildingNo: props.maxBuildingNo,
          })}
        </p>
        <div className="mt-6 px-6 flex w-full items-end justify-end py-4 bg-gray-50">
          <Button
            className="font-[500] text-[16px]"
            width="fit-content"
            onClick={props.close}
            type="primary"
          >
            {t("dashboard.btn_i_understand")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MaxBuildingCountAlert;
