export enum DB_VARS {
  AUTH_HEADER = "authHeader",
  ROOM_NAMES = "roomNames",
}

export function getDBVariable(key: string) {
  return localStorage.getItem(key);
}

export function setDBVariable(key: string, value: string) {
  localStorage.setItem(key, value);
}
