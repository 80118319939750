import React, { useEffect } from "react";
import ConfigureConnection from "features/Revit/ConfigurePlugin";
import InfoScreen from "features/Revit/InfoScreen";
import { useUser } from "contexts/user";
import { useTranslation } from "react-i18next";
import LoadingIcon from "components/LoadingIcon";
import { observer } from "mobx-react";
import RevitLayout from "features/Revit/RevitLayout";
import { buildingStore } from "store/Building/BuildingStore";
import { userStore } from "store/UserStore";

interface RevitProps {
  lang: string;
}

export default observer(function Revit({ lang }: RevitProps) {
  const { user } = useUser();
  const { onboarded } = userStore;
  const { t, i18n } = useTranslation();
  const { noBuilding } = buildingStore;

  useEffect(() => {
    if (lang === "de") {
      i18n.changeLanguage("de");
    }
  }, []);

  function loadingState() {
    return (
      !user ||
      onboarded === undefined ||
      (onboarded && noBuilding === undefined)
    );
  }

  function notReadyToConnect() {
    return !onboarded || noBuilding === true;
  }

  if (loadingState())
    return (
      <div className="flex flex-col items-center h-full min-h-screen justify-center">
        <LoadingIcon className="!w-20 !h-20 mb-4" />
        <div className="text-gray-500 text-xs font-mendium">
          {t("revit.pluginLoading")}
        </div>
      </div>
    );

  return (
    <RevitLayout>
      <div className="p-4 min-h-screen flex justify-center">
        {notReadyToConnect() ? <InfoScreen /> : <ConfigureConnection />}
      </div>
    </RevitLayout>
  );
});
