import React from "react";

const EditorLayout = (props: {
  header: React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <div className="fixed top-0 left-0 w-screen h-screen z-20 bg-gray-100">
      {props.header}
      <div className="grid grid-cols-2 relative min-h-[calc(100vh-57px)]">
        {props.children}
      </div>
    </div>
  );
};

export default EditorLayout;
