import React from "react";

export default function useDisableScrollOnNumberInputs() {
  React.useEffect(() => {
    window.addEventListener("wheel", disableScrollOnNumberInputs);
    return () =>
      window.removeEventListener("wheel", disableScrollOnNumberInputs);
  }, []);
}

function disableScrollOnNumberInputs() {
  if (
    document &&
    document.activeElement &&
    (document.activeElement as HTMLInputElement).type === "number"
  ) {
    (document.activeElement as HTMLInputElement).blur();
  }
}
