import React from "react";
import useDisableScrollOnNumberInputs from "hooks/useDisableScrollOnNumberInputs";
import { useTranslation } from "react-i18next";
import { blueprintCreateStore } from "store/AuditsOnline/BlueprintCreateStore";
import { observer } from "mobx-react-lite";
import GeneratedFieldsOnline from "features/AuditOnline/GeneratedFieldsOnline";
import { ContinueButton } from "../navigation/ContinueButton";
import SkipButton from "../navigation/SkipButton";

const Step2BlueprintForm = () => {
  const { selectedProductGroup, step: currentStep } = blueprintCreateStore;
  const { t } = useTranslation();
  const ref = React.useRef<HTMLDivElement>(null);

  useDisableScrollOnNumberInputs();

  React.useEffect(() => {
    if (!ref?.current) return;
    if (currentStep !== 1) return;
    setTimeout(() => {
      if (!ref?.current) return;
      const firstInput = ref.current.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      }
    }, 500);
  }, [ref, currentStep]);

  if (!selectedProductGroup) {
    return <div>{t("audits.productGroupMustBeSelected")}</div>;
  } else {
    return (
      <>
        <GeneratedFieldsOnline
          ref={ref}
          generatesDimensions
          generatesColor
          required_fields={selectedProductGroup.required_fields}
          optional_fields={selectedProductGroup.optional_fields}
        />
        <div className="flex justify-evenly gap-4">
          <SkipButton />
          <ContinueButton />
        </div>
      </>
    );
  }
};

export default observer(Step2BlueprintForm);
